import { ContentWrapper, Wrapper, iconStyles } from './styles';
import React, { useRef, useState } from 'react';
import { storeFiltersOptions, userSettingsReducerValues } from '../../../../UserSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';

import ContextualMenu from '../../../../ContextualMenu/ContexttualMenu';
import DropdownBody from '../DropdownBody/DropdownBody';
import { IProps } from './interfaces';
import { IReportingObject } from '../../../../../../General.interfaces';
import { Icon } from '@fluentui/react';
import { generalReducerValues } from '../../../../../../General.reducer';
import { objectTypesMap } from '../../../../../../tools/getReportingObjects';
import { useTranslation } from 'react-i18next';

const Bubble: React.FC<IProps> = ({ objects }) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        cfg: { reportingObjectsById, reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);
    const selectedFilterIds = currentOptions?.['filters'] || [];
    const selectedFilteringObjects: IReportingObject[] = selectedFilterIds.map(
        (id: number) => reportingObjectsById[id],
    );
    const [isContextMenuOpen, setContextMenuOpen] = useState(false);

    const [cursorCoordinates, setCursorCoordinates] = useState({ x: 0, y: 0 });

    const { currentModule, selectedLocationId } = currentOptions;
    const objType = objects[0].object_type;

    const dispatchFilters = (ids: number[]) => {
        selectedLocationId &&
            dispatch(
                storeFiltersOptions({
                    locationId: selectedLocationId,
                    module: currentModule,
                    selectedOptions: ids,
                }),
            );
    };

    const handleSelect = (ids: number[]) => {
        dispatchFilters(ids);
        setContextMenuOpen(false);
    };

    const onIconClick = () => {
        const newIds = selectedFilteringObjects
            ?.filter((obj: IReportingObject) => obj.object_type !== objType)
            .map((obj) => obj.id);
        dispatchFilters(newIds);
    };

    const toggleDropdown = (e: React.MouseEvent<HTMLElement>) => {
        setContextMenuOpen((prevState) => !prevState);
        setCursorCoordinates({ x: e.clientX, y: e.clientY });
    };

    const closeContextMenu = () => {
        setContextMenuOpen(false);
    };

    return (
        <Wrapper ref={targetRef}>
            <ContentWrapper>
                <div onClick={toggleDropdown}>
                    {`${t(objectTypesMap[objType] || objType)}: ${
                        objects.length > 3
                            ? objects.length
                            : objects.reduce((acc, value) => {
                                  if (acc) return acc + `, ${value.name}`;
                                  return acc + `${value.name}`;
                              }, '')
                    }`}

                    <ContextualMenu
                        isContextualMenuOpen={isContextMenuOpen}
                        hideContextualMenu={closeContextMenu}
                        targetRef={targetRef}
                        gapSpace={10}
                        CustomMenuComponent={
                            <DropdownBody
                                reportingObjects={reportingObjectsByType[objType]}
                                selectedReportingObjects={selectedFilteringObjects}
                                filterType={t(objectTypesMap[objType])}
                                key={JSON.stringify(closeContextMenu)}
                                handleSelect={handleSelect}
                            />
                        }
                    />
                </div>
                <Icon styles={iconStyles} onClick={onIconClick} iconName="Cancel" />
            </ContentWrapper>
        </Wrapper>
    );
};

export default Bubble;
