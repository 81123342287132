import { Stack } from '@fluentui/react';
import { isNull, isUndefined } from 'lodash';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import { TOrdering } from '../../../../../../../../../OrderSwitcher/interfaces';
import OrderSwitcher from '../../../../../../../../../OrderSwitcher/OrderSwitcher';
import { subsetsOfSalesMetricsOptions, subsetsSubtypeTextMap } from '../../../../../../constants/contsants';
import { SalesOfTenantsContext } from '../../../../context';
import { Tr } from '../../styles';
import { PercentageValue, Th, Thead } from './styles';

/**
 * Компонент для отображения шапки таблицы
 */
const Header = () => {
    const context = useContext(SalesOfTenantsContext);
    const { t } = useTranslation();

    const subtypes = useMemo(() => {
        if (context?.selectedSubsetId) {
            const currentSubtypes = subsetsOfSalesMetricsOptions?.find(
                (element) => element.id === context.selectedSubsetId,
            )?.subtypes;
            if (currentSubtypes) {
                return currentSubtypes.map((element) => element.id).sort();
            }
        }
        return [];
    }, [context?.selectedSubsetId]);

    const handleOrderToggle = (columnId: string, period: 'main' | 'compare') => (args: { ordering: TOrdering }) => {
        if (context) {
            const { ordering } = args;
            context.storeTableOrderDataTrans({ ordering, columnId, period });
        }
    };

    return (
        <Thead>
            <Tr>
                <Th fontSize="16px" fontWeight="600" bgColor="#f5f5f5">
                    <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <div>{t('Tenant')}</div>
                        <OrderSwitcher
                            ordering={
                                context?.tableOrderData?.columnId === 'tenant'
                                    ? context.tableOrderData.ordering
                                    : undefined
                            }
                            handleOrderToggle={handleOrderToggle('tenant', 'main')}
                        />
                    </Stack>
                </Th>
                {subtypes.map((element) => (
                    <Th key={element} fontSize="16px" fontWeight="600" bgColor="#f5f5f5">
                        <Stack
                            horizontalAlign="space-between"
                            tokens={{ childrenGap: 10 }}
                            verticalAlign="center"
                            horizontal={true}
                        >
                            <OrderSwitcher
                                ordering={
                                    context?.tableOrderData?.columnId === element &&
                                    context?.tableOrderData?.period === 'main'
                                        ? context.tableOrderData.ordering
                                        : undefined
                                }
                                handleOrderToggle={handleOrderToggle(element, 'main')}
                            />
                            <div>{t(subsetsSubtypeTextMap[element])}</div>
                            <OrderSwitcher
                                ordering={
                                    context?.tableOrderData?.columnId === element &&
                                    context?.tableOrderData?.period === 'compare'
                                        ? context.tableOrderData.ordering
                                        : undefined
                                }
                                handleOrderToggle={handleOrderToggle(element, 'compare')}
                            />
                        </Stack>
                    </Th>
                ))}
            </Tr>
            <Tr>
                <Th fontSize="14px" fontWeight="500" bgColor="#fffded">
                    {t('Total')}
                </Th>
                {subtypes.map((element) => {
                    const mainTotalValue = context?.totalValue?.[element]?.mainPeriodTotalValue;
                    const comparePercentageOfTheMain = context?.totalValue?.[element]?.comparePercentageOfTheMain;

                    return (
                        <Th key={element} fontSize="14px" fontWeight="500" bgColor="#fffded">
                            {context?.metrcisFetching ? (
                                t('Loading...')
                            ) : context?.metrcisFetchingError ? (
                                t('Unable to get data')
                            ) : (
                                <Stack horizontal={true} verticalAlign="center" horizontalAlign="space-between">
                                    <div>
                                        {!isUndefined(mainTotalValue)
                                            ? numberWithSpaces(mainTotalValue.toFixed(2))
                                            : '—'}
                                    </div>
                                    <PercentageValue value={comparePercentageOfTheMain}>
                                        {!isUndefined(comparePercentageOfTheMain) && !isNaN(comparePercentageOfTheMain)
                                            ? `${numberWithSpaces(comparePercentageOfTheMain.toFixed(2))}%`
                                            : '—'}
                                    </PercentageValue>
                                </Stack>
                            )}
                        </Th>
                    );
                })}
            </Tr>
            <Tr>
                <Th fontSize="14px" fontWeight="500" bgColor="#fffded">
                    {t('Average')}
                </Th>
                {subtypes.map((element) => {
                    const mainAverageValue = context?.totalValue?.[element]?.mainAverageValue.toFixed(2);
                    const comparePercentageOfTheAverageMain =
                        context?.totalValue?.[element]?.comparePercentageOfTheAverageMain;

                    return (
                        <Th key={element} fontSize="14px" fontWeight="500" bgColor="#fffded">
                            {context?.metrcisFetching ? (
                                t('Loading...')
                            ) : context?.metrcisFetchingError ? (
                                t('Unable to get data')
                            ) : (
                                <Stack horizontal={true} verticalAlign="center" horizontalAlign="space-between">
                                    <div>
                                        {!isUndefined(mainAverageValue) ? numberWithSpaces(mainAverageValue) : '—'}
                                    </div>
                                    <PercentageValue value={comparePercentageOfTheAverageMain?.toFixed(2)}>
                                        {!isUndefined(comparePercentageOfTheAverageMain) &&
                                        !isNaN(comparePercentageOfTheAverageMain)
                                            ? `${numberWithSpaces(comparePercentageOfTheAverageMain.toFixed(2))}%`
                                            : '—'}
                                    </PercentageValue>
                                </Stack>
                            )}
                        </Th>
                    );
                })}
            </Tr>
        </Thead>
    );
};

export default Header;
