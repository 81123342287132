import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../lib/esm/components';
import { IProps } from './interfaces';
import { StIcon, Wrapper } from './styles';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';

import { TSelectedOptions } from '../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues, storeUniversalOptions } from '../../UserSettings/reducer';

const UniversalNestedSelect: React.FC<IProps> = ({
    name,
    options,
    iconType = 'chevron-down',
    maxSelected,
    dropdownAlign = 'left',
    showFilter = true,
    minSelected = 1,
    labelText = ' ',
    dropdownWidth,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { currentModule, selectedLocationId } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);

    const handleSelect = (args: TSelectedOptions) => {
        selectedLocationId &&
            dispatch(
                storeUniversalOptions({
                    locationId: selectedLocationId,
                    module: currentModule,
                    selectedOptions: args.map((item) => ({ id: item.id, section: item.section, text: item.text })),
                    name,
                }),
            );
    };

    const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                options={options}
                dropdownWidth="md"
                text={t('Selected')}
                dropdownAlign={dropdownAlign}
                showFilter={showFilter}
                mode="multi"
                labelText={`${t(labelText)}${max}`}
                iconType={iconType}
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                outsideSelected={
                    (currentOptions[name] as TSelectedOptions)?.map((element) => ({
                        ...element,
                        text: t(element.text || ''),
                    })) || []
                }
                additionalControl={<StIcon iconName="Delete" />}
                minSelected={minSelected}
            />
        </Wrapper>
    );
};

export default UniversalNestedSelect;
