import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { OptionsWrapper, TitleWrapper } from './styles/styles';
import DynamicsPMO from './widgets/DynamicsPMO/DynamicsPMO';
import AnalysisByArea from './widgets/AnalysisByArea/AnalysisByArea';
import Traffic from './widgets/Traffic/Traffic';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../Preset/Preset';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import SummaryTable from './widgets/SummaryTable/SummaryTable';
import EventsCarousel from '../../Events/Events/widgets/EventsCarousel/EventsCarousel';

const Dashboard: React.FC = ({}) => {
    const { selectedLocationId, locations = [], currentModule } = useSelector(generalReducerValues);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ComparePeriodSelect />
                        <ObjectsSelect maxSelected={5} />
                        <MetricsSelect />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'selectedReportingObjectsIds',
                                'selectedMetrics',
                                'comparePeriods',
                                'mainPeriod',
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <SummaryTable />
                <EventsCarousel moduleName={'Performance:Dashboard'} />
                <AnalysisByArea />
                <DynamicsPMO />
                <Traffic />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
