import { ResponseStatus } from '../../../../../../../../tools/API/constants';

type TElement = { status: ResponseStatus; message: string };

/**
 * Функцция получения данных для передачи HOC, отвечающим за отображение загрузки и ошибок
 * @param elements Загружаемые данные
 * @returns Объект для передачи HOC, отвечающим за отображение загрузки и ошибок
 */
const getDataForLoadingWrapper = (elements: TElement[] | any[]): TElement | any[] => {
    let response: TElement | any[] = [];
    elements
        ?.filter((element) => element !== null)
        .forEach((element) => {
            if (element?.['status']) {
                response = element;
            }
        });

    return response;
};

export default getDataForLoadingWrapper;
