import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { TSelectedOptions } from '../../../../../../../../../lib/esm/components/Select/configurations';
import { shortStringDate } from '../../../../../../../../../tools/Strings/shortStringDate';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';
import WidgetPeriodWrapper from '../../../../../../../Wrappers/WidgetPeriodWrapper/WidgetPeriodWrapper';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../../reducer';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../../reducer';
import { MainPeriodText, SelectsWrapper } from './styles';


const Selects: React.FC = () => {
    const { t } = useTranslation();
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const {
        lang,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { selectedTab } = useSelector(Leasing_TenantOverview_Reference_Widget_Reducer_Values);

    const selectedTenant = localCurrentOptions?.['tenants']?.[0];

    const compareOptions =
        localCurrentOptions?.comparePeriods?.map((item) => {
            const [range] = localCurrentOptions?.compareDateRanges?.filter((r) => r.id === item.id);
            const text = range?.period ? `${range.period.dateFrom} – ${range.period.dateTo}` : '';
            return { id: item.id, text };
        }) || [];

    const otherTenantsOptions: TSelectedOptions = reportingObjectsByType?.['tenant']
        ?.filter((item) => item.id !== selectedTenant?.id)
        ?.map((item) => {
            return {
                id: item.id,
                text: item.name,
            };
        });

    const selectedComparePeriodID = localCurrentOptions?.['selectedComparePeriod']?.[0]?.id;
    const outsideText = localCurrentOptions?.compareDateRanges?.find((r) => r.id === selectedComparePeriodID)?.text;

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;
    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];

    return (
        <SelectsWrapper horizontal tokens={{ childrenGap: 20, padding: 10 }}>
            <MainPeriodText>{shortStringDate(mainDateRange?.period, lang)}</MainPeriodText>
            <UniversalSelect
                mode="single"
                options={compareOptions}
                name={'selectedComparePeriod'}
                labelText={t('Comparison period')}
                text={t('Select period to compare')}
                outsideText={outsideText}
            />
            {selectedTab === 'otherTenants' && (
                <UniversalSelect
                    mode="multi"
                    options={otherTenantsOptions}
                    name={'otherTenants'}
                    labelText={t('Other tenants')}
                    text={t('Select tenants to compare')}
                />
            )}
        </SelectsWrapper>
    );
};

export default Selects;
