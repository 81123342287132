import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { storePreraredData, Performance_Dashboard_Traffic_Widget_Reducer_Values } from '../reducer';
import { DateTime } from 'luxon';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IMetricResponseItem } from '../../../../../../../../General.interfaces';
import { cloneDeep } from 'lodash';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { filterValidDateRanges } from '../../../../../../../../tools/filterValidDateRanges';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';

export const usePrepareData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        cfg: { reportingObjectsByType },
        structures,
    } = useSelector(generalReducerValues);
    const { ratingType, selectedZoneId, responseResult, selectedFloor } = useSelector(
        Performance_Dashboard_Traffic_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');

    useEffect(() => {
        if (
            !structures?.relations_passway2dataobj ||
            !localCurrentOptions?.mainPeriod ||
            !localCurrentOptions?.mainDateRanges
        )
            return;
        if (!responseResult || responseResult['status']) {
            if (
                responseResult?.['status'] === ResponseStatus.Error ||
                responseResult?.['status'] === ResponseStatus.NoData
            ) {
                dispatch(storePreraredData({ ...responseResult }));
            }
        } else {
            const currentPeriod = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod?.id,
            )?.period;

            if (currentPeriod) {
                try {
                    const selectedZone = reportingObjectsByType['zone']
                        ? reportingObjectsByType['zone']?.filter(
                              (item) => String(item.id) === String(selectedZoneId),
                          )[0]
                        : null;

                    const filteredByMainPeriod = filterValidDateRanges(
                        structures.relations_passway2dataobj,
                        currentPeriod,
                    );
                    const filtered: number[] = filteredByMainPeriod
                        ?.filter((row) => {
                            if (ratingType !== 'zone') {
                                if (ratingType === 'all') {
                                    return true;
                                }
                                if (ratingType === 'floor') {
                                    return (
                                        row.dataobj_type === ratingType && Number(row.dataobj_marker) === selectedFloor
                                    );
                                }

                                return row.dataobj_type === ratingType;
                            }
                            if (ratingType === 'zone' && selectedZone) {
                                return row.dataobj_type === ratingType && row.dataobj_marker === selectedZone.marker;
                            }
                            return false;
                        })
                        .map((item) => item.passway_dataobj_id);

                    const mainResult = responseResult[0]?.filter((item) =>
                        filtered.includes(item.context.data_objects[0].id),
                    );

                    const clone = cloneDeep(responseResult);

                    clone.shift();
                    const compareResults = clone;

                    const mainTrafficsById: { [x: string]: number } = {};
                    mainResult.forEach((item: IMetricResponseItem) => {
                        const id = item.context.data_objects[0].id;

                        const mainTraffic: number = item.items[0].value;
                        mainTrafficsById[id] = mainTraffic;
                    });

                    const sumMainTraffic = Object.values(mainTrafficsById).reduce((acc, item) => {
                        return !isNaN(item) ? acc + item : acc;
                    }, 0);

                    const maxMainTraffic: number = Math.max(
                        ...Object.values(mainTrafficsById).map((item) => {
                            return !isNaN(item) ? item : 0;
                        }),
                    );

                    const preparedData = mainResult.length
                        ? mainResult
                              .map((item: any, index: number) => {
                                  const name = item.context.data_objects[0].name;
                                  const id = item.context.data_objects[0].id;
                                  const period = item.context.time_range;
                                  const mainTraffic = mainTrafficsById[id];
                                  const mainPercentByMax = Math.round((mainTraffic * 100) / maxMainTraffic);
                                  const mainPercent =
                                      Math.round((mainTraffic / sumMainTraffic) * 100 * Math.pow(10, 2)) /
                                      Math.pow(10, 2);

                                  const row: any[] = [
                                      { type: 'id', value: id },
                                      { type: 'name', value: name },
                                      { type: 'rating', value: mainPercentByMax },
                                      { type: 'mainPercent', value: mainPercent },
                                      { type: 'mainTraffic', value: mainTraffic },
                                  ];

                                  compareResults.forEach((resArr: any) => {
                                      const obj = resArr?.find((item: any) => item.context.data_objects[0].id === id);
                                      const period = obj.context.time_range;
                                      const traffic = obj.items.reduce(
                                          (acc: number, item: { value: number | null }) => {
                                              return item.value ? acc + item.value : acc;
                                          },
                                          0,
                                      );
                                      const percent =
                                          Math.round(((mainTraffic - traffic) * 100 * Math.pow(10, 2)) / traffic) /
                                          Math.pow(10, 2);

                                      row.push({ type: 'comparePercent', period, value: percent });
                                  });

                                  return row;
                              })
                              .sort((a: any, b: any) => b[2].value - a[2].value)
                        : { status: 'No data', message: 'NO DATA' };

                    dispatch(storePreraredData(preparedData));
                } catch (error) {}
            }
        }
    }, [
        structures?.relations_passway2dataobj,
        responseResult,
        ratingType,
        selectedZoneId,
        selectedFloor,
        reportingObjectsByType,
        dispatch,
    ]);
};
