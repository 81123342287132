import { Stack } from '@fluentui/react';
import { FC, memo } from 'react';
import { IMetric } from '../../../../../../../../../General.interfaces';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { IPreparedDataValue } from '../../interfaces';
import Body from './components/Body/Body';
import Header from './components/Header/Header';
import { StyledTable, Wrapper } from './styles';

interface IProps {
    /** Данные отчетного объекта */
    reportingObjectData: IPreparedDataValue;
    /** Все метрики */
    allMetrics: IMetric[];
}

/**
 * Компонент для отображения таблицы
 */
const Table: FC<IProps> = memo(({ reportingObjectData, allMetrics }) => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');

    const metricsById: { [id: string]: IMetric } = allMetrics.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
    }, {});

    const mainPeriodData = localCurrentOptions?.mainDateRanges?.find(
        (element) => element.id === localCurrentOptions?.mainPeriod?.id,
    );

    const comparePeriodsData = localCurrentOptions?.compareDateRanges
        ?.filter((element) => {
            let flag = false;
            const comparePeriodsIds = localCurrentOptions?.comparePeriods?.map((element) => element.id) || [];

            if (comparePeriodsIds.includes(element.id)) flag = true;

            return flag;
        })
        ?.sort((a, b) => (a.id < b.id ? -1 : 1));

    return (
        <Wrapper>
            <Stack horizontal={true} tokens={{ childrenGap: 3 }}>
                <StyledTable>
                    <Header
                        reportingObjectName={reportingObjectData.reportingObject.name}
                        comparePeriodsData={comparePeriodsData}
                        mainPeriodData={mainPeriodData}
                    />
                    <Body
                        metricsData={reportingObjectData.metrics}
                        comparePeriodsData={comparePeriodsData}
                        mainPeriodData={mainPeriodData}
                        metricsById={metricsById}
                    />
                </StyledTable>
            </Stack>
        </Wrapper>
    );
});

export default Table;
