import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { TOrdering } from '../../../../../../OrderSwitcher/interfaces';
import OrderSwitcher from '../../../../../../OrderSwitcher/OrderSwitcher';
import { THeaderCellType } from '../interfaces';
import { Th, Percent, Value, Container, Title, ArrowWrapper } from '../styles';

const HeaderCell: React.FC<{
    children: ReactNode;
    colSpan: number;
    hasSorting?: boolean;
    ordering: TOrdering;
    type?: string;
    primaryType: string;
    handleOrderToggle: (x: { ordering: TOrdering; id: string }) => void;
}> = ({ children, colSpan, ordering, type, primaryType, hasSorting = false, ...props }) => {
    const { t } = useTranslation();
    const { allMetrics } = useSelector(generalReducerValues);
    const units = allMetrics?.find((item) => item.id === type)?.units || '';

    return (
        <Th colSpan={colSpan}>
            <Container>
                <Title>
                    {children}
                    {`, ${t(units)}`}
                </Title>
                {hasSorting && (
                    <OrderSwitcher
                        mode="triple"
                        id={type}
                        handleOrderToggle={props.handleOrderToggle}
                        ordering={ordering}
                        primaryId={primaryType}
                    />
                )}
            </Container>
        </Th>
    );
};

export default HeaderCell;
