import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const InputWrapper = styled.div`
    width: 100%;
    margin-bottom: var(--space-xs);
`;

export const Footer = styled.div`
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
