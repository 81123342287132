import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    flex: 0 0 100%;
    margin-right: var(--space-md);
    min-height: 500px;
    max-height: 600px;
    overflow: hidden;
    border: 1px solid #ebebeb;
    overflow: auto;
    position: relative;
    :last-child {
        margin-right: 0px;
    }
`;

export const Table = styled.table`
    width: 100%;
`;

export const StyledIcon = styled(Icon)<{ color: string }>`
    color: ${({ color }) => color};
`;
