import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponseStatus } from '../../../../../../../../../tools/API/constants';
import { getArrowAndColor } from '../../../../../../../../../tools/getArrowAndColor';
import LoadingBox from '../../../../../../../LoadingBox/LoadingBox';
import Header from '../Header/Header';
import { Li, Percent, Rating, Ul } from './styles';

const RatingItem: React.FC<{ data: any[] | null | string; title: string }> = ({ data = [], title }) => {
    let asc = true;
    const [ascStatus, setAscSort] = useState(asc);
    const { t } = useTranslation();

    const shandleHeaderClick = (e: boolean) => {
        setAscSort(e);
    };

    const dataSort = () => {
        if (Array.isArray(data)) {
            let dataSort = _.cloneDeep(data);
            if (ascStatus) {
                return dataSort;
            } else {
                return dataSort.reverse();
            }
        }
        return [];
    };

    const list = useMemo(() => {
        const listdata = dataSort().map((item) => {
            const percent = `${item.colorValue}%`;
            const color = getArrowAndColor(Number(item.colorValue)).color;
            const icon = getArrowAndColor(Number(item.colorValue)).icon;
            return (
                <Li key={item?.name}>
                    {t(item?.name || '')}
                    <Percent color={color}>
                        {percent}
                        {icon}
                    </Percent>
                </Li>
            );
        });
        return <Ul>{listdata}</Ul>;
    }, [ascStatus, data]);

    return (
        <Rating>
            <Header text={title} handleHeaderClick={shandleHeaderClick} />

            <div className="byArea-ratins-list">
                {data && !Array.isArray(data) ? (
                    <LoadingBox
                        isError={data === ResponseStatus.Error ? true : undefined}
                        noData={data === ResponseStatus.NoData ? true : undefined}
                        height={190}
                        hasBorder={false}
                    />
                ) : (
                    list
                )}
            </div>
        </Rating>
    );
};

export default RatingItem;
