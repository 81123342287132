import { IRequestMetricsArgs } from '../../../../../../../../../../../../lib/esm/components';
import { IGenerateQueryDataArgs } from './interfaces';

/**
 * Функция для генерации тел запроса для получения метрик
 * @param reportingObjectId ID отчетного объекта
 * @param selectedMetric Выбранная метрика
 * @param chartDetailing Детализация графика
 * @param metricLevel Уровень метрики
 * @param timeRanges Массив периодов, за которое нужно получить данные
 * @param token Тоен авторизации
 * @returns Тела запросов для получения метрик
 */
const generateQueryData = (args: IGenerateQueryDataArgs): IRequestMetricsArgs[] => {
    const { reportingObjectId, selectedMetric, chartDetailing, metricLevel, timeRanges, token } = args;
    const queryData: IRequestMetricsArgs[] = timeRanges.map((element) => {
        return {
            obj_ids: [reportingObjectId],
            time_freq: chartDetailing,
            object_aggregation: false,
            metric_level: metricLevel,
            metric: selectedMetric,
            time_range: element,
            alias: null,
            token,
        };
    });
    return queryData;
};

export default generateQueryData;
