import styled from 'styled-components/macro';

export const Color = styled.div<{ color: string }>`
    flex: 0 0 25px;
    height: 25px;
    border-radius: 5px;
    background-color: ${(p) => p.color};
    cursor: pointer;
`;

export const Name = styled.div`
    margin-left: 10px;
`;

export const Wrapper = styled.div`
    display: flex;
    min-height: 30px;
`;
