import styled from 'styled-components/macro';
import { IWindowSize } from '../../../../../../../../../General.interfaces';

export const ModalNewRatingWrapper = styled.div<{ mainWindowSize: IWindowSize }>`
    width: 500px;
    max-width: ${(p) => p.mainWindowSize.width - 40 + 'px'};
    z-index: 100;
`;

export const FormItem = styled.div`
    display: block;
    margin-bottom: var(--space-sm);
`;
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-xs);
`;
export const CheckboxItem = styled.div`
    display: flex;
    margin-bottom: var(--space-xxxs);
`;

export const Label = styled.div`
    margin-top: var(--space-xxxs);
    font-size: 12px;
    opacity: 0.4;
`;
export const Column = styled.div``;
export const GridTemplate = styled.div`
    display: grid;
    grid-template-columns: auto auto;
`;
