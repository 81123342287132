import { useEffect, useState } from 'react';
import { getUrls, hosts } from '../../../../constants/urls';
import { requestFrontExchange } from '../../../../lib/esm/components';
import { IUrlParams, TUrlParams } from '../configurations';

const EMERGENCY_INITIAL_PARAMS = { state: { lang: 'en', sidebar_compact: false, collapsed_sections: [], selected_subsection: '' } };

const useUrl = (): { urlParams: TUrlParams; FEparams: any } | null => {
    const [result, setResult] = useState<{ urlParams: TUrlParams; FEparams: any } | null>(null);
    const [urlParams, setUrlParams] = useState<TUrlParams>(null);
    const [FEparams, setFEparams] = useState<any>(null);
    const currentUrl = window.location.origin + window.location.pathname;

    useEffect(() => {
        findUrlParams();
    }, []);

    useEffect(() => {
        urlParams && FEparams && setResult({ urlParams, FEparams });
    }, [urlParams, FEparams]);

    const getStateFromFrontExchange = (cloud_temp_hash: string) => {
        requestFrontExchange({
            mode: 'load',
            cloud_temp_hash,
        })?.then((response: any) => {
            if (response?.state) {
                setFEparams({ ...response, state: JSON.parse(response.state) });
            } else {
                setFEparams(EMERGENCY_INITIAL_PARAMS);
            }
        });
    };

    /**
     * Ищем параметры в урле.
     */
    const findUrlParams = () => {
        const result: IUrlParams = {};
        const searchParams = new window.URLSearchParams(document.location.search);
        searchParams.forEach((value, key) => {
            result[key] = value;
            if (key === 'cloud_temp_hash') {
                getStateFromFrontExchange(value);
            } else if (key === 'token') {
                localStorage.setItem('xtoken', value);
            }
        });

        if (!result.hasOwnProperty('cloud_temp_hash')) {
            setFEparams(EMERGENCY_INITIAL_PARAMS);
        }

        if (!result.hasOwnProperty('token')) {
            const localToken = localStorage.getItem('xtoken');
            if (localToken) {
                result.token = localToken;
            } else {
                redirectToAuth(getUrls(hosts).AUTH_URL);
            }
        }

        // Если параметры в урле были, убираем их
        if (Object.keys(result).length) {
            window.history.pushState(
                'object or string',
                'Title',
                '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]
            );
        }

        setUrlParams(result);
    };

    /**
     * Редирект на сервер аутентификации.
     */
    const redirectToAuth = (authUrl: string): void => window.location.replace(`${authUrl}?next=${currentUrl}`);

    return result;
};

export default useUrl;
