import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../../../lib/esm/components';
import { TMode } from '../../interfaces';
import { Wrapper } from './styles/styles';
import './styles/styles.css';

interface IProps {
    changeModeHandler: (mode: TMode) => void;
    mode: TMode;
}

const Header: FC<IProps> = ({ mode, changeModeHandler }) => {
    const { t } = useTranslation();

    const onModeClick = (mode: TMode) => () => {
        changeModeHandler(mode);
    };

    return (
        <Wrapper>
            <span>{t('Show by')}:</span>
            <Button
                addClasses="border-right"
                theme={mode === 'years' ? undefined : 'outline'}
                onClick={onModeClick('years')}
                text={t('Years')}
                type="primary"
                size="sm"
            />
            <Button
                addClasses="border-left"
                theme={mode === 'months' ? undefined : 'outline'}
                onClick={onModeClick('months')}
                text={t('Months')}
                type="primary"
                size="sm"
            />
        </Wrapper>
    );
};

export default Header;
