/**
 * Типы кнопок
 */
export enum ButtonView {
    /**
     * Базовая кнопка
     */
    Base = 'default',
    /**
     * Главаня кнопка
     */
    Primary = 'primary',
    /**
     * Кнопка ввиде ссылки
     */
    Link = 'link',
    /**
     * Вторая кнопка
     */
    Secondary = 'secondary',
    /**
     * Подтверждающая кнопка
     */
    Success = 'success',
    /**
     * Кнопка предупреждающая об опасности
     */
    Danger  = 'danger',
    /**
     * Кнопка предупреждения
     */
    Warning = 'warning',
    /**
     * Кнопка особого внимания
     */
    Accent = 'accent'
}
