import {
    IDynamicsPMOReducerState,
    IExtendedReportingObject,
    IMetric,
    ISelectedChartType,
    TSelectedChartTypeId,
} from './interfaces';
import { IReportingObject, TLoadingData } from '../../../../../../../General.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IChartDetailing } from '../../../../../../../constants/chartDetailing';
import { RootState } from '../../../../../../../store';
import { cloneDeep } from 'lodash';

const availableChartTypes: ISelectedChartType[] = [
    { headerText: 'Periods', id: 'periods' },
    { headerText: 'Metrics', id: 'metrics' },
    // { headerText: 'Objects', id: 'objects' },
];

const initialState: IDynamicsPMOReducerState = {
    rawMetricsData: { status: 'Loading', message: 'Loading' },
    selectedChartType: availableChartTypes[0],
    selectedChartDetailing: null,
    extendedReportingObjects: [],
    shoudUpdateMetricsCounter: 0,
    availableChartDetailing: [],
    alignByDaysOfWeek: false,
    selectedObject: null,
    selectedMetric: null,
    extendedMetrics: [],
    availableChartTypes,
    dataRefetchObject: {},
};

const Leasing_TenantOverview_Dynamics_Widget_Reducer = createSlice({
    name: 'Leasing_TenantOverview_Dynamics_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Запсиь расширенной ифнормации об выбранных отчетных объектах
         */
        storeExtendedReportingObjects: (
            state,
            action: PayloadAction<{ reportingObjects: IReportingObject[]; selectedReportingObjectsIds: number[] }>,
        ) => {
            const { reportingObjects, selectedReportingObjectsIds } = action.payload;
            const { selectedObject, selectedChartType } = cloneDeep(state);
            const extendedReportingObjects = reportingObjects
                ?.filter((element) => selectedReportingObjectsIds.includes(element.id))
                .map((element) => ({ ...element, text: element.name }));

            const extendedReportingObjectsIds: (string | number)[] = extendedReportingObjects.map(
                (element) => element.id,
            );
            if (!extendedReportingObjectsIds.includes(selectedObject?.id || '')) {
                state.selectedObject = extendedReportingObjects[0];
            }

            if (selectedChartType.id === 'objects') {
                state.shoudUpdateMetricsCounter += 1;
            }

            state.extendedReportingObjects = extendedReportingObjects;
        },

        /**
         * Запсиь выбранной сущности (entity)
         */
        storeSelectedEntity: (state, action: PayloadAction<IExtendedReportingObject[] | null>) => {
            state.selectedObject = action.payload ? action.payload[0] : null;
        },

        /**
         * Запсиь выбранной метрики
         */
        storeSelectedMetric: (state, action: PayloadAction<IMetric[]>) => {
            state.selectedMetric = action.payload[0];
        },

        /**
         * Запись выбранной детализации для графика
         */
        storeSelectedChartDetailing: (state, action: PayloadAction<IChartDetailing[]>) => {
            state.selectedChartDetailing = action.payload[0];
        },

        /**
         * Запись расширенной информации об выбранных метрик
         */
        storeExtendedMetrics: (state, action: PayloadAction<{ selectedMetrics: string[]; allMetrics: IMetric[] }>) => {
            const { selectedMetric, selectedChartType } = cloneDeep(state);
            const { selectedMetrics, allMetrics } = action.payload;
            const extendedMetrics = allMetrics?.filter((element) => selectedMetrics.includes(element.id));

            const extendedMetricsIds = extendedMetrics.map((element) => element.id);
            if (!extendedMetricsIds.includes(selectedMetric?.id || '')) {
                state.selectedMetric = extendedMetrics[0];
            } else {
                const prevSelected = extendedMetrics?.find((element) => element.id === selectedMetric?.id);
                if (prevSelected) state.selectedMetric = prevSelected;
            }

            if (selectedChartType.id === 'metrics') {
                state.shoudUpdateMetricsCounter += 1;
            }
            state.extendedMetrics = extendedMetrics;
        },

        /**
         * Изменение флага сдвига по дням недели
         */
        toggleAlignByDaysOfWeek: (state, action: PayloadAction<boolean>) => {
            state.alignByDaysOfWeek = action.payload;
        },

        /**
         * Запись достпуной детализации графика (в зависимостри от периода)
         */
        storeAvailableChartDetailing: (state, action) => {
            const selectedChartDetailing = cloneDeep(state.selectedChartDetailing);
            const availableChartDetailingIds: string[] = action.payload.map((element: IChartDetailing) => element.id);
            const availableChartDetailingTexts: string[] = action.payload.map(
                (element: IChartDetailing) => element.text,
            );
            if (!availableChartDetailingIds.includes(selectedChartDetailing?.id || '')) {
                state.selectedChartDetailing = action.payload[0];
            }

            if (!availableChartDetailingTexts.includes(selectedChartDetailing?.text || '')) {
                state.selectedChartDetailing =
                    action.payload?.find((element: IChartDetailing) => selectedChartDetailing?.id === element.id) ||
                    action.payload[0];
            }
            state.availableChartDetailing = action.payload;
        },

        /**
         * Запись выбранного типа графика
         */
        storeSelectedChartType: (state, action: PayloadAction<TSelectedChartTypeId>) => {
            const currentChartType = availableChartTypes?.find((element) => element.id === action.payload);
            state.selectedChartType = currentChartType || availableChartTypes[0];
        },

        /**
         * Функция для увелечения значения счетчика, отвечающего за запрос метрик
         */
        increaseShoudUpdateMetricsCounter: (state) => {
            state.shoudUpdateMetricsCounter += 1;
        },

        /**
         * Запись сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TLoadingData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },

        /**
         * Обнуление редьюсера
         */
        resetDynamicsPMORedcuer: () => initialState,
    },
});

export const {
    increaseShoudUpdateMetricsCounter,
    storeExtendedReportingObjects,
    storeAvailableChartDetailing,
    storeSelectedChartDetailing,
    toggleAlignByDaysOfWeek,
    resetDynamicsPMORedcuer,
    storeSelectedChartType,
    storeExtendedMetrics,
    storeSelectedEntity,
    storeSelectedMetric,
    storeRawMetricsData,
    reloadWidget,
} = Leasing_TenantOverview_Dynamics_Widget_Reducer.actions;

export const Leasing_TenantOverview_Dynamics_Widget_Reducer_Values = (state: RootState) =>
    state.Leasing_TenantOverview_Dynamics_Widget_Reducer;

export default Leasing_TenantOverview_Dynamics_Widget_Reducer.reducer;
