import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import React, { useRef } from 'react';
import cn from 'classnames';
import * as constants from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';
import { BoxWrapper } from './styles/styles.js';

var Box = function (_a) {
    var border = _a.border, width = _a.width, height = _a.height, classString = _a.classString, props = __rest(_a, ["border", "width", "height", "classString"]);
    var ref = useRef(null);
    var mergedStyles = useStyles({ ref: ref, props: props, constants: constants });
    var className = React.useMemo(function () {
        return cn(__spreadArray([classString], mergedStyles, true));
    }, [props]);
    return (jsx(BoxWrapper, __assign({ ref: ref, width: width, height: height, border: border, className: className }, { children: props.children }), void 0));
};

export { Box as default };
