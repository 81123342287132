import filterTenantValidZone from './filterTenantValidZone';
import filterZoneValidFloors from './filterZoneValidFloors';
import {
    ITenant2ZoneRelation,
    IReportingObject,
    IMsDataObject,
    IPeriod,
} from '../../../../../../../../General.interfaces';
import { cloneDeep } from 'lodash';

interface IArgs {
    reportingObjectsById: { [reportingObjectId: string]: IReportingObject };
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    tenant2ZoneRelations: ITenant2ZoneRelation[];
    selectedReportingObjectsIds: number[];
    msDataObjects: IMsDataObject[];
    selectedGroupMarker: string;
    dateRanges: IPeriod[];
    period: IPeriod;
}

/**
 * Функция для получения дополнительных id для запроса метрик отчетных объектов
 * @param reportingObjectsByTypeAndMarker Объект отчетных объектов где ключ это [тип:маркер]
 * @param selectedReportingObjectsIds id выбранных отчетных объектов
 * @param tenant2ZoneRelations Взаимосвязь арендатора и зоны
 * @param reportingObjectsById Отчетные объекты, где ключ это id отчетного объекта
 * @param selectedGroupMarker Выбранный маркер группы
 * @param msDataObjects Данные для отчетных объектов
 * @param dateRanges Периоды дат для главного периода
 * @param period Главный период
 */
const getAdditionalIdsForMetricsRequest = (args: IArgs): number[] => {
    const {
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectsIds,
        tenant2ZoneRelations,
        reportingObjectsById,
        selectedGroupMarker,
        msDataObjects,
        dateRanges,
        period,
    } = args;
    const ids = cloneDeep(selectedReportingObjectsIds);

    const locationId = reportingObjectsByTypeAndMarker['location:location']?.id;
    locationId && ids.push(locationId);

    selectedReportingObjectsIds.forEach((id) => {
        const reportingObject = reportingObjectsById[id];

        if (reportingObject) {
            switch (reportingObject.object_type) {
                case 'tenant':
                    const validZone = filterTenantValidZone({
                        reportingObjectsByTypeAndMarker,
                        mainDateRanges: dateRanges,
                        tenant: reportingObject,
                        tenant2ZoneRelations,
                        selectedGroupMarker,
                        mainPeriod: period,
                    });

                    if (validZone) {
                        ids.push(validZone.id);
                        const validFloors = filterZoneValidFloors({
                            reportingObjectsByTypeAndMarker,
                            zoneMarker: validZone.marker,
                            mainDateRanges: dateRanges,
                            mainPeriod: period,
                            msDataObjects,
                        });

                        if (validFloors) validFloors.forEach((element) => ids.push(element.id));
                    }

                    break;

                case 'zone': {
                    ids.push(reportingObject.id);

                    const validFloors = filterZoneValidFloors({
                        zoneMarker: reportingObject.marker,
                        reportingObjectsByTypeAndMarker,
                        mainDateRanges: dateRanges,
                        mainPeriod: period,
                        msDataObjects,
                    });

                    if (validFloors) validFloors.forEach((element) => ids.push(element.id));

                    break;
                }

                case 'floor':
                    ids.push(reportingObject.id);

                    break;

                default:
                    break;
            }
        }
    });

    return Array.from(new Set(ids));
};

export default getAdditionalIdsForMetricsRequest;
