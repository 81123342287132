import { TFunction } from 'i18next';

export interface ITenantsReportAdditionalFieldOption {
    id: string;
    text: string;
    structure_type: string;
    columnName: string;
}

export enum AdditionalFieldsIds {
    Id = 'Id',
    Place = 'Place',
    Zone = 'Zone',
    Floor = 'Floor',
    TimeZone = 'TimeZone',
    WeekDay = 'WeekDay',
}

export const tenantsReportAdditionalFieldsForGridData = [
    {
        id: AdditionalFieldsIds.Place,
        text: 'Place',
        structure_type: 'relations_tenant2place',
    },
];

export const tenantsReportAdditionalFieldsForRequest = [
    {
        id: AdditionalFieldsIds.Place,
        text: 'Place',
        structure_type: 'relations_tenant2place',
        columnName: 'Place',
    },
    {
        id: 'Place2Zone',
        text: 'Place to Zone',
        structure_type: 'relations_place2zone',
        columnName: 'Place to Zone',
    },
];

export const tenantsReportAdditionalFieldsOptions: ITenantsReportAdditionalFieldOption[] = [
    {
        id: AdditionalFieldsIds.Id,
        text: 'Add id',
        structure_type: '',
        columnName: 'Id',
    },
    {
        id: AdditionalFieldsIds.TimeZone,
        text: 'Add time zone',
        structure_type: '',
        columnName: 'Time zone',
    },
    {
        id: AdditionalFieldsIds.WeekDay,
        text: 'Add week day',
        structure_type: '',
        columnName: 'Week day',
    },
    {
        id: AdditionalFieldsIds.Place,
        text: 'Add place',
        structure_type: 'relations_tenant2place',
        columnName: 'Place',
    },
    {
        id: AdditionalFieldsIds.Zone,
        text: 'Add zone',
        structure_type: 'relations_tenant2zone',
        columnName: 'Zone',
    },
    {
        id: AdditionalFieldsIds.Floor,
        text: 'Add floor',
        structure_type: 'relations_tenant2floor',
        columnName: 'Floor',
    },
    // {
    //     id: 'Category',
    //     text: 'Category',
    // },
    // {
    //     id: 'Subcategory',
    //     text: 'Subcategory',
    // },
    // {
    //     id: 'Buildings',
    //     text: 'Buildings',
    // },
    // {
    //     id: 'Price segment',
    //     text: 'Price segment',
    // },
];

export const tenantsReportAdditionalFieldsOptionsById: { [fieldId: string]: ITenantsReportAdditionalFieldOption } =
    tenantsReportAdditionalFieldsOptions.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
    }, {});
