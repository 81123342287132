import React from 'react';
import { Title } from '../../../../../../lib/esm/components';
import { useTranslation } from 'react-i18next';
import WrapperContainer from '../../../../Wrappers/WrapperContainer/WrapperContainer';
import FaqImage from '../../image/faq.svg';
import {
    AbsoluteImage,
    BackgroundHeaderContainer,
    BackgroundHeaderContentContainer,
    HeaderDescription,
} from './styles';

/**
 * Компонент элемента Header с фоном и картинкой
 */
const BackgroundHeader: React.FC<{ title: string; description?: string }> = ({ title = '', description = '' }) => {
    const { t } = useTranslation('translationHelp');
    return (
        <BackgroundHeaderContainer>
            <WrapperContainer>
                <BackgroundHeaderContentContainer color={'white'}>
                    <Title titleLevel="h1">{t(title)}</Title>
                    <HeaderDescription>
                        <p>{t(description)}</p>
                    </HeaderDescription>
                    <AbsoluteImage src={FaqImage} />
                </BackgroundHeaderContentContainer>
            </WrapperContainer>
        </BackgroundHeaderContainer>
    );
};

export default BackgroundHeader;
