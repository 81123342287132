import _, { xor } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Title, Button, Icon, Modals } from '../../../../../../../../../../../lib/esm/components';
import {
    Controls,
    OptionWrapper,
    RatingBody,
    RatingCard,
    RatingCardItem,
    RatingHat,
    RatingHatFiller,
    RatingHeader,
    RatingTable,
    RatingTableTD,
    RatingTableTH,
} from './style';
import cn from 'classnames';
import { IRating, IRatingDataItem } from '../../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { Performance_Ratings_RatingList_Widget_Reducer_Values, storeEditRatingId } from '../../../../reducer';
import LoadingBox from '../../../../../../../../../LoadingBox/LoadingBox';
import { generalReducerValues } from '../../../../../../../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import ContextualMenu from '../../../../../../../../../ContextualMenu/ContexttualMenu';
import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import { useRerender } from '../../../../../../../../../../../tools/useRerender';
import ModalDeleteRating from '../../../ModalDeleteRating/ModalDeleteRating';
import WidgetAdditionalControls from '../../../../../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const RatingItem: React.FC<{ rating: IRating }> = ({ rating }) => {
    const { ratingsData } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const ref = useRef(null);
    const [contextMenuStatus, setContextMenuStatus] = useState(false);
    const [ratingItemLoading, setRatingItemsLoading] = useState(true);
    const targetRef = useRef<HTMLDivElement>(null);
    const [ratingCollapse, setRatingCollapse] = useState(false);
    const [modalRemoveStatus, setModalRemoveStatus] = useState({ show: false });
    const metricName: string | undefined = allMetrics?.find((x) => x.id === rating.metric)?.text;
    const rerender = useRerender(JSON.stringify([ratingsData, rating]));
    useEffect(() => {
        const ratingsDataItem = ratingsData?.find((x) => Number(x.id) === rating.id);
        if (ratingsDataItem && !ratingsDataItem.error) {
            setRatingItemsLoading(false);
        } else {
            setRatingItemsLoading(true);
        }
    }, [ratingsData]);

    const ratingHatClass = cn('rating-hat', {
        positive: rating.sort_method === 'asc',
        negative: rating.sort_method === 'desc',
    });

    const handleRemoveModalClose = () => {
        setModalRemoveStatus({ show: false });
    };
    const dispatch = useDispatch();

    const closeContextualMenu = () => {
        setContextMenuStatus(false);
    };

    const toggleDropdown = () => {
        setContextMenuStatus((prevState) => !prevState);
    };

    const removeRating = () => {
        setModalRemoveStatus({ show: true });
        closeContextualMenu();
    };

    const editRating = () => {
        if (rating.id) {
            dispatch(storeEditRatingId(rating.id));
        }
        closeContextualMenu();
    };

    const ratingCollapseControll = (status: boolean) => () => {
        setRatingCollapse(status);
        closeContextualMenu();
    };
    const ratingItems = ratingsData?.find((x) => Number(x.id) === rating.id);
    const ratingObjectsCount: number = ratingItems?.objects ? ratingItems?.objects.length : 0;
    const tableBody = useMemo(() => {
        let arrayForSort: IRatingDataItem[] = [];

        if (ratingItems) {
            if (rating.sort_method === 'desc') {
                arrayForSort = _.cloneDeep(ratingItems.objects);
                if (rating.main_column === 'absolute') {
                    arrayForSort.sort((a, b) => a.values.value - b.values.value);
                } else {
                    let indicator = rating.main_column ? rating.main_column : 'value';
                    arrayForSort.sort((a, b) => a.values[indicator] - b.values[indicator]);
                }
            } else {
                arrayForSort = _.cloneDeep(ratingItems.objects);
                if (rating.main_column === 'absolute') {
                    arrayForSort.sort((a, b) => b.values.value - a.values.value);
                } else {
                    let indicator = rating.main_column ? rating.main_column : 'value';
                    arrayForSort.sort((a, b) => b.values[indicator] - a.values[indicator]);
                }
            }
        }

        return arrayForSort.map((obj, index) => {
            return (
                <tr key={obj.id}>
                    <RatingTableTD>{index + 1}</RatingTableTD>
                    <RatingTableTD>{obj.name}</RatingTableTD>
                    {rating.additional_columns?.find((x) => x.id === 'area')?.checked ? (
                        <RatingTableTD>{numberWithSpaces(obj.values.area)}</RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.additional_columns?.find((x) => x.id === 'delta')?.checked ? (
                        <RatingTableTD>{obj.values.delta ? numberWithSpaces(obj.values.delta) : '—'}</RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.additional_columns?.find((x) => x.id === 'deviation')?.checked ? (
                        <RatingTableTD>
                            {obj.values.deviation ? numberWithSpaces(obj.values.deviation) : '—'}
                        </RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.additional_columns?.find((x) => x.id === 'absolute')?.checked ? (
                        <RatingTableTD>{obj.values.value ? numberWithSpaces(obj.values.value) : '—'}</RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.main_column === 'absolute' ? (
                        <RatingTableTD>{numberWithSpaces(obj.values.value)}</RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.main_column === 'delta' ? (
                        <RatingTableTD>{obj.values.delta ? numberWithSpaces(obj.values.delta) : '—'}</RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.main_column === 'deviation' ? (
                        <RatingTableTD>
                            {obj.values.deviation ? numberWithSpaces(obj.values.deviation) : '—'}
                        </RatingTableTD>
                    ) : (
                        ''
                    )}
                    {rating.main_column === 'area' ? (
                        <RatingTableTD>{obj.values.area ? numberWithSpaces(obj.values.area) : '—'}</RatingTableTD>
                    ) : (
                        ''
                    )}
                </tr>
            );
        });
    }, [ratingsData, rating]);

    return (
        <RatingCardItem ref={ref}>
            <RatingHat className={ratingHatClass}>
                <RatingHatFiller></RatingHatFiller>
            </RatingHat>
            <RatingCard>
                <RatingHeader>
                    <Title titleLevel="h3">{rating.name}</Title>
                    <Controls>
                        <WidgetAdditionalControls
                            widgetName="Ratings"
                            pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        />
                        <div onClick={toggleDropdown} ref={targetRef}>
                            <Button theme="icon" type="secondary">
                                <>
                                    <Icon size="xs" type="dots" color="secondary-dark" />
                                </>
                            </Button>
                            <ContextualMenu
                                isContextualMenuOpen={contextMenuStatus}
                                hideContextualMenu={closeContextualMenu}
                                gapSpace={10}
                                targetRef={targetRef}
                                CustomMenuComponent={
                                    <div>
                                        {!ratingCollapse && ratingObjectsCount >= 7 ? (
                                            <OptionWrapper onClick={ratingCollapseControll(true)}>
                                                {t('Expand Rating')}
                                            </OptionWrapper>
                                        ) : (
                                            ''
                                        )}
                                        {ratingCollapse && ratingObjectsCount >= 7 ? (
                                            <OptionWrapper onClick={ratingCollapseControll(false)}>
                                                {t('Collapse rating')}
                                            </OptionWrapper>
                                        ) : (
                                            ''
                                        )}

                                        <OptionWrapper onClick={editRating}>{t('Edit')}</OptionWrapper>
                                        <OptionWrapper onClick={removeRating}>{t('Remove')}</OptionWrapper>
                                    </div>
                                }
                            />
                        </div>
                    </Controls>
                </RatingHeader>
                <RatingBody className={ratingCollapse ? 'rating-body--collapse' : ''}>
                    <RatingTable>
                        <thead>
                            <tr>
                                <RatingTableTH colSpan={2}>
                                    {t(
                                        `${String(rating.dataobj_type)[0].toUpperCase()}${rating.dataobj_type.slice(
                                            1,
                                        )}`,
                                    )}
                                </RatingTableTH>
                                {rating.additional_columns?.find((x) => x.id === 'area')?.checked ? (
                                    <RatingTableTH>{t('Area')}, m² </RatingTableTH>
                                ) : (
                                    ''
                                )}
                                {rating.additional_columns?.find((x) => x.id === 'delta')?.checked ? (
                                    <RatingTableTH>{t('Delta')} </RatingTableTH>
                                ) : (
                                    ''
                                )}
                                {rating.additional_columns?.find((x) => x.id === 'deviation')?.checked ? (
                                    <RatingTableTH>{t('Deviation')} %</RatingTableTH>
                                ) : (
                                    ''
                                )}
                                {rating.additional_columns?.find((x) => x.id === 'absolute')?.checked ? (
                                    <RatingTableTH>{metricName !== undefined ? t(metricName) : ''}</RatingTableTH>
                                ) : (
                                    ''
                                )}
                                {rating.main_column === 'absolute' ? (
                                    <RatingTableTH>{metricName !== undefined ? t(metricName) : ''}</RatingTableTH>
                                ) : (
                                    ''
                                )}
                                {rating.main_column === 'delta' ? <RatingTableTH>{t('Delta')} </RatingTableTH> : ''}
                                {rating.main_column === 'deviation' ? (
                                    <RatingTableTH>{t('Deviation')} </RatingTableTH>
                                ) : (
                                    ''
                                )}
                                {rating.main_column === 'area' ? <RatingTableTH>{t('Area')}, m² </RatingTableTH> : ''}
                            </tr>
                        </thead>
                        <tbody>{tableBody}</tbody>
                    </RatingTable>
                    {ratingItemLoading ? (
                        <LoadingBox
                            height={250}
                            text={ratingItems?.error?.message ? ratingItems?.error?.message : t('Loading')}
                            isError={_.isObject(ratingItems?.error) ? true : false}
                        ></LoadingBox>
                    ) : (
                        ''
                    )}
                </RatingBody>
            </RatingCard>
            <Modals
                title={t('Delete rating')}
                closeModal={handleRemoveModalClose}
                modalStatus={modalRemoveStatus}
                topColor="fff"
            >
                <ModalDeleteRating ratingId={rating.id} closeModal={handleRemoveModalClose}></ModalDeleteRating>
            </Modals>
        </RatingCardItem>
    );
};
export default RatingItem;
