import { IRequestMetricsArgs } from '../../../../../../../../lib/esm/components/';
import { VISITORS_INSIDE_METRIC } from '../constants/constants';
import { IGenerateQueryDataArgs } from './interfaces';

/**
 * Функция генерации данных для запрса метрик
 * @param extendedReportingObjectsById объект расширенных отчетных объектов
 * @param selectedReportingObjectsIds массив выбранных id отчетных отбъектов
 * @param shoudUpdateChartsByPeriod флаг, отвечающий за обновление графиков, при смене периодов
 * @param compareDateRanges массив дат для периодов сравнения
 * @param mainDateRanges массив дат для главного периода
 * @param metricLevel Уровень для получения метрик
 * @param mainPeriod главный период
 * @param token токен авторизации
 * @returns массив данными, необходимыми для запроса метрик
 */
const generateQueryData = (args: IGenerateQueryDataArgs): IRequestMetricsArgs[] => {
    const {
        extendedReportingObjectsById,
        selectedReportingObjectsIds,
        shoudUpdateChartsByPeriod,
        compareDateRanges,
        comparePeriods,
        mainDateRanges,
        metricLevel,
        mainPeriod,
        token,
    } = args;

    const reportingObjectsIds = shoudUpdateChartsByPeriod
        ? selectedReportingObjectsIds
        : Object.keys(extendedReportingObjectsById)
              .map((element) => +element)
              ?.filter((element) => !!!extendedReportingObjectsById[element].chartOptionsGeneratorSettings);
    const queryData: IRequestMetricsArgs[] = [];

    const comparePeriodsIds = comparePeriods.map((element) => element.id);
    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod?.id);
    const selectedCompareDateRanges = compareDateRanges?.filter((element) => comparePeriodsIds.includes(element.id));

    if (mainPeriodDateRnage && reportingObjectsIds.length) {
        selectedCompareDateRanges.unshift(mainPeriodDateRnage);

        selectedCompareDateRanges.forEach((element) => {
            const compareDate = [element.period.dateFrom, element.period.dateTo];
            queryData.push({
                metric: VISITORS_INSIDE_METRIC,
                obj_ids: reportingObjectsIds,
                object_aggregation: false,
                metric_level: metricLevel,
                time_range: compareDate,
                time_freq: '15MIN',
                alias: null,
                token,
            });
        });
    }
    return queryData;
};

export default generateQueryData;
