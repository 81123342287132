import { PivotItem } from '@fluentui/react';
import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, reloadCategories } from '../../../../../../../General.reducer';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Configuration_Categories_Widget_Reducer_Values, storeChapter } from './reducer';
import { WidgetWrapper } from './styles';
import { useRerender } from '../../../../../../../tools/useRerender';
import Table from './components/Table/Table';
import Tree from './components/Tree/Tree';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const Categories: React.FC = ({}) => {
    const ref = useRef(null);
    const { chapter } = useSelector(Configuration_Categories_Widget_Reducer_Values);
    const {
        src: { categories },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const rerender = useRerender(categories);

    const pivotItems = [
        {
            itemKey: 'table',
            headerText: t('Table'),
        },
        {
            itemKey: 'projectCategories',
            headerText: t('Project categories'),
        },
    ];

    const onLinkClick = (item: PivotItem) => {
        item.props.itemKey && dispatch(storeChapter(item.props.itemKey));
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Summary table of categories')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={'Summary table of categories'}
                        // reloadHandler={reloadCategories}
                    />
                </WidgetTitle>
                <Pivot widgetName="SummaryTableOfCategories" onLinkClick={onLinkClick} selectedKey={chapter}>
                    {pivotItems.map((element) => (
                        <PivotItem
                            key={element.itemKey + Math.random()}
                            headerText={t(element.headerText)}
                            itemKey={element.itemKey}
                        />
                    ))}
                </Pivot>
            </WidgetTitleWrapper>
            {chapter === 'table' && <Table />}
            {chapter === 'projectCategories' && <Tree />}
        </WidgetWrapper>
    );
};

export default Categories;
