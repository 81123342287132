import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../store';
import { IColorsByMarker, IReducerState } from './interfaces';

import { ILayer, IPlan, IReportingObject, IVersion, TLoadingData } from '../../../../../General.interfaces';

const initialState: IReducerState = {
    plans: [],
    versions: [],
    layers: [],
    selectedMarker: null,
    overMarker: null,
    selectedVersionId: null,
    selectedRelationId: 'all',
    showComparison: false,
    selectedObjectType: 'tenant',
    zonesByGroup: {},
    selectedGroupId: null,
    selectedZoneId: null,
    selectedPlaceId: null,
    metricsData: { message: 'Loading', status: 'Loading' },
    colorsByMarker: null,
};

export const Maps_MapsOverview_Module_Reducer = createSlice({
    name: 'Maps_MapsOverview_Module_Reducer',
    initialState,

    reducers: {
        storePlans: (state, action: PayloadAction<IPlan[] | { status: string; message?: string; alias?: string }>) => {
            state.plans = action.payload;
        },
        storeVersions: (state, action: PayloadAction<IVersion[]>) => {
            state.versions = action.payload;
        },
        storeLayers: (state, action: PayloadAction<ILayer[]>) => {
            state.layers = action.payload;
        },
        storeSelectedMarker: (state, action: PayloadAction<string | null>) => {
            state.selectedMarker = action.payload;
        },
        storeOverMarker: (state, action: PayloadAction<string | null>) => {
            state.overMarker = action.payload;
        },
        storeObjectsType: (state, action: PayloadAction<string>) => {
            state.selectedMarker = null;
            state.selectedObjectType = action.payload;
        },
        storeZonesByGroup: (state, action: PayloadAction<{ [x: string]: IReportingObject[] }>) => {
            state.zonesByGroup = action.payload;
        },
        storeSelectedGroup: (state, action: PayloadAction<string | null>) => {
            state.selectedGroupId = action.payload;
        },
        storeSelectedZoneId: (state, action: PayloadAction<string | null>) => {
            state.selectedZoneId = action.payload;
        },
        storeSelectedPlaceId: (state, action: PayloadAction<number | null>) => {
            state.selectedPlaceId = action.payload;
        },
        storeInitialData: (state, action: PayloadAction<TLoadingData>) => {
            state.metricsData = action.payload;
        },
        storeColorData: (state, action: PayloadAction<IColorsByMarker | null>) => {
            state.colorsByMarker = action.payload;
        },
        storeShowComparison: (state, action: PayloadAction<boolean>) => {
            state.showComparison = action.payload;
        },
        storeSelectedVersionId: (state, action: PayloadAction<number>) => {
            state.selectedVersionId = action.payload;
        },
        storeSelectedRelationId: (state, action: PayloadAction<string>) => {
            state.selectedRelationId = action.payload;
        },
    },
});

export const {
    storePlans,
    storeSelectedVersionId,
    storeSelectedRelationId,
    storeSelectedPlaceId,
    storeOverMarker,
    storeVersions,
    storeLayers,
    storeSelectedMarker,
    storeObjectsType,
    storeZonesByGroup,
    storeShowComparison,
    storeInitialData,
    storeSelectedGroup,
    storeColorData,
    storeSelectedZoneId,
} = Maps_MapsOverview_Module_Reducer.actions;

export const Maps_MapsOverview_Module_Reducer_Values = (state: RootState) => state.Maps_MapsOverview_Module_Reducer;

export default Maps_MapsOverview_Module_Reducer.reducer;
