import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import {
    Performance_Dashboard_ByArea_Widget_Reducer_Values,
    storeTreeMapData,
    storeResponseResult,
    storeObjectsType,
    resetReducer,
} from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { usePrepareData } from './usePrepareData';
import { useTranslation } from 'react-i18next';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { useGetObjectMetric } from './useFetchObjectMetric';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    usePrepareData();
    useGetObjectMetric();
    const {
        token,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { comparePeriod, colorMetric, areaMetric, objectsType, dataRefetchObject } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(storeResponseResult({ status: 'Loading', message: t('Loading...') }));

        if (!localCurrentOptions) return;

        const controller = new AbortController();
        const signal = controller.signal;

        if (
            objectsType &&
            reportingObjectsByType?.[objectsType] &&
            comparePeriod &&
            localCurrentOptions.mainPeriod?.id &&
            localCurrentOptions.compareDateRanges &&
            localCurrentOptions.mainDateRanges
        ) {
            const obj_ids = reportingObjectsByType[objectsType].map((item) => item.id);
            const mainPeriod = localCurrentOptions.mainPeriod?.id;
            const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriod);
            const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter(
                (item) => item.id === comparePeriod.id,
            );
            if (!compareDateRange) return;

            const mainTimeRange = [mainDateRange.period.dateFrom, mainDateRange.period.dateTo];
            const compareTimeRange = [compareDateRange.period.dateFrom, compareDateRange.period.dateTo];

            const areaRequest = {
                signal,
                token,
                alias: '-:areaMetric',
                metric: areaMetric,
                metric_level: metricLevel,
                obj_ids,
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            const metricMainRequest = { ...areaRequest, metric: colorMetric, alias: 'main:colorMetric' };
            const metricCompareRequest = {
                ...metricMainRequest,
                time_range: compareTimeRange,
                alias: 'compare:colorMetric',
            };

            fetchData([areaRequest, metricMainRequest, metricCompareRequest])
                .then((res: TServerResponse) => {
                    handleServerResponse({
                        responseAnalyzer: responseAnalyzer,
                        success: storeResponseResult,
                        error: storeResponseResult,
                        dispatch,
                        res,
                    });
                })
                .catch((e: any) => {});
        } else {
            dispatch(storeTreeMapData([]));
            handleServerResponse({
                responseAnalyzer: responseAnalyzer,
                success: storeResponseResult,
                error: storeResponseResult,
                dispatch,
                res: [],
            });
        }
        return () => {
            controller.abort();
        };
    }, [
        objectsType,
        reportingObjectsByType,
        localCurrentOptions?.mainPeriod?.id,
        comparePeriod,
        colorMetric,
        areaMetric,
        dataRefetchObject,
    ]);
};
