import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';

var requestObjectMetrics = function (args, url) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, requests, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = {
                    'Content-Type': 'application/json',
                };
                requests = args.map(function (request) {
                    var signal = request.signal, token = request.token, alias = request.alias, metric = request.metric, metric_level = request.metric_level, obj_ids = request.obj_ids, object_aggregation = request.object_aggregation, time_range = request.time_range, time_freq = request.time_freq, metric_params = request.metric_params;
                    if (!obj_ids.length)
                        return;
                    return axios({
                        method: 'POST',
                        headers: headers,
                        signal: signal,
                        url: url || 'https://lambda.focustech.xyz/core-data-provider/v1/metric_dynamics',
                        data: JSON.stringify({
                            auth: {
                                xtoken: token,
                            },
                            input_parameters: {
                                metric_params: metric_params,
                                alias: alias,
                                metric: metric,
                                metric_level: metric_level,
                                obj_ids: obj_ids,
                                object_aggregation: object_aggregation,
                                time_range: time_range,
                                time_freq: time_freq,
                            },
                        }),
                    });
                });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.all(requests).then(axios.spread(function () {
                        var res = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            res[_i] = arguments[_i];
                        }
                        return res;
                    }))];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, err_1];
            case 4: return [2 /*return*/];
        }
    });
}); };

export { requestObjectMetrics as default };
