import styled from 'styled-components/macro';

export const MainWrapper = styled.div`
    margin-top: var(--space-sm);
    /* .ms-Pivot {
        display: inline-block;
    } */
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray);
    margin-bottom: var(--space-sm);
`;

export const PivotWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--space-xs);
    color: #6e6e6e;
    margin-top: var(--space-sm);
`;

export const ChartWrapper = styled.div`
    margin-top: var(--space-sm);
`;

export const CheckboxWrapper = styled.div`
    margin-bottom: var(--text-base-size);
`;
