import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { MainWrapper } from './styles';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { useRerender } from '../../../../../../../tools/useRerender';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import { Cases_Calculator_Calculator_Widget_Reducer_Values, storeChartMode } from './reducer';
import MainSelects from './components/MainSelects/MainSelects';
import CalculatorSelects from './components/CalculatorSelects/CalculatorSelects';
import MainTable from './components/MainTable/MainTable';
import ColumnChart from './components/ColumnChart/ColumnChart';
import { PivotItem } from '@fluentui/react';
import DiscountTable from './components/DiccountTable/DiscountTable';
import FinalTable from './components/FinalTable/FinalTable';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Комопнент для отображения графиков динамики по периодам, метрикам и объектам
 */
const CalculatorWidget: React.FC = ({}) => {
    const ref = useRef(null);

    const { moduleName, chartMode } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const rerender = useRerender(localCurrentOptions);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const rent: string = localCurrentOptions?.['rentInMonth'];
    const showData = Number(rent) === 800000;
    const tenantName = localCurrentOptions?.['tenant']?.[0]?.text;
    const tenantArea = tenantName === 'Henderson' ? 205 : 250;
    const tenantCategory = tenantName === 'Henderson' ? 'Одежда' : 'Фудкорт';

    const pivotItems = [
        {
            itemKey: '2022',
            headerText: t('2022'),
        },
        {
            itemKey: 'Dev. 22/21',
            headerText: `${t('Dev.')} 22/21`,
        },
        {
            itemKey: 'Dev. 22/19',
            headerText: `${t('Dev.')} 22/19`,
        },
    ];

    const onLinkClick = (item: PivotItem) => {
        item.props.itemKey && dispatch(storeChartMode(item.props.itemKey));
    };

    return (
        <MainWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {`${tenantName}: ${tenantArea} m², категория: ${tenantCategory}`}

                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={`${t('Calculator')}: ${tenantName}: ??? m²`}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <MainSelects title={''} />
            <MainTable />
            <WidgetTitleWrapper>
                <WidgetTitle>{t('Dynamics by periods, main metrics')}</WidgetTitle>
                <Pivot onLinkClick={onLinkClick} selectedKey={chartMode}>
                    {pivotItems.map((element) => (
                        <PivotItem
                            key={element.itemKey + Math.random()}
                            headerText={t(element.headerText)}
                            itemKey={element.itemKey}
                        />
                    ))}
                </Pivot>
            </WidgetTitleWrapper>
            <ColumnChart />
            <WidgetTitleWrapper>
                <WidgetTitle>{`${tenantName}: ${t('discount calculation')}`}</WidgetTitle>
            </WidgetTitleWrapper>
            <CalculatorSelects title={''} />
            <DiscountTable />
            <FinalTable />
        </MainWrapper>
    );
};

export default CalculatorWidget;
