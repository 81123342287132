import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { storePreraredData, Events_Events_Summary_Widget_Reducer_Values } from '../reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';

export const usePrepareData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { responseResult } = useSelector(Events_Events_Summary_Widget_Reducer_Values);

    useEffect(() => {
        if (!responseResult || responseResult?.length === 0) return;

        const byObjectId = {};

        // Создаем удобный объект
        responseResult.forEach((res) => {
            res.forEach((data) => {
                const objId = data.context.data_objects[0].id;
                const metric = data.context.metric;
                const period = data.context.alias?.split(':')[0];
                const value = data.items[0].value;
                if (!byObjectId.hasOwnProperty(objId)) {
                    byObjectId[objId] = {};
                }
                if (!byObjectId[objId].hasOwnProperty(metric)) {
                    byObjectId[objId][metric] = {};
                }
                byObjectId[objId][metric][period] = { value };
            });
        });

        // Пробегаемся по объекту и добавляем проценты
        Object.values(byObjectId).forEach((id: { [x: string]: { [x: string]: { value: number } } }) => {
            Object.values(id).forEach((metric) => {
                const mainValue = metric['main'].value;
                Object.keys(metric).forEach((key) => {
                    const percent = ((mainValue / metric[key].value - 1) * 100).toFixed(2);
                    if (key !== 'main') {
                        metric[key]['percent'] = percent;
                    }
                });
            });
        });

        // Делаем массив из объекта
        const preparedData = Object.keys(byObjectId).map((objId) => {
            return { data: byObjectId[objId], objId };
        });

        dispatch(storePreraredData(preparedData));
    }, [responseResult, reportingObjectsByType, dispatch]);
};
