import { Stack } from '@fluentui/react';
import { isNull, isNumber } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IMetric, IPeriod } from '../../../../../../../../../../../General.interfaces';
import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import { IMetricData } from '../../../../interfaces';
import { Td, Tr } from '../../styles';
import { ComparePeriodValue, PercentValueWrapper } from './styles';

interface IProps {
    /** Периоды сравнения */
    comparePeriodsData?: IPeriod[];
    /** Основной периоды */
    mainPeriodData?: IPeriod;
    /** Данные по метрикам */
    metricsData: IMetricData;
    /** Объект всех метрик, где id это id метрики */
    metricsById: { [id: string]: IMetric };
}

/**
 * Компонент для отображения тела таблицы
 */
const Body: FC<IProps> = ({ comparePeriodsData, metricsData, metricsById, mainPeriodData }) => {
    const { t } = useTranslation();
    return (
        <tbody>
            {Object.keys(metricsData).map((metricId) => {
                const units = metricsById[metricId]?.units;
                const isFetching = metricsData[metricId]?.mainPeriod[mainPeriodData?.id || '']?.isFetching;
                const isError = metricsData[metricId]?.mainPeriod[mainPeriodData?.id || '']?.isError;

                const value: number | null | undefined =
                    metricsData[metricId]?.mainPeriod[mainPeriodData?.id || '']?.data.items?.[0]?.value;
                return (
                    <Tr key={metricId}>
                        <Td>{t(metricsById?.[metricId]?.text)}</Td>
                        <Td fontWeight="600" textAlign="right">
                            {isFetching
                                ? t('Loading...')
                                : isError
                                ? t('Unable to retrive data')
                                : !isNull(value) && isNumber(value)
                                ? `${numberWithSpaces(value.toFixed(2))} ${t(units)}`
                                : '—'}
                        </Td>
                        {comparePeriodsData?.map((comparePeriod) => {
                            const isFetching = metricsData[metricId]?.comparePeriod[comparePeriod.id || '']?.isFetching;
                            const isError = metricsData[metricId]?.comparePeriod[comparePeriod.id || '']?.isError;

                            const value: number | null | undefined =
                                metricsData[metricId]?.comparePeriod[comparePeriod.id || '']?.data.items?.[0]?.value;

                            const percentageOfMain: number | null | undefined =
                                metricsData[metricId]?.comparePeriod[comparePeriod.id || '']?.percentageOfMain;
                            return (
                                <Td key={comparePeriod.id} padding="0">
                                    {isFetching ? (
                                        <ComparePeriodValue>{t('Loading...')}</ComparePeriodValue>
                                    ) : isError ? (
                                        <ComparePeriodValue>{t('Unable to retrive data')}</ComparePeriodValue>
                                    ) : (
                                        <Stack verticalFill={true} horizontal={true} verticalAlign="center">
                                            <PercentValueWrapper value={percentageOfMain}>
                                                {!isNull(percentageOfMain) && isNumber(percentageOfMain)
                                                    ? `${numberWithSpaces(percentageOfMain.toFixed(2))} %`
                                                    : '—'}
                                            </PercentValueWrapper>
                                            <ComparePeriodValue>
                                                {!isNull(value) && isNumber(value)
                                                    ? numberWithSpaces(value.toFixed(2))
                                                    : '—'}
                                            </ComparePeriodValue>
                                        </Stack>
                                    )}
                                </Td>
                            );
                        })}
                    </Tr>
                );
            })}
        </tbody>
    );
};

export default Body;
