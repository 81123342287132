import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { Leasing_TenantOverview_Summary_Widget_Reducer_Values, reloadWidget } from './reducer';
import { WidgetWrapper } from './styles';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { Stack } from '@fluentui/react';
import Table from './components/Table/Table';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета Summary
 */
const SummaryTable: React.FC = ({}) => {
    const { preparedData } = useSelector(Leasing_TenantOverview_Summary_Widget_Reducer_Values);
    const { allMetrics } = useSelector(generalReducerValues);
    const rerender = useRerender(preparedData);
    const { t } = useTranslation();
    const ref = useRef(null);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Summary')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidget}
                        widgetName={'Summary'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <Stack tokens={{ childrenGap: 10 }}>
                {Object.values(preparedData).map((element) => {
                    if (!element.reportingObject) {
                        return null;
                    }
                    return (
                        <Table key={element.reportingObject.id} reportingObjectData={element} allMetrics={allMetrics} />
                    );
                })}
            </Stack>
        </WidgetWrapper>
    );
};

export default SummaryTable;
