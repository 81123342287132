import React from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { LangValue, LangWrapper } from './styles';
import { IContextualMenuItem } from '@fluentui/react';
import { Props } from './configurations';
import { StyledContextualMenu } from '../../styles';
import { TLang } from '../../../SideBar/configurations';
/**
 * Компонент переключения языка
 */
const LangSelectMenu: React.FC<Props> = ({ lang, handleClickItem }) => {
    const linkRef = React.useRef(null);
    const [showContextualMenu, { setTrue: onShowContextualMenu, setFalse: onHideContextualMenu }] = useBoolean(false);
    
    const onClickItem = (lang: TLang) => {
        handleClickItem(lang);
    };

    /**
     * Конфигурация элементов переключения языка
     */
    const menuItems: IContextualMenuItem[] = [
        { 
            key: 'en', 
            text: 'En', 
            iconProps: { iconName: 'en' }, 
            onClick: () => onClickItem('en') 
        },
        {
            key: 'ru',
            text: 'Ru',
            iconProps: { iconName: 'ru' },
            onClick: () => onClickItem('ru')
        }
    ]

    return (
        <>
            <LangWrapper ref={linkRef} onClick={!showContextualMenu ? onShowContextualMenu : onHideContextualMenu}>
                <FontIcon iconName={lang} />
                <LangValue className="LangValue">{lang}</LangValue>
            </LangWrapper>
            <StyledContextualMenu
                items={menuItems}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </>
    );
};
export default LangSelectMenu;
