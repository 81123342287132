import { useState, useEffect } from 'react';

var useClasses = function (_a) {
    var addClasses = _a.styles.addClasses;
    var _b = useState([]), classes = _b[0], setClasses = _b[1];
    useEffect(function () {
        var classes = [];
        if (addClasses) {
            classes.push(addClasses);
        }
        setClasses(classes);
    }, [addClasses]);
    return classes;
};

export { useClasses as default };
