import { userSettingsReducerValues, storeUniversalOptions } from '../../UserSettings/reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DateRangePicker, Modals } from '../../../../lib/esm/components';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { IProps } from './interfaces';
import { Label, Wrapper } from './styles';
import { shortStringDate } from '../../../../tools/Strings/shortStringDate';

const UniversalDatePicker: React.FC<IProps> = ({ oneDayOnly = false, yearsOnly = false, labelText = ' ', name }) => {
    const [modalStatus, setModalStatus] = useState({ show: false });
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { lang, selectedLocationId, currentModule } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);

    const onClick = () => {
        setModalStatus({ show: true });
    };

    const text = currentOptions?.[name]?.[0]
        ? shortStringDate([currentOptions[name][0].text, currentOptions[name][1].text], lang)
        : t('Select date(s)');

    const handleDateRangeChange = (period: { dateFrom: string; dateTo: string }) => {
        selectedLocationId &&
            dispatch(
                storeUniversalOptions({
                    locationId: selectedLocationId,
                    module: currentModule,
                    selectedOptions: [
                        { id: 'dateFrom', text: period.dateFrom },
                        { id: 'dateTo', text: period.dateTo },
                    ],
                    name,
                }),
            );
        setModalStatus({ show: false });
    };

    return (
        <Wrapper>
            <Label>{labelText}</Label>
            <Button type="primary" theme="outline" text={text} onClick={onClick} icon="CalendarWeek" />
            <Modals title="" modalStatus={modalStatus}>
                <DateRangePicker
                    handleDateRangeChange={handleDateRangeChange}
                    oneDayOnly={oneDayOnly}
                    yearsOnly={yearsOnly}
                    lang={lang}
                />
            </Modals>
        </Wrapper>
    );
};

export default UniversalDatePicker;
