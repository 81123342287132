import { ILocation } from '../cabinet/components/SideBar/configurations';
import { getParameterByName } from './getParameterByName';

export const mlFromLocation = (location: ILocation | undefined) => {
    if (!location) return null;

    const mlId = getParameterByName('location_id', location?.map_service_link);

    return mlId;
};
