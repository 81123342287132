import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';
import { TLang } from '../../cabinet/components/SideBar/configurations';
import { IChartDetailing } from '../../constants/chartDetailing';
import getDateFormatByChartDetailing from './getDateFormatByChartDetailing';
import { stringDate } from './stringDate';

interface IArgs {
    selectedChartDetailing: IChartDetailing | null;
    dateInMillis: number;
    isCategory: boolean;
    t: TFunction;
    lang: TLang;
}

/**
 * Функция для получения даты
 * @param selectedChartDetailing Детализация графика
 * @param dateInMillis время в миллисекундах
 * @param isCategory флга категории (лейбла на оси x)
 * @param lang Язык приложения
 * @param t функция для перевода
 * @returns строку даты
 */
const datetimeFormatHelper = (args: IArgs): string => {
    const { selectedChartDetailing, isCategory, dateInMillis, lang, t } = args;
    if (selectedChartDetailing) {
        const dateFormat = getDateFormatByChartDetailing(selectedChartDetailing, isCategory, t);
        let date = stringDate(DateTime.fromMillis(dateInMillis).toISO(), lang, dateFormat.preset, dateFormat.token);
        return date;
    }
    return stringDate(DateTime.fromMillis(dateInMillis).toISO(), lang, '', 'MMM dd, yyyy');
};

export default datetimeFormatHelper;
