import axios from 'axios';
import { hosts, getUrls } from '../../constants/urls';

export const requestCurr = async (token: string) => {
    const headers = {
        'X-Token': token,
        'Content-type': 'application/json',
    };

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url: `${getUrls(hosts).CURRENT_URL}`,
        });

        return response;
    } catch (error) {
        return error;
    }
};
