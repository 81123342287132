import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import FiltersSelect from '../../../Filters/FiltersSelect/FiltersSelect';
import { ISettings } from '../../../UserSettings/interfaces';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import { OptionsWrapper } from './styles';
import Preset from '../../../Preset/Preset';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import TenantsWidget from './widgets/TenantsWidget/TenantsWidget';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../../../General.reducer';
import { getReportingObjects } from '../../../../../tools/getReportingObjects';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Комопонет для отображения модуля Leasing:Tenants
 */
const Tenants = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const validOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ComparePeriodSelect mode="single" />
                        <MetricsSelect />
                        <FiltersSelect label={t('Filters')} validOptions={validOptions} />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'mainPeriod',
                                'comparePeriods',
                                'selectedMetrics',
                                'filters' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <TenantsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Tenants;
