import { Stack } from '@fluentui/react';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../../../lib/esm/components';
import { EventsManagementContext } from '../../../../context';
import { ITableCellData } from '../../../../interfaces';
import { Wrapper } from './styles';

interface IProps {
    /** Данные ячейки таблицы */
    data: ITableCellData;
}

/**
 * Компонент для отображения элементов управления для каждого события
 */
const EventControls: FC<IProps> = ({ data }) => {
    const context = useContext(EventsManagementContext);
    const { t } = useTranslation();

    const onDelete = () => {
        context?.addDeletedRowToChangedDataTrans({ frontId: data.frontId });
    };

    const onClone = () => {
        context?.onCloneClickTrans(data.frontId);
    };

    return (
        <Wrapper>
            <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                <Button onClick={onClone}>{t('Clone')}</Button>
                <Button type="accent" onClick={onDelete}>
                    {t('Delete')}
                </Button>
            </Stack>
        </Wrapper>
    );
};

export default EventControls;
