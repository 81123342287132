import { getTheme } from '@fluentui/react';
import { PlotOptions, PlotTreemapOptions, PlotSeriesOptions } from 'highcharts';
import { set } from 'lodash';

const theme = getTheme();

export const treemapPlotOptions: PlotTreemapOptions = {
    layoutAlgorithm: 'strip',
    // animation: false,
    linecap: 'butt',
    cursor: 'pointer',
    color: 'rgba(255, 255, 255, 0.57)',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    borderWidth: 3,
    states: {
        select: {
            borderWidth: 2,
            borderColor: theme.palette.themePrimary,
        },
        hover: {
            borderWidth: 0,
            shadow: true,
            brightness: 0.1,
        },
    },
};

const series: PlotSeriesOptions = {
    allowPointSelect: true,
    stickyTracking: false,
    dataLabels: {
        padding: 8,
        style: {
            color: '000000',
            textOutline: 'none',
            fontSize: '12px',
        },
        verticalAlign: 'middle',
    },
};

// TODO Добавить в объект, когда в типе будет свойство borderRadius
set(series, 'borderRadius', 4);

export const plotOptions: PlotOptions = {
    treemap: treemapPlotOptions,
    series,
};
