import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues, changeAppBackendHost } from '../General.reducer';

export const useAppBackendHost = () => {
    const d = useDispatch();
    const { appBackendHost } = useSelector(generalReducerValues);

    useEffect(() => {
        const localAppBackendHost = localStorage.getItem('localAppBackendHost');
        if (localAppBackendHost && localAppBackendHost !== appBackendHost) {
            d(changeAppBackendHost(localAppBackendHost));
        } else if (!localAppBackendHost) {
            localStorage.setItem('localAppBackendHost', appBackendHost);
        }
    }, [appBackendHost]);
};
