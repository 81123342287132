import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { Leasing_TenantOverview_Info_Widget_Reducer_Values } from './reducer';
import { TableWrapper, WidgetWrapper } from './styles';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import Cell from './components/Cell';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import { usePrepareData } from './hooks/usePrepareData';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const Info: React.FC = ({}) => {
    const ref = useRef(null);
    usePrepareData();

    const { preparedData } = useSelector(Leasing_TenantOverview_Info_Widget_Reducer_Values);

    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);

    const {
        src: { reportingObjects },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const rerender = useRerender(preparedData);

    const tenantId = localCurrentOptions?.['tenants']?.[0]?.id;
    const tenant = reportingObjects?.find((item) => String(item.id) === String(tenantId));
    const tenantName = tenant?.name || '';
    const widgetName = `${tenantName}: ${t('Info')}`;

    const table = useMemo(() => {
        const result = Array.isArray(preparedData)
            ? preparedData?.map((item) => {
                  return <Cell data={item.data} name={item.name} key={item.key} />;
              })
            : null;

        return result;
    }, [JSON.stringify(preparedData)]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {widgetName}
                    <WidgetAdditionalControls
                        widgetName={widgetName}
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <TableWrapper>{table}</TableWrapper>
        </WidgetWrapper>
    );
};

export default Info;
