import { CrossVisitsMapsSettingsIds, ICrossVisitsSelectOption } from '../../constants/crossVisitsMapsSettings';
import { ILayer, IPlan } from '../../../../../../../General.interfaces';
import { IObject, TSelectedOptions } from '../../../../../../../lib/esm/components/Select/configurations';
import { MapsWrapper, SelectWrapper, StageWrapper, WidgetWrapper } from './styles';
import {
    Maps_CrossVisits_Widget_Reducer_Values,
    storeSelectedGroupOfZones,
    storeSelectedReportingObjectMarker,
    storeSelectedReportingObjectType,
} from './reducer';
import { memo, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AvailableReportingObjectsTypes } from './interfaces';
import { CROSS_VISITS_MAPS_SETTINGS } from '../../constants/constants';
import Floors from '../../../components/Floors/Floors';
import Header from './components/Header/Header';
import { LAYER_TYPE_BY_REPORTING_OBJECT_TYPE } from './constants/constants';
import Ratings from './components/Ratings/Ratings';
import { ResponseStatus } from '../../../../../../../tools/API/constants';
import { Select } from '../../../../../../../lib/esm/components';
import { Maps_CrossVisits_Module_Reducer_Values } from '../../reducer';
import { generalReducerValues } from '../../../../../../../General.reducer';
import getDataForLoadingWrapper from './tools/getDataForLoadingWrapper';
import usePrepareData from './hooks/usePrepareData';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { useWindowResize } from '../../../../../../../tools/useWindowResize';
import { withLoading } from '../../../../../../../tools/API/withLoading';

/**
 * Виджет для отображения виджетя среднедневных пересечений
 */
const CrossVisitsWidget = memo(() => {
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Cross-visits');
    const { rawVersionsData } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const {
        rawComparisonAverageIntersectionsData,
        selectedReportingObjectMarker,
        selectedReportingObjectType,
        rawAverageIntersectionsData,
        groupOfZonesSelectOptions,
        selectedGroupOfZones,
        availableMarkers,
        colorsByMarker,
        extendedLayers,
        rawLayersData,
        rawPlansData,
    } = useSelector(Maps_CrossVisits_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsByType, reportingObjectsByTypeAndMarker },
        structures,
    } = useSelector(generalReducerValues);
    const stageAreaRef = useRef(null);
    const stageAreaSize = useWindowResize(stageAreaRef);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePrepareData();

    const storeSelectedReportingObjectTypeTrans = (value: AvailableReportingObjectsTypes) => {
        dispatch(storeSelectedReportingObjectType(value));
    };

    const storeSelectedReportingObjectMarkerTrans = (value: string | null) => {
        dispatch(storeSelectedReportingObjectMarker(value));
    };

    const groupOdZonesHandler = (args: TSelectedOptions) => {
        dispatch(storeSelectedGroupOfZones(args[0]));
    };

    const maps = useMemo(() => {
        if (rawVersionsData) {
            const loadingData = getDataForLoadingWrapper([
                structures || { status: ResponseStatus.Error, message: t('Get structure error') },
                rawVersionsData,
                rawLayersData,
                rawPlansData,
            ]);

            const stageWidthCalculationFactor = localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                (element: ICrossVisitsSelectOption) => element.id === CrossVisitsMapsSettingsIds.ShowRatings,
            );

            const WithLoadingTable = withLoading(MapsWrapper, { height: 400, data: loadingData });
            return (
                <WithLoadingTable>
                    {rawPlansData && extendedLayers && (
                        <Floors
                            showAllLabels={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.ShowLabels,
                                )
                            }
                            showPlans={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.ShowPlans,
                                )
                            }
                            showPerimeters={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.ShowPerimeters,
                                )
                            }
                            joinFloors={
                                !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                                    (element: ICrossVisitsSelectOption) =>
                                        element.id === CrossVisitsMapsSettingsIds.JoinFloors,
                                )
                            }
                            showLoader={
                                !!rawComparisonAverageIntersectionsData?.['status'] ||
                                !!rawAverageIntersectionsData?.['status']
                            }
                            selectedLayerType={LAYER_TYPE_BY_REPORTING_OBJECT_TYPE[selectedReportingObjectType]}
                            selectedGroupOfZones={selectedGroupOfZones ? String(selectedGroupOfZones.id) : null}
                            stageWidthCalculationFactor={stageWidthCalculationFactor ? 0.7 : 1}
                            reportingObjectsByTypeAndMarker={reportingObjectsByTypeAndMarker}
                            selectedReportingObjectType={selectedReportingObjectType}
                            selectObject={storeSelectedReportingObjectMarkerTrans}
                            RatingsComponent={(props) => <Ratings {...props} />}
                            selectedMarker={selectedReportingObjectMarker}
                            colorsByMarker={colorsByMarker || {}}
                            availableMarkers={availableMarkers}
                            layers={extendedLayers as ILayer[]}
                            plans={rawPlansData as IPlan[]}
                            stageAreaSize={stageAreaSize}
                            labelAdditionalText={'%'}
                        />
                    )}
                </WithLoadingTable>
            );
        }
        return null;
    }, [
        localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS],
        rawComparisonAverageIntersectionsData,
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectMarker,
        JSON.stringify(stageAreaSize),
        selectedReportingObjectType,
        rawAverageIntersectionsData,
        selectedGroupOfZones,
        availableMarkers,
        rawVersionsData,
        colorsByMarker,
        extendedLayers,
        rawLayersData,
        rawPlansData,
        structures,
    ]);

    return (
        <WidgetWrapper>
            <Header
                storeSelectedReportingObjectTypeTrans={storeSelectedReportingObjectTypeTrans}
                existingReportingObjectsTypes={Object.keys(reportingObjectsByType)}
                selectedReportingObjectType={selectedReportingObjectType}
            />
            {selectedReportingObjectType === AvailableReportingObjectsTypes.Zone ? (
                <SelectWrapper>
                    <Select
                        outsideSelected={selectedGroupOfZones ? [selectedGroupOfZones] : []}
                        options={groupOfZonesSelectOptions as IObject[]}
                        labelText={t('Select zones group')}
                        handleSelect={groupOdZonesHandler}
                        iconType="chevron-down"
                        showFilter={false}
                        dropdownWidth="sm"
                        mode="single"
                        margin="r_sm"
                        text={'null'}
                    />
                </SelectWrapper>
            ) : null}
            <StageWrapper ref={stageAreaRef}>{maps}</StageWrapper>
        </WidgetWrapper>
    );
});

export default CrossVisitsWidget;
