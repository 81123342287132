import { IPerimeter, IPlace, IZone } from '../../../../../../../../../General.interfaces';
import Perimeter from '../Perimeter/Perimeter';
import { IProps } from './interfaces';
import Zone from '../Zone/Zone';
import { FC, useContext } from 'react';
import Place from '../Place/Place';
import FloorsContext from '../../../../Floors/context';

/**
 * Компонент, отвечающий за отображдений конкретного слоя
 */
const LayerByType: FC<IProps> = ({ object, floor, stageIndex, stage, plan }) => {
    const {
        selectedLayerType,
        availableMarkers,
        selectedMarker,
        changeStageCursor,
        selectObject,
        onMouseLeave,
        onMouseOver,
        showPlans,
        colorsByMarker,
        stageScale,
    } = useContext(FloorsContext);

    const heatColor =
        colorsByMarker?.[object['marker']]?.heatColor ||
        colorsByMarker?.[object['zone_marker']]?.heatColor ||
        colorsByMarker?.[floor]?.heatColor;

    const props = {
        selectedLayerType,
        changeStageCursor,
        availableMarkers,
        selectedMarker,
        selectObject,
        onMouseLeave,
        onMouseOver,
        stageIndex,
        stageScale: stageScale.stageScale,
        showPlans,
        heatColor,
        floor,
        stage,
        plan,
    };
    switch (selectedLayerType) {
        case 'perimeter_layer':
            return <Perimeter {...props} object={object as IPerimeter} />;
        case 'pass_ways_layer':
            return null;
        case 'zones_layer':
            return <Zone {...props} object={object as IZone} />;

        case 'places_layer':
        case 'tenants_layer':
            return <Place {...props} object={object as IPlace} />;

        default:
            return null;
    }
};

export default LayerByType;
