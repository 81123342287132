export const CABINET_SERVICE_NAME = 'client-office';
export const CURRENT_HOST = 'https://test.focus.bi/client-office/';

export const hosts = {
    lambdaService: 'https://lambda.focustech.xyz',
    // adminService: 'https://admin-api.focustech.xyz',
    adminService: 'https://admin.focustech.xyz',
    mapService: 'https://map-service.focustech.xyz',
    geoService: 'https://geomapping.focustech.xyz',
    fpcService: 'https://fpct-metric-storage.focustech.xyz',
    // appBackendService: 'https://app-backend.focustech.xyz',
    appBackendService: 'http://localhost:1130',
};

/**
 * Объект с изначальными адресами для ручек.
 */
export const getUrls = (host: { [x: string]: string } = hosts) => {
    return {
        AUTH_URL: `${host.adminService}/sso/ajax-token`,
        LOGOUT_URL: `${host.adminService}/admin/logout/`,
        CURRENT_URL: `${host.adminService}/sso/current?permissions=1`,
        PL_BY_ML_URL: `${host.adminService}/api/pl-by-ml`,
        CATEGORIES_URL: `${host.adminService}/api/categories`,
        LOCATIONS_URL: `${host.lambdaService}/structure-service/v1/project_locations/`,
        OBJECTS_URL: `${host.lambdaService}/structure-service/v1/cached_structure/`,
        // LOCATIONS_URL: `${host.mapService}/api/locations/`,
        LOCATION_CLONE_URL: `${host.mapService}/api/location-clone/`,
        PLANS_URL: `${host.mapService}/api/plans/`,
        LAYERS_URL: `${host.mapService}/api/layers/`,
        VERSIONS_URL: `${host.mapService}/api/versions/`,
        GEO_URL: `${host.geoService}/geo-mapping/`,

        PLACES_URL: `${host.adminService}/api/places/`,
        TENANTS_URL: `${host.adminService}/api/tenants/`,
        TENANT_2_PLACE_URL: `${host.adminService}/api/tenant-2-place/`,

        FPC_SENSOR_STATUS_URL: `${host.fpcService}/api/sensor-status/`,
        CABINET_PREFERENCES_URL: `${host.appBackendService}/api/user-settings/cabinet-preferences/`,
    };
};
