import { Action, AnyAction, Dispatch } from '@reduxjs/toolkit';
import { IPeriod } from '../../../../../../../../General.interfaces';
import { requestAverageIntersections } from '../../../../../../../../lib/esm/components';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import generateAverageIntersectionsQueryData from './generateAverageIntersectionsQueryData';
interface IArgs {
    dispatch: Dispatch<AnyAction>;
    action: (x: any) => AnyAction;
    mainReportingObjectId: number;
    contraReportingObjectsIds: number[];
    signal: AbortSignal;
    token: string;
    dateRanges: IPeriod[];
    period: IPeriod;
}

const requestAverageIntersectionsHelper = (args: IArgs): void => {
    const { dispatch, action, mainReportingObjectId, contraReportingObjectsIds, signal, token, dateRanges, period } =
        args;

    const queryData = generateAverageIntersectionsQueryData({
        mainReportingObjectId,
        contraReportingObjectsIds,
        signal,
        token,
        dateRanges,
        period,
    });

    if (queryData) {
        dispatch(action({ status: ResponseStatus.Loading, message: 'Loading...' }));
        requestAverageIntersections(queryData).then((responses: TServerResponse) => {
            handleServerResponse({
                responseAnalyzer: responseAnalyzer,
                success: action,
                error: action,
                res: responses,
                dispatch,
            });
        });
    }
};

export default requestAverageIntersectionsHelper;
