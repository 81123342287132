import ReactDataSheet from 'react-datasheet';
import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../../tools/getColorFromPercent';

export const HeaderCell = styled.div`
    padding: 10px 20px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    font-weight: bold;
`;

export const TableWrapper = styled.div`
    flex: 1 0 0;
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    overflow: auto;
`;

export const CustomTable = styled(ReactDataSheet)`
    width: 100%;
    table-layout: auto !important;
`;

export const YearCell = styled.div`
    text-align: right;
    font-weight: bold;
    padding: 10px 20px;
    white-space: nowrap;
`;

export const LoadingWrapper = styled.div`
    padding: 10px 20px;
    border: 1px solid var(--color-border-gray);
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TotalRowCell = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    background-color: #fffdec;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TotalRowCellValue = styled.div`
    padding: 10px 20px;
    height: 100%;
    background-color: #fffdec;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const CompareCellValue = styled.div<{ value: string | null }>`
    padding: 10px 20px;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: ${({ value }) => getColorFromPercent(value)};
`;

export const TotalCompareCellValue = styled(CompareCellValue)`
    background-color: #fffdec;
`;

export const MonthCellValue = styled.div`
    padding: 10px 20px;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const MonthCell = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const compareCellTooltipStyles = {
    root: {
        width: '100%',
    },
};
