import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../lib/esm/components';
import { IProps } from './interfaces';
import { StIcon, Wrapper } from './styles';
import { getReportingObjects, objectTypesMap } from '../../../../tools/getReportingObjects';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { modulesConfig, getStoreMethod } from '../../../../constants/modulesConfig';

import { TSelectedOptions } from '../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues, storeSelectedObjectIds } from '../../UserSettings/reducer';
import { getSettings } from '../../../../tools/getSettings';

const isTenantsModules = (moduleName: string) => {
    return (
        moduleName === 'Reports:By tenants' ||
        moduleName === 'Leasing:Tenant overview' ||
        moduleName.split(':')[1] === 'Path to purchase' ||
        moduleName === 'Leasing:Cross-visits'
    );
};

const isLeasingTenantsModule = (module: string) => {
    return module === 'Leasing:Tenants';
};

const ObjectsSelect: React.FC<IProps> = ({
    maxSelected,
    minSelected = 1,
    label = 'Objects',
    validOptions,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        src: { reportingObjects, projectCategories },
        cfg: { reportingObjectsByType },
        currentModule,
        selectedLocationId,
    } = useSelector(generalReducerValues);

    const { currentOptions, settings } = useSelector(userSettingsReducerValues);
    const { selectedReportingObjectsIds } = currentOptions;

    useEffect(() => {
        if (!reportingObjects.length || !selectedLocationId || !currentModule) return;
        const storeMethod = getStoreMethod(currentModule, 'selectedReportingObjectsIds');

        const objSettings = getSettings(
            settings,
            selectedLocationId,
            currentModule,
            'selectedReportingObjectsIds',
            storeMethod,
        );

        if (!objSettings && selectedLocationId) {
            let ids: number[] = [];
            if (reportingObjectsByType['location']) {
                if (isTenantsModules(currentModule)) {
                    ids = [reportingObjectsByType?.['tenant']?.[0]?.id];
                } else if (isLeasingTenantsModule(currentModule)) {
                    ids = [];
                } else {
                    ids = [reportingObjectsByType['location'][0].id];
                }
            } else {
                // ids = [reportingObjects[0].id];
                ids = [];
            }

            dispatch(
                storeSelectedObjectIds({
                    module: currentModule,
                    locationId: selectedLocationId,
                    selectedReportingObjectsIds: ids,
                }),
            );
        }
    }, [currentModule, dispatch, reportingObjects, reportingObjectsByType, selectedLocationId, settings]);

    const options = useMemo(() => {
        let result = [];
        if (!validOptions) {
            result.push(...getReportingObjects(reportingObjectsByType, t));
        } else {
            result.push(
                ...getReportingObjects(reportingObjectsByType, t)?.filter((item) =>
                    validOptions.map((item) => item.toLowerCase()).includes(item.section.toLowerCase()),
                ),
            );
        }

        return result;
    }, [validOptions, projectCategories, reportingObjectsByType, t]);

    let outsideSelected: TSelectedOptions = [];

    if (selectedReportingObjectsIds) {
        outsideSelected = reportingObjects
            ?.filter((item) => selectedReportingObjectsIds.includes(item.id))
            .map((item) => ({
                ...item,
                section: t(objectTypesMap[item.object_type] || item.object_type),
                text: item.name,
            }));
    } else {
        const ids = currentOptions?.selectedReportingObjectsIds || [];
        outsideSelected = reportingObjects
            ?.filter((item) => ids.includes(item.id))
            .map((item) => ({
                ...item,
                section: t(objectTypesMap[item.object_type] || item.object_type),
                text: item.name,
            }));
    }

    // const outsideSelectedCategories = projectCategories
    //     ?.filter((item) => {
    //         const ids = (currentOptions?.selectedReportingObjectsIds, 'category') || [];
    //         return ids.includes(item.id);
    //     })
    //     .map((item) => {
    //         const section = projectCategories?.find((cat) => cat.id === item.parent_id)?.name;
    //         return { ...item, section, text: item.name, type: 'category' };
    //     });

    // outsideSelected.push(...outsideSelectedCategories);

    const storeObjectIds = (selectedReportingObjectsIds: number[], locationId: number | null, module: string) => {
        if (locationId) {
            dispatch(
                storeSelectedObjectIds({
                    module,
                    locationId,
                    selectedReportingObjectsIds,
                }),
            );
        }
    };

    const handleSelect = (args: { id: number | null | string; block_type?: string }[]) => {
        props.handleSelect && props.handleSelect(args.map((item: { id: number | null | string }) => item.id));
        const selectedReportingObjectsIds: number[] = args
            ?.filter((item) => item !== null)
            .map((item) => item.id as number);
        storeObjectIds(selectedReportingObjectsIds, selectedLocationId, currentModule);
    };

    const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                options={options}
                dropdownWidth="md"
                text={t('Selected')}
                dropdownAlign="left"
                showFilter={true}
                mode="multi"
                labelText={`${t(label)}${max}`}
                iconType="chevron-down"
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                outsideSelected={outsideSelected}
                additionalControl={<StIcon iconName="Delete" />}
                minSelected={minSelected}
            />
        </Wrapper>
    );
};

export default ObjectsSelect;
