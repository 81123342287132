import styled from 'styled-components/macro';
import { GradientColorObject, PatternObject } from 'highcharts';

export const ChartDot = styled.div<{ newColor: string | GradientColorObject | PatternObject | undefined }>`
    height: 8px;
    width: 8px;
    background-color: ${(props) => String(props.newColor)};
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px 0 0;
`;
