import { PivotItem } from '@fluentui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Maps_MapsOverview_Module_Reducer_Values, storeObjectsType } from '../../reducer';
import { CanvasWrapper, Cont, MetricContainer, WidgetWrapper } from './styles';
import { DateTime } from 'luxon';
import { geoMatrixConverner } from './core/geoMatrixConverter';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { useWindowResize } from '../../../../../../../tools/useWindowResize';
import Selects from './components/Selects/Selects';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { pivotItems } from './core/pivotItems';
import Ratings from './components/Ratings/Ratings';
import Floors from './components/Floors/Floors';
import { IScale } from './components/Floors/interfaces';
import LoadingBox from '../../../../../LoadingBox/LoadingBox';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import { MapsWrapper } from '../../../components/Floors/styles';
import Pivot from '../../../../../Pivot/Pivot';

const MapsWidget: React.FC = ({}) => {
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Maps overview');
    const canvasAreaRef = useRef(null);

    const [widgetSettings, setWidgetSettings] = useState<string[]>([]);
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: 0,
        stageY: 0,
    });
    const { plans, selectedObjectType, colorsByMarker, metricsData } = useSelector(
        Maps_MapsOverview_Module_Reducer_Values,
    );

    const canvasAreaSize = useWindowResize(canvasAreaRef);

    useEffect(() => {
        if (localCurrentOptions?.['mapsSettings']) {
            setWidgetSettings(localCurrentOptions?.['mapsSettings'].map((item: { id: string }) => item.id));
        }
    }, [localCurrentOptions?.['mapsSettings']]);

    const existingTypes = ['tenant', 'zone', 'floor'];

    const onLinkClick = (item: PivotItem | undefined) => {
        item?.props.itemKey && dispatch(storeObjectsType(item.props.itemKey));
    };

    const latestPlans = {};

    Array.isArray(plans) &&
        plans?.forEach((plan) => {
            const mainPlan = plans?.filter((item) => plan.floor === item?.floor)?.filter((item) => item.is_main)[0];

            if (latestPlans[plan.floor]) {
                if (
                    DateTime.fromISO(plan.active_from).toMillis() >=
                    DateTime.fromISO(latestPlans[plan.floor].active_from).toMillis()
                ) {
                    latestPlans[plan.floor] = { ...plan, mainPlan };
                }
            } else {
                latestPlans[plan.floor] = { ...plan, mainPlan };
            }
        });

    const widestPlan = Object.keys(latestPlans)
        .map((key) => latestPlans[key])
        .sort((a, b) => b.width / b.scale - a.width / a.scale)[0];

    const finalPlans = Object.keys(latestPlans)
        .map((key) => {
            const plan = latestPlans[key];
            let imageOffset = [0, 0];
            const vector = geoMatrixConverner(0, 0, plan.plan2geo);
            if (vector && vector[0]) {
                imageOffset = geoMatrixConverner(vector[0], vector[1], widestPlan.mainPlan.geo2plan).map((item) => {
                    if (Math.abs(item) < 0.1) {
                        return 0;
                    } else {
                        return item;
                    }
                });
            }
            return { ...plan, imageOffset, widestPlan };
        })
        .sort((a, b) => b.floor - a.floor);

    const layerType = pivotItems?.find((item) => item.itemKey === selectedObjectType)?.layerType || 'places_layer';

    const metricsNumber = localCurrentOptions?.['selectedMetrics']?.length
        ? localCurrentOptions?.['selectedMetrics']?.length
        : 1;

    const ratingHeight = useMemo(() => {
        return finalPlans.reduce((acc, plan) => {
            const planRatio = plan.width / plan.height;
            const stageWidth = widgetSettings.includes('showRatings')
                ? (0.7 * canvasAreaSize.width) / metricsNumber
                : canvasAreaSize.width / metricsNumber;
            const stageHeight = Math.round(stageWidth / planRatio);
            return acc + stageHeight;
        }, 0);
    }, [canvasAreaSize.width, finalPlans, metricsNumber, widgetSettings]);

    const changeScale = (args: IScale) => {
        set_scale(args);
    };

    const diagram = useMemo(() => {
        return finalPlans[0] ? (
            (localCurrentOptions?.['selectedMetrics'] || [1]).map((metric, j) => {
                const metricName = t(allMetrics?.find((item) => item.id === metric)?.text || '');
                return (
                    <Cont key={`canvas--${metric}`}>
                        <WidgetTitle>{metricName}</WidgetTitle>
                        <MetricContainer>
                            <Floors
                                scale={scale}
                                changeScale={changeScale}
                                metric={String(metric)}
                                layerType={layerType}
                                canvasAreaSize={canvasAreaSize}
                                ratingHeight={ratingHeight + (8 * finalPlans.length - 1)}
                            />
                            {widgetSettings.includes('showRatings') && (
                                <Ratings
                                    metric={String(metric)}
                                    colorsByMarker={colorsByMarker}
                                    height={ratingHeight + (9 * finalPlans.length - 1)}
                                />
                            )}
                        </MetricContainer>
                    </Cont>
                );
            })
        ) : (
            <LoadingBox height={400} text={t('Loading...')} />
        );
        // }
    }, [
        allMetrics,
        canvasAreaSize,
        colorsByMarker,
        finalPlans,
        layerType,
        localCurrentOptions,
        metricsData,
        ratingHeight,
        scale,
        t,
        widgetSettings,
    ]);

    const colsNumber = localCurrentOptions?.['selectedMetrics']?.length || 1;

    return (
        <WidgetWrapper>
            <WidgetTitleWrapper>
                <WidgetTitle>{t('Metrics on the map')}</WidgetTitle>
                <Pivot widgetName="MapsOverview" onLinkClick={onLinkClick} selectedKey={selectedObjectType}>
                    {pivotItems
                        ?.filter((item) => existingTypes.includes(item.itemKey) || item.itemKey === 'all')
                        .map((element) => (
                            <PivotItem
                                key={element.itemKey + Math.random()}
                                headerText={t(element.headerText)}
                                itemKey={element.itemKey}
                            />
                        ))}
                </Pivot>
            </WidgetTitleWrapper>
            <Selects />
            <CanvasWrapper ref={canvasAreaRef} colsNumber={colsNumber}>
                {diagram}
            </CanvasWrapper>
        </WidgetWrapper>
    );
};

export default MapsWidget;
