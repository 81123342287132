import { ChartWrapper, MainWrapper, PivotWrapper } from './styles';
import { IExtendedReportingObject, IMetric, TSelectedChartTypeId } from './interfaces';
import { PivotItem } from '@fluentui/react';
import React, { memo, useRef } from 'react';
import {
    Performance_Dashboard_DynamicsPMO_Widget_Reducer_Values,
    reloadWidget,
    storeSelectedChartDetailing,
    storeSelectedChartType,
    storeSelectedEntity,
    storeSelectedMetric,
} from './reducer';
import { useDispatch, useSelector } from 'react-redux';

import ChartOptions from './components/ChartOptions/ChartOptions';
import Dynamics from '../../../../../Charts/Dynamics/Dynamics';
import { DynamicsPMOContext } from './context';
import { IChartDetailing } from '../../../../../../../constants/chartDetailing';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../../../General.reducer';
import useChartOptions from './hooks/useChartOptions';
import { useRerender } from '../../../../../../../tools/useRerender';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Комопнент для отображения графиков динамики по периодам, метрикам и объектам
 */
const DynamicsPMO: React.FC = memo(({}) => {
    const ref = useRef(null);

    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');
    const { lang, allMetrics } = useSelector(generalReducerValues);
    const {
        extendedReportingObjects,
        availableChartDetailing,
        selectedChartDetailing,
        availableChartTypes,
        selectedChartType,
        extendedMetrics,
        selectedObject,
        selectedMetric,
        rawMetricsData,
    } = useSelector(Performance_Dashboard_DynamicsPMO_Widget_Reducer_Values);
    const { series, xAxisLabelsFormating, tooltipTitleFormatter, ...additionalSettings } = useChartOptions();
    const rerender = useRerender(rawMetricsData);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const chartId = `dynamics:${localCurrentOptions?.currentModule || ''}:0`;

    const handleLinkClick = (item?: PivotItem) => {
        item && dispatch(storeSelectedChartType(item.props.itemKey as TSelectedChartTypeId));
    };

    const storeSelectedChartDetailingTrans = (detailing: IChartDetailing[]) => {
        dispatch(storeSelectedChartDetailing(detailing));
    };

    const storeSelectedEntityTrans = (entitys: IExtendedReportingObject[]) => {
        dispatch(storeSelectedEntity(entitys));
    };

    const storeSelectedMetricTrans = (metrics: IMetric[]) => {
        dispatch(storeSelectedMetric(metrics));
    };

    const contextValue = {
        mainDateRanges: localCurrentOptions?.mainDateRanges,
        mainPeriod: localCurrentOptions?.mainPeriod,
        storeSelectedChartDetailingTrans,
        storeSelectedMetricTrans,
        storeSelectedEntityTrans,
        extendedReportingObjects,
        availableChartDetailing,
        selectedChartDetailing,
        extendedMetrics,
        selectedObject,
        selectedMetric,
        allMetrics,
        lang,
    };

    const WithLoadingTable = withLoading(ChartWrapper, { data: rawMetricsData, height: 400 });

    if (!extendedMetrics.length || !extendedReportingObjects.length) return null;

    return (
        <DynamicsPMOContext.Provider value={contextValue}>
            <MainWrapper ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t('Dynamics by periods, metrics, objects')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                            widgetName={'Dynamics by periods, metrics, objects'}
                            reloadHandler={reloadWidget}
                        />
                    </WidgetTitle>
                    <PivotWrapper>
                        <Pivot
                            selectedKey={selectedChartType.id}
                            onLinkClick={handleLinkClick}
                            widgetName="DynamicsByPMO"
                        >
                            {availableChartTypes.map((element) => (
                                <PivotItem
                                    key={element.id + Math.random()}
                                    headerText={t(element.headerText)}
                                    itemKey={element.id}
                                />
                            ))}
                        </Pivot>
                    </PivotWrapper>
                </WidgetTitleWrapper>
                {selectedChartType.id === 'periods' && <ChartOptions title={t('Period')} />}
                {selectedChartType.id === 'metrics' && (
                    <ChartOptions title={t('Metric comparison')} showMetricsSelect={false} showDatePeriod={true} />
                )}
                {selectedChartType.id === 'objects' && (
                    <ChartOptions title={t('Objects comparison')} showEntitiesSelect={false} showDatePeriod={true} />
                )}
                <WithLoadingTable>
                    <Dynamics
                        tooltipTitleFormatter={tooltipTitleFormatter}
                        xAxisLabelsFormating={xAxisLabelsFormating}
                        {...additionalSettings}
                        chartId={chartId}
                        series={series}
                    />
                </WithLoadingTable>
            </MainWrapper>
        </DynamicsPMOContext.Provider>
    );
});

export default DynamicsPMO;
