import { IMetric } from "../General.interfaces";

const currency = '₽';

export const allMetrics: IMetric[] = [
    // Common
    { id: 'sum_area', text: 'Sum area', section: 'Common', units: 'm²' },
    { id: 'sum_working_hours', text: 'Working hours', section: 'Common', units: 'h' },
    // Sales
    { id: 'fsf_bill_count', text: 'Bills count', section: 'Sales', units: 'pc' },
    { id: 'fsf_sales_vat', text: 'Sales vat', section: 'Sales', units: currency },
    { id: 'fsf_sales_novat', text: 'Sales novat', section: 'Sales', units: currency },
    { id: 'fsf_sales_return_vat', text: 'Sales returns vat', section: 'Sales', units: currency },
    { id: 'fsf_sales_return_novat', text: 'Sales returns novat', section: 'Sales', units: currency },
    { id: 'fsf_good_count', text: 'Goods count', section: 'Sales', units: 'pc' },
    { id: 'fsf_good_position_count', text: 'Positions count', section: 'Sales', units: 'pc' },
    { id: 'fsf_sales_vat - fsf_sales_novat', text: 'Vat', section: 'Sales', units: currency },
    { id: 'fsf_sales_vat / sum_area', text: 'Sales vat/m²', section: 'Sales', units: `${currency}/m²` },
    { id: 'fsf_sales_novat / sum_area', text: 'Sales novat/m²', section: 'Sales', units: `${currency}/m²` },
    { id: 'fsf_sales_return_vat / fsf_sales_vat', text: 'Sales returns rate vat', section: 'Sales', units: '%' },
    { id: 'fsf_sales_return_novat / fsf_sales_novat', text: 'Sales returns rate novat', section: 'Sales', units: '%' },
    { id: 'fsf_sales_vat / fsf_bill_count', text: 'Avg bill vat', section: 'Sales', units: currency },
    { id: 'fsf_sales_novat / fsf_bill_count', text: 'Avg bill novat', section: 'Sales', units: currency },
    { id: 'fsf_good_count / fsf_bill_count', text: 'Avg goods count', section: 'Sales', units: 'pc' },
    { id: 'fsf_good_position_count / fsf_bill_count', text: 'Avg positions count', section: 'Sales', units: 'pc' },
    { id: 'fsf_bill_count / fpc_sum_pass_count_in_wh * 100', text: 'Conversion', section: 'Sales', units: '%' },
    // People counting
    { id: 'fpc_sum_pass_count_in_wh', text: 'Traffic in', section: 'People counting', units: 'ppl' },
    { id: 'fpc_sum_pass_count_out_wh', text: 'Traffic out', section: 'People counting', units: 'ppl' },
    { id: 'fpc_sum_pass_count_in_wh / sum_area', text: 'Traffic/m² (in)', section:'People counting', units: 'ppl/m²' },
    { id: 'fpc_sum_pass_count_out_wh / sum_area', text: 'Traffic/m² (out)', section:'People counting', units: 'ppl/m²' },
    //Shopster
    { id: 'shp_avg_dwell_time', text: 'Average visit duration', section: 'Shopster', units: 'min' },
    { id: 'shp_sum_visit_count', text: 'Visitors (MAC-addresses)', section: 'Shopster', units: 'ppl' },
    { id: 'shp_avg_engagement_rate', text: 'Engagement rate', section: 'Shopster', units: '%' },
    { id: 'shp_avg_returning_rate', text: 'Returned visitors rate', section: 'Shopster', units: '%' },
    { id: 'shp_avg_dwell_time_engaged', text: 'Average visit duration (engaged)', section: 'Shopster', units: 'min' },
    { id: 'shp_avg_entry_rate', text: 'Capture rate', section: 'Shopster', units: '%' },
    { id: 'shp_unique_count', text: 'Unique MAC-addresses', section: 'Shopster', units: 'pc' },
    { id: '100 - shp_avg_engagement_rate', text: 'Bounced rate', section: 'Shopster', units: '%' },
    { id: '100 - shp_avg_returning_rate', text: 'New visitors rate', section: 'Shopster', units: '%' },
];

export const permissionsMap = {
    Common: '',
    Sales: 'core/data-source | fsf | api/read',
    'People counting': 'core/data-source | fpc | api/read',
    Shopster: 'core/data-source | shp | api/read',
    Video: 'core/data-source | fcv | api/read',
    Weather: 'core/data-source | fwe | api/read',
};
