import { FC, MouseEvent } from 'react';
import { IProps } from './interfaces';
import { Pivot as FluentPivot, PivotItem } from '@fluentui/react';
import { sendSimpleMetrics } from '../../../tools/useAnalytics';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';

/**
 * Компонент для отображения Pivot с использованием яндекс метрики
 */
const Pivot: FC<IProps> = ({ widgetName, children, ...props }) => {
    const { currentModule, user } = useSelector(generalReducerValues);
    const canSendAnalytics = !user?.permissions.includes('app/client | not-collect-analytics | feature/view');

    const onLinkClick = (
        item?: PivotItem | undefined,
        ev?: MouseEvent<HTMLElement, globalThis.MouseEvent> | undefined,
    ) => {
        props.onLinkClick && props.onLinkClick(item, ev);
        widgetName &&
            sendSimpleMetrics(
                'reachGoal',
                `switch_to_tab_${item?.props.itemKey}`,
                `${currentModule}:${widgetName}`,
                canSendAnalytics,
            );
    };

    return (
        <FluentPivot {...props} selectedKey={props.selectedKey} onLinkClick={onLinkClick}>
            {children}
        </FluentPivot>
    );
};

export default Pivot;
