import { IObject } from '../../../../../../../lib/esm/components/Select/configurations';
import { IChartDetailing } from '../../../../../../../constants/chartDetailing';
import { IDateRange, IPeriod } from '../../../../../../../General.interfaces';
import { IExtendedReportingObject, IMetric } from './interfaces';
import { createContext } from 'react';
import { TLang } from '../../../../../SideBar/configurations';

interface IDynamicsPMOContext {
    storeSelectedEntityTrans?: (entitys: IExtendedReportingObject[]) => void;
    storeSelectedChartDetailingTrans?: (detailing: IObject[]) => void;
    storeSelectedMetricTrans?: (metrics: IMetric[]) => void;
    extendedReportingObjects: IExtendedReportingObject[];
    selectedObject: IExtendedReportingObject | null;
    availableChartDetailing: IChartDetailing[];
    selectedChartDetailing: IObject | null;
    selectedMetric: IMetric | null;
    mainDateRanges?: IDateRange[];
    extendedMetrics: IMetric[];
    mainPeriod?: IPeriod;
    lang: TLang;
    allMetrics: {
        id: string;
        text: string;
    }[];
}

export const DynamicsPMOContext = createContext<IDynamicsPMOContext>({
    extendedReportingObjects: [],
    selectedChartDetailing: null,
    availableChartDetailing: [],
    selectedObject: null,
    selectedMetric: null,
    extendedMetrics: [],
    allMetrics: [],
    lang: 'en',
});
