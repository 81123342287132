import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { IUseChartOptionsArgs } from '../tools/interfaces';
import { TSeries } from '../../../../../../../../../../Charts/Dynamics/interfaces';
import { generalReducerValues } from '../../../../../../../../../../../../General.reducer';
import generateSeries from '../tools/generateSeries';
import { stringDate } from '../../../../../../../../../../../../tools/Strings/stringDate';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values } from '../reducer';

/**
 * Кастомный хук для получения настроек графика
 * @param chartDetailing Детализация графиков (МЕСЯЦ: MS или ДЕНЬ: D)
 * @param selectedMetric выбранная метрика
 * @returns Настройки для графика
 */
const useChartOptions = (args: IUseChartOptionsArgs) => {
    const { chartDetailing, selectedMetric } = args;
    const { rawMetricsDataByTimeRange } = useSelector(Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values);
    const { lang, allMetrics } = useSelector(generalReducerValues);
    const [series, setSeries] = useState<TSeries[]>([]);

    useEffect(() => {
        if (rawMetricsDataByTimeRange) {
            const series = generateSeries({
                rawMetricsDataByTimeRange,
                chartDetailing,
                lang,
            });
            setSeries(series);
        }
    }, [rawMetricsDataByTimeRange, chartDetailing, lang]);

    const xAxisLabelsFormating = function (
        this: Highcharts.AxisLabelsFormatterContextObject,
        _: Highcharts.AxisLabelsFormatterContextObject,
    ) {
        return stringDate(
            DateTime.fromMillis(this.value as number).toISO(),
            lang,
            '',
            chartDetailing === 'MS' ? 'MMMM yyyy' : 'MMM dd, yyyy',
        );
    };

    const tooltipSubTitle = allMetrics?.find((element) => element.id === selectedMetric)?.text || selectedMetric;

    return { series, xAxisLabelsFormating, tooltipSubTitle };
};

export default useChartOptions;
