import React from 'react';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { CellWrapper, Percent, Value } from '../styles';

const Cell: React.FC<{ isMain?: boolean; value: number | string; percent?: string }> = ({
    value,
    percent,
    isMain = false,
}) => {
    return (
        <CellWrapper>
            {!isMain && percent && (
                <Percent percent={percent}>
                    {percent && !isNaN(Number(percent)) && percent !== 'Infinity'
                        ? `${numberWithSpaces(percent)} %`
                        : '–'}
                </Percent>
            )}
            <Value isMain={isMain}>{value}</Value>
        </CellWrapper>
    );
};

export default Cell;
