import { IEmptyStructure } from './interfaces';

/**
 * Функция для подготовки аргументов запроса
 */
const prepareRequestsArgs = (rawDataStructure: IEmptyStructure) => {
    const reportingObjectsIds: number[] = [];
    const metricIds: string[] = [];
    const periods: { [alias: string]: { dateFrom: string; dateTo: string } } = {};

    Object.keys(rawDataStructure).forEach((key) => {
        const splitedKey = key.split(':');
        const rawDataStructureValue = rawDataStructure[key];

        const reportingObjectId = Number(splitedKey[0]);
        const metricId = splitedKey[1];
        const period = `${splitedKey[2]}:${splitedKey[3]}`;

        if (!reportingObjectsIds.includes(reportingObjectId)) {
            reportingObjectsIds.push(reportingObjectId);
        }

        if (!metricIds.includes(metricId)) {
            metricIds.push(metricId);
        }

        if (!periods[period]) {
            periods[period] = rawDataStructureValue.period;
        }
    });

    return {
        reportingObjectsIds,
        metricIds,
        periods,
    };
};

export default prepareRequestsArgs;
