/**
 * Размеры тени
 */
var ShadowSize;
(function (ShadowSize) {
    /**
     * Пустое значение
     */
    ShadowSize["Empty"] = "";
    /**
     * Значение по умолчанию
     */
    ShadowSize["Default"] = "md";
    /**
     * Маленький размер
     */
    ShadowSize["Sm"] = "sm";
    /**
     * Средний размер
     */
    ShadowSize["Md"] = "md";
    /**
     * Средний размер
     */
    ShadowSize["Unset"] = "unset";
})(ShadowSize || (ShadowSize = {}));

export { ShadowSize };
