import { useContext, useEffect, useMemo, useState } from 'react';
import { subsetsOfSalesMetricsOptions } from '../../../../../../constants/contsants';
import { SalesOfTenantsContext } from '../../../../context';
import { Tr } from '../../styles';
import { MetricValue, PercentageValue, Td } from './styles';
import { Stack } from '@fluentui/react';
import { IReportingObject } from '../../../../../../../../../../../General.interfaces';
import { useSelector } from 'react-redux';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../../../../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../../../../tools/useWidgetCurrentOptions';
import filterReportingObjects from '../../../../../../../../../Filters/tools/filterReportingObjects';
import { useTranslation } from 'react-i18next';
import { isNumber, isUndefined } from 'lodash';
import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import sortTenants from '../../../../tools/sortTenants';

/**
 * Компонент для отображения тела таблицы
 */
const Body = () => {
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const [filteredTenants, setFilteredTenants] = useState<IReportingObject[]>([]);
    const [sortedTenants, setSortedTenants] = useState<IReportingObject[]>([]);
    const context = useContext(SalesOfTenantsContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (context && context.tableOrderData && context.extendedTenantsById) {
            const sortedData = sortTenants({
                extendedTenantsById: context.extendedTenantsById,
                tableOrderData: context.tableOrderData,
                tenants: filteredTenants,
            });

            setSortedTenants(sortedData);
        } else {
            setSortedTenants(filteredTenants);
        }
    }, [context?.tableOrderData, filteredTenants]);

    useEffect(() => {
        if (
            context &&
            context.extendedTenantsById &&
            localCurrentOptions?.mainPeriod &&
            localCurrentOptions?.mainDateRanges
        ) {
            const filteredArray = filterReportingObjects({
                initialArray: Object.values(context.extendedTenantsById).map((element) => element.tenantData),
                dataObj2ProjectCategory: context.dataObj2ProjectCategory,
                tenant2FloorByTenantId: context.tenant2FloorByTenantId,
                tenant2ZoneByTenantId: context.tenant2ZoneByTenantId,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                reportingObjectsById: context.reportingObjectsById,
                filters: localCurrentOptions?.['filters'] || [],
                mainPeriod: localCurrentOptions.mainPeriod,
            });
            setFilteredTenants(filteredArray);
        }
    }, [
        localCurrentOptions?.mainDateRanges,
        localCurrentOptions?.['filters'],
        localCurrentOptions?.mainPeriod,
        context?.extendedTenantsById,
    ]);

    const rows = useMemo(() => {
        if (context && sortedTenants.length) {
            const currentSubtypes = subsetsOfSalesMetricsOptions
                ?.find((element) => element.id === context.selectedSubsetId)
                ?.subtypes.map((element) => element.id)
                .sort();
            if (currentSubtypes && context.extendedTenantsById) {
                return sortedTenants.map((tenant) => {
                    const extendedTenant = context.extendedTenantsById![tenant.id];

                    return (
                        <Tr key={extendedTenant.tenantData.id}>
                            <Td>{extendedTenant.tenantData.name}</Td>
                            {currentSubtypes.map((element: string) => {
                                const mainValue = extendedTenant.metricsData[element]?.mainPeriod?.items[0].value;
                                const compareValue = extendedTenant.metricsData[element]?.comparePeriod?.items[0].value;
                                let comparePercentageOfTheMain = undefined;
                                let mainPercentageOfTheMainTotal = undefined;

                                if (
                                    !isUndefined(compareValue) &&
                                    !isUndefined(mainValue) &&
                                    context &&
                                    context.totalValue?.[element]
                                ) {
                                    const totalValue = context.totalValue[element].mainPeriodTotalValue;

                                    if (totalValue > 0) {
                                        mainPercentageOfTheMainTotal = (mainValue / totalValue) * 100;
                                    } else {
                                        mainPercentageOfTheMainTotal = 0;
                                    }

                                    if (mainValue > 0) {
                                        comparePercentageOfTheMain = (compareValue / mainValue) * 100 - 100;
                                    } else {
                                        comparePercentageOfTheMain = 0;
                                    }
                                }

                                return (
                                    <Td key={element}>
                                        {context.metrcisFetching ? (
                                            t('Loading...')
                                        ) : context.metrcisFetchingError ? (
                                            t('Unable to get data')
                                        ) : (
                                            <Stack
                                                horizontal={true}
                                                verticalAlign="center"
                                                horizontalAlign="space-between"
                                            >
                                                <MetricValue>
                                                    {isNumber(mainValue) && isNumber(mainPercentageOfTheMainTotal)
                                                        ? `${numberWithSpaces(
                                                              mainValue.toFixed(2),
                                                          )} (${numberWithSpaces(
                                                              mainPercentageOfTheMainTotal.toFixed(2),
                                                          )}%)`
                                                        : '—'}
                                                </MetricValue>
                                                <PercentageValue value={comparePercentageOfTheMain}>
                                                    {!isUndefined(comparePercentageOfTheMain)
                                                        ? `${numberWithSpaces(comparePercentageOfTheMain.toFixed(2))}%`
                                                        : '—'}
                                                </PercentageValue>
                                            </Stack>
                                        )}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                });
            }
            return null;
        }
        return null;
    }, [sortedTenants, context?.metrcisFetchingError, context?.metrcisFetching, context?.selectedSubsetId]);

    return <tbody>{rows}</tbody>;
};

export default Body;
