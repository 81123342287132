import { storeAvailableChartDetailing, storeExtendedMetrics, storeExtendedReportingObjects } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { generalReducerValues } from '../../../../../../../../General.reducer';
import getChartDataDetailing from '../../../../../../../../constants/chartDetailing';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';

/**
 * Кастомный хук для подготовки данных
 */
const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Events:Events');
    const {
        src: { reportingObjects },
        allMetrics,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // В зависимости от главного периода изменяет доступную детализацию графкиа
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            dispatch(
                storeAvailableChartDetailing(
                    getChartDataDetailing(t, localCurrentOptions.mainDateRanges, localCurrentOptions.mainPeriod),
                ),
            );
        }
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, t]);

    /**
     * В зависимости от выбранных отбъектов в главном селекте изменяет и селект виджета.
     * Инициурет обновление метрик, если изменение объектов произошло на вкладке Объектов
     */
    useEffect(() => {
        localCurrentOptions?.selectedReportingObjectsIds &&
            dispatch(
                storeExtendedReportingObjects({
                    selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
                    reportingObjects,
                }),
            );
    }, [reportingObjects, localCurrentOptions?.selectedReportingObjectsIds]);

    /**
     * В зависимости от выбранных метрик в главном селекте изменяет селект виджета.
     * Иницииурет обновление метрик, если изменение метрик произошло на вкладке Метрики
     */
    useEffect(() => {
        localCurrentOptions?.selectedMetrics &&
            dispatch(
                storeExtendedMetrics({
                    selectedMetrics: localCurrentOptions.selectedMetrics,
                    allMetrics,
                }),
            );
    }, [localCurrentOptions?.selectedMetrics]);
};

export default usePrepareData;
