import { Stack } from '@fluentui/react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { getReportingObjects } from '../../../../../../../tools/getReportingObjects';
import FiltersMonitor from '../../../../../Filters/FiltersMonitor/FiltersMonitor';
import FiltersSelect from '../../../../../Filters/FiltersSelect/FiltersSelect';
import Header from './components/Header/Header';
import Table from './components/Table/Table';
import { IContext, SalesOfTenantsContext } from './context';
import { ITableOrderData } from './interfaces';
import {
    reloadWidget,
    Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    storeSelectedSubsetId,
    storeTableOrderData,
} from './reducer';
import { WidgetWrapper } from './styles';

/**
 * Компонент для отображения виджета проажи арендаторов
 */
const SalesOfTenants = () => {
    const {
        src: { dataObj2ProjectCategory },
        cfg: { reportingObjectsByType, reportingObjectsById, tenant2FloorByTenantId, tenant2ZoneByTenantId },
    } = useSelector(generalReducerValues);
    const { selectedSubsetId, extendedTenantsById, metrcisFetching, metrcisFetchingError, totalValue, tableOrderData } =
        useSelector(Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values);
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const filterValidOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    const storeSelectedSubsetIdTrans = (id: string) => {
        dispatch(storeSelectedSubsetId(id));
    };

    const storeTableOrderDataTrans = (orderData: null | ITableOrderData) => {
        dispatch(storeTableOrderData(orderData));
    };

    const context: IContext = {
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        metrcisFetchingError,
        extendedTenantsById,
        selectedSubsetId,
        metrcisFetching,
        tableOrderData,
        totalValue,
        storeSelectedSubsetIdTrans,
        storeTableOrderDataTrans,
        reloadWidget,
    };

    return (
        <SalesOfTenantsContext.Provider value={context}>
            <WidgetWrapper ref={wrapperRef}>
                <Header wrapperRef={wrapperRef} />
                <Stack verticalAlign="center" horizontal={true} tokens={{ childrenGap: 10 }}>
                    <FiltersSelect label="" validOptions={filterValidOptions} />
                    <FiltersMonitor />
                </Stack>
                <Table />
            </WidgetWrapper>
        </SalesOfTenantsContext.Provider>
    );
};

export default SalesOfTenants;
