import { FC, useContext, useMemo, useRef, useState } from 'react';
import ReportingObjectsMenuBody from '../../../../../../../../../ContextualMenu/components/ReportingObjectsMenuBody/ReportingObjectsMenuBody';
import ContextualMenu from '../../../../../../../../../ContextualMenu/ContexttualMenu';
import { EventsManagementContext } from '../../../../context';
import { ITableCellData } from '../../../../interfaces';
import { Wrapper } from './styles';

interface IProps {
    /** Данные из ячейки */
    data: ITableCellData;
}

/**
 * Компонент для отображения ячейки с отчетными объектами
 */
const ReportingObjects: FC<IProps> = ({ data }) => {
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const context = useContext(EventsManagementContext);
    const ref = useRef(null);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const reportingObjectsNames = useMemo(() => {
        if (context?.reportingObjectsById && data.value) {
            return data.value.reduce((acc: string, id: number, index: number) => {
                if (index === data.value.length - 1) {
                    acc += context.reportingObjectsById[id]?.name;
                } else {
                    acc += `${context.reportingObjectsById[id]?.name}, `;
                }
                return acc;
            }, '');
        }
        return '';
    }, [context?.reportingObjectsById, data]);

    const onReportingObjectsSelect = (ids: number[]) => {
        context?.updateCellValueTrans({ frontId: data.frontId, key: data.key, value: ids });
        closeContextualMenu();
    };

    return (
        <Wrapper ref={ref} onDoubleClick={toggleContextualMenu}>
            {reportingObjectsNames}
            <ContextualMenu
                CustomMenuComponent={
                    <ReportingObjectsMenuBody
                        onReportingObjectsSelect={onReportingObjectsSelect}
                        selectedReportingObjectsIdsTrans={data.value}
                    />
                }
                isContextualMenuOpen={isContextualMenuOpen}
                hideContextualMenu={closeContextualMenu}
                targetRef={ref}
                gapSpace={5}
            />
        </Wrapper>
    );
};

export default ReportingObjects;
