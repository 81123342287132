import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../../lib/esm/components';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import BackgroundHeader from '../components/Header/Header';
import AccordionItemContent from '../components/AccordionItemContent/AccordionItemContent';
import { ListBullets, ListItemBullets, Description} from '../styles';
import { peopleCountingMetricsData, salesFlowMetricsData, shopsterMetricsData } from './data';


/**
 * Компонент модуля сабмодуля Метрики
 */
const Metrics: React.FC = ({}) => {
    const { t } = useTranslation('translationHelps');
    return (
        <ModuleWrapper>
            <BackgroundHeader title={t('Metrics')} />
            <WrapperContainer>
                <Title titleLevel="h2" margin="b_md">
                    {t('People counting system')}
                </Title>

                <Description>
                    {t(
                        'Provides data on the number of people who crossed a certain passage. The system counts people over 120 cm tall.',
                    )}
                </Description>
                <AccordionItemContent data={peopleCountingMetricsData}></AccordionItemContent>

                <Title titleLevel="h2" margin="t_md b_md">
                    {t('Shopster')}
                </Title>
                <Description>
                    {t('Tracking object visitors by MAC addresses of their mobile devices with Wi-Fi sensors.')}
                </Description>

                <AccordionItemContent data={shopsterMetricsData}></AccordionItemContent>

                <Title titleLevel="h2" margin="t_md b_md">
                    {t('Automated sales flow system')}
                </Title>
                <Description>
                    {t('Collecting data about sales amounts and tenants’ checks in the following ways:')}
                </Description>
                <ListBullets>
                    <ListItemBullets>
                        {t(
                            'Unloading data from the personal account of the fiscal data operator (OFD) through the API.',
                        )}
                    </ListItemBullets>
                    <ListItemBullets>
                        {t("Direct connection of the program (Module) to the tenant's database.")}
                    </ListItemBullets>
                    <ListItemBullets>
                        {t("The tenant uploads the sales data to the generated file (FTP / File) once a day, after which the data arrives at our server for further processing.")}
                    </ListItemBullets>
                </ListBullets>
                <AccordionItemContent data={salesFlowMetricsData}></AccordionItemContent>
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Metrics;
