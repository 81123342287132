import _, { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAreaObject, IRatingData, IRatingDataItem } from '../interfaces';
import {
    Performance_Ratings_RatingList_Widget_Reducer_Values,
    storeRatingsData,
    storeRatingsDataRecived,
} from '../reducer';

export const usePrepareData = () => {
    const { responseResult, ratingsDataRecived } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    const dispatch = useDispatch();

    useEffect(() => {
        if (responseResult) {
            const storeDataIdsRecived = cloneDeep(ratingsDataRecived);
            const result = _.cloneDeep(responseResult);
            const finalRatings: IRatingData[] = [];
            const objectsArea: IAreaObject[] = [];
            const dataIdsRecived: number[] = [];

            /**
             * Создаем массив каждого рейтинга
             */
            if (Array.isArray(result)) {
                /**
                 * Создаем массив объектов с данными по площадям
                 */
                _.forEach(result, (item) => {
                    if (item.request.length !== 0) {
                        _.forEach(item.request, (item) => {
                            const alias = item[0].context.alias;

                            if (String(alias.split(':')[1]) === 'Area') {
                                _.forEach(item, (object) => {
                                    const id = _.get(object, 'context.data_objects[0].id');
                                    const value = _.get(object, 'items[0].value');
                                    const finalObject = {
                                        id,
                                        value,
                                    };
                                    objectsArea.push(finalObject);
                                });
                            }
                        });
                    }
                });

                /**
                 * Создаем массив рейтинга с данными
                 */
                _.forEach(result, (item) => {
                    dataIdsRecived.push(item.id);
                    const ratingMainPeriodObjects = item?.request?.[0];
                    const ratingComparePeriodObjects: any[] = item?.request?.[1];
                    const error = item.error;
                    const objects: IRatingDataItem[] = [];
                    _.forEach(ratingMainPeriodObjects, (object) => {
                        const objectId = object.context.data_objects[0].id;
                        const objectName = object.context.data_objects[0].name;
                        const mainPeriodValue = object.items[0].value;
                        const comparePeriodObject = ratingComparePeriodObjects?.find(
                            (x: any) => x.context.data_objects[0].id === objectId,
                        );
                        const comparePeriodValue = comparePeriodObject ? comparePeriodObject.items[0].value : 0;

                        const colorValue: number = Number(
                            (
                                ((Number(mainPeriodValue) - Number(comparePeriodValue)) / Number(comparePeriodValue)) *
                                100
                            ).toFixed(2),
                        );
                        const delta: number = Number(
                            (_.isNumber(comparePeriodValue) && _.isNumber(mainPeriodValue)
                                ? mainPeriodValue - comparePeriodValue
                                : NaN
                            ).toFixed(2),
                        );
                        const areaValue = objectsArea?.find((x) => x.id === objectId)?.value;
                        const area = areaValue ? areaValue : 0;
                        objects.push({
                            id: objectId,
                            name: objectName,
                            values: {
                                area,
                                delta,
                                deviation: colorValue,
                                value: mainPeriodValue,
                            },
                        });
                    });
                    finalRatings.push({
                        id: item.id,
                        objects,
                        error,
                    });
                });
                const allDataIdsRecived = _.uniq(dataIdsRecived.concat(storeDataIdsRecived));

                dispatch(storeRatingsDataRecived(allDataIdsRecived));
                dispatch(storeRatingsData(finalRatings));
            }
        }
    }, [responseResult]);
};
