import { CardsWrapper, LeasingCrossVisitsWidgetWrapper } from './styles';
import { Leasing_CrossVisits_Widget_Reducer_Values, reloadWidget, storeSelectedReportingObjects } from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';

import Card from './components/Card/Card';
import FiltersMonitor from '../../../../../Filters/FiltersMonitor/FiltersMonitor';
import { ISelectedReportingObject } from './interfaces';
import { IdsOfREportingObjectTypes } from '../../constants/optionsOfReportingObjectType';
import { LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE } from '../../constants/constants';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useRerender } from '../../../../../../../tools/useRerender';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета Leasing:Cross-visits
 */
const LeasingCrossVisitsWidget = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Leasing:Cross-visits');
    const {
        extendedReportingObjectsById,
        selectedReportingObjects,
        intersectionsDataById,
        daysInPeriod,
        isLoading,
        isError,
    } = useSelector(Leasing_CrossVisits_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsById, tenant2FloorByTenantId, tenant2ZoneByTenantId },
        src: { dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const storeSelectedReportingObjectsTrans = (data: ISelectedReportingObject[]) => {
        dispatch(storeSelectedReportingObjects(data));
    };

    const cards = useMemo(() => {
        if (
            localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE] &&
            localCurrentOptions?.mainDateRanges &&
            Object.keys(dataObj2ProjectCategory) &&
            Object.keys(tenant2FloorByTenantId) &&
            Object.keys(tenant2ZoneByTenantId) &&
            Object.keys(reportingObjectsById) &&
            localCurrentOptions?.mainPeriod &&
            extendedReportingObjectsById
        ) {
            return Object.keys(extendedReportingObjectsById).map((key) => {
                const extendedReportingObject = extendedReportingObjectsById[key];

                return (
                    <Card
                        intersectionsDataById={
                            intersectionsDataById
                                ? intersectionsDataById[extendedReportingObject.reportingObject.id]
                                : null
                        }
                        reportingObjectsType={localCurrentOptions[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE][0].text}
                        storeSelectedReportingObjectsTrans={storeSelectedReportingObjectsTrans}
                        extendedReportingObjectsById={extendedReportingObjectsById}
                        selectedReportingObjects={selectedReportingObjects}
                        mainDateRanges={localCurrentOptions.mainDateRanges}
                        extendedReportingObject={extendedReportingObject}
                        dataObj2ProjectCategory={dataObj2ProjectCategory}
                        key={extendedReportingObject.reportingObject.id}
                        filters={localCurrentOptions?.['filters'] || []}
                        tenant2FloorByTenantId={tenant2FloorByTenantId}
                        tenant2ZoneByTenantId={tenant2ZoneByTenantId}
                        mainPeriod={localCurrentOptions.mainPeriod!}
                        reportingObjectsById={reportingObjectsById}
                        daysInPeriod={daysInPeriod}
                        isLoading={isLoading}
                        isError={isError}
                    />
                );
            });
        }
        return null;
    }, [
        localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE],
        localCurrentOptions?.['filters'],
        localCurrentOptions?.mainPeriod,
        extendedReportingObjectsById,
        selectedReportingObjects,
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        intersectionsDataById,
        reportingObjectsById,
        daysInPeriod,
        isLoading,
        isError,
    ]);

    const rerender = useRerender(cards);

    return (
        <LeasingCrossVisitsWidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Cross-visits')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidget}
                        widgetName={'Cross-visits'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            {localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE][0].id ===
                IdsOfREportingObjectTypes.Tenants && <FiltersMonitor />}
            <CardsWrapper>{cards}</CardsWrapper>
        </LeasingCrossVisitsWidgetWrapper>
    );
};

export default LeasingCrossVisitsWidget;
