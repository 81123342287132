import { IMetricResponseItem, IPeriod, TMetricResponse } from '../../../../../../../../General.interfaces';
import { IAggregatedMetricsData } from '../interfaces';

interface IArgs {
    /**
     * Сырые данные
     */
    rawMetricsData: TMetricResponse[];
}

/**
 * Функция для аггрегации данных по метрикам
 */
const aggregateRawMetrcisData = (args: IArgs): IAggregatedMetricsData => {
    const { rawMetricsData } = args;

    const result: IAggregatedMetricsData = {};

    rawMetricsData.forEach((metricResponse) => {
        metricResponse.forEach((data) => {
            const tenantId = data.context.data_objects[0].id;
            const subtypeId = data.context.alias!.split(':')[2];
            const periodType = data.context.alias!.split(':')[3];

            if (!result[tenantId]) {
                result[tenantId] = {
                    [subtypeId]: {
                        comparePeriod: periodType === 'compare' ? data : null,
                        mainPeriod: periodType === 'main' ? data : null,
                    },
                };
            } else if (result[tenantId][subtypeId]) {
                const prevValue = result[tenantId][subtypeId];
                let newValue: { [periodType: string]: IMetricResponseItem } = {
                    mainPeriod: data,
                };
                if (periodType === 'compare') {
                    newValue = {
                        comparePeriod: data,
                    };
                }
                result[tenantId][subtypeId] = {
                    ...prevValue,
                    ...newValue,
                };
            } else {
                const prevValue = result[tenantId];
                result[tenantId] = {
                    ...prevValue,
                    [subtypeId]: {
                        comparePeriod: periodType === 'compare' ? data : null,
                        mainPeriod: periodType === 'main' ? data : null,
                    },
                };
            }
        });
    });

    return result;
};

export default aggregateRawMetrcisData;
