import { __spreadArray } from '../_virtual/_tslib.js';
import { useState, useEffect } from 'react';
import useColor from './color/useColor.js';
import useHover from './hover/useHover.js';
import useBorderRadius from './borderRadius/useBorderRadius.js';
import useShadow from './shadow/useShadow.js';
import useIndent from './indent/useIndent.js';
import useClasses from './classes/useClasses.js';

var useStyles = function (args) {
    var _a = useState([]), mergedClasses = _a[0], setMergedClasses = _a[1];
    var props = args.props, ref = args.ref, constants = args.constants;
    var colorClasses = useColor({ styles: { color: props === null || props === void 0 ? void 0 : props.color, bgColor: props === null || props === void 0 ? void 0 : props.bgColor }, constants: constants });
    var hoverClasses = useHover({ ref: ref, styles: { hover: props === null || props === void 0 ? void 0 : props.hover }, constants: constants });
    var shadowClasses = useShadow({ ref: ref, styles: { shadow: props === null || props === void 0 ? void 0 : props.shadow }, constants: constants });
    var indentClasses = useIndent({ styles: { margin: props === null || props === void 0 ? void 0 : props.margin, padding: props === null || props === void 0 ? void 0 : props.padding }, constants: constants });
    var addClassesClasses = useClasses({ styles: { addClasses: props === null || props === void 0 ? void 0 : props.addClasses }, constants: constants });
    var borderRadiusClasses = useBorderRadius({
        styles: { borderRadius: props === null || props === void 0 ? void 0 : props.borderRadius, borderRadiusExclude: props === null || props === void 0 ? void 0 : props.borderRadiusExclude },
        constants: constants,
    });
    useEffect(function () {
        setMergedClasses(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], colorClasses, true), hoverClasses, true), borderRadiusClasses, true), shadowClasses, true), indentClasses, true), addClassesClasses, true));
    }, [borderRadiusClasses, colorClasses, hoverClasses, shadowClasses, indentClasses, addClassesClasses]);
    return mergedClasses;
};

export { useStyles as default };
