import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../../../../tools/getColorFromPercent';

export const PercentValueWrapper = styled.div<{ value?: number | string | null }>`
    white-space: nowrap;
    background-color: rgba(235, 235, 235, 0.3);
    padding: var(--space-xxxs) var(--space-xxs);
    color: ${(p) => getColorFromPercent(p.value)};
    font-weight: 600;
    max-width: var(--space-xxxl);
    min-width: var(--space-xxxl);
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0 !important;
    height: 100%;
`;

export const ComparePeriodValue = styled.div`
    padding: var(--space-xxxs) var(--space-xxs);
    flex-shrink: 0 !important;
    height: 100%;
    align-items: center;
    display: flex;
`;
