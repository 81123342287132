import { IGetCurrentOptionValueArgs } from './interfaces';

/**
 * Функция для получения понятного значения для настройки пользователя
 * @param reportingObjectsById Объект отчетных объектов, где ключ это ID отчетного объекта
 * @param currentOptions Текущие настройки пользователя
 * @param allMetrics Все метрики
 * @param key Ключ для которого нужно получить значения
 * @returns Значение по ключу или список значений
 */
const getCurrentOptionValueFromKey = (args: IGetCurrentOptionValueArgs): string | string[] => {
    const { key, currentOptions, reportingObjectsById, allMetrics } = args;
    if (key === 'mainPeriod') {
        return (
            currentOptions.mainDateRanges?.find((element) => element.id === currentOptions.mainPeriod?.id)?.text ||
            JSON.stringify(currentOptions.mainPeriod)
        );
    }

    if (key === 'comparePeriods') {
        const comparePeriodsIds = currentOptions.comparePeriods?.map((element) => element.id);
        return currentOptions.compareDateRanges
            ?.filter((element) => comparePeriodsIds?.includes(element.id))
            .map((element) => element.text);
    }

    if (key === 'selectedReportingObjectsIds') {
        return (
            currentOptions.selectedReportingObjectsIds?.map((element) => reportingObjectsById?.[element]?.name || '') ||
            JSON.stringify(currentOptions.selectedReportingObjectsIds)
        );
    }

    if (key === 'selectedMetrics') {
        return (
            currentOptions[key]?.map((metric) => {
                const text = allMetrics?.find((element) => element.id === metric);
                return text?.text || metric;
            }) || JSON.stringify(currentOptions[key])
        );
    }

    return JSON.stringify(currentOptions[key]);
};

export default getCurrentOptionValueFromKey;
