import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { increaserDownloadSignalCounter, Reports_ByTenants_Widget_Reducer_Values } from './reducer';
import SaveXLSXFileButton from '../../../../../SaveXLSXFileButton/SaveXLSXFileButton';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../tools/API/constants';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import { Button } from '../../../../../../../lib/esm/components';
import { Footer, TableWrapper, WidgetWrapper } from './styles';
import useGridGenerator from './hooks/useGridGenerator';
import { usePrepareData } from './hooks/usePrepareData';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactDataSheet from 'react-datasheet';
import React, { useMemo } from 'react';

/**
 * Компонент для отображения виджета загрузки отчета по арендаторам
 */
const TenantsReportWidget: React.FC = ({}) => {
    const { rawMetricsData, grid } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const { selectedLocationId, locations, structures, currentModule } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions('Reports:By tenants');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useGridGenerator();
    usePrepareData();

    const locationName = locations?.find((item) => item.id === selectedLocationId)?.name;

    const onDownloadClick = () => {
        dispatch(increaserDownloadSignalCounter());
    };

    const table = useMemo(() => {
        if (rawMetricsData) {
            const data = rawMetricsData['status']
                ? rawMetricsData
                : !structures
                ? { status: ResponseStatus.Error, message: t('Get structure error') }
                : [];

            const WithLoadingTable = withLoading(TableWrapper, { height: 400, data });
            return (
                <WithLoadingTable>
                    {grid && <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />}
                </WithLoadingTable>
            );
        }
        return null;
    }, [rawMetricsData, grid, structures, t]);

    return (
        <WidgetWrapper>
            {table}
            <Footer>
                <Button
                    text={t('Download')}
                    disabled={
                        !!!localCurrentOptions?.selectedReportingObjectsIds?.length ||
                        !!!localCurrentOptions?.selectedMetrics?.length
                    }
                    onClick={onDownloadClick}
                />
                <SaveXLSXFileButton
                    grid={grid || []}
                    disabled={!!!grid}
                    bookName={`${locationName} report by tenants`}
                    currentModule={currentModule}
                />
            </Footer>
        </WidgetWrapper>
    );
};

export default TenantsReportWidget;
