import { DateTime, Interval } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { stringDate } from '../../../../../../../../tools/Strings/stringDate';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { IEvent } from '../../../../EventsMap/widgets/EventsMapWidget/interfaces';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../EventsMap/widgets/EventsMapWidget/reducer';

interface IArgs {
    moduleName: string;
}

const usePrepareData = (args: IArgs) => {
    const { moduleName } = args;
    const { lang } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const [filteredEvents, setFilteredEvents] = useState<IEvent[]>([]);
    const { extendedEventsDataById } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const [period, setPeriod] = useState({
        stringValue: '',
        value: {
            dateFrom: '',
            dateTo: '',
        },
    });
    const { t } = useTranslation();

    /** Получение текущего периода*/
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            const period = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod?.id,
            )?.period;
            if (period) {
                setPeriod({
                    stringValue: stringDate(period, lang, '', 'dd MMM yyyy'),
                    value: period,
                });
            }
        }
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, t, lang]);

    /** Получение отфильтрованных событий */
    useEffect(() => {
        if (Object.values(extendedEventsDataById).length) {
            const filteredEventsByDate = Object.values(extendedEventsDataById)?.filter((event) => {
                let flag = false;
                const periodInterval = Interval.fromDateTimes(
                    DateTime.fromISO(period.value.dateFrom),
                    DateTime.fromISO(period.value.dateTo).endOf('day'),
                );

                const eventIntervalDays = Interval.fromDateTimes(
                    DateTime.fromISO(event.date_from),
                    DateTime.fromISO(event.date_to),
                )
                    .splitBy({ days: 1 })
                    .map((d) => d.start);

                eventIntervalDays.forEach((element) => {
                    if (!flag && periodInterval.contains(element)) {
                        flag = true;
                    }
                });

                return flag;
            });
            setFilteredEvents(filteredEventsByDate);
        } else {
            setFilteredEvents([]);
        }
    }, [period, extendedEventsDataById]);

    return { filteredEvents, period };
};

export default usePrepareData;
