import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import { OptionsWrapper } from './styles/styles';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../Preset/Preset';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import SummaryTable from './widgets/SummaryTable/SummaryTable';
import Info from './widgets/Info/Info';
import DynamicsPMO from './widgets/DynamicsPMO/DynamicsPMO';
import Reach from './widgets/Reach/Reach';
import Reference from './widgets/Reference/Reference';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import { useTranslation } from 'react-i18next';
import { ISettings } from '../../../UserSettings/interfaces';
import IntersectionsWithTenants from './widgets/IntersectionsWithTenants/IntersectionsWithTenants';
import EventsCarousel from '../../Events/Events/widgets/EventsCarousel/EventsCarousel';
import { Leasing_TenantOverview_Module_Reducer_Values } from './reducer';

const TenantOverview: React.FC = ({}) => {
    const { t } = useTranslation();
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);

    const options = useMemo(() => {
        return reportingObjectsByType['tenant']?.map((item) => ({ id: item.id, text: item.name }));
    }, [reportingObjectsByType]);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ComparePeriodSelect />
                        <UniversalSelect
                            showFilter
                            mode="single"
                            options={options}
                            name={'tenants'}
                            labelText={'Tenant'}
                            text={t('Select tenant')}
                            dropdownWidth="sm"
                        />
                        <MetricsSelect />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'mainPeriod',
                                'comparePeriods',
                                'selectedMetrics',
                                'tenants' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <Info />
                <SummaryTable />
                <EventsCarousel moduleName={moduleName} />
                <DynamicsPMO />
                <Reach />
                <Reference />
                <IntersectionsWithTenants />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default TenantOverview;
