import { ColorAxisOptions } from 'highcharts';
import { PercentColor } from '../percent-colors.enum';

export const colorAxisOptions: ColorAxisOptions = {
    startOnTick: false,
    endOnTick: false,
    min: -100,
    max: 100,
    stops: [
        [0.35,      PercentColor.Negative1  ],  // red
        [0.4,       PercentColor.Negative2  ],  // red-
        [0.45,      PercentColor.Negative3  ],  // red--
        [0.475,     PercentColor.Negative4  ],  // red---
        [0.485,     PercentColor.Negative5  ],  // red----
        [0.51499,   PercentColor.Neutral    ],  // yellow
        [0.52499,   PercentColor.Positive5  ],  // green----
        [0.54999,   PercentColor.Positive4  ],  // green---
        [0.59999,   PercentColor.Positive3  ],  // green--
        [0.64999,   PercentColor.Positive2  ],  // green-
        [1,         PercentColor.Positive1  ]   // green
    ],
    labels: {
        step: 1
    },
    maxPadding: 1
};