import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { storePreraredData, Leasing_TenantOverview_Reach_Widget_Reducer_Values } from '../reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';

export const usePrepareData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { responseResult, tenantId2zoneId } = useSelector(Leasing_TenantOverview_Reach_Widget_Reducer_Values);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    useEffect(() => {
        if (!responseResult || responseResult?.length === 0) return;

        const byObjectType = {};

        // Создаем удобный объект
        responseResult.forEach((res) => {
            const ids = localCurrentOptions?.['tenants']?.map((item: any) => item.id);
            res.forEach((data) => {
                const objId = data.context.data_objects[0].id;
                const isSelectedTenant = ids?.includes(objId);
                const type = data.context.alias || '';
                const value = data.items[0].value;
                const objName = data.context.data_objects[0].name;
                if (!byObjectType.hasOwnProperty(type)) {
                    byObjectType[type] = {};
                }

                byObjectType[type][objId] = { value, objName, objId, isSelectedTenant };
            });
        });

        // Делаем массив из объекта
        const preparedData = Object.keys(byObjectType['tenants']).map((tenantId) => {
            const tenant = byObjectType['tenants'][tenantId];
            const row = [];
            row.push({ name: tenant.objName, isSelectedTenant: tenant.isSelectedTenant });
            row.push({ value: tenant.value, type: 'tenant' });
            const zoneId = tenantId2zoneId?.find((item) => String(item.tenantId) === String(tenantId))?.zoneId || null;
            const zone = byObjectType['zones'][zoneId];
            const percentValue = (tenant.value * 100) / zone.value;
            row.push({ value: percentValue, type: 'percent' });
            row.push({ name: zone.objName });
            row.push({ value: zone.value, type: 'zone' });
            return row;
        });
        dispatch(storePreraredData(preparedData));
    }, [responseResult, reportingObjectsByType, dispatch, tenantId2zoneId]);
};
