import { IPeriod, IReportingObject, ITenant2ZoneRelation } from '../../../../../../../../General.interfaces';
import { DateTime } from 'luxon';

interface IArgs {
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    tenant2ZoneRelations: ITenant2ZoneRelation[];
    selectedGroupMarker: string;
    mainDateRanges: IPeriod[];
    tenant: IReportingObject;
    mainPeriod: IPeriod;
}

/**
 * Получение зоны для тенанта
 * @param reportingObjectsByTypeAndMarker Объект отчетных объектов где ключ это [тип:маркер]
 * @param tenant2ZoneRelations  Взаимосвязь арендатора и зоны
 * @param selectedGroupMarker Выбранный маркер группы
 * @param mainDateRanges Периоды дат для главного периода
 * @param mainPeriod Главный период
 * @param tenant арендатор
 */
const filterTenantValidZone = (args: IArgs): IReportingObject | null => {
    const {
        reportingObjectsByTypeAndMarker,
        tenant2ZoneRelations,
        selectedGroupMarker,
        mainDateRanges,
        mainPeriod,
        tenant,
    } = args;
    const mainPeriodDateRanges = mainDateRanges?.find((element) => element.id === mainPeriod.id);
    const MIN_CONTENT_PERCENTAGE = 99;

    if (mainPeriodDateRanges) {
        const mainPeriodDateFrom = DateTime.fromISO(mainPeriodDateRanges.period?.dateFrom || '');
        const mainPeriodDateTo = DateTime.fromISO(mainPeriodDateRanges.period?.dateTo || '');

        const validZone = tenant2ZoneRelations?.find((relation) => {
            const relationDateFrom = DateTime.fromISO(relation.date_from);
            const relationDateTo = DateTime.fromISO(relation.date_to);

            return (
                relation.content_percentage > MIN_CONTENT_PERCENTAGE &&
                relation.group_marker === selectedGroupMarker &&
                relationDateFrom <= mainPeriodDateFrom &&
                relationDateTo >= mainPeriodDateTo &&
                relation.tenant_id === tenant.id
            );
        });

        if (validZone) {
            const result = reportingObjectsByTypeAndMarker[`zone:${validZone.zone_marker}`];

            if (result) return result;
        }
    }

    return null;
};

export default filterTenantValidZone;
