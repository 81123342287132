import React, { useEffect, useLayoutEffect, useState } from 'react';

export const useWindowResize = (ref: React.MutableRefObject<null>) => {
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    useLayoutEffect(() => {
        window.onresize = windowResizeHandler;
    }, []);

    useEffect(() => {
        if (ref?.current) {
            setWidth(() => ref?.current?.['offsetWidth'] || 0);
            setHeight(() => ref?.current?.['offsetHeight'] || 0);
        }
    }, []);

    const windowResizeHandler = () => {
        if (ref?.current) {
            setWidth(() => ref?.current?.['offsetWidth'] || 0);
            setHeight(() => ref?.current?.['offsetHeight'] || 0);
        }
    };

    return { width, height };
};
