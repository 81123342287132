import React from 'react';
import { METRIC_LEVEL_OPTIONS } from './constants/metricLevelOptions';
import { TMetricLevel } from './interfaces';
import Panel from '../../../Panel/Panel';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cabinetPreferencesValues, storeMetricLevel } from '../../reducer';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';

/**
 * Компонент для отображения настроек уровня запроса метрик
 */
const General: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cabinetPreferences = useSelector(cabinetPreferencesValues);

    const {
        preferences: { metricLevel },
    } = cabinetPreferences;

    const onMetricLevelSelect = React.useCallback(
        (ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
            dispatch(storeMetricLevel(option.key as TMetricLevel));
        },
        [],
    );

    return (
        <>
            <Panel title={'Metrics level'}>
                <ChoiceGroup
                    options={METRIC_LEVEL_OPTIONS.map((element) => ({ ...element, text: element.text }))}
                    onChange={onMetricLevelSelect}
                    selectedKey={metricLevel}
                />
            </Panel>
        </>
    );
};

export default General;
