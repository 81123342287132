import { IMetricResponseItem } from '../../../../YearOverYear/widgets/YearOverYear/tools/interfaces';
import { IAggregatedObjectsById, IAggregatedObject, IResponseItem } from './interfaces';
import { TLoadingData } from '../../../../../../../../lib/esm/General.interfaces';
import { VISITORS_INSIDE_METRIC } from '../constants/constants';
import { cloneDeep } from 'lodash';

/**
 * Функция для обработки сырых данных с сервера и аггрегации их по id отчтетного объекта
 * @param reportingObjectsRowMetricsDataById Объект сырых данных для каждого из отчетных объектов
 * @param shoudUpdateChartsByPeriod Флаг, отвечающий за обновление всех отчетных объектов
 * @param rowMetricsData Сырые данные с сервера
 * @returns Объект, где ключ это id отчетного объекта, а значение это саггрегированные данные
 */
const aggregateMetricsResponse = (
    rowMetricsData: TLoadingData,
    reportingObjectsRowMetricsDataById: { [reportingObjectId: string]: TLoadingData },
    shoudUpdateChartsByPeriod: boolean,
): { [objectId: string]: TLoadingData } => {
    if (Array.isArray(rowMetricsData)) {
        /**
         * Получение объекта, где лючом является id объекта, а в его значение включены данные
         * по двум метрикам (вход и выход) по всем переданным периодам
         */
        const aggregatedObjects = rowMetricsData.reduce((acc: IAggregatedObjectsById, value: IMetricResponseItem[]) => {
            value.forEach((element) => {
                const objectId: number = element.context.data_objects[0].id;
                const timeRange = element.context.time_range;
                if (!acc[objectId]) {
                    acc[objectId] = {
                        objectInfo: element.context.data_objects[0],
                        [VISITORS_INSIDE_METRIC]: {
                            [`${timeRange[0]}`]: element.items,
                        },
                    };
                } else if (!acc[objectId][VISITORS_INSIDE_METRIC]) {
                    acc[objectId][VISITORS_INSIDE_METRIC] = { [`${timeRange[0]}`]: element.items };
                } else if (!acc[objectId][VISITORS_INSIDE_METRIC][`${timeRange[0]}`]) {
                    acc[objectId][VISITORS_INSIDE_METRIC][`${timeRange[0]}`] = element.items;
                }
            });
            return acc;
        }, {});

        /**
         * Получение массива, где каждый элемент это объект со значениями метрики
         * VisitorsInside (она получатеся путем вычета из метрика входа метрики выхода) по
         * всем переданным периодам
         */
        const finalAggregatedData = Object.keys(aggregatedObjects).reduce((acc, objectId) => {
            const aggregatedObject: IAggregatedObject = {
                objectInfo: aggregatedObjects[objectId].objectInfo,
                visitorsInside: {},
            };

            Object.keys(aggregatedObjects[objectId][VISITORS_INSIDE_METRIC] || []).forEach((date) => {
                let visitorsInside = 0;
                aggregatedObjects[objectId][VISITORS_INSIDE_METRIC]?.[date].forEach((item: IResponseItem) => {
                    if (item.value) visitorsInside += item.value;

                    aggregatedObject.visitorsInside && aggregatedObject.visitorsInside[date]
                        ? aggregatedObject.visitorsInside[date].push({
                              ...item,
                              value: item.value === null ? null : visitorsInside,
                          })
                        : (aggregatedObject.visitorsInside![date] = [
                              { ...item, value: item.value === null ? null : visitorsInside },
                          ]);
                });
            }, {});

            acc[objectId] = [aggregatedObject];

            return acc;
        }, {});
        return { ...reportingObjectsRowMetricsDataById, ...finalAggregatedData };
    }

    return Object.keys(reportingObjectsRowMetricsDataById).reduce(
        (acc: { [objectId: string]: TLoadingData }, value) => {
            const data = cloneDeep(reportingObjectsRowMetricsDataById[value]);
            if (shoudUpdateChartsByPeriod) {
                acc[value] = rowMetricsData;
            } else if (!Array.isArray(data)) {
                acc[value] = rowMetricsData;
            } else {
                acc[value] = data;
            }

            return acc;
        },
        {},
    );
};

export default aggregateMetricsResponse;
