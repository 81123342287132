import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IRequestMetricsArgs } from '../../../../../../../../lib/esm/components';
import { storeResponseResult, storePreraredData, Events_Events_Summary_Widget_Reducer_Values } from '../reducer';
import { userSettingsReducerValues } from '../../../../../../UserSettings/reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { usePrepareData } from './usePrepareData';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { Events_Events_Module_Reducer_Values } from '../../../reducer';

export const useGetMetrics = () => {
    usePrepareData();
    const {
        token,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const localCurrentOptions = useWidgetCurrentOptions('Events:Events');
    const { currentEvent } = useSelector(Events_Events_Module_Reducer_Values);
    const { dataRefetchObject } = useSelector(Events_Events_Summary_Widget_Reducer_Values);

    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);

    const fetchData = useRequestMetrics();

    useEffect(() => {
        if (!localCurrentOptions?.selectedReportingObjectsIds || !token || !reportingObjectsById || !currentEvent)
            return;

        const controller = new AbortController();
        const signal = controller.signal;
        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];
        const compareTimeRanges = localCurrentOptions.compareDateRanges
            ?.filter((item) => localCurrentOptions.comparePeriods?.find((p) => p.id === item.id) !== undefined)
            .map((item) => ({ id: item.id, range: [item.period.dateFrom, item.period.dateTo] }));

        const requests: IRequestMetricsArgs[] = [];

        localCurrentOptions?.selectedMetrics?.forEach((metric) => {
            const mainRequest = {
                signal,
                token,
                alias: `main:${metric}`,
                metric,
                metric_level: metricLevel,
                obj_ids: localCurrentOptions.selectedReportingObjectsIds || [],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            requests.push(mainRequest);

            compareTimeRanges.forEach((compareRange) => {
                const compareRequest = {
                    signal,
                    token,
                    alias: `${compareRange.id}:${metric}`,
                    metric,
                    metric_level: metricLevel,
                    obj_ids: localCurrentOptions?.selectedReportingObjectsIds || [],
                    object_aggregation: false,
                    time_range: compareRange.range,
                    time_freq: null,
                };

                requests.push(compareRequest);
            });
        });

        dispatch(storePreraredData({ status: 'Loading', message: t('Loading...') }));
        dispatch(storeResponseResult(null));

        fetchData(requests).then((res: any) => {
            handleServerResponse({
                responseAnalyzer,
                success: storeResponseResult,
                error: storePreraredData,
                dispatch,
                res,
            });
        });

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.mainPeriod,
        localCurrentOptions?.comparePeriods,
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.selectedReportingObjectsIds,
        reportingObjectsById,
        dataRefetchObject,
        currentEvent,
        token,
    ]);
};
