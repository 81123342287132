import { useSelector } from 'react-redux';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import { Container } from '../../../Wrappers/WrapperContainer/styles';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import { Events_Events_Module_Reducer_Values } from './reducer';
import usePrepareData from './hooks/usePrepareData';
import Header from './components/Header/Header';
import SummaryTable from './widgets/SummaryTable/SummaryTable';
import DynamicsPMO from './widgets/DynamicsPMO/DynamicsPMO';
import TableOfObjects from './widgets/TableOfObjects/TableOfObjects';

/**
 * Компонент для отображения модуля События
 */
const Events = () => {
    const { selectedEventId, currentEvent } = useSelector(Events_Events_Module_Reducer_Values);
    usePrepareData();

    return (
        <ModuleWrapper>
            {selectedEventId && currentEvent ? (
                <>
                    <Header event={currentEvent} />
                    <Container>
                        <SummaryTable />
                        <DynamicsPMO />
                        <TableOfObjects />
                    </Container>
                </>
            ) : (
                <Container>
                    <ErrorMessage />
                </Container>
            )}
        </ModuleWrapper>
    );
};

export default Events;
