import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSignal, Reports_Basic_Widget_Reducer_Values } from './reducer';
import { Footer, TableWrapper, WidgetWrapper } from './styles';
import ReactDataSheet from 'react-datasheet';
import { Button } from '../../../../../../../lib/esm/components';
import SaveXLSXFileButton from '../../../../../SaveXLSXFileButton/SaveXLSXFileButton';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import DownloadXLSXFileButton from '../../../../../DownloadXLSXFileButton/DownloadXLSXFileButton';

const BasicReportWidget: React.FC = ({}) => {
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Basic');
    const { grid, rawMetricsData } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const { selectedLocationId, locations, currentModule } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const table = useMemo(() => {
        if (rawMetricsData) {
            const data = rawMetricsData['status'] ? rawMetricsData : [];

            const WithLoadingTable = withLoading(TableWrapper, { height: 400, data });
            return (
                <WithLoadingTable>
                    {grid && <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />}
                </WithLoadingTable>
            );
        }
        return null;
    }, [rawMetricsData, grid, t]);

    const locationName = locations?.find((item) => item.id === selectedLocationId)?.name;

    const onDownloadClick = () => {
        dispatch(downloadSignal());
    };

    return (
        <WidgetWrapper>
            {table}
            <Footer>
                <Button
                    disabled={!localCurrentOptions?.selectedReportingObjectsIds?.length}
                    text={t('Download')}
                    onClick={onDownloadClick}
                />
                <SaveXLSXFileButton
                    grid={grid}
                    disabled={grid.length === 0}
                    bookName={`${locationName} basic report`}
                    currentModule={currentModule}
                />
                <DownloadXLSXFileButton currentModule={currentModule} />
            </Footer>
        </WidgetWrapper>
    );
};

export default BasicReportWidget;
