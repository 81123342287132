import { ISeriesPoint, TSeries } from '../../interfaces';

import { AdditionalSeriesIds } from '../../constants/enums';
import { ColorType } from 'highcharts';
import { TFunction } from 'i18next';

interface IArgs {
    seriesData: ISeriesPoint[];
    seriesName: string;
    seriesColor?: ColorType;
    t: TFunction;
}

const generateTrendSeries = (args: IArgs): TSeries | null => {
    const { seriesData, seriesName, seriesColor, t } = args;

    const filteredSeriesData = seriesData?.filter((element) => element.y !== null);

    if (!filteredSeriesData.length) return null;

    const avgX =
        filteredSeriesData.reduce((acc: number, _, i: number) => {
            return (acc += i + 1);
        }, 0) / filteredSeriesData.length;

    const squareAvgX = avgX ** 2;

    const avgY =
        filteredSeriesData.reduce((acc: number, item) => {
            return (acc += Number(item.y));
        }, 0) / filteredSeriesData.length;

    const b =
        (filteredSeriesData.reduce((acc: number, _, i: number) => {
            return (acc = acc + (i + 1) * (filteredSeriesData[i].y as number));
        }, 0) -
            filteredSeriesData.length * avgX * avgY) /
        (filteredSeriesData.reduce((acc: number, _, i: number) => {
            return (acc = acc + (i + 1) * (i + 1));
        }, 0) -
            filteredSeriesData.length * squareAvgX);

    const a = avgY - b * avgX;

    const result = {
        name: `${t('Trend for')} ${seriesName || ''}`,
        type: 'line',
        additionalSeriesId: AdditionalSeriesIds.Trend,
        dashStyle: 'LongDash',
        color: seriesColor || 'grey',
        isMain: false,
        id: `Trend for ${seriesName || ''}`,
        data: [
            {
                x: filteredSeriesData[0].x,
                showTooltipBody: false,
                name: '',
                y: a + b,
            },
            {
                x: filteredSeriesData[filteredSeriesData.length - 1].x,
                y: a + b * (filteredSeriesData.length - 1),
                name: '',
                showTooltipBody: false,
            },
        ],
    };

    return result as TSeries;
};

export default generateTrendSeries;
