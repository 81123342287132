import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const Wrapper = styled.div`
    margin-top: var(--space-lg);
`;

export const ChartWrapper = styled.div`
    margin-top: var(--space-lg);
    :first-child {
        margin-top: 0px;
    }
`;

export const VisitorsCount = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
`;

export const VisitorsAtTheMomentWrapper = styled.div`
    text-align: right;
`;

export const VisitorsInsideCurrentTime = styled.div`
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 14px;
`;

export const ReportingObjectType = styled.span`
    margin-right: 10px;
    :first-letter {
        text-transform: uppercase;
    }
`;

export const ChartContainer = styled.div``;
