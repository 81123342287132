/**
 * Список углов
 */
var Exclude;
(function (Exclude) {
    /**
     * Пустое значение
     */
    Exclude["Empty"] = "";
    /**
     * Верхний правый угол
     */
    Exclude["TopRight"] = "top-right";
    /**
     * Верхний левый угол
     */
    Exclude["TopLeft"] = "top-left";
    /**
     * Нижний правый угол
     */
    Exclude["BottomRight"] = "bottom-right";
    /**
     * Нижний левый угол
     */
    Exclude["BottomLeft"] = "bottom-left";
})(Exclude || (Exclude = {}));

export { Exclude };
