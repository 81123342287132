import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { TRequestMetricsArgs } from '../../../../../../../../lib/esm/components';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { Reports_Comparison_Widget_Reducer_Values, storeRawMetricsData } from '../reducer';
import { TTimeFreq } from '../../../../../../../../components/ExportFunctions/interfaces';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import usePrepareData from './usePrepareData';

/** Кастомный хук для получения данных */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    const { token } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const { downloadSignal } = useSelector(Reports_Comparison_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions('Reports:With comparison');
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    usePrepareData();
    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!localCurrentOptions) return;
        const {
            mainDateRanges,
            mainPeriod,
            selectedReportingObjectsIds,
            detail,
            selectedMetrics,
            compareDateRanges,
            comparePeriods,
        } = localCurrentOptions!;

        if (
            !mainPeriod ||
            !mainDateRanges ||
            !detail ||
            !selectedMetrics ||
            !selectedReportingObjectsIds ||
            !compareDateRanges ||
            !comparePeriods
        )
            return;

        const mainDateRange = mainDateRanges.find((item) => item.id === mainPeriod?.id);
        const compareDateRange = compareDateRanges.find((item) => item.id === comparePeriods[0]?.id);
        if (mainDateRange && compareDateRange) {
            const timeRanges = [
                [mainDateRange.period.dateFrom, mainDateRange.period.dateTo, 'main'],
                [compareDateRange.period.dateFrom, compareDateRange.period.dateTo, 'compare'],
            ];

            const time_freq: TTimeFreq = detail.id as TTimeFreq;

            const requests: TRequestMetricsArgs = [];

            selectedMetrics.forEach((metric) => {
                timeRanges.forEach((time_range) => {
                    requests.push({
                        signal,
                        token,
                        alias: time_range[2],
                        metric,
                        metric_level: metricLevel,
                        obj_ids: selectedReportingObjectsIds,
                        object_aggregation: false,
                        time_range: time_range.slice(0, 2),
                        time_freq,
                    });
                });
            });

            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

            fetchData(requests).then((responses: TServerResponse) => {
                /** Обработка ответа для метрик */
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: responses,
                    dispatch,
                });
            });
        }

        return () => controller.abort();
    }, [downloadSignal]);
};
