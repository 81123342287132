import { useGetMetrics as useAnalysisByAreaWidget } from '../cabinet/components/Modules/Performance/Dashboard/widgets/AnalysisByArea/hooks/useFetchData';
import { useGetMetrics as useBasicReportWidget } from '../cabinet/components/Modules/Reports/Basic/widgets/BasicReportWidget/hooks/useFetchData';
import { useGetMetrics as useCasesBenchmarcsSalesWidget } from '../cabinet/components/Modules/Cases/Benchmarks/widgets/Sales/hooks/useFetchData';
import { useGetMetrics as useCasesBenchmarcsTrafficWidget } from '../cabinet/components/Modules/Cases/Benchmarks/widgets/Traffic/hooks/useFetchData';
import { useGetMetrics as useComparisonReportWidget } from '../cabinet/components/Modules/Reports/Comparison/widgets/ComparisonReportWidget/hooks/useFetchData';
import { useGetRawData as useCrossVisitsModuleRawData } from '../cabinet/components/Modules/Maps/CrossVisits/hooks/useFetchData';
import { useGetMetrics as useDashboardDynamicsPMOWidget } from '../cabinet/components/Modules/Performance/Dashboard/widgets/DynamicsPMO/hooks/useFetchData';
import { useGetMetrics as useDashboardTrafficWidget } from '../cabinet/components/Modules/Performance/Dashboard/widgets/Traffic/hooks/useFetchData';
import { useGetRawData as useIntersectionsWidgetReducer } from '../cabinet/components/Modules/Reports/Intersections/widgets/IntersectionsReportWidget/hooks/useFetchData';
//-----------------Leasing:Tenant overview
import { usePrepareData as useLeasingTOSummaryWidgetData } from '../cabinet/components/Modules/Leasing/TenantOverview/widgets/SummaryTable/hooks/usePrepareData';
import { useGetMetrics as useLeasing_TO_DynamicsPMO_Widget } from '../cabinet/components/Modules/Leasing/TenantOverview/widgets/DynamicsPMO/hooks/useFetchData';
import { useGetMetrics as useLeasing_TO_Reach_Widget } from '../cabinet/components/Modules/Leasing/TenantOverview/widgets/Reach/hooks/useFetchData';
import { useGetMetrics as useLeasing_TO_Reference_Widget } from '../cabinet/components/Modules/Leasing/TenantOverview/widgets/Reference/hooks/useFetchData';
import { useGetMetrics as useMapsOverviewWidget } from '../cabinet/components/Modules/Maps/MapsOverview/widgets/MapsWidget/hooks/useFetchData';
import { useGetRawData as usePathToPurchaseWidgetRawData } from '../cabinet/components/Modules/Performance/PathToPurchase/widgets/PathToPurchaseWidget/hooks/useFetchData';
import { useGetMetrics as usePerformanceVisitorsInsideWidget } from '../cabinet/components/Modules/Performance/VisitorsInside/widgets/VisitorsInside/hooks/useFetchData';
import { useFetchData as usePerformanceRatingsWidget } from '../cabinet/components/Modules/Performance/Ratings/widgets/RatingList/hooks/useFetchData';
import { useGetMetrics as usePerformanceYearOverYearWidget } from '../cabinet/components/Modules/Performance/YearOverYear/widgets/YearOverYear/hooks/useFetchData';
import { usePrepareData as useSummaryTable } from '../cabinet/components/Modules/Performance/Dashboard/widgets/SummaryTable/hooks/usePrepareData';
import { useGetRawData as useTenantsWidgetReducer } from '../cabinet/components/Modules/Reports/Tenants/widgets/TenantsReportWidget/hooks/useFetchData';
import { useGetRawData as useLeasing_TO_IntersectionsWithTenants_Widget } from '../cabinet/components/Modules/Leasing/TenantOverview/widgets/IntersectionsWithTenants/hooks/useFetchData';
//-----------------Leasing:Tenants
import { useGetRawData as useLeasingTenantsWidgetRawData } from '../cabinet/components/Modules/Leasing/Tenants/widgets/TenantsWidget/hooks/useFetchData';
//-----------------Leasing:Cross-visits
import { useGetRawData as useLeasingCrossVisitsWidgetRawData } from '../cabinet/components/Modules/Leasing/CrossVisits/widgets/LeasingCrossVisitsWidget/hooks/useFetchData';
//-----------------Events:Events
import { useGetMetrics as useEventsSummaryTableWidget } from '../cabinet/components/Modules/Events/Events/widgets/SummaryTable/hooks/useFecthData';
import { useGetMetrics as useEventsDynamicsPMOWidget } from '../cabinet/components/Modules/Events/Events/widgets/DynamicsPMO/hooks/useFetchData';
import { useGetRawData as useEventsTableOfObjectsWidget } from '../cabinet/components/Modules/Events/Events/widgets/TableOfObjects/hooks/useFetchData';
//-----------------Sales:Payment analysis
import { useGetRawData as usePaymentStructureWidget } from '../cabinet/components/Modules/Sales/PaymentAnalysis/widgets/PaymentStructure/hooks/useFetchData';
import { useGetRawData as useSalesOfTenantsWidget } from '../cabinet/components/Modules/Sales/PaymentAnalysis/widgets/SalesOfTenants/hooks/useFetchData';
import { useGetRawData as usePaymentDynamicStructureWidget } from '../cabinet/components/Modules/Sales/PaymentAnalysis/widgets/StructureDynamics/hooks/useFetchData';

//-----------------Common

const DataComponent = () => {
    //-----------------Performance:Dashboard
    useSummaryTable();
    useAnalysisByAreaWidget();
    useDashboardDynamicsPMOWidget();
    useDashboardTrafficWidget();
    //-----------------Performance:YearOverYear
    usePerformanceYearOverYearWidget();
    //-----------------Performance:VisitorsInside
    usePerformanceVisitorsInsideWidget();
    //-----------------Performance:Ratings
    usePerformanceRatingsWidget();
    //-----------------Performance:PathToPurchase
    usePathToPurchaseWidgetRawData();
    //-----------------Maps:MapsOverview
    useMapsOverviewWidget();
    //-----------------Maps:CrossVisits
    useCrossVisitsModuleRawData();
    //-----------------Cases:Benchmarks
    useCasesBenchmarcsTrafficWidget();
    useCasesBenchmarcsSalesWidget();
    //-----------------Leasing:Tenant overview
    useLeasingTOSummaryWidgetData();
    useLeasing_TO_DynamicsPMO_Widget();
    useLeasing_TO_Reach_Widget();
    useLeasing_TO_Reference_Widget();
    useLeasing_TO_IntersectionsWithTenants_Widget();
    //-----------------Reports
    useBasicReportWidget();
    useComparisonReportWidget();
    useIntersectionsWidgetReducer();
    useTenantsWidgetReducer();
    //-----------------Leasing:TenantsWidget
    useLeasingTenantsWidgetRawData();
    //-----------------Leasing:Cross-visits
    useLeasingCrossVisitsWidgetRawData();
    //-----------------Events:Events
    useEventsSummaryTableWidget();
    useEventsDynamicsPMOWidget();
    useEventsTableOfObjectsWidget();
    //-----------------Sales:Payment analysis
    usePaymentStructureWidget();
    useSalesOfTenantsWidget();
    usePaymentDynamicStructureWidget();
    return null;
};

export default DataComponent;
