import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPeriod, IReportingObject } from '../../../../General.interfaces';
import { Select } from '../../../../lib/esm/components';
import { IProps } from './interfaces';
import { StIcon, Wrapper } from './styles';
import { getReportingObjects, objectTypesMap } from '../../../../tools/getReportingObjects';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { TSelectedOptions } from '../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues, storeFiltersOptions } from '../../UserSettings/reducer';
import { Icon } from '@fluentui/react';

const FiltersSelect: React.FC<IProps> = ({
    maxSelected,
    label = 'Filters',
    dropdownAlign = 'left',
    validOptions,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        src: { reportingObjects, projectCategories },
        cfg: { reportingObjectsByType, reportingObjectsById },
    } = useSelector(generalReducerValues);

    const { currentOptions } = useSelector(userSettingsReducerValues);
    const { currentModule, selectedLocationId } = currentOptions;

    const options = useMemo(() => {
        let result = [];
        if (!validOptions) {
            result.push(...getReportingObjects(reportingObjectsByType, t));
        } else {
            result.push(
                ...getReportingObjects(reportingObjectsByType, t)?.filter((item) =>
                    validOptions.map((item) => item.toLowerCase()).includes(item.section.toLowerCase()),
                ),
            );
        }

        return result;
    }, [validOptions, projectCategories, reportingObjectsByType, t]);

    const outsideSelected: TSelectedOptions = [];

    const ids = currentOptions?.['filters'] || [];

    ids.forEach((id: number) => {
        const obj = reportingObjectsById[id];
        if (obj) {
            outsideSelected.push({
                ...obj,
                section: t(objectTypesMap[obj.object_type] || obj.object_type),
                text: obj.name,
            });
        }
    });

    const handleSelect = (args: { id: number | null | string; block_type?: string }[]) => {
        selectedLocationId &&
            dispatch(
                storeFiltersOptions({
                    locationId: selectedLocationId,
                    module: currentModule,
                    selectedOptions: args?.filter((item) => item !== null).map((item) => Number(item.id)),
                }),
            );
    };

    const max = maxSelected ? ` (${t('max')}: ${maxSelected})` : '';

    return (
        <Wrapper>
            <Select
                options={options}
                dropdownWidth="md"
                text={t('Selected')}
                dropdownAlign={dropdownAlign}
                showFilter={true}
                mode="multi"
                bgColor="primary"
                color="white"
                labelText={`${t(label)}${max}`}
                iconType="filter"
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                outsideSelected={outsideSelected}
                additionalControl={<StIcon iconName="Delete" />}
            />
        </Wrapper>
    );
};

export default FiltersSelect;
