import React from 'react';

import '../../../../styles/styles.scss';
import logo from '../../../../assets/logo.svg';
import SwitcherContent from '../../../SwitcherContent';
import { Box } from '../../../../../../../lib/esm/components';

const Logo: React.FC = () => {
    return (
        <SwitcherContent>
            <a href="/">
                <Box classString="navigation-item__text" margin="l_xxs" border="none" height="auto" width="auto">
                    <img
                        src={logo}
                        alt="logo"
                        //  style={{ width: '200px' }}
                        width={'150px'}
                    />
                </Box>
            </a>
        </SwitcherContent>
    );
};

export default Logo;
