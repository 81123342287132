import styled from 'styled-components/macro';

export const StageWrapper = styled.div<{ marginBottom: number }>`
    background-color: var(--color-secondary);
    border: 1px solid var(--color-secondary-dark);
    margin-bottom: ${({ marginBottom }) => marginBottom + 'px'};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
`;

export const Floor = styled.div`
    background-color: var(--color-secondary);
    justify-content: center;
    height: var(--space-md);
    width: var(--space-md);
    align-items: center;
    position: absolute;
    font-weight: 500;
    display: flex;
    z-index: 2;
    left: 0;
    top: 0;
`;

export const MapsWrapper = styled.div`
    min-width: 70%;
`;

export const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
`;

export const RatingsWrapper = styled.div<{ height: number }>`
    height: ${({ height }) => height + 'px'};
    min-width: 30%;
    box-sizing: border-box;
`;
