import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './store';
import './index.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root')!);

root.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
