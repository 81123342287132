import styled from 'styled-components/macro';

export const PivotWrapper = styled.div`
    margin-bottom: var(--space-sm);
`;

export const Wrapper = styled.div`
    min-height: 250px;
    min-width: 30vw;
`;
