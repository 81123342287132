import styled from 'styled-components/macro';

export const TooltipWrapper = styled.div`
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 3px 4px 4px 3px rgba(166, 166, 166, 0.3);
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-weight: bold;
`;

export const HeaderItem = styled.div`
    margin-left: 40px;
    :first-child {
        margin-left: 0px;
    }
`;

export const BodyWrapper = styled.div`
    padding-top: 5px;
    border-top: 1px solid #ebebeb;
`;
