import styled from 'styled-components/macro';

export const OptionsWrapper = styled.div`
    position: relative;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const Container = styled.div`
    padding-right: var(--space-md);
    padding-left: var(--space-md);
    padding-bottom: var(--space-md);
`;
