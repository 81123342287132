import { PivotItem, Tooltip, TooltipHost } from '@fluentui/react';
import React, { Component, ComponentType, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { getArrowAndColor } from '../../../../../../../tools/getArrowAndColor';
import { getFixedLines } from '../../../../../../../tools/getFixedLines';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import LoadingBox from '../../../../../LoadingBox/LoadingBox';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import Selects from './components/Selects/Selects';
import { ICell } from './interfaces';
import { Performance_Dashboard_Traffic_Widget_Reducer_Values, reloadWidget, storeRatingType } from './reducer';
import {
    CompareDates,
    ComparePercent,
    MainPercent,
    MainTraffic,
    Name,
    Rating,
    RatingValue,
    TableWrapper,
    WidgetWrapper,
} from './styles';
import { usePrepareData } from './hooks/usePrepareData';
import { useRerender } from '../../../../../../../tools/useRerender';
import { numberWithSpaces } from '../../../../../../../tools/Strings/numberWithSpaces';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import { DateTime } from 'luxon';

const Traffic: React.FC = ({}) => {
    const ref = useRef(null);
    const { ratingType, preparedData, responseResult } = useSelector(
        Performance_Dashboard_Traffic_Widget_Reducer_Values,
    );
    const {
        cfg: { reportingObjectsByType },
        lang,
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePrepareData();
    const rerender = useRerender(preparedData);

    const table = useMemo(() => {
        // const tableData = ratingType === 'all' ? data : preparedData;
        const tableData = preparedData;

        if (!tableData?.['status'] && Array.isArray(tableData) && tableData.length) {
            // Формируем Хедер таблицы
            const headerContent = [
                <td key={'svds'}>{t('Entrances')}</td>,
                <td colSpan={3} key="gtfh">
                    {t('Visitors for period')}
                </td>,
            ];
            tableData[0]?.forEach((cell: ICell, i: number) => {
                if (cell.type === 'comparePercent' && cell.period) {
                    headerContent.push(
                        <CompareDates key={`${cell.value}++${i}`}>
                            <div>
                                {/* <span>{`${t('from')} ${cell.period[0]}`}</span>
                                <span>{`${t('to')} ${cell.period[1]}`}</span> */}
                                <span>{shortStringDate([cell.period[0], cell.period[1]], lang)}</span>
                            </div>
                        </CompareDates>,
                    );
                }
            });
            const tableHeader = <tr>{headerContent}</tr>;

            // Формируем тело таблицы
            const rows = tableData.map((row, i) => {
                const cells: ReactNode[] = [];
                row.forEach((cell: ICell, j: number) => {
                    switch (cell.type) {
                        case 'name':
                            cells.push(
                                <Name key={cell.type + i + j}>
                                    <TooltipHost content={String(cell.value)}>
                                        <span>{getFixedLines(String(cell.value))[0]}</span>
                                    </TooltipHost>
                                </Name>,
                            );
                            break;
                        case 'rating':
                            cells.push(
                                <Rating key={cell.type + i + j}>
                                    <RatingValue width={`${cell.value}%`} />
                                </Rating>,
                            );
                            break;
                        case 'mainPercent':
                            cells.push(
                                <MainPercent key={cell.type + i + j}>{`${
                                    isNaN(cell.value) || cell.value === Infinity ? '–' : `${cell.value}%`
                                }`}</MainPercent>,
                            );
                            break;
                        case 'mainTraffic':
                            cells.push(
                                <MainTraffic key={cell.type + i + j}>{`${numberWithSpaces(cell.value)} ${t(
                                    'ppl',
                                )}`}</MainTraffic>,
                            );
                            break;
                        case 'comparePercent':
                            let color = getArrowAndColor(Number(cell.value)).color;
                            const icon = getArrowAndColor(Number(cell.value)).icon;
                            let display = (
                                <>
                                    {`${cell.value}%`}
                                    {icon}
                                </>
                            );

                            if (isNaN(cell.value) || cell.value === Infinity) {
                                display = <>{'–'}</>;
                                color = 'black';
                            }

                            cells.push(
                                <ComparePercent color={color} key={cell.type + i + j}>
                                    {display}
                                </ComparePercent>,
                            );
                            break;

                        default:
                            break;
                    }
                });
                return <tr key={`row--${i}`}>{cells}</tr>;
            });

            return (
                <table>
                    <thead>{tableHeader}</thead>
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            return;
        }
    }, [t, preparedData, ratingType]);

    const pivotItems = [
        {
            itemKey: 'all',
            headerText: 'All',
        },
        {
            itemKey: 'location',
            headerText: 'Location',
        },
        {
            itemKey: 'zone',
            headerText: 'Zones',
        },
        {
            itemKey: 'floor',
            headerText: 'Floors',
        },
    ];

    const existingTypes = Object.keys(reportingObjectsByType).map((key) => key);

    const onLinkClick = (item: PivotItem) => {
        item.props.itemKey && dispatch(storeRatingType(item.props.itemKey));
    };

    const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: preparedData, height: 400 });

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Traffic by entrances')}
                    <WidgetAdditionalControls
                        widgetName="Traffic by entrances"
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidget}
                        // xlsxDownloadData={{}}
                    />
                </WidgetTitle>
                <Pivot widgetName="TrafficByEntrance" onLinkClick={onLinkClick} selectedKey={ratingType}>
                    {pivotItems
                        ?.filter((item) => existingTypes.includes(item.itemKey) || item.itemKey === 'all')
                        .map((element) => (
                            <PivotItem
                                key={element.itemKey + Math.random()}
                                headerText={t(element.headerText)}
                                itemKey={element.itemKey}
                            />
                        ))}
                </Pivot>
            </WidgetTitleWrapper>
            <Selects />
            <WithLoadingTable>{table}</WithLoadingTable>
        </WidgetWrapper>
    );
};

export default Traffic;
