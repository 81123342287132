import { TServerResponse, handleServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { Performance_PathToPurchase_Widget_Reducer_Values, resetReducer, storeRawMetricsData } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS } from '../../../constants/constants';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateMetricsQueryData from '../../../../../../../../tools/API/queryDataGenerators/generateMetricsQueryData';
import getAdditionalIdsForMetricsRequest from '../tools/getAdditionalIdsForMetricsRequest';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useEffect } from 'react';
import usePrepareData from './usePrepareData';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';

/** Кастомный хук для получения сырых данных */
export const useGetRawData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Path to purchase');
    const { dataRefetchObject } = useSelector(Performance_PathToPurchase_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsByTypeAndMarker, reportingObjectsById },
        structures,
        token,
    } = useSelector(generalReducerValues);
    const TRAFFIC_IN_METRIC = 'fpc_sum_pass_count_in_wh';
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePrepareData();

    /**  Запрос сырых данных по метрике */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        dispatch(resetReducer());
        if (
            !localCurrentOptions?.[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS]?.[0]?.id ||
            !Object.keys(reportingObjectsByTypeAndMarker).length ||
            !localCurrentOptions?.selectedReportingObjectsIds ||
            !Object.keys(reportingObjectsById).length ||
            !localCurrentOptions?.mainDateRanges ||
            !localCurrentOptions?.mainPeriod ||
            !structures ||
            !token
        ) {
            return;
        }
        const idsOfReportingObjects = getAdditionalIdsForMetricsRequest({
            selectedGroupMarker: localCurrentOptions[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS][0].id,
            selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
            tenant2ZoneRelations: structures['relations_tenant2zone'] || [],
            msDataObjects: structures['elements_ms_data_objects'] || [],
            dateRanges: localCurrentOptions.mainDateRanges,
            period: localCurrentOptions.mainPeriod,
            reportingObjectsByTypeAndMarker,
            reportingObjectsById,
        });

        const queryData = generateMetricsQueryData({
            selectedReportingObjectsIds: idsOfReportingObjects,
            dateRanges: localCurrentOptions.mainDateRanges,
            period: localCurrentOptions.mainPeriod!,
            selectedMetrics: [TRAFFIC_IN_METRIC],
            timiFreq: null,
            metricLevel,
            token,
        }).map((element) => ({ ...element, signal }));

        dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
        fetchData(queryData).then((response: TServerResponse) => {
            handleServerResponse({
                responseAnalyzer: responseAnalyzer,
                success: storeRawMetricsData,
                error: storeRawMetricsData,
                res: response,
                dispatch,
            });
        });

        return () => controller.abort();
    }, [
        localCurrentOptions?.[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS],
        localCurrentOptions?.selectedReportingObjectsIds,
        localCurrentOptions?.mainPeriod,
        reportingObjectsByTypeAndMarker,
        reportingObjectsById,
        dataRefetchObject,
        structures,
        token,
    ]);
};
