import { ChartOptions } from 'highcharts';

/**
 * Функция для генерация настроек чарта
 * @param inverted Флаг отвечающий за смену осей
 * @returns Настройки чарта
 */
const generateChart = (inverted: boolean): ChartOptions => {
    return {
        style: { fontFamily: 'Roboto' },
        animation: false,
        inverted,
    };
};

export default generateChart;
