import styled from 'styled-components/macro';

export const ObjectWrapper = styled.div`
    margin-bottom: var(--space-sm);
`;
export const Row = styled.div<{ numCompPeriods: number }>`
    -moz-column-gap: 3px;
    column-gap: 3px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${(p) => {
        return 'repeat(' + p.numCompPeriods + ', 210px)';
    }};
`;

export const CellWrapper = styled.div<{ isHeader?: boolean }>`
    align-items: stretch;
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    border-top: ${(p) => (p.isHeader ? 'auto' : 'none')};
    display: flex;
    flex: 1;
    font-size: 14px;
`;

export const Percent = styled.div<{ percent: string }>`
    white-space: nowrap;
    background-color: hsla(0, 0%, 92.2%, 0.3);
    flex: 0;
    justify-content: flex-end;
    max-width: var(--space-xxxl);
    min-width: var(--space-xxxl);
    font-weight: 600;
    align-items: center;
    display: flex;
    padding: var(--space-xxxs) var(--space-xxs);
    color: ${(p) => {
        if (Number(p.percent) > 3) {
            return 'var(--color-success)';
        } else if (Number(p.percent) < -3) {
            return 'var(--color-accent)';
        } else if (Number(p.percent) >= -3 && Number(p.percent) <= 3) {
            return 'var(--color-warning-light)';
        } else {
            return 'black';
        }
    }};
`;
export const Value = styled.div<{ isMain?: boolean }>`
    /* flex: 0; */
    width: 100%;
    justify-content: ${(p) => (p.isMain ? 'flex-end' : 'flex-start')};
    /* max-width: var(--space-xxxl);
    min-width: var(--space-xxxl); */
    align-items: center;

    display: flex;
    font-weight: ${(p) => (p.isMain ? '600' : '400')};
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const Period = styled.div`
    /* font-size: var(--text-sm); */
    width: 100%;
    padding: var(--space-xxxs) var(--space-xxs);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`;

export const Metric = styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const WidgetWrapper = styled.div`
    margin-top: var(--space-sm);
`;

export const Marker = styled.div`
    padding: var(--space-xxs);
    display: block;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
export const TableWrapper = styled.div`
    padding: var(--space-xxs) 0;
    /* max-height: 700px; */
    overflow: auto;
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }
    tr {
        border: 1px solid var(--default-border-color);
    }
    td {
        padding: var(--space-xxxxs) var(--space-xxs);
        height: var(--space-lg);
    }
    thead {
        font-weight: 700;
    }
`;

export const Name = styled.td`
    /* max-width: 400px; */
`;

export const MainPercent = styled.td`
    font-weight: 700;
    text-align: right;
`;

export const MainTraffic = styled.td`
    text-align: right;
    color: var(--color-gray-darker);
    border-right: 1px solid var(--default-border-color);
`;
export const CompareDates = styled.td`
    text-align: right;
    div {
        display: flex;
        flex-direction: column;
    }
`;

export const ComparePercent = styled.td<{ color: string }>`
    text-align: right;
    font-weight: 700;
    color: ${(p) => p.color};
`;

export const Rating = styled.td`
    min-width: 100px;
    position: relative;
    display: flex;
`;

export const RatingValue = styled.div<{ width: string }>`
    width: ${(p) => {
        return p.width;
    }};
    background-color: var(--color-info-lighter);
    opacity: 0.6;
`;


export const PeriodWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const Cont = styled.div`
    display: flex;
    align-items: center;
`;
