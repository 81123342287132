import { useEffect, useState } from 'react';

import { AxisLabelsFormatterContextObject } from 'highcharts';
import { IAdditioanlSettings } from './interfaces';
import { TSeries } from '../../../../../../Charts/Dynamics/interfaces';
import datetimeFormatHelper from '../tools/datetimeFormatHelper';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateAdditionalSettings from '../tools/generateAdditionalSettings';
import generateSeries from '../tools/generateSeries';
import { Performance_Dashboard_DynamicsPMO_Widget_Reducer_Values } from '../reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Кастомный хук для получения настроек диаграммы
 */
const useChartOptions = () => {
    const { selectedChartDetailing, rawMetricsData, selectedChartType, selectedMetric } = useSelector(
        Performance_Dashboard_DynamicsPMO_Widget_Reducer_Values,
    );

    const [series, setSeries] = useState<TSeries[]>([]);
    const [additionalSettings, setAdditionalSettings] = useState<IAdditioanlSettings>({
        showSeriesNameInTooltipBody: false,
        tooltipSubTitle: '',
        tooltipTitle: '',
    });
    const { lang, allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    /** Получение серий для графика */
    useEffect(() => {
        if (Array.isArray(rawMetricsData) && selectedChartDetailing) {
            const series = generateSeries({
                selectedChartDetailing: selectedChartDetailing,
                selectedChartType,
                rawMetricsData,
                allMetrics,
                lang,
                t,
            });

            setSeries(series);
        }
    }, [rawMetricsData, selectedChartDetailing, selectedChartType, t, lang]);

    /** Получение дополнительных настроек для графика*/
    useEffect(() => {
        if (selectedMetric) {
            const metricText = allMetrics?.find((element) => selectedMetric.id === element.id)?.text;
            const additionalSettings = generateAdditionalSettings({
                selectedMetric: metricText || selectedMetric.text,
                selectedChartType,
            });
            setAdditionalSettings(additionalSettings);
        }
    }, [selectedMetric, selectedChartType, t]);

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: this.value as number,
            isCategory: true,
            lang,
            t,
        });
    };

    const tooltipTitleFormatter = (value: string | number) => {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: value as number,
            isCategory: false,
            lang,
            t,
        });
    };

    return { series, xAxisLabelsFormating, tooltipTitleFormatter, ...additionalSettings };
};

export default useChartOptions;
