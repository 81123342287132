import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';
import { getUrls, hosts } from '../../constants/urls.js';

var CategoriesAPI = function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var token, chapter, id, data, method, urlParams, headers, getId, getParams, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = args.token, chapter = args.chapter, id = args.id, data = args.data, method = args.method, urlParams = args.urlParams;
                headers = {
                    'X-Token': token,
                    'Content-type': 'application/json',
                };
                getId = function () {
                    if (id) {
                        return "".concat(id, "/");
                    }
                    else {
                        return '';
                    }
                };
                getParams = function () {
                    if (urlParams) {
                        var arr = Object.keys(urlParams).map(function (param) { return "".concat(param, "=").concat(urlParams[param]); });
                        return "?".concat(arr.join('&'));
                    }
                    else {
                        return '';
                    }
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios({
                        method: method,
                        headers: headers,
                        data: data,
                        url: "".concat(getUrls(hosts).CATEGORIES_URL, "/").concat(chapter, "/").concat(getId()).concat(getParams()),
                    })];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, error_1];
            case 4: return [2 /*return*/];
        }
    });
}); };

export { CategoriesAPI };
