import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import {
    BodyIntersectionWrapper,
    RowReportingObjectName,
    BodyDefaultText,
    PaiwiseValue,
    PercentValue,
    BodyTd,
    BodyTr,
} from './styles';
import { FC, useContext } from 'react';
import { IntersectionsWithTenantsContext } from '../../../../context';

/**
 * Компонент для отображения тела таблицы
 */
const Body: FC<IProps> = ({ sortedReportingObjects, intersectionsDataById, days }) => {
    const context = useContext(IntersectionsWithTenantsContext);
    const { t } = useTranslation();

    return (
        <tbody>
            {sortedReportingObjects.map((reportingObject) => {
                return (
                    <BodyTr key={reportingObject.id}>
                        <BodyTd>
                            <RowReportingObjectName>
                                <div>{reportingObject.name}</div>
                            </RowReportingObjectName>
                        </BodyTd>
                        <BodyTd>
                            {context?.isLoading ? (
                                <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                            ) : context?.isError ? (
                                <BodyDefaultText>{t('Some error')}</BodyDefaultText>
                            ) : (
                                <BodyIntersectionWrapper>
                                    <PercentValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].percentIntersection !== null
                                            ? numberWithSpaces(
                                                  (
                                                      intersectionsDataById[reportingObject.id].percentIntersection! /
                                                      days
                                                  ).toFixed(2),
                                              ) + '%'
                                            : '—'}
                                    </PercentValue>
                                    <PaiwiseValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].intersection !== null
                                            ? numberWithSpaces(
                                                  String(
                                                      (
                                                          intersectionsDataById[reportingObject.id].intersection! / days
                                                      ).toFixed(0),
                                                  ),
                                              )
                                            : '—'}
                                    </PaiwiseValue>
                                </BodyIntersectionWrapper>
                            )}
                        </BodyTd>
                        <BodyTd>
                            {context?.isLoading ? (
                                <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                            ) : context?.isError ? (
                                <BodyDefaultText>{t('Some error')}</BodyDefaultText>
                            ) : (
                                <BodyIntersectionWrapper>
                                    <PercentValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].percentIntersection !== null
                                            ? numberWithSpaces(
                                                  intersectionsDataById[
                                                      reportingObject.id
                                                  ].percentIntersection!.toFixed(2),
                                              ) + '%'
                                            : '—'}
                                    </PercentValue>
                                    <PaiwiseValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].intersection !== null
                                            ? numberWithSpaces(
                                                  String(
                                                      intersectionsDataById[reportingObject.id].intersection!.toFixed(
                                                          0,
                                                      ),
                                                  ),
                                              )
                                            : '—'}
                                    </PaiwiseValue>
                                </BodyIntersectionWrapper>
                            )}
                        </BodyTd>
                    </BodyTr>
                );
            })}
        </tbody>
    );
};

export default Body;
