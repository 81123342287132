import { __assign } from '../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import React, { useState, useEffect } from 'react';
import { DEFAULT_INPUT_MAX_LENGTH, DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } from '../../constants/constants.js';
import cn from 'classnames';
import { SearchWrapper, ButtonWrapper } from './styles/styles.js';
import Button from '../Button/index.js';
import Icon from '../Icon/index.js';

var Search = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.id, id = _c === void 0 ? undefined : _c, _d = _a.name, name = _d === void 0 ? undefined : _d, _e = _a.maxLength, maxLength = _e === void 0 ? DEFAULT_INPUT_MAX_LENGTH : _e, _f = _a.minLength, minLength = _f === void 0 ? 0 : _f, _g = _a.placeholder, placeholder = _g === void 0 ? '' : _g, _h = _a.pattern, pattern = _h === void 0 ? undefined : _h, _j = _a.size, size = _j === void 0 ? 'md' : _j, _k = _a.fullWidth, fullWidth = _k === void 0 ? false : _k, handleChange = _a.handleChange;
    var _l = useState('search'), iconType = _l[0], setIconType = _l[1];
    var onIconClick = function () {
        if (iconType === 'close') {
            setInputValue(function () { return ''; });
        }
    };
    var _m = useState(''), inputValue = _m[0], setInputValue = _m[1];
    var DEFAULT_CLASS_INPUT_PREFIX$1 = DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1 = DEFAULT_SEPARATOR_MODIFICATION_PREFIX;
    /**
     * получить класс размера кнопки
     * @param size - размер кнопки
     */
    var getSizeCss = function (size) {
        return "".concat(DEFAULT_CLASS_INPUT_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, "size").concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1).concat(size);
    };
    var onChange = function (e) {
        var newValue = e.currentTarget.value;
        setInputValue(function () { return newValue; });
    };
    useEffect(function () {
        handleChange && handleChange({ id: id, name: name, value: inputValue });
        if (inputValue && inputValue.length) {
            setIconType(function () { return 'close'; });
        }
        else {
            setIconType(function () { return 'search'; });
        }
    }, [inputValue, id, name]);
    var className = React.useMemo(function () {
        var _a;
        var cssClassSize = getSizeCss(size);
        return cn(DEFAULT_CLASS_INPUT_PREFIX$1, cssClassSize, (_a = {},
            _a["".concat(DEFAULT_CLASS_INPUT_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, "full-width")] = fullWidth,
            _a), 'search__input');
    }, [DEFAULT_CLASS_INPUT_PREFIX$1, DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, getSizeCss, size, disabled]);
    return (jsxs(SearchWrapper, __assign({ fullWidth: fullWidth }, { children: [jsx("input", { type: 'text', placeholder: placeholder, pattern: pattern, maxLength: maxLength, minLength: minLength, disabled: disabled, className: className, value: inputValue, onChange: onChange }, void 0), jsx(ButtonWrapper, { children: jsx(Button, __assign({ onClick: onIconClick, theme: "icon" }, { children: jsx(Icon, { size: "xxs", type: iconType, color: "contrast-high" }, void 0) }), void 0) }, void 0)] }), void 0));
};

export { Search as default };
