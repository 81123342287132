import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../../lib/esm/components';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import AccordionItemContent from '../components/AccordionItemContent/AccordionItemContent';
import BackgroundHeader from '../components/Header/Header';
import { FaqDataCrossVisits, FaqDataEvents, FaqDataEventsAnalysis, FaqDataImport, FaqDataPathToPurchase, FaqDataPeriodReview, FaqDataRatings, FaqDataReportList, FaqDataSpecialFeatures, FaqDataTenantAnalysis, FaqDataTenants, FaqDataVisitorInside, FaqDataYearOverYear } from './data';
import { Description } from '../styles';

/**
 * Компонент модуля сабмодуля FAQ
 */
const Faq: React.FC = ({}) => {
    const { t } = useTranslation('translationHelps');
    return (
        <ModuleWrapper>
            <BackgroundHeader
                title={t('Help')}
                description={t(
                    'Here you can get acquainted with the capabilities of the Focus system, learn more about modules, special functions and find answers to frequently asked questions.',
                )}
            />
            <WrapperContainer>
                <Title titleLevel="h2" margin="b_md">
                    {t('Performance')}
                </Title>
                <Title titleLevel="h3" margin="b_md">
                    {t('Period review')}
                </Title>
                <Description>
                    {t(
                        'This is the Focus home page. There you can check out changes in the Mall’s metrics, evaluate the performance of tenants or their groups subject to the area they rent, check tenants’ rating, compare the Mall’s entrances.',
                    )}
                </Description>
                <AccordionItemContent data={FaqDataPeriodReview}></AccordionItemContent>

                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Year over year')}
                </Title>
                <Description>{t('Page for comparing the performance of Mall objects year to year.')}</Description>

                <AccordionItemContent data={FaqDataYearOverYear}></AccordionItemContent>

                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Cross-visits')}
                </Title>
                <Description>
                    {t(
                        'The page is intended for the analysis of intersection data between tenants using wifi analytics.',
                    )}
                </Description>

                <AccordionItemContent data={FaqDataCrossVisits}></AccordionItemContent>

                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Visitors inside')}
                </Title>
                <Description>
                    {t('Page for analyzing the hourly dynamics of the number of people inside the selected object.')}
                </Description>

                <AccordionItemContent data={FaqDataVisitorInside}></AccordionItemContent>

                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Ratings')}
                </Title>
                <Description>
                    {t('This page allows you to create and store custom object ratings.')}
                </Description>

                <AccordionItemContent data={FaqDataRatings}></AccordionItemContent>

                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Path to purchase')}
                </Title>

                <AccordionItemContent data={FaqDataPathToPurchase}></AccordionItemContent>
                
                <Title titleLevel="h2" margin="t_md b_md">
                    {t('Marketing')}
                </Title>
                
                <Title titleLevel="h3" margin="b_md">
                    {t('Events')}
                </Title>

                <Description>
                    {t('It allows you to add and store comments on past events and helps generate analytical reports based on them. Events can be either positive (e.g. a tenant’s event, a sale, a holiday period) or negative (e.g. evacuation, repairs).')}
                </Description>

                <AccordionItemContent data={FaqDataEvents}></AccordionItemContent>
                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Event analysis')}
                </Title>

                <Description>
                    {t('It allows you to see how an event affected the performance of the Mall in general (whether sales increased along with traffic), the dynamics (whether metrics grew), and which tenants or groups of tenants grew during the event.')}
                </Description>

                <AccordionItemContent data={FaqDataEventsAnalysis}></AccordionItemContent>

                <Title titleLevel="h2" margin="t_md b_md">
                    {t('Leasing')}
                </Title>
                
                <Title titleLevel="h3" margin="b_md">
                    {t('Tenant Analysis')}
                </Title>

                <Description>
                    {t('Helps to get prepared for negotiations with a tenant, compare the tenant with competitors and assess its location.')}
                </Description>

                <AccordionItemContent data={FaqDataTenantAnalysis}></AccordionItemContent>
                
                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Tenants')}
                </Title>

                <Description>
                    {t('View summary indicators of all tenants of the shopping center. There is a sorting by indicator and deviation, as well as a transition to other pages with a more detailed analysis of the tenant.')}
                </Description>

                <AccordionItemContent data={FaqDataTenants}></AccordionItemContent>
                
                <Title titleLevel="h2" margin="t_md b_md">
                    {t('Special features')}
                </Title>
                
                <AccordionItemContent data={FaqDataSpecialFeatures}></AccordionItemContent>
                
                <Title titleLevel="h2" margin="t_md b_md">
                    {t('Work with data')}
                </Title>
                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Data import')}
                </Title>

                <Description>
                    {t('Allows loading data from other sources for analysis into Focus.')}
                </Description>

                <AccordionItemContent data={FaqDataImport}></AccordionItemContent>

                <Title titleLevel="h3" margin="t_md b_md">
                    {t('Report list')}
                </Title>

                <AccordionItemContent data={FaqDataReportList}></AccordionItemContent>

            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Faq;
