import { IPeriod } from './../../../lib/esm/General.interfaces.d';
import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { ISettings, IReducerState, IAccountParams, TCurrentOptions } from './interfaces';
import { IObject, TSelectedOptions } from '../../../lib/esm/components/Select/configurations';
import regenerateSettings from './tools/regenerateSettings';

const recursiveTransform = (instance: any, field: string, newData: IPeriod | IPeriod[]): any => {
    if (Array.isArray(instance)) {
        instance.forEach((item) => {
            recursiveTransform(item, field, newData);
        });
    } else if (typeof instance === 'object' && instance !== null && instance.hasOwnProperty(field)) {
        instance[field] = newData;
    } else if (typeof instance === 'object' && instance !== null) {
        Object.keys(instance).forEach((k) => {
            recursiveTransform(instance[k], field, newData);
        });
    } else {
        return;
    }
};

export const defaultOptions: ISettings = {
    mainPeriod: { id: 'previousWeek' },
    detail: { id: null, text: 'Without detail' },
    comparePeriods: [{ id: 'previous' }],
    selectedMetrics: ['fpc_sum_pass_count_in_wh'],
};

const initialState: IReducerState = {
    accParamsID: null,
    accParamsReceived: false,
    settings: {
        // byML: { ['Performance:Dashboard']: {} },
        // byM: { ['Performance:Dashboard']: { ...defaultOptions } },
        // byL: {},
        global: {},
    },
    currentOptions: {
        ...defaultOptions,
        currentModule: 'Performance:Dashboard',
        selectedLocationId: null,
        mainDateRanges: [],
        compareDateRanges: [],
    },
};

export const UserSettingsReducer = createSlice({
    name: 'UserSettingsReducer',
    initialState,

    reducers: {
        /**
         * Сохранение настроек.
         */
        fillInitial: (state, action: PayloadAction<IAccountParams>) => {
            state.settings = action.payload;
        },

        storeSelectedObjectIds: (
            state,
            action: PayloadAction<{
                module: string;
                locationId: number;
                selectedReportingObjectsIds: number[];
            }>,
        ) => {
            const { module, locationId, selectedReportingObjectsIds } = action.payload;
            const { settings } = cloneDeep(state);
            state.settings = regenerateSettings({
                newData: { selectedReportingObjectsIds },
                selectedLocationId: locationId,
                currentModule: module,
                settings,
            });
        },

        /**
         * Сохранение настроек.
         */
        storeSelectedMetrics: (
            state,
            action: PayloadAction<{
                module: string;
                locationId: number;
                selectedMetrics: string[];
            }>,
        ) => {
            const { module, locationId, selectedMetrics } = action.payload;
            const { settings } = cloneDeep(state);
            state.settings = regenerateSettings({
                selectedLocationId: locationId,
                newData: { selectedMetrics },
                currentModule: module,
                settings,
            });
        },
        /**
         * Сохранение настроек.
         */
        storeUniversalOptions: (
            state,
            action: PayloadAction<{
                module: string;
                name: string;
                locationId: number;
                selectedOptions: TSelectedOptions;
            }>,
        ) => {
            const { module, locationId, selectedOptions, name } = action.payload;
            const { settings } = cloneDeep(state);
            state.settings = regenerateSettings({
                newData: { [name]: selectedOptions },
                selectedLocationId: locationId,
                currentModule: module,
                settings,
            });
        },

        storeFiltersOptions: (
            state,
            action: PayloadAction<{
                module: string;
                locationId: number;
                selectedOptions: number[];
            }>,
        ) => {
            const { module, locationId, selectedOptions } = action.payload;
            const { settings } = cloneDeep(state);
            state.settings = regenerateSettings({
                newData: { filters: selectedOptions },
                selectedLocationId: locationId,
                currentModule: module,
                settings,
            });
        },
        /**
         * Сохранение настроек.
         */
        storeUniversalTextInput: (
            state,
            action: PayloadAction<{
                module: string;
                name: string;
                locationId: number;
                data: string;
            }>,
        ) => {
            const { module, locationId, data, name } = action.payload;
            const { settings } = cloneDeep(state);
            state.settings = regenerateSettings({
                newData: { [name]: data },
                selectedLocationId: locationId,
                currentModule: module,
                settings,
            });
        },

        /**
         * Сохранение настроек.
         */
        storeMainPeriod: (
            state,
            action: PayloadAction<{
                module: string;
                locationId: number;
                mainPeriod: IPeriod;
            }>,
        ) => {
            const { module, locationId, mainPeriod } = action.payload;

            const { settings } = cloneDeep(state);
            let newSettings = regenerateSettings({
                selectedLocationId: locationId,
                newData: { mainPeriod },
                currentModule: module,
                settings,
            });

            state.settings = newSettings;
        },

        storeComparePeriods: (
            state,
            action: PayloadAction<{
                module: string;
                locationId: number;
                comparePeriods: IPeriod[];
            }>,
        ) => {
            const { module, locationId, comparePeriods } = action.payload;
            const { settings } = cloneDeep(state);
            let newSettings = regenerateSettings({
                selectedLocationId: locationId,
                newData: { comparePeriods },
                currentModule: module,
                settings,
            });

            state.settings = newSettings;
        },

        /**
         * Сохранение настроек.
         */
        storeDetail: (
            state,
            action: PayloadAction<{
                module: string;
                locationId: number;
                detail: IObject;
            }>,
        ) => {
            const { module, locationId, detail } = action.payload;
            const { settings } = cloneDeep(state);
            state.settings = regenerateSettings({
                selectedLocationId: locationId,
                currentModule: module,
                newData: { detail },
                settings,
            });
        },

        /**
         * Запись настроек из пресета
         */
        storeSettingsFromPreset: (
            state,
            action: PayloadAction<{
                selectedLocationId: number;
                currentModule: string;
                settings: ISettings;
            }>,
        ) => {
            const { settings, selectedLocationId, currentModule } = action.payload;

            const storedSettings = cloneDeep(state.settings);
            state.settings = regenerateSettings({
                settings: storedSettings,
                selectedLocationId,
                newData: settings,
                currentModule,
            });
        },

        /**
         * Сохранение настроек.
         */
        storeCurrentOptions: (state, action: PayloadAction<{ [x: string]: any }>) => {
            const newCurrentOptions = {};
            Object.keys(action.payload).forEach((key) => {
                newCurrentOptions[key] = action.payload[key];
            });

            state.currentOptions = newCurrentOptions as TCurrentOptions;
        },

        /**
         * Заливка настроек в стор из LocalStorage.
         */
        fillSettingsFromLocalStorage: (state, action: PayloadAction<string>) => {
            state.settings = JSON.parse(action.payload);
        },

        storeAccParams: (state, action: PayloadAction<{ id: number; params: IAccountParams }>) => {
            state.accParamsID = action.payload.id;
            state.settings = action.payload.params;
        },

        toggleAccParamsReceived: (state) => {
            state.accParamsReceived = true;
        },
    },
});

export const {
    fillInitial,
    fillSettingsFromLocalStorage,
    storeMainPeriod,
    storeAccParams,
    storeComparePeriods,
    storeUniversalTextInput,
    toggleAccParamsReceived,
    storeDetail,
    storeCurrentOptions,
    storeUniversalOptions,
    storeFiltersOptions,
    storeSelectedMetrics,
    storeSelectedObjectIds,
    storeSettingsFromPreset,
} = UserSettingsReducer.actions;

export const userSettingsReducerValues = (state: RootState) => state.UserSettingsReducer;

export default UserSettingsReducer.reducer;
