import React from 'react';
import { Icon } from '../../../../../../../../../lib/esm/components';
import { getColorFromPercent } from '../../../../../../../../../tools/getColorFromPercent';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { CellWrapper, IconWrapper } from './styles';

const Cell: React.FC<{
    toggleCollapse: (x: string) => void;
    value: number | string;
    type: string;
    name?: string;
    collapseable?: boolean;
    isExpanded?: boolean;
    alias?: string;
    metric?: string;
}> = ({ value, type, collapseable, name, alias, toggleCollapse, isExpanded, metric }) => {
    const cellValue = (value: number | string, type: string) => {
        switch (type) {
            case 'name':
                return value;
            case 'deviation':
                const units = isNaN(Number(value)) ? '' : '%';
                return value + units;
            case 'main':
                return numberWithSpaces(Number(value)?.toFixed(2));

            default:
                return value;
        }
    };

    const styles = (value: number | string, type: string) => {
        if (type === 'name') {
            const hover = collapseable ? true : false;
            const fontWeight = collapseable ? '600' : '400';
            return { textAlign: 'left', fontWeight, color: 'black', hover };
        } else if (type === 'main') {
            return { textAlign: 'right', fontWeight: '400', color: 'black' };
        } else {
            const color = getColorFromPercent(value);
            return { textAlign: 'right', fontWeight: '600', color };
        }
    };

    const onCellClick = () => {
        if (collapseable && alias) {
            toggleCollapse(alias);
        }
    };

    const iconType = isExpanded ? 'chevron-down' : 'chevron-right';

    return (
        <CellWrapper styles={styles(value, type)} onClick={onCellClick}>
            {cellValue(value, type)}
            {collapseable && (
                <IconWrapper>
                    <Icon type={iconType} size="xxs" />
                </IconWrapper>
            )}
        </CellWrapper>
    );
};

export default Cell;
