import General from '../components/General/General';

export interface IPivotItem {
    id: string;
    headerText: string;
    component?: JSX.Element;
}

export const PIVOT_ITEMS: IPivotItem[] = [
    { id: 'preferences', headerText: 'General', component: <General /> },
    { id: 'option 2', headerText: 'Option 2' },
    { id: 'option 3', headerText: 'Option 3' },
];
