import { FC } from 'react';
import { IPeriod } from '../../../../../../../../../../../General.interfaces';
import { shortStringDate } from '../../../../../../../../../../../tools/Strings/shortStringDate';
import { Th, Tr } from '../../styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../../../General.reducer';
import { cloneDeep } from 'lodash';
import { Stack } from '@fluentui/react';
import { Icon } from '../../../../../../../../../../../lib/esm/components';

interface IProps {
    /** Имя отчетного объекта */
    reportingObjectName: string;
    /** Основной периоды */
    mainPeriodData?: IPeriod;
    /** Периоды сравнения */
    comparePeriodsData?: IPeriod[];
}
/**
 * Компонент для отображения шапки таблицы
 */
const Header: FC<IProps> = ({ reportingObjectName, mainPeriodData, comparePeriodsData }) => {
    const { lang } = useSelector(generalReducerValues);
    return (
        <thead>
            <Tr>
                <Th>{reportingObjectName}</Th>
                <Th fontSize="14px" textAlign="center" fontWeight="600">
                    {shortStringDate(mainPeriodData?.period, lang)}
                </Th>
                {comparePeriodsData?.map((comparePeriod) => {
                    return (
                        <Th
                            key={comparePeriod.id}
                            whiteSpace="nowrap"
                            fontSize="14px"
                            textAlign="center"
                            fontWeight="600"
                        >
                            <Stack horizontalAlign="center" horizontal={true} tokens={{ childrenGap: 10 }}>
                                <Icon type="compare" />
                                <div>{shortStringDate(comparePeriod.period, lang)}</div>
                            </Stack>
                        </Th>
                    );
                })}
            </Tr>
        </thead>
    );
};

export default Header;
