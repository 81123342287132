import React from 'react';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import ComparisonReportWidget from './widgets/ComparisonReportWidget/ComparisonReportWidget';
import DetailSelect from '../../../Selects/DetailSelect/DetailSelect';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import {
    additionalFieldsOptions,
    AdditionalFiledsIds,
    COMPARISON_REPORT_ADDITIONAL_FIELDS,
} from './constants/constants';
import { useTranslation } from 'react-i18next';
import { PresetsWrapper } from '../../../Wrappers/PresetsWrapper/styles';
import Preset from '../../../Preset/Preset';
import { ISettings } from '../../../UserSettings/interfaces';
import { OptionsWrapper } from './styles/styles';

const ComparisonReport: React.FC = ({}) => {
    const { t } = useTranslation();

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ComparePeriodSelect mode="single" />
                        <ObjectsSelect />
                        <MetricsSelect />
                        <DetailSelect restrictedOptions={['15MIN']} />
                        <UniversalSelect
                            defaultSelected={additionalFieldsOptions?.filter(
                                (element) => element.id === AdditionalFiledsIds.AddId,
                            )}
                            name={COMPARISON_REPORT_ADDITIONAL_FIELDS}
                            labelText={t('Additional fields')}
                            options={additionalFieldsOptions}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'mainPeriod',
                                'comparePeriods',
                                'selectedReportingObjectsIds',
                                'selectedMetrics',
                                'detail',
                                'compariosnReportAdditonalFields' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <ComparisonReportWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default ComparisonReport;
