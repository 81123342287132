import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../../../General.reducer';
import { Select, ISelectProps } from '../../../../../../../../../../../lib/esm/components';
import {
    ISelectedOption,
    TSelectedOptions,
} from '../../../../../../../../../../../lib/esm/components/Select/configurations';
import { FormItem } from '../../styles';

const SelectMetric: React.FC<
    ISelectProps & { selectedId: string | undefined; handleSelectValue: (selects: TSelectedOptions) => void }
> = ({ handleSelectValue, selectedId = undefined }) => {
    const { allMetrics } = useSelector(generalReducerValues);
    const [selectedMetrics, setSelectedMetrics] = useState<TSelectedOptions>([]);
    const { t } = useTranslation();
    const options = useMemo(() => {
        return _.cloneDeep(allMetrics).map((item) => {
            return { ...item, text: t(item.text) };
        });
    }, [allMetrics, t]);

    const handleSelect = (args: TSelectedOptions) => {
        if (args.length) {
            setSelectedMetrics([args[0]]);
            handleSelectValue(args);
        }
    };

    const metricSelect = useMemo(() => {
        let outsideSelected: TSelectedOptions = [];

        const outsideSelect = options?.find((x: ISelectedOption) => x.id === selectedId);
        if (outsideSelect) {
            outsideSelected = [outsideSelect];
        } else {
            outsideSelected = [options[0]];
        }

        return (
            <Select
                options={options}
                showFilter={false}
                labelText={t('Main metric')}
                iconType={'chevron-down'}
                mode="single"
                shadow="md"
                handleSelect={handleSelect}
                outsideSelected={outsideSelected}
            />
        );
    }, [selectedMetrics, options, t, selectedId]);

    return <FormItem>{metricSelect}</FormItem>;
};
export default SelectMetric;
