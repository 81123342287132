import { AxisTypeValue, XAxisOptions } from 'highcharts';

import { DateTime } from 'luxon';
import { MINUTE_IN_MILLIS } from '../../../../../../constants/constants';
import { TSeries } from '../../interfaces';
import genegatePlotBandsForWeekends from './genegatePlotBandsForWeekends';

export interface IArgs {
    series: TSeries[];
    xAxisLabelsFormating?(
        this: Highcharts.AxisLabelsFormatterContextObject,
        ctx: Highcharts.AxisLabelsFormatterContextObject,
    ): string;
    xAxisType: AxisTypeValue;
    highlightWeekends?: boolean;
    showCurrentDateIndicator: boolean;
    timezone?: string;
    categories?: string[];
}

const generateXAxis = (args: IArgs): XAxisOptions => {
    const {
        series,
        xAxisLabelsFormating,
        xAxisType,
        highlightWeekends,
        showCurrentDateIndicator,
        timezone,
        categories,
    } = args;
    const ONE_DAY_IN_MILLIS = 24 * 3600 * 1000;
    let TICK_INTERVAL = ONE_DAY_IN_MILLIS;

    if (series?.length) {
        if (series[0].data.length > 1) {
            TICK_INTERVAL = series[0].data[1].x - series[0].data[0].x;
        }

        return {
            labels: {
                format: '{value: %Y-%m-%d %H:%M}',
                formatter: xAxisLabelsFormating,
            },
            crosshair: {
                dashStyle: 'Dash',
                color: '#babbbc',
                width: 1,
            },
            plotLines:
                showCurrentDateIndicator && timezone
                    ? [
                          {
                              dashStyle: 'Solid',
                              color: 'red',
                              width: 1,
                              value:
                                  DateTime.now().toMillis() +
                                  DateTime.now().setZone(timezone).offset * MINUTE_IN_MILLIS,
                              label: {
                                  text: DateTime.now().setZone(timezone).toFormat('HH:mm'),
                                  rotation: 0,
                                  align: 'left',
                                  textAlign: 'left',
                                  x: -35,
                              },
                          },
                      ]
                    : undefined,
            plotBands: highlightWeekends ? genegatePlotBandsForWeekends({ series }) : [],
            tickInterval: categories ? undefined : TICK_INTERVAL,
            title: { text: null },
            type: xAxisType,
            lineWidth: 0,
            margin: 0,
            categories,
        };
    }

    return {};
};

export default generateXAxis;
