import { DateTime, Interval } from 'luxon';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { rawData } from '../../rawData';
import { Cases_Calculator_Calculator_Widget_Reducer_Values } from '../../reducer';
import { Cell, Row, TableWrapper } from './styles';
import { canShowOCR, namesMap, tenantsOptions } from '../../tenantsOptions';

const DiscountTable: React.FC = () => {
    const { moduleName } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const tenantId = localCurrentOptions?.['tenant']?.[0]?.id;
    const tenantName = localCurrentOptions?.['tenant']?.[0]?.text;
    const discount = Number(localCurrentOptions?.[`discount`]);
    const discountUnits = localCurrentOptions?.['discountUnits']?.[0]?.id;
    const discountPeriod = localCurrentOptions?.[`discountPeriod`];
    const mainPeriodId = localCurrentOptions?.mainPeriod?.id;
    const mainPeriod = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriodId)[0].period!;

    const rent = localCurrentOptions?.['rentInMonth'];
    const indexPercent = localCurrentOptions?.['indexPercent'];
    const indexingDate = localCurrentOptions?.['indexingDate'];

    const show = rawData[tenantId] && discount && canShowOCR(tenantId, rent, indexingDate, indexPercent);
    if (!show || !discountPeriod) return null;

    const mainInterval = Interval.fromISO(`${mainPeriod.dateFrom}/${mainPeriod.dateTo}`);
    const discountInterval = Interval.fromISO(`${discountPeriod[0]?.text}/${discountPeriod[1].text}`);

    const inInterval = (date: string, interval: Interval): boolean => {
        return DateTime.fromISO(date) <= interval.end && DateTime.fromISO(date) >= interval.start;
    };

    const rows: any[][] = [];

    const profit = rawData[tenantId]['profit']
        ?.filter((item) => inInterval(item.date, mainInterval))
        .map((item) => ({ date: item.date, value: item[2022].value }));
    const sales = rawData[tenantId]['sales_no_vat']
        ?.filter((item) => inInterval(item.date, mainInterval))
        .map((item) => item[2022].value);
    const ocr = rawData[tenantId]['ocr']
        ?.filter((item) => inInterval(item.date, mainInterval))
        .map((item) => ({ ...item[2022], date: item.date }));

    let row1: any[] = [{ title: true, value: namesMap(t)['ocr'] }];
    let row2: any[] = [{ title: true, value: t('OCR with discount, %') }];
    let row3: any[] = [{ title: true, value: t('Loss of income with discount, ₽') }];

    const newOCR = profit.map((item, i) => {
        const diff = discountUnits === 'percent' ? item.value - (item.value * discount) / 100 : item.value - discount;
        let value: number = Number(((diff / sales[i]) * 100).toFixed(0));
        // let value = Math.round((diff / sales[i]) * 100);
        if (!inInterval(item.date, discountInterval)) {
            value = ocr[i].value;
        }
        return { percent: true, value, date: item.date, newOCR: true };
    });

    const loss = profit.map((item) => {
        let diff = discountUnits === 'percent' ? ((item.value * discount) / 100).toFixed(0) : discount.toFixed(0);
        if (!inInterval(item.date, discountInterval)) {
            diff = '';
        }
        return { percent: false, value: diff, date: item.date };
    });

    row1.push(...ocr);
    row2.push(...newOCR);
    row3.push(...loss);

    // Создаем столбец со средними значениями
    [row1, row2, row3].forEach((row) => {
        const percent = row[row.length - 1].percent;
        const filtered = row
            ?.filter((item) => inInterval(item.date, discountInterval))
            .map((item) => Number(item.value));
        const average = _.sum(filtered) / filtered.length;
        row.push({ percent, value: Math.round(average) });
    });

    // Создаем столбец с суммой потерь
    const percent = row3[row3.length - 1].percent;
    const filtered = row3?.filter((item) => inInterval(item.date, discountInterval)).map((item) => Number(item.value));
    const sum = _.sum(filtered);
    row3.push({ percent, value: Math.round(sum) });

    rows.push(row1, row2, row3);

    const table = rows.map((row, j) => {
        let isTitleRow = false;
        const cells = row.map((cell, i) => {
            const percent = cell.percent ? '%' : '';
            const value = !cell.isYear ? numberWithSpaces(cell.value) + percent : cell.value + percent;
            if (cell.title) {
                isTitleRow = true;
            }
            let color = '#212529';
            let bgColor = 'transparent';
            if (cell.percent) {
                if (cell.value > tenantsOptions[tenantId].percent) {
                    color = 'rgb(252, 54, 47)';
                }
            }
            if (cell.newOCR && inInterval(cell.date, discountInterval)) {
                bgColor = 'hsl(223, 76%, 90%)';
            }
            return (
                <Cell key={`${j}++${i}`} isTitle={cell.title} color={color} bgColor={bgColor}>
                    {value}
                </Cell>
            );
        });
        return (
            <Row key={`${j}++row`} isTitleRow={isTitleRow}>
                {cells}
            </Row>
        );
    });

    // Создаем хедер таблицы
    const headerRow = [{ value: '' }];
    rawData[tenantId]['traffic'].forEach((item) => {
        if (
            DateTime.fromISO(item.date).toMillis() >= DateTime.fromISO(mainPeriod?.dateFrom).toMillis() &&
            DateTime.fromISO(item.date).toMillis() <= DateTime.fromISO(mainPeriod?.dateTo).toMillis()
        ) {
            const dt = DateTime.fromISO(item.date).setLocale(lang);
            headerRow.push({ value: dt.toFormat('LLL yyyy') });
        }
    });
    headerRow.push({ value: t('Average for discount period') });
    headerRow.push({ value: t('Total for discount period') });

    const header = (
        <tr>
            {headerRow.map((item, i) => (
                <th key={`${i}===th`}>{item.value}</th>
            ))}
        </tr>
    );

    return (
        <TableWrapper>
            <table>
                <thead>{header}</thead>
                <tbody>{table}</tbody>
            </table>
        </TableWrapper>
    );
};

export default DiscountTable;
