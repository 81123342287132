import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
`;
export const IconWrapper = styled.div`
    height: 14px;
    cursor: pointer;
    display: flex;
`;
