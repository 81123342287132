import { TTimeFreq } from '../../../../../../../../../../../../lib/esm/components';

import { DateTime } from 'luxon';
import { IRawMetricDataByTimeRange } from '../interfaces';
import { TSeries } from '../../../../../../../../../../Charts/Dynamics/interfaces';
import { stringDate } from '../../../../../../../../../../../../tools/Strings/stringDate';
import { TLang } from '../../../../../../../../../../SideBar/configurations';

/**
 * Функция для генерации серий для графика
 * @param args аргументы
 * @return Массив серий для графика
 */
const generateSeries = (args: IArgs): TSeries[] => {
    const { rawMetricsDataByTimeRange, chartDetailing, lang } = args;
    const series: TSeries[] = [];

    Object.keys(rawMetricsDataByTimeRange).forEach((timeRange, index) => {
        const seriesDataPoints = rawMetricsDataByTimeRange[timeRange].map((element) => {
            return {
                name: stringDate(element.time, lang, '', chartDetailing === 'MS' ? 'MMMM yyyy' : 'MMM dd, yyyy (EEEE)'),
                x: DateTime.fromISO(element.time).toMillis(),
                y: element.value,
            };
        });

        const newSeries: TSeries = {
            name: stringDate(timeRange.split(':'), lang, '', chartDetailing === 'MS' ? 'MMMM yyyy' : 'MMM dd, yyyy'),
            type: index === 0 ? 'areaspline' : 'spline',
            data: seriesDataPoints,
            isMain: index === 0,
            id: timeRange,
        };
        series.push(newSeries);
    });

    return series;
};

export default generateSeries;

interface IArgs {
    /**
     * сырые данные метрик, где ключ это значение dateFrom:dateTo
     */
    rawMetricsDataByTimeRange: IRawMetricDataByTimeRange;
    /**
     * Выбранная детализация
     */
    chartDetailing: TTimeFreq;
    /**
     * Выбранный язык приложения
     */
    lang: TLang;
}
