import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, isNull, omit } from 'lodash';
import { RootState } from '../../../../../../../store';
import { IEvent } from '../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { TColumnsKeys } from './components/Table/constants/constants';
import {
    IChangedData,
    IContextMenuTarget,
    IReducerState,
    IRowValues,
    ITableCellData,
    TFiltersMode,
} from './interfaces';
import generateRowData from './tools/generateRowData';
import getEventDataFromRow from './tools/getEventDataFromTableRow';

const initialState: IReducerState = {
    cancelChangesObject: {},
    contextMenuTarget: null,
    saveChangesObject: {},
    filtersByCellKey: {},
    changedData: {},
    tableData: [],
    timezone: null,
};

const Configuration_EventsConfig_EventManagement_Widget_Reducer = createSlice({
    name: 'Configuration_EventsConfig_EventManagement_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение данных для таблицы
         */
        storeTableData: (state, action: PayloadAction<ITableCellData[][]>) => {
            state.tableData = action.payload;
        },
        /**
         * Добавление удаленной строки в объект с изменениями
         */
        addDeletedRowToChangedData: (state, action: PayloadAction<{ frontId: string }>) => {
            const { timezone } = cloneDeep(state);
            const { frontId } = action.payload;
            let { tableData, changedData } = cloneDeep(state);

            const rowIndex = tableData.findIndex((element) => element.every((element) => element.frontId === frontId));

            if (rowIndex > -1 && timezone) {
                const event = getEventDataFromRow(tableData[rowIndex], timezone);

                if (event.id) {
                    changedData[frontId] = {
                        type: 'DELETE',
                        event,
                    };
                } else {
                    const result = tableData.filter((element) =>
                        element.every((element) => element.frontId !== frontId),
                    );
                    state.tableData = result;
                    changedData = omit(changedData, frontId);
                }
            }
            state.changedData = changedData;
        },
        /**
         * Удаление строки
         */
        deleteRow: (state, action: PayloadAction<string>) => {
            const { tableData } = cloneDeep(state);
            const result = tableData.filter((element) =>
                element.every((element) => element.frontId !== action.payload),
            );
            state.tableData = result;
        },
        /**
         * Добавление новой строки
         */
        addNewRow: (state, action: PayloadAction<string | undefined>) => {
            const { tableData, timezone } = cloneDeep(state);
            let data: undefined | IRowValues = undefined;

            if (action.payload) {
                const row = tableData.find((row) => row.every((cell) => cell.frontId === action.payload));
                if (row) {
                    data = row.reduce((acc, cell) => {
                        acc[cell.key] = cell.value;
                        return acc;
                    }, {}) as IRowValues;
                }
            }

            const row = generateRowData(data);

            tableData.push(row);

            if (timezone) {
                const event = getEventDataFromRow(tableData[tableData.length - 1], timezone);

                const frontId = row[0].frontId;
                state.changedData[frontId] = {
                    type: 'POST',
                    event,
                };
            }
            state.tableData = tableData;
        },
        /**
         * Обновление значения в ячейке
         */
        updateCellValue: (state, action: PayloadAction<{ frontId: string; key: TColumnsKeys; value: any }>) => {
            const { frontId, key, value } = action.payload;
            const { tableData, timezone } = cloneDeep(state);
            const { row, col } = tableData.reduce(
                (acc: { row: null | number; col: null | number }, value, index) => {
                    const rowIndex = value.every((cell) => cell.frontId === frontId);
                    if (rowIndex) {
                        acc.row = index;
                        const colIndex = value.findIndex((cell) => cell.key === key);
                        if (colIndex > -1) {
                            acc.col = colIndex;
                        }
                    }
                    return acc;
                },
                { row: null, col: null },
            );
            if (!isNull(row) && !isNull(col) && timezone) {
                if (tableData[row]?.[col]) {
                    tableData[row][col].value = value;
                    const frontId = tableData[row][col].frontId;
                    const eventId = tableData[row][col].event?.id;
                    const event = getEventDataFromRow(tableData[row], timezone);

                    state.changedData[frontId] = {
                        type: eventId ? 'PATCH' : 'POST',
                        event,
                    };
                    state.tableData = tableData;
                }
            }
        },
        /**
         * Запись новых измененных данных
         */
        addNewChangedData: (state, action: PayloadAction<IChangedData>) => {
            const { changedData } = cloneDeep(state);
            state.changedData = {
                ...changedData,
                ...action.payload,
            };
        },

        /**
         * Убрать элемент из списка измененных
         */
        removeChangedDataItem: (state, action: PayloadAction<string>) => {
            let { changedData } = cloneDeep(state);
            changedData = omit(changedData, action.payload);
            state.changedData = changedData;
        },

        /**
         * Обновление данных таблицу, путем добавления информации о событии
         */
        updateTableDataWithEvent: (state, action: PayloadAction<{ frontId: string; event: IEvent }>) => {
            const { frontId, event } = action.payload;
            const { tableData } = cloneDeep(state);
            const rowIndex = tableData.findIndex((row) => row.every((cell) => cell.frontId === frontId));
            if (rowIndex > -1) {
                tableData[rowIndex] = tableData[rowIndex].map((cell) => {
                    return {
                        ...cell,
                        event: { ...event },
                    };
                });
            }
            state.tableData = tableData;
        },
        /**
         * Обнуление измененных данных
         */
        resetChangedData: (state) => {
            state.changedData = {};
        },

        /**
         * Запись таймзоны
         */
        storeTimezone: (state, action: PayloadAction<string | null>) => {
            state.timezone = action.payload;
        },

        /**
         * Отменить все изменения в таблице
         */
        cancelChanges: (state) => {
            state.cancelChangesObject = {};
        },

        /**
         * Сохранить изменения на сервер
         */
        saveChanges: (state) => {
            state.saveChangesObject = {};
        },

        /**
         * Сохранение данных для ячейки на которую нажали правой кнопкой мыши
         */
        storeContextMenuTarget: (state, action: PayloadAction<null | IContextMenuTarget>) => {
            state.contextMenuTarget = action.payload;
        },

        /**
         * Добавление нового фильра
         */
        addNewFilter: (state, action: PayloadAction<{ filter: string; key: string; mode?: TFiltersMode }>) => {
            const { filter, key, mode } = action.payload;
            state.filtersByCellKey[key] = {
                filter,
                mode,
            };
        },

        /**
         * Обнуление всех фильтров
         */
        resetFilters: (state) => {
            state.filtersByCellKey = {};
        },
    },
});

export const {
    storeTableData,
    addDeletedRowToChangedData,
    updateCellValue,
    addNewChangedData,
    resetChangedData,
    cancelChanges,
    addNewRow,
    saveChanges,
    deleteRow,
    removeChangedDataItem,
    updateTableDataWithEvent,
    storeContextMenuTarget,
    addNewFilter,
    resetFilters,
    storeTimezone,
} = Configuration_EventsConfig_EventManagement_Widget_Reducer.actions;

export const Configuration_EventsConfig_EventManagement_Widget_Reducer_Values = (state: RootState) =>
    state.Configuration_EventsConfig_EventManagement_Widget_Reducer;

export default Configuration_EventsConfig_EventManagement_Widget_Reducer.reducer;
