import { __makeTemplateObject } from '../../../_virtual/_tslib.js';
import styled from 'styled-components/macro';

var IconWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    top: 50%;\n    margin-top: -5px;\n    right: 14px;\n    position: absolute;\n    line-height: 9px;\n    color: var(--color-contrast-medium);\n"], ["\n    top: 50%;\n    margin-top: -5px;\n    right: 14px;\n    position: absolute;\n    line-height: 9px;\n    color: var(--color-contrast-medium);\n"])));
var SelectWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    display: inline-block;\n    opacity: ", ";\n    pointer-events: ", ";\n    width: ", ";\n"], ["\n    position: relative;\n    display: inline-block;\n    opacity: ", ";\n    pointer-events: ", ";\n    width: ", ";\n"])), function (p) { return (p.disabled ? 0.5 : 1); }, function (p) { return (p.disabled ? 'none' : 'initial'); }, function (p) { return (p.fullWidth ? '100%' : 'auto'); });
var CheckBoxWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: var(--space-xxxxs) 0;\n"], ["\n    padding: var(--space-xxxxs) 0;\n"])));
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding-right: var(--space-xxxs);\n"], ["\n    padding-right: var(--space-xxxs);\n"])));
var OptionWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    padding: var(--space-xxxxs) var(--space-xs);\n    cursor: pointer;\n    border: 1px solid transparent;\n    background-color: ", ";\n\n    :hover {\n        background-color: var(--color-primary-lighter);\n        color: white;\n    }\n"], ["\n    padding: var(--space-xxxxs) var(--space-xs);\n    cursor: pointer;\n    border: 1px solid transparent;\n    background-color: ", ";\n\n    :hover {\n        background-color: var(--color-primary-lighter);\n        color: white;\n    }\n"])), function (p) { return (p.selected ? 'var(--color-primary-lighter)' : 'transparent'); });
var List = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    max-height: 400px;\n    margin-left: ", ";\n    overflow: auto;\n"], ["\n    max-height: 400px;\n    margin-left: ", ";\n    overflow: auto;\n"])), function (p) { return (p.mode === 'single' ? '-16px' : '0'); });
var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    justify-content: flex-end;\n"], ["\n    display: flex;\n    justify-content: flex-end;\n"])));
var SearchWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-bottom: var(--space-xxs);\n"], ["\n    margin-bottom: var(--space-xxs);\n"])));
var StDropdown = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    z-index: 100;\n    top: 50px;\n    right: ", ";\n    left: ", ";\n    /* will-change: transform; */\n    position: absolute;\n    /* transform: translate(0px, 36px); */\n    width: ", ";\n"], ["\n    z-index: 100;\n    top: 50px;\n    right: ", ";\n    left: ", ";\n    /* will-change: transform; */\n    position: absolute;\n    /* transform: translate(0px, 36px); */\n    width: ", ";\n"])), function (p) { return (p.dropdownAlign === 'right' ? '10%' : 'unset'); }, function (p) { return (p.dropdownAlign === 'left' ? '10%' : 'unset'); }, function (p) {
    if (p.width === 'sm') {
        return 12.5 + 'rem';
    }
    else if (p.width === 'md') {
        return 17.5 + 'rem';
    }
    else if (p.width === 'lg') {
        return 22.5 + 'rem';
    }
    else if (p.width === 'auto') {
        return 'auto';
    }
    else {
        return 'auto';
    }
});
var SelectButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    width: ", ";\n"], ["\n    width: ", ";\n"])), function (p) { return (p.fullWidth ? '100%' : 'auto'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

export { ButtonWrapper, CheckBoxWrapper, IconWrapper, List, OptionWrapper, SearchWrapper, SelectButton, SelectWrapper, StDropdown, TextWrapper };
