import { PivotItem } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { withLoading } from '../../../../../../../../../tools/API/withLoading';
import { useRerender } from '../../../../../../../../../tools/useRerender';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { TOrdering } from '../../../../../../../OrderSwitcher/interfaces';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../../reducer';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../../reducer';
import HeaderCell from '../HeaderCell';
import Cell from './Cell';
import { TableWrapper, Tr } from './styles';

const ComplexTable: React.FC = ({}) => {
    const [expandedAliases, setExpandedAliases] = useState<string[]>([]);

    const { preparedData, selectedTab, allTenantsData } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const { allMetrics } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();

    const [ordering, setOrdering] = useState<{ ordering: TOrdering; primaryType: string }>({
        ordering: 'none',
        primaryType: 'percent',
    });

    const rerender = useRerender(preparedData);

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;
    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];

    const handleOrderToggle = (args: { ordering: TOrdering; id: string }) => {
        setOrdering({ ordering: args.ordering, primaryType: args.id });
    };

    const table = useMemo(() => {
        if (Array.isArray(preparedData)) {
            const header = (
                <thead>
                    <tr key={`ttss`}>
                        <HeaderCell
                            colSpan={1}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                        >
                            {t('Tenant')}
                        </HeaderCell>
                        {preparedData?.[0]?.metricsData?.map((item: any, i: number) => {
                            const meticName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
                            return (
                                <HeaderCell
                                    key={`${item?.metric}--${i}`}
                                    type={item.metric}
                                    colSpan={2}
                                    handleOrderToggle={handleOrderToggle}
                                    ordering={ordering.ordering}
                                    primaryType={ordering.primaryType}
                                >
                                    {t(meticName)}
                                </HeaderCell>
                            );
                        })}
                    </tr>
                </thead>
            );

            const toggleCollapse = (alias: string) => {
                if (expandedAliases.includes(alias)) {
                    setExpandedAliases(expandedAliases?.filter((item) => item !== alias));
                } else {
                    setExpandedAliases([...expandedAliases, alias]);
                }
            };

            const rows = preparedData
                .map((row) => {
                    const data =
                        row?.metricsData?.reduce((acc: any[], item: any) => {
                            return [
                                ...acc,
                                { value: item?.main, type: 'main', metric: item.metric },
                                { value: item?.deviation, type: 'deviation' },
                            ];
                        }, []) || [];

                    return [
                        {
                            value: row?.tenantName,
                            isSelectedTenant: row.isSelectedTenant,
                            type: 'name',
                            collapseable: row.collapseable,
                            alias: row.alias,
                            isExpanded: expandedAliases.includes(row.alias),
                        },
                        ...data,
                    ];
                })
                ?.filter(
                    (row) =>
                        row[0]?.collapseable ||
                        expandedAliases.includes(row[0]?.alias) ||
                        row[0]?.alias === 'selectedTenant',
                )
                .map((row, i) => {
                    const cells = row.map((cell: any, i: number) => {
                        return <Cell toggleCollapse={toggleCollapse} key={`${cell?.value}mm${i}`} {...cell} />;
                    });
                    const isSelectedTenant = row[0].isSelectedTenant;
                    return (
                        <Tr key={`row{{}}${i}`} isSelectedTenant={isSelectedTenant}>
                            {cells}
                        </Tr>
                    );
                });

            return (
                <table>
                    {header}
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            return null;
        }
    }, [expandedAliases, ordering, preparedData, t]);

    const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: allTenantsData, height: 400 });

    return <WithLoadingTable>{table}</WithLoadingTable>;
};

export default ComplexTable;
