import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const Year = styled.div`
    box-sizing: border-box;
    overflow-x: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    max-height: 77vh;
    grid-gap: 2px;
    border: 1px solid #dce0e0;
`;

export const Cell = styled.div`
    text-transform: capitalize;
    height: 270px;
    outline: 2px solid #dce0e0;
    box-sizing: border-box;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* :last-child {
        border-right: none;
        margin-right: -1px;
    } */
`;

export const MonthTitle = styled.div`
    padding: 5px;
    display: flex;
    color: #80878e;
    justify-content: center;
    background-color: hsla(0, 0%, 92.2%, 0.4);
    height: 30px;
    font-size: 18px;
    align-items: center;
    cursor: pointer;
`;
