import React, { useRef } from 'react';
import { ButtonSizeType, ButtonTheme, ButtonThemeType, Props } from './configurations';
import * as constants from '../../constants/constants';
import useStyles from '../../hooks/useStyles';

import cn from 'classnames';

import './styles/styles.scss';
import { StIcon } from './styles/styles';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';

const Button: React.FC<Props> = ({
    onClick,
    icon,
    iconColor = 'var(--color-contrast-medium)',
    text = 'Button',
    size = 'md',
    type = 'primary',
    theme = '',
    disabled = false,
    children,
    ...props
}) => {
    const ref = useRef<SVGSVGElement>(null);
    const mergedStyles = useStyles({ ref, props, constants });

    const { DEFAULT_CLASS_BTN_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;
    /**
     * Проверка: есть ли такая тема ?
     * @param theme - тема ('outline')
     */
    const isValidTheme = (theme: ButtonThemeType): boolean => {
        const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';
        return (ButtonTheme as Record<string, string>)[capitalize(theme)] !== undefined;
    };

    /**
     * Возращает визуальное представление кнопки
     * @param theme - вид кнопки
     */
    const getThemeCssButton = (theme: ButtonThemeType): string => {
        return isValidTheme(theme) ? `${theme}-` : ButtonTheme.Empty;
    };

    /**
     * получить класс размера кнопки
     * @param size - размер кнопки
     */
    const getSizeCssButton = (size: ButtonSizeType): string => {
        return `${DEFAULT_CLASS_BTN_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${size}`;
    };

    /**
     * получить класс disabled кнопки
     */
    const getDisabledCssButton = (disabled: boolean): string => {
        return disabled ? `${DEFAULT_CLASS_BTN_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}disabled` : '';
    };

    const className = React.useMemo(() => {
        const cssClassButton: string = `${DEFAULT_CLASS_BTN_PREFIX}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}${getThemeCssButton(
            theme,
        )}${type}`;
        const cssClassAlignButton: string = '';
        const cssClassSizeButton = getSizeCssButton(size);
        const cssClassDisabledButton = getDisabledCssButton(disabled);
        return cn([
            'button-height',
            DEFAULT_CLASS_BTN_PREFIX,
            cssClassButton,
            cssClassSizeButton,
            cssClassAlignButton,
            cssClassDisabledButton,
            ...mergedStyles,
        ]);
    }, [
        DEFAULT_CLASS_BTN_PREFIX,
        DEFAULT_SEPARATOR_MODIFICATION_PREFIX,
        getThemeCssButton,
        theme,
        type,
        getSizeCssButton,
        size,
        getDisabledCssButton,
        disabled,
        mergedStyles,
    ]);

    const iconClass = mergeStyles({
        // fontSize: 50,
        // height: 50,
        // width: 50,
        // margin: '0 25px',
    });

    const iconClassNames = mergeStyleSets({
        main: [{ color: iconColor }, iconClass],
    });

    return (
        <button className={className} onClick={onClick} disabled={disabled}>
            {icon && <StIcon iconName={icon} className={iconClassNames.main} />}
            <>{children ? children : text}</>
        </button>
    );
};

export default Button;
