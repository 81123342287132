import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRerender } from '../../../../../../../tools/useRerender';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import EventCard from './components/EventCard/EventCard';
import usePrepareData from './hooks/usePrepareData';
import { CarouselWrapper, NoEventsText, Period, SlideArrowWrapper } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';
import { Icon } from '../../../../../../../lib/esm/components';
import { storeSelectedEventId } from '../../reducer';
import { useNavigate } from 'react-router-dom';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import { reloadWidgets } from '../../../EventsMap/widgets/EventsMapWidget/reducer';
import { IEvent } from '../../../EventsMap/widgets/EventsMapWidget/interfaces';

interface IProps {
    /** Название модуля, в котором находится данныый виджет */
    moduleName: string;
}

/**
 * Компонент для отображения слайдера с событиями за выбранный основной период
 */
const EventsCarousel: FC<IProps> = memo(({ moduleName }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
    const { period, filteredEvents } = usePrepareData({ moduleName });
    const [isSwiperBeginning, setSwiperBeginning] = useState(false);
    const [isSwiperEnd, setSwiperEnd] = useState(false);
    const rerender = useRerender(filteredEvents);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef(null);

    useEffect(() => {
        if (swiperInstance) {
            setSwiperBeginning(swiperInstance.isBeginning);
            setSwiperEnd(swiperInstance.isEnd);
        }
    }, [swiperInstance, period]);

    const onSlideChange = (swiper: SwiperCore) => {
        setSwiperBeginning(swiper.isBeginning);
        setSwiperEnd(swiper.isEnd);
    };

    const storeSelectedEventIdTrans = (id: number) => {
        dispatch(storeSelectedEventId(id));
        navigate('/events/events');
    };

    const changeSlide = (mode: 'next' | 'prev') => () => {
        if (mode === 'next') {
            swiperInstance?.slideNext();
        }

        if (mode === 'prev') {
            swiperInstance?.slidePrev();
        }
    };

    return (
        <div ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Events')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidgets}
                        widgetName="Events"
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <Period>{period.stringValue}</Period>
            <CarouselWrapper>
                {filteredEvents.length ? (
                    <>
                        <Swiper
                            key={JSON.stringify([period, filteredEvents])}
                            onSlideChange={onSlideChange}
                            onSwiper={setSwiperInstance}
                            spaceBetween={15}
                            slidesPerView={4}
                            modules={[A11y]}
                        >
                            {filteredEvents.map((event) => {
                                return (
                                    <SwiperSlide key={event.id}>
                                        <EventCard
                                            storeSelectedEventIdTrans={storeSelectedEventIdTrans}
                                            event={event}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <SlideArrowWrapper hide={isSwiperBeginning} onClick={changeSlide('prev')} side="left">
                            <Icon type="chevron-left" />
                        </SlideArrowWrapper>
                        <SlideArrowWrapper hide={isSwiperEnd} onClick={changeSlide('next')} side="right">
                            <Icon type="chevron-right" />
                        </SlideArrowWrapper>
                    </>
                ) : (
                    <NoEventsText>{t('No events during the period')}</NoEventsText>
                )}
            </CarouselWrapper>
        </div>
    );
});

export default EventsCarousel;
