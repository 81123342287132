import {
    storeReportigObjectsRawMetricsDataById,
    updateExtendedReportingObjectsWithChartOptionsGenratorSettings,
    Performance_VisitorsInside_Widget_Reducer_Values,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import aggregateMetricsResponse from '../tools/aggregateMetricsResponse';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateSeriesData from '../tools/generateSeriesData';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';

/**
 * Кастомный хук для подготовки данных, необходимых для графиков
 */
const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Visitors inside');
    const {
        reportingObjectsRawMetricsDataById,
        extendedReportingObjectsById,
        shoudUpdateChartsByPeriod,
        rawMetricsData,
    } = useSelector(Performance_VisitorsInside_Widget_Reducer_Values);
    const dispatch = useDispatch();
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    /** Получение и запись в стейт настроек графиков  для каждого из отчетных объектов */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod) {
            const chartOptions = generateSeriesData({
                mainDateRanges: localCurrentOptions.mainDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                reportingObjectsRawMetricsDataById,
                extendedReportingObjectsById,
                shoudUpdateChartsByPeriod,
                lang,
                t,
            });
            dispatch(updateExtendedReportingObjectsWithChartOptionsGenratorSettings(chartOptions));
        }
    }, [reportingObjectsRawMetricsDataById, localCurrentOptions?.mainPeriod, t, lang]);

    /** хук для записи сырых данных для каждого из отчетных объектов */
    useEffect(() => {
        const aggregatedData = aggregateMetricsResponse(
            rawMetricsData,
            reportingObjectsRawMetricsDataById,
            shoudUpdateChartsByPeriod,
        );
        dispatch(storeReportigObjectsRawMetricsDataById(aggregatedData));
    }, [rawMetricsData]);
};

export default usePrepareData;
