import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding: 16px 20px 0 20px;
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    height: 55px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const HeaderWrapper = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
`;

export const WrappedItem = styled.div`
    margin-right: 10px;
    display: flex;
    :last-child {
        margin-right: 0px;
    }
`;

export const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #000;
    max-width: 100%;
    word-wrap: break-word;
    margin-right: 10px;
`;
