import { TFunction } from 'react-i18next';
import { IModuleConfig } from '../../../../General.interfaces';
import { ISection, ISubsection, TMenu } from '../configurations';

interface IArgs {
    /** Конфигурация модулей */
    modulesConfig: IModuleConfig[];
    /** Функция перевода */
    t: TFunction;
    permissions: string[];
}

/**
 * Функция генерации данных для бокового меню
 */
const generateMenu = (args: IArgs): TMenu => {
    const { modulesConfig, t, permissions } = args;

    const result = modulesConfig
        .filter((item) => {
            return item.permissions.some((perm) => permissions.includes(perm));
        })
        .reduce((acc: { [sectionName: string]: ISection | ISubsection }, value) => {
            const sectionName = value.module_name.split(':')[0];
            const subsectionName = value.module_name.split(':')[1];
            if (sectionName === 'none') {
                acc[sectionName] = {
                    type: 'subsection',
                    names: { ru: 'Импорт метрик', en: 'Import metrics' },
                    serviceName: value.service.name,
                    serviceHost: value.service.host,
                    servicePath: value.service.path,
                    icon: value.icons.icon,
                    fluentIcon: value.icons.fluent_icon,
                    version: value.version,
                };
            } else {
                const subsection: ISubsection = {
                    type: 'subsection',
                    names: { ru: t(subsectionName), en: subsectionName },
                    servicePath: value.service.path,
                    icon: value.icons.icon,
                    fluentIcon: value.icons.fluent_icon,
                    version: value.version,
                };
                if (acc[sectionName]) {
                    (acc[sectionName] as ISection).subsections.push(subsection);
                } else {
                    acc[sectionName] = {
                        type: 'section',
                        names: { ru: t(sectionName), en: sectionName },
                        serviceName: value.service.name,
                        serviceHost: value.service.host,
                        subsections: [subsection],
                    };
                }
            }

            return acc;
        }, {});

    return Object.values(result);
};

export default generateMenu;
