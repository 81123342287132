import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TMetricResponse } from '../../../../../../../General.interfaces';
import { RootState } from '../../../../../../../store';
import { IChartOptions, IReducerState, ISaleBySubset, TRawMetricsData } from './interfaces';

const initialState: IReducerState = {
    salesBySubset: null,
    rawMetricsData: null,
    chartOptions: null,
    dataRefetchObject: {},
};

export const Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Запись сырых данных
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Сохранение данных по ключу производной от метрики продаж
         */
        storeSalesBySubset: (state, action: PayloadAction<null | ISaleBySubset>) => {
            state.salesBySubset = action.payload;
        },

        /**
         * Сохранение настроек диаграммы
         */
        storeChartOptions: (state, action: PayloadAction<null | IChartOptions>) => {
            state.chartOptions = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const { storeRawMetricsData, storeSalesBySubset, storeChartOptions, reloadWidget } =
    Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer.actions;

export const Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer;

export default Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer.reducer;
