import styled from 'styled-components/macro';

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-top: var(--space-sm);
    margin-bottom: var(--space-xs);
    display: flex;
    align-items: flex-end;
`;
