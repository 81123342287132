import { ButtonWrapper, CheckBoxWrapper, ListWrapper, SearchWrapper, StyledIcon, Wrapper } from './styles';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { cloneDeep } from 'lodash';
import { IReportingObject } from '../../../../../../General.interfaces';
import { Button, Checkbox, Collapse, Search } from '../../../../../../lib/esm/components';

/**
 * Компонент для отображения тела дропдауна
 * @param selectedReportingObjectsВсе выьранные отчетные объекты данного типа
 * @param reportingObjects все отчетные объекты данного типа
 * @param handleSelect Обработичка выбора
 * @param filterType тип очтетного объекта
 */
const DropdownBody: FC<IProps> = ({ reportingObjects, filterType, selectedReportingObjects, handleSelect }) => {
    const [selectedOptions, setSelectedOptions] = useState<IReportingObject[]>([]);
    const [filteredObjects, setFilteredObjects] = useState<IReportingObject[]>([]);
    const [filter, setFilter] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setFilteredObjects(cloneDeep(reportingObjects));
    }, [reportingObjects]);

    useEffect(() => {
        setSelectedOptions(cloneDeep(selectedReportingObjects));
    }, [selectedReportingObjects]);

    const onCheck = (reportingObject: IReportingObject) => (args: { checked: boolean }) => {
        if (args.checked) {
            setSelectedOptions((prevState) => [...prevState, { ...reportingObject }]);
        } else {
            const index = selectedOptions.findIndex((item) => item.id === reportingObject.id);
            if (index > -1) {
                const newState = cloneDeep(selectedOptions);
                newState.splice(index, 1);
                setSelectedOptions(newState);
            }
        }
    };

    const onSaveButtonClick = () => {
        handleSelect(selectedOptions.map((element) => element.id));
    };

    const handleAdditionalControlClick = () => {
        setSelectedOptions([]);
    };

    const filterOnChange = ({ value }: { value: string }) => {
        setFilter(value);
    };

    const list = useMemo(() => {
        return filteredObjects
            ?.filter((reportingObject) => reportingObject.name.toLowerCase().includes(filter.toLowerCase()))
            .map((reportingObject) => {
                const selected = !!selectedOptions?.find((item) => item.id === reportingObject.id);
                return (
                    <CheckBoxWrapper key={reportingObject.id}>
                        <Checkbox
                            handleToggle={onCheck(reportingObject)}
                            label={reportingObject.name}
                            selected={selected}
                        />
                    </CheckBoxWrapper>
                );
            });
    }, [selectedOptions, filteredObjects, filter]);

    return (
        <Wrapper>
            <SearchWrapper>
                <Search fullWidth={true} placeholder={t('Filter')} handleChange={filterOnChange} />
            </SearchWrapper>
            <Collapse
                label={`${filterType}`}
                handleAdditionalControlClick={handleAdditionalControlClick}
                additionalControl={<StyledIcon iconName="Delete" />}
                addClasses={'SelectCollapse'}
                hover="background-none"
                icon="chevron-right"
                iconPosition="right"
                isCollapsed={false}
                key={filterType}
            >
                <ListWrapper>{list}</ListWrapper>
            </Collapse>
            <ButtonWrapper>
                <Button size="sm" margin="t_xxs" onClick={onSaveButtonClick}>
                    {t('Save')}
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default DropdownBody;
