import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store';
import { IReducerState } from './interfaces';

import {
    IPassway2DataObjRow,
    IReportingObject,
    TLoadingData,
    TMetricResponse,
} from '../../../../../../../General.interfaces';

const initialState: IReducerState = {
    preparedData: { message: 'Loading...', status: 'Loading' },
    ratingType: 'all',
    passway2Dataobj: [],
    allFloors: [],
    zonesByGroup: {},
    selectedGroupId: null,
    selectedFloor: null,
    selectedZoneId: null,
    dataRefetchObject: {},
    responseResult: null,
};

export const Events_Events_Summary_Widget_Reducer = createSlice({
    name: 'Events_Events_Summary_Widget_Reducer',
    initialState,

    reducers: {
        storePreraredData: (state, action: PayloadAction<TLoadingData>) => {
            state.preparedData = action.payload;
        },

        storePassway2Dataobj: (state, action: PayloadAction<IPassway2DataObjRow[]>) => {
            state.passway2Dataobj = action.payload;
        },

        storeRatingType: (state, action: PayloadAction<string>) => {
            state.ratingType = action.payload;
        },

        storeZonesByGroup: (state, action: PayloadAction<{ [x: string]: IReportingObject[] }>) => {
            state.zonesByGroup = action.payload;
        },

        storeSelectedGroup: (state, action: PayloadAction<string | null>) => {
            state.selectedGroupId = action.payload;
        },

        storeSelectedZoneId: (state, action: PayloadAction<string | null>) => {
            state.selectedZoneId = action.payload;
        },

        storeResponseResult: (state, action: PayloadAction<TMetricResponse[] | null>) => {
            state.responseResult = action.payload;
        },
        storeAllFloors: (state, action: PayloadAction<IReportingObject[]>) => {
            state.allFloors = action.payload;
        },
        storeSelectedFloor: (state, action: PayloadAction<number | null>) => {
            state.selectedFloor = action.payload;
        },

        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeRatingType,
    storePassway2Dataobj,
    storeAllFloors,
    storeSelectedFloor,
    storePreraredData,
    storeResponseResult,
    storeSelectedGroup,
    storeZonesByGroup,
    storeSelectedZoneId,
    reloadWidget,
} = Events_Events_Summary_Widget_Reducer.actions;

export const Events_Events_Summary_Widget_Reducer_Values = (state: RootState) =>
    state.Events_Events_Summary_Widget_Reducer;

export default Events_Events_Summary_Widget_Reducer.reducer;
