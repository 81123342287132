import React from 'react';
import { getColorFromPercent } from '../../../../../../../../../tools/getColorFromPercent';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { CellWrapper, Percent, Value } from '../../styles';

const Cell: React.FC<{ value: number | string; type: string; name?: string; metric?: string }> = ({
    value,
    type,
    metric,
}) => {
    const cellValue = (value: number | string, type: string) => {
        switch (type) {
            case 'name':
                return value;
            case 'deviation':
                const units = isNaN(Number(value)) ? '' : '%';
                return value + units;
            case 'main':
                return numberWithSpaces(value);

            default:
                return value;
        }
    };

    const styles = (value: number | string, type: string) => {
        if (type === 'name') {
            return { textAlign: 'left', fontWeight: '400', color: 'black' };
        } else if (type === 'main') {
            return { textAlign: 'right', fontWeight: '400', color: 'black' };
        } else {
            const color = getColorFromPercent(value);
            return { textAlign: 'right', fontWeight: '600', color };
        }
    };

    return <CellWrapper styles={styles(value, type)}>{cellValue(value, type)}</CellWrapper>;
};

export default Cell;
