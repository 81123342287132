import styled from 'styled-components/macro';

export const LangWrapper = styled.div`
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: var(--space-xxxs);
    line-height: 0;
    min-height: 36px;
    min-width: 50px;
`;
export const LangValue =  styled.span`
    margin-left: var(--space-xxxs);
`
