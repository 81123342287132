import React, { useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';

import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { ChartWrapper, WidgetWrapper } from './styles';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../reducer';
import { Pivot, PivotItem } from '@fluentui/react';
import { TSelectedChartTypeId } from './interfaces';
import {
    reloadWidget,
    Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values,
    storeSelectedChartType,
} from './reducer';
import Dynamics from '../../../../../Charts/Dynamics/Dynamics';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import { subsetsOfSalesMetricsOptions } from '../../constants/contsants';
import DetailSelect from '../../../../../Selects/DetailSelect/DetailSelect';
import getChartDataDetailing from '../../../../../../../constants/chartDetailing';
import { detailOptions } from '../../../../../../../constants/detailOptions';
import _ from 'lodash';
import { TTimeFreq } from '../../../../../../../lib/esm/components';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const StructureDynamics: React.FC = ({}) => {
    const ref = useRef(null);
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const { selectedChartType, chartOptions, rawMetricsData, chartDynamicOptions } = useSelector(
        Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values,
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const rerender = useRerender(chartOptions);
    const dynamicChartId = useId();
    const handleLinkClick = (item?: PivotItem) => {
        item && dispatch(storeSelectedChartType(item.props.itemKey as TSelectedChartTypeId));
    };

    const detailSelect = useMemo(() => {
        let restrictedOptions: TTimeFreq[] = [];
        if (localCurrentOptions?.mainDateRanges) {
            const validDetailing = getChartDataDetailing(
                t,
                localCurrentOptions?.mainDateRanges,
                localCurrentOptions?.mainPeriod,
            ).map((item) => {
                return item.id;
            });
            const detaiOptionsId = detailOptions.map((item) => {
                return item.id;
            });
            restrictedOptions = _.difference(detaiOptionsId, validDetailing);
        }

        return <DetailSelect restrictedOptions={[...restrictedOptions, '15MIN']} />;
    }, [localCurrentOptions?.mainPeriod]);

    const chart = useMemo(() => {
        const WithLoadingTable = withLoading(ChartWrapper, { height: 400, data: rawMetricsData });

        return <WithLoadingTable>{chartOptions && <Dynamics {...chartOptions} />}</WithLoadingTable>;
    }, [rawMetricsData, chartOptions]);

    const chartDynamic = useMemo(() => {
        const WithLoadingDynamicTable = withLoading(ChartWrapper, { height: 400, data: rawMetricsData });
        return (
            <WithLoadingDynamicTable>
                {chartDynamicOptions && (
                    <Dynamics chartId={`structureDynamics-${dynamicChartId}`} {...chartDynamicOptions} />
                )}
            </WithLoadingDynamicTable>
        );
    }, [rawMetricsData, chartDynamicOptions]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Structure and dynamics')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={'Structure and dynamics'}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
                <Pivot selectedKey={selectedChartType.id} onLinkClick={handleLinkClick}>
                    {subsetsOfSalesMetricsOptions.map((element) => (
                        <PivotItem key={element.id + Math.random()} headerText={t(element.text)} itemKey={element.id} />
                    ))}
                </Pivot>
            </WidgetTitleWrapper>
            {detailSelect}
            {chart}
            {chartDynamic}
        </WidgetWrapper>
    );
};

export default StructureDynamics;
