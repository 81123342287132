import { tenantsReportAdditionalFieldsOptions } from './constants/tenantsReportAdditionalFields';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import { TENANTS_REPORT_ADDITIONAL_FIELDS } from './constants/constants';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import Widget from './widgets/TenantsReportWidget/TenantsReportWidget';
import DetailSelect from '../../../Selects/DetailSelect/DetailSelect';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { OptionsWrapper } from './styles/styles';
import { PresetsWrapper } from '../../../Wrappers/PresetsWrapper/styles';
import Preset from '../../../Preset/Preset';
import { ISettings } from '../../../UserSettings/interfaces';

/**
 * Компонент для отображения репорта по арендаторам
 */
const ReportList: React.FC = ({}) => {
    const { t } = useTranslation();
    const validOptions = [t('Tenants')];

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ObjectsSelect validOptions={validOptions} />
                        <MetricsSelect />
                        <DetailSelect restrictedOptions={['15MIN']} />
                        <UniversalSelect
                            options={tenantsReportAdditionalFieldsOptions}
                            name={TENANTS_REPORT_ADDITIONAL_FIELDS}
                            labelText={t('Additional fields')}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    'mainPeriod',
                                    'selectedReportingObjectsIds',
                                    'selectedMetrics',
                                    'detail',
                                    'tenantsReportAdditionalFileds',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <Widget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default ReportList;
