import { createContext } from 'react';
import { AnyAction } from 'redux';
import {
    IDataObj2ProjectCategory,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
} from '../../../../../../../General.interfaces';
import { IExtendedTenant, ITableOrderData, ITotalValue } from './interfaces';

export interface IContext {
    selectedSubsetId: string | null;
    extendedTenantsById: {
        [id: string]: IExtendedTenant;
    } | null;
    storeSelectedSubsetIdTrans: (id: string) => void;
    tenant2FloorByTenantId: {
        [tenantId: string]: ITenant2FloorRelation[];
    };
    tenant2ZoneByTenantId: {
        [tenantId: string]: ITenant2ZoneRelation[];
    };
    reportingObjectsById: {
        [x: string]: IReportingObject;
    };
    dataObj2ProjectCategory: IDataObj2ProjectCategory[];
    metrcisFetching: boolean;
    metrcisFetchingError: boolean;
    totalValue: ITotalValue | null;
    storeTableOrderDataTrans: (orderData: ITableOrderData | null) => void;
    tableOrderData: ITableOrderData | null;
    reloadWidget: () => AnyAction;
}

export const SalesOfTenantsContext = createContext<null | IContext>(null);
