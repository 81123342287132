import { __assign } from '../../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { AccordionItemStyle, AccordionItemHeading, AccordionItemButton, AccordionIcon, AccordionItemBody } from '../styles/styles.js';
import { useState, useEffect, useMemo } from 'react';
import Button from '../../Button/index.js';
import cn from 'classnames';
import Icon from '../../Icon/index.js';
import 'lodash';
import '../../../hooks/borderRadius/configurations/exclude.enum.js';
import '../../../hooks/borderRadius/configurations/size.enum.js';
import '../../../hooks/shadow/configurations/shadow-size.enum.js';
import '@fluentui/react/lib/Tooltip';
import '@fluentui/react';
import Title from '../../Title/index.js';
import 'luxon';
import '../../DateRangePicker/styles/styles.js';
import '../../DateRangePicker/components/MonthsGrid/styles.js';
import '../../DateRangePicker/components/Header/styles.js';
import '../../DateRangePicker/components/YearsGrid/styles.js';
import '../../Box/styles/styles.js';
import '../../Modals/Modals.styles.js';
import '../../Select/styles/styles.js';
import '../../Search/styles/styles.js';
import 'react-i18next';
import '../../Slider/styles/styles.js';
import 'axios';

var AccordionItem = function (_a) {
    var _b = _a.isOpened, isOpened = _b === void 0 ? false : _b, icon = _a.icon, label = _a.label, id = _a.id, content = _a.content, handleHeaderClick = _a.handleHeaderClick;
    var _c = useState(isOpened), opened = _c[0], setOpened = _c[1];
    useEffect(function () {
        setOpened(function () { return isOpened; });
    }, [isOpened]);
    var onHeaderClick = function (id) { return function () {
        handleHeaderClick(id);
        setOpened(!opened);
    }; };
    var iconClassName = useMemo(function () {
        return cn({ 'collapse__icon--rotate': opened });
    }, [opened]);
    return (jsxs(AccordionItemStyle, { children: [jsx(AccordionItemHeading, __assign({ onClick: onHeaderClick(id) }, { children: jsxs(AccordionItemButton, { children: [jsx(Title, __assign({ titleLevel: "h4" }, { children: label }), void 0), jsx(AccordionIcon, __assign({ className: iconClassName }, { children: jsx(Button, __assign({ type: "base", theme: "icon", size: "sm" }, { children: jsx(Icon, { type: icon, size: "xxxs" }, void 0) }), void 0) }), void 0)] }, void 0) }), void 0), opened && jsx(AccordionItemBody, { children: content }, void 0)] }, void 0));
};

export { AccordionItem as default };
