import { IconButton, IIconProps, TooltipHost } from '@fluentui/react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';

interface IProps {
    reloadHandler: () => AnyAction;
}

const Reload: FC<IProps> = ({ reloadHandler }) => {
    const onReloadClick = () => {
        dispatch(reloadHandler());
    };

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const iconPrtops: IIconProps = {
        iconName: 'Refresh',
        styles: { root: { color: 'rgb(180, 180, 195)', fontSize: '16px' } },
    };

    return (
        <TooltipHost content={t('Reload widget')}>
            <IconButton styles={{ root: { width: '22px' } }} iconProps={iconPrtops} onClick={onReloadClick} />
        </TooltipHost>
    );
};

export default Reload;
