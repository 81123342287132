import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';

const ModuleWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    return <Wrapper>{children}</Wrapper>;
};

export default ModuleWrapper;
