import styled from 'styled-components/macro';

export const WidgetWrapper = styled.div`
    /* margin-top: var(--space-sm); */
`;

export const ColorBubble = styled.div<{ color: string }>`
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: ${(p) => p.color};
`;

export const ListWrapper = styled.div`
    margin-top: var(--space-xxs);
    .ms-DetailsHeader {
        padding-top: 0px;
    }
`;

export const ListRowStyles = {
    root: {
        display: 'flex',
        cursor: 'pointer',
    },
    cell: {
        display: 'flex',
        width: 'auto',
        alignItems: 'center',
    },
};
