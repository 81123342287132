import { Stack, TooltipHost } from '@fluentui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Modals from '../../../../../../../components/Modals';
import { Button } from '../../../../../../../lib/esm/components';
import { useRerender } from '../../../../../../../tools/useRerender';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { storeSelectedEventId as storeSelectedEventIdForEventsModule } from '../../../Events/reducer';
import Calendar from './components/Calendar/Calendar';
import EventModalBody from './components/EventModalBody/EventModalBody';
import MultipleEventsModalBody from './components/MultipleEventsModalBody/MultipleEventsModalBody';
import Sidebar from './components/Sidebar/Sidebar';
import { EventsMapWidgetContext, IEventsMapWidgetContext } from './context';
import { IEvent, IEventTypeToUpdate, IExtendedEvent } from './interfaces';

import {
    Events_EventsMap_Widget_Reducer_Values,
    reloadWidgets,
    storeEventIdToDelete,
    storeEventsToUpdate,
    storeEventTypeToUpdate,
    storeNewEvents,
    storeSelectedEventId,
    storeSelectedEventIdToEdit,
} from './reducer';
import { MapWrapper, WidgetTitleWrapper, WidgetWrapper, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета карты событий (календаря)
 */
const EventsMapWidget = () => {
    const { extendedEventsDataById, eventTypesByName, selectedEventId, selectedEventIdToEdit, eventTypes } =
        useSelector(Events_EventsMap_Widget_Reducer_Values);
    const [multipleEventsModalStatus, setMultipleEventsModalStatus] = useState({ show: false });
    const [eventModalStatus, setEventModalStatus] = useState({ show: false });
    const rerender = useRerender(extendedEventsDataById);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef(null);

    useEffect(() => {
        if (selectedEventId) {
            dispatch(storeSelectedEventIdForEventsModule(selectedEventId));
            dispatch(storeSelectedEventId(null));
            navigate('/events/events');
        }
    }, [selectedEventId]);

    useEffect(() => {
        if (selectedEventIdToEdit) {
            openEventModal();
        }
    }, [selectedEventIdToEdit]);

    const closeEventModal = () => {
        setEventModalStatus({ show: false });
        dispatch(storeSelectedEventIdToEdit(null));
    };

    const openEventModal = () => {
        setEventModalStatus({ show: true });
    };

    const openMultipleEventsModal = () => {
        setMultipleEventsModalStatus({ show: true });
    };

    const closeMultipleEventsModal = () => {
        setMultipleEventsModalStatus({ show: false });
    };

    const storeNewEventsTrans = (events: IEvent[]) => {
        dispatch(storeNewEvents(events));
    };

    const storeEventTypeToUpdateTrans = (event: IEventTypeToUpdate | null) => {
        dispatch(storeEventTypeToUpdate(event));
    };

    const storeSelectedEventIdTrans = (id: number) => {
        dispatch(storeSelectedEventId(id));
    };

    const storeEventIdToDeleteTrans = (id: number) => {
        dispatch(storeEventIdToDelete(id));
    };

    const storeEventsToUpdateTrans = (events: IExtendedEvent[]) => {
        dispatch(storeEventsToUpdate(events));
    };

    const storeSelectedEventIdToEditTrans = (id: number) => {
        dispatch(storeSelectedEventIdToEdit(id));
    };

    const context: IEventsMapWidgetContext = {
        extendedEventsDataById,
        eventTypesByName,
        storeSelectedEventIdToEditTrans,
        storeEventTypeToUpdateTrans,
        storeSelectedEventIdTrans,
        storeEventIdToDeleteTrans,
        storeEventsToUpdateTrans,
        storeNewEventsTrans,
        closeEventModal,
    };

    return (
        <EventsMapWidgetContext.Provider value={context}>
            <WidgetWrapper ref={ref}>
                <Modals
                    title={selectedEventId ? t(extendedEventsDataById[selectedEventId].name) : t('Add new event')}
                    closeModal={closeEventModal}
                    modalStatus={eventModalStatus}
                    topColor="fff"
                >
                    <EventModalBody
                        event={selectedEventIdToEdit ? extendedEventsDataById[selectedEventIdToEdit] : undefined}
                    />
                </Modals>
                <Modals
                    title={t('Add multiple events')}
                    closeModal={closeMultipleEventsModal}
                    modalStatus={multipleEventsModalStatus}
                    topColor="fff"
                >
                    <MultipleEventsModalBody />
                </Modals>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t('Events map')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                            reloadHandler={reloadWidgets}
                            widgetName={'Events map'}
                        />
                    </WidgetTitle>
                    <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                        <Button disabled onClick={openMultipleEventsModal}>
                            {t('Add multiple events')}
                        </Button>
                        <TooltipHost
                            content={!eventTypes.length ? t('You should add a new event type first!') : undefined}
                        >
                            <Button disabled={!!!eventTypes.length} onClick={openEventModal}>
                                {t('Add new event')}
                            </Button>
                        </TooltipHost>
                    </Stack>
                </WidgetTitleWrapper>
                <Wrapper>
                    <Sidebar />
                    <MapWrapper>
                        <Calendar />
                    </MapWrapper>
                </Wrapper>
            </WidgetWrapper>
        </EventsMapWidgetContext.Provider>
    );
};

export default EventsMapWidget;
