import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from '../../../../../../../store';
import {
    IAggregatedMetricsData,
    IExtendedTenant,
    IReducerState,
    ITableOrderData,
    ITotalValue,
    TRawMetricsData,
} from './interfaces';

const initialState: IReducerState = {
    metrcisFetchingError: false,
    extendedTenantsById: null,
    selectedSubsetId: null,
    metrcisFetching: false,
    tableOrderData: null,
    rawMetricsData: null,
    totalValue: null,
    dataRefetchObject: {},
};

const Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение выбранного подмножества
         */
        storeSelectedSubsetId: (state, action: PayloadAction<string | null>) => {
            state.selectedSubsetId = action.payload;
        },

        /**
         * Сохранение расщиренных данных по арендаторам
         */
        storeExtendedTenantsById: (state, action: PayloadAction<null | { [id: string]: IExtendedTenant }>) => {
            state.extendedTenantsById = action.payload;
        },

        /**
         * Обновление расширенных данных по арендаторам, путем добавления агрегированных данных метрик
         */
        updateExtendedTenantsWithMetricData: (state, action: PayloadAction<IAggregatedMetricsData>) => {
            const { extendedTenantsById } = cloneDeep(state);

            Object.keys(action.payload).forEach((tenantId) => {
                const metrcisData = action.payload[tenantId];
                if (extendedTenantsById && extendedTenantsById[tenantId]) {
                    extendedTenantsById[tenantId].metricsData = metrcisData;
                }
                state.extendedTenantsById = extendedTenantsById;
            });
        },

        /**
         * Изменение флага загрузки метрик
         */
        toggleMetricsFetching: (state, action: PayloadAction<boolean>) => {
            state.metrcisFetching = action.payload;
        },

        /**
         * Изменение флага ошибки загрузки метрик
         */
        toggleMetricsFetchingError: (state, action: PayloadAction<boolean>) => {
            state.metrcisFetchingError = action.payload;
        },

        /**
         * Сохранение сырых данных
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Сохранение итоговых значений для периодов
         */
        storeTotalValue: (state, action: PayloadAction<null | ITotalValue>) => {
            state.totalValue = action.payload;
        },

        /**
         * Сохранение данных для сортировки таблицы
         */
        storeTableOrderData: (state, action: PayloadAction<null | ITableOrderData>) => {
            state.tableOrderData = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeSelectedSubsetId,
    storeExtendedTenantsById,
    toggleMetricsFetching,
    toggleMetricsFetchingError,
    storeRawMetricsData,
    updateExtendedTenantsWithMetricData,
    storeTotalValue,
    storeTableOrderData,
    reloadWidget,
} = Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer.actions;

export const Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer;

export default Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer.reducer;
