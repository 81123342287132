import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IMetricResponseItem, TMetricResponse } from '../../../../../../../../General.interfaces';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { subsetsOfSalesMetricsSelect, paymentAnalysisMetricsSelect } from '../../../constants/contsants';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../reducer';
import {
    Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values,
    storeChartOptions,
    storeSalesBySubset,
} from '../reducer';
import generateChartOptions from '../tools/generateChartOptions';
import generateSalesBySubset from '../tools/generateSalesBySubset';

/**
 * Кастомный хук для подготовки данных
 */
const usePrepareData = () => {
    const { rawMetricsData, salesBySubset } = useSelector(Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values);
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Получение продаж по ключу производной */
    useEffect(() => {
        if (Array.isArray(rawMetricsData)) {
            const result = generateSalesBySubset({ rawMetricsData });
            dispatch(storeSalesBySubset(result));
        }
    }, [rawMetricsData]);

    /** Получение настроек диаграммы */
    useEffect(() => {
        if (
            salesBySubset &&
            localCurrentOptions?.[subsetsOfSalesMetricsSelect] &&
            localCurrentOptions?.[paymentAnalysisMetricsSelect]
        ) {
            const chartOptions = generateChartOptions({
                selectedSubsets: localCurrentOptions[subsetsOfSalesMetricsSelect] || [],
                selectedMetric: localCurrentOptions[paymentAnalysisMetricsSelect][0],
                salesBySubset,
                t,
            });
            dispatch(storeChartOptions(chartOptions));
        }
    }, [
        localCurrentOptions?.[subsetsOfSalesMetricsSelect],
        localCurrentOptions?.[paymentAnalysisMetricsSelect],
        salesBySubset,
        t,
    ]);
};

export default usePrepareData;
