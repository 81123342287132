import { useState, useEffect } from 'react';

var DEFAULT_CLASS_PADDING_PREFIX = 'space_inner_';
var DEFAULT_CLASS_MARGIN_PREFIX = 'space_outer_';
var useIndent = function (_a) {
    var _b = _a.styles, padding = _b.padding, margin = _b.margin;
    var _c = useState([]), classes = _c[0], setClasses = _c[1];
    useEffect(function () {
        var classes = [];
        var paddingClasses = padding === null || padding === void 0 ? void 0 : padding.split(' ').map(function (item) {
            return "".concat(DEFAULT_CLASS_PADDING_PREFIX).concat(item);
        });
        var marginClasses = margin === null || margin === void 0 ? void 0 : margin.split(' ').map(function (item) {
            return "".concat(DEFAULT_CLASS_MARGIN_PREFIX).concat(item);
        });
        if (marginClasses) {
            classes.push.apply(classes, marginClasses);
        }
        if (paddingClasses) {
            classes.push.apply(classes, paddingClasses);
        }
        setClasses(classes);
    }, [padding, margin]);
    return classes;
};

export { useIndent as default };
