import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../General.reducer';
import { Select, TTimeFreq } from '../../../../lib/esm/components';
import { IObject } from '../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues, storeDetail } from '../../UserSettings/reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { detailOptions as options } from '../../../../constants/detailOptions';

const DetailSelect: React.FC<IProps> = ({ restrictedOptions = [], ...props }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const translatedOptions = options
        ?.filter((item: { id: TTimeFreq; text: string }) => !restrictedOptions.includes(item.id))
        .map((item) => {
            return { ...item, text: item.text };
        });

    const { locations, currentModule } = useSelector(generalReducerValues);
    const {
        currentOptions: { selectedLocationId, detail },
    } = useSelector(userSettingsReducerValues);

    useEffect(() => {
        const translatedOptionsIds = translatedOptions.map((element) => element.id);
        if (translatedOptions.length && selectedLocationId && !translatedOptionsIds.includes(detail?.id as TTimeFreq)) {
            dispatch(
                storeDetail({
                    module: currentModule,
                    locationId: selectedLocationId,
                    detail: translatedOptions[0],
                }),
            );
        }
    }, [JSON.stringify(translatedOptions)]);

    const handleSelect = (
        args: {
            id: string | number | null;
            text?: string;
        }[],
    ) => {
        const initial = options?.find((obj) => obj.id === args[0].id);
        selectedLocationId &&
            initial?.text &&
            dispatch(
                storeDetail({
                    module: currentModule,
                    locationId: selectedLocationId,
                    detail: initial as IObject,
                }),
            );
    };

    return (
        <Wrapper>
            <Select
                disabled={locations.length === 0}
                options={translatedOptions.map((element) => ({ ...element, text: t(element.text) }))}
                dropdownWidth="sm"
                dropdownAlign="left"
                showFilter={false}
                mode="single"
                labelText={t('Detail')}
                iconType="chevron-down"
                handleSelect={handleSelect}
                outsideText={t(detail?.text || '')}
                outsideSelected={[detail || { id: 'd', text: 'Days' }]}
            />
        </Wrapper>
    );
};

export default DetailSelect;
