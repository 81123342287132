import {
    buildColumns,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IconButton,
    IDetailsHeaderProps,
    IDetailsRowProps,
    mergeStyles,
    Pivot,
    PivotItem,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
} from '@fluentui/react';
import React, { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { ColorBubble, ListRowStyles, ListWrapper, WidgetWrapper } from './styles';
import PDFDownload from '../../../../../WidgetAdditionalControls/components/PDFDownload/PDFDownload';
import { useRerender } from '../../../../../../../tools/useRerender';
import { Button, Modals } from '../../../../../../../lib/esm/components';
import CreateModalBody from './components/CreateModalBody/CreateModalBody';
import { IEventType } from '../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import {
    Events_EventsMap_Widget_Reducer_Values,
    reloadWidgets,
} from '../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetsNavigation from '../../components/WidgetsNavigation/WidgetsNavigation';

const EventTypes: React.FC = ({}) => {
    const [modalStatus, setModalStatus] = useState({ show: false });

    const ref = useRef(null);
    const {
        src: { categories },
    } = useSelector(generalReducerValues);
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const [eventTypeToEdit, setEventTypeToEdit] = useState<IEventType | null>(null);
    const { t } = useTranslation();
    const rerender = useRerender(categories);

    const onCreateClick = () => {
        setModalStatus({ show: true });
    };

    const handleModalClose = () => {
        setModalStatus({ show: false });
        setEventTypeToEdit(null);
    };

    const onEditClick = (eventType: IEventType) => () => {
        setModalStatus({ show: true });
        setEventTypeToEdit(eventType);
    };

    const columns: IColumn[] = buildColumns(
        eventTypes.map((item) => {
            return { id: item.id, name: item.name, color: item.color, edit: '' };
        }),
    );

    const onRenderRow = (props: IDetailsRowProps | undefined) => {
        if (props) {
            return <DetailsRow {...props} styles={ListRowStyles} />;
        }
        return <DetailsRow styles={ListRowStyles} item={undefined} itemIndex={0} />;
    };

    const onRenderItemColumn = (item: IEventType, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof IEventType] as string;

        switch (column.key) {
            case 'name':
                return <span>{fieldContent}</span>;

            case 'color':
                return <ColorBubble color={fieldContent} />;

            case 'edit':
                return (
                    <IconButton
                        onClick={onEditClick(item)}
                        iconProps={{ iconName: 'Edit' }}
                        title="Emoji"
                        ariaLabel="Emoji"
                    />
                );

            default:
                return <span>{fieldContent}</span>;
        }
    };

    // const onRenderDetailsHeader = (
    //     headerProps: IDetailsHeaderProps | undefined,
    //     defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined,
    // ) => {
    //     if (!defaultRender) return null;
    //     return (
    //         <Sticky
    //             stickyPosition={StickyPositionType.Header}
    //             isScrollSynced={true}
    //             stickyBackgroundColor="transparent"
    //         >
    //             <div>{defaultRender(headerProps)}</div>
    //         </Sticky>
    //     );
    // };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Event types management')}
                    <WidgetAdditionalControls
                        widgetName={'Event types management'}
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        // reloadHandler={reloadWidgets}
                    />
                </WidgetTitle>
                <WidgetsNavigation />
            </WidgetTitleWrapper>
            <Modals
                title={eventTypeToEdit ? t('Event type edit') : t('Create new event type')}
                closeModal={handleModalClose}
                modalStatus={modalStatus}
                topColor="fff"
            >
                <CreateModalBody eventTypeToEdit={eventTypeToEdit} closeModal={handleModalClose} />
            </Modals>
            <Button type="primary" text={t('Create new event type')} onClick={onCreateClick} />
            <ListWrapper>
                <DetailsList
                    // onRenderDetailsHeader={onRenderDetailsHeader}
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderRow={onRenderRow}
                    isHeaderVisible={true}
                    items={eventTypes}
                    columns={columns}
                    selectionMode={0}
                    setKey="set"
                />
            </ListWrapper>
        </WidgetWrapper>
    );
};

export default EventTypes;
