import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../../tools/API/constants';

import { Performance_Dashboard_ByArea_Widget_Reducer_Values } from '../../reducer';
import RatingItem from '../Rating/Rating';
import { RatingsWrapper } from './styles';

const Ratings: React.FC = () => {
    const { t } = useTranslation();

    const { allMetrics } = useSelector(generalReducerValues);
    const { colorMetric, treeMapData, responseResult } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const metricName = allMetrics?.filter((item) => item.id === colorMetric)?.[0]?.text;

    const list = (trend: string) => {
        if (Array.isArray(responseResult)) {
            const list = treeMapData
                ?.filter((item) => Number(item.colorValue) !== Infinity)
                ?.filter((item) => {
                    if (trend === 'Growing') {
                        return Number(item.colorValue) >= 0;
                    } else {
                        return Number(item.colorValue) < 0;
                    }
                })
                .sort((a, b) => Math.abs(Number(b.colorValue)) - Math.abs(Number(a.colorValue)));

            return list;
        }
        return responseResult.status;
    };

    return (
        <RatingsWrapper>
            <RatingItem data={list('Growing')} title={`${t('Growing')} ${t('by metric')} "${t(metricName)}"`} />

            <RatingItem data={list('Falling')} title={`${t('Falling')} ${t('by metric')} "${t(metricName)}"`} />
        </RatingsWrapper>
    );
};

export default Ratings;
