import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, reloadCategories } from '../../../../../../../../../../General.reducer';
import { Button, CategoriesAPI, TextInput } from '../../../../../../../../../../lib/esm/components';
import { Footer, Wrapper } from './styles';

const ModalAddSubCategory: React.FC<{
    handleOKClick: () => void;
    modalStatus: { show: boolean; parentId?: number | null; mode?: string };
}> = ({ modalStatus, handleOKClick }) => {
    const [name, setName] = useState('');
    const { token, selectedProjectId } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (args: { value: string }) => {
        setName(() => args.value);
    };

    const onOKClick = () => {
        modalStatus.parentId !== undefined &&
            selectedProjectId &&
            CategoriesAPI({
                method: 'POST',
                token,
                chapter: 'project-categories',
                data: {
                    object_name: name,
                    parent_id: modalStatus?.parentId,
                    project_id: selectedProjectId,
                    is_active: true,
                },
            }).then(() => {
                dispatch(reloadCategories());
            });
        setName(() => '');
        handleOKClick();
    };

    return (
        <Wrapper>
            <TextInput handleChange={handleChange} outsideValue={name} />
            <Footer>
                <Button text={t('Add')} onClick={onOKClick} />
            </Footer>
        </Wrapper>
    );
};

export default ModalAddSubCategory;
