import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { IPeriod } from '../../../../General.interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { Modals, Select } from '../../../../lib/esm/components';
import DateRangePicker from '../../../../lib/esm/components/DateRangePicker';
import { IObject } from '../../../../lib/esm/components/Select/configurations';
import { stringDate } from '../../../../tools/Strings/stringDate';
import { useWidgetCurrentOptions } from '../../../../tools/useWidgetCurrentOptions';
import { userSettingsReducerValues, storeMainPeriod } from '../../UserSettings/reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';

const MainPeriodSelect: React.FC<IProps> = ({
    validOptions = [],
    oneDayOnly = false,
    defaultPeriod,
    yearsOnly = false,
    ...props
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [modalStatus, setModalStatus] = useState({ show: false });

    const { locations, lang, currentModule } = useSelector(generalReducerValues);
    const local = useWidgetCurrentOptions(currentModule);
    const mainPeriod = local?.['mainPeriod']
    const localCurrentModule = local?.['currentModule']
    const {
        currentOptions: { selectedLocationId, mainDateRanges },
    } = useSelector(userSettingsReducerValues);

    // useEffect(() => {
    //     if (validOptions.length && !validOptions.includes(mainPeriod?.id || '')) {
    //         console.log('>>>>', currentModule);

    //         handleSelect([{ id: validOptions[0] }]);
    //     }
    // }, [currentModule]);

    useEffect(() => {
        if (!mainPeriod && selectedLocationId && localCurrentModule === currentModule) {
            if (!defaultPeriod) {
                dispatch(
                    storeMainPeriod({
                        module: currentModule,
                        locationId: selectedLocationId,
                        mainPeriod: { id: 'previousWeek' },
                    }),
                );
            } else {
                dispatch(
                    storeMainPeriod({
                        module: currentModule,
                        locationId: selectedLocationId,
                        mainPeriod: { id: defaultPeriod },
                    }),
                );
            }
        }
    }, [currentModule, defaultPeriod, dispatch, localCurrentModule, mainPeriod, selectedLocationId]);

    const getOutsideText = (period: IPeriod) => {
        if (!period) return '';
        const dates = mainDateRanges?.find((item) => item.id === period.id)?.period;
        if (period.id === 'today' || period.id === 'yesterday') {
            return stringDate(dates?.dateFrom, lang);
        } else {
            return `${stringDate(dates?.dateFrom, lang)} – ${stringDate(dates?.dateTo, lang)}`;
        }
    };

    const options =
        validOptions.length === 0 ? mainDateRanges : mainDateRanges?.filter((range) => validOptions.includes(range.id));

    const handleDateRangeChange = (period: { dateFrom: string; dateTo: string }) => {
        props.handleSelect && props.handleSelect({ id: 'selectCustom', period });
        selectedLocationId &&
            dispatch(
                storeMainPeriod({
                    module: currentModule,
                    locationId: selectedLocationId,
                    mainPeriod: { id: 'selectCustom', period },
                }),
            );
        setModalStatus({ show: false });
    };

    const handleSelect = (args: { id: string | number | null }[]) => {
        if (args[0].id === null) return;
        if (args[0].id !== 'selectCustom') {
            props.handleSelect && props.handleSelect({ id: String(args[0].id) });
            selectedLocationId &&
                dispatch(
                    storeMainPeriod({
                        module: currentModule,
                        locationId: selectedLocationId,
                        mainPeriod: { id: String(args[0].id) },
                    }),
                );
        } else setModalStatus({ show: true });
    };

    return mainPeriod ? (
        <Wrapper>
            <Modals title="" modalStatus={modalStatus}>
                <DateRangePicker
                    handleDateRangeChange={handleDateRangeChange}
                    oneDayOnly={oneDayOnly}
                    yearsOnly={yearsOnly}
                    lang={lang}
                />
            </Modals>
            <Select
                disabled={locations.length === 0}
                options={options as IObject[]}
                dropdownWidth="sm"
                dropdownAlign="left"
                showFilter={false}
                mode="single"
                labelText={t('Main period')}
                iconType="chevron-down"
                handleSelect={handleSelect}
                outsideText={getOutsideText(mainPeriod)}
                outsideSelected={[mainPeriod]}
            />
        </Wrapper>
    ) : null;
};

export default MainPeriodSelect;
