import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store';
import { IChartOptions, IReducerState, ISaleBySubset, TRawMetricsData, TSelectedChartTypeId } from './interfaces';
import { ISelectedChartType } from './interfaces';
import { TMetricResponse } from '../../../../../../../General.interfaces';

const availableChartTypes: ISelectedChartType[] = [
    { headerText: 'Payment attribute', id: 'is_fiscal' },
    { headerText: 'Payment type', id: 'payment_type' },
    { headerText: 'Operation type', id: 'operation_type' },
    { headerText: 'Discount level', id: 'discount_level' },
];

const initialState: IReducerState = {
    salesBySubset: null,
    selectedChartType: availableChartTypes[0],
    availableChartTypes,
    rawMetricsData: null,
    chartOptions: null,
    salesByDerivatives: null,
    chartDynamicOptions: null,
    dataRefetchObject: {},
};

export const Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer',
    initialState,

    reducers: {
        /**
         * Сохранение данных по ключу производной от метрики продаж
         */
        storeSalesBySubset: (state, action: PayloadAction<null | ISaleBySubset>) => {
            state.salesBySubset = action.payload;
        },
        /**
         * Запись сырых данных
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },
        /**
         * Запись выбранного типа графика
         */
        storeSelectedChartType: (state, action: PayloadAction<TSelectedChartTypeId>) => {
            const currentChartType = availableChartTypes?.find((element) => element.id === action.payload);
            state.selectedChartType = currentChartType || availableChartTypes[0];
        },
        /**
         * Сохранение настроек диаграммы столбиков
         */
        storeChartOptions: (state, action: PayloadAction<null | IChartOptions>) => {
            state.chartOptions = action.payload;
        },
        /**
         * Сохранение настроек диаграммы динамики
         */
        storeChartDynamicOptions: (state, action: PayloadAction<null | IChartOptions>) => {
            state.chartDynamicOptions = action.payload;
        },
        /**
         * Сохранение данных по ключу производной от метрики продаж
         */
        storeSalesByDerivatives: (state, action: PayloadAction<null | { [derivative: string]: TMetricResponse[] }>) => {
            state.salesByDerivatives = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeSelectedChartType,
    storeRawMetricsData,
    storeChartOptions,
    storeSalesByDerivatives,
    storeSalesBySubset,
    storeChartDynamicOptions,
    reloadWidget,
} = Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer.actions;

export const Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer;

export default Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer.reducer;
