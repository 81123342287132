import { __spreadArray } from '../../_virtual/_tslib.js';
import { useState, useEffect } from 'react';

var useHover = function (_a) {
    var ref = _a.ref, hover = _a.styles.hover, _b = _a.constants, DEFAULT_SEPARATOR_MODIFICATION_PREFIX = _b.DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_CLASS_HOVER_PREFIX = _b.DEFAULT_CLASS_HOVER_PREFIX;
    var _c = useState([]), classes = _c[0], setClasses = _c[1];
    useEffect(function () {
        hover && setClasses(["".concat(DEFAULT_CLASS_HOVER_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(hover)]);
    }, [hover]);
    var hoverHandler = function (e) {
        e.stopPropagation();
        hover &&
            setClasses(__spreadArray(__spreadArray([], classes, true), ["".concat(DEFAULT_CLASS_HOVER_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX, "active")], false));
    };
    var leaveHandler = function () {
        hover && setClasses(["".concat(DEFAULT_CLASS_HOVER_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(hover)]);
    };
    useEffect(function () {
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            ref.current.addEventListener('mouseover', function () { return hoverHandler; });
            ref.current.addEventListener('mouseleave', leaveHandler);
            return function () {
                var _a, _b;
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseover', function () { return hoverHandler; });
                (_b = ref.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('mouseleave', leaveHandler);
            };
        }
        else {
            return;
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    return classes;
};

export { useHover as default };
