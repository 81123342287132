import { isNull, isUndefined } from 'lodash';

export const getColorFromPercent = (value?: number | string | null | undefined) => {
    let color = 'black';

    if (isNaN(Number(value)) || isNull(value) || isUndefined(value)) {
        return color;
    }

    if (Number(value) > 3) {
        color = 'var(--color-success)';
    } else if (Number(value) < -3) {
        color = 'var(--color-accent)';
    } else if (Number(value) >= -3 && Number(value) <= 3) {
        color = 'var(--color-warning-light)';
    }
    return color;
};
