import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';
import {
    TMetricResponse,
    IReportingObject,
    IMetricResponseItem,
    IResponseItem,
    ISheetCell,
} from '../../../../../../../../General.interfaces';
import { TTimeFreq } from '../../../../../../../../lib/esm/components';

export const generateGrid = (
    response: TMetricResponse[],
    reportingObjects: IReportingObject[],
    allMetrics: {
        id: string;
        text: string;
    }[],
    selectedAddCols: string[],
    t: TFunction,
) => {
    const dataArray: Array<ISheetCell>[] = [];

    const timeFreq: TTimeFreq = response[0][0].context.time_freq as TTimeFreq;
    const timeRange = response[0][0].context.time_range;

    response.forEach((metric: TMetricResponse) => {
        const metricId = metric[0].context.metric;
        metric.forEach((item: IMetricResponseItem) => {
            const timeZone = item.context.data_objects[0].timezone;
            const objId = item.context.data_objects[0].id;
            const objName = reportingObjects?.find((item) => item.id === objId)?.name || '';
            item.items.forEach((data: IResponseItem) => {
                const row: Array<ISheetCell> = [{ key: t('Object'), value: objName }];

                if (selectedAddCols.includes('addId')) {
                    row.unshift({ key: 'Id', value: String(objId) });
                }

                let innerId = ``;

                if (timeFreq === null) {
                    const value = `${timeRange[0]} – ${timeRange[1]}`;
                    row.push({ key: t('Period'), value });
                    innerId = `${objId}:${value}`;
                } else {
                    const value =
                        timeFreq !== 'H'
                            ? DateTime.fromISO(data.time).toISODate()
                            : DateTime.fromISO(data.time).toFormat('yyyy-MM-dd HH:mm');
                    const key = timeFreq !== 'H' ? t('Date') : t('DateTime');
                    row.push({ key, value });
                    innerId = `${objId}:${value}`;
                    const weekDay = DateTime.fromISO(data.time).weekdayShort;

                    if (selectedAddCols.includes('addWeekDay')) {
                        row.push({ key: t('Week day'), value: t(weekDay) });
                    }
                }

                if (selectedAddCols.includes('addTimeZone')) {
                    row.push({ key: t('Time zone'), value: timeZone });
                }

                row.push({ metric: metricId, value: String(data.value) });
                row.push({ key: 'innerId', value: innerId });

                dataArray.push(row);
            });
        });
    });

    const rows: { [x: string]: Array<ISheetCell> } = {};
    dataArray.forEach((item) => {
        const id = item?.find((cell) => cell.key === 'innerId')?.value || ('Y' as string);
        if (rows.hasOwnProperty(id)) {
            const metricCell = item?.find((cell) => cell.metric);
            if (metricCell) {
                rows[id] = [...rows[id], metricCell];
            }
        } else {
            rows[id] = item?.filter((cell) => cell.key !== 'innerId');
        }
    });

    const grid: Array<ISheetCell[]> = Object.values(rows).map((value) => value);

    const firstRow = grid[0].map((item) => {
        if (!item?.metric) return { value: item?.key || '', readOnly: true };
        const metricName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
        return { value: item.key || t(metricName) || '', readOnly: true };
    });
    grid.unshift(firstRow);
    return grid;
};
