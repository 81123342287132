import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { stringDate } from '../../../../../../../tools/Strings/stringDate';
import { Container } from '../../../../../Wrappers/WrapperContainer/styles';
import { IEvent } from '../../../EventsMap/widgets/EventsMapWidget/interfaces';
import {
    Comment,
    EventName,
    EventNameWrapper,
    EventPeriod,
    EventTypeColor,
    OptionsWrapper,
    ReportingObjects,
    Wrapper,
} from './styles';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import { objectTypesMap } from './constants/constants';
import { IReportingObject } from '../../../../../../../General.interfaces';
import { SelectsWrapper } from '../../../../../Wrappers/SelectsWrapper/styles';
import ComparePeriodSelect from '../../../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import { PresetsWrapper } from '../../../../../Wrappers/PresetsWrapper/styles';
import Preset from '../../../../../Preset/Preset';
import MetricsSelect from '../../../../../Selects/MetricsSelect/MetricsSelect';

interface IProps {
    /** Текущее событие */
    event: IEvent;
}

/**
 * Компонент для отображения шапки модуля События
 */
const Header: FC<IProps> = ({ event }) => {
    const {
        lang,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const reportingObjectsByType = useMemo(() => {
        return event.data_objects_ids.reduce((acc, id) => {
            const reportingObject = reportingObjectsById[id];
            if (reportingObject) {
                if (!acc[reportingObject.object_type]) {
                    acc[reportingObject.object_type] = [reportingObject];
                } else {
                    acc[reportingObject.object_type].push(reportingObject);
                }
            }
            return acc;
        }, {});
    }, [reportingObjectsById, event]);

    return (
        <Wrapper>
            <Container>
                <EventNameWrapper>
                    <EventName>{event.name}</EventName>
                    <EventTypeColor color={event.event_type_color} />
                </EventNameWrapper>
                <EventPeriod>
                    {stringDate({ dateFrom: event.date_from, dateTo: event.date_to }, lang, '', 'd MMMM yyyy HH:mm')}
                </EventPeriod>
                {Object.keys(reportingObjectsByType).map((key) => {
                    const text = reportingObjectsByType[key].reduce(
                        (acc: string, value: IReportingObject, index: number) => {
                            if (index + 1 === reportingObjectsByType[key].length) {
                                return (acc += value.name);
                            } else {
                                return (acc += `${value.name}, `);
                            }
                        },
                        '',
                    );
                    return (
                        <ReportingObjects key={key}>
                            <span>{t(objectTypesMap[key] || key)}:</span>
                            {text}
                        </ReportingObjects>
                    );
                })}
                {event.comment && (
                    <Comment>
                        <span>{t('Comment')}:</span>
                        {event.comment}
                    </Comment>
                )}

                <OptionsWrapper>
                    <SelectsWrapper>
                        <ComparePeriodSelect />
                        <MetricsSelect />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset currentOptionsKeys={['selectedMetrics', 'comparePeriods']} />
                    </PresetsWrapper>
                </OptionsWrapper>
            </Container>
        </Wrapper>
    );
};

export default Header;
