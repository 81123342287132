import { ChartWrapper, TabWrapper, TabsWrapper, WidgetWrapper } from './styles';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { AxisLabelsFormatterContextObject } from 'highcharts';
import { DateTime } from 'luxon';
import Dynamics from '../../../../../Charts/Dynamics/Dynamics';
import { TSeries } from '../../../../../Charts/Dynamics/interfaces';
import Table from './components/Table/Table';
import { YOY_TABLE_SETTINGS } from '../../constants/constants';
import { generalReducerValues } from '../../../../../../../General.reducer';
import generateSeriesData from './tools/generateSeriesData';
import { stringDate } from '../../../../../../../tools/Strings/stringDate';
import usePrepareData from './hooks/usePrepareData';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import { Performance_YoY_YoY_Widget_Reducer_Values, reloadWidget } from './reducer';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import { useRerender } from '../../../../../../../tools/useRerender';

/**
 * Виджет Год к Году
 */
const YearOverYear: FC = () => {
    const ref = useRef(null);
    const [selectedReportingObjectId, setSelectedReportingObjectId] = useState<number | null>(null);
    const { extendedReportingObjectsById } = useSelector(Performance_YoY_YoY_Widget_Reducer_Values);
    const rerender = useRerender(extendedReportingObjectsById);
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Year over year');
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    usePrepareData();

    /**
     * При изменении отчетных объектов, проверяет выбранный id отчетного объекта.
     * Если прежнего выбранного объекта нет в текущих отчетных объектоах,
     * то перезаписывает на новое значение
     */
    useEffect(() => {
        if (Object.keys(extendedReportingObjectsById).length) {
            if (selectedReportingObjectId && extendedReportingObjectsById[selectedReportingObjectId]) return;
            const initialSelected = extendedReportingObjectsById[Object.keys(extendedReportingObjectsById)[0]];
            setSelectedReportingObjectId(initialSelected.reportingObjectInfo.id);
        }
    }, [extendedReportingObjectsById]);

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return stringDate(DateTime.fromMillis(this.value as number).toISO(), lang, '', 'MMMM');
    };

    const onTabClick = (id: number) => () => {
        setSelectedReportingObjectId(id);
    };

    /** Отображение табов с именами отчетных объектов */
    const reportingObjectsTabs = useMemo(() => {
        return (
            <TabsWrapper>
                {Object.keys(extendedReportingObjectsById).map((key) => {
                    const extendedReportingObject = extendedReportingObjectsById[key];
                    const isSelected = extendedReportingObject.reportingObjectInfo.id === selectedReportingObjectId;
                    return (
                        <TabWrapper
                            onClick={onTabClick(extendedReportingObject.reportingObjectInfo.id)}
                            key={extendedReportingObject.reportingObjectInfo.id}
                            isSelected={isSelected}
                        >
                            {extendedReportingObject.reportingObjectInfo.name}
                        </TabWrapper>
                    );
                })}
            </TabsWrapper>
        );
    }, [extendedReportingObjectsById, selectedReportingObjectId]);

    /** Отображение диаграммы для выбранного отчетного объекта */
    const selectedReportingObjectChart = useMemo(() => {
        if (selectedReportingObjectId && extendedReportingObjectsById[selectedReportingObjectId]) {
            const { chartOptionsGeneratorSettings, rawMetricsByDate } =
                extendedReportingObjectsById[selectedReportingObjectId];

            const WithLoadingTable = withLoading(ChartWrapper, { data: rawMetricsByDate, height: 400 });
            let series: TSeries[] = [];
            if (chartOptionsGeneratorSettings && Array.isArray(rawMetricsByDate)) {
                series = generateSeriesData(rawMetricsByDate, lang);
            }
            return (
                <WithLoadingTable>
                    <Dynamics
                        tooltipSubTitle={t(chartOptionsGeneratorSettings?.tooltipSubTitle || '')}
                        tooltipTitle={t(chartOptionsGeneratorSettings?.tooltipTitle || '')}
                        xAxisLabelsFormating={xAxisLabelsFormating}
                        series={series}
                    />
                </WithLoadingTable>
            );
        }
        return null;
    }, [selectedReportingObjectId, extendedReportingObjectsById, lang, t]);

    /** Компонент для отображения таблицы для всех отчетных объектов */
    const table = useMemo(() => {
        return (
            <Table
                yoyTableSettings={localCurrentOptions?.[YOY_TABLE_SETTINGS]}
                extendedReportingObjectsById={extendedReportingObjectsById}
                selectedMetrics={localCurrentOptions?.selectedMetrics}
                lang={lang}
            />
        );
    }, [
        localCurrentOptions?.[YOY_TABLE_SETTINGS],
        localCurrentOptions?.selectedMetrics,
        extendedReportingObjectsById,
        lang,
    ]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Retrospective')}
                    <WidgetAdditionalControls
                        widgetName="Retrospective"
                        pdfDownloadData={{
                            targetRef: ref,
                            rerenderKey: rerender,
                        }}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>

            {reportingObjectsTabs}
            {selectedReportingObjectChart}
            {table}
        </WidgetWrapper>
    );
};

export default YearOverYear;
