import { IPlace2ZoneRelation, ISheetCell, ITenant2PlaceRelation } from '../../../../../../../../General.interfaces';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { TENANTS_REPORT_ADDITIONAL_FIELDS } from '../../../constants/constants';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { stringDate } from '../../../../../../../../tools/Strings/stringDate';
import { IPreparedTenantData, ITenantCurrentDateData } from '../interfaces';
import getDateColumnDataByDetail from '../tools/getDateColumnDataByDetail';
import { TTimeFreq } from '../../../../../../../../lib/esm/components';
import { storeGrid, Reports_ByTenants_Widget_Reducer_Values } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';
import generateGridRow from '../tools/generateGridRow';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import {
    tenantsReportAdditionalFieldsOptionsById,
    AdditionalFieldsIds,
} from '../../../constants/tenantsReportAdditionalFields';

/**
 * Кастомный хук для получения самой таблицы
 */
const useGridGenerator = (): void => {
    const { preparedGridData } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const { allMetrics, lang, structures } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions('Reports:By tenants');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localCurrentOptions && preparedGridData && localCurrentOptions.selectedMetrics && structures) {
            const selectedMetrics: { [metricName: string]: string } = localCurrentOptions.selectedMetrics
                .map((metric) => allMetrics?.find((element) => element.id === metric)?.text || metric)
                .reduce((acc, value) => {
                    acc[value] = t(value);
                    return acc;
                }, {});

            const grid: ISheetCell[][] = [];
            const mainPeriodDateRnage = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod?.id,
            );

            const headerDateColumnData = getDateColumnDataByDetail(
                (localCurrentOptions.detail?.id || null) as TTimeFreq,
                `${mainPeriodDateRnage?.period.dateFrom} - ${mainPeriodDateRnage?.period.dateTo}`,
            );

            /** хеддер */
            grid.push(
                generateGridRow({
                    additionalFields: localCurrentOptions[TENANTS_REPORT_ADDITIONAL_FIELDS] || [],
                    timeZone: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.TimeZone].columnName),
                    placeName: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.Place].columnName),
                    weekday: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.WeekDay].columnName),
                    zoneName: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.Zone].columnName),
                    floor: t(tenantsReportAdditionalFieldsOptionsById[AdditionalFieldsIds.Floor].columnName),
                    showWeekDay: localCurrentOptions.detail?.id === null ? false : true,
                    mainPeriod: t(headerDateColumnData.columnHeader),
                    metricsData: selectedMetrics,
                    tenantName: t('Tenant'),
                    isFieldReadOnly: true,
                    tenantId: t('Id'),
                    area: t('Area'),
                }),
            );

            /** тело таблицы */
            Object.keys(preparedGridData).forEach((tenantId: string) => {
                const { tenantData, tenantTableDataByDate }: IPreparedTenantData = preparedGridData[tenantId];

                Object.keys(tenantTableDataByDate).forEach((date) => {
                    const currentDate = DateTime.fromISO(date);

                    const metricDateColumnData = getDateColumnDataByDetail(
                        (localCurrentOptions.detail?.id || null) as TTimeFreq,
                        date,
                    );

                    const { metricsData, additionalFieldsData }: ITenantCurrentDateData = tenantTableDataByDate[date];
                    const convertedMetricData = Object.keys(metricsData).reduce((acc, value) => {
                        acc[value] =
                            metricsData[value] !== null ? numberWithSpaces(metricsData[value]?.toFixed(2) || '') : '--';
                        return acc;
                    }, {});

                    if (additionalFieldsData['relations_tenant2place'].length) {
                        additionalFieldsData['relations_tenant2place'].forEach(
                            (tenant2place: ITenant2PlaceRelation) => {
                                const place2ZoneRelation: IPlace2ZoneRelation[] | undefined =
                                    structures[`relations_place2zone`];

                                if (place2ZoneRelation) {
                                    const suitableZones = place2ZoneRelation?.filter(
                                        (place2zone: IPlace2ZoneRelation) => {
                                            const place2zoneDateFrom = DateTime.fromISO(place2zone.date_from);
                                            const place2zoneDateTo = DateTime.fromISO(place2zone.date_to);
                                            let flag = false;
                                            if (
                                                tenant2place.place_marker === place2zone.place_marker &&
                                                currentDate >= place2zoneDateFrom &&
                                                currentDate <= place2zoneDateTo
                                            ) {
                                                flag = true;
                                            }
                                            return flag;
                                        },
                                    );
                                    suitableZones.forEach((suitableZones: IPlace2ZoneRelation) => {
                                        grid.push(
                                            generateGridRow({
                                                showWeekDay: localCurrentOptions.detail?.id === null ? false : true,
                                                additionalFields:
                                                    localCurrentOptions[TENANTS_REPORT_ADDITIONAL_FIELDS] || [],
                                                mainPeriod:
                                                    localCurrentOptions.detail?.id === null
                                                        ? headerDateColumnData.columnValue
                                                        : metricDateColumnData.columnValue,
                                                placeName: tenant2place.place_name,
                                                zoneName: suitableZones.zone_name,
                                                floor: String(tenant2place.floor),
                                                metricsData: convertedMetricData,
                                                tenantId: String(tenantData.id),
                                                area: String(tenant2place.area),
                                                timeZone: tenantData.timezone,
                                                tenantName: tenantData.name,
                                                isFieldReadOnly: false,
                                                weekday:
                                                    localCurrentOptions.detail?.id === null
                                                        ? stringDate(headerDateColumnData.columnValue, lang, '', 'EEEE')
                                                        : stringDate(date, lang, '', 'EEEE'),
                                            }),
                                        );
                                    });
                                } else {
                                    grid.push(
                                        generateGridRow({
                                            showWeekDay: localCurrentOptions.detail?.id === null ? false : true,
                                            additionalFields:
                                                localCurrentOptions[TENANTS_REPORT_ADDITIONAL_FIELDS] || [],
                                            mainPeriod:
                                                localCurrentOptions.detail?.id === null
                                                    ? headerDateColumnData.columnValue
                                                    : metricDateColumnData.columnValue,
                                            placeName: tenant2place.place_name,
                                            floor: String(tenant2place.floor),
                                            metricsData: convertedMetricData,
                                            tenantId: String(tenantData.id),
                                            area: String(tenant2place.area),
                                            timeZone: tenantData.timezone,
                                            tenantName: tenantData.name,
                                            isFieldReadOnly: false,
                                            zoneName: '--',
                                            weekday:
                                                localCurrentOptions.detail?.id === null
                                                    ? stringDate(headerDateColumnData.columnValue, lang, '', 'EEEE')
                                                    : stringDate(date, lang, '', 'EEEE'),
                                        }),
                                    );
                                }
                            },
                        );
                    } else {
                        grid.push(
                            generateGridRow({
                                showWeekDay: localCurrentOptions.detail?.id === null ? false : true,
                                additionalFields: localCurrentOptions[TENANTS_REPORT_ADDITIONAL_FIELDS] || [],
                                mainPeriod:
                                    localCurrentOptions.detail?.id === null
                                        ? headerDateColumnData.columnValue
                                        : metricDateColumnData.columnValue,
                                metricsData: convertedMetricData,
                                tenantId: String(tenantData.id),
                                timeZone: tenantData.timezone,
                                tenantName: tenantData.name,
                                isFieldReadOnly: false,
                                floor: String('--'),
                                placeName: '--',
                                zoneName: '--',
                                area: '--',
                                weekday:
                                    localCurrentOptions.detail?.id === null
                                        ? stringDate(headerDateColumnData.columnValue, lang, '', 'EEEE')
                                        : stringDate(date, lang, '', 'EEEE'),
                            }),
                        );
                    }
                });
            });

            dispatch(storeGrid(grid));
        }
    }, [localCurrentOptions?.[TENANTS_REPORT_ADDITIONAL_FIELDS], preparedGridData, t, lang, structures]);
};

export default useGridGenerator;
