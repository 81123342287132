import styled from 'styled-components/macro';

export const LoadingWrapper = styled.div<{ height?: number; hasBorder?: boolean }>`
    width: 100%;
    height: ${(p) => p.height + 'px' || 'auto'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${(p) => (p.hasBorder ? '1px solid var(--color-border-gray)' : 'none')};
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
    span {
        margin: var(--space-sm);
        color: var(--color-primary);
    }
`;
