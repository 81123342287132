import { DataLabelsOptions, PointLabelObject } from 'highcharts';
import { TFunction } from 'i18next';
import { cloneDeep } from 'lodash';
import { renderToString } from 'react-dom/server';
import { TMetricResponse } from '../../../../../../../../General.interfaces';
import { ISeriesPoint, TSeries } from '../../../../../../Charts/Dynamics/interfaces';
import { subsetsSubtypeTextMap, subsetsTextMap } from '../../../constants/contsants';
import ColumnLabel from '../components/ColumnLabel/ColumnLabel';
import { IChartOptions, ISaleBySubset } from '../interfaces';

interface IArgs {
    /** Продажи по ключу производной */
    salesBySubset: ISaleBySubset;
    /** Выбранные производные */
    selectedSubsets: { id: string; text: string }[];
    /** Выбрання метрика */
    selectedMetric: { id: string; text: string; units: string };
    /** Функция перевода */
    t: TFunction;
}

/**
 * Функция для генерации настроек диаграммы
 */
const generateChartOptions = (args: IArgs): IChartOptions => {
    const { salesBySubset, selectedSubsets, selectedMetric, t } = args;
    const categories: string[] = [];
    const series: TSeries[] = [];

    const colorsByIndex = {
        '0': '166, 196, 229',
        '1': '71, 117, 41',
        '2': '170, 99, 31',
        '3': '220,108, 73',
    };

    const selectedSubsetsIds = selectedSubsets.map((element) => element.id);

    const filteredSubsets = Object.keys(salesBySubset)?.filter((element) => selectedSubsetsIds.includes(element));

    filteredSubsets.forEach((subset, index) => {
        const sales = salesBySubset[subset];
        if (filteredSubsets.length === 1) {
            const seriesData = sales.map((sale) => {
                const [data] = sale.metricData;
                return {
                    name: t(subsetsSubtypeTextMap[data.context.alias!.split(':')[2]]),
                    additionalText: `(${sale.percentageOfSubset?.toFixed(2)}%)`,
                    title: t(subsetsTextMap[subset]),
                    units: t(selectedMetric.units),
                    y: data.items[0].value,
                } as unknown as ISeriesPoint;
            });

            series.push({
                name: subset,
                data: seriesData,
                id: subset,
                isMain: true,
                type: 'pie',
            });
        } else {
            categories.push(t(subsetsTextMap[subset]));

            cloneDeep(sales)
                .sort((a, b) => {
                    const aData = a.metricData[0];
                    const bData = b.metricData[0];
                    return aData.items[0].value > bData.items[0].value ? 1 : -1;
                })
                .forEach((sale, saleIndex) => {
                    const [data] = sale.metricData;

                    const seriesData = {
                        name: t(subsetsSubtypeTextMap[data.context.alias!.split(':')[2]]),
                        additionalText: `(${sale.percentageOfSubset?.toFixed(2)}%)`,
                        title: t(subsetsTextMap[subset]),
                        units: t(selectedMetric.units),
                        y: data.items[0].value,
                        x: index,
                    };
                    series.push({
                        color: `rgba(${colorsByIndex[index]}, ${1 - Number(`0.${sales.length - saleIndex}`)})`,
                        name: t(subsetsSubtypeTextMap[data.context.alias!.split(':')[2]]),
                        id: data.context.alias!,
                        data: [seriesData],
                        type: 'column',
                        isMain: true,

                        dataLabels: {
                            formatter: function (this: PointLabelObject, _: DataLabelsOptions) {
                                return renderToString(
                                    <ColumnLabel text={subsetsSubtypeTextMap[data.context.alias!.split(':')[2]]} />,
                                );
                            },
                            useHTML: true,
                            align: 'center',
                        },
                    });
                });
        }
    });

    return {
        tooltipSubTitle: `${t(selectedMetric.text)}`,
        columnDataLabelsEnabled: true,
        numberOfSeriesToDispaly: null,
        inactiveSeriesOpacity: 0.2,
        columnsStacking: 'normal',
        columnsGrouping: false,
        xAxisType: 'category',
        sharedTooltips: false,
        categories,
        series,
    };
};

export default generateChartOptions;
