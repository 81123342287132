import { storeRawMetricsDataByTimeRange, Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { IMetricResponseItem } from '../../../../../tools/interfaces';
import { TTimeFreq } from '../../../../../../../../../../../../lib/esm/components';
import { useEffect } from 'react';

/**
 * Кастомный хук для подготовки сырых данных
 */
const usePrepareData = () => {
    const { rawMetricsData } = useSelector(Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Array.isArray(rawMetricsData)) {
            const result = rawMetricsData.reduce((acc, value: IMetricResponseItem[]) => {
                const {
                    context: { time_range },
                    items,
                } = value[0];
                acc[`${time_range[0]}:${time_range[1]}`] = items;
                return acc;
            }, {});
            dispatch(storeRawMetricsDataByTimeRange(result));
        }
    }, [rawMetricsData]);
};

export default usePrepareData;
