import { ISettings, ISubmodule, IAccountParams } from '../interfaces';
import { defaultOptions } from '../reducer';
import { modulesConfig, getStoreMethod } from '../../../../constants/modulesConfig';
import { cloneDeep } from 'lodash';

export interface IRegenerateSettingsArgs {
    newData: { [x: string]: any };
    selectedLocationId: number;
    settings: IAccountParams;
    currentModule: string;
}

/**
 * Функция для перегенирации настроек
 * @param selectedLocationId ID выбранной локации
 * @param currentModule Название текущего модуля
 * @param settings Текущие настройки
 * @param newData Новая информация (ВАЖНО!!! что ключи, составляющие этот объект будут записаны в настройки)
 * @returns Новый оюъект настроек
 */
const regenerateSettings = (args: IRegenerateSettingsArgs): IAccountParams => {
    const { selectedLocationId, currentModule, settings, newData } = args;

    let result = cloneDeep(settings);
    let fullKey = `global`;

    Object.keys(newData).forEach((key) => {
        const storeMethod = getStoreMethod(currentModule, key);

        if (storeMethod === 'byML') {
            fullKey = `${storeMethod}:${currentModule}:${selectedLocationId}`;
        } else if (storeMethod === 'byM') {
            fullKey = `${storeMethod}:${currentModule}`;
        } else if (storeMethod === 'byL') {
            fullKey = `${storeMethod}:${selectedLocationId}`;
        } else {
            fullKey = `global`;
        }
        let options = result?.[fullKey];
        if (!options) result[fullKey] = { ...defaultOptions };
        result[fullKey][key] = newData[key];
    });

    return result;
};

export default regenerateSettings;
