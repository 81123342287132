import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useRerender } from '../../../../../../../tools/useRerender';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import WidgetsNavigation from '../../components/WidgetsNavigation/WidgetsNavigation';
import { TColumnsKeys } from './components/Table/constants/constants';
import Table from './components/Table/Table';
import { IContext, EventsManagementContext } from './context';
import usePrepareData from './hooks/usePrepareData';
import { Stack } from '@fluentui/react';
import {
    addNewFilter,
    addNewRow,
    cancelChanges,
    Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    addDeletedRowToChangedData,
    resetFilters,
    saveChanges,
    storeContextMenuTarget,
    storeTimezone,
    updateCellValue,
} from './redcuer';
import { Button } from '../../../../../../../lib/esm/components';
import useFetchData from './hooks/useFetchData';
import { IContextMenuTarget, TFiltersMode } from './interfaces';

/**
 * Компонент для отображения всех событий
 */
const EventsManagement = () => {
    const {
        timeZone,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { tableData, changedData, contextMenuTarget, filtersByCellKey } = useSelector(
        Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    );
    const { eventTypes, eventTypesByName: eventTypesById } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const rerender = useRerender(tableData);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    usePrepareData();
    useFetchData();

    useEffect(() => {
        dispatch(storeTimezone(timeZone));
    }, [timeZone]);

    const updateCellValueTrans = (data: { frontId: string; key: TColumnsKeys; value: any }) => {
        dispatch(updateCellValue(data));
    };

    const addNewFilterTrans = (data: { filter: string; key: string; mode?: TFiltersMode }) => {
        dispatch(addNewFilter(data));
    };

    const storeContextMenuTragetTrans = (data: null | IContextMenuTarget) => {
        dispatch(storeContextMenuTarget(data));
    };

    const onCloneClickTrans = (frontId: string) => {
        dispatch(addNewRow(frontId));
    };

    const addDeletedRowToChangedDataTrans = (data: { frontId: string }) => {
        dispatch(addDeletedRowToChangedData(data));
    };

    const onAddEmptyRowClick = () => {
        dispatch(addNewRow());
    };

    const onCancelClick = () => {
        dispatch(cancelChanges());
    };

    const onSaveClick = () => {
        dispatch(saveChanges());
    };

    const resetFiltersTrans = () => {
        dispatch(resetFilters());
    };

    const context: IContext = {
        reportingObjectsById,
        contextMenuTarget,
        filtersByCellKey,
        eventTypesById,
        changedData,
        eventTypes,
        tableData,
        addDeletedRowToChangedDataTrans,
        storeContextMenuTragetTrans,
        updateCellValueTrans,
        addNewFilterTrans,
        resetFiltersTrans,
        onCloneClickTrans,
    };

    return (
        <EventsManagementContext.Provider value={context}>
            <div ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t('Events management')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                            widgetName={'Events management'}
                        />
                    </WidgetTitle>
                    <WidgetsNavigation />
                </WidgetTitleWrapper>
                <Stack horizontal={true} horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                    <Button type="base" onClick={onAddEmptyRowClick}>
                        {t('Add empty row')}
                    </Button>

                    <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                        <Button disabled={!Object.keys(changedData).length} onClick={onSaveClick}>
                            {t('Save changes')}
                        </Button>
                        <Button type="warning" onClick={onCancelClick}>
                            {t('Cancel changes')}
                        </Button>
                    </Stack>
                </Stack>
                <Table />
            </div>
        </EventsManagementContext.Provider>
    );
};

export default EventsManagement;
