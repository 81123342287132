import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

//------------------- Performance ----------------------
import Performance_Dashboard_Summary_Widget_Reducer from './cabinet/components/Modules/Performance/Dashboard/widgets/SummaryTable/reducer';
import Performance_Dashboard_ByArea_Widget_Reducer from './cabinet/components/Modules/Performance/Dashboard/widgets/AnalysisByArea/reducer';
import Performance_Dashboard_DynamicsPMO_Widget_Reducer from './cabinet/components/Modules/Performance/Dashboard/widgets/DynamicsPMO/reducer';
import Performance_Dashboard_Traffic_Widget_Reducer from './cabinet/components/Modules/Performance/Dashboard/widgets/Traffic/reducer';
import Performance_VisitorsInside_Widget_Reducer from './cabinet/components/Modules/Performance/VisitorsInside/widgets/VisitorsInside/reducer';
import Performance_PathToPurchase_Widget_Reducer from './cabinet/components/Modules/Performance/PathToPurchase/widgets/PathToPurchaseWidget/reducer';
import Performance_YoY_YoY_Widget_Reducer from './cabinet/components/Modules/Performance/YearOverYear/widgets/YearOverYear/reducer';
import Performance_YoY_YoY_PeriodModalBody_Component_Reducer from './cabinet/components/Modules/Performance/YearOverYear/widgets/YearOverYear/components/Table/components/PeriodModalBody/reducer';
import Performance_Ratings_RatingList_Widget_Reducer from './cabinet/components/Modules/Performance/Ratings/widgets/RatingList/reducer';
//------------------- Maps ----------------------
import Maps_CrossVisits_Module_Reducer from './cabinet/components/Modules/Maps/CrossVisits/reducer';
import Maps_CrossVisits_Widget_Reducer from './cabinet/components/Modules/Maps/CrossVisits/widgets/CrossVisitsWidget/reducer';
import Maps_MapsOverview_Module_Reducer from './cabinet/components/Modules/Maps/MapsOverview/reducer';
import Maps_MapsOverview_Widget_Reducer from './cabinet/components/Modules/Maps/MapsOverview/widgets/MapsWidget/reducer';
//------------------- Sales ----------------------
import Sales_PaymentAnalysis_Module_Reducer from './cabinet/components/Modules/Sales/PaymentAnalysis/reducer';
import Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer from './cabinet/components/Modules/Sales/PaymentAnalysis/widgets/PaymentStructure/reducer';
import Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer from './cabinet/components/Modules/Sales/PaymentAnalysis/widgets/StructureDynamics/reducer';
import Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer from './cabinet/components/Modules/Sales/PaymentAnalysis/widgets/SalesOfTenants/reducer';
//------------------- Cases ----------------------
import Cases_Benchmarks_Sales_Widget_Reducer from './cabinet/components/Modules/Cases/Benchmarks/widgets/Sales/reducer';
import Cases_Benchmarks_Traffic_Widgwet_Reducer from './cabinet/components/Modules/Cases/Benchmarks/widgets/Traffic/reducer';
import Cases_Calculator_Calculator_Widget_Reducer from './cabinet/components/Modules/Cases/Calculator/widgets/CalculatorWidget/reducer';
//------------------- Leasing ----------------------
import Leasing_TenantOverview_Module_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/reducer';
import Leasing_TenantOverview_Info_Widget_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/widgets/Info/reducer';
import Leasing_TenantOverview_Dynamics_Widget_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/widgets/DynamicsPMO/reducer';
import Leasing_TenantOverview_Reach_Widget_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/widgets/Reach/reducer';
import Leasing_TenantOverview_Reference_Widget_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/widgets/Reference/reducer';
import Leasing_TenantOverview_Summary_Widget_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/widgets/SummaryTable/reducer';
import Leasing_CrossVisits_Widget_Reducer from './cabinet/components/Modules/Leasing/CrossVisits/widgets/LeasingCrossVisitsWidget/reducer';
import Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer from './cabinet/components/Modules/Leasing/TenantOverview/widgets/IntersectionsWithTenants/reducer';
import Leasing_Tenants_Tenants_Widget_Reducer from './cabinet/components/Modules/Leasing/Tenants/widgets/TenantsWidget/reducer';
//------------------- Reports ----------------------
import Reports_Basic_Widget_Reducer from './cabinet/components/Modules/Reports/Basic/widgets/BasicReportWidget/reducer';
import Reports_Comparison_Widget_Reducer from './cabinet/components/Modules/Reports/Comparison/widgets/ComparisonReportWidget/reducer';
import Reports_Intersections_Widget_Reducer from './cabinet/components/Modules/Reports/Intersections/widgets/IntersectionsReportWidget/reducer';
import Reports_ByTenants_Widget_Reducer from './cabinet/components/Modules/Reports/Tenants/widgets/TenantsReportWidget/reducer';
//------------------- Configuration ----------------------
import Configuration_Categories_Widget_Reducer from './cabinet/components/Modules/Configuration/Categories/widgets/Categories/reducer';
import Configuration_EventsConfig_Module_Redcuer from './cabinet/components/Modules/Configuration/EventsConfig/reducer';
import Configuration_EventsConfig_EventManagement_Widget_Reducer from './cabinet/components/Modules/Configuration/EventsConfig/widgets/EventsManagement/redcuer';
//------------------- Events --------------------
import Events_EventsMap_Widget_Reducer from './cabinet/components/Modules/Events/EventsMap/widgets/EventsMapWidget/reducer';
import Events_Events_Module_Reducer from './cabinet/components/Modules/Events/Events/reducer';
import Events_Events_Summary_Widget_Reducer from './cabinet/components/Modules/Events/Events/widgets/SummaryTable/reducer';
import Events_Events_DynamicsPMO_Widget_Reducer from './cabinet/components/Modules/Events/Events/widgets/DynamicsPMO/reducer';
import Events_Events_TableOfObjects_Widget_Reducer from './cabinet/components/Modules/Events/Events/widgets/TableOfObjects/reducer';
//------------------- Other ----------------------
import GeneralReducer from './General.reducer';
import UserSettingsReducer from './cabinet/components/UserSettings/reducer';
import PresetReducer from './cabinet/components/Preset/reducer';
import CabinetPreferencesReducer from './cabinet/components/CabinetPreferences/reducer';
import ChartSettingsReducer from './cabinet/components/ChartSettings/reducer';
import AlertsReducer from './cabinet/components/Alert/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

export const store = configureStore({
    reducer: {
        //------------------- Performance ----------------------
        Performance_Dashboard_Summary_Widget_Reducer,
        Performance_Dashboard_ByArea_Widget_Reducer,
        Performance_Dashboard_Traffic_Widget_Reducer,
        Performance_Dashboard_DynamicsPMO_Widget_Reducer,
        Performance_VisitorsInside_Widget_Reducer,
        Performance_YoY_YoY_PeriodModalBody_Component_Reducer,
        Performance_YoY_YoY_Widget_Reducer,
        Performance_PathToPurchase_Widget_Reducer,
        Performance_Ratings_RatingList_Widget_Reducer,
        //------------------- Maps ----------------------
        Maps_MapsOverview_Module_Reducer,
        Maps_MapsOverview_Widget_Reducer,
        Maps_CrossVisits_Module_Reducer,
        Maps_CrossVisits_Widget_Reducer,
        //------------------- Sales ----------------------
        Sales_PaymentAnalysis_Module_Reducer,
        Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer,
        Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer,
        Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer,
        //------------------- Cases ----------------------
        Cases_Benchmarks_Traffic_Widgwet_Reducer,
        Cases_Benchmarks_Sales_Widget_Reducer,
        Cases_Calculator_Calculator_Widget_Reducer,
        //------------------- Leasing ----------------------
        Leasing_TenantOverview_Module_Reducer,
        Leasing_TenantOverview_Info_Widget_Reducer,
        Leasing_TenantOverview_Summary_Widget_Reducer,
        Leasing_TenantOverview_Dynamics_Widget_Reducer,
        Leasing_TenantOverview_Reach_Widget_Reducer,
        Leasing_TenantOverview_Reference_Widget_Reducer,
        Leasing_Tenants_Tenants_Widget_Reducer,
        Leasing_CrossVisits_Widget_Reducer,
        Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer,
        //------------------- Reports ----------------------
        Reports_Basic_Widget_Reducer,
        Reports_Comparison_Widget_Reducer,
        Reports_Intersections_Widget_Reducer,
        Reports_ByTenants_Widget_Reducer,
        //------------------- Configuration ----------------------
        Configuration_Categories_Widget_Reducer,
        Configuration_EventsConfig_Module_Redcuer,
        Configuration_EventsConfig_EventManagement_Widget_Reducer,
        //------------------- Events --------------------
        Events_EventsMap_Widget_Reducer,
        Events_Events_Module_Reducer,
        Events_Events_Summary_Widget_Reducer,
        Events_Events_DynamicsPMO_Widget_Reducer,
        Events_Events_TableOfObjects_Widget_Reducer,
        //------------------- Other ----------------------
        GeneralReducer,
        UserSettingsReducer,
        PresetReducer,
        CabinetPreferencesReducer,
        ChartSettingsReducer,
        AlertsReducer,
    },
    devTools: true,
    middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
