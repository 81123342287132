import { Icon, IconButton, IIconStyles } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    margin: var(--space-xxs) 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const StIcon = styled(Icon)`
    color: var(--color-secondary-darker);
    margin-left: 5px;
    :hover {
        color: var(--color-primary);
    }
`;
