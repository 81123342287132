import { IPlan } from '../../../../../../../General.interfaces';
import { ILatestPlan } from './interfaces';
import { DateTime } from 'luxon';

/**
 * Функция для получения последних (актуальных) палнов для локации
 * @param plans Все планы по локации
 * @returns Массив последних (актуальных) планов
 */
const getLatestPlans = (plans: IPlan[]): ILatestPlan[] => {
    const result = {};
    plans.forEach((plan) => {
        const [mainPlan] = plans?.filter((item) => plan.floor === item.floor)?.filter((item) => item.is_main);
        if (result[plan.floor]) {
            if (
                DateTime.fromISO(plan.active_from).toMillis() >=
                DateTime.fromISO(result[plan.floor].active_from).toMillis()
            ) {
                result[plan.floor] = { ...plan, mainPlan };
            }
        } else {
            result[plan.floor] = { ...plan, mainPlan };
        }
    });
    return Object.keys(result).map((key) => result[key]);
};

export default getLatestPlans;
