import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCurrentModule, changeLang, generalReducerValues, storeSelectedLocationId } from '../General.reducer';
import { userSettingsReducerValues, fillSettingsFromLocalStorage } from '../cabinet/components/UserSettings/reducer';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep, differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import { TLang } from '../cabinet/components/SideBar/configurations';
import { usePrevious } from './usePrevious';

/**
 * Кастомный хук для работы с языком интерфейса
 */
const useSettings = (): void => {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();
    const { lang, selectedLocationId, currentModule, modulesByPaths } = useSelector(generalReducerValues);
    const { settings } = useSelector(userSettingsReducerValues);
    const objectDiff = (a: any, b: any) => fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));
    const previousValue = usePrevious(settings);

    useEffect(() => {
        const selectedLocationIdTemp: string | null = localStorage.getItem('selectedLocationId');
        const lang: string | null = localStorage.getItem('lang');

        if (lang) {
            dispatch(changeLang(lang as TLang));
        }
  
        if (selectedLocationIdTemp) {
            dispatch(storeSelectedLocationId(Number(selectedLocationIdTemp)));
        }
    }, []);

    useEffect(() => {
        if (Object.keys(modulesByPaths).length === 0) return;
        const pathnameTemp: string | null = localStorage.getItem('pathname');

        if (pathnameTemp) {
            if (location.pathname === pathnameTemp) {
                dispatch(changeCurrentModule(modulesByPaths[pathnameTemp]));
                navigate(pathnameTemp, { replace: true });
            } else {
                dispatch(changeCurrentModule(modulesByPaths[location.pathname]));
                navigate(location.pathname, { replace: true });
            }
        }
    }, [modulesByPaths, location.pathname]);

    /**
     * Запись изменения языка в библиатеку i18n и в localStorage
     */
    useEffect(() => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }, [lang]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
            // localStorage.setItem('settings', JSON.stringify(settings));
    //     }, 20000);
    //     return () => clearTimeout(timer);
    // }, [settings]);

    // useEffect(() => {
    //     Object.keys(settings).forEach((key) => {
    //         const diff = objectDiff(settings[key], previousValue?.[key]);
    //         console.log('>>>', diff);
    //     });
    // }, [settings]);

    useEffect(() => {
        localStorage.setItem('selectedLocationId', JSON.stringify(selectedLocationId));
    }, [selectedLocationId]);

    useEffect(() => {
        localStorage.setItem('pathname', window.location.pathname);
    }, [currentModule]);
};

export default useSettings;
