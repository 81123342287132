import { __generator } from '../../../_virtual/_tslib.js';

/**
 * Функция распределяет массив дней месяца месяца по сетке из 6-ти недель календаря
 * @param days Массив дней месяца
 * @returns
 */
var arrangeDaysToMonthGrid = function (days) {
    var monthGrid = [];
    var daysIndex = 0;
    for (var monthWeek = 1; monthWeek <= 6; monthWeek++) {
        var week = [];
        var weekNumber = null;
        for (var weekDay = 1; weekDay <= 7; weekDay++) {
            if (days[daysIndex] && weekDay === days[daysIndex].day.weekday) {
                weekNumber = days[daysIndex].day.weekNumber;
                week.push({ data: days[daysIndex] });
                daysIndex++;
            }
            else {
                week.push({ data: null });
            }
        }
        monthGrid.push({ weekNumber: weekNumber, week: week });
    }
    return monthGrid;
};
function getRanges(interval, mode) {
    var cursor;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                cursor = interval.start.startOf(mode);
                _b.label = 1;
            case 1:
                if (!(cursor < interval.end.endOf(mode))) return [3 /*break*/, 3];
                return [4 /*yield*/, cursor];
            case 2:
                _b.sent();
                cursor = cursor.plus((_a = {}, _a["".concat(mode, "s")] = 1, _a));
                return [3 /*break*/, 1];
            case 3: return [2 /*return*/];
        }
    });
}
var getResult = function (firstSelectedDate, secondSelectedDate) {
    var dateFrom = firstSelectedDate;
    var dateTo = secondSelectedDate;
    if (firstSelectedDate < secondSelectedDate) {
        dateFrom = firstSelectedDate;
        dateTo = secondSelectedDate;
    }
    else if (firstSelectedDate > secondSelectedDate) {
        dateFrom = secondSelectedDate;
        dateTo = firstSelectedDate;
    }
    else {
        dateFrom = firstSelectedDate;
        dateTo = secondSelectedDate;
    }
    return { dateFrom: dateFrom, dateTo: dateTo };
};

export { arrangeDaysToMonthGrid, getRanges, getResult };
