import styled from 'styled-components/macro';
import { TreeItem, TreeView } from '@mui/lab';

export const TreeWrapper = styled.div`
    padding: var(--space-xxs) 0;
    /* max-height: 700px; */
    overflow: auto;
    /* font-weight: 700; */
`;

export const ItemWrapper = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
`;

export const IconWrapper = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-left: var(--space-sm);
    padding-top: 6px;
    color: var(--color-primary);
    cursor: pointer;
`;

export const DeleteIconWrapper = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-left: var(--space-xxs);
    padding-top: 6px;
    color: var(--color-accent-light);
    cursor: pointer;
`;

export const BothIcomsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
