import { FC, useContext, useEffect, useState } from 'react';
import { Table, Wrapper } from './styles';

import Body from './components/Body/Body';

import { IReportingObject } from '../../../../../../../../../General.interfaces';
import TableHeader from './components/TableHeader/TableHeader';
import filterReportingObjects from '../../../../../../../Filters/tools/filterReportingObjects';
import sortReportingObjects from './tools/sortReportingObjects';
import Header from './components/Header/Header';
import { IntersectionsWithTenantsContext } from '../../context';
import { IExtendedReportingObject, IIntersectionData } from '../../interfaces';

interface IProps {
    /** Данные пересенеия для пересекаемых отчетных объектов */
    intersectionsDataById: { [contraId: string]: IIntersectionData } | null;
    /** Текущий отчетный объект */
    extendedReportingObject: IExtendedReportingObject;
}

/**
 * Компонент отображения карточки отчетного объекта
 */
const Card: FC<IProps> = ({ extendedReportingObject, intersectionsDataById }) => {
    const context = useContext(IntersectionsWithTenantsContext);
    const [filteredReportingObjects, setFilteredReportingObjects] = useState<IReportingObject[]>([]);
    const [sortedReportingObjects, setSortedReportingObjects] = useState<IReportingObject[]>([]);

    useEffect(() => {
        setFilteredReportingObjects(
            extendedReportingObject.contraReportingObjects.map((element) => element.reportingObject),
        );
    }, [extendedReportingObject.contraReportingObjects]);

    useEffect(() => {
        setSortedReportingObjects(
            sortReportingObjects({
                reportingObjects: filteredReportingObjects,
                intersectionsDataById,
            }),
        );
    }, [intersectionsDataById, filteredReportingObjects]);

    useEffect(() => {
        if (
            context &&
            context.selectedReportingObjectsType === 'tenant' &&
            context.mainDateRanges &&
            context.mainPeriod
        ) {
            setFilteredReportingObjects(
                filterReportingObjects({
                    initialArray: extendedReportingObject.contraReportingObjects.map(
                        (element) => element.reportingObject,
                    ),
                    dataObj2ProjectCategory: context.dataObj2ProjectCategory,
                    tenant2FloorByTenantId: context.tenant2FloorByTenantId,
                    tenant2ZoneByTenantId: context.tenant2ZoneByTenantId,
                    reportingObjectsById: context?.reportingObjectsById,
                    mainDateRanges: context.mainDateRanges,
                    mainPeriod: context.mainPeriod,
                    filters: context.filters || [],
                }),
            );
        }
    }, [
        context?.dataObj2ProjectCategory,
        extendedReportingObject,
        context?.tenant2FloorByTenantId,
        context?.tenant2ZoneByTenantId,
        context?.reportingObjectsById,
        context?.filters,
    ]);

    const days = context?.daysInPeriod || 1;
    const sumValue = intersectionsDataById
        ? Object.keys(intersectionsDataById).reduce((acc, id) => {
              const value = intersectionsDataById[id];
              if (value.intersection !== null) {
                  acc += value.intersection;
              }
              return acc;
          }, 0)
        : null;

    return (
        <Wrapper>
            <Header extendedReportingObject={extendedReportingObject} />
            <Table>
                <TableHeader sumValue={sumValue} days={days} />
                <Body
                    sortedReportingObjects={sortedReportingObjects}
                    intersectionsDataById={intersectionsDataById}
                    days={days}
                />
            </Table>
        </Wrapper>
    );
};

export default Card;
