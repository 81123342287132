import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import { GreyText, IntersectionThDataWrapper, Th, THead } from './styles';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { FC } from 'react';

/**
 * Компонент для отображения шапки таблицы
 * @param reportingObjectsType Тип отчетных объектов
 * @param isLoading Флаг загрузки
 * @param sumValue Сумма всех значений по пересеченяим
 * @param isError флаг Ошибки
 * @param days количество дней в выбранном периоде
 */
const Header: FC<IProps> = ({ reportingObjectsType, isLoading, isError, sumValue, days }) => {
    const { t } = useTranslation();
    return (
        <THead>
            <tr>
                <Th>
                    <GreyText>{t(reportingObjectsType)}</GreyText>
                </Th>
                <Th>
                    <IntersectionThDataWrapper>
                        <GreyText>{t('Daily average')}</GreyText>
                        {isLoading ? (
                            <GreyText>{t('Loading...')}</GreyText>
                        ) : isError ? (
                            <GreyText>{t('Some error')}</GreyText>
                        ) : (
                            <GreyText>
                                {sumValue !== null ? numberWithSpaces((sumValue / days).toFixed(0)) : '—'}
                            </GreyText>
                        )}
                    </IntersectionThDataWrapper>
                </Th>
                <Th>
                    <IntersectionThDataWrapper>
                        <GreyText>{t('Period')}</GreyText>
                        {isLoading ? (
                            <GreyText>{t('Loading...')}</GreyText>
                        ) : isError ? (
                            <GreyText>{t('Some error')}</GreyText>
                        ) : (
                            <GreyText>{sumValue !== null ? numberWithSpaces(String(sumValue)) : '—'}</GreyText>
                        )}
                    </IntersectionThDataWrapper>
                </Th>
            </tr>
        </THead>
    );
};

export default Header;
