import { noDataOptions } from '../core/options/no-data';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import { SeriesOptionsType } from 'highcharts';
import Highcharts from 'highcharts';
import { chartOptions } from '../core/options/chart';
import { plotOptions } from '../core/options/plot';
import {
    Performance_Dashboard_ByArea_Widget_Reducer_Values,
    resetReducer,
    storeComparePeriod,
    storeData,
    storeObjectsRatingData,
    storeSelectedObjectId,
    storeTreeMapData,
} from '../reducer';
import { PercentColor } from '../core/percent-colors.enum';
import { IObjectsRatingData, ITreeMapData } from '../interfaces';
import { IMetricResponseItem } from '../../../../../../../../General.interfaces';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { isUndefined } from 'lodash';

const initialOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    credits: {
        enabled: false,
    },
    chart: chartOptions,
    noData: noDataOptions,
    plotOptions,
    // tooltip: tooltipOptions,
};

export const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');
    const { allMetrics } = useSelector(generalReducerValues);
    const { colorMetric, responseResult, areaMetric, objectsType, comparePeriod, rawObjectsRatingData } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetReducer());
    }, [JSON.stringify(localCurrentOptions?.selectedLocationId)]);

    useEffect(() => {
        if (objectsType) {
            dispatch(storeSelectedObjectId(null));
        }
    }, [objectsType]);

    useEffect(() => {
        if (localCurrentOptions?.compareDateRanges && localCurrentOptions?.comparePeriods) {
            const isSelected = !!localCurrentOptions.comparePeriods?.find((element) => element.id === comparePeriod.id);
            if (!isSelected) {
                dispatch(storeComparePeriod(localCurrentOptions.comparePeriods[0]));
            }
        }
    }, [localCurrentOptions?.compareDateRanges, localCurrentOptions?.comparePeriods]);

    useEffect(() => {
        if (!Array.isArray(responseResult?.[0])) return;
        const metricName = allMetrics?.find((item) => item.id === colorMetric)?.text || '';
        const data = responseResult[0]?.map((item: IMetricResponseItem) => {
            const objectId = item.context.data_objects[0].id;
            const mainValue = responseResult[1]?.filter(
                (m: IMetricResponseItem) => m.context.data_objects[0].id === objectId,
            )[0]?.items[0].value;
            const compareValue = responseResult[2]?.filter((m: any) => m.context.data_objects[0].id === objectId)[0]
                ?.items[0].value;

            const percentValue = (((Number(mainValue) - Number(compareValue)) / Number(compareValue)) * 100).toFixed(2);
            const name = item.context.data_objects[0].name;
            const value = item.items[0].value;
            const colorValue = Number(percentValue);

            const areaResult: ITreeMapData = {
                name,
                value,
                metricName,
                mainValue,
                colorValue,
                objectId,
            };
            if (isNaN(colorValue) || colorValue === Infinity) {
                areaResult.color = PercentColor.Empty;
            }

            return areaResult;
        });

        const series: Array<SeriesOptionsType> = [
            {
                type: 'treemap',
                layoutAlgorithm: 'strip',
                colorKey: 'colorValue',
                data,
            },
        ];

        dispatch(storeTreeMapData(data as ITreeMapData[]));
        dispatch(storeData({ ...initialOptions, series }));
    }, [allMetrics, colorMetric, areaMetric, dispatch, responseResult]);

    /** Получение рейтинга для выбранного отчетного объекта */
    useEffect(() => {
        if (Array.isArray(rawObjectsRatingData) && localCurrentOptions?.selectedMetrics) {
            const result: IObjectsRatingData[] = [];
            localCurrentOptions.selectedMetrics.forEach((selectedMetric) => {
                const currentMetricName =
                    allMetrics?.find((metric) => metric.id === selectedMetric)?.text || selectedMetric;
                const metricObjectMainPeriodValue = rawObjectsRatingData?.find(
                    (element) => element[0].context.alias === `mainPeriod_${selectedMetric}`,
                )?.[0].items[0].value;
                const metricObjectComparePeriodValue = rawObjectsRatingData?.find(
                    (element) => element[0].context.alias === `comparePeriod_${selectedMetric}`,
                )?.[0].items[0].value;

                let colorValue: number | null = null;

                if (!isUndefined(metricObjectMainPeriodValue) && !isUndefined(metricObjectComparePeriodValue)) {
                    colorValue = Number(
                        (
                            ((metricObjectMainPeriodValue - metricObjectComparePeriodValue) /
                                (metricObjectComparePeriodValue || 1)) *
                            100
                        ).toFixed(2),
                    );
                }
                result.push({
                    name: currentMetricName,
                    colorValue,
                });
            });

            dispatch(storeObjectsRatingData(result));
        }
    }, [rawObjectsRatingData, localCurrentOptions?.selectedMetrics, allMetrics]);
};
