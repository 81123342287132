import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import Button from '../Button/index.js';
import Icon from '../Icon/index.js';
import * as constants from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';

var Collapse = function (_a) {
    var _b = _a.isCollapsed, isCollapsed = _b === void 0 ? true : _b, toggleCollapse = _a.toggleCollapse, _c = _a.actingAllHeader, actingAllHeader = _c === void 0 ? true : _c, _d = _a.iconPosition, iconPosition = _d === void 0 ? 'left' : _d, icon = _a.icon, label = _a.label, collapseHeader = _a.collapseHeader, children = _a.children, handleAdditionalControlClick = _a.handleAdditionalControlClick, additionalControl = _a.additionalControl, props = __rest(_a, ["isCollapsed", "toggleCollapse", "actingAllHeader", "iconPosition", "icon", "label", "collapseHeader", "children", "handleAdditionalControlClick", "additionalControl"]);
    var _e = useState(isCollapsed), collapsed = _e[0], setCollapsed = _e[1];
    useEffect(function () {
        setCollapsed(function () { return isCollapsed; });
    }, [isCollapsed]);
    var ref = useRef(null);
    var mergedStyles = useStyles({ ref: ref, props: props, constants: constants });
    var onHeaderClick = function () {
        actingAllHeader && setCollapsed(function () { return !collapsed; });
        toggleCollapse && toggleCollapse(!collapsed);
    };
    var onButtonClick = function () {
        !actingAllHeader && setCollapsed(function () { return !collapsed; });
    };
    var headerClassName = useMemo(function () {
        return cn(['molecules-collapse__header', { 'molecules-collapse--open': !collapsed }]);
    }, [collapsed]);
    var mainClassName = useMemo(function () {
        return cn(__spreadArray(['molecules-collapse'], mergedStyles, true));
    }, [mergedStyles]);
    var iconClassName = useMemo(function () {
        return cn('molecules-collapse__icon', { 'molecules-collapse__icon--left': iconPosition === 'left' }, { 'molecules-collapse__icon--right': iconPosition === 'right' }, { 'collapse__icon--rotate': !collapsed });
    }, [collapsed]);
    var onAdditionalControlClick = function (label) { return function (e) {
        e.stopPropagation();
        handleAdditionalControlClick && label && handleAdditionalControlClick(label);
    }; };
    return (jsxs("div", __assign({ className: mainClassName }, { children: [jsxs("div", __assign({ ref: ref, className: headerClassName, onClick: onHeaderClick }, { children: [icon && iconPosition === 'left' && (jsx("div", __assign({ className: iconClassName }, { children: jsx(Button, __assign({ type: "base", theme: "icon", size: "sm", onClick: onButtonClick }, { children: jsx(Icon, { type: icon, size: "xxxs" }, void 0) }), void 0) }), void 0)), label && jsx("div", __assign({ className: "molecules-collapse__label" }, { children: label }), void 0), additionalControl && jsx("div", __assign({ onClick: onAdditionalControlClick(label) }, { children: additionalControl }), void 0), collapseHeader && collapseHeader, icon && iconPosition === 'right' && (jsx("div", __assign({ className: iconClassName }, { children: jsx(Button, __assign({ type: "base", theme: "icon", size: "sm", onClick: onButtonClick }, { children: jsx(Icon, { type: icon, size: "xxxs" }, void 0) }), void 0) }), void 0))] }), void 0), !collapsed && children && jsx("div", __assign({ className: "molecules-collapse__body" }, { children: children }), void 0)] }), void 0));
};

export { Collapse as default };
