import { IChartSettings, IState, TChartSettingsKeys, TChartSettingsValues } from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../store';
import { cloneDeep } from 'lodash';

const initialState: IState = {
    chartSettingsById: {},
};

const ChartSettingsReducer = createSlice({
    name: 'ChartSettingsReducer',
    initialState,
    reducers: {
        /**
         * Добавление новых настроек для графиков
         */
        storeChartSettingsById: (state, action: PayloadAction<{ [chartId: string]: IChartSettings }>) => {
            const chartSettingsById = cloneDeep(state.chartSettingsById);

            state.chartSettingsById = {
                ...chartSettingsById,
                ...action.payload,
            };
        },

        /**
         * Обновление настроек графика
         */
        updateChartSettingsById: (
            state,
            action: PayloadAction<{ chartId: string; key: TChartSettingsKeys; value: any }>,
        ) => {
            const { chartId, key, value } = action.payload;
            if (state.chartSettingsById[chartId]) {
                state.chartSettingsById[chartId][key] = value;
            }
        },
    },
});

export const { storeChartSettingsById, updateChartSettingsById } = ChartSettingsReducer.actions;

export const chartSettingsReducerValues = (state: RootState) => state.ChartSettingsReducer;

export default ChartSettingsReducer.reducer;
