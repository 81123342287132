import { DirectionalHint, TooltipHost } from '@fluentui/react';
import _ from 'lodash';
import React from 'react';
import { getFixedLines } from '../../../../../../../../../tools/getFixedLines';
import { Props } from './interfaces';
import { Color, Li, Ul, Wrapper, Text } from './styles';
import { storeOverMarker } from '../../../../reducer';
import { useDispatch } from 'react-redux';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { allMetrics } from '../../../../../../../../../constants/metricsNames';
import { useTranslation } from 'react-i18next';

const Ratings: React.FC<Props> = ({ colorsByMarker, metric, height }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions('Maps:Maps overview');

    if (!colorsByMarker || !colorsByMarker[metric]) return null;

    const onMouseOver = (marker: string) => (e: React.MouseEvent) => {
        dispatch(storeOverMarker(marker));
    };

    const onMouseLeave = (e: React.MouseEvent) => {
        dispatch(storeOverMarker(null));
    };

    const showComparison =
        localCurrentOptions?.['mapsSettings']?.find((item: any) => item.id === 'comparison') !== undefined;

    const units = showComparison ? '%' : t(allMetrics?.find((m) => m.id === metric)?.units || '');

    const list = _.map(Object.keys(colorsByMarker[metric]), (marker) => {
        return colorsByMarker[metric][marker];
    })
        ?.filter((item) => item.value !== undefined)
        .sort((a, b) => b.value - a.value)
        .map((item, i) => {
            const value = Number(Number(item?.value).toFixed(2));

            let text = `${getFixedLines(item.name, 25)}: ${numberWithSpaces(value)} ${units}`;
            let content = `${item.name}: ${numberWithSpaces(value)} ${units}`;

            return (
                <Li
                    key={`${item.name}${item.id}00${i}`}
                    onMouseOver={onMouseOver(item.marker)}
                    onMouseLeave={onMouseLeave}
                >
                    <TooltipHost content={content} directionalHint={DirectionalHint.leftCenter}>
                        <Color width={`${item.normValue * 100}%`} bgColor={item.heatColor}>
                            <Text>{text}</Text>
                        </Color>
                    </TooltipHost>
                </Li>
            );
        });
    return (
        <Wrapper height={height}>
            <Ul>{list}</Ul>
        </Wrapper>
    );
};

export default Ratings;
