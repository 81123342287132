import { useState, useEffect } from 'react';

var useColor = function (_a) {
    _a.ref; var _b = _a.styles, color = _b.color, bgColor = _b.bgColor, _c = _a.constants, DEFAULT_CLASS_COLOR_PREFIX = _c.DEFAULT_CLASS_COLOR_PREFIX, DEFAULT_SEPARATOR_NAME_SPACE_PREFIX = _c.DEFAULT_SEPARATOR_NAME_SPACE_PREFIX, DEFAULT_CLASS_BACKGROUND_PREFIX = _c.DEFAULT_CLASS_BACKGROUND_PREFIX;
    var _d = useState([]), classes = _d[0], setClasses = _d[1];
    useEffect(function () {
        var colorClasses = [];
        if (color) {
            colorClasses.push("".concat(DEFAULT_CLASS_COLOR_PREFIX).concat(DEFAULT_SEPARATOR_NAME_SPACE_PREFIX).concat(color));
        }
        if (bgColor) {
            colorClasses.push("".concat(DEFAULT_CLASS_BACKGROUND_PREFIX).concat(DEFAULT_SEPARATOR_NAME_SPACE_PREFIX).concat(bgColor));
        }
        setClasses(colorClasses);
    }, [color, bgColor]);
    return classes;
};

export { useColor as default };
