import { generateIntersectionsReportAddFields } from './widgets/IntersectionsReportWidget/constants/intersectionsReportAdditionalFields';
import { INTERSECTIONS_REPORT_ADDITIONAL_FILEDS } from './widgets/IntersectionsReportWidget/constants/constants';
import Widget from './widgets/IntersectionsReportWidget/IntersectionsReportWidget';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { PresetsWrapper } from '../../../Wrappers/PresetsWrapper/styles';
import Preset from '../../../Preset/Preset';
import { ISettings } from '../../../UserSettings/interfaces';
import { OptionsWrapper } from './styles/styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { getReportingObjects } from '../../../../../tools/getReportingObjects';
import UniversalNestedSelect from '../../../Selects/UniversalNestedSelect/UniversalNestedSelect';
import { useWidgetCurrentOptions } from '../../../../../tools/useWidgetCurrentOptions';

/**
 * Модуль среднедневынх пересечений
 */
const DailyIntersections: React.FC = ({}) => {
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Daily intersections');

    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);

    const validOptions = [t('Location'), t('Tenants'), t('Zones')];

    const compareObjectsOptions = useMemo(() => {
        let result = [];

        result.push(
            ...getReportingObjects(reportingObjectsByType, t)
                ?.filter((item) => validOptions.map((item) => item.toLowerCase()).includes(item.section.toLowerCase()))
                .map((section) => {
                    return {
                        ...section,
                        objects: section.objects?.filter(
                            (obj) =>
                                !localCurrentOptions?.selectedReportingObjectsIds?.includes(obj.id) ||
                                (localCurrentOptions?.selectedReportingObjectsIds?.includes(obj.id) &&
                                    localCurrentOptions?.['compareObjects']
                                        ?.map((item: any) => item.id)
                                        ?.includes(obj.id)),
                        ),
                    };
                }),
        );

        return result;
    }, [reportingObjectsByType, t, validOptions, localCurrentOptions]);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ObjectsSelect maxSelected={5} validOptions={validOptions} />
                        <UniversalNestedSelect
                            options={compareObjectsOptions}
                            name={'compareObjects'}
                            maxSelected={5}
                            minSelected={0}
                            labelText={t('Objects to compare')}
                        />

                        <UniversalSelect
                            options={generateIntersectionsReportAddFields(t)}
                            name={INTERSECTIONS_REPORT_ADDITIONAL_FILEDS}
                            labelText={t('Additional fields')}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    'mainPeriod',
                                    'selectedReportingObjectsIds',
                                    'compareObjects',
                                    'intersectionsReportAdditionalFields',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <Widget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default DailyIntersections;
