import { useState, useEffect } from 'react';
import _ from 'lodash';
import { ShadowSize } from './configurations/shadow-size.enum.js';

var useShadow = function (_a) {
    _a.ref; var shadow = _a.styles.shadow, _b = _a.constants, DEFAULT_CLASS_SHADOW_PREFIX = _b.DEFAULT_CLASS_SHADOW_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX = _b.DEFAULT_SEPARATOR_MODIFICATION_PREFIX;
    var _c = useState([]), classes = _c[0], setClasses = _c[1];
    /**
     * Проверка: есть ли такой размер ?
     * @param size - размер тени, например ('sm')
     */
    function isValidSize(size) {
        return _.values(ShadowSize).includes(size);
    }
    /**
     * Возвращает размер тени
     * @param size - размер тени, например ('sm')
     */
    function getShadowSizeClass(size) {
        if (isValidSize(size)) {
            return size;
        }
        else {
            return ShadowSize.Default;
        }
    }
    useEffect(function () {
        var classes = [];
        if (shadow && isValidSize(shadow)) {
            var sizeClassModification = getShadowSizeClass(shadow);
            classes.push("".concat(DEFAULT_CLASS_SHADOW_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(sizeClassModification));
        }
        setClasses(classes);
    }, [shadow]);
    return classes;
};

export { useShadow as default };
