import { __rest, __assign } from '../../_virtual/_tslib.js';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { Overlay, Modal, Header, Title, Body } from './Modals.styles.js';
import Button from '../Button/index.js';
import Icon from '../Icon/index.js';

/**
 * Обертка для всех модалок.
 * @param modalStatus Состояние модалки, управляемое извне.
 * @param title Название модалки.
 */
var Modals = function (_a) {
    var topColor = _a.topColor, title = _a.title, props = __rest(_a, ["topColor", "title"]);
    var _b = useState(false), isModalOpen = _b[0], setModalOpen = _b[1];
    useEffect(function () {
        props.modalStatus.show ? open() : close();
    }, [props.modalStatus]);
    useEffect(function () {
        !isModalOpen && props.closeModal && props.closeModal();
    }, [isModalOpen]);
    useEffect(function () {
        function listener(e) {
            if (e.code === 'Escape')
                close();
        }
        document.addEventListener('keydown', listener);
        return function () { return document.removeEventListener('keydown', listener); };
    }, []);
    var close = function () { return setModalOpen(false); };
    var open = function () { return setModalOpen(true); };
    return (jsx(Overlay, __assign({ tabIndex: 0, isActive: isModalOpen, onClick: close }, { children: jsxs(Modal, __assign({ topColor: topColor, isActive: isModalOpen, onClick: function (e) { return e.stopPropagation(); } }, { children: [jsxs(Header, { children: [jsx(Title, { children: title }, void 0), jsx(Button, __assign({ onClick: close, theme: "icon", type: "base" }, { children: jsx(Icon, { type: "close", size: "xxs" }, void 0) }), void 0)] }, void 0), jsx(Body, { children: props.children }, void 0)] }), void 0) }), void 0));
};

export { Modals as default };
