import { ILegendProps } from './interfaces';
import { ChartDot } from '../../../styles';
import { Text, Wrapper } from './styles';
import { FC } from 'react';

/**
 * Компонент для отображения легенды графиков DynamicsPMO
 * @param visible Флаг отвечающий за отображение легенды (выбрана или нет) от этого меняется цвет
 * @param color Цвет точки
 * @param name Название легенды
 */
const Legend: FC<ILegendProps> = ({ color, name, visible }) => {
    return (
        <Wrapper visible={visible}>
            <ChartDot newColor={color} />
            <Text>{name}</Text>
        </Wrapper>
    );
};

export default Legend;
