import styled from 'styled-components/macro';

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    margin: var(--space-xxs) 0;
    height: 44px;
    /* padding: var(--space-xxxs) 0; */
    border-bottom: 1px solid var(--color-contrast-low);
    max-width: 100%;
    display: flex;
    /* grid-template-columns: auto 500px; */
    justify-content: space-between;

    .ms-Pivot {
        /* display: flex; */
        text-align: right;
        max-width: 500px;
    }
`;
