import {
    TServerResponse,
    handleServerResponse,
} from '../../../../../../../../../../../../tools/API/handleServerResponse';
import { storeRawMetricsData, storeRawMetricsDataByTimeRange } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { IUseGetMetricsArgs } from '../tools/interfaces';
import { ResponseStatus } from '../../../../../../../../../../../../tools/API/constants';
import { cabinetPreferencesValues } from '../../../../../../../../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../../../../../../../General.reducer';
import generateQueryData from '../tools/generateQueryData';
import responseAnalyzer from '../../../../../../../../../../../../tools/API/responseAnalyzer';
import { useEffect } from 'react';
import { useRequestMetrics } from '../../../../../../../../../../../../tools/API/useRequestMetrics';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

/**
 * Кастомный хук для получения метрик
 * @param extendedReportingObjectsById Объект расширенных отчетных объектов, где ключ ID объетка
 * @param reportingObjectId ID отчетного объекта, для которого нужно посмотреть детализацию
 * @param chartDetailing Детализация графиков (МЕСЯЦ: MS или ДЕНЬ: D)
 * @param selectedMetric Выбранная метркиа
 * @param timeRanges Период, за который нужна детализация
 * @returns Настройки для диграммы и флаг загрузки
 */
const useGetMetrics = (args: IUseGetMetricsArgs) => {
    const fetchData = useRequestMetrics();

    const { extendedReportingObjectsById, reportingObjectId, chartDetailing, selectedMetric, timeRanges } = args;
    const { token } = useSelector(generalReducerValues);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Получение метрик и сохранение их в локальный стейт rowMetricsByTimeRange */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        /** При детализации в месяц не нужно запрашивать данные, так как они уже есть */
        if (chartDetailing === 'MS' && extendedReportingObjectsById[reportingObjectId]?.rawMetricsByDate) {
            const { rawMetricsByDate } = extendedReportingObjectsById[reportingObjectId];

            if (Array.isArray(rawMetricsByDate)) {
                const result = timeRanges.reduce((acc, timeRange) => {
                    const currentElement = rawMetricsByDate?.find((element) => {
                        const [date] = Object.keys(element);
                        return DateTime.fromISO(date).year === DateTime.fromISO(timeRange[0]).year;
                    });
                    if (currentElement) {
                        const [currentKey] = Object.keys(currentElement);
                        acc[`${timeRange[0]}:${timeRange[1]}`] = currentElement[currentKey];
                    }
                    return acc;
                }, {});
                dispatch(storeRawMetricsDataByTimeRange(result));
            }
        } else {
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            const queryData = generateQueryData({
                reportingObjectId,
                selectedMetric,
                chartDetailing,
                metricLevel,
                timeRanges,
                token,
            }).map((element) => ({ ...element, signal }));

            fetchData(queryData)
                .then((res: TServerResponse) => {
                    handleServerResponse({
                        responseAnalyzer: responseAnalyzer,
                        success: storeRawMetricsData,
                        error: storeRawMetricsData,
                        dispatch,
                        res,
                    });
                })
                .catch((error: any) => {
                    console.log(`Year over year period modal error: ${error}`);
                });
        }
        return () => {
            controller.abort();
        };
    }, [chartDetailing, timeRanges, token]);
};

export default useGetMetrics;
