import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { TRequestMetricsArgs } from '../../../../../../../../lib/esm/components';
import { storeAllTenantsData, Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { usePrepareData } from './usePrepareData';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { filterValidDateRanges } from '../../../../../../../../tools/filterValidDateRanges';
import { ITenant2PlaceRow, ITenant2ZoneRow } from '../../../../../../../../General.interfaces';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    usePrepareData();
    const {
        token,
        structures,
        selectedLocationId,
        src: { dataObj2ProjectCategory, projectCategories },
        cfg: { reportingObjectsByType, reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const { selectedTab, dataRefetchObject } = useSelector(Leasing_TenantOverview_Reference_Widget_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);

    useEffect(() => {
        if (!localCurrentOptions?.['tenants']?.length || !localCurrentOptions.selectedMetrics || !token) return;

        dispatch(storeAllTenantsData({ status: 'Loading', message: t('Loading...') }));

        const controller = new AbortController();
        const signal = controller.signal;
        const mainPeriod = localCurrentOptions.mainPeriod?.id;

        const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];

        const comparePeriodId = localCurrentOptions?.['selectedComparePeriod']?.[0]?.id;
        const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter((item) => item.id === comparePeriodId);
        const compareTimeRange = [compareDateRange?.period.dateFrom, compareDateRange?.period.dateTo];

        const obj_ids = reportingObjectsByType?.['tenant'].map((item) => item.id);

        const requests: TRequestMetricsArgs = localCurrentOptions.selectedMetrics?.reduce((acc, metric) => {
            const result = [
                {
                    signal,
                    token,
                    alias: `${metric}:main`,
                    metric,
                    metric_level: metricLevel,
                    obj_ids,
                    object_aggregation: false,
                    time_range: mainTimeRange,
                    time_freq: null,
                },
                {
                    signal,
                    token,
                    alias: `${metric}:comparison`,
                    metric,
                    metric_level: metricLevel,
                    obj_ids,
                    object_aggregation: false,
                    time_range: compareTimeRange,
                    time_freq: null,
                },
            ];
            return [...acc, ...result];
        }, []);

        fetchData(requests).then((res: TServerResponse) => {
            handleServerResponse({
                responseAnalyzer,
                success: storeAllTenantsData,
                error: storeAllTenantsData,
                dispatch,
                res,
            });
        });

        return () => {
            controller.abort();
        };
    }, [
        dispatch,
        localCurrentOptions?.['selectedComparePeriod'],
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.mainPeriod,
        reportingObjectsByType,
        token,
        dataRefetchObject,
    ]);
};
