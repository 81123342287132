import React from 'react';
import cn from 'classnames';

import { ISideBarFormProps } from '../../configurations';
import * as constants from '../../constants/constants';
import '../../styles/styles.scss';

const SideBarForm: React.FC<ISideBarFormProps> = ({ compact, ...props }) => {
    const { DEFAULT_CLASS_SIDEBAR, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;

    const className = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SIDEBAR]: true,
            [`${DEFAULT_CLASS_SIDEBAR}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}left`]: true,
            [`${DEFAULT_CLASS_SIDEBAR}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: compact,
        });
    }, [props]);

    return <div className={className}>{props.children}</div>;
};

export default SideBarForm;
