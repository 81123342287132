import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';
import {
    TMetricResponse,
    IReportingObject,
    IMetricResponseItem,
    IResponseItem,
    ISheetCell,
} from '../../../../../../../../General.interfaces';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';

export const generateGrid = (
    response: TMetricResponse[],
    reportingObjects: IReportingObject[],
    allMetrics: {
        id: string;
        text: string;
    }[],
    t: TFunction,
    selectedAddCols: string[] = [],
) => {
    const timeFreq = response[0][0].context.time_freq;

    //Собираем предварительную структуру
    const dataArray: Array<any>[] = [];

    response.forEach((resp: TMetricResponse) => {
        const metricId = resp[0].context.metric;
        resp.forEach((item: IMetricResponseItem) => {
            const timeZone = item.context.data_objects[0].timezone;
            const timeRange = item.context.time_range;
            const isMainPeriod = item.context.alias === 'main';
            const isMainMetric = isMainPeriod;
            const objId = item.context.data_objects[0].id;
            const objName = reportingObjects?.find((item) => item.id === objId)?.name || '';
            item.items.forEach((data: IResponseItem, timeIndex) => {
                const row: any[] = [{ key: t('Object'), value: objName }];

                if (selectedAddCols?.includes('addId')) {
                    row.unshift({ key: 'Id', value: String(objId) });
                }

                let innerId = ``;
                if (timeFreq === null) {
                    const value = `${timeRange[0]} – ${timeRange[1]}`;
                    row.push({ key: t('Period'), value, isMainPeriod, timeIndex });
                    innerId = `${objId}:${value}`;
                } else {
                    const value =
                        timeFreq !== 'H'
                            ? DateTime.fromISO(data.time).toISODate()
                            : DateTime.fromISO(data.time).toFormat('yyyy-MM-dd HH:mm');
                    const key = timeFreq !== 'H' ? t('Date') : t('DateTime');
                    const weekDay = DateTime.fromISO(data.time).weekdayShort;
                    row.push({ key, value, isMainPeriod, timeIndex, weekDay });
                    innerId = `${objId}:${value}`;
                }

                if (selectedAddCols?.includes('addTimeZone')) {
                    row.push({ key: t('Time zone'), value: timeZone });
                }

                row.push({ metric: metricId, value: numberWithSpaces(data.value), isMainMetric });
                row.push({ key: 'innerId', value: innerId });
                dataArray.push(row);
            });
        });
    });

    //Собираем все метрики основного периода
    const mainRows: { [x: string]: Array<any> } = {};

    dataArray.forEach((item) => {
        if (item.some((cell) => cell.isMainPeriod)) {
            const id = item?.find((cell) => cell.key === 'innerId')?.value || ('Y' as string);
            if (mainRows.hasOwnProperty(id)) {
                const metricCell = item?.find((cell) => cell.metric);
                console.log(metricCell);

                if (metricCell) {
                    mainRows[id] = [...mainRows[id], metricCell];
                }
            } else {
                mainRows[id] = item;
                // mainRows[id] = item?.filter((cell) => cell.key !== 'innerId');
            }
        }
    });

    //Добавляем все метрики периода сравнения
    const grid = Object.values(mainRows)
        .map((value) => value)
        .map((item) => {
            const mainId = item?.find((cell) => cell.key === 'innerId').value.split(':')[0];
            const mainTimeIndex = item?.find((cell) => cell.timeIndex)?.timeIndex;
            const row = [...item?.filter((cell) => cell.key !== 'innerId')];
            dataArray.forEach((r) => {
                const id = r?.find((cell) => cell.key === 'innerId').value.split(':')[0];
                const timeIndex = r?.find((cell) => cell.timeIndex)?.timeIndex;
                const isMain = r.some((cell) => cell.isMainPeriod);
                if (mainId === id && !isMain && mainTimeIndex === timeIndex) {
                    const compareDate = r?.find((cell) => cell.timeIndex !== undefined);
                    if (compareDate && !row.some((item) => item.value === compareDate.value)) {
                        row.push({ ...compareDate, key: t('Compare date') });
                    }
                    const metricCell = r?.find((cell) => cell.metric);
                    if (metricCell) {
                        row.push(metricCell);
                    }
                }
            });
            return row;
        })
        //Добавляем ячейки с дельтой и процентами, если надо
        .map((row) => {
            const newRow: ISheetCell[] = [];
            row.forEach((cell) => {
                if (cell.isMainMetric === false) {
                    const mainValue = Number(
                        row
                            ?.find((item) => item.metric === cell.metric && item.isMainMetric)
                            ?.value?.replaceAll(' ', ''),
                    );
                    const compareValue = Number(cell.value.replaceAll(' ', ''));

                    const metricName = allMetrics?.find((m) => m.id === cell.metric)?.text || '';
                    newRow.push(cell);
                    if (selectedAddCols?.includes('addDelta')) {
                        const delta = Number(mainValue) - Number(compareValue);
                        newRow.push({ value: numberWithSpaces(delta), key: `Δ ${metricName}` });
                    }
                    if (selectedAddCols?.includes('addPercent')) {
                        const percent = numberWithSpaces(
                            (((Number(mainValue) - Number(compareValue)) * 100) / Number(compareValue))?.toFixed(2),
                        );
                        newRow.push({ value: `${percent} %`, key: `% ${metricName}` });
                    }
                } else if (cell.weekDay) {
                    newRow.push(cell);
                    if (selectedAddCols?.includes('addWeekDay')) {
                        if (cell.isMainPeriod) {
                            newRow.push({ key: t('Week day'), value: t(cell.weekDay) });
                        } else {
                            newRow.push({ key: t('Compare week day'), value: t(cell.weekDay) });
                        }
                    }
                } else {
                    newRow.push(cell);
                }
            });
            return newRow;
        });

    try {
        //Добавляем первую строку таблицы
        const firstRow = grid[0].map((item) => {
            const metricName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
            return { value: item.key || t(metricName) || '', readOnly: true };
        });
        grid.unshift(firstRow);
        return grid;
    } catch (error) {
        return [];
    }
};
