export const numberWithSpaces = (x: any) => {
    if (x === undefined || x === null || isNaN(Number(x)) || x === Infinity) return '–';
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts[1] === '00') {
        return parts[0];
    } else {
        return parts.join('.');
    }
};
