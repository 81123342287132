import React, { useEffect, useState } from 'react';

import { IProps, ISelectSubsectionArgs, TLang, TMenu } from './configurations';
import './styles/styles.scss';
import SideBarForm from './components/SideBarForm';
import SideBarHeader from './components/SideBarHeader';
import SideBarMenu from './components/SideBarMenu';
import useUrl from './core/useUrl';
import { cloneDeep } from 'lodash';
import { requestFrontExchange } from '../../../lib/esm/components';
import generateMenu from './core/generateMenu';
import { useTranslation } from 'react-i18next';
import { generalReducerValues } from '../../../General.reducer';
import { useSelector } from 'react-redux';

const SideBar: React.FC<IProps> = ({
    initialOptionsDidObtain,
    tokenDidObtain,
    menuDidObtain,
    modulesConfig,
    sideBarOptions: { lang, selectedSubsection },
    ...props
}) => {
    const [menu, setMenu] = useState<TMenu>([]);
    const [compact, setCompact] = useState(false);
    const [language, setLanguage] = useState<TLang>(lang || 'ru');
    const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
    const { t } = useTranslation('sidebarTranslation');
    const { user } = useSelector(generalReducerValues);

    const initialParams = useUrl();
    // console.log('>>> initialParams', initialParams);

    useEffect(() => {
        if (user?.permissions) {
            setMenu(generateMenu({ modulesConfig, permissions: user.permissions, t }));
        }
    }, [t, modulesConfig, user]);

    useEffect(() => {
        menuDidObtain && menuDidObtain(menu);
    }, [menu]);

    useEffect(() => {
        if (!initialParams?.FEparams.state.sidebar_state) return;
        const { sidebar_compact, lang, collapsed_sections, selected_subsection } =
            initialParams?.FEparams.state.sidebar_state;
        setCompact(() => sidebar_compact);
        setLanguage(() => lang);
        setCollapsedSections(() => collapsed_sections);
        initialOptionsDidObtain &&
            initialOptionsDidObtain({
                lang: lang,
                selectedSubsection: selected_subsection,
            });
    }, [initialParams?.FEparams.state.sidebar_state]);

    useEffect(() => {
        if (initialParams?.urlParams?.token) {
            tokenDidObtain && tokenDidObtain(initialParams.urlParams.token);
        }
    }, [initialParams?.urlParams]);

    useEffect(() => {
        language !== lang && setLanguage(() => lang);
    }, [lang]);

    const onStubClick = () => {
        setCompact(() => !compact);
    };

    const changeCollapsedSections = (collapsedSections: string[]): void => {
        setCollapsedSections(collapsedSections);
    };

    const subsectionDidSelected = (args: ISelectSubsectionArgs) => {
        // console.log('>>', args);
        if (args.serviceName === props.currentServiceName) {
            // Если мы хотим перейти внутри своего сервиса, отправляем событие наружу
            props.subsectionDidSelected &&
                props.subsectionDidSelected({ servicePath: args.servicePath, subsectionId: args.subsectionId });
        } else {
            // Если мы усли мы хотим перйти в другой сервис, записываем в YDB текущее состояние...
            requestFrontExchange({
                mode: 'save',
                data: {
                    data: {
                        sidebar_state: {
                            lang,
                            sidebar_compact: compact,
                            collapsed_sections: collapsedSections,
                            selected_subsection: args.subsectionId,
                        },
                    },
                    schema_url:
                        'https://schema-service.focustech.xyz/example/v1/cloud-state-test-1.json?token=cloud_state',
                },
            })?.then((r: any) => {
                // ...в случае успешной записи, получаем хэш записи в ответе и редиректимся с ним на другой сервис
                const response = cloneDeep(r) || ({} as { cloud_temp_hash: string });
                const redirectUrl = response?.cloud_temp_hash
                    ? `${args.serviceHost}${args.servicePath}?cloud_temp_hash=${response.cloud_temp_hash as string}`
                    : '';
                console.log('redirectUrl +++++', redirectUrl);
            });
        }
    };

    return initialParams?.FEparams?.state ? (
        <SideBarForm compact={compact}>
            <SideBarHeader onStubClick={onStubClick} compact={compact}></SideBarHeader>
            <SideBarMenu
                {...props}
                collapsedSections={collapsedSections || []}
                changeCollapsedSections={changeCollapsedSections}
                subsectionDidSelected={subsectionDidSelected}
                compact={compact}
                selectedSubsection={selectedSubsection}
                lang={language}
                menu={menu}
            ></SideBarMenu>
        </SideBarForm>
    ) : null;
};

export default SideBar;
