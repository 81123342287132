import { TooltipHost } from '@fluentui/react';
import EventTooltipContent from './components/EventTooltipContent/EventTooltipContent';
import { EventBody, EventName, EventWrapper } from './styles';
import { DateTime } from 'luxon';
import { IExtendedEvent } from '../../../../../../interfaces';
import { FC, MouseEvent, useContext, useRef, useState } from 'react';
import ContextualMenu from '../../../../../../../../../../../ContextualMenu/ContexttualMenu';
import EventContextualMenuBody from './components/EventContextualMenuBody/EventContextualMenuBody';
import { EventsMapWidgetContext } from '../../../../../../context';

interface IProps {
    /** Текущий день в календаре */
    day: { data: DateTime | null };
    /** Событие за этот день */
    event: IExtendedEvent;
    /** Режим работы календаря */
    yearView: boolean;
}

/**
 * Компонент для отображения события в календаре
 */
const Event: FC<IProps> = ({ day, event, yearView }) => {
    const [showEventContextualMenu, setShowEventContextualMenu] = useState(false);

    const context = useContext(EventsMapWidgetContext);
    const ref = useRef(null);

    const isStart =
        day.data?.day === DateTime.fromISO(event.date_from).day &&
        day.data?.month === DateTime.fromISO(event.date_from).month;
    const isEnd =
        day.data?.day === DateTime.fromISO(event.date_to).day &&
        day.data?.month === DateTime.fromISO(event.date_to).month;

    const toggleShowEventContextualMenu = (e: MouseEvent) => {
        e.preventDefault();
        setShowEventContextualMenu((prevState) => !prevState);
    };

    const onEventClick = () => {
        setShowEventContextualMenu(false);
        if (context) {
            context.storeSelectedEventIdTrans(event.id);
        }
    };

    const onEditClick = (id: number) => {
        setShowEventContextualMenu(false);
        if (context) {
            context.storeSelectedEventIdToEditTrans(id);
        }
    };

    const closeEventContextualMenu = () => {
        setShowEventContextualMenu(false);
    };

    return (
        <EventWrapper
            onContextMenu={toggleShowEventContextualMenu}
            color={event.event_type_color}
            onClick={onEventClick}
            index={event.index}
            key={event.id}
            yearView={yearView}
            name={event.name}
            isStart={isStart}
            isEnd={isEnd}
        >
            <TooltipHost
                calloutProps={{ hidden: showEventContextualMenu }}
                content={<EventTooltipContent event={event} />}
            >
                <EventBody ref={ref}>{isStart && !yearView && <EventName>{event.name}</EventName>}</EventBody>
            </TooltipHost>
            <ContextualMenu
                CustomMenuComponent={<EventContextualMenuBody onEditClick={onEditClick} event={event} />}
                isContextualMenuOpen={showEventContextualMenu}
                hideContextualMenu={closeEventContextualMenu}
                gapSpace={yearView ? 2 : 10}
                eventBubblingEnabled={false}
                directionalHint={0}
                targetRef={ref}
            />
        </EventWrapper>
    );
};

export default Event;
