import { cloneDeep, xorBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IPeriod } from '../../../../../../../../../General.interfaces';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Select } from '../../../../../../../../../lib/esm/components';
import { IObject, TSelectedOptions } from '../../../../../../../../../lib/esm/components/Select/configurations';
import { shortStringDate } from '../../../../../../../../../tools/Strings/shortStringDate';
import { userSettingsReducerValues } from '../../../../../../../UserSettings/reducer';
import { Performance_Dashboard_ByArea_Widget_Reducer_Values, storeComparePeriod, storeColorMetric, storeAreaMetric } from '../../reducer';
import { MainPeriodText, SelectsWrapper } from './styles';

const restrictedMetrics = ['sum_area'];

const Selects: React.FC = () => {
    const { t } = useTranslation();
    const {
        currentOptions: { comparePeriods, compareDateRanges, selectedMetrics, mainDateRanges, mainPeriod },
    } = useSelector(userSettingsReducerValues);
    const [compareOptions, setCompareOptions] = useState<IObject[]>([]);
    const dispatch = useDispatch();
    const { allMetrics, lang } = useSelector(generalReducerValues);
    const { comparePeriod, colorMetric, areaMetric } = useSelector(Performance_Dashboard_ByArea_Widget_Reducer_Values);

    useEffect(() => {
        const compareOptions: IObject[] = compareDateRanges
            ? (comparePeriods?.map((p) => {
                  const [range] = compareDateRanges?.filter((r) => r.id === p.id);
                  const text = range?.period ? `${range.period.dateFrom} – ${range.period.dateTo}` : '';
                  return { id: p.id, text };
              }) as IObject[])
            : [];
            
        setCompareOptions(compareOptions);
    }, [compareDateRanges, comparePeriods]);

    const areaMetricOptions: IObject[] = selectedMetrics
        ? cloneDeep(allMetrics)
              ?.filter((item) => selectedMetrics.includes(item.id) || item.id === 'sum_area')
              .map((item) => ({ ...item, text: t(item.text) }))
        : [];

    const colorMetricOptions: IObject[] = selectedMetrics
        ? cloneDeep(allMetrics)
              ?.filter(
                  (item) => selectedMetrics.includes(item.id) && item.id !== 'sum_area',
                  //   || item.id === 'fpc_sum_pass_count_in_wh'
              )
              .map((item) => ({ ...item, text: t(item.text) }))
        : [];

    const handPeriodleSelect = (args: IPeriod[]) => {
        dispatch(storeComparePeriod(args[0]));
    };

    const handleAreaMetricleSelect = (args: TSelectedOptions) => {
        dispatch(storeAreaMetric(String(args[0].id)));
    };

    const handleColorMetricleSelect = (args: TSelectedOptions) => {
        dispatch(storeColorMetric(String(args[0].id)));
    };

    const outsideSelectedColorMetric = cloneDeep(allMetrics)
        ?.filter((item) => colorMetric === item.id)
        .map((item) => ({ ...item, text: t(item.text) }));
    const outsideSelectedAreaMetric = cloneDeep(allMetrics)
        ?.filter((item) => areaMetric === item.id)
        .map((item) => ({ ...item, text: t(item.text) }));
    const mainDates = mainDateRanges?.filter((item) => item.id === mainPeriod?.id)[0]?.period;
    // const mainPeriodText = mainDates?.dateFrom ? `${mainDates?.dateFrom} – ${mainDates?.dateTo}` : '';
    const mainPeriodText = mainDates?.dateFrom ? shortStringDate([mainDates?.dateFrom, mainDates?.dateTo], lang) : '';

    const compareSelect = useMemo(() => {
        const outsideSelectedCompPeriod = compareDateRanges?.filter((item) => comparePeriod.id === item.id);
        return (
            <Select
                iconType="chevron-down"
                options={compareOptions}
                labelText={t('Select comparison period')}
                showFilter={false}
                mode="single"
                handleSelect={handPeriodleSelect}
                outsideSelected={outsideSelectedCompPeriod}
            />
        );
    }, [compareDateRanges, compareOptions, comparePeriod.id, handPeriodleSelect, t]);

    return (
        <SelectsWrapper horizontal tokens={{ childrenGap: 20, padding: 10 }}>
            <MainPeriodText>{mainPeriodText}</MainPeriodText>
            {compareSelect}
            <Select
                iconType="chevron-down"
                options={areaMetricOptions}
                labelText={t('Select area metrics')}
                showFilter={false}
                mode="single"
                handleSelect={handleAreaMetricleSelect}
                outsideSelected={outsideSelectedAreaMetric}
            />
            <Select
                iconType="chevron-down"
                options={colorMetricOptions}
                labelText={t('Select color metrics')}
                showFilter={false}
                mode="single"
                handleSelect={handleColorMetricleSelect}
                outsideSelected={outsideSelectedColorMetric}
            />
        </SelectsWrapper>
    );
};

export default Selects;
