import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IPeriod } from '../../../../General.interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { Modals, Select } from '../../../../lib/esm/components';
import DateRangePicker from '../../../../lib/esm/components/DateRangePicker';
import { IPeriodObj } from '../../../../lib/esm/General.interfaces';
import { stringDate } from '../../../../tools/Strings/stringDate';
import { useWidgetCurrentOptions } from '../../../../tools/useWidgetCurrentOptions';
import { userSettingsReducerValues, storeComparePeriods } from '../../UserSettings/reducer';
import { IProps } from './interfaces';
import { Wrapper } from './styles';

const ComparePeriodSelect: React.FC<IProps> = ({
    mode = 'multi',
    validOptions = [],
    oneDayOnly = false,
    defaultPeriod,
    yearsOnly = false,
    maxSelected = 3,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [modalStatus, setModalStatus] = useState({ show: false });
    const [outsideCloseDropdown, setOutsideCloseDropdown] = useState({ close: false });
    const {
        currentOptions: { selectedLocationId, compareDateRanges },
    } = useSelector(userSettingsReducerValues);
    const { lang, currentModule } = useSelector(generalReducerValues);
    const local = useWidgetCurrentOptions(currentModule);
    const comparePeriods = local?.['comparePeriods'];
    const localCurrentModule = local?.['currentModule'];

    useEffect(() => {
        if (!comparePeriods?.length && selectedLocationId && localCurrentModule === currentModule) {
            if (!defaultPeriod) {
                dispatch(
                    storeComparePeriods({
                        module: currentModule,
                        locationId: selectedLocationId,
                        comparePeriods: [{ id: 'previous' }],
                    }),
                );
            } else {
                dispatch(
                    storeComparePeriods({
                        module: currentModule,
                        locationId: selectedLocationId,
                        comparePeriods: [{ id: defaultPeriod }],
                    }),
                );
            }
        }
    }, [comparePeriods?.length, currentModule, defaultPeriod, dispatch, localCurrentModule, selectedLocationId]);

    const outsideSelected = compareDateRanges?.filter((item) => comparePeriods?.find((p) => p.id === item.id));

    const options =
        validOptions.length === 0
            ? compareDateRanges
            : compareDateRanges?.filter((range) => validOptions.includes(range.id));

    const storePeriods = (
        comparePeriods: {
            id: string | number | null;
            period?: IPeriodObj;
        }[],
    ) => {
        selectedLocationId &&
            dispatch(
                storeComparePeriods({
                    module: currentModule,
                    locationId: selectedLocationId,
                    comparePeriods: comparePeriods as IPeriod[],
                }),
            );
    };

    const handleSelect = (args: { id: string | null | number; period?: IPeriodObj }[]) => {
        if (mode === 'multi') {
            const comparePeriods = args.map((item) => {
                if (item.id === 'selectCustom') {
                    return { id: item.id, period: item.period };
                } else {
                    return { id: item.id };
                }
            });
            props.handleSelect && props.handleSelect(comparePeriods);
            storePeriods(comparePeriods);
            setModalStatus({ show: false });
        } else if (mode === 'single') {
            if (args[0].id === 'selectCustom') {
                setModalStatus({ show: true });
            } else {
                props.handleSelect && props.handleSelect([{ id: args[0].id }]);
                storePeriods([{ id: args[0].id }]);
                setModalStatus({ show: false });
            }
        }
    };

    const closeModal = () => {
        const isCustomPeriodSelected = comparePeriods?.some((item) => item.id === 'selectCustom');
        if (!isCustomPeriodSelected) {
            comparePeriods &&
                props.handleSelect &&
                props.handleSelect([...comparePeriods] as {
                    id: string | number | null;
                    period?: IPeriodObj;
                }[]);
            comparePeriods &&
                storePeriods(
                    comparePeriods as {
                        id: string | number | null;
                        period?: IPeriodObj;
                    }[],
                );
        }
        setModalStatus({ show: false });
    };

    const handleCheck = (args: { id: string | null | number; period?: string[] } & { checked: boolean }) => {
        if (args.id === 'selectCustom' && args.checked) {
            setModalStatus({ show: true });
        } else if (args.id === 'selectCustom' && !args.checked) {
            const newComparePeriods = comparePeriods?.filter((item) => item.id !== 'selectCustom');
            // dispatch(storeComparePeriods(newComparePeriods));
        }
    };

    const handleDateRangeChange = (period: { dateFrom: string; dateTo: string }) => {
        period &&
            comparePeriods &&
            props.handleSelect &&
            props.handleSelect([
                ...comparePeriods?.filter((item) => item.id !== 'selectCustom'),
                { id: 'selectCustom', period },
            ] as {
                id: string | number | null;
                period?: IPeriodObj;
            }[]);
        comparePeriods &&
            mode === 'multi' &&
            storePeriods([
                ...comparePeriods?.filter((item) => item.id !== 'selectCustom'),
                { id: 'selectCustom', period },
            ] as {
                id: string | number | null;
                period?: IPeriodObj;
            }[]);
        comparePeriods &&
            mode === 'single' &&
            storePeriods([{ id: 'selectCustom', period }] as {
                id: string | number | null;
                period?: IPeriodObj;
            }[]);
        setModalStatus({ show: false });
        setOutsideCloseDropdown({ close: true });
    };

    const getOutsideText = (period: IPeriod) => {
        if (!period) return '';
        const dates = compareDateRanges?.find((item) => item.id === period.id)?.period;
        if (dates?.dateFrom === dates?.dateTo) {
            return stringDate(dates?.dateFrom, lang);
        } else {
            return `${stringDate(dates?.dateFrom, lang)} – ${stringDate(dates?.dateTo, lang)}`;
        }
    };

    return (
        <Wrapper>
            <Modals title="" modalStatus={modalStatus} closeModal={closeModal}>
                <DateRangePicker
                    handleDateRangeChange={handleDateRangeChange}
                    oneDayOnly={oneDayOnly}
                    yearsOnly={yearsOnly}
                    lang={lang}
                />
            </Modals>
            <Select
                // disabled={locations.length === 0}
                options={options}
                dropdownWidth="lg"
                text={t('Selected')}
                dropdownAlign="left"
                showFilter={false}
                mode={mode}
                labelText={t('Comparison period')}
                iconType="chevron-down"
                handleSelect={handleSelect}
                handleCheck={handleCheck}
                outsideCloseDropdown={outsideCloseDropdown}
                outsideText={getOutsideText(outsideSelected?.[0])}
                outsideSelected={outsideSelected}
                minSelected={1}
                maxSelected={maxSelected}
            />
        </Wrapper>
    );
};

export default ComparePeriodSelect;
