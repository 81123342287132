import { Stack } from '@fluentui/react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { stringDate } from '../../../../../../../../../tools/Strings/stringDate';
import { IEvent } from '../../../../../EventsMap/widgets/EventsMapWidget/interfaces';
import { EventName, Wrapper } from './styles';

interface IProps {
    /** функция для записи id выбранного события */
    storeSelectedEventIdTrans: (id: number) => void;
    /** само событие */
    event: IEvent;
}

/**
 * Компонент для отображения карточки события
 */
const EventCard: FC<IProps> = ({ event, storeSelectedEventIdTrans }) => {
    const { lang } = useSelector(generalReducerValues);

    const onCardClick = () => {
        storeSelectedEventIdTrans(event.id);
    };

    return (
        <Wrapper color={event.event_type_color} onClick={onCardClick}>
            <Stack verticalAlign="center" horizontal={true} tokens={{ childrenGap: 10 }}>
                <EventName>{event.name}</EventName>
            </Stack>

            <div>{stringDate({ dateFrom: event.date_from, dateTo: event.date_to }, lang, '', 'dd MMM yyyy')}</div>
        </Wrapper>
    );
};

export default EventCard;
