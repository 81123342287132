import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import { Button, Checkbox, Collapse, Search } from '../../../../../lib/esm/components';

import { reportingObjectsTypesMap } from './constants/constants';
import {
    ButtonWrapper,
    CheckboxWrapper,
    ListWrapper,
    ReportingObjectsListWrapper,
    SearchWrapper,
    Wrapper,
} from './styles';

interface IProps {
    /** Обработчик выбора отчетного объекта */
    onReportingObjectsSelect: (ids: number[]) => void;
    /** Все выбраные id отчетных объектов */
    selectedReportingObjectsIdsTrans: number[];
}

/**
 * Тело контекстного меню со всеми типами отчетных объектов
 */
const ReportingObjectsMenuBody: FC<IProps> = ({ onReportingObjectsSelect, selectedReportingObjectsIdsTrans }) => {
    const INVALID_REPORTING_OBJECTS_TYPES: string[] = [];
    const [selectedReportingObjectsIds, setSelectedReportingObjectsIds] = useState<number[]>(
        selectedReportingObjectsIdsTrans,
    );
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const [filter, setFilter] = useState('');
    const { t } = useTranslation();

    const filterOnChange = ({ value }: { value: string }) => {
        setFilter(value);
    };

    const onCheck = (id: number) => (args: { checked: boolean }) => {
        if (args.checked) {
            setSelectedReportingObjectsIds((prevState) => [...prevState, id]);
        } else {
            setSelectedReportingObjectsIds((prevState) => prevState?.filter((element) => element !== id));
        }
    };

    const onSaveButtonClick = () => {
        onReportingObjectsSelect(selectedReportingObjectsIds);
    };

    const list = useMemo(() => {
        if (Object.keys(reportingObjectsByType).length) {
            return Object.keys(reportingObjectsByType)
                ?.filter((element) => !INVALID_REPORTING_OBJECTS_TYPES.includes(element))
                .map((reportingObjectType) => {
                    let collapseSelectedNumberLabel = 0;
                    const reportingObjectsList = reportingObjectsByType[reportingObjectType]
                        ?.filter((reportingObject) => reportingObject.name.toLowerCase().includes(filter.toLowerCase()))
                        .map((reportingObject) => {
                            const selected = selectedReportingObjectsIds.includes(reportingObject.id);
                            if (selected) collapseSelectedNumberLabel++;
                            return (
                                <CheckboxWrapper key={reportingObject.id}>
                                    <Checkbox
                                        handleToggle={onCheck(reportingObject.id)}
                                        label={reportingObject.name}
                                        selected={selected}
                                    />
                                </CheckboxWrapper>
                            );
                        });
                    return (
                        <Collapse
                            label={`${
                                reportingObjectsTypesMap[reportingObjectType]
                                    ? t(reportingObjectsTypesMap[reportingObjectType])
                                    : reportingObjectType
                            } (${collapseSelectedNumberLabel}/${reportingObjectsList.length})`}
                            addClasses={'SelectCollapse'}
                            key={reportingObjectType}
                            hover="background-none"
                            icon="chevron-right"
                            iconPosition="right"
                        >
                            <ReportingObjectsListWrapper>{reportingObjectsList}</ReportingObjectsListWrapper>
                        </Collapse>
                    );
                });
        }
        return null;
    }, [filter, reportingObjectsByType, selectedReportingObjectsIds, t]);

    return (
        <Wrapper>
            <SearchWrapper>
                <Search fullWidth={true} placeholder={t('Filter')} handleChange={filterOnChange} />
            </SearchWrapper>
            <ListWrapper>{list}</ListWrapper>
            <ButtonWrapper>
                <Button size="sm" onClick={onSaveButtonClick}>
                    {t('Save')}
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default ReportingObjectsMenuBody;
