export const currentOptionNameByKey = {
    selectedReportingObjectsIds: 'Reporting objects',
    comparePeriods: 'Comparison periods',
    mainPeriod: 'Main period',
    selectedMetrics: 'Metrics',
    yoyTableSettings: 'Table settings',
    yoyYearsSettings: 'Years',
    pathToPurchaseGroupsOfZonesSettings: 'Group of zones',
    pathToPurchaseFunnelStepsSettings: 'Funnel steps',
    pathToPurchaseFunnelSettings: 'Funnel settings',
    mapsSettings: 'Settings',
    crossVisitsMapsSettings: 'Settings',
    mallTypeOptions: 'Mall type'
};

export const createNewOptionId = 'createNew';
export const deleteOptionId = 'delete';
