import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values, reloadWidget, storeSelectedTab } from './reducer';
import { WidgetWrapper, PivotWrapper } from './styles';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { PivotItem } from '@fluentui/react';
import Selects from './components/Selects/Selects';
import { TSelectedTab } from './interfaces';
import Table from './components/Table/Table';
import ComplexTable from './components/ComplexTable/ComplexTable';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const Reference: React.FC = ({}) => {
    const ref = useRef(null);

    const { preparedData, selectedTab, allTenantsData } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const rerender = useRerender(preparedData);

    const tabs = [
        {
            itemKey: 'refGroups',
            headerText: t('Reference groups'),
        },
        {
            itemKey: 'otherTenants',
            headerText: t('Other tenants'),
        },
    ];

    const handleLinkClick = (item?: PivotItem) => {
        item?.props?.itemKey && dispatch(storeSelectedTab(item.props.itemKey as TSelectedTab));
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Comparison with reference groups')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={'Comparison with reference groups'}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
                <PivotWrapper>
                    <Pivot
                        widgetName="ComparisonWithReferenceGroups"
                        selectedKey={selectedTab as unknown as string}
                        onLinkClick={handleLinkClick}
                    >
                        {tabs.map((element) => (
                            <PivotItem
                                key={element.itemKey + Math.random()}
                                headerText={t(element.headerText)}
                                itemKey={String(element.itemKey)}
                            />
                        ))}
                    </Pivot>
                </PivotWrapper>
            </WidgetTitleWrapper>
            <Selects />
            {selectedTab === 'otherTenants' && <Table />}
            {selectedTab === 'refGroups' && <ComplexTable />}
        </WidgetWrapper>
    );
};

export default Reference;
