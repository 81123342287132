import { IAccountParams } from '../cabinet/components/UserSettings/interfaces';

export const getSettings = (
    settings: IAccountParams,
    selectedLocationId: number | null,
    currentModule: string,
    selectName: string,
    storeMethod: string,
) => {
    if (!selectedLocationId) return null;
    if (storeMethod === 'byML') {
        return settings?.[`${storeMethod}:${currentModule}:${selectedLocationId}`]?.[selectName];
    } else if (storeMethod === 'byM') {
        return settings?.[`${storeMethod}:${currentModule}`]?.[selectName];
    } else if (storeMethod === 'byL') {
        return settings?.[`${storeMethod}:${selectedLocationId}`]?.[selectName];
    } else {
        return settings?.['global']?.[selectName];
    }
};
