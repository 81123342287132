import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
`;
