import { useState, useEffect } from 'react';
import { IStyles } from './styles/styles.interfaces';

import useColor from './color/useColor';
import useHover from './hover/useHover';
import useBorderRadius from './borderRadius/useBorderRadius';
import useShadow from './shadow/useShadow';
import useIndent from './indent/useIndent';
import useClasses from './classes/useClasses';

const useStyles = (args: IStyles): string[] => {
    const [mergedClasses, setMergedClasses] = useState<string[]>([]);
    const { props, ref, constants } = args;

    const colorClasses = useColor({ styles: { color: props?.color, bgColor: props?.bgColor }, constants });
    const hoverClasses = useHover({ ref, styles: { hover: props?.hover }, constants });
    const shadowClasses = useShadow({ ref, styles: { shadow: props?.shadow }, constants });
    const indentClasses = useIndent({ styles: { margin: props?.margin, padding: props?.padding }, constants });
    const addClassesClasses = useClasses({ styles: { addClasses: props?.addClasses }, constants });
    const borderRadiusClasses = useBorderRadius({
        styles: { borderRadius: props?.borderRadius, borderRadiusExclude: props?.borderRadiusExclude },
        constants,
    });

    useEffect(() => {
        setMergedClasses([
            ...colorClasses,
            ...hoverClasses,
            ...borderRadiusClasses,
            ...shadowClasses,
            ...indentClasses,
            ...addClassesClasses,
        ]);
    }, [borderRadiusClasses, colorClasses, hoverClasses, shadowClasses, indentClasses, addClassesClasses]);

    return mergedClasses;
};

export default useStyles;
