import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { ITableCellData } from '../../../interfaces';

/**
 * Функция для сортировки строк таблицы по дате
 * @param tableData данные таблицы
 */
const sortTableData = (tableData?: ITableCellData[][]) => {
    if (tableData) {
        return cloneDeep(tableData).sort((a, b) => {
            if (a[0].type !== 'header' && b[0].type !== 'header') {
                const aDateFromCellValue = a.find((cell) => cell.key === 'date_from')?.value;
                const aTimeFromCellvalue = a.find((cell) => cell.key === 'time_from')?.value;

                const bDateFromCellValue = b.find((cell) => cell.key === 'date_from')?.value;
                const bTimeFromCellvalue = b.find((cell) => cell.key === 'time_from')?.value;

                if (aDateFromCellValue && aTimeFromCellvalue && bDateFromCellValue && bTimeFromCellvalue) {
                    const aDate = DateTime.fromFormat(
                        `${aDateFromCellValue}T${aTimeFromCellvalue}`,
                        "yyyy-MM-dd'T'HH:mm",
                    ).toMillis();
                    const bDate = DateTime.fromFormat(
                        `${bDateFromCellValue}T${bTimeFromCellvalue}`,
                        "yyyy-MM-dd'T'HH:mm",
                    ).toMillis();
                    if (!aDate) return 1;
                    if (!bDate) return -1;
                    return aDate > bDate ? -1 : 1;
                }

                if (!aDateFromCellValue || !aTimeFromCellvalue) return 1;
                if (!bDateFromCellValue || !bTimeFromCellvalue) return -1;
            }

            return 1;
        });
    }
    return [];
};

export default sortTableData;
