import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { IRatingComponentProps } from '../../../../../components/Floors/interfaces';
import { getFixedLines } from '../../../../../../../../../tools/getFixedLines';
import { Color, Header, Li, Text, TextWrapper, Ul, Wrapper } from './styles';
import { DirectionalHint, TooltipHost } from '@fluentui/react';
import { FC, useMemo } from 'react';
import Loader from '../../../../../components/Loader/Loader';

/**
 * компонент для отображения рейтингов
 * @param colorsByMarker Объект с цветами объектов и дополнительными параметрами
 * @param selectedName выбранное имя
 * @param onMouseLeave функция, которая срабатвыает, когда мышь убирают с объекта
 * @param onMouseOver функция, которая срабатывает при наведении на объект
 * @param showLoader флаг, отвечающий за отображение спиннера
 * @returns
 */
const Ratings: FC<IRatingComponentProps> = ({
    onMouseLeave: onMouseLeaveTrans,
    onMouseOver: onMouseOverTrans,
    colorsByMarker,
    selectedName,
    showLoader,
}) => {
    const list = useMemo(() => {
        if (colorsByMarker) {
            return Object.values(colorsByMarker)
                .map((value) => value)
                .sort((a, b) => b.value - a.value)
                .map((element) => {
                    const text = `${getFixedLines(element.name, 25)}: ${numberWithSpaces(element.value.toFixed(2))}%`;
                    const contraText = `${numberWithSpaces(element['contraValue'].toFixed(2) || 0)}%`;
                    const content = `${element.name}: ${numberWithSpaces(element.value.toFixed(2))}%`;
                    return (
                        <Li
                            key={`${element.name}${element.id}`}
                            onMouseOver={() => onMouseOverTrans(element.marker)}
                            onMouseLeave={() => onMouseLeaveTrans(null)}
                        >
                            <TooltipHost content={content} directionalHint={DirectionalHint.leftCenter}>
                                <TextWrapper>
                                    <Text>{`${text} (${contraText})`}</Text>
                                    <Color
                                        width={`${element.normValue * 100 < 3 ? 3 : element.normValue * 100}%`}
                                        bgColor={element.heatColor}
                                    />
                                </TextWrapper>
                            </TooltipHost>
                        </Li>
                    );
                });
        }

        return null;
    }, [colorsByMarker]);

    return (
        <Wrapper>
            {selectedName && <Header>{selectedName}</Header>}
            <Ul>
                {list}
                {showLoader && Object.keys(colorsByMarker).length ? <Loader /> : null}
            </Ul>
        </Wrapper>
    );
};

export default Ratings;
