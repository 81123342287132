import { useEffect, useState } from 'react';

export const useRerender = (data: any) => {
    const [counter, setCounter] = useState(Math.round(Math.random() * 10000));

    useEffect(() => {
        setTimeout(() => {
            setCounter((prev) => prev + 1);
        }, 100);
    }, [data]);

    return counter;
};
