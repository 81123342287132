import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { ButtonsWrapper, HeaderWrapper, TextWrapper } from './styles.js';
import { shortMonthNames, monthNames, showText, yearsText, monthsText } from '../../const/index.js';
import { getResult } from '../../core/index.js';
import Button from '../../../Button/index.js';

var Header = function (_a) {
    var lang = _a.lang, modeDidChanged = _a.modeDidChanged, _b = _a.mode, mode = _b === void 0 ? 'months' : _b, firstSelectedDate = _a.firstSelectedDate, secondSelectedDate = _a.secondSelectedDate;
    var _c = useState(''), text = _c[0], setText = _c[1];
    useEffect(function () {
        if (firstSelectedDate && secondSelectedDate) {
            var res = getResult(firstSelectedDate, secondSelectedDate);
            if (mode === 'months') {
                var text_1 = "".concat(res.dateFrom.day, " ").concat(shortMonthNames[res.dateFrom.month][lang], " ").concat(res.dateFrom.year, "  \u2013  ").concat(res.dateTo.day, " ").concat(shortMonthNames[res.dateTo.month][lang], " ").concat(res.dateTo.year);
                setText(function () { return text_1; });
            }
            else {
                var text_2 = "".concat(monthNames[res.dateFrom.month][lang], " ").concat(res.dateFrom.year, "  \u2013  ").concat(monthNames[res.dateTo.month][lang], " ").concat(res.dateTo.year);
                setText(function () { return text_2; });
            }
        }
        else {
            setText(function () { return ''; });
        }
    }, [firstSelectedDate, secondSelectedDate]);
    useEffect(function () {
        setText(function () { return ''; });
    }, [mode]);
    var onClick = function (mode) { return function () {
        modeDidChanged(mode);
    }; };
    var buttons = (jsxs(ButtonsWrapper, { children: [jsx("span", { children: showText[lang] }, void 0), jsx(Button, { type: "primary", theme: mode === 'years' ? undefined : 'outline', text: yearsText[lang], size: "sm", onClick: onClick('years') }, void 0), jsx(Button, { type: "primary", theme: mode === 'months' ? undefined : 'outline', text: monthsText[lang], size: "sm", onClick: onClick('months') }, void 0)] }, void 0));
    return (jsxs(HeaderWrapper, { children: [buttons, jsx(TextWrapper, { children: text }, void 0)] }, void 0));
};

export { Header as default };
