import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';

var requestAverageIntersections = function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var AVERAGE_INTERSECTIONS_REQUEST_URL, requests, response, err_1, signal, token, time_range, main_obj_id, contra_obj_ids, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                AVERAGE_INTERSECTIONS_REQUEST_URL = 'https://lambda.focustech.xyz/core-data-provider/v1/shopster_awgweighted_intersections_percent_obj_with_arr';
                if (!Array.isArray(args)) return [3 /*break*/, 5];
                requests = args.map(function (request) {
                    var signal = request.signal, token = request.token, time_range = request.time_range, main_obj_id = request.main_obj_id, contra_obj_ids = request.contra_obj_ids;
                    return axios({
                        method: 'POST',
                        signal: signal,
                        url: AVERAGE_INTERSECTIONS_REQUEST_URL,
                        data: {
                            auth: {
                                xtoken: token,
                            },
                            input_parameters: {
                                contra_obj_ids: contra_obj_ids,
                                main_obj_id: main_obj_id,
                                time_range: time_range,
                            },
                        },
                    });
                });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.all(requests).then(axios.spread(function () {
                        var res = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            res[_i] = arguments[_i];
                        }
                        return res;
                    }))];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, err_1];
            case 4: return [3 /*break*/, 9];
            case 5:
                signal = args.signal, token = args.token, time_range = args.time_range, main_obj_id = args.main_obj_id, contra_obj_ids = args.contra_obj_ids;
                _a.label = 6;
            case 6:
                _a.trys.push([6, 8, , 9]);
                return [4 /*yield*/, axios({
                        method: 'POST',
                        signal: signal,
                        url: AVERAGE_INTERSECTIONS_REQUEST_URL,
                        data: {
                            auth: {
                                xtoken: token,
                            },
                            input_parameters: {
                                contra_obj_ids: contra_obj_ids,
                                main_obj_id: main_obj_id,
                                time_range: time_range,
                            },
                        },
                    })];
            case 7:
                response = _a.sent();
                return [2 /*return*/, response];
            case 8:
                err_2 = _a.sent();
                return [2 /*return*/, err_2];
            case 9: return [2 /*return*/];
        }
    });
}); };

export { requestAverageIntersections as default };
