import { IPeriod } from '../General.interfaces';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';

export const generateMainDateRanges = (mainPeriod: IPeriod, t: TFunction, timeZone: string) => {
    const getCurrentPeriod = (period: 'week' | 'month' | 'year') => {
        const start = DateTime.now().setZone(timeZone).startOf(period).toISODate();
        const now = DateTime.now().setZone(timeZone).toISODate();
        return { dateFrom: start, dateTo: now };
    };

    const getPreviousPeriod = (period: 'week' | 'month' | 'year') => {
        const start = DateTime.now()
            .setZone(timeZone)
            .minus({ [period]: 1 })
            .startOf(period)
            .toISODate();
        const end = DateTime.now()
            .setZone(timeZone)
            .minus({ [period]: 1 })
            .endOf(period)
            .toISODate();
        return { dateFrom: start, dateTo: end };
    };

    const getCustomPeriod = () => {
        if (mainPeriod?.period) {
            return mainPeriod.period;
        } else {
            return { dateFrom: '', dateTo: '' };
        }
    };

    let options = [
        {
            id: 'today',
            text: t('Today'),
            period: {
                dateFrom: DateTime.now().setZone(timeZone).toISODate(),
                dateTo: DateTime.now().setZone(timeZone).toISODate(),
            },
        },
        {
            id: 'yesterday',
            text: t('Yesterday'),
            period: {
                dateFrom: DateTime.now().setZone(timeZone).minus({ days: 1 }).toISODate(),
                dateTo: DateTime.now().setZone(timeZone).minus({ days: 1 }).toISODate(),
            },
        },
        { id: 'currentWeek', text: t('Current week'), period: getCurrentPeriod('week') },
        { id: 'previousWeek', text: t('Previous week'), period: getPreviousPeriod('week') },
        { id: 'currentMonth', text: t('Current month'), period: getCurrentPeriod('month') },
        { id: 'previousMonth', text: t('Previous month'), period: getPreviousPeriod('month') },
        { id: 'currentYear', text: t('Current year'), period: getCurrentPeriod('year') },
        { id: 'selectCustom', text: t('Select custom'), period: getCustomPeriod() },
    ];

    return options;
};
