import { ICrossVisitsModuleReducerState, IVersionSelectOption, TRawVersionsData } from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store';

const initialState: ICrossVisitsModuleReducerState = {
    selectedVersionOption: null,
    versionSelectOptions: null,
    selectedVersionId: null,
    rawVersionsData: null,
};

const Maps_CrossVisits_Module_Reducer = createSlice({
    name: 'Maps_CrossVisits_Module_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение сырых данных версий
         */
        storeRawVersionsData: (state, action: PayloadAction<TRawVersionsData>) => {
            state.rawVersionsData = action.payload;
        },

        /**
         * Сохранение id выбранной версии
         */
        storeSelectedVersionId: (state, action: PayloadAction<number | null>) => {
            state.selectedVersionId = action.payload;
        },

        /**
         * Сохранение настроек для селека версий
         */
        storeVersionSelectOptions: (state, action: PayloadAction<IVersionSelectOption[] | null>) => {
            state.versionSelectOptions = action.payload;
        },

        /**
         * Сохранение выбранной опции из селекта версий
         */
        storeSelectedVersionOption: (state, action: PayloadAction<[IVersionSelectOption] | null>) => {
            state.selectedVersionOption = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetCrossVisitsModuleReducer: () => initialState,
    },
});

export const {
    resetCrossVisitsModuleReducer,
    storeSelectedVersionOption,
    storeVersionSelectOptions,
    storeSelectedVersionId,
    storeRawVersionsData,
} = Maps_CrossVisits_Module_Reducer.actions;

export const Maps_CrossVisits_Module_Reducer_Values = (state: RootState) => state.Maps_CrossVisits_Module_Reducer;

export default Maps_CrossVisits_Module_Reducer.reducer;
