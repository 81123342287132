import { PivotItem } from '@fluentui/react';
import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { getReportingObjects } from '../../../../../../../tools/getReportingObjects';
import TreeMap from '../../../../../Charts/TreeMap/Treemap';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import {
    Performance_Dashboard_ByArea_Widget_Reducer_Values,
    reloadWidget,
    storeObjectsType,
    storeSelectedObjectId,
} from './reducer';
import Selects from './components/Selects/Selects';
import Ratings from './components/Ratings/Ratings';
import { TreeMapWrapper, WidgetWrapper } from './styles';
import { colorAxisOptions } from './core/options/color-axis';
import { SeriesClickEventObject, TooltipOptions } from 'highcharts';
import { renderToString } from 'react-dom/server';
import Tooltip from '../../../../../Charts/Tooltip/Tooltip';
import TooltipBody from '../../../../../Charts/TreeMap/components/TooltipBody/TooltipBody';
import { numberWithSpaces } from '../../../../../../../tools/Strings/numberWithSpaces';
import _ from 'lodash';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import ObjectsRatings from './components/ObjectsRatings/Ratings';
import { useRerender } from '../../../../../../../tools/useRerender';
import { plotOptions, treemapPlotOptions } from './core/options/plot';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const AnalysisByArea: React.FC = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { objectsType, data, responseResult, selectedObjectId } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const [pivotItems, setPivotItems] = useState<{ itemKey: string; headerText: string }[]>([]);
    const rerender = useRerender(data);

    useEffect(() => {
        const lockedFields = ['location', 'passway', 'place'];

        const pivotItems = getReportingObjects(reportingObjectsByType, t)
            .map((item) => ({
                itemKey: item.key,
                headerText: item.section,
            }))
            ?.filter((item) => !lockedFields.includes(item.itemKey));

        if (!objectsType && pivotItems.length) dispatch(storeObjectsType(pivotItems[0].itemKey));

        setPivotItems(pivotItems);
    }, [reportingObjectsByType, t, objectsType]);

    const treeMap = useMemo(() => {
        const tooltip: TooltipOptions = {
            formatter: function (this: any) {
                let percent = '–';
                if (!isNaN(Number(this.point?.colorValue))) {
                    percent = numberWithSpaces(this.point.colorValue) + '%';
                }
                return renderToString(
                    <Tooltip title={t(String(this.key))} subTitle={''}>
                        <TooltipBody
                            showSeriesName={false}
                            body={[
                                {
                                    title: t(this?.point?.metricName),
                                    data: [{ value: percent }, { value: numberWithSpaces(this.point?.mainValue) }],
                                },
                            ]}
                        />
                    </Tooltip>,
                );
            },
            backgroundColor: 'transparent',
            animation: false,
            borderWidth: 0,
            useHTML: true,
            shadow: false,
            hideDelay: 0,
            shared: true,
            padding: 0,
        };

        return (
            <TreeMap
                options={{
                    colorAxis: colorAxisOptions,
                    ...(data as Highcharts.Options),
                    tooltip,
                    plotOptions: {
                        ...plotOptions,
                        treemap: {
                            ...treemapPlotOptions,
                            events: {
                                click: function (e) {
                                    setSelectedObject(e);
                                },
                            },
                        },
                    },
                }}
            />
        );
    }, [data, t]);

    const onLinkClick = (item: PivotItem) => {
        item.props.itemKey && dispatch(storeObjectsType(item.props.itemKey));
    };

    const setSelectedObject = (objectId: SeriesClickEventObject) => {
        const selectId = _.get(objectId.point.options, 'objectId');
        selectId && dispatch(storeSelectedObjectId(selectId));
    };

    const WithLoadingTreeMap = useMemo(() => {
        return withLoading(TreeMapWrapper as ComponentType, { data: responseResult, height: 400 });
    }, [responseResult, data, t]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Analysis of shopping center objects by area')}
                    <WidgetAdditionalControls
                        widgetName="Analysis of shopping center objects by area"
                        pdfDownloadData={{
                            targetRef: ref,
                            rerenderKey: rerender,
                        }}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
                <Pivot
                    widgetName="AnalysisByArea"
                    onLinkClick={onLinkClick}
                    selectedKey={objectsType}
                    overflowBehavior="menu"
                >
                    {pivotItems.map((element) => (
                        <PivotItem
                            key={element.itemKey + Math.random()}
                            headerText={t(element.headerText)}
                            itemKey={element.itemKey}
                        />
                    ))}
                </Pivot>
            </WidgetTitleWrapper>
            <Selects />
            <WithLoadingTreeMap>{treeMap}</WithLoadingTreeMap>
            {selectedObjectId === null ? <Ratings /> : <ObjectsRatings />}
        </WidgetWrapper>
    );
};

export default AnalysisByArea;
