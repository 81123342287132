/**
 * Функция для получение различия между переданными числами
 * @param a Перове число
 * @param b Второе число
 * @returns Объект с различием между переданными числавми в процентах и числовое различие
 */
const getDifferenceBetweenNumbers = (a: number, b: number) => {
    let percentDifference = 0;
    percentDifference = (b / a) * 100 - 100;

    return {
        percentDifference: percentDifference.toFixed(2),
        difference: (b - a).toFixed(2),
    };
};

export default getDifferenceBetweenNumbers;
