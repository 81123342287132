import { ColorsEnum } from '../../../../Charts/Dynamics/constants/enums';

export const PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS = 'pathToPurchaseGroupsOfZonesSettings';
export const PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS = 'pathToPurchaseFunnelStepsSettings';
export const PATH_TO_PURCHASE_FUNNEL_SETTINGS = 'pathToPurchaseFunnelSettings';

export enum IdsOfFunnelSettings {
    PercentageOfTheMall = 'PercentageOfTheMall',
    PercentageOfThePreviousStep = 'PercentageOfThePreviousStep',
}

export const optionsOfFunnelSettings = [
    { id: IdsOfFunnelSettings.PercentageOfTheMall, text: 'Percentage of the mall' },
    { id: IdsOfFunnelSettings.PercentageOfThePreviousStep, text: 'Percentage of the previous step' },
];

export enum IdsOfFunnelStepsOptions {
    Transactions = 'transactions',
    Tenant = 'tenant',
    Floor = 'floor',
    Location = 'location',
    Zone = 'zone',
}

export interface IOptionsOfFunnelSteps {
    id: IdsOfFunnelStepsOptions;
    text: string;
    legendName: LegendNamesOfFunnelSteps;
    order: number;
    seriesColor?: ColorsEnum;
}

export enum LegendNamesOfFunnelSteps {
    tenant = 'Traffic of tenant',
    zone = 'Traffic of zone',
    floor = 'Traffic of floor',
    transactions = 'Transactions',
    location = 'Traffic of location',
}

export const optionsOfFunnelSteps: IOptionsOfFunnelSteps[] = [
    { id: IdsOfFunnelStepsOptions.Location, text: 'Location', legendName: LegendNamesOfFunnelSteps.location, order: 5 },
    {
        id: IdsOfFunnelStepsOptions.Floor,
        text: 'Floor',
        legendName: LegendNamesOfFunnelSteps.floor,
        order: 4,
        seriesColor: ColorsEnum['index-0'],
    },
    {
        id: IdsOfFunnelStepsOptions.Zone,
        text: 'Zone',
        legendName: LegendNamesOfFunnelSteps.zone,
        order: 3,
        seriesColor: ColorsEnum['index-2'],
    },
    {
        id: IdsOfFunnelStepsOptions.Tenant,
        text: 'Tenant',
        legendName: LegendNamesOfFunnelSteps.tenant,
        order: 2,
        seriesColor: ColorsEnum['index-1'],
    },
    {
        id: IdsOfFunnelStepsOptions.Transactions,
        text: 'Transactions',
        legendName: LegendNamesOfFunnelSteps.transactions,
        order: 1,
        seriesColor: ColorsEnum['index-9'],
    },
];
