import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import React, { useRef } from 'react';
import './configurations/button-size.enum.js';
import { ButtonTheme } from './configurations/button-theme.enum.js';
import './configurations/button-view.enum.js';
import * as constants from '../../constants/constants.js';
import { DEFAULT_CLASS_BTN_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';
import cn from 'classnames';
import { StIcon } from './styles/styles.js';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';

var Button = function (_a) {
    var onClick = _a.onClick, icon = _a.icon, _b = _a.iconColor, iconColor = _b === void 0 ? 'var(--color-contrast-medium)' : _b, _c = _a.text, text = _c === void 0 ? 'Button' : _c, _d = _a.size, size = _d === void 0 ? 'md' : _d, _e = _a.type, type = _e === void 0 ? 'primary' : _e, _f = _a.theme, theme = _f === void 0 ? '' : _f, _g = _a.disabled, disabled = _g === void 0 ? false : _g, children = _a.children, props = __rest(_a, ["onClick", "icon", "iconColor", "text", "size", "type", "theme", "disabled", "children"]);
    var ref = useRef(null);
    var mergedStyles = useStyles({ ref: ref, props: props, constants: constants });
    var DEFAULT_CLASS_BTN_PREFIX$1 = DEFAULT_CLASS_BTN_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1 = DEFAULT_SEPARATOR_MODIFICATION_PREFIX;
    /**
     * Проверка: есть ли такая тема ?
     * @param theme - тема ('outline')
     */
    var isValidTheme = function (theme) {
        var capitalize = function (s) { return (s && s[0].toUpperCase() + s.slice(1)) || ''; };
        return ButtonTheme[capitalize(theme)] !== undefined;
    };
    /**
     * Возращает визуальное представление кнопки
     * @param theme - вид кнопки
     */
    var getThemeCssButton = function (theme) {
        return isValidTheme(theme) ? "".concat(theme, "-") : ButtonTheme.Empty;
    };
    /**
     * получить класс размера кнопки
     * @param size - размер кнопки
     */
    var getSizeCssButton = function (size) {
        return "".concat(DEFAULT_CLASS_BTN_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1).concat(size);
    };
    /**
     * получить класс disabled кнопки
     */
    var getDisabledCssButton = function (disabled) {
        return disabled ? "".concat(DEFAULT_CLASS_BTN_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, "disabled") : '';
    };
    var className = React.useMemo(function () {
        var cssClassButton = "".concat(DEFAULT_CLASS_BTN_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1).concat(getThemeCssButton(theme)).concat(type);
        var cssClassAlignButton = '';
        var cssClassSizeButton = getSizeCssButton(size);
        var cssClassDisabledButton = getDisabledCssButton(disabled);
        return cn(__spreadArray([
            'button-height',
            DEFAULT_CLASS_BTN_PREFIX$1,
            cssClassButton,
            cssClassSizeButton,
            cssClassAlignButton,
            cssClassDisabledButton
        ], mergedStyles, true));
    }, [
        DEFAULT_CLASS_BTN_PREFIX$1,
        DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1,
        getThemeCssButton,
        theme,
        type,
        getSizeCssButton,
        size,
        getDisabledCssButton,
        disabled,
        mergedStyles,
    ]);
    var iconClass = mergeStyles({
    // fontSize: 50,
    // height: 50,
    // width: 50,
    // margin: '0 25px',
    });
    var iconClassNames = mergeStyleSets({
        main: [{ color: iconColor }, iconClass],
    });
    return (jsxs("button", __assign({ className: className, onClick: onClick, disabled: disabled }, { children: [icon && jsx(StIcon, { iconName: icon, className: iconClassNames.main }, void 0), jsx(Fragment, { children: children ? children : text }, void 0)] }), void 0));
};

export { Button as default };
