import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { Button, Modals } from '../../../../../../../lib/esm/components';
import { getReportingObjects } from '../../../../../../../tools/getReportingObjects';
import ComparePeriodSelect from '../../../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../../../Selects/MainPeriodSelect/MainPeriodSelect';
import SelectsWrapper from '../../../../../Wrappers/SelectsWrapper/SelectsWrapper';
import WrapperContainer from '../../../../../Wrappers/WrapperContainer/WrapperContainer';
import ModalNewRating from './components/ModalNewRating/ModalNewRating';
import RatingList from './components/RatingList/RatingList';
import { Performance_Ratings_RatingList_Widget_Reducer_Values } from './reducer';
import { OptionsWrapper, WidgetWrapper } from './style';

const RatingListWidget: React.FC = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { editRatingId } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    const { mainWindowSize } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const [modalStatus, setModalStatus] = useState({ show: false });
    const buttonClick = () => {
        setModalStatus({ show: true });
    };
    const objectBytype = getReportingObjects(reportingObjectsByType, t);
    useEffect(() => {
        if (editRatingId) {
            setModalStatus({ show: true });
        }
    }, [editRatingId]);

    const handleModalClose = () => {
        setModalStatus({ show: false });
    };

    return (
        <>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ComparePeriodSelect mode="single" />
                    </SelectsWrapper>
                    <Button onClick={buttonClick} disabled={objectBytype.length === 0}>
                        {t('New rating')}
                    </Button>
                </OptionsWrapper>
                <WidgetWrapper>
                    <RatingList></RatingList>
                </WidgetWrapper>
            </WrapperContainer>
            <Modals
                title={t('New rating adding')}
                closeModal={handleModalClose}
                modalStatus={modalStatus}
                topColor="fff"
            >
                <ModalNewRating mainWindowSize={mainWindowSize} closeModal={handleModalClose}></ModalNewRating>
            </Modals>
        </>
    );
};
export default RatingListWidget;
