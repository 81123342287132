import { FC, memo, useContext, useState } from 'react';
import { StyledIcon, Wrapper, GrandWrapper } from './styles';
import { Stack } from '@fluentui/react';
import EventItem from './components/EventItem/EventItem';
import { EventsMapWidgetContext } from '../../context';
import { useSelector } from 'react-redux';
import { Events_EventsMap_Widget_Reducer_Values } from '../../reducer';

interface IProps {}

/**
 * Боковое меню
 */
const Sidebar: FC<IProps> = memo(() => {
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen((prevState) => !prevState);
    };

    return (
        <GrandWrapper open={open}>
            <Wrapper>
                <StyledIcon open={open} onClick={toggleOpen} iconName={'NavigateForward'} />
                <Stack tokens={{ childrenGap: 10 }}>
                    {/* {context &&
                        Object.keys(context.eventTypesByName || {}).map((name) => {
                            return <EventItem key={name} open={open} eventType={context.eventTypesByName[name]} />;
                        })} */}
                        {eventTypes.map(type => {
                            return <EventItem key={type.id} open={open} eventType={type} /> 
                        })}
                </Stack>
            </Wrapper>
        </GrandWrapper>
    );
});

export default Sidebar;
