import { Button, Icon, Modals, Tooltip } from '../../../../../lib/esm/components';
import CabinetPreferences from '../../../CabinetPreferences/CabinetPreferences';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

/**
 * Компонент для отображения шестеренки с настройками кабинета для пользователя
 */
const CabinetPreferencesMenu = () => {
    const [modalStatus, setModalStatus] = useState({ show: false });
    const { t } = useTranslation();

    const closeModal = () => {
        setModalStatus({ show: false });
    };

    const openModal = () => {
        setModalStatus({ show: true });
    };

    return (
        <>
            <Modals topColor="fff" modalStatus={modalStatus} title={t('Cabinet preferences')} closeModal={closeModal}>
                <CabinetPreferences />
            </Modals>
            <Tooltip
                calloutProps={{
                    isBeakVisible: false,
                    beakWidth: 0,
                    gapSpace: 0,
                }}
                content={t('Cabinet preferences')}
            >
                <Button padding="l_xxxxs r_xxxxs" margin="l_xs" onClick={openModal} theme="icon" type="primary">
                    <Icon size="sm" color="gray-darker" type="setting-bolder" />
                </Button>
            </Tooltip>
        </>
    );
};

export default CabinetPreferencesMenu;
