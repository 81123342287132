import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from './styles';

const LoadingBox: React.FC<{
    height?: number;
    hasBorder?: boolean;
    text?: string;
    isError?: boolean;
    noData?: boolean;
}> = ({ height, text, hasBorder, isError = false, noData = false }) => {
    const { t } = useTranslation();
    return (
        <LoadingWrapper height={height} hasBorder={hasBorder}>
            {!isError && !noData && <Spinner size={SpinnerSize.large} />}
            {isError && <span>{t('Network Error')}</span>}
            {noData && <span>{t('No data')}</span>}
            {text && <span>{t(text)}</span>}
        </LoadingWrapper>
    );
};

export default LoadingBox;
