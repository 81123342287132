import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { TOrdering } from '../../../../../../OrderSwitcher/interfaces';
import OrderSwitcher from '../../../../../../OrderSwitcher/OrderSwitcher';
import { THeaderCellType } from '../interfaces';
import { Th, Percent, Value, Container, Title, ArrowWrapper } from '../styles';

const HeaderCell: React.FC<{
    children: ReactNode;
    colSpan: number;
    ordering: TOrdering;
    type: string;
    primaryType: string;
    handleOrderToggle: (x: { ordering: TOrdering; id: string }) => void;
}> = ({ children, colSpan, ordering, type, primaryType, ...props }) => {
    const { t } = useTranslation();
    const units = type === 'percent' ? ', %' : `, ${t('ppl')}`;

    return (
        <Th colSpan={colSpan}>
            <Container>
                <Title>
                    {children}
                    {units}
                </Title>
                <OrderSwitcher
                    mode="triple"
                    id={type}
                    handleOrderToggle={props.handleOrderToggle}
                    ordering={ordering}
                    primaryId={primaryType}
                />
                {type !== 'tenant' && <ArrowWrapper>⬅︎</ArrowWrapper>}
            </Container>
        </Th>
    );
};

export default HeaderCell;
