import { __rest, __assign } from '../../_virtual/_tslib.js';
import { jsx, Fragment } from 'react/jsx-runtime';
import { TooltipDelay, TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react';

// import './styles/styles.scss';
var Tooltip = function (_a) {
    var _b = _a.content, content = _b === void 0 ? '' : _b, _c = _a.showTooltip, showTooltip = _c === void 0 ? true : _c, _d = _a.calloutProps, calloutProps = _d === void 0 ? { isBeakVisible: false, beakWidth: 0, gapSpace: -15 } : _d, _e = _a.directionalHint, directionalHint = _e === void 0 ? DirectionalHint.rightCenter : _e, _f = _a.delay; _f === void 0 ? TooltipDelay.zero : _f; var props = __rest(_a, ["content", "showTooltip", "calloutProps", "directionalHint", "delay"]);
    var hostStyles = { root: { display: 'inline-block' } };
    return showTooltip ? (jsx(TooltipHost, __assign({ content: content, id: 'tooltipId', calloutProps: calloutProps, directionalHint: directionalHint, styles: hostStyles }, props, { children: props.children }), void 0)) : (jsx(Fragment, { children: props.children }, void 0));
};

export { Tooltip as default };
