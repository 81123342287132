import { __makeTemplateObject } from '../../../_virtual/_tslib.js';
import styled from 'styled-components/macro';

var AccordionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var AccordionItemStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border: 1px solid var(--color-border-gray);\n    border-radius: 3px;\n    overflow: hidden;\n    margin-bottom: 8px;\n    background-color: var(--color-white);\n"], ["\n    border: 1px solid var(--color-border-gray);\n    border-radius: 3px;\n    overflow: hidden;\n    margin-bottom: 8px;\n    background-color: var(--color-white);\n"])));
var AccordionItemHeading = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var AccordionItemButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    cursor: pointer;\n    font-size: 1rem;\n    line-height: 1.187rem;\n    display: block;\n    width: 100%;\n    text-align: left;\n    position: relative;\n    padding: 20px 40px 20px 20px;\n"], ["\n    cursor: pointer;\n    font-size: 1rem;\n    line-height: 1.187rem;\n    display: block;\n    width: 100%;\n    text-align: left;\n    position: relative;\n    padding: 20px 40px 20px 20px;\n"])));
var AccordionItemBody = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    min-height: 1px;\n    padding: 1.25rem;\n"], ["\n    min-height: 1px;\n    padding: 1.25rem;\n"])));
var AccordionIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: absolute;\n    right: 25px;\n    top: calc(50% - 11px);\n"], ["\n    position: absolute;\n    right: 25px;\n    top: calc(50% - 11px);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

export { AccordionIcon, AccordionItemBody, AccordionItemButton, AccordionItemHeading, AccordionItemStyle, AccordionWrapper };
