import { DateTime } from 'luxon';

export function filterValidDateRanges<T extends { date_to: string; date_from: string }>(
    relation: Array<T>,
    period: { dateFrom: string; dateTo: string } | undefined,
) {
    return relation?.filter(
        (item) =>
            DateTime.fromISO(item.date_from).toMillis() <= DateTime.fromISO(period?.dateTo || '').toMillis() &&
            DateTime.fromISO(item.date_to).toMillis() >= DateTime.fromISO(period?.dateTo || '').toMillis(),
    );
}
