import { CardsWrapper, CurrentDate, LeasingCrossVisitsWidgetWrapper } from './styles';
import {
    Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer_Values,
    reloadWidget,
    storeSelectedReportingObjectsType,
} from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';

import Card from './components/Card/Card';
import { TAvailableReportingObjectsType } from './interfaces';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useRerender } from '../../../../../../../tools/useRerender';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import { IntersectionsWithTenantsContext } from './context';
import { stringDate } from '../../../../../../../tools/Strings/stringDate';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета Leasing:Cross-visits
 */
const IntersectionsWithTenants = () => {
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        selectedReportingObjectsType,
        extendedReportingObjectsById,
        intersectionsDataById,
        daysInPeriod,
        isLoading,
        isError,
    } = useSelector(Leasing_TenantOverview_IntersectionsWithTenants_Widget_Reducer_Values);
    const {
        lang,
        cfg: { reportingObjectsById, tenant2FloorByTenantId, tenant2ZoneByTenantId, reportingObjectsByType },
        src: { dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const storeSelectedReportingObjectsTypeTrans = (type: TAvailableReportingObjectsType) => {
        dispatch(storeSelectedReportingObjectsType(type));
    };

    const cards = useMemo(() => {
        if (extendedReportingObjectsById) {
            return Object.keys(extendedReportingObjectsById).map((key) => {
                const extendedReportingObject = extendedReportingObjectsById[key];

                return (
                    <Card
                        intersectionsDataById={
                            intersectionsDataById
                                ? intersectionsDataById[extendedReportingObject.reportingObject.id]
                                : null
                        }
                        extendedReportingObject={extendedReportingObject}
                        key={extendedReportingObject.reportingObject.id}
                    />
                );
            });
        }
        return null;
    }, [intersectionsDataById, daysInPeriod, extendedReportingObjectsById]);

    const currentPeriod = useMemo(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            const period = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions?.mainPeriod?.id,
            )?.period;
            if (period) {
                return stringDate(period, lang, '', 'dd MMM yyyy');
            }
        }
        return '';
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, lang]);

    const context = {
        mainDateRanges: localCurrentOptions?.mainDateRanges,
        mainPeriod: localCurrentOptions?.mainPeriod,
        filters: localCurrentOptions?.['filters'],
        extendedReportingObjectsById,
        selectedReportingObjectsType,
        dataObj2ProjectCategory,
        reportingObjectsByType,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        daysInPeriod,
        isLoading,
        isError,
        storeSelectedReportingObjectsTypeTrans,
    };

    const rerender = useRerender(cards);

    return (
        <IntersectionsWithTenantsContext.Provider value={context}>
            <LeasingCrossVisitsWidgetWrapper ref={ref}>
                <WidgetTitleWrapper>
                    <WidgetTitle>
                        {t('Intersections with tenants')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                            widgetName={'Intersections with tenants'}
                            reloadHandler={reloadWidget}
                        />
                    </WidgetTitle>
                </WidgetTitleWrapper>
                <CurrentDate>{currentPeriod}</CurrentDate>
                <CardsWrapper>{cards}</CardsWrapper>
            </LeasingCrossVisitsWidgetWrapper>
        </IntersectionsWithTenantsContext.Provider>
    );
};

export default IntersectionsWithTenants;
