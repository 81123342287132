import styled from 'styled-components/macro';

export const WidgetWrapper = styled.div``;

export const MapsWrapper = styled.div``;

export const StageWrapper = styled.div`
    /* display: flex; */
`;

export const SelectWrapper = styled.div`
    margin-bottom: var(--space-xxs);
`;

export const RatingsWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
`;
