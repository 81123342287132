import { Spinner } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';

const Loader = () => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Spinner label={t('Loading...')} labelPosition="bottom" />
        </Wrapper>
    );
};

export default Loader;
