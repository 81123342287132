import { TooltipWrapper, HeaderWrapper, HeaderItem, BodyWrapper } from './styles';
import { ITooltipProps } from './interfaces';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отображения тултипа графика
 * @param subTitle Второстепенный заголовок
 * @param title Заголовок
 * @param body "Тело" тултипа
 */
const Tooltip: FC<ITooltipProps> = ({ title, subTitle, children }) => {
    const { t } = useTranslation();
    return (
        <TooltipWrapper>
            <HeaderWrapper>
                <HeaderItem>{t(title)}</HeaderItem>
                <HeaderItem>{t(subTitle)}</HeaderItem>
            </HeaderWrapper>
            <BodyWrapper>{children}</BodyWrapper>
        </TooltipWrapper>
    );
};

export default Tooltip;
