import axios, { AxiosError, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { ICategory, IDataObj2ProjectCategory, IProjectCategory, IReportingObject } from '../../General.interfaces';
import { generalReducerValues } from '../../General.reducer';
import { IRequestMetricsArgs, requestObjectMetrics, TRequestMetricsArgs } from '../../lib/esm/components';

export const useRequestMetrics = (requestsHandler?: (data: any) => void) => {
    const {
        src: { dataObj2ProjectCategory },
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    return (args: IRequestMetricsArgs[]) => {
        return requestMiddleware(args, dataObj2ProjectCategory, reportingObjectsById, requestsHandler);
    };
};

const requestMiddleware = async (
    args: IRequestMetricsArgs[],
    dataObj2ProjectCategory: IDataObj2ProjectCategory[],
    reportingObjectsById: {
        [x: string]: IReportingObject;
    },
    requestsHandler?: (data: any) => void,
) => {
    const getIdsOfBlock = (ids: number[], blockType: string) => {
        return ids?.filter((id) => {
            return reportingObjectsById?.[id]?.block_type === blockType;
        });
    };

    const all = [];

    //--------------- Создание запроса для отчетных объектов

    const objectMetricsArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const reportingObjectIds = getIdsOfBlock(request.obj_ids, 'reporting_object');

        reportingObjectIds?.length && objectMetricsArgs.push({ ...request, obj_ids: reportingObjectIds });
    });

    if (objectMetricsArgs.length) {
        const defaultRequests: IRequestMetricsArgs[] = [];
        const salesRequests: IRequestMetricsArgs[] = [];
        objectMetricsArgs.forEach((element) => {
            requestsHandler && requestsHandler(element);
            if (element.alias?.split(':')[0] === 'sales') {
                salesRequests.push(element);
            } else {
                defaultRequests.push(element);
            }
        });

        all.push(requestObjectMetrics(defaultRequests));
        // all.push(requestObjectMetrics(salesRequests, 'http://130.193.54.34:8000/api/dataobj_metrics/metric_dynamics'));
        all.push(requestObjectMetrics(salesRequests));
    }

    //--------------- Создание запроса для категорий

    const categoriesMetricsArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const categoryIds = getIdsOfBlock(request.obj_ids, 'category');
        categoryIds?.forEach((id) => {
            const obj_ids = dataObj2ProjectCategory
                ?.filter((item) => item.category_id === id)
                .map((item) => item.data_object_id);
            obj_ids?.length &&
                categoriesMetricsArgs.push({
                    ...request,
                    obj_ids,
                    object_aggregation: true,
                    alias: request.alias + `∧category∧${id}`,
                });
        });
    });

    categoriesMetricsArgs.length && all.push(requestCategoryMetrics(categoriesMetricsArgs));

    //--------------- Создание запроса для проектных категорий

    const projectCategoriesMetricsArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const projectCategoryIds = getIdsOfBlock(request.obj_ids, 'project_category');
        projectCategoryIds?.forEach((id) => {
            const obj_ids = dataObj2ProjectCategory
                ?.filter((item) => item.category_id === id)
                .map((item) => item.data_object_id);

            if (obj_ids?.length) {
                const data = {
                    ...request,
                    obj_ids,
                    object_aggregation: true,
                    alias: request.alias + `∧project_category∧${id}`,
                };
                requestsHandler && requestsHandler(data);

                projectCategoriesMetricsArgs.push(data);
            }
        });
    });

    projectCategoriesMetricsArgs.length && all.push(requestCategoryMetrics(projectCategoriesMetricsArgs));

    //--------------- Создание запроса для бенчмаков

    const benchArgs: IRequestMetricsArgs[] = [];

    args.forEach((request) => {
        const benchIds = request.obj_ids?.filter((id) => id >= 6660000);
        benchIds?.length && benchArgs.push({ ...request, obj_ids: benchIds });
    });

    benchArgs.length && all.push(requestBenchmark(benchArgs));

    //--------------- Подгонка ответов под существующий фронт (убрать, когда появятся нужные ручки)

    try {
        const response = await axios.all(all).then(
            axios.spread((...res) => {
                const newRes = {};
                const responses = res.reduce((acc, item) => {
                    if (item?.name === 'AxiosError') {
                        newRes['error'] = item;
                        return [...acc, item];
                    } else {
                        return [...acc, ...item];
                    }
                }, []);

                responses.forEach((item: AxiosResponse, i: number) => {
                    if (
                        item?.data?.result?.[0]?.context?.alias?.split('∧')[2] &&
                        item?.data?.result?.[0]?.context?.alias?.split('∧')[0] !== 'benchmark'
                    ) {
                        const arr = item.data.result[0].context.alias.split('∧');
                        const id = item.data.result[0].context.alias.split('∧')[arr.length - 1];
                        arr.splice(-2, 2);
                        const alias = arr.join(':');
                        const name = reportingObjectsById[id].name;
                        item.data.result[0].context.alias = alias;
                        item.data.result[0].context.data_objects = [{ id: Number(id), name }];

                        if (newRes[alias]) {
                            newRes[alias].data.result.push(item.data.result[0]);
                        } else {
                            newRes[alias] = item;
                        }
                    } else {
                        const alias = item?.data?.result?.[0]?.context?.alias || `${i}`;
                        newRes[`${alias}${i}`] = item;
                    }
                });
                return Object.values(newRes).map((value) => value);
            }),
        );

        return response;
    } catch (err) {
        return err;
    }
};

const requestCategoryMetrics = (args: TRequestMetricsArgs) => {
    return requestObjectMetrics(args);
};

const requestBenchmark = async (args: TRequestMetricsArgs) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    const requests = args.map((request) => {
        const { signal, token, alias, metric, metric_level, obj_ids, object_aggregation, time_range, time_freq } =
            request;
        const url = `https://storage.yandexcloud.net/index.focustech.xyz/${alias?.split(':').join('_')}.json`;

        if (!obj_ids.length) return;

        return axios({
            method: 'GET',
            headers,
            signal,
            url,
        });
    });

    try {
        const response = await axios.all(requests).then(
            axios.spread((...res) => {
                const newRes = res.map((item: any, i) => {
                    const items = item.data.result[0].items?.filter((data: any) => {
                        return (
                            DateTime.fromISO(data.time).toMillis() >=
                                DateTime.fromISO(args[i].time_range[0]).toMillis() &&
                            DateTime.fromISO(data.time).toMillis() <= DateTime.fromISO(args[i].time_range[1]).toMillis()
                        );
                    });

                    item.data.result[0].items = items;
                    item.data.result[0].context.alias = args[i].alias;

                    return item;
                });
                return newRes;
            }),
        );
        return response;
    } catch (err) {
        return err;
    }
};
