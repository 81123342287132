import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IReducerState } from './interfaces';
import { IReportingObject } from '../../../../../../../General.interfaces';
import { ISeriesData } from './tools/interfaces';
import { RootState } from '../../../../../../../store';
import { TLoadingData } from '../../../../../../../lib/esm/General.interfaces';
import { cloneDeep } from 'lodash';

export const LOADING_DATA = { status: 'Loading', message: 'Loading' };

const initialState: IReducerState = {
    updateChartsByReportingObjectsCounter: 0,
    reportingObjectsRawMetricsDataById: {},
    shoudUpdateChartsByPeriod: false,
    extendedReportingObjectsById: {},
    updateChartsByPeriodCounter: 0,
    rawMetricsData: LOADING_DATA,
    dataRefetchObject: {},
};
const Performance_VisitorsInside_Widget_Reducer = createSlice({
    name: 'Performance_VisitorsInside_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение расширенной версии всех выбранных объектов
         */
        storeExtendedReportingObjects: (
            state,
            action: PayloadAction<{ reportingObjects: IReportingObject[]; shoudUpdateAllItems: boolean }>,
        ) => {
            const { reportingObjects, shoudUpdateAllItems } = action.payload;
            const { extendedReportingObjectsById, reportingObjectsRawMetricsDataById } = cloneDeep(state);

            const objectsById = reportingObjects.reduce((acc, value) => {
                acc[value.id] = {
                    objectInfo: value,
                    chartOptionsGeneratorSettings: shoudUpdateAllItems
                        ? null
                        : extendedReportingObjectsById[value.id]?.chartOptionsGeneratorSettings || null,
                };
                return acc;
            }, {});

            const objectsRawMetricsDataById = reportingObjects.reduce((acc, value) => {
                acc[value.id] = shoudUpdateAllItems
                    ? LOADING_DATA
                    : reportingObjectsRawMetricsDataById[value.id] || LOADING_DATA;
                return acc;
            }, {});

            if (!shoudUpdateAllItems || !Object.keys(extendedReportingObjectsById).length) {
                state.updateChartsByReportingObjectsCounter += 1;
            }

            state.reportingObjectsRawMetricsDataById = objectsRawMetricsDataById;
            state.extendedReportingObjectsById = { ...objectsById };
        },
        /**
         * Запись сырых данных для каждого из отчтных объектов
         */
        storeReportigObjectsRawMetricsDataById: (
            state,
            action: PayloadAction<{
                [reportingObjectId: string]: TLoadingData;
            }>,
        ) => {
            state.reportingObjectsRawMetricsDataById = action.payload;
            state.shoudUpdateChartsByPeriod = false;
        },

        /**
         * Оновление отчетных объектов, путем добавления настроек для генерации опций диаграммы
         */
        updateExtendedReportingObjectsWithChartOptionsGenratorSettings: (
            state,
            action: PayloadAction<{ [reportingObjectId: string]: ISeriesData | null }>,
        ) => {
            Object.keys(action.payload).forEach((id) => {
                if (state.extendedReportingObjectsById[id]?.objectInfo) {
                    state.extendedReportingObjectsById[id] = {
                        ...state.extendedReportingObjectsById[id],
                        chartOptionsGeneratorSettings: action.payload[id],
                    };
                }
            });
        },

        /**
         * Увеличение счетчика, отвечающего за изменение отчетных объектов
         */
        increaseChartsByReportingObjectsCounter: (state, _: PayloadAction<null>) => {
            state.updateChartsByReportingObjectsCounter += 1;
        },

        /**
         * Увеличение счетчика обноввления диграм от периода
         */
        increaseUpdateChartsByPeriodCounter: (state, _: PayloadAction<null>) => {
            state.updateChartsByPeriodCounter += 1;
        },

        /**
         * Запись сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TLoadingData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Изменение флага обновления диаграмм от пеирода
         */
        toggleUpdateChartsByPeriod: (state, action: PayloadAction<boolean>) => {
            state.shoudUpdateChartsByPeriod = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },

        /**
         * Обнуление редьюсера
         */
        resetReducer: () => initialState,
    },
});

export const {
    updateExtendedReportingObjectsWithChartOptionsGenratorSettings,
    increaseChartsByReportingObjectsCounter,
    storeReportigObjectsRawMetricsDataById,
    increaseUpdateChartsByPeriodCounter,
    storeExtendedReportingObjects,
    toggleUpdateChartsByPeriod,
    storeRawMetricsData,
    resetReducer,
    reloadWidget,
} = Performance_VisitorsInside_Widget_Reducer.actions;

export const Performance_VisitorsInside_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_VisitorsInside_Widget_Reducer;

export default Performance_VisitorsInside_Widget_Reducer.reducer;
