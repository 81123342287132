import { Leasing_Tenants_Tenants_Widget_Reducer_Values, reloadWidget, storeTableSortData } from './reducer';
import { memo, useMemo, useRef } from 'react';
import {
    storeSelectedMetrics,
    storeSelectedObjectIds,
    storeUniversalOptions,
} from '../../../../../UserSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';

import FiltersMonitor from '../../../../../Filters/FiltersMonitor/FiltersMonitor';
import { IReportingObject } from '../../../../../../../General.interfaces';
import { ITableSortData } from './interfaces';
import Table from './components/Table/Table';
import { TableWrapper } from './styles';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useNavigate } from 'react-router-dom';
import { useRerender } from '../../../../../../../tools/useRerender';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета Leasing:Tenants
 */
const TenantsWidget = memo(() => {
    const localCurrentOptions = useWidgetCurrentOptions('Leasing:Tenants');
    const {
        allMetrics,
        cfg: { tenant2ZoneByTenantId, tenant2FloorByTenantId, reportingObjectsById },
        src: { dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);
    const { extendedTenants, rawMetricsData, tableSortData, extendedTenantsById } = useSelector(
        Leasing_Tenants_Tenants_Widget_Reducer_Values,
    );
    const rerender = useRerender(JSON.stringify([rawMetricsData]));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef(null);

    /** Открытие других виджетов для выбранного арендатора */
    const openAdditionalTenantInformation = (data: {
        module: 'Leasing:Tenant overview' | 'Performance:Year over year';
        tenantData: IReportingObject;
        path: string;
        selectedMetrics: string[];
    }) => {
        if (localCurrentOptions?.selectedLocationId) {
            dispatch(
                storeUniversalOptions({
                    selectedOptions: [{ id: data.tenantData.id, text: data.tenantData.name }],
                    locationId: localCurrentOptions.selectedLocationId,
                    module: data.module,
                    name: 'tenants',
                }),
            );
            dispatch(
                storeSelectedObjectIds({
                    locationId: localCurrentOptions.selectedLocationId,
                    selectedReportingObjectsIds: [data.tenantData.id],
                    module: data.module,
                }),
            );

            dispatch(
                storeSelectedMetrics({
                    locationId: localCurrentOptions.selectedLocationId,
                    selectedMetrics: data.selectedMetrics,
                    module: data.module,
                }),
            );
        }
        navigate(data.path);
    };

    const storeTableSortDataTrans = (data: null | ITableSortData) => {
        dispatch(storeTableSortData(data));
    };

    const table = useMemo(() => {
        if (
            extendedTenants &&
            extendedTenantsById &&
            localCurrentOptions?.selectedMetrics &&
            Object.keys(reportingObjectsById).length &&
            Object.keys(dataObj2ProjectCategory).length
        ) {
            return (
                <Table
                    openAdditionalTenantInformation={openAdditionalTenantInformation}
                    selectedMetricsIds={localCurrentOptions.selectedMetrics}
                    mainDateRanges={localCurrentOptions.mainDateRanges}
                    storeTableSortDataTrans={storeTableSortDataTrans}
                    dataObj2ProjectCategory={dataObj2ProjectCategory}
                    tenant2FloorByTenantId={tenant2FloorByTenantId}
                    filters={localCurrentOptions['filters'] || []}
                    tenant2ZoneByTenantId={tenant2ZoneByTenantId}
                    reportingObjectsById={reportingObjectsById}
                    mainPeriod={localCurrentOptions.mainPeriod}
                    extendedTenantsById={extendedTenantsById}
                    extendedTenants={extendedTenants}
                    tableSortData={tableSortData}
                    allMetrics={allMetrics}
                />
            );
        }
        return null;
    }, [
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.['filters'],
        dataObj2ProjectCategory,
        reportingObjectsById,
        extendedTenantsById,
        extendedTenants,
        tableSortData,
    ]);

    return (
        <div ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Tenants')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidget}
                        widgetName={'Tenants'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <FiltersMonitor />
            <TableWrapper>{table}</TableWrapper>
        </div>
    );
});

export default TenantsWidget;
