import { createNewOptionId, deleteOptionId } from './components/PresetModalBody/contants/constants';
import { ISelectedOption } from '../../../lib/esm/components/Select/configurations/index.d';
import { TSelectedOptions } from '../../../lib/esm/components/Select/configurations';
import PresetModalBody from './components/PresetModalBody/PresetModalBody';
import { PresetReducerValues, storeSelectedPreset } from './reducer';
import { userSettingsReducerValues } from '../UserSettings/reducer';
import { generalReducerValues } from '../../../General.reducer';
import { Modals, Select } from '../../../lib/esm/components';
import { useDispatch, useSelector } from 'react-redux';
import usePresetData from './hooks/usePresetData';
import { useTranslation } from 'react-i18next';
import { IPresetProps } from './interfaces';
import { FC, useState } from 'react';
import { PresetWrapper } from './styles';

/**
 * Комопонент для создания пресетов
 * @param currentOptionsKeys ключи из настроек currentOptions, которые необходимо поместить в пресет
 */
const Preset: FC<IPresetProps> = ({ currentOptionsKeys }) => {
    const {
        currentOptions,
        currentOptions: { currentModule, selectedLocationId },
    } = useSelector(userSettingsReducerValues);
    const {
        cfg: { reportingObjectsById },
        allMetrics,
    } = useSelector(generalReducerValues);
    const [modalStatus, setModalStatus] = useState({ show: false });
    const { selectedPreset } = useSelector(PresetReducerValues);
    const [deletePreset, setDeletePreset] = useState(false);
    const options = usePresetData();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSelect = (value: TSelectedOptions) => {
        if (value[0].id === createNewOptionId) return setModalStatus({ show: true });
        if (value[0].id === deleteOptionId) {
            setModalStatus({ show: true });
            setDeletePreset(true);
            return;
        }
        dispatch(storeSelectedPreset(value[0]));
    };

    const storeSelectedPresetTrans = (value: ISelectedOption | null) => {
        dispatch(storeSelectedPreset(value));
    };

    const handleModalClose = () => {
        setModalStatus({ show: false });
        setDeletePreset(false);
    };

    return (
        <>
            <Modals
                title={deletePreset ? t('Are you sure you want to delete this preset?') : t('Create new preset')}
                closeModal={handleModalClose}
                modalStatus={modalStatus}
                topColor="fff"
            >
                <PresetModalBody
                    storeSelectedPresetTrans={storeSelectedPresetTrans}
                    reportingObjectsById={reportingObjectsById}
                    currentOptionsKeys={currentOptionsKeys}
                    selectedLocationId={selectedLocationId}
                    currentOptions={currentOptions}
                    selectedPreset={selectedPreset}
                    currentModule={currentModule}
                    closeModal={handleModalClose}
                    deletePreset={deletePreset}
                    allMetrics={allMetrics}
                />
            </Modals>
            <Select
                outsideText={selectedPreset ? selectedPreset.text : t('No preset')}
                outsideSelected={selectedPreset && [selectedPreset]}
                handleSelect={handleSelect}
                labelText={t('Presets')}
                showFilter={false}
                options={options}
                dropdownWidth="sm"
                dropdownAlign="right"
                mode="single"
            />
        </>
    );
};

export default Preset;
