import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import cn from 'classnames';
import * as constants from '../../constants/constants.js';
import { DEFAULT_SEPARATOR_MODIFICATION_PREFIX } from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';

/**
 * Компонент для отображения элементов списка
 */
var List = function (_a) {
    var children = _a.children, _b = _a.listHorizontal, listHorizontal = _b === void 0 ? false : _b, props = __rest(_a, ["children", "listHorizontal"]);
    var mergedStyles = useStyles({ props: props, constants: constants });
    var listClassName = useMemo(function () {
        var _a;
        return cn.apply(void 0, __spreadArray(['organisms_list', (_a = {},
                _a["organisms_list".concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX, "horizontal")] = listHorizontal,
                _a)], mergedStyles, false));
    }, [listHorizontal, mergedStyles]);
    return jsx("div", __assign({ className: listClassName }, { children: children }), void 0);
};

export { List as default };
