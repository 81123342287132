import React from 'react';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { useTranslation } from 'react-i18next';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import BasicReportWidget from './widgets/BasicReportWidget/BasicReportWidget';
import DetailSelect from '../../../Selects/DetailSelect/DetailSelect';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import { additionalFieldsOptions, AdditionalFiledsIds, BASIC_REPORT_ADDITIONAL_FIELDS } from './constants/constants';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import { PresetsWrapper } from '../../../Wrappers/PresetsWrapper/styles';
import Preset from '../../../Preset/Preset';
import { ISettings } from '../../../UserSettings/interfaces';
import { OptionsWrapper } from './styles/styles';
import LayoutSelect from '../components/LayoutSelect/LayoutSelect';

const ReportList: React.FC = ({}) => {
    const { t } = useTranslation();

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ObjectsSelect />
                        <MetricsSelect />
                        <DetailSelect restrictedOptions={['15MIN']} />
                        <UniversalSelect
                            defaultSelected={additionalFieldsOptions?.filter(
                                (element) => element.id === AdditionalFiledsIds.AddId,
                            )}
                            name={BASIC_REPORT_ADDITIONAL_FIELDS}
                            labelText={t('Additional fields')}
                            options={additionalFieldsOptions}
                        />
                        <LayoutSelect />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'mainPeriod',
                                'selectedReportingObjectsIds',
                                'selectedMetrics',
                                'detail',
                                'pageLayout',
                                'basicReportAdditonalFields' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <BasicReportWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default ReportList;
