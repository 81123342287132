import { Stack } from '@fluentui/react';
import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    max-height: 60vh;
    overflow: auto;
    border: 1px solid #ebebeb;
`;

export const StyledStack = styled(Stack)`
    .ms-Stack-inner {
        padding: 10px 0 20px 0 !important;
    }
`;
