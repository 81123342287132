/**
 * Размеры кнопоки
 */
export enum ButtonSize {
    /**
     * Маленький размер
     */
    Sm = 'sm',
    /**
     * Средний размер
     */
    Md = 'md',
    /**
     * Большоя размер
     */
    Lg = 'lg'

}
