import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import CrossVisitsWidget from './widgets/CrossVisitsWidget/CrossVisitsWidget';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import { userSettingsReducerValues } from '../../../UserSettings/reducer';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import { CROSS_VISITS_MAPS_SETTINGS } from './constants/constants';
import { ISettings } from '../../../UserSettings/interfaces';
import { useSelector } from 'react-redux';
import usePrepareData from './hooks/usePrepareData';
import Preset from '../../../Preset/Preset';
import { Container, OptionsWrapper } from './styles';
import {
    crossVisitsMapsSettingsOptions,
    CrossVisitsMapsSettingsIds,
    ICrossVisitsSelectOption,
} from './constants/crossVisitsMapsSettings';

/**
 * Модуль для отображения кросс-визитов
 */
const CrossVisits = () => {
    // const { versionSelectOptions, selectedVersionOption } = useSelector(crossVisitsModuleReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);
    // const { t } = useTranslation();
    // const dispatch = useDispatch();
    usePrepareData();

    // const handleVersionSelect = (args: TSelectedOptions) => {
    //     dispatch(storeSelectedVersionId(Number(args[0].id)));
    // };

    return (
        <ModuleWrapper>
            <Container>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        {!!currentOptions[CROSS_VISITS_MAPS_SETTINGS]?.find(
                            (element: ICrossVisitsSelectOption) => element.id === CrossVisitsMapsSettingsIds.Comparison,
                        ) && <ComparePeriodSelect mode="single" />}

                        {/* {versionSelectOptions && (
                            <Select
                                outsideSelected={selectedVersionOption || []}
                                handleSelect={handleVersionSelect}
                                options={versionSelectOptions}
                                labelText={t('Versions')}
                                iconType="chevron-down"
                                showFilter={false}
                                mode="single"
                            />
                        )} */}
                        <UniversalSelect
                            options={crossVisitsMapsSettingsOptions}
                            name={CROSS_VISITS_MAPS_SETTINGS}
                            labelText={'Settings'}
                            iconType={'setting'}
                            defaultSelected={[{ id: 'showRatings', text: 'Show ratings' }]}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                CROSS_VISITS_MAPS_SETTINGS as keyof ISettings,
                                'comparePeriods',
                                'mainPeriod',
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <CrossVisitsWidget />
            </Container>
        </ModuleWrapper>
    );
};

export default CrossVisits;
