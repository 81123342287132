import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../../../General.reducer';
import { TMode } from '../../interfaces';
import { Wrapper, Text } from './styles';

interface IProps {
    /** Текущая дата */
    date: DateTime;
    /** Режим работы календаря */
    mode: TMode;
}

/**
 * Компонент для отображения шапки таблицы с отфарматированной выбранной датой
 */
const GridHeader: FC<IProps> = ({ date, mode }) => {
    const { t } = useTranslation();

    const formatedDate = useMemo(() => {
        let format = 'MMMM yyyy';
        switch (mode) {
            case 'years':
                format = 'yyyy';
                break;

            default:
                break;
        }

        return date.toFormat(format);
    }, [mode, date]);

    return (
        <Wrapper>
            <Text>{`${t(formatedDate.split(' ')[0])} ${formatedDate.split(' ')[1] || ''}`}</Text>
        </Wrapper>
    );
};

export default GridHeader;
