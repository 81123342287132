import { TTimeFreq } from '../lib/esm/components/ExportFunctions/interfaces';

export const detailOptions: { id: TTimeFreq; text: string }[] = [
    { id: null, text: 'Without detail' },
    { id: '15MIN', text: '15 min' },
    { id: 'H', text: 'Hours' },
    { id: 'D', text: 'Days' },
    { id: 'W-MON', text: 'Weeks' },
    { id: 'MS', text: 'Months' },
    { id: 'YS', text: 'Years' },
];

const minute = 1000 * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const mounth = week * 4;
const year = mounth * 12;

export const chartTickIntervalsByDetail = {
    '15MIN': minute * 15,
    H: hour,
    D: day,
    'W-MON': week,
    MS: mounth,
    YS: year,
};

export const chartTickOptionsByDetail = {
    '15MIN': { minutes: 15 },
    H: { hours: 1 },
    D: { days: 1 },
    'W-MON': { weeks: 1 },
    MS: { months: 1 },
    YS: { years: 1 },
};
