var hosts = {
    lambdaService: 'https://lambda.focustech.xyz',
    // adminService: 'https://admin-api.focustech.xyz',
    adminService: 'https://admin.focustech.xyz',
    mapService: 'https://map-service.focustech.xyz',
    geoService: 'https://geomapping.focustech.xyz',
    fpcService: 'https://fpct-metric-storage.focustech.xyz',
    // appBackendService: 'https://app-backend.focustech.xyz',
    appBackendService: 'http://localhost:1130',
};
/**
 * Объект с изначальными адресами для ручек.
 */
var getUrls = function (host) {
    if (host === void 0) { host = hosts; }
    return {
        AUTH_URL: "".concat(host.adminService, "/sso/ajax-token"),
        LOGOUT_URL: "".concat(host.adminService, "/admin/logout/"),
        CURRENT_URL: "".concat(host.adminService, "/sso/current?permissions=1"),
        PL_BY_ML_URL: "".concat(host.adminService, "/api/pl-by-ml"),
        CATEGORIES_URL: "".concat(host.adminService, "/api/categories"),
        LOCATIONS_URL: "".concat(host.lambdaService, "/structure-service/v1/project_locations/"),
        OBJECTS_URL: "".concat(host.lambdaService, "/structure-service/v1/cached_structure/"),
        // LOCATIONS_URL: `${host.mapService}/api/locations/`,
        LOCATION_CLONE_URL: "".concat(host.mapService, "/api/location-clone/"),
        PLANS_URL: "".concat(host.mapService, "/api/plans/"),
        LAYERS_URL: "".concat(host.mapService, "/api/layers/"),
        VERSIONS_URL: "".concat(host.mapService, "/api/versions/"),
        GEO_URL: "".concat(host.geoService, "/geo-mapping/"),
        PLACES_URL: "".concat(host.adminService, "/api/places/"),
        TENANTS_URL: "".concat(host.adminService, "/api/tenants/"),
        TENANT_2_PLACE_URL: "".concat(host.adminService, "/api/tenant-2-place/"),
        FPC_SENSOR_STATUS_URL: "".concat(host.fpcService, "/api/sensor-status/"),
        CABINET_PREFERENCES_URL: "".concat(host.appBackendService, "/api/user-settings/cabinet-preferences/"),
    };
};

export { getUrls, hosts };
