import WidgetTitleWrapper from '../../../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetTitle from '../../../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { AvailableReportingObjectsTypes } from '../../interfaces';
import { generatePivotItems, pivotItems } from './constants/pivotItems';
import { PivotItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { FC } from 'react';
import Pivot from '../../../../../../../Pivot/Pivot';

/**
 * Компонент для отображения хеддера виджета Кросс-визитов
 * @param storeSelectedReportingObjectTypeTrans Функция длы выбора типа отчетного объекта
 * @param existingReportingObjectsTypes Типы отчетных отбъектов, которые есть в приложении
 * @param selectedReportingObjectType Выбранный тип отчетного объекта
 */
const Header: FC<IProps> = ({
    storeSelectedReportingObjectTypeTrans,
    existingReportingObjectsTypes,
    selectedReportingObjectType,
}) => {
    const { t } = useTranslation();

    const onLinkClick = (item: PivotItem) => {
        item.props.itemKey &&
            storeSelectedReportingObjectTypeTrans(item.props.itemKey as AvailableReportingObjectsTypes);
    };

    return (
        <WidgetTitleWrapper>
            <WidgetTitle>{t('Cross-visits on the map')}</WidgetTitle>
            <Pivot widgetName="CrossVisits" onLinkClick={onLinkClick} selectedKey={selectedReportingObjectType}>
                {generatePivotItems(t)
                    ?.filter((element) => existingReportingObjectsTypes.includes(element.itemKey))
                    .map((element) => (
                        <PivotItem
                            key={element.itemKey + Math.random()}
                            headerText={element.headerText}
                            itemKey={element.itemKey}
                        />
                    ))}
            </Pivot>
        </WidgetTitleWrapper>
    );
};

export default Header;
