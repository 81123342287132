import styled from "styled-components/macro";

export const Container = styled.div`
    max-width: 1230px;
    padding-right: var(--space-md);
    padding-left: var(--space-md);
    padding-bottom: var(--space-md);
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
`

// export const Container = styled.div`
//     padding-right: var(--space-md);
//     padding-left: var(--space-md);
//     padding-bottom: var(--space-md);
// `;
