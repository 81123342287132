import { IAverageIntersection, TSelectedOptions } from '../../../../../../../lib/esm/components';
import { ILayer, IPlan, IReportingObject } from '../../../../../../../General.interfaces';

import { IColorsByMarker } from '../../../components/Floors/interfaces';
import { ISelectedOption } from '../../../../../../../lib/esm/components/Select/configurations';
import { ResponseStatus } from '../../../../../../../tools/API/constants';

export interface ICrossVisitsWidgetReducerState {
    selectedReportingObjectType: AvailableReportingObjectsTypes;
    rawComparisonAverageIntersectionsData: TRawComparisonAverageIntersectionsData;
    rawAverageIntersectionsData: TRawAverageIntersectionsData;
    selectedReportingObjectMarker: string | null;
    selectedGroupOfZones: ISelectedOption | null;
    groupOfZonesSelectOptions: TSelectedOptions;
    contraReportingObjectsIds: number[] | null;
    colorsByMarker: IColorsByMarker | null;
    mainReportingObjectId: number | null;
    zonesByGroup: IZonesByGroup | null;
    availableMarkers: string[] | null;
    extendedLayers: ILayer[] | null;
    rawLayersData: TRawLayersData;
    rawPlansData: TRawPlansData;
}

export interface IZonesByGroup {
    [groupMarker: string]: IReportingObject[];
}

export type TRawAverageIntersectionsData = null | IAverageIntersection[] | { status: ResponseStatus; message: string };
export type TRawComparisonAverageIntersectionsData =
    | null
    | IAverageIntersection[]
    | { status: ResponseStatus; message: string };
export type TRawLayersData = null | ILayer[] | { status: ResponseStatus; message: string };
export type TRawPlansData = null | IPlan[] | { status: ResponseStatus; message: string };

export enum AvailableReportingObjectsTypes {
    Tenant = 'tenant',
    Floor = 'floor',
    Zone = 'zone',
}
