import { ISeriesPoint, TSeries } from '../../../../../../Charts/Dynamics/interfaces';

import { DateTime } from 'luxon';
import { IChartDetailing } from '../../../../../../../../constants/chartDetailing';
import { ISelectedChartType } from '../interfaces';
import { MAIN_PERIOD_ALIAS } from '../constants/constants';
import { TFunction } from 'i18next';
import { TMetricResponse } from '../../../../../../../../General.interfaces';
import generateId from '../../../../../../../../tools/generateId';
import getDateFormatByChartDetailing from './getDateFormatByChartDetailing';
import { stringDate } from '../../../../../../../../tools/Strings/stringDate';
import { TLang } from '../../../../../../SideBar/configurations';

/**
 * Функция для получения серий графика
 * @param args аргументы
 * @returns массив серий для графика
 */
const generateSeries = (args: IArgs): TSeries[] => {
    const { selectedChartDetailing, selectedChartType, rawMetricsData, allMetrics, lang, t } = args;
    const series: TSeries[] = [];
    rawMetricsData.forEach((metric, metricIndex) => {
        metric.forEach((metricData) => {
            let name = '';
            switch (selectedChartType.id) {
                case 'metrics':
                    name =
                        allMetrics?.find((element) => element.id === metricData.context.metric)?.text ||
                        metricData.context.metric;
                    break;
                case 'objects':
                    name = metricData.context.data_objects[0].name;
                    break;

                default:
                    name = stringDate(
                        {
                            dateFrom: metricData.context.time_range[0],
                            dateTo: metricData.context.time_range[1],
                        },
                        lang,
                    );
                    break;
            }
            const pointDateFormat = getDateFormatByChartDetailing(selectedChartDetailing, false, t);

            const seriesPoints: ISeriesPoint[] = metricData.items.map((element) => {
                return {
                    name: stringDate(element.time, lang, pointDateFormat.preset, pointDateFormat.token),
                    x: DateTime.fromISO(element.time).toMillis(),
                    y: element.value,
                };
            });
            const newSeries: TSeries = {
                id: `${metricData.context.time_range[0]}__${metricData.context.time_range[1]}__${metricData.context.metric}__${metricData.context.data_objects[0].name}__${metricIndex}}`,
                yAxis: selectedChartType.id === 'metrics' ? metricIndex : undefined,
                isMain: metricData.context.alias === MAIN_PERIOD_ALIAS,
                data: seriesPoints,
                name,
                type:
                    seriesPoints.length === 1
                        ? 'column'
                        : selectedChartType.id === 'periods' && metricData.context.alias === MAIN_PERIOD_ALIAS
                        ? 'areaspline'
                        : 'spline',
            };
            series.push(newSeries);
        });
    });
    return series;
};

export default generateSeries;

interface IArgs {
    /**
     * Все метрики из GeneralReducer
     */
    allMetrics: Array<{ id: string; text: string }>;
    /**
     * Выбранная детализация для графика
     */
    selectedChartDetailing: IChartDetailing;
    /**
     * Выбранный тип графика
     */
    selectedChartType: ISelectedChartType;
    /**
     * Сырые данные метрик
     */
    rawMetricsData: TMetricResponse[];
    /**
     * Функция перевода
     */
    t: TFunction;
    /**
     * Выбранный язык приложения
     */
    lang: TLang;
}
