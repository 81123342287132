import { IExtendedReportingObject, IExtendedReportingObjectData, IReducerState } from './interfaces';
import { IReportingObject, TLoadingData } from '../../../../../../../General.interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../../../../../store';
import { cloneDeep } from 'lodash';

export const LOADING_DATA = { status: 'Loading', message: 'Loading...' };

const initialState: IReducerState = {
    rawMetricsData: LOADING_DATA,
    dataRefetchObject: {},
    updateChartByReportingObjectsCounter: 0,
    shoudUpdateAllReportingObjects: true,
    updateAllReportingObjectsCounter: 0,
    extendedReportingObjectsById: {},
};

const Performance_YoY_YoY_Widget_Reducer = createSlice({
    name: 'Performance_YoY_YoY_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Запись расширенных отчетных отбъектов
         */
        storeExtendedReportingObjectsById: (state, action: PayloadAction<IReportingObject[]>) => {
            const copy = cloneDeep(state.extendedReportingObjectsById);
            const shoudUpdateAllReportingObjects = cloneDeep(state.shoudUpdateAllReportingObjects);
            const objectsById = action.payload.reduce(
                (
                    acc: {
                        [reportingObjectId: string]: IExtendedReportingObject;
                    },
                    value,
                ) => {
                    acc[value.id] = {
                        reportingObjectInfo: value,
                        chartOptionsGeneratorSettings: shoudUpdateAllReportingObjects
                            ? undefined
                            : copy[value.id]?.chartOptionsGeneratorSettings
                            ? { ...copy[value.id].chartOptionsGeneratorSettings!, showSeriesAnimation: false }
                            : undefined,
                        rawMetricsByDate: shoudUpdateAllReportingObjects
                            ? LOADING_DATA
                            : copy[value.id]?.rawMetricsByDate || LOADING_DATA,
                    };
                    return acc;
                },
                {},
            );
            state.extendedReportingObjectsById = objectsById;
        },

        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },

        /**
         * Обновление отчетных объектов расширенными данными
         */
        updateReportingObjectsWithExtendedData: (state, action: PayloadAction<IExtendedReportingObjectData>) => {
            const reportingObjects = cloneDeep(state.extendedReportingObjectsById);
            Object.keys(action.payload).forEach((id) => {
                reportingObjects[id] = {
                    ...reportingObjects[id],
                    ...action.payload[id],
                };
            });
            state.shoudUpdateAllReportingObjects = false;
            state.extendedReportingObjectsById = reportingObjects;
        },

        /**
         * Увеличение счетчика для обновления диграмм, при изменении отчетных объектов
         */
        increaseChartByReportingObjectsCounter: (state, _: PayloadAction<null>) => {
            state.updateChartByReportingObjectsCounter += 1;
        },

        /**
         * Изменение флага обновления всех отчетных объектов
         */
        toggleUpdateAllReportingObjects: (state, action: PayloadAction<boolean>) => {
            state.shoudUpdateAllReportingObjects = action.payload;
        },

        /**
         * Увеличение счетчика , отвечающего за обновление отчетных объектов
         */
        increaseAllReportingObjectsCounter: (state, _: PayloadAction<null>) => {
            state.updateAllReportingObjectsCounter += 1;
        },

        /**
         * Запись сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TLoadingData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetYearOverYearWidgetReducer: () => initialState,
    },
});

export const {
    increaseChartByReportingObjectsCounter,
    updateReportingObjectsWithExtendedData,
    increaseAllReportingObjectsCounter,
    storeExtendedReportingObjectsById,
    toggleUpdateAllReportingObjects,
    reloadWidget,
    resetYearOverYearWidgetReducer,
    storeRawMetricsData,
} = Performance_YoY_YoY_Widget_Reducer.actions;

export const Performance_YoY_YoY_Widget_Reducer_Values = (state: RootState) => state.Performance_YoY_YoY_Widget_Reducer;

export default Performance_YoY_YoY_Widget_Reducer.reducer;
