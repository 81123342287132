import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
    IGeneralReducerState,
    IReportingObject,
    IWindowSize,
    IUser,
    TStructures,
    ICategory,
    IProjectCategory,
    IStoreObjectsArgs,
    ITenant2ZoneRelation,
    IMetric,
} from './General.interfaces';

import { cloneDeep } from 'lodash';
import { ILocation, TLang, TMenu } from './cabinet/components/SideBar/configurations';
import { fakeNames } from './constants/fakeNames';
import { IAccountParams } from './cabinet/components/UserSettings/interfaces';
import { DateTime } from 'luxon';

const defaultAppBackendHost = 'https://app-backend.focustech.xyz';

const initialState: IGeneralReducerState = {
    currentModule: 'Performance:Dashboard',
    categoriesReload: 0,
    appBackendHost: defaultAppBackendHost,
    user: null,
    signalBackXLS: null,
    token: '',
    allMetrics: [],
    locations: [],
    structures: null,
    sideBarMenu: [],
    modulesByPaths: {},
    mainWindowSize: { width: 0, height: 0 },
    selectedLocationId: null,
    selectedProjectId: null,
    timeZone: null,
    lang: 'en',
    src: { reportingObjects: [], categories: [], projectCategories: [], dataObj2ProjectCategory: [] },
    cfg: {
        reportingObjectsByType: {},
        reportingObjectsByTypeAndMarker: {},
        locationsById: {},
        reportingObjectsById: {},
        tenant2ZoneByTenantId: {},
        tenant2FloorByTenantId: {},
    },
};

export const GeneralReducer = createSlice({
    name: 'GeneralReducer',
    initialState,

    reducers: {
        storeToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },

        generateBackXLS: (state, action: PayloadAction<{ reportAlias: string }>) => {
            state.signalBackXLS = action.payload;
        },

        storeAllMetrics: (state, action: PayloadAction<Array<IMetric>>) => {
            state.allMetrics = action.payload;
        },

        storeLocations: (state, action: PayloadAction<ILocation[]>) => {
            const { selectedLocationId, cfg } = cloneDeep(state);

            // const newLocations = action.payload.map((item) => {
            //     if (item.id === 201) {
            //         return { ...item, name: 'Demo2' };
            //     } else {
            //         return item;
            //     }
            // });

            state.locations = action.payload;
            const locationsById = Object.fromEntries(action.payload.map((item) => [item.id, item]));

            state.cfg = { ...cfg, locationsById };
            if (!selectedLocationId) {
                state.selectedLocationId = action.payload[0].id;
                state.selectedProjectId = action.payload[0].project_id;
                state.timeZone = action.payload[0].timezone;
            } else {
                state.timeZone = locationsById[selectedLocationId].timezone;
                state.selectedProjectId = locationsById[selectedLocationId].project_id;
            }
        },

        storeObjects: (state, action: PayloadAction<IStoreObjectsArgs>) => {
            const { src, cfg } = cloneDeep(state);

            let { reportingObjects, categories, projectCategories, dataObj2ProjectCategory } = action.payload;

            reportingObjects = reportingObjects.map((item, i) => {
                // console.log(i, item);

                return {
                    ...item,
                    block_type: 'reporting_object',
                    // name: fakeNames[i],
                };
            });

            const modifyCategories = (categories: Array<ICategory | IProjectCategory>, block_type: string) => {
                const result: IReportingObject[] = categories
                    ?.filter((item) => item.parent_id)
                    .map((item) => {
                        const parentName = categories?.find((cat) => item.parent_id === cat.id)?.object_name || '';
                        return {
                            ...item,
                            object_type: parentName,
                            block_type,
                            pl_id: 0,
                            object_params: {
                                marker: '',
                            },
                            external_ids: [
                                {
                                    data_source: '',
                                    external_id: '',
                                },
                            ],
                            is_active: true,
                            marker: '',
                            object_name: item.object_name,
                            name: item.object_name,
                        };
                    });

                return result;
            };

            const modCategories = modifyCategories(categories, 'category');
            const modProjectCategories = modifyCategories(projectCategories, 'project_category');

            reportingObjects.push(...modCategories);
            reportingObjects.push(...modProjectCategories);

            state.src = {
                ...src,
                reportingObjects,
                categories,
                projectCategories,
                dataObj2ProjectCategory,
            };

            const reportingObjectsByTypeAndMarker = {};
            const reportingObjectsByType = {};
            const reportingObjectsById = {};

            reportingObjects
                ?.filter(
                    (item) => item.block_type !== 'category',
                    // && item.block_type !== 'project_category'
                )
                .forEach((obj) => {
                    if (reportingObjectsByType[obj.object_type]) {
                        reportingObjectsByType[obj.object_type] = [...reportingObjectsByType[obj.object_type], obj];
                    } else {
                        reportingObjectsByType[obj.object_type] = [obj];
                    }
                    reportingObjectsByTypeAndMarker[`${obj.object_type}:${obj.marker}`] = obj;
                });

            reportingObjects.forEach((obj) => {
                reportingObjectsById[obj.id] = obj;
            });

            state.cfg = { ...cfg, reportingObjectsByType, reportingObjectsById, reportingObjectsByTypeAndMarker };
        },

        changeLang: (state, action: PayloadAction<TLang>) => {
            state.lang = action.payload;
        },

        reloadCategories: (state, action: PayloadAction) => {
            state.categoriesReload = cloneDeep(state.categoriesReload) + 1;
        },

        storeSelectedLocationId: (state, action: PayloadAction<number>) => {
            if (cloneDeep(state.selectedLocationId) !== action.payload) {
                const { cfg, src } = cloneDeep(state);
                state.src = {
                    reportingObjects: [],
                    categories: [],
                    projectCategories: [],
                    dataObj2ProjectCategory: [],
                };
                state.cfg = {
                    ...cfg,
                    reportingObjectsByType: {},
                    reportingObjectsByTypeAndMarker: {},
                    reportingObjectsById: {},
                    tenant2ZoneByTenantId: {},
                    tenant2FloorByTenantId: {},
                };

                state.selectedLocationId = action.payload;
                const timeZone = cloneDeep(state.cfg.locationsById)[action.payload]?.timezone;
                const selectedProjectId = cloneDeep(state.cfg.locationsById)[action.payload]?.project_id;

                if (timeZone !== undefined) {
                    state.timeZone = timeZone;
                }
                if (selectedProjectId !== undefined) {
                    state.selectedProjectId = selectedProjectId;
                }
            }
        },

        changeCurrentModule: (state, action: PayloadAction<string>) => {
            state.currentModule = action.payload;
        },

        storeMainWindowSize: (state, action: PayloadAction<IWindowSize>) => {
            state.mainWindowSize = action.payload;
        },

        storeCategories: (state, action: PayloadAction<{ [x: string]: any[] }>) => {
            const { src } = cloneDeep(state);
            state.src = { ...src, ...action.payload };
        },

        resetReportingObjects: (state, action: PayloadAction) => {
            const { src, cfg } = cloneDeep(state);

            state.src = {
                ...src,
                reportingObjects: [],
                categories: [],
                projectCategories: [],
                dataObj2ProjectCategory: [],
            };
            state.cfg = {
                ...cfg,
                reportingObjectsByType: {},
                reportingObjectsByTypeAndMarker: {},
                reportingObjectsById: {},
            };
        },

        storeStructures: (state, action: PayloadAction<TStructures>) => {
            const structures = action.payload;
            if (structures) {
                try {
                    // Переименовываем структуры pl_structure/pl201/core/relations_tenant2place.json ---> relations_tenant2place
                    const newStructures: TStructures = {};
                    Object.keys(structures).forEach((key) => {
                        const newKey = key.split('/')[key.split('/').length - 1].split('.')[0];
                        newStructures[newKey] = structures[key];
                    });

                    const tenant2ZoneByTenantId = newStructures.relations_tenant2zone?.reduce(
                        (acc, value: ITenant2ZoneRelation) => {
                            if (!acc[value.tenant_id]) acc[value.tenant_id] = [value];
                            else acc[value.tenant_id].push(value);
                            return acc;
                        },
                    );

                    const tenant2FloorByTenantId = newStructures.relations_tenant2floor?.reduce(
                        (acc, value: ITenant2ZoneRelation) => {
                            if (!acc[value.tenant_id]) acc[value.tenant_id] = [value];
                            else acc[value.tenant_id].push(value);
                            return acc;
                        },
                    );

                    if (tenant2FloorByTenantId) state.cfg.tenant2FloorByTenantId = tenant2FloorByTenantId;
                    if (tenant2ZoneByTenantId) state.cfg.tenant2ZoneByTenantId = tenant2ZoneByTenantId;

                    state.structures = newStructures;
                } catch (error) {
                    console.log("CAN'T CONVERT STRUCTURES");
                }
            } else {
                state.structures = null;
            }
        },

        storeUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
        },

        changeAppBackendHost: (state, action: PayloadAction<string>) => {
            state.appBackendHost = action.payload;
        },

        storeSideBarMenu: (state, action: PayloadAction<TMenu>) => {
            state.sideBarMenu = action.payload;
            const modulesByPaths = {};
            action.payload.forEach((item) => {
                if (item.type === 'section') {
                    item.subsections?.forEach((sub) => {
                        modulesByPaths[sub.servicePath] = `${item.names.en}:${sub.names.en}`;
                    });
                } else if (item.type === 'subsection') {
                    modulesByPaths[item.servicePath || 'tt'] = `none:${item.names.en}`;
                }
            });
            state.modulesByPaths = modulesByPaths;
        },
    },
});

export const {
    changeAppBackendHost,
    storeUser,
    storeCategories,
    resetReportingObjects,
    storeToken,
    storeStructures,
    storeAllMetrics,
    reloadCategories,
    storeObjects,
    changeCurrentModule,
    storeLocations,
    storeMainWindowSize,
    generateBackXLS,
    storeSideBarMenu,
    storeSelectedLocationId,
    changeLang,
} = GeneralReducer.actions;

export const generalReducerValues = (state: RootState) => state.GeneralReducer;

export default GeneralReducer.reducer;
