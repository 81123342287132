import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import generateAvailableYears from '../../../../../tools/generateAvailableYears';
import { yoyTableSettingsOptions, YOY_TABLE_SETTINGS, YOY_YEARS_SETTINGS } from './constants/constants';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import YearOverYearWidget from './widgets/YearOverYear/YearOverYear';
import { ISettings } from '../../../UserSettings/interfaces';
import { OptionsWrapper } from './styles/styles';
import { useTranslation } from 'react-i18next';
import Preset from '../../../Preset/Preset';
import React, { useEffect } from 'react';
import { useWidgetCurrentOptions } from '../../../../../tools/useWidgetCurrentOptions';
import { useDispatch } from 'react-redux';
import { storeUniversalOptions } from '../../../UserSettings/reducer';

/**
 * Модуль Год к Году
 */
const YearOverYear: React.FC = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Year over year');
    const availableYears = generateAvailableYears();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localCurrentOptions?.selectedLocationId && localCurrentOptions?.currentModule) {
            dispatch(
                storeUniversalOptions({
                    selectedOptions: [yoyTableSettingsOptions[1]],
                    locationId: localCurrentOptions.selectedLocationId,
                    module: localCurrentOptions.currentModule,
                    name: YOY_TABLE_SETTINGS,
                }),
            );
        }
    }, [localCurrentOptions?.[YOY_YEARS_SETTINGS]]);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <ObjectsSelect maxSelected={5} />
                        <MetricsSelect maxSelected={1} />
                        <UniversalSelect
                            defaultSelected={availableYears.slice(0, 2)}
                            options={availableYears}
                            name={YOY_YEARS_SETTINGS}
                            labelText={t('Years')}
                            minSelected={1}
                        />
                        <UniversalSelect
                            minSelected={localCurrentOptions?.[YOY_YEARS_SETTINGS]?.length === 1 ? 1 : undefined}
                            maxSelected={localCurrentOptions?.[YOY_YEARS_SETTINGS]?.length === 1 ? 1 : undefined}
                            options={yoyTableSettingsOptions}
                            labelText={t('Table settings')}
                            name={YOY_TABLE_SETTINGS}
                            iconType={'setting'}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    'selectedReportingObjectsIds',
                                    YOY_YEARS_SETTINGS,
                                    YOY_TABLE_SETTINGS,
                                    'selectedMetrics',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <YearOverYearWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default YearOverYear;
