import { FC, Fragment, useContext, useMemo } from 'react';

import FloorsContext from '../../../../Floors/context';
import { Group } from 'react-konva';
import { IProps } from './interfaces';
import { ObjectLabel } from '../../../../../../../ObjectLabel/ObjectLabel';
import { TLayerData } from '../../../../../../../../../General.interfaces';
import { findPolygonCenter } from '../../../../../../../../../tools/findPolygonCenter';

/**
 * Компонент для отображения лейблов
 * @param transformedLayer Трансформированный слой
 */
const Labels: FC<IProps> = ({ transformedLayer }) => {
    const {
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectType,
        labelAdditionalText,
        selectedMarker,
        colorsByMarker,
        hoveredMarker,
        showAllLabels,
        onMouseOver,
        stageScale,
    } = useContext(FloorsContext);

    const labels = useMemo(() => {
        if (transformedLayer) {
            return transformedLayer.data.map((element: TLayerData) => {
                const marker = element['marker'] || element['zone_marker'] || String(transformedLayer.floor);
                const coordinates = element['coords'] || element['passLine']['coordinates'];
                const labelCoordinates = findPolygonCenter(coordinates['coordinates']);

                const name =
                    reportingObjectsByTypeAndMarker[`${selectedReportingObjectType}:${marker}`]?.['name'] ||
                    colorsByMarker?.[marker]?.name ||
                    marker;

                const showLabel = showAllLabels || selectedMarker === marker || hoveredMarker === marker;

                return (
                    <Fragment key={`${name}-${transformedLayer.floor}-${Math.random()}`}>
                        {showLabel && (
                            <ObjectLabel
                                value={
                                    colorsByMarker?.[marker]?.value
                                        ? String(colorsByMarker?.[marker].value.toFixed(2)) +
                                          (labelAdditionalText || '')
                                        : undefined
                                }
                                stageScale={stageScale.stageScale}
                                onMouseOver={onMouseOver}
                                x={labelCoordinates?.x}
                                y={labelCoordinates?.y}
                                name={String(name)}
                            />
                        )}
                    </Fragment>
                );
            });
        }

        return null;
    }, [transformedLayer, hoveredMarker, showAllLabels, stageScale]);

    return <Group>{labels}</Group>;
};

export default Labels;
