import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePrepareEmptyStructure from './usePrepareEmptyStructure';
import { cloneDeep, difference, omit } from 'lodash';
import { generalReducerValues } from '../../../General.reducer';
import { cabinetPreferencesValues } from '../../../cabinet/components/CabinetPreferences/reducer';
import { useRequestMetrics } from '../useRequestMetrics';
import { TMetricResponse } from '../../../General.interfaces';
import { IRequestMetricsArgs } from '../../../lib/esm/components';
import prepareRequestsArgs from './prepareRequestsArgs';
import { handleServerResponse, TServerResponse } from '../handleServerResponse';
import responseAnalyzer from '../responseAnalyzer';
import generateId from '../../generateId';
import { IEmptyStructure, IUseParallelFetchinhArgs } from './interfaces';
import { ResponseStatus } from '../constants';

/**
 * Кастомный зук для получения данных
 */
const useParallelFetching = (args: IUseParallelFetchinhArgs) => {
    const { moduleName, dataRefetchObject, reportingObjectsIds } = args;
    const { token } = useSelector(generalReducerValues);
    const [resolvedRequests, setResolvedRequests] = useState<string[]>([]);
    const [rawData, setRawData] = useState<IEmptyStructure | null>(null);
    const { emptyStructure } = usePrepareEmptyStructure({
        setResolvedRequests,
        setRawData,
        moduleName,
        dataRefetchObject,
        reportingObjectsIds,
    });
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics(requestHandler);
    const [responses, setResponses] = useState<any>([]);
    const dispatch = useDispatch();
    const ALIAS_SEPARATOR = '∧';

    /** Получение объекта rawData */
    useEffect(() => {
        if (emptyStructure) {
            let rawDataCopy = cloneDeep(rawData);

            /** Получение ключей для дальнейшего сравнения и удаления лишних */
            const emptyStructureKeys = Object.keys(emptyStructure);
            const rawDataKeys = Object.keys(rawDataCopy || {});

            /**
             * Если был изменен основной период, то данные перезаписываются, иначе, просто добавляются новые периоды и объекты
             */
            Object.keys(emptyStructure).forEach((key) => {
                if (rawDataCopy && resolvedRequests.length) {
                    if (!rawDataCopy[key] && emptyStructure[key]) {
                        rawDataCopy[key] = emptyStructure[key];
                    }
                } else {
                    rawDataCopy = cloneDeep(emptyStructure);
                }
            });

            /** Получение элементов для удаления */
            const keysDifference = difference(rawDataKeys, emptyStructureKeys);

            keysDifference.forEach((element) => {
                rawDataCopy = omit(rawDataCopy, element);
            });

            /** Обновление обработанных запросов (удаление ненужных) */
            const filteredResolvedRequests = resolvedRequests?.filter((element) => !keysDifference.includes(element));
            setResolvedRequests(filteredResolvedRequests);

            /** Запись новых данных */
            setRawData(rawDataCopy);
        }
    }, [emptyStructure]);

    /** Обработка ответа с сервера */
    useEffect(() => {
        if (responses.length) {
            responses.forEach((response: any) => {
                if (
                    response.response.status !== ResponseStatus.Error &&
                    response.response.status !== ResponseStatus.NoData
                ) {
                    (response.response as TMetricResponse[]).forEach((element) => {
                        element.forEach((element) => {
                            const rawDataKey = `${element.context.data_objects[0].id}:${element.context.alias}`;
                            if (!resolvedRequests.includes(rawDataKey)) {
                                setRawData((prevState) => {
                                    const copy = cloneDeep(prevState);

                                    if (copy?.[rawDataKey]) {
                                        const prevValue = copy[rawDataKey];

                                        copy[rawDataKey] = {
                                            ...prevValue,
                                            isFetching: false,
                                            isError: false,
                                            data: element,
                                        };
                                    }

                                    return copy;
                                });
                                /** Запись обработанного ответа */
                                setResolvedRequests((prevState) => {
                                    const copy = cloneDeep(prevState);
                                    copy.push(rawDataKey);
                                    return copy;
                                });
                            }
                        });
                    });
                } else {
                    const rawDataKeys = Object.keys(rawData || {});

                    /** Получение ключей, у которых небыло получено ответа */
                    const keysDifference = difference(rawDataKeys, resolvedRequests);
                    const ids = keysDifference.map((element) => element.split(':')[0]);

                    ids.forEach((id) => {
                        setRawData((prevState) => {
                            const copy = cloneDeep(prevState);
                            const key = `${id}:${response.response.alias}`.split(ALIAS_SEPARATOR)[0];
                            if (copy?.[key]) {
                                const prevValue = copy[key];

                                copy[key] = {
                                    ...prevValue,
                                    isFetching: false,
                                    isError: true,
                                    data: [],
                                };
                            }

                            return copy;
                        });
                    });
                }
                /** Удаление обработанного ответа */
                setResponses((prevState: any) => {
                    return prevState?.filter((element: any) => element.id !== response.id);
                });
            });
        }
    }, [responses]);

    /** Получение сырых данных */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (emptyStructure) {
            const { reportingObjectsIds, metricIds, periods } = prepareRequestsArgs(emptyStructure);

            const requests: IRequestMetricsArgs[] = [];

            metricIds.forEach((metric) => {
                Object.keys(periods).forEach((alias) => {
                    const availableReportingObjectsIds: number[] = [];

                    reportingObjectsIds.forEach((id) => {
                        if (!resolvedRequests.includes(`${id}:${metric}:${alias}`)) {
                            availableReportingObjectsIds.push(id);
                        }
                    });

                    if (availableReportingObjectsIds.length) {
                        const period = periods[alias];
                        const request = {
                            time_range: [period.dateFrom, period.dateTo],
                            obj_ids: availableReportingObjectsIds || [],
                            alias: `${metric}:${alias}`,
                            metric_level: metricLevel,
                            object_aggregation: false,
                            time_freq: null,
                            metric,
                            signal,
                            token,
                        };
                        requests.push(request);
                    }
                });
            });

            requests.forEach((request) => {
                fetchData([request]).then((res: TServerResponse) => {
                    handleServerResponse({
                        responseHandlerFoo,
                        responseAnalyzer,
                        dispatch,
                        res,
                    });
                });
            });
        }

        return () => {
            controller.abort();
        };
    }, [emptyStructure]);

    /** Функция для обработки ответа с сервера и изменения объекта rawData */
    const responseHandlerFoo = (response: TMetricResponse[]) => {
        setResponses((prevState: any) => {
            if (prevState) {
                return [...prevState, { id: generateId(), response }];
            }
            return [{ id: generateId(), response }];
        });
    };

    /** Функция для установки флага загрузки на запросах */
    function requestHandler(request: IRequestMetricsArgs) {
        let ids: number[] = [];

        if (request.alias) {
            const alias = request.alias.split(ALIAS_SEPARATOR)[0];
            if (request.alias.split(ALIAS_SEPARATOR)[2]) {
                ids.push(Number(request.alias.split(ALIAS_SEPARATOR)[2]));
            }
            ids = [...ids, ...request.obj_ids];
            ids.forEach((id) => {
                setRawData((prevState) => {
                    const copy = cloneDeep(prevState);
                    const key = `${id}:${alias}`;

                    if (copy?.[key]) {
                        const prevValue = copy[key];

                        copy[key] = {
                            ...prevValue,
                            isFetching: true,
                            isError: false,
                            data: [],
                        };
                    }

                    return copy;
                });
            });
        }
    }

    return { rawData };
};

export default useParallelFetching;
