import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getChartDataDetailing, { IChartDetailing } from '../../../../../../../../constants/chartDetailing';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { paymentAnalysisMetricsSelect, subsetsOfSalesMetricsSelect } from '../../../constants/contsants';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../reducer';
import {
    Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values,
    storeChartDynamicOptions,
    storeChartOptions,
    storeSalesBySubset,
} from '../reducer';
import generateChartOptions from '../tools/generateChartOptions';
import generateSalesBySubset from '../tools/generateSalesBySubset';

/**
 * Кастомный хук для подготовки данных
 */
const usePrepareData = () => {
    const { rawMetricsData, salesBySubset, selectedChartType } = useSelector(
        Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values,
    );
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { lang } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Получение продаж по ключу производной */
    useEffect(() => {
        if (Array.isArray(rawMetricsData)) {
            const result = generateSalesBySubset({ rawMetricsData });
            dispatch(storeSalesBySubset(result));
        }
    }, [rawMetricsData]);
    /**Подготовка данных для графиков */
    useEffect(() => {
        if (
            salesBySubset &&
            localCurrentOptions?.[subsetsOfSalesMetricsSelect] &&
            localCurrentOptions?.[paymentAnalysisMetricsSelect] &&
            localCurrentOptions?.['detail']
        ) {
            const chartDynamicOptions = generateChartOptions({
                selectedSubsets: selectedChartType,
                selectedMetric: localCurrentOptions[paymentAnalysisMetricsSelect][0],
                salesBySubset,
                isDynamic: true,
                lang,
                selectedChartDetailing: localCurrentOptions['detail'] as IChartDetailing,
                t,
            });

            dispatch(storeChartDynamicOptions(chartDynamicOptions));
            const chartOptions = generateChartOptions({
                selectedSubsets: selectedChartType,
                selectedMetric: localCurrentOptions[paymentAnalysisMetricsSelect][0],
                salesBySubset,
                isDynamic: false,
                lang,
                selectedChartDetailing: localCurrentOptions['detail'] as IChartDetailing,
                t,
            });

            dispatch(storeChartOptions(chartOptions));
        }
    }, [salesBySubset, t, lang, localCurrentOptions?.['detail']]);
};

export default usePrepareData;
