import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { TTimeFreq } from '../../../../../../../../components/ExportFunctions/interfaces';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import usePrepareData from './usePrepareData';
import { Reports_Basic_Widget_Reducer_Values, storeRawMetricsData } from '../reducer';

/** кастомный хук для получения данных */
export const useGetMetrics = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Basic');
    const { downloadSignal } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const { token } = useSelector(generalReducerValues);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePrepareData();

    useEffect(() => {
        if (
            !localCurrentOptions?.mainPeriod ||
            !localCurrentOptions?.mainDateRanges ||
            !localCurrentOptions?.detail ||
            !localCurrentOptions?.selectedMetrics ||
            !localCurrentOptions?.selectedReportingObjectsIds
        )
            return;

        const { mainPeriod, selectedReportingObjectsIds, detail, selectedMetrics } = localCurrentOptions;

        const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriod?.id);
        const time_range = [mainDateRange.period.dateFrom, mainDateRange.period.dateTo];
        const time_freq: TTimeFreq = detail.id as TTimeFreq;

        const controller = new AbortController();
        const signal = controller.signal;

        const requests = selectedMetrics.map((metric) => {
            return {
                signal,
                token,
                alias: null,
                metric,
                metric_level: metricLevel,
                obj_ids: selectedReportingObjectsIds,
                object_aggregation: false,
                time_range,
                time_freq,
            };
        });

        dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

        fetchData(requests).then((responses: TServerResponse) => {
            /** Обработка ответа для метрик */
            handleServerResponse({
                responseAnalyzer: responseAnalyzer,
                success: storeRawMetricsData,
                error: storeRawMetricsData,
                res: responses,
                dispatch,
            });
        });

        return () => controller.abort();
    }, [downloadSignal]);
};
