import { __rest } from '../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { AccordionWrapper } from './styles/styles.js';
import AccordionItem from './components/AccordionItem.js';

var Accordion = function (_a) {
    var data = _a.data, allowMultipleExpanded = _a.allowMultipleExpanded, _b = _a.icon, icon = _b === void 0 ? 'chevron-right' : _b; __rest(_a, ["data", "allowMultipleExpanded", "icon"]);
    var _c = useState(null), openedId = _c[0], setOpenedId = _c[1];
    function handleHeaderClick(id) {
        if (!allowMultipleExpanded) {
            setOpenedId(id);
        }
    }
    var items = data.map(function (item, index) {
        var id = index;
        var isOpened = openedId === id;
        return (jsx(AccordionItem, { icon: icon, isOpened: isOpened, id: id, label: item.label, content: item.content, handleHeaderClick: handleHeaderClick }, index + Math.random()));
    });
    return jsx(AccordionWrapper, { children: items }, void 0);
};

export { Accordion as default };
