import styled from 'styled-components/macro';
import { TitleWrapper } from '../../../../../Wrappers/WidgetTitleWrapper/styles';

export const WidgetWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const WidgetTitleWrapper = styled(TitleWrapper)`
    padding-left: var(--space-md);
    padding-right: var(--space-md);
    margin: 0;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-grow: 0;
    height: 100%;
`;

export const MapWrapper = styled.div`
    padding-right: var(--space-md);
    padding-top: var(--space-md);
    padding-left: var(--space-md);
    padding-bottom: var(--space-md);
    /* overflow: auto; */
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
`;
