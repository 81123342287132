import { tenantsReportAdditionalFieldsForGridData } from '../../../constants/tenantsReportAdditionalFields';
import { IMetricResponseItem } from '../../../../../../../../General.interfaces';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { storePreparedGridData, Reports_ByTenants_Widget_Reducer_Values } from '../reducer';
import { IPreparedGridData, ITenantData } from '../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DateTime } from 'luxon';

/**
 * Кастомный хук для подготовки данных отчета
 */
export const usePrepareData = (): void => {
    const { rawMetricsData } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const { structures } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    /** Создание данных, необходимых для отображения таблицы */
    useEffect(() => {
        if (rawMetricsData && !rawMetricsData['status'] && structures) {
            const result: IPreparedGridData = {};
            /** Цикл по всем метрикам */
            (rawMetricsData as IMetricResponseItem[][]).forEach((metricResponseItems) => {
                /** Цикл по всем объектам для конкретной метрики */
                metricResponseItems.forEach((tenantMetricData) => {
                    const {
                        context: {
                            data_objects: [tenantData],
                            metric,
                        },
                        items,
                    } = tenantMetricData;

                    if (!result[tenantData.id]) {
                        const tenantTableDataByDate = items.reduce((acc, metricData) => {
                            const metricDataDate = DateTime.fromISO(metricData.time, {
                                zone: tenantData.timezone,
                            });
                            /** Получение информации по дополнительным полям для выбранной даты */
                            const additionalFieldsData = tenantsReportAdditionalFieldsForGridData.reduce(
                                (acc, field) => {
                                    const currentStructureData: { [x: string]: string | number }[] =
                                        structures[field.structure_type];
                                    if (currentStructureData) {
                                        const suitableValues = currentStructureData?.filter((structureData) => {
                                            let flag = false;

                                            const structureDateFrom = DateTime.fromISO(
                                                structureData['date_from'] as string,
                                            );
                                            const structureDateTo = DateTime.fromISO(
                                                structureData['date_to'] as string,
                                            );
                                            if (
                                                metricDataDate >= structureDateFrom &&
                                                metricDataDate <= structureDateTo &&
                                                structureData['tenant_id'] === tenantData.id
                                            ) {
                                                flag = true;
                                            }
                                            return flag;
                                        });

                                        acc[field.structure_type] = suitableValues;
                                    } else {
                                        acc[field.structure_type] = null;
                                    }
                                    return acc;
                                },
                                {},
                            );
                            acc[metricDataDate.toISO()] = {
                                metricsData: {
                                    [metric]: metricData.value,
                                },
                                additionalFieldsData,
                            };
                            return acc;
                        }, {});

                        result[tenantData.id] = {
                            tenantData: tenantData as unknown as ITenantData,
                            tenantTableDataByDate,
                        };
                    } else {
                        items.forEach((element) => {
                            const metricDataDate = DateTime.fromISO(element.time, {
                                zone: tenantData.timezone,
                            });

                            if (result[tenantData.id].tenantTableDataByDate[metricDataDate.toISO()]) {
                                result[tenantData.id].tenantTableDataByDate[metricDataDate.toISO()].metricsData[
                                    metric
                                ] = element.value;
                            }
                        });
                    }
                });
            });
            dispatch(storePreparedGridData(result));
        }
    }, [rawMetricsData, structures]);
};

export default usePrepareData;
