import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Performance_Dashboard_ByArea_Widget_Reducer_Values,
    storeObjectsRatingData,
    storeRawObjectsRatingData,
} from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import _ from 'lodash';
import { TRequestMetricsArgs } from '../../../../../../../../lib/esm/components';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useTranslation } from 'react-i18next';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';

export const useGetObjectMetric = () => {
    const fetchData = useRequestMetrics();
    const { t } = useTranslation();

    const { selectedObjectId, areaMetric, comparePeriod, dataRefetchObject } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');
    const { allMetrics, token } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { preferences } = useSelector(cabinetPreferencesValues);

    useEffect(() => {
        if (!localCurrentOptions) {
            return;
        }
        const controller = new AbortController();
        const signal = controller.signal;
        if (selectedObjectId && localCurrentOptions) {
            const mainPeriodValue = localCurrentOptions.mainPeriod?.id;
            const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriodValue);
            const mainTimeRange = [mainDateRange?.period?.dateFrom, mainDateRange?.period?.dateTo];
            const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter(
                (item) => item.id === comparePeriod.id,
            );
            const compareTimeRange = [compareDateRange.period.dateFrom, compareDateRange.period.dateTo];

            const areaRequest = {
                signal,
                token,
                metric: areaMetric,
                metric_level: preferences?.metricLevel,
                obj_ids: [selectedObjectId],
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            const requestParams: TRequestMetricsArgs = [];
            _.forEach(localCurrentOptions.selectedMetrics, function (value) {
                const metricMainPeriod = {
                    ...areaRequest,
                    metric: value,
                    alias: 'mainPeriod_' + value,
                };
                const metricComparePeriod = {
                    ...areaRequest,
                    metric: value,
                    time_range: compareTimeRange,
                    alias: 'comparePeriod_' + value,
                };
                requestParams.push(metricMainPeriod, metricComparePeriod);
            });
            dispatch(storeRawObjectsRatingData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            dispatch(storeObjectsRatingData(null));
            fetchData(requestParams).then((res: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawObjectsRatingData,
                    error: storeRawObjectsRatingData,
                    dispatch,
                    res,
                });
            });
        }
        return () => {
            controller.abort();
        };
    }, [
        selectedObjectId,
        localCurrentOptions?.selectedMetrics,
        comparePeriod,
        localCurrentOptions?.mainPeriod,
        dataRefetchObject,
    ]);
};
