import React, { useMemo } from 'react';
import { IconButton, IIconProps, TooltipHost } from '@fluentui/react';
import Pdf from 'react-to-pdf';
import { useTranslation } from 'react-i18next';
import { Props } from './interfaces';
import { PDFWrapper } from './styles';
import { generalReducerValues } from '../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { sendSimpleMetrics } from '../../../../../tools/useAnalytics';

const PADDING = 50;

const PDFDownload: React.FC<Props> = ({ targetRef, fileName = 'PDF report' }) => {
    const { t } = useTranslation();
    const { currentModule, user } = useSelector(generalReducerValues);
    const PDFIcon: IIconProps = { iconName: 'PDF', styles: { root: [{ color: 'rgb(180, 180, 195)' }] } };
    const canSendAnalytics = !user?.permissions.includes('app/client | not-collect-analytics | feature/view');

    const options = useMemo(() => {
        if (!targetRef?.current) return null;
        // const width = 1200;
        // const height = 1600;
        const width = targetRef.current.clientWidth;
        const height = targetRef.current.clientHeight;
        const orientation = height >= width ? 'portrait' : 'landscape';

        return {
            orientation,
            unit: 'px',
            format: [width + PADDING * 2, height + PADDING * 2],
        };
    }, [targetRef?.current?.['clientHeight'], fileName]);

    const onComplete = (args: any) => {
        sendSimpleMetrics('reachGoal', `${fileName} PDF_download`, currentModule, canSendAnalytics);
    };

    return options ? (
        <TooltipHost content={t('Download PDF')}>
            <PDFWrapper>
                <Pdf
                    targetRef={targetRef}
                    filename={t(fileName)}
                    options={options}
                    scale={1.8}
                    x={PADDING}
                    y={PADDING}
                    onComplete={onComplete}
                >
                    {({ toPdf }: { toPdf: any }) => {
                        return (
                            <IconButton
                                styles={{ root: { width: '22px' } }}
                                iconProps={PDFIcon}
                                aria-label="Download PDF"
                                onClick={toPdf}
                            />
                        );
                    }}
                </Pdf>
            </PDFWrapper>
        </TooltipHost>
    ) : null;
};

export default PDFDownload;
