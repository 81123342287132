import { DateTime } from 'luxon';
import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import Column from '../../../../../../../Charts/Column/Column';
import { rawData } from '../../rawData';
import { Cases_Calculator_Calculator_Widget_Reducer_Values } from '../../reducer';
import { canShowOCR } from '../../tenantsOptions';
import { TableWrapper } from './styles';

const ColumnChart: React.FC = () => {
    const { moduleName, chartMode } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const rent = localCurrentOptions?.['rentInMonth'];
    const indexPercent = localCurrentOptions?.['indexPercent'];
    const indexingDate = localCurrentOptions?.['indexingDate'];
    const tenantId = localCurrentOptions?.['tenant']?.[0]?.id;
    const tenantName = localCurrentOptions?.['tenant']?.[0]?.text;
    const selectedMetricsIds = localCurrentOptions?.['myMetrics']?.map((item: any) => item.id);
    const mainPeriodId = localCurrentOptions?.mainPeriod?.id;
    const mainPeriod = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriodId)[0].period!;

    const namesMap = {
        traffic: t('Tenant attendance, ppl.'),
        sales_no_vat: t('Sales without VAT, ₽'),
        sales_per_area_no_vat: `${t('Sales of')} ${tenantName} ${t('per m² without VAT')}`,
        sales_per_area_uniq_no_vat: t('Sales of group per m² without VAT'),
        profit: t('Total rental income, ₽'),
        ocr: `${tenantName} OCR, %`,
    };

    const categories = rawData?.[tenantId]?.['traffic']
        ?.filter((item) => {
            return (
                DateTime.fromISO(item.date).toMillis() >= DateTime.fromISO(mainPeriod?.dateFrom).toMillis() &&
                DateTime.fromISO(item.date).toMillis() <= DateTime.fromISO(mainPeriod?.dateTo).toMillis()
            );
        })
        .map((item) => {
            const dt = DateTime.fromISO(item.date).setLocale(lang);
            return dt.toFormat('LLL yyyy');
        });

    const colors = { sales_per_area_no_vat: '#4C7EFF', sales_per_area_uniq_no_vat: '#FFA928', ocr: 'red' };

    const arr = ['sales_per_area_no_vat', 'sales_per_area_uniq_no_vat']?.filter((item) =>
        selectedMetricsIds?.includes(item),
    );

    if (indexingDate && canShowOCR(tenantId, rent, indexingDate, indexPercent)) {
        arr.push('ocr');
    }

    const series = arr.map((key, i: number) => {
        const data = rawData?.[tenantId]?.[key]
            ?.filter((item) => {
                return (
                    DateTime.fromISO(item.date).toMillis() >= DateTime.fromISO(mainPeriod?.dateFrom).toMillis() &&
                    DateTime.fromISO(item.date).toMillis() <= DateTime.fromISO(mainPeriod?.dateTo).toMillis()
                );
            })
            .map((item) => {
                return { y: item[chartMode].value };
            });
        const type = key === 'ocr' ? 'line' : 'column';
        const unit =
            chartMode === '2022' && (key === 'sales_per_area_no_vat' || key === 'sales_per_area_uniq_no_vat')
                ? '₽'
                : key === 'sales_per_area_no_vat' || key === 'sales_per_area_uniq_no_vat'
                ? '%'
                : '';
        return { name: namesMap[key] + `, ${unit}`, data, type, yAxis: i, color: colors[key], id: key };
    });

    const getMinMax = (
        series: {
            name: any;
            data: {
                y: any;
            }[];
            type: string;
            yAxis: number;
            color: any;
            id: string;
        }[],
    ) => {
        const allNumbers = series.reduce((acc, dataLine) => {
            const values = dataLine.data.map((item) => item.y);
            return [...acc, ...values];
        }, []);

        return { min: Math.min(...allNumbers), max: Math.max(...allNumbers) };
    };

    const yAxis = arr.map((key, i) => {
        if (key === 'ocr') {
            if (chartMode === '2022') {
                const allNumbers = series?.filter((item) => item.id === key)[0].data.map((item) => item.y);
                let min = -100;
                let max = 100;

                if (allNumbers?.length) {
                    min = Math.min(...allNumbers) - 5;
                    max = Math.max(...allNumbers) + 5;
                }

                return {
                    title: { text: '' },
                    labels: { style: { color: colors[key] } },
                    min,
                    max,
                    opposite: true,
                };
            } else {
                return {
                    title: { text: '' },
                    labels: { style: { color: colors[key] } },
                    min: getMinMax(series).min,
                    max: getMinMax(series).max,
                    opposite: true,
                };
            }
        } else {
            return {
                title: { text: '' },
                labels: { style: { color: colors[key] } },
                min: getMinMax(series).min,
                max: getMinMax(series).max,
            };
        }
    });

    const options: any = {
        multipleAxes: true,

        chart: {
            type: 'column',
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        yAxis,
        xAxis: {
            categories,
        },
        series,
    };

    return (
        <TableWrapper>
            <Column options={options} />
        </TableWrapper>
    );
};

export default ColumnChart;
