import { __makeTemplateObject } from '../../../../_virtual/_tslib.js';
import styled from 'styled-components/macro';

styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-clip: padding-box;\n    background-color: #fff;\n    display: inline-block;\n    vertical-align: top;\n    border-radius: 4px;\n    box-shadow: 0 8px 24px 0 rgba(22, 102, 167, 0.2);\n    padding: var(--space-md);\n"], ["\n    background-clip: padding-box;\n    background-color: #fff;\n    display: inline-block;\n    vertical-align: top;\n    border-radius: 4px;\n    box-shadow: 0 8px 24px 0 rgba(22, 102, 167, 0.2);\n    padding: var(--space-md);\n"])));
styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    margin-top: var(--space-md);\n"], ["\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    margin-top: var(--space-md);\n"])));
var MonthCell = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    cursor: pointer;\n    color: ", ";\n    pointer-events: ", ";\n    background: ", ";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 32px;\n    width: 72px;\n    margin-bottom: 30px;\n\n    span {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        height: 32px;\n        width: 100%;\n        border-radius: 16px;\n        background-color: ", ";\n    }\n"], ["\n    cursor: pointer;\n    color: ", ";\n    pointer-events: ", ";\n    background: ", ";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 32px;\n    width: 72px;\n    margin-bottom: 30px;\n\n    span {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        height: 32px;\n        width: 100%;\n        border-radius: 16px;\n        background-color: ", ";\n    }\n"])), function (p) { return (p.selected || p.isLastUnderCusor ? 'white' : p.disabled ? 'var(--color-secondary-dark)' : 'black'); }, function (p) { return (p.disabled ? 'none' : 'auto'); }, function (p) { return p.bg; }, function (p) { return (p.selected || p.isLastUnderCusor ? 'var(--color-primary)' : 'transparent'); });
var Year = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    grid-template-rows: repeat(3, 1fr);\n    padding: var(--space-xs);\n"], ["\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    grid-template-rows: repeat(3, 1fr);\n    padding: var(--space-xs);\n"])));
var YearWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: grid;\n    grid-template-rows: auto, auto;\n    border-right: ", ";\n    position: relative;\n"], ["\n    display: grid;\n    grid-template-rows: auto, auto;\n    border-right: ", ";\n    position: relative;\n"])), function (p) { return (p.index === 0 || p.index === 1 ? '1px solid #dce0e0' : 'none'); });
styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: grid;\n    grid-template-rows: auto, auto;\n    border-right: ", ";\n    position: relative;\n"], ["\n    display: grid;\n    grid-template-rows: auto, auto;\n    border-right: ", ";\n    position: relative;\n"])), function (p) { return (p.index === 0 || p.index === 1 ? '1px solid #dce0e0' : 'none'); });
styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    position: absolute;\n    width: calc(100% - 20px);\n    left: 10px;\n    top: 11px;\n    z-index: 100;\n    display: flex;\n    justify-content: space-between;\n"], ["\n    position: absolute;\n    width: calc(100% - 20px);\n    left: 10px;\n    top: 11px;\n    z-index: 100;\n    display: flex;\n    justify-content: space-between;\n"])));
styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 26px;\n    width: 26px;\n    border-radius: 50%;\n    border: 1px solid var(--color-secondary-dark) !important;\n    :hover {\n        background-color: var(--color-primary-lighter);\n    }\n"], ["\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 26px;\n    width: 26px;\n    border-radius: 50%;\n    border: 1px solid var(--color-secondary-dark) !important;\n    :hover {\n        background-color: var(--color-primary-lighter);\n    }\n"])));
var YearHeader = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    display: flex;\n    border-bottom: 1px solid #dce0e0;\n"], ["\n    display: flex;\n    border-bottom: 1px solid #dce0e0;\n"])));
var YearName = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    color: var(--color-primary);\n    font-size: 16px;\n    width: 100%;\n    padding: 15px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n"], ["\n    color: var(--color-primary);\n    font-size: 16px;\n    width: 100%;\n    padding: 15px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n"])));
styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: 32px repeat(7, 1fr);\n    border: ", ";\n    border-radius: 16px;\n    height: 34px;\n    margin-bottom: 6px;\n"], ["\n    display: grid;\n    grid-template-columns: 32px repeat(7, 1fr);\n    border: ", ";\n    border-radius: 16px;\n    height: 34px;\n    margin-bottom: 6px;\n"])), function (p) { return (p.weekInFocus ? '1px solid rgba(74,113,232,0.3)' : '1px solid transparent'); });
styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    font-size: 12px;\n    align-items: flex-start;\n    border: 1px solid #dce0e0;\n    border-radius: 5px;\n    display: flex;\n    margin: 6px 0 0;\n    position: relative;\n"], ["\n    font-size: 12px;\n    align-items: flex-start;\n    border: 1px solid #dce0e0;\n    border-radius: 5px;\n    display: flex;\n    margin: 6px 0 0;\n    position: relative;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;

export { MonthCell, Year, YearHeader, YearName, YearWrapper };
