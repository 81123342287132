import { jsx, Fragment } from 'react/jsx-runtime';
import React, { useState, useEffect } from 'react';
import { DEFAULT_INPUT_MAX_LENGTH, DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } from '../../constants/constants.js';
import cn from 'classnames';
import { useDebounce } from './core/useDebounce.js';

var TextInput = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.id, id = _c === void 0 ? undefined : _c, _d = _a.type, type = _d === void 0 ? 'text' : _d, _e = _a.name, name = _e === void 0 ? undefined : _e, _f = _a.maxLength, maxLength = _f === void 0 ? DEFAULT_INPUT_MAX_LENGTH : _f, _g = _a.minLength, minLength = _g === void 0 ? 0 : _g, _h = _a.placeholder, placeholder = _h === void 0 ? '' : _h, _j = _a.pattern, pattern = _j === void 0 ? undefined : _j, _k = _a.size, size = _k === void 0 ? 'md' : _k, _l = _a.fullWidth, fullWidth = _l === void 0 ? false : _l, handleChange = _a.handleChange, outsideValue = _a.outsideValue, _m = _a.hasError, hasError = _m === void 0 ? false : _m, _o = _a.isMultiLine, isMultiLine = _o === void 0 ? false : _o, minNumber = _a.minNumber;
    var DEFAULT_CLASS_INPUT_PREFIX$1 = DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1 = DEFAULT_SEPARATOR_MODIFICATION_PREFIX;
    var _p = useState(''), value = _p[0], setValue = _p[1];
    useEffect(function () {
        outsideValue !== undefined && setValue(function () { return outsideValue; });
    }, [outsideValue]);
    var debouncedValue = useDebounce(value, 300);
    useEffect(function () {
        handleChange && handleChange({ id: id, name: name, value: debouncedValue || '' });
    }, [debouncedValue]);
    /**
     * получить класс размера кнопки
     * @param size - размер кнопки
     */
    var getSizeCss = function (size) {
        return "".concat(DEFAULT_CLASS_INPUT_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, "size").concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1).concat(size);
    };
    var onChange = function (e) {
        var newValue = e.currentTarget.value;
        setValue(function () { return newValue; });
    };
    var className = React.useMemo(function () {
        var _a;
        var cssClassSize = getSizeCss(size);
        return cn(DEFAULT_CLASS_INPUT_PREFIX$1, cssClassSize, (_a = {},
            _a["".concat(DEFAULT_CLASS_INPUT_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, "full-width")] = fullWidth,
            _a), { error: hasError }, 'transition', { resize: isMultiLine });
    }, [DEFAULT_CLASS_INPUT_PREFIX$1, DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1, getSizeCss, size, disabled]);
    return (jsx(Fragment, { children: isMultiLine ? (jsx("textarea", { placeholder: placeholder, maxLength: maxLength, minLength: minLength, disabled: disabled, className: className, value: value, onChange: onChange }, void 0)) : (jsx("input", { type: type, placeholder: placeholder, pattern: pattern, maxLength: maxLength, minLength: minLength, disabled: disabled, className: className, value: value, onChange: onChange, min: minNumber }, void 0)) }, void 0));
};

export { TextInput as default };
