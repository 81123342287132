import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../../../../../../tools/API/appBackendAPI';
import {
    Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    deleteRow,
    removeChangedDataItem,
    resetChangedData,
    updateTableDataWithEvent,
} from '../redcuer';
import { storeNewAlert } from '../../../../../../Alert/reducer';
import { MessageBarType } from '@fluentui/react';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { AxiosError, AxiosResponse } from 'axios';
import {
    Events_EventsMap_Widget_Reducer_Values,
    storeRawEventsData,
} from '../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
/**
 * Кастомный хук для работы с сервером
 */
const useFetchData = () => {
    const { token, appBackendHost, timeZone, selectedLocationId } = useSelector(generalReducerValues);
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const { saveChangesObject, changedData } = useSelector(
        Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    );
    const dispatch = useDispatch();

    /** Перезапрос событий при смене модуля */
    useEffect(() => {
        return () => {
            dispatch(resetChangedData());
            dispatch(storeRawEventsData([]));

            if (token && selectedLocationId && eventTypes.length) {
                const url = `${appBackendHost}/api/location-events/location-events/?event_type__pl_id=${selectedLocationId}`;
                getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                    dispatch(storeRawEventsData(response['data']));
                });
            }
        };
    }, [token, selectedLocationId, eventTypes]);

    /**
     * Вызывается при нажатии на кнопку Сохранить, все запросы элементов объекта changedData
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (Object.keys(changedData).length && token && timeZone) {
            const url = `${appBackendHost}/api/location-events/location-events/`;
            Object.keys(changedData).forEach((element, index) => {
                const value = changedData[element];
                let request: null | Promise<any> = null;
                switch (value.type) {
                    case 'POST':
                        request = postRequest(url, token, { ...value.event, timezone: timeZone });
                        break;

                    case 'PATCH':
                        request = patchRequest(url, token, { ...value.event, timezone: timeZone });
                        break;

                    case 'DELETE':
                        if (value.event.id) {
                            request = deleteRequest(url, token, value.event.id);
                        }

                    default:
                        break;
                }

                request?.then((response) => {
                    const handledResponse = responseAnalyzer(response);

                    if (handledResponse.isError) {
                        dispatch(
                            storeNewAlert({
                                type: MessageBarType.error,
                                text: `Error with ${value.type} ${value.event.name}. Error: ${handledResponse.result.message}`,
                                duration: 30,
                            }),
                        );
                    } else {
                        dispatch(removeChangedDataItem(element));
                        if (value.type === 'POST') {
                            dispatch(updateTableDataWithEvent({ frontId: element, event: handledResponse.result }));
                        }
                        if (value.type === 'DELETE') {
                            dispatch(deleteRow(element));
                        }

                        dispatch(
                            storeNewAlert({
                                type: MessageBarType.success,
                                text: `Success ${value.type} ${value.event.name}`,
                                duration: 2,
                            }),
                        );
                    }
                });
            });
        }
        return () => controller.abort();
    }, [saveChangesObject, token, timeZone]);
};

export default useFetchData;
