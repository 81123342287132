import { __assign } from '../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import cn from 'classnames';

/**
 * Компонент для отображения элементов списка
 */
var Checkbox = function (_a) {
    // const [checked, setChecked] = useState(false);
    var _b = _a.selected, selected = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.view, view = _d === void 0 ? 'switch-toggle-checkbox' : _d, handleToggle = _a.handleToggle, id = _a.id, label = _a.label;
    // useEffect(() => {
    //     if (selected !== checked) {
    //         setChecked(() => selected);
    //     }
    // }, [selected]);
    var toggleCheckbox = function (event) {
        if (!disabled) {
            handleToggle({ checked: !selected, event: event, id: id });
        }
    };
    var containerClassName = useMemo(function () {
        return cn('checkbox-container', { disabled: disabled });
    }, [disabled]);
    var checkboxClassName = useMemo(function () {
        return cn(view, { active: selected }, { deactive: !selected }, { disabled: disabled });
    }, [selected, disabled, view]);
    return (jsxs("div", __assign({ className: containerClassName, onClick: toggleCheckbox }, { children: [jsx("input", { type: "checkbox", className: "checkbox-disabled" }, void 0), jsxs("div", __assign({ className: checkboxClassName }, { children: [" ", view === 'checkbox' && jsx("div", { className: "checkbox-switch" }, void 0)] }), void 0), label && jsx("span", __assign({ className: "checkbox__content" }, { children: label }), void 0)] }), void 0));
};

export { Checkbox as default };
