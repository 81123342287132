import { Point, Series } from 'highcharts';

import { ColorsEnum } from '../../constants/enums';
import Legend from '../../components/Legend/Legend';
import { renderToString } from 'react-dom/server';

interface IArgs {
    seriesReverse: boolean;
    seriesLength: number;
    maxColors: number;
}

/**
 * Функция для генерации настроек легенды
 * @param seriesReverse Флаг отвечающий за порядок серий (прямой или обратный)
 * @param seriesLength количесвто серий
 * @param maxColors колчиесвто дотсупных цветов
 * @returns Объект с настройками для легенды
 */
const generateLegend = (args: IArgs) => {
    const { seriesReverse, seriesLength, maxColors } = args;
    return {
        labelFormatter: function (this: Point | Series) {
            const colorIndex = this.index > maxColors ? this.index % 10 : this.index;

            return renderToString(
                <Legend
                    color={
                        this.options.color ||
                        ColorsEnum[`index-${seriesReverse ? seriesLength - colorIndex - 1 : colorIndex}`]
                    }
                    name={this.name}
                    visible={this.visible}
                />,
            );
        },
        reversed: seriesReverse,
        itemMarginBottom: 10,
        squareSymbol: false,
        alignColumns: false,
        itemDistance: 10,
        symbolPadding: 0,
        symbolHeight: 0,
        symbolRadius: 0,
        align: 'left',
        symbolWidth: 0,
        maxHeight: 100,
        useHTML: true,
        margin: 27,
    };
};

export default generateLegend;
