import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    flex: 0 0 370px;
    margin-right: var(--space-md);

    :last-child {
        margin-right: 0px;
    }
`;

export const Header = styled.div`
    display: flex;
    padding: 16px 20px 0 20px;
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
`;

export const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #000;
    max-width: 100%;
    word-wrap: break-word;
    margin-right: 10px;
`;

export const Table = styled.table`
    width: 100%;
`;

export const StyledIcon = styled(Icon)<{ color: string }>`
    color: ${({ color }) => color};
`;
