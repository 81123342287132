import { TFunction } from 'react-i18next';
import { AvailableReportingObjectsTypes } from '../../../interfaces';

export const pivotItems = [
    {
        itemKey: AvailableReportingObjectsTypes.Zone,
        layerType: 'zones_layer',
        headerText: 'Zones',
    },
    {
        itemKey: AvailableReportingObjectsTypes.Tenant,
        layerType: 'tenants_layer',
        headerText: 'Tenants',
    },
    {
        itemKey: AvailableReportingObjectsTypes.Floor,
        layerType: 'perimeter_layer',
        headerText: 'Floors',
    },
];

export const generatePivotItems = (t: TFunction) =>
    pivotItems.map((element) => ({ ...element, headerText: t(element.headerText) }));
