import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import Categories from './widgets/Categories/Categories';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';

const Dashboard: React.FC = ({}) => {
    const { selectedLocationId, locations = [] } = useSelector(generalReducerValues);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <Categories />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
