import { Stack } from '@fluentui/react';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, TSelectedOptions } from '../../../../../../../../../../../lib/esm/components';
import { getReportingObjects } from '../../../../../../../../../../../tools/getReportingObjects';
import FiltersMonitor from '../../../../../../../../../Filters/FiltersMonitor/FiltersMonitor';
import FiltersSelect from '../../../../../../../../../Filters/FiltersSelect/FiltersSelect';
import { generateOptionsOfReportingObjectsTypes, ReportingObjectsTypesMap } from '../../../../constants/constants';
import { IntersectionsWithTenantsContext } from '../../../../context';
import { IExtendedReportingObject, TAvailableReportingObjectsType } from '../../../../interfaces';
import { HeaderTitle, HeaderWrapper, WrappedItem, Wrapper } from './styles';

interface IProps {
    /** расширенные данные выбранного арендатора */
    extendedReportingObject: IExtendedReportingObject;
}

const Header: FC<IProps> = ({ extendedReportingObject }) => {
    const context = useContext(IntersectionsWithTenantsContext);
    const { t } = useTranslation();

    const validOptions = useMemo(() => {
        if (context && Object.keys(context.reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(context.reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [context?.reportingObjectsByType, t]);

    const onReportingObjectTypeSelect = (args: TSelectedOptions) => {
        if (context) {
            const [value] = args;
            context.storeSelectedReportingObjectsTypeTrans(value['value'] as TAvailableReportingObjectsType);
        }
    };

    return (
        <Wrapper>
            <HeaderWrapper>
                <WrappedItem>
                    <HeaderTitle>{extendedReportingObject.reportingObject.name}</HeaderTitle>
                </WrappedItem>
                <WrappedItem>
                    <Stack horizontal={true} tokens={{ childrenGap: 15 }}>
                        <Select
                            outsideSelected={
                                context
                                    ? [
                                          {
                                              text:
                                                  t(ReportingObjectsTypesMap[context.selectedReportingObjectsType]) ||
                                                  t(context.selectedReportingObjectsType),
                                              id: context.selectedReportingObjectsType,
                                          },
                                      ]
                                    : undefined
                            }
                            options={generateOptionsOfReportingObjectsTypes(t)}
                            handleSelect={onReportingObjectTypeSelect}
                            iconType="chevron-down"
                            dropdownAlign="right"
                            showFilter={false}
                            dropdownWidth="sm"
                            mode="single"
                            labelText=""
                        />
                        {context?.selectedReportingObjectsType === 'tenant' && (
                            <FiltersSelect label="" dropdownAlign="right" validOptions={validOptions} />
                        )}
                    </Stack>
                </WrappedItem>
            </HeaderWrapper>
            {context?.selectedReportingObjectsType === 'tenant' && <FiltersMonitor />}
        </Wrapper>
    );
};

export default Header;
