/**
 * Стилевое оформление кнопки
 */
export enum ButtonTheme {
    /**
     * Значение темы кнопки не определено
     */
    Empty = '',
    /**
     * Кнопка только с рамочкой
     */
    Outline = 'outline',
    /**
     * Кнопка без рамки и фона
     */
    Text = 'text',

    /**
     * Кнопка с иконкой без текста
     */
    Icon = 'icon'
}
