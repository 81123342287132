import styled from 'styled-components/macro';
import { getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';

const theme = getTheme();

const {
    spacing: { l1, l2, s1, s2, m },
} = theme;

export const Overlay = styled.div<{ isActive: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isActive }) => (isActive ? 10000 : -10000)};
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};
`;

export const Title = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: auto;
`;

export const CloseWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 5px;
`;

export const Modal = styled.div<{ isActive: boolean; topColor?: string }>`
    box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    min-width: 300px;
    min-height: 200px;
    max-width: 90vw;
    max-height: 90vh;
    padding: 20px;
    overflow: hidden;
    transition: all 0.2s;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    border-top: ${({ topColor }) => `${s2} solid ${topColor || theme.palette.themePrimary}`};
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
`;

export const Body = styled.div`
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

/**
 * Стили для кнопки закрытия модалки.
 */
export const iconButtonStyles = {
    root: {
        color: theme.palette.themePrimary,
        marginLeft: 'auto',
        marginTop: s2,
        marginRight: '2px',
        selectors: {
            i: { fontSize: '20px !important' },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

/**
 * Стили для содержимого.
 */
export const contentStyles = (topColor: string = theme.palette.themePrimary) =>
    mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minHeight: 'unset',
        },
        header: [
            theme.fonts.large,
            {
                flex: '1 1 auto',
                borderTop: `${s2} solid ${topColor}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '24px 24px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
