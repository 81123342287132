import { KonvaEventObject } from 'konva/lib/Node';
import React, { useMemo } from 'react';
import { Arrow, Group, Line, Shape } from 'react-konva';
import { findLabelCoords } from '../../../../../../../../../../../tools/findLabelCoords';
import { scaleTransformFoo } from '../../../../../../../../../../../tools/scaleTransformFoo';
import { ObjectLabel } from '../../../../../../../../../ObjectLabel/ObjectLabel';
import { getArrowPoint } from '../../../../core/getArrowPoint';
import { Props } from './interfaces';

const PassWay: React.FC<Props> = ({
    object,
    selectObject,
    selectedMarker,
    colorsByMarker,
    planScale,
    stageScale,
    metric,
    overMarker,
    heatColor,
    onMouseOverLeave,
}) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject(object.marker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave(object.marker);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave(null);
    };

    const arrows = () => {
        const result: JSX.Element[] = [];
        object.passLine.coordinates.forEach((point, i, arr) => {
            if (i === 0) return;
            const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
            const arrowEndPoint = getArrowPoint(point, i, arr, planScale || 1, object.connectivityMargin / 2, 'end');
            const arrowStartPoint = getArrowPoint(
                point,
                i,
                arr,
                planScale || 1,
                object.connectivityMargin / 2,
                'start',
            );

            result.push(
                <React.Fragment key={`${i}--${point[0]}`}>
                    <Arrow
                        points={[arrowStartPoint[0], arrowStartPoint[1], arrowEndPoint[0], arrowEndPoint[1]]}
                        stroke="red"
                        strokeWidth={scaleTransformFoo(3, stageScale)}
                        pointerWidth={scaleTransformFoo(8, stageScale)}
                        pointerLength={scaleTransformFoo(12, stageScale)}
                    />
                </React.Fragment>,
            );
        });
        return result;
    };

    const stroke = selectedMarker !== object.marker ? heatColor : 'rgba(0,0,255,0.5)';
    const strokeWidth = scaleTransformFoo(20, stageScale);

    const points = useMemo(() => {
        const result: Array<number> = [];
        object.passLine.coordinates.forEach((point) => {
            result.push(...point);
        });
        return result;
    }, [object.passLine.coordinates]);

    return (
        <Group>
            {/* {connectivityShape} */}
            <Line
                onClick={onObjectClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                points={points}
                stroke={stroke}
                strokeWidth={strokeWidth}
                lineJoin={'round'}
                lineCap={'round'}
            />
            {arrows()}
        </Group>
    );
};

export default PassWay;
