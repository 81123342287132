import { PresetReducerValues, resetPresetReducer, storeAvailablePresets, storeSelectedPreset } from '../reducer';
import { createNewOptionId, deleteOptionId } from '../components/PresetModalBody/contants/constants';
import { storeSettingsFromPreset, userSettingsReducerValues } from '../../UserSettings/reducer';
import { IObject } from '../../../../lib/esm/components/Select/configurations';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TPresets } from '../interfaces';
import { isEqual } from 'lodash';

/**
 * Кастомный хук для работы с данными пресета
 * @returns Настройки для селекта пресета
 */
const usePresetData = () => {
    const {
        currentOptions: { currentModule, selectedLocationId },
        currentOptions,
    } = useSelector(userSettingsReducerValues);
    const { availablePresets, selectedPreset } = useSelector(PresetReducerValues);
    const [options, setOptions] = useState<IObject[]>([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const createNewOption = {
        id: createNewOptionId,
        text: t('Create new'),
    };
    const deleteOption = {
        id: deleteOptionId,
        text: t('Delete selected'),
    };

    /**
     * Обнуление редьюсера при смене локации
     */
    useEffect(() => {
        dispatch(resetPresetReducer());
    }, [selectedLocationId, currentModule]);

    /**
     * Запись доступных пресетов из localStorage
     */
    useEffect(() => {
        let presets: TPresets = localStorage.getItem('presets');
        if (presets) presets = JSON.parse(presets);
        if (selectedLocationId && presets && presets[currentModule]?.[selectedLocationId]) {
            dispatch(storeAvailablePresets(presets[currentModule][selectedLocationId]));
        }
    }, [currentModule, localStorage.getItem('presets'), selectedLocationId]);

    /**
     * Получение опций селекта пресета
     */
    useEffect(() => {
        const result: IObject[] = [];
        Object.keys(availablePresets).forEach((key) => {
            result.push({ id: key, text: t(key) });
        });
        selectedPreset && result.push(deleteOption);
        result.push(createNewOption);
        setOptions(result);
    }, [availablePresets, selectedPreset]);

    /**
     * Перезапись настроек пользователя данными из пресета
     */
    useEffect(() => {
        if (selectedPreset && selectedLocationId && availablePresets[selectedPreset.id!]) {
            dispatch(
                storeSettingsFromPreset({
                    settings: availablePresets[selectedPreset.id!],
                    selectedLocationId,
                    currentModule,
                }),
            );
        }
    }, [selectedPreset, availablePresets, selectedLocationId, dispatch, currentModule]);

    /**
     * Проверка на идентичность данный настроек пользователя с данными пресета
     */
    useEffect(() => {
        if (selectedLocationId && localStorage.getItem('presets') && selectedPreset) {
            const currentPreset =
                JSON.parse(localStorage.getItem('presets') as string)[currentModule]?.[selectedLocationId]?.[
                    selectedPreset.id!
                ] || {};
            let isPresetEqual = true;
            Object.keys(currentPreset).forEach((key) => {
                if (isPresetEqual) {
                    isPresetEqual = isEqual(currentOptions[key], currentPreset[key]);
                }
            });

            !isPresetEqual && dispatch(storeSelectedPreset(null));
        }
    }, [JSON.stringify(currentOptions), localStorage.getItem('presets'), selectedLocationId, currentModule]);

    return options;
};

export default usePresetData;
