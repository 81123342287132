import React, { ReactNode } from 'react';
import cn from 'classnames';
import * as constants from '../../constants/constants';

import '../../styles/styles.scss';

const SwitcherContent: React.FC<{ children?: ReactNode }> = ({ ...props }) => {
    const { DEFAULT_CLASS_SWITCHER, DEFAULT_SEPARATOR_ELEMENT_PREFIX } = constants;

    const className = cn([`${DEFAULT_CLASS_SWITCHER}${DEFAULT_SEPARATOR_ELEMENT_PREFIX}content`]);

    return (
        <div className={className}>
            <div className={'navigation-item'}>{props.children}</div>
        </div>
    );
};

export default SwitcherContent;
