import { Tooltip } from '@fluentui/react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContextualMenu from '../../../../../../../../../ContextualMenu/ContexttualMenu';
import { EventsMapWidgetContext } from '../../../../context';
import { IEventType } from '../../../../interfaces';
import ColorPicker from '../ColorPicker/ColorPicker';
import { Color, Name, Wrapper } from './styles';

interface IProps {
    /** Тип события */
    eventType: IEventType;
    /** Флаг состояния бокового меню */
    open: boolean;
}

/**
 * КОмпонент для отображения каждого типа события в боковом меню
 */
const EventItem: FC<IProps> = ({ eventType, open }) => {
    const { t } = useTranslation('eventsTranslation');
    const context = useContext(EventsMapWidgetContext);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const [transitionOpen, setTransitionOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => setTransitionOpen(open), 200);
    }, [open]);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const toggleHovered = () => {
        setIsHovered((prevState) => !prevState);
    };

    const onColorChange = (color: string) => {
        if (context) {
            context.storeEventTypeToUpdateTrans({ name: eventType.name, color });
        }
    };

    return (
        <Wrapper>
            {isHovered && !open && (
                <Tooltip
                    targetElement={ref?.current as unknown as HTMLElement}
                    content={open ? undefined : t(eventType.name)}
                    calloutProps={{ gapSpace: 5 }}
                    directionalHint={12}
                />
            )}

            <Color
                // onClick={toggleContextualMenu}
                onMouseEnter={toggleHovered}
                onMouseLeave={toggleHovered}
                color={eventType.color}
                ref={ref}
            />

            {(open ? transitionOpen : false) && <Name>{t(eventType.name)}</Name>}
            <ContextualMenu
                CustomMenuComponent={<ColorPicker onColorChange={onColorChange} currentColor={eventType.color} />}
                hideContextualMenu={closeContextualMenu}
                isContextualMenuOpen={isContextualMenuOpen}
                targetRef={ref}
                gapSpace={10}
            />
        </Wrapper>
    );
};

export default EventItem;
