import { IGenerateQueryDataArgs } from './interfaces';
import { IRequestMetricsArgs } from '../../../../../../../../lib/esm/components/ExportFunctions/interfaces';
import { MAIN_PERIOD_ALIAS } from '../constants/constants';

/**
 * Функция генерации данных для запрса метрик
 * @param extendedReportingObjects Расширенный список отчетных объектов
 * @param selectedChartDetailing Выбранная детализация графика
 * @param selectedChartType Выбранный режим графика
 * @param compareDateRanges Диапазоны выбранных дат (с периодами)
 * @param extendedMetrics Расширенные метрики
 * @param mainDateRanges Диапазон основной выбранной даты (с периодами)
 * @param comparePeriods Периоды для сравнения
 * @param selectedObject Выбранный объект
 * @param selectedMetric Выбранная метрика
 * @param metricLevel Уровень получения метрик
 * @param mainPeriod Основной период
 * @param token токен авторизации
 * @returns Сгенерированные данные для запроса метрик
 */
const generateQueryData = (args: IGenerateQueryDataArgs) => {
    const {
        extendedReportingObjects,
        selectedChartDetailing,
        selectedChartType,
        compareDateRanges,
        extendedMetrics,
        mainDateRanges,
        comparePeriods,
        selectedObject,
        selectedMetric,
        metricLevel,
        mainPeriod,
        token,
    } = args;

    const queryData = [];

    const comparePeriodsIds = comparePeriods.map((element) => element.id);
    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod?.id);
    const selectedCompareDateRanges = compareDateRanges?.filter((element) => comparePeriodsIds.includes(element.id));

    if (mainPeriodDateRnage) {
        selectedCompareDateRanges.push(mainPeriodDateRnage);
        const mainTimeRange = [mainPeriodDateRnage.period.dateFrom, mainPeriodDateRnage.period.dateTo];

        switch (selectedChartType.id) {
            case 'metrics':
                extendedMetrics.forEach((element, index) => {
                    queryData.push({
                        token,
                        alias: index === 0 ? MAIN_PERIOD_ALIAS : null,
                        metric: element.id,
                        metric_level: metricLevel,
                        obj_ids: [selectedObject.id],
                        object_aggregation: false,
                        time_range: mainTimeRange,
                        time_freq: selectedChartDetailing.id,
                    });
                });
                break;

            case 'objects':
                const reportingObjectsIds = extendedReportingObjects.map((element) => element.id);
                queryData.push({
                    token,
                    alias: MAIN_PERIOD_ALIAS,
                    metric: selectedMetric.id,
                    metric_level: metricLevel,
                    obj_ids: reportingObjectsIds,
                    object_aggregation: false,
                    time_range: mainTimeRange,
                    time_freq: selectedChartDetailing.id,
                });
                break;

            case 'periods':
            default:
                selectedCompareDateRanges.forEach((element) => {
                    if (element) {
                        const compareDate = [element.period.dateFrom, element.period.dateTo];
                        let mainPeriodAlias = null;

                        if (
                            element.period.dateFrom === mainTimeRange[0] &&
                            element.period.dateTo === mainTimeRange[1]
                        ) {
                            mainPeriodAlias = MAIN_PERIOD_ALIAS;
                        }
                        queryData.push({
                            token,
                            alias: mainPeriodAlias,
                            metric: selectedMetric.id,
                            metric_level: metricLevel,
                            obj_ids: [selectedObject.id],
                            object_aggregation: false,
                            time_range: compareDate,
                            time_freq: selectedChartDetailing.id,
                        });
                    }
                });
                break;
        }
    }

    return queryData;
};

export default generateQueryData;
