import { Icon, Stack } from '@fluentui/react';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { modulesConfig } from '../../../constants/modulesConfig';
import { getUrls, hosts } from '../../../constants/urls';
import { changeLang, generalReducerValues } from '../../../General.reducer';
import { TMode } from '../../../lib/esm/components/Select/configurations';
import ContextualMenu from '../ContextualMenu/ContexttualMenu';
import LocationsSelect from '../Selects/LocationsSelect/LocationsSelect';
import { TLang } from '../SideBar/configurations';
import { userSettingsReducerValues } from '../UserSettings/reducer';
import ModuleTitle from '../Wrappers/ModuleTitle/ModuleTitle';
import ModuleTitleWrapper from '../Wrappers/ModuleTitleWrapper/ModuleTitleWrapper';
import CabinetPreferencesMenu from './components/CabinetPreferencesMenu/CabinetPreferencesMenu';
import LangSelectMenu from './components/LangSelectMenu/LangContextualMenu';
import LogoutContextualMenuBody from './components/LogoutContextualMenuBody/LogoutContextualMenuBody';
import { IProps } from './interfaces';
import { HeaderCentralArea, HeaderControlsArea, HeaderWrapper, LangWrapper, LogoutWrapper } from './styles';

const Header: React.FC<IProps> = ({ marginBottom }) => {
    const { t } = useTranslation();
    const { locations = [], lang, timeZone, user, currentModule } = useSelector(generalReducerValues);
    const { settings } = useSelector(userSettingsReducerValues);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const contextualMenuRef = useRef(null);

    const dispatch = useDispatch();

    let name = 'pl';
    let mode: TMode = 'single';

    if (modulesConfig?.find((item) => item.module_name === currentModule)?.store_method['global'].includes('pls')) {
        name = 'pls';
        mode = 'multi';
    }

    const options = locations.map((location) => {
        return { id: String(location.id), text: location.name };
    });

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const onChangeLang = (lang: TLang) => {
        dispatch(changeLang(lang));
    };

    const onLogout = () => {
        localStorage.removeItem('xtoken');
        window.location.replace(
            `${getUrls(hosts).LOGOUT_URL}?next=/admin/login/?next=/sso/ajax-token?next=` + window.location.href,
        );
    };

    const outsideSelected = options?.filter((item) => {
        return settings['global']?.[name]?.map((item: any) => item.id).includes(item.id);
    });

    const moduleName = t(currentModule?.split(':')[1]);
    let dateTime = timeZone ? DateTime.fromObject({ hour: 0 }, { zone: timeZone }) : DateTime.now();

    const shift = dateTime.offset / 60 >= 0 ? `+${dateTime.offset / 60}` : `-${dateTime.offset / 60}`;

    const locationText = outsideSelected
        ? `${outsideSelected[0]?.text} (${timeZone} ${shift}UTC)`
        : t('Select location');

    return (
        <HeaderWrapper marginBottom={marginBottom}>
            <HeaderCentralArea>
                <ModuleTitleWrapper>
                    <ModuleTitle>{moduleName}</ModuleTitle>
                </ModuleTitleWrapper>
                <HeaderControlsArea>
                    <LocationsSelect
                        showFilter
                        labelText=""
                        mode={mode}
                        iconType="caret-down"
                        options={options}
                        name={name}
                        text={t('Select location')}
                        outsideText={locationText}
                        dropdownWidth="lg"
                        dropdownAlign="right"
                    />
                    <CabinetPreferencesMenu />
                    <LangSelectMenu lang={lang} handleClickItem={onChangeLang}></LangSelectMenu>

                    {user && (
                        <LogoutWrapper onClick={toggleContextualMenu} ref={contextualMenuRef}>
                            <Stack horizontalAlign="center" horizontal={true} tokens={{ childrenGap: 10 }}>
                                <div>{user.username}</div>
                                <Icon iconName="ChevronDown" />
                            </Stack>
                            <ContextualMenu
                                CustomMenuComponent={<LogoutContextualMenuBody onLogout={onLogout} />}
                                hideContextualMenu={closeContextualMenu}
                                isContextualMenuOpen={isContextualMenuOpen}
                                targetRef={contextualMenuRef}
                                gapSpace={10}
                            />
                        </LogoutWrapper>
                    )}
                </HeaderControlsArea>
            </HeaderCentralArea>
        </HeaderWrapper>
    );
};

export default Header;
