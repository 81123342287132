import { createNewOptionId, currentOptionNameByKey, deleteOptionId } from './contants/constants';
import { ISelectedOption } from '../../../../../lib/esm/components/Select/configurations';
import getCurrentOptionValueFromKey from './tools/getCurrentOptionValueFromKey';
import { Button, TextInput } from '../../../../../lib/esm/components';
import { ISettings } from '../../../UserSettings/interfaces';
import { IPresetModalBodyProps } from './interfaces';
import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TPresets } from '../../interfaces';
import {
    SettingsBodyWrapper,
    SettingsBodyItem,
    SettingsSubtitle,
    SettingsWrapper,
    ButtonWrapper,
    SettingsTitle,
    SettingsText,
    Wrapper,
} from './styles';

/**
 * Контент внутри модального окна. Удаление или создание пресета
 * @param storeSelectedPresetTrans Запись выбранного пресета
 * @param reportingObjectsById Отчетные объекты, где ключ объекта - это id отчетного объекта
 * @param currentOptionsKeys Ключи настроек для текущего пресета
 * @param selectedLocationId ID выбранной локации
 * @param selectedPreset Выбранный пресет
 * @param currentOptions Текущие настройки пользователя
 * @param currentModule Название текущего модуля
 * @param deletePreset Флаг отвечающий за удаление пресета
 * @param closeModal Функция закрытия модального окна
 * @param allMetrics Все метрики
 */
const PresetModalBody: FC<IPresetModalBodyProps> = ({
    storeSelectedPresetTrans,
    reportingObjectsById,
    currentOptionsKeys,
    selectedLocationId,
    selectedPreset,
    currentOptions,
    currentModule,
    deletePreset,
    closeModal,
    allMetrics,
}) => {
    const [presetNameError, setPresetNameError] = useState(false);
    const [presetName, setPresetName] = useState('');
    const { t } = useTranslation();

    /**
     * Установка значения названия пресета при флаге удаления
     */
    useEffect(() => {
        if (deletePreset && selectedPreset) {
            setPresetName(selectedPreset.id as string);
            setPresetNameError(false);
        }
    }, [deletePreset, selectedPreset]);

    /**
     * Проверка валидности введенного названия пресета
     */
    useEffect(() => {
        setPresetNameError(false);
        presetName === createNewOptionId && setPresetNameError(true);
        presetName === deleteOptionId && setPresetNameError(true);
        !presetName && setPresetNameError(true);
    }, [presetName]);

    /**
     * Удаление или сохранение пресета
     */
    const onButtonClick = () => {
        let presets: TPresets = localStorage.getItem('presets');
        let newPresetValue: ISelectedOption | null = null;
        if (presets) presets = JSON.parse(presets) || {};
        else presets = {};

        if (selectedLocationId) {
            if (deletePreset) {
                selectedPreset && delete presets![currentModule][selectedLocationId][selectedPreset.id];
            } else {
                const value = currentOptionsKeys.reduce((acc: ISettings, value) => {
                    acc[value as string] = currentOptions[value];
                    return acc;
                }, {});

                const currentLocationData = presets![currentModule]?.[selectedLocationId] || {};
                const currentModuleData = presets![currentModule] || {};

                presets![currentModule] = {
                    ...currentModuleData,
                    [selectedLocationId]: {
                        ...currentLocationData,
                        [presetName]: value,
                    },
                };
                newPresetValue = {
                    text: presetName,
                    id: presetName,
                };
            }

            localStorage.setItem('presets', JSON.stringify(presets));
            storeSelectedPresetTrans(newPresetValue);
            closeModal();
        }
    };

    const handleNameInput = (data: { value: string }) => setPresetName(data.value);

    return (
        <Wrapper>
            <TextInput
                handleChange={handleNameInput}
                hasError={presetNameError}
                outsideValue={presetName}
                placeholder={t('Name')}
                disabled={deletePreset}
                fullWidth
            />
            <SettingsWrapper>
                <SettingsTitle>{t('Current settings:')}</SettingsTitle>
                <SettingsBodyWrapper>
                    {currentOptionsKeys.map((element) => {
                        const valueFromKey = getCurrentOptionValueFromKey({
                            reportingObjectsById,
                            currentOptions,
                            key: element,
                            allMetrics,
                        });
                        return (
                            <SettingsBodyItem key={element}>
                                <SettingsSubtitle>{t(currentOptionNameByKey[element]) || t(element as string)}</SettingsSubtitle>
                                {typeof valueFromKey === 'object' ? (
                                    valueFromKey.map((element) => (
                                        <SettingsText key={element + Math.random()}>{t(element)}</SettingsText>
                                    ))
                                ) : (
                                    <SettingsText>{t(valueFromKey)}</SettingsText>
                                )}
                            </SettingsBodyItem>
                        );
                    })}
                </SettingsBodyWrapper>
            </SettingsWrapper>
            <ButtonWrapper>
                <Button
                    text={deletePreset ? t('Delete') : t('Save as new')}
                    disabled={presetNameError || !selectedLocationId}
                    type={deletePreset ? 'accent' : 'primary'}
                    onClick={onButtonClick}
                />
            </ButtonWrapper>
        </Wrapper>
    );
};

export default PresetModalBody;
