import React from 'react';
import cn from 'classnames';
import * as constants from '../../../../constants/constants';

import { ICollapseHeaderProps } from '../../../../configurations';
import '../../../../styles/styles.scss';
import { Icon } from '../../../../../../../lib/esm/components';

const CollapseHeader: React.FC<ICollapseHeaderProps> = ({ title, icon, compact, ...props }) => {
    const { DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_CLASS_SWITCHER, DEFAULT_CLASS_SWITCHER_FORM } = constants;
    const switcherFormClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SWITCHER_FORM]: true,
        });
    }, [props]);
    const switcherClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SWITCHER]: true,
            [`${DEFAULT_CLASS_SWITCHER}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: compact,
        });
    }, [props]);
    return (
        <div className={switcherFormClassName}>
            <div className={switcherClassName}>
                <div className="crumbs_sidebar-compact-switcher__stub">
                    <Icon type={icon} size="xxxs" color="gray-darker" />
                </div>
                {!compact && (
                    <div className="crumbs_sidebar-compact-switcher__content">
                        <div className="collapse-header navigation-item color-gray-darker">
                            <span className="navigation-item__text">{title}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CollapseHeader;
