import { Icon } from '@fluentui/react';
import styled from 'styled-components/macro';

export const GrandWrapper = styled.div<{ open: boolean }>`
    position: relative;
    flex: ${(p) => (p.open ? '0 0 15%' : '0 0 56px')};
    height: 100%;
    border-right: 1px solid var(--color-contrast-low);
    transition: all 0.3s;
    padding: 15px;
`;

export const Wrapper = styled.div`
    overflow-x: hidden;
    margin-top: 25px;
`;

export const StyledIcon = styled(Icon)<{ open: boolean }>`
    position: absolute;
    cursor: pointer;
    top: 9px;
    right: 0px;
    font-size: 24px;
    line-height: 24px;
    background-color: white;
    transform: ${(p) => 'translateX(50%)' + (p.open ? 'rotateY(180deg)' : 'rotateY(0deg)')};
`;
