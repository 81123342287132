import { TMetricLevel } from '../../../interfaces';
export interface IMetricOption {
    disabled?: boolean;
    key: TMetricLevel;
    text: string;
}

export const METRIC_LEVEL_OPTIONS: IMetricOption[] = [
    {
        key: 'source',
        text: 'source',
    },
    {
        key: 'clean',
        text: 'clean',
    },
    {
        key: 'frozen',
        text: 'frozen',
    },
];
