import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { OptionsWrapper, TitleWrapper } from './styles/styles';
import Traffic from './widgets/Traffic/Traffic';
import Sales from './widgets/Sales/Sales';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../Preset/Preset';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import { useTranslation } from 'react-i18next';
import { ISettings } from '../../../UserSettings/interfaces';
import { getSubCategoriesByParentName } from '../../../../../tools/getSubCategoriesByParentName';
import FiltersSelect from '../../../Filters/FiltersSelect/FiltersSelect';
import FiltersMonitor from '../../../Filters/FiltersMonitor/FiltersMonitor';

const Dashboard: React.FC = ({}) => {
    const {
        src: { categories },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const geoOptions = getSubCategoriesByParentName('Регион', categories)?.map((item) => ({
        id: String(item.id),
        text: item.object_name,
    }));

    const mallTypeOptions = getSubCategoriesByParentName('Размер ТЦ', categories)?.map((item) => ({
        id: String(item.id),
        text: item.object_name,
    }));
    const detailOptions = [
        { id: 'W-MON', text: t('Weekly') },
        { id: 'MS', text: t('Monthly') },
    ];

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            yearsOnly
                            validOptions={[
                                'previousMonth',
                                'currentYear',
                                'previousWeek',
                                'currentMonth',
                                'selectCustom',
                            ]}
                        />
                        <ComparePeriodSelect
                            yearsOnly
                            validOptions={['sameMonthdays', 'yoy', 'selectCustom']}
                            maxSelected={2}
                            mode="single"
                        />
                        <UniversalSelect
                            mode="single"
                            options={geoOptions}
                            name={'geoOptions'}
                            labelText={'Region'}
                            text={t('Select a region')}
                            dropdownWidth="sm"
                        />
                        <UniversalSelect
                            mode="single"
                            options={mallTypeOptions}
                            name={'mallTypeOptions'}
                            labelText={'Mall type'}
                            text={t('Select mall type')}
                            dropdownWidth="sm"
                        />
                        <UniversalSelect
                            mode="single"
                            options={detailOptions}
                            name={'myDetail'}
                            labelText={'Detail'}
                            text={t('Select detail')}
                            dropdownWidth="sm"
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                ['mainPeriod', 'mallTypeOptions', 'category', 'tenant'] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <Traffic />
                {/* <Sales /> */}
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
