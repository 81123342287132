/**
 * Функция для получение различия между переданными числами
 * @param a Перове число
 * @param b Второе число
 * @returns Объект с различием между переданными числавми в процентах и числовое различие
 */
const getDifferenceBetweenNumbers = (a: number, b: number) => {
    const difference = (a - b) / ((a + b) / 2 || 1);
    return {
        percentDifference: (difference * 100).toFixed(2),
        difference: (a - b).toFixed(2),
    };
};

export default getDifferenceBetweenNumbers;
