import { IChartDetailing } from '../../../../../../../constants/chartDetailing';
import { IReportingObject, TLoadingData } from '../../../../../../../General.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store';
import { cloneDeep } from 'lodash';
import {
    IChartOptionsGeneratorSettings,
    IDynamicsPMOReducerState,
    IExtendedReportingObject,
    TSelectedChartTypeId,
    ISelectedChartType,
    IMetric,
} from './interfaces';

const initialState: IDynamicsPMOReducerState = {
    rowMetricsData: { status: 'Loading', message: 'Loading' },
    chartOptionsGeneratorSettings: null,
    selectedChartDetailing: null,
    extendedReportingObjects: [],
    shoudUpdateMetricsCounter: 0,
    availableChartDetailing: [],
    alignByDaysOfWeek: false,
    highlightWeekend: false,
    metricsFetching: false,
    selectedObject: null,
    selectedMetric: null,
    extendedMetrics: [],
    dataRefetchObject: {},
};

const Cases_Benchmarks_Traffic_Widgwet_Reducer = createSlice({
    name: 'Cases_Benchmarks_Traffic_Widgwet_Reducer',
    initialState,
    reducers: {
        /**
         * Запсиь расширенной ифнормации об выбранных отчетных объектах
         */
        storeExtendedReportingObjects: (
            state,
            action: PayloadAction<{ reportingObjects: IReportingObject[]; selectedReportingObjectsIds: number[] }>,
        ) => {
            const { reportingObjects, selectedReportingObjectsIds } = action.payload;
            const { selectedObject } = cloneDeep(state);
            const extendedReportingObjects = reportingObjects
                ?.filter((element) => selectedReportingObjectsIds.includes(element.id))
                .map((element) => ({ ...element, text: element.name }));

            const extendedReportingObjectsIds: (string | number)[] = extendedReportingObjects.map(
                (element) => element.id,
            );
            if (!extendedReportingObjectsIds.includes(selectedObject?.id || '')) {
                state.selectedObject = extendedReportingObjects[0];
            }

            state.extendedReportingObjects = extendedReportingObjects;
        },

        /**
         * Запсиь выбранной сущности (entity)
         */
        storeSelectedEntity: (state, action: PayloadAction<IExtendedReportingObject[]>) => {
            state.selectedObject = action.payload[0];
        },

        /**
         * Запсиь выбранной метрики
         */
        storeSelectedMetric: (state, action: PayloadAction<IMetric[]>) => {
            state.selectedMetric = action.payload[0];
        },

        /**
         * Запись выбранной детализации для графика
         */
        storeSelectedChartDetailing: (state, action: PayloadAction<IChartDetailing[]>) => {
            state.selectedChartDetailing = action.payload[0];
        },

        /**
         * Запись расширенной информации об выбранных метрик
         */
        storeExtendedMetrics: (state, action: PayloadAction<{ selectedMetrics: string[]; allMetrics: IMetric[] }>) => {
            const { selectedMetric } = cloneDeep(state);
            const { selectedMetrics, allMetrics } = action.payload;
            const extendedMetrics = allMetrics?.filter((element) => selectedMetrics.includes(element.id));
            const extendedMetricsIds = extendedMetrics.map((element) => element.id);
            if (!extendedMetricsIds.includes(selectedMetric?.id || '')) {
                state.selectedMetric = extendedMetrics[0];
            }

            state.extendedMetrics = extendedMetrics;
        },

        /**
         * Изменение флага загрузки метрик
         */
        toggleMetricsFetching: (state, action: PayloadAction<boolean>) => {
            state.metricsFetching = action.payload;
        },

        /**
         * Изменение флага сдвига по дням недели
         */
        toggleAlignByDaysOfWeek: (state, action: PayloadAction<boolean>) => {
            state.alignByDaysOfWeek = action.payload;
        },

        /**
         * Запись достпуной детализации графика (в зависимостри от периода)
         */
        storeAvailableChartDetailing: (state, action) => {
            const selectedChartDetailing = cloneDeep(state.selectedChartDetailing);
            const availableChartDetailingIds: string[] = action.payload.map((element: IChartDetailing) => element.id);
            const availableChartDetailingTexts: string[] = action.payload.map(
                (element: IChartDetailing) => element.text,
            );
            if (!availableChartDetailingIds.includes(selectedChartDetailing?.id || '')) {
                state.selectedChartDetailing = action.payload[0];
            }

            if (!availableChartDetailingTexts.includes(selectedChartDetailing?.text || '')) {
                state.selectedChartDetailing =
                    action.payload?.find((element: IChartDetailing) => selectedChartDetailing?.id === element.id) ||
                    action.payload[0];
            }
            state.availableChartDetailing = action.payload;
        },

        /**
         * Изменение флага, отвечающего за отображение выходных дней
         */
        toggleHighlightWeekend: (state, action: PayloadAction<boolean>) => {
            state.highlightWeekend = action.payload;
        },

        /**
         * Функция для увелечения значения счетчика, отвечающего за запрос метрик
         */
        increaseShoudUpdateMetricsCounter: (state) => {
            state.shoudUpdateMetricsCounter += 1;
        },

        /**
         * Запись настроект диаграммы
         */
        storeChartOptionsGeneratorSettings: (state, action: PayloadAction<IChartOptionsGeneratorSettings | null>) => {
            state.chartOptionsGeneratorSettings = action.payload;
        },

        /**
         * Запись сырых данных метрик
         */
        storeRowMetricsData: (state, action: PayloadAction<TLoadingData>) => {
            state.rowMetricsData = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },

        /**
         * Обнуление редьюсера
         */
        resetDynamicsPMORedcuer: () => initialState,
    },
});

export const {
    storeChartOptionsGeneratorSettings,
    increaseShoudUpdateMetricsCounter,
    storeExtendedReportingObjects,
    storeAvailableChartDetailing,
    storeSelectedChartDetailing,
    toggleAlignByDaysOfWeek,
    resetDynamicsPMORedcuer,
    toggleHighlightWeekend,
    toggleMetricsFetching,
    storeExtendedMetrics,
    storeSelectedEntity,
    storeSelectedMetric,
    storeRowMetricsData,
    reloadWidget,
} = Cases_Benchmarks_Traffic_Widgwet_Reducer.actions;

export const Cases_Benchmarks_Traffic_Widgwet_Reducer_Values = (state: RootState) =>
    state.Cases_Benchmarks_Traffic_Widgwet_Reducer;

export default Cases_Benchmarks_Traffic_Widgwet_Reducer.reducer;
