import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';
import { getUrls, hosts } from '../../constants/urls.js';

var requestVersions = function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var mlId, token, headers, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                mlId = args.mlId, token = args.token;
                headers = {
                    'X-Token': token,
                    'Content-type': 'application/json',
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios({
                        method: 'GET',
                        headers: headers,
                        url: "".concat(getUrls(hosts).VERSIONS_URL, "?location_id=").concat(mlId),
                    })];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, error_1];
            case 4: return [2 /*return*/];
        }
    });
}); };

export { requestVersions as default };
