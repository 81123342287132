import React from 'react';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { CellWrapper, Name, Value } from '../styles';

const Cell: React.FC<{ data: string; name: string }> = ({ data, name }) => {
    return (
        <CellWrapper>
            <Name>{name}</Name>
            <Value>{data}</Value>
        </CellWrapper>
    );
};

export default Cell;
