import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import {
    requestVersions,
    requestPlans,
    requestLayers,
    IRequestMetricsArgs,
} from '../../../../../../../../lib/esm/components';
import { useTranslation } from 'react-i18next';
import { userSettingsReducerValues } from '../../../../../../UserSettings/reducer';
import {
    Maps_MapsOverview_Module_Reducer_Values,
    storePlans,
    storeVersions,
    storeLayers,
    storeZonesByGroup,
    storeSelectedGroup,
    storeSelectedZoneId,
    storeSelectedPlaceId,
    storeShowComparison,
    storeInitialData,
    storeSelectedVersionId,
} from '../../../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { mlFromLocation } from '../../../../../../../../tools/mlFromLocation';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { usePrepareData } from './usePrepareData';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { filterValidDateRanges } from '../../../../../../../../tools/filterValidDateRanges';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    usePrepareData();
    const [initialLayers, setInitialLayers] = useState<any>([]);
    const {
        allMetrics,
        token,
        locations,
        selectedLocationId,
        structures,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const {
        currentOptions: { currentModule },
    } = useSelector(userSettingsReducerValues);
    const { selectedObjectType, selectedGroupId, showComparison, selectedVersionId, versions } = useSelector(
        Maps_MapsOverview_Module_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Maps overview');
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);

    const dispatch = useDispatch();

    useEffect(() => {
        // Тут мы анализируем изменения основного периода и , если надо меняем текущую версию
        const mainPeriod = localCurrentOptions?.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
        const period = mainDateRange?.period;
        const filtered = versions?.filter((version) => {
            return (
                period?.dateTo &&
                version.date_from &&
                DateTime.fromISO(period.dateTo).toMillis() >= DateTime.fromISO(version.date_from).toMillis() &&
                (!version.date_to ||
                    (version.date_to &&
                        DateTime.fromISO(period.dateTo).toMillis() <= DateTime.fromISO(version.date_to).toMillis()))
            );
        })[0];

        if (filtered?.id && filtered?.id !== selectedVersionId) {
            dispatch(storeSelectedVersionId(filtered.id));
        }
    }, [localCurrentOptions?.mainPeriod?.id]);

    useEffect(() => {
        if (!Object.keys(reportingObjectsByType).length) return;
        const allZones = reportingObjectsByType['zone'];
        const allPlaces = reportingObjectsByType['place'];

        if (!allZones) {
            dispatch(storeZonesByGroup({}));
            dispatch(storeSelectedGroup(null));
            dispatch(storeSelectedZoneId(null));
            return;
        }

        if (!allPlaces) {
            dispatch(storeSelectedPlaceId(null));
            return;
        }

        const zonesByGroup = {};
        allZones.forEach((zone) => {
            const groupMarker = zone.object_params.group_marker;
            if (groupMarker && zonesByGroup[groupMarker]) {
                zonesByGroup[groupMarker] = [...zonesByGroup[groupMarker], zone];
            } else if (groupMarker && !zonesByGroup[groupMarker]) {
                zonesByGroup[groupMarker] = [zone];
            }
        });
        dispatch(storeZonesByGroup(zonesByGroup));
        const selectedGroupId = Object.keys(zonesByGroup).map((groupMarker) => groupMarker)[0];
        dispatch(storeSelectedGroup(selectedGroupId));
        const selectedZoneId = zonesByGroup[selectedGroupId][0].id;
        dispatch(storeSelectedZoneId(selectedZoneId));
        const selectedPlaceId = allPlaces[0].id;
        dispatch(storeSelectedPlaceId(selectedPlaceId));
    }, [reportingObjectsByType]);

    useEffect(() => {
        if (!selectedLocationId || localCurrentOptions?.currentModule !== 'Maps:Maps overview') return;

        const location = locations?.find((item) => item.id === selectedLocationId);
        const mlId = mlFromLocation(location);

        if (mlId && token) {
            dispatch(storePlans({ status: 'Loading' }));
            dispatch(storeVersions([]));
            requestPlans({ mlId, token }).then((response) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storePlans,
                    error: storePlans,
                    dispatch,
                    res: response,
                });
            });
            requestVersions({ mlId, token }).then((response) => {
                !response.message && dispatch(storeVersions(response.data));
            });
        }
    }, [selectedLocationId, localCurrentOptions?.currentModule, locations]);

    useEffect(() => {
        if (!selectedVersionId) return;

        const location = locations?.find((item) => item.id === selectedLocationId);
        const mlId = mlFromLocation(location);

        if (mlId && token) {
            dispatch(storeLayers([]));
            requestLayers({ mlId, token, versionId: String(selectedVersionId) }).then((response) => {
                !response.message && setInitialLayers(() => response.data);
            });
        }
    }, [selectedLocationId, selectedVersionId, token]);

    useEffect(() => {
        if (initialLayers.length && structures?.relations_tenant2place) {
            const mainPeriod = localCurrentOptions?.mainPeriod?.id;
            const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
            const period = mainDateRange?.period;

            const filteredTenant2Place = filterValidDateRanges(structures?.['relations_tenant2place'], period);

            const newLayers = cloneDeep(initialLayers);

            initialLayers.forEach((layer: any) => {
                if (layer.layer_type === 'places_layer') {
                    const data = layer.data?.map((item: any) => {
                        const marker = filteredTenant2Place?.find(
                            (t2p) => t2p.place_marker === item.marker,
                        )?.tenant_marker;
                        return { ...item, marker };
                    });
                    const newLay = { ...layer, layer_type: 'tenants_layer', data };
                    newLayers.push(newLay);
                }
            });

            dispatch(storeLayers(newLayers));
        } else if (initialLayers.length && !structures?.relations_tenant2place) {
            dispatch(storeLayers(initialLayers));
        }
    }, [initialLayers, structures?.['relations_tenant2place']]);

    useEffect(() => {
        if (!localCurrentOptions?.['mapsSettings']) return;
        const showComparison =
            localCurrentOptions['mapsSettings']?.find((item: any) => item.id === 'comparison') !== undefined;
        dispatch(storeShowComparison(showComparison));
    }, [localCurrentOptions?.['mapsSettings']]);

    useEffect(() => {
        dispatch(storeInitialData([]));
        if (!localCurrentOptions || !localCurrentOptions?.mainDateRanges || !localCurrentOptions?.comparePeriods)
            return;
        const controller = new AbortController();
        const signal = controller.signal;

        let obj_ids = reportingObjectsByType?.[selectedObjectType]?.map((item) => item.id);
        if (selectedObjectType === 'zone') {
            obj_ids = reportingObjectsByType[selectedObjectType]
                ?.filter((item) => item.object_params.group_marker === selectedGroupId)
                .map((item) => item.id);
        }

        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const [mainDateRange] = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];
        const comparePeriodId = localCurrentOptions?.comparePeriods?.[0]?.id;
        const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter((item) => item.id === comparePeriodId);
        const compareTimeRange = [compareDateRange?.period.dateFrom, compareDateRange?.period.dateTo];

        const requests: IRequestMetricsArgs[] = [];
        localCurrentOptions?.selectedMetrics?.forEach((metric) => {
            const request: IRequestMetricsArgs = {
                signal,
                token,
                alias: `${metric}:main`,
                metric,
                metric_level: metricLevel,
                obj_ids,
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            requests.push(request);
            if (showComparison) {
                requests.push({ ...request, alias: `${metric}:compare`, time_range: compareTimeRange });
            }
        });
        dispatch(storeInitialData({ status: 'Loading', message: t('Loading...') }));
        requests &&
            fetchData(requests).then((res: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeInitialData,
                    error: storeInitialData,
                    dispatch,
                    res,
                });
            });

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.mainPeriod,
        selectedLocationId,
        localCurrentOptions?.comparePeriods,
        selectedObjectType,
        selectedGroupId,
        showComparison,
    ]);
};
