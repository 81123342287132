import { CellWrapper, RowWrapper } from './styles';
import { FC, useMemo } from 'react';

import { ChartDot } from '../../../styles';
import { ITooltipBodyProps } from './interfaces';
import { isUndefined } from 'lodash';
import { numberWithSpaces } from '../../../../../../tools/Strings/numberWithSpaces';

/**
 * Компонент для отображения "тела" тултипа
 * @param showSeriesName Флаг, отвечающий за отобажение названия серии
 * @param points Все элементы тултипа
 */
const TooltipBody: FC<ITooltipBodyProps> = ({ points, showSeriesName = false }) => {
    const rows = useMemo(() => {
        return points && points.length
            ? points.map((point) => {
                  const units = point?.point?.['units'] || '';
                  const additionalText = point?.point?.['additionalText'] || '';
                  const showTooltipBody = point?.point?.['showTooltipBody'];

                  if (!isUndefined(showTooltipBody) && !showTooltipBody) return null;

                  return (
                      <RowWrapper key={point.key || point.colorIndex + Math.random()}>
                          <CellWrapper>
                              <ChartDot newColor={point.color} />
                              <span>{showSeriesName ? point.series.name : point.key}</span>
                          </CellWrapper>
                          <CellWrapper>
                              {point.y
                                  ? `${numberWithSpaces(point.y)}${units} ${additionalText}`
                                  : `${point.y}${units} ${additionalText}`}
                          </CellWrapper>
                      </RowWrapper>
                  );
              })
            : null;
    }, [points]);

    return <>{rows}</>;
};

export default TooltipBody;
