import { useState, useEffect } from 'react';

function useDebounce(value, delay) {
    if (delay === void 0) { delay = 500; }
    // Состояние и сеттер для отложенного значения
    var _a = useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        // Выставить debouncedValue равным value (переданное значение)
        // после заданной задержки
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        // Вернуть функцию очистки, которая будет вызываться каждый раз, когда ...
        // ... useEffect вызван снова. useEffect будет вызван снова, только если ...
        // ... value будет изменено (смотри ниже массив зависимостей).
        // Так мы избегаем изменений debouncedValue, если значение value ...
        // ... поменялось в рамках интервала задержки.
        // Таймаут очищается и стартует снова.
        // Что бы сложить это воедино: если пользователь печатает что-то внутри ...
        // ... нашего приложения в поле поиска, мы не хотим, чтобы debouncedValue...
        // ... не менялось до тех пор, пока он не прекратит печатать дольше, чем 500ms.
        return function () {
            clearTimeout(handler);
        };
    }, 
    // Вызывается снова, только если значение изменится
    // мы так же можем добавить переменную "delay" в массива зависимостей ...
    // ... если вы собираетесь менять ее динамически.
    [value]);
    return debouncedValue;
}

export { useDebounce };
