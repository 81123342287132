import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { INTERSECTIONS_REPORT_ADDITIONAL_FILEDS } from '../constants/constants';
import { Reports_Intersections_Widget_Reducer_Values, storeGrid } from '../reducer';
import { ISheetCell } from '../../../../../../../../General.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    IntersectionsReportAdditionalFieldsIds,
    IIntersectionsReportAddFieldOption,
} from '../constants/intersectionsReportAdditionalFields';

interface IExtendedCell {
    fieldId?: IntersectionsReportAdditionalFieldsIds;
    readOnly: boolean;
    value: string;
}

/**
 * Кастомный хук для генерации таблицы
 */
const useGridGenerator = () => {
    const { extendedAverageIntersectionsData } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Daily intersections');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (extendedAverageIntersectionsData) {
            const additionalFieldsIds: string[] =
                localCurrentOptions?.[INTERSECTIONS_REPORT_ADDITIONAL_FILEDS]?.map(
                    (element: IIntersectionsReportAddFieldOption) => element.id,
                ) || [];

            const grid: ISheetCell[][] = [];

            /** хеддер */
            const header: IExtendedCell[] = [
                { value: t('Base object id'), readOnly: true, fieldId: IntersectionsReportAdditionalFieldsIds.Id },
                { value: t('Base object'), readOnly: true },
                {
                    value: t('Intersecting object id'),
                    readOnly: true,
                    fieldId: IntersectionsReportAdditionalFieldsIds.Id,
                },
                { value: t('Intersecting object'), readOnly: true },
                { value: t('Base to Intersecting'), readOnly: true },
                { value: t('Intersecting to Base'), readOnly: true },
            ];

            const body: IExtendedCell[][] = [];

            /** тело таблицы */
            extendedAverageIntersectionsData.forEach((intersections) => {
                intersections.forEach((element) => {
                    body.push([
                        {
                            value: String(element.mainReportingObjectData?.id),
                            readOnly: false,
                            fieldId: IntersectionsReportAdditionalFieldsIds.Id,
                        },
                        { value: element.mainReportingObjectData?.name, readOnly: false },
                        {
                            value: String(element.contraReportingObjectData?.id),
                            readOnly: false,
                            fieldId: IntersectionsReportAdditionalFieldsIds.Id,
                        },
                        { value: element.contraReportingObjectData?.name, readOnly: false },
                        { value: `${numberWithSpaces(element.main_percent.toFixed(2))}%`, readOnly: false },
                        { value: `${numberWithSpaces(element.contra_percent.toFixed(2))}%`, readOnly: false },
                    ]);
                });
            });

            grid.push(header);
            grid.push(...body);

            /** Удаление невыбранных дополнительных полей */
            grid.forEach((gridRow) => {
                Object.keys(IntersectionsReportAdditionalFieldsIds).forEach((key) => {
                    if (!additionalFieldsIds.includes(key)) {
                        const elementsToDelete = gridRow?.filter((element: IExtendedCell) => element.fieldId === key);

                        elementsToDelete.forEach(() => {
                            const index = gridRow.findIndex((element: IExtendedCell) => element.fieldId === key);
                            if (index > -1) gridRow.splice(index, 1);
                        });
                    }
                });
            });

            dispatch(storeGrid(grid));
        }
    }, [extendedAverageIntersectionsData, t, localCurrentOptions?.[INTERSECTIONS_REPORT_ADDITIONAL_FILEDS]]);
};

export default useGridGenerator;
