/**
 * Типы кнопок
 */
var ButtonView;
(function (ButtonView) {
    /**
     * Базовая кнопка
     */
    ButtonView["Base"] = "default";
    /**
     * Главаня кнопка
     */
    ButtonView["Primary"] = "primary";
    /**
     * Кнопка ввиде ссылки
     */
    ButtonView["Link"] = "link";
    /**
     * Вторая кнопка
     */
    ButtonView["Secondary"] = "secondary";
    /**
     * Подтверждающая кнопка
     */
    ButtonView["Success"] = "success";
    /**
     * Кнопка предупреждающая об опасности
     */
    ButtonView["Danger"] = "danger";
    /**
     * Кнопка предупреждения
     */
    ButtonView["Warning"] = "warning";
    /**
     * Кнопка особого внимания
     */
    ButtonView["Accent"] = "accent";
})(ButtonView || (ButtonView = {}));

export { ButtonView };
