import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';
import { getUrls, hosts } from '../../constants/urls.js';

var requestStructure = function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var signal, token, queries, jq, response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                signal = args.signal, token = args.token, queries = args.queries, jq = args.jq;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios({
                        method: 'POST',
                        signal: signal,
                        url: getUrls(hosts).OBJECTS_URL,
                        data: JSON.stringify({
                            auth: {
                                xtoken: token,
                            },
                            queries: queries.map(function (q) { return ({
                                item: "pl_structure/pl".concat(q.pl_id, "/").concat(q.structure_section, "/").concat(q.structure_type, ".json"),
                            }); }),
                            jq: jq,
                        }),
                    })];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, error_1];
            case 4: return [2 /*return*/];
        }
    });
}); };

export { requestStructure as default };
