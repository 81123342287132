import { ColorsEnum } from './enums';

export const defaultOptions = {
    lang: {
        noData: 'Отсутствуют данные',
    },
    noData: {
        position: {
            verticalAlign: 'middle',
            align: 'center',
            x: 0,
            y: 0,
        },
        style: {
            color: 'rgba(0,0,0,.4)',
            fontWeight: 'normal',
            fontSize: '16px',
        },
    },
    title: {
        text: undefined,
    },
    credits: {
        enabled: false,
    },
    colors: Object.values(ColorsEnum),
};
