import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    overflow-x: auto;
`;

export const StyledTable = styled.table`
    border-collapse: collapse;
    flex-shrink: 0 !important;
`;

export const Tr = styled.tr`
    height: 40px;
`;

export const Td = styled.td<{ fontWeight?: string; textAlign?: string; padding?: string }>`
    width: 210px;
    padding: ${(p) => p.padding || 'var(--space-xxxs) var(--space-xxs)'};
    font-weight: ${(p) => p.fontWeight || 'normal'};
    text-align: ${(p) => p.textAlign || 'left'};
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    height: 30px;
`;

export const Th = styled.th<{ fontSize?: string; fontWeight?: string; textAlign?: string; whiteSpace?: string }>`
    height: 55px;
    font-size: ${(p) => p.fontSize || '1.25rem'};
    font-weight: ${(p) => p.fontWeight || '500'};
    text-align: ${(p) => p.textAlign || 'left'};
    width: 210px;
    padding: var(--space-xxs);
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    white-space: ${(p) => p.whiteSpace || 'wrap'};
`;
