import getDifferenceBetweenNumbers from '../../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { IExtendedReportingObject } from '../../../interfaces';
import { IDataForSort } from '../interfaces';
import { cloneDeep, isNull } from 'lodash';

interface IArgs {
    reportingObjects: IExtendedReportingObject[];
    dataForSort: IDataForSort;
}

const sortHandler = (a: number | null, b: number | null, isReverse: boolean): number => {
    if (isNull(a)) return isReverse ? -1 : 1;
    if (isNull(b)) return isReverse ? 1 : -1;
    if (isReverse) return a < b ? -1 : 1;
    else return a > b ? -1 : 1;
};

/**
 * Функция для сортировки арендаторов
 * @param dataForSort данные для сортировки
 * @param reportingObjects все отчетные объекты
 */
const sortReportingObjects = (args: IArgs): IExtendedReportingObject[] => {
    const { reportingObjects, dataForSort } = args;

    return cloneDeep(reportingObjects).sort((a, b) => {
        if (a.metrics && b.metrics) {
            const aValue: number | null = a.metrics[dataForSort.metricId].mainPeriod.items[0].value;
            const bValue: number | null = b.metrics[dataForSort.metricId].mainPeriod.items[0].value;

            let aCompareValue = null;
            let bCompareValue = null;

            if (!isNull(aValue)) {
                const difference = getDifferenceBetweenNumbers(
                    aValue,
                    a.metrics[dataForSort.metricId].comparePeriod?.items[0].value,
                );
                aCompareValue = +difference.percentDifference;
            }

            if (!isNull(bValue)) {
                const difference = getDifferenceBetweenNumbers(
                    bValue,
                    b.metrics[dataForSort.metricId].comparePeriod?.items[0].value,
                );
                bCompareValue = +difference.percentDifference;
            }

            if (dataForSort.periodType === 'mainPeriod') {
                return sortHandler(aValue, bValue, dataForSort.ordering === 'up' ? false : true);
            }

            if (dataForSort.periodType === 'comaprePeriod') {
                return sortHandler(aCompareValue, bCompareValue, dataForSort.ordering === 'up' ? false : true);
            }
        }
        return 1;
    });
};

export default sortReportingObjects;
