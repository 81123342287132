import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../General.reducer';

export const useErrorReset = () => {
    const [reset, setReset] = useState(0);
    const { selectedLocationId, currentModule } = useSelector(generalReducerValues);

    useEffect(() => {
        setReset(() => reset + 1);
    }, [selectedLocationId, currentModule]);

    return reset;
};
