import { TServerResponse, handleServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import {
    increaseShoudUpdateMetricsCounter,
    Events_Events_DynamicsPMO_Widget_Reducer_Values,
    storeRawMetricsData,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateQueryData from '../tools/generateQueryData';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useEffect } from 'react';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { Events_Events_Module_Reducer_Values } from '../../../reducer';

/**
 * Кастомный хук для получения метрик с сервера
 */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    const localCurrentOptions = useWidgetCurrentOptions('Events:Events');
    const { token } = useSelector(generalReducerValues);
    const { currentEvent } = useSelector(Events_Events_Module_Reducer_Values);
    const {
        shoudUpdateMetricsCounter,
        extendedReportingObjects,
        selectedChartDetailing,
        selectedChartType,
        extendedMetrics,
        selectedMetric,
        selectedObject,
        dataRefetchObject,
    } = useSelector(Events_Events_DynamicsPMO_Widget_Reducer_Values);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Инициирует перезапрос метрик, если изменился период сравнения на вкладке Периодов
    useEffect(() => {
        if (selectedChartType.id === 'periods' && localCurrentOptions?.comparePeriods) {
            dispatch(increaseShoudUpdateMetricsCounter());
        }
    }, [localCurrentOptions?.comparePeriods]);

    useEffect(() => {
        dispatch(storeRawMetricsData({ status: 'Loading', message: t('Loading...') }));
        if (!localCurrentOptions) return;
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions.comparePeriods &&
            localCurrentOptions.mainPeriod &&
            selectedChartDetailing &&
            selectedObject &&
            currentEvent &&
            selectedMetric
        ) {
            const queryData = generateQueryData({
                compareDateRanges: localCurrentOptions.compareDateRanges,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                comparePeriods: localCurrentOptions.comparePeriods,
                mainPeriod: localCurrentOptions.mainPeriod,
                extendedReportingObjects,
                selectedChartDetailing,
                selectedChartType,
                extendedMetrics,
                selectedObject,
                selectedMetric,
                metricLevel,
                token,
            }).map((item) => {
                return { ...item, signal };
            });

            dispatch(storeRawMetricsData({ status: 'Loading', message: t('Loading...') }));
            fetchData(queryData).then((res: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    dispatch,
                    res,
                });
            });
        }

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.mainPeriod,
        selectedChartDetailing?.id,
        shoudUpdateMetricsCounter,
        selectedChartType,
        selectedMetric,
        selectedObject,
        dataRefetchObject,
        currentEvent,
    ]);
};
