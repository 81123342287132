import generateMetricsQueryData from '../../../../../../../../tools/API/queryDataGenerators/generateMetricsQueryData';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { storeRawMetricsData, storeTableSortData, Leasing_Tenants_Tenants_Widget_Reducer_Values } from '../reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePrepareData from './usePrepareData';
import { useEffect, useMemo } from 'react';

/** Кастомный хук для получения сырых данных*/
export const useGetRawData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Leasing:Tenants');
    const { idsOfTenants, dataRefetchObject } = useSelector(Leasing_Tenants_Tenants_Widget_Reducer_Values);
    const { token } = useSelector(generalReducerValues);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePrepareData();

    const compareDateRangesPeriods = useMemo(() => {
        return localCurrentOptions?.compareDateRanges?.map((element) => element.period);
    }, [localCurrentOptions?.compareDateRanges]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (
            localCurrentOptions?.compareDateRanges &&
            localCurrentOptions?.selectedMetrics &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.comparePeriods &&
            localCurrentOptions?.mainPeriod &&
            idsOfTenants &&
            token
        ) {
            dispatch(storeTableSortData(null));
            const mainPeriodQueryData = generateMetricsQueryData({
                selectedMetrics: localCurrentOptions.selectedMetrics,
                dateRanges: localCurrentOptions.mainDateRanges,
                selectedReportingObjectsIds: idsOfTenants,
                period: localCurrentOptions.mainPeriod,
                timiFreq: null,
                metricLevel,
                token,
                alias: 'main',
            }).map((element) => ({ ...element, signal }));

            const comparePeriodQueryData = generateMetricsQueryData({
                selectedMetrics: localCurrentOptions.selectedMetrics,
                dateRanges: localCurrentOptions.compareDateRanges,
                period: localCurrentOptions.comparePeriods[0],
                selectedReportingObjectsIds: idsOfTenants,
                timiFreq: null,
                metricLevel,
                token,
                alias: 'compare',
            }).map((element) => ({ ...element, signal }));

            const finalQueryData = mainPeriodQueryData.concat(comparePeriodQueryData);
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            fetchData(finalQueryData).then((response: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: response,
                    dispatch,
                });
            });
        }
        return () => controller.abort();
    }, [
        JSON.stringify(compareDateRangesPeriods),
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.comparePeriods,
        dataRefetchObject,
        idsOfTenants,
        token,
    ]);
};
