import { useDispatch, useSelector } from 'react-redux';
import { PIVOT_ITEMS } from './constants/pivotItems';
import { PivotItem } from '@fluentui/react';
import { Wrapper, PivotWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Pivot from '../Pivot/Pivot';

/**
 * Модальное окно для отображения настроек кабинета для пользователя
 * @info Хук useCabinetPreferences Вызывается в App.tsx
 */
const CabinetPreferences = () => {
    const [selectedPivot, setSelectedPivot] = useState(PIVOT_ITEMS[0]);
    const [pivotItems, _] = useState(PIVOT_ITEMS);
    const { t } = useTranslation();

    const onPivotSelect = (item?: PivotItem) => {
        if (item?.props.itemKey) {
            const currentPivot = PIVOT_ITEMS?.find((element) => element.id === item.props.itemKey);
            currentPivot && setSelectedPivot(currentPivot);
        }
    };

    return (
        <Wrapper>
            <PivotWrapper>
                <Pivot selectedKey={selectedPivot.id} onLinkClick={onPivotSelect}>
                    {pivotItems.map((element) => (
                        <PivotItem key={element.id} itemKey={element.id} headerText={t(element.headerText)} />
                    ))}
                </Pivot>
            </PivotWrapper>
            {selectedPivot.component}
        </Wrapper>
    );
};

export default CabinetPreferences;
