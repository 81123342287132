import { ISelectOption, IUsePrepareDataResponse } from './interfaces';
import { useEffect, useState } from 'react';

import { generalReducerValues } from '../../../../../../General.reducer';
import { useSelector } from 'react-redux';

/** Кастомный хук для подготовки данных */
const usePrepareData = (): IUsePrepareDataResponse => {
    const [groupsOfZonesSelectOptions, setGroupsOfZonesSelectOptions] = useState<ISelectOption[]>([]);
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState<null | ISelectOption>(null);
    const {
        cfg: { reportingObjectsByType },
        selectedLocationId,
        selectedProjectId,
    } = useSelector(generalReducerValues);

    useEffect(() => {
        setDefaultSelectedGroup(null);
        const groupsOfZones: string[] = reportingObjectsByType?.['zone']?.reduce((acc: string[], value) => {
            if (value.object_params.group_marker && !acc.includes(value.object_params.group_marker)) {
                acc.push(value.object_params.group_marker);
            }
            return acc;
        }, []);

        if (groupsOfZones) {
            const result = groupsOfZones.map((element) => ({ id: element, text: element }));
            setGroupsOfZonesSelectOptions(result);
            setDefaultSelectedGroup(result[0]);
        }
    }, [reportingObjectsByType, selectedLocationId, selectedProjectId]);

    return { groupsOfZonesSelectOptions, defaultSelectedGroup };
};

export default usePrepareData;
