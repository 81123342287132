import { updateReportingObjectsWithExtendedData, Performance_YoY_YoY_Widget_Reducer_Values } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import aggregateMetricsResponse from '../tools/aggregateMetricsResponse';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateExtendedReportingObjectData from '../tools/generateExtendedReportingObjectData';
import { useEffect } from 'react';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';

/**
 * Кастомный хук для подготовки данных
 */
const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Year over year');
    const { rawMetricsData, shoudUpdateAllReportingObjects, extendedReportingObjectsById } = useSelector(
        Performance_YoY_YoY_Widget_Reducer_Values,
    );
    const { allMetrics } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    /** Обновление отченых объектов, при изменении тырых данных */
    useEffect(() => {
        if (localCurrentOptions?.selectedMetrics) {
            if (Array.isArray(rawMetricsData) || rawMetricsData?.status === 'Error') {
                const aggaregatedMetrics = aggregateMetricsResponse(
                    rawMetricsData,
                    extendedReportingObjectsById,
                    shoudUpdateAllReportingObjects,
                );
                const extendedData = generateExtendedReportingObjectData({
                    selectedMetrics: localCurrentOptions.selectedMetrics,
                    aggaregatedMetrics,
                    allMetrics,
                });

                dispatch(updateReportingObjectsWithExtendedData(extendedData));
            }
        }
    }, [rawMetricsData]);
};

export default usePrepareData;
