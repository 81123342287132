import React, { useMemo } from 'react';
import Datasheet from 'react-datasheet';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomCell, CustomHeader, TableWrapper } from './styles';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Configuration_Categories_Widget_Reducer_Values, toggleContextMenu } from '../../reducer';
import { IReportingObject } from '../../../../../../../../../General.interfaces';
import ContextMenu from './components/ContextMenu/ContextMenu';
import { cloneDeep } from 'lodash';

const objectTypes = ['tenant'];

const Table: React.FC = ({}) => {
    const { filters } = useSelector(Configuration_Categories_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsByType },
        src: { categories, projectCategories, dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cellRenderer = (props: any) => {
        const { children, editing, ...rest } = props;
        if (props.cell.types.includes('cell')) {
            return (
                <CustomCell {...rest} types={props.cell.types}>
                    {children}
                </CustomCell>
            );
        } else if (props.cell.types.includes('header')) {
            return (
                <CustomHeader {...rest} types={props.cell.types}>
                    {children}
                    {/* <FilterIndicator>{filterText}</FilterIndicator> */}
                </CustomHeader>
            );
        } else {
            return (
                <CustomCell {...rest} types={props.cell.types}>
                    {children}
                </CustomCell>
            );
        }
    };

    const table = useMemo(() => {
        const objects: (IReportingObject & { types: string[] })[] = [];
        objectTypes.forEach((objType) => {
            if (reportingObjectsByType?.[objType]) {
                objects.push(
                    ...reportingObjectsByType?.[objType]?.map((obj) => {
                        const types: string[] = ['cell'];
                        return { ...obj, types };
                    }),
                );
            }
        });

        const projectCategoriesColumns = projectCategories
            ?.filter((item) => !item.parent_id)
            .map((item) => ({ ...item, value: item.object_name }));

        const columns = [{ value: t('Object type') }, { value: t('Object name') }];

        columns.push(
            // ...categoriesColumns,
            ...projectCategoriesColumns,
        );

        const headerRow = columns.map((item) => {
            const types = ['header'];
            return { ...item, types, readOnly: true };
        });

        const data = objects.map((obj) => {
            const { types } = obj;
            const allCategoriesColumns = [
                // ...categoriesColumns,
                ...projectCategoriesColumns,
            ].map((item) => {
                const parentCategoryId = item.id;
                const validCategories = [
                    // ...categories,
                    ...projectCategories,
                ]?.filter((item) => item.parent_id === parentCategoryId);

                const relation = dataObj2ProjectCategory?.find(
                    (item) =>
                        item.data_object_id === obj.id && validCategories.map((c) => c.id).includes(item.category_id),
                );

                const value = validCategories?.find((item) => item.id === relation?.category_id)?.object_name || '';

                return { ...item, value, relation, types: ['cell'] };
            });
            return [
                { value: obj.object_type, types },
                { ...obj, value: obj.object_name, types },
                ...allCategoriesColumns,
            ];
        });

        const filterData = (
            data: { value: string; [x: string]: any }[][],
            filters: { [x: string]: { filter: string; mode?: string } },
        ) => {
            let result = cloneDeep(data);
            Object.keys(filters).forEach((colIndex) => {
                if (filters?.[colIndex]?.mode === 'any') {
                    result = result?.filter((row) => {
                        return row?.[Number(colIndex)]?.value !== '';
                    });
                } else if (filters?.[colIndex]?.mode === 'no') {
                    result = result?.filter((row) => {
                        return row?.[Number(colIndex)].value === '';
                    });
                } else {
                    result = result?.filter((row) => {
                        return row[Number(colIndex)].value
                            .toLowerCase()
                            .includes(filters[colIndex]?.filter.toLowerCase());
                    });
                }
            });

            return result;
        };

        const filtered = filterData(data, filters);

        filtered.unshift(headerRow);

        const onContextMenu = (e: MouseEvent, cell: any, row: number, col: number) => {
            e.preventDefault();
            const cursorCoords = { x: e.clientX, y: e.clientY };
            const object: IReportingObject = filtered[row][1] as unknown as IReportingObject;
            dispatch(toggleContextMenu({ show: true, cursorCoords, cell, row, col, object }));
        };

        return (
            <Datasheet
                onContextMenu={onContextMenu}
                cellRenderer={cellRenderer}
                data={filtered}
                valueRenderer={(cell: any) => cell.value}
            />
        );
    }, [categories, dataObj2ProjectCategory, dispatch, filters, projectCategories, reportingObjectsByType, t]);

    const menu = useMemo(() => {
        if (!table) return null;
        return <ContextMenu />;
    }, [table]);

    return (
        <TableWrapper>
            {menu}
            {table}
        </TableWrapper>
    );
};

export default Table;
