import styled from 'styled-components/macro';
import img from '../../image/violet.png';

export const BackgroundHeaderContainer = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    padding: 40px 0 30px;
    position: relative;
    width: 100%;
    background-image: url(${img});
    margin-bottom: var(--space-lg);
`;
export const BackgroundHeaderContentContainer = styled.div<{ color?: string }>`
    color: ${(p) => (p.color ? p.color : 'unset')};
    min-height: 140px;
`;

export const AbsoluteImage = styled.img`
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 0;
`;
export const HeaderDescription = styled.div`
    padding-top: var(--space-md);
    font-size: 1rem;
    line-height: 1.187rem;
    font-weight: 400;
    width: 50%;
`;
