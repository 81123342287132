import styled from 'styled-components/macro';

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
`;
export const OptionsWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 100%;
`;
export const WidgetWrapper = styled.div`
    margin-top: var(--space-sm);
`;
