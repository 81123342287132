import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generalReducerValues } from '../General.reducer';
import { useWidgetCurrentOptions } from './useWidgetCurrentOptions';
import { TTimeFreq } from '../lib/esm/components';
import axios from 'axios';

export interface IReportParams {
    reportAlias: string;
    xToken: string;
    plId?: number;
    mainDateRange?: string[];
    objectIds?: number[];
    metrics?: string[];
    detail?: string | null;
    basicReportAdditonalFields?: string[];
    pageLayout?: string;
}

export const useGenerateBackXLS = () => {
    const { token, currentModule, signalBackXLS } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModule);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (signalBackXLS === null || !localCurrentOptions) return;

        const reportParams: IReportParams = { ...signalBackXLS, xToken: token };

        Object.entries(localCurrentOptions).forEach((entry) => {
            const [key, value] = entry;

            switch (key) {
                case 'mainPeriod':
                    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter(
                        (item) => item.id === value?.id,
                    )[0];
                    const time_range = [mainDateRange.period.dateFrom, mainDateRange.period.dateTo];
                    reportParams.mainDateRange = time_range;
                    break;

                case 'pl':
                    reportParams.plId = Number(value?.[0]?.id);
                    break;

                case 'selectedReportingObjectsIds':
                    reportParams.objectIds = value;
                    break;

                case 'selectedMetrics':
                    reportParams.metrics = value;
                    break;

                case 'detail':
                    reportParams.detail = value?.id;
                    break;

                case 'basicReportAdditonalFields':
                    reportParams.basicReportAdditonalFields = value?.map((item: any) => item.id);
                    break;

                case 'pageLayout':
                    reportParams.pageLayout = value?.[0]?.id;
                    break;

                default:
                    break;
            }
        });

        console.log('>>>', reportParams);

        const headers = {
            'Content-type': 'application/json',
        };

        const data = {
            xtoken: token,
            report_type: 'example',
            date_from: '2022-11-01',
            date_to: '2022-11-10',
            data_object_id: 1234,
        };

        axios({ method: 'POST', url: ' https://d5dcvjg4i3p24aocdtqj.apigw.yandexcloud.net', data, headers });
    }, [signalBackXLS]);
};
