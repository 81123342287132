import { PivotItem } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { withLoading } from '../../../../../../../../../tools/API/withLoading';
import { useRerender } from '../../../../../../../../../tools/useRerender';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { TOrdering } from '../../../../../../../OrderSwitcher/interfaces';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../../reducer';
import { Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../../reducer';
import Cell from './Cell';
import HeaderCell from '../HeaderCell';
import { TableWrapper, Tr } from './styles';

const ComplexTable: React.FC = ({}) => {
    const ref = useRef(null);

    const { preparedData, selectedTab, allTenantsData } = useSelector(
        Leasing_TenantOverview_Reference_Widget_Reducer_Values,
    );
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const {
        allMetrics,
        src: { projectCategories },
        lang,
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();

    const [ordering, setOrdering] = useState<{ ordering: TOrdering; primaryType: string }>({
        ordering: 'none',
        primaryType: 'percent',
    });

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;

    const handleOrderToggle = (args: { ordering: TOrdering; id: string }) => {
        setOrdering({ ordering: args.ordering, primaryType: args.id });
    };

    const table = useMemo(() => {
        if (Array.isArray(preparedData)) {
            const header = (
                <thead>
                    <tr>
                        <HeaderCell
                            colSpan={1}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                        >
                            {t('Tenant')}
                        </HeaderCell>
                        {preparedData?.[0]?.metricsData?.map((item: any) => {
                            const meticName = allMetrics?.find((m) => m.id === item.metric)?.text || '';
                            return (
                                <HeaderCell
                                    type={item.metric}
                                    hasSorting
                                    colSpan={2}
                                    handleOrderToggle={handleOrderToggle}
                                    ordering={ordering.ordering}
                                    primaryType={ordering.primaryType}
                                >
                                    {t(meticName)}
                                </HeaderCell>
                            );
                        })}
                    </tr>
                </thead>
            );

            const sortedData = (
                preparedData: any[],
                ordering: {
                    ordering: TOrdering;
                    primaryType: string;
                },
            ) => {
                if (ordering.ordering === 'none') {
                    return cloneDeep(preparedData)?.sort((rowA: any, rowB: any) => {
                        const isSelectedTenant = rowA[0]?.isSelectedTenant;
                        return isSelectedTenant ? -1 : 1;
                    });
                } else {
                    const multiplier = ordering.ordering === 'up' ? 1 : -1;
                    const getNumericData = (row: any) => {
                        return Number(row.metricsData?.find((item: any) => item.metric === ordering.primaryType).main);
                    };
                    return cloneDeep(preparedData)?.sort((rowA: any, rowB: any) => {
                        if (isNaN(getNumericData(rowB))) {
                            return -1;
                        } else {
                            return multiplier * (getNumericData(rowB) - getNumericData(rowA));
                        }
                    });
                }
            };

            const rows = sortedData(preparedData, ordering)
                .map((row) => {
                    const data =
                        row?.metricsData?.reduce((acc: any[], item: any) => {
                            return [
                                ...acc,
                                { value: item?.main, type: 'main', metric: item.metric },
                                { value: item?.deviation, type: 'deviation' },
                            ];
                        }, []) || [];

                    return [{ value: row?.tenantName, isSelectedTenant: row.isSelectedTenant, type: 'name' }, ...data];
                })
                .map((row) => {
                    const cells = row.map((cell: any, i: number) => {
                        return <Cell key={`${cell?.value}mm${i}`} {...cell} />;
                    });
                    const isSelectedTenant = row[0].isSelectedTenant;
                    return <Tr isSelectedTenant={isSelectedTenant}>{cells}</Tr>;
                });

            return (
                <table>
                    {header}
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            return null;
        }
    }, [ordering, preparedData, t]);

    const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: allTenantsData, height: 400 });

    return <WithLoadingTable>{table}</WithLoadingTable>;
};

export default ComplexTable;
