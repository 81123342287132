import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { read, utils, writeFile } from 'xlsx-with-styles';
import { Button } from '../../../lib/esm/components';
import { ISheetCell } from '../../../General.interfaces';
import { sendSimpleMetrics } from '../../../tools/useAnalytics';
import { generalReducerValues, generateBackXLS } from '../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../tools/useWidgetCurrentOptions';

const DownloadXLSXFileButton: React.FC<{
    // grid: ISheetCell[][];
    // disabled?: boolean;
    // bookName?: string;
    currentModule: string;
}> = ({ currentModule }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(generalReducerValues);
    const canSendAnalytics = !user?.permissions.includes('app/client | not-collect-analytics | feature/view');
    const localCurrentOptions = useWidgetCurrentOptions(currentModule);

    const onClick = () => {
        dispatch(generateBackXLS({ reportAlias: currentModule }));
    };

    return <Button text={t('Save to local drive')} onClick={onClick} margin="l_xxxs" />;
};

export default DownloadXLSXFileButton;
