export const BASIC_REPORT_ADDITIONAL_FIELDS = 'basicReportAdditonalFields';

export enum AdditionalFiledsIds {
    AddId = 'addId',
    AddWeekDay = 'addWeekDay',
    AddTimeZone = 'addTimeZone',
}

export const additionalFieldsOptions = [
    { id: AdditionalFiledsIds.AddId, text: 'Add id' },
    { id: AdditionalFiledsIds.AddWeekDay, text: 'Add week day' },
    { id: AdditionalFiledsIds.AddTimeZone, text: 'Add time zone' },
];
