import { IdsOfREportingObjectTypes, optionsOfReportingObjectType } from './constants/optionsOfReportingObjectType';

import FiltersSelect from '../../../Filters/FiltersSelect/FiltersSelect';
import { ISettings } from '../../../UserSettings/interfaces';
import { LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE } from './constants/constants';
import LeasingCrossVisitsWidget from './widgets/LeasingCrossVisitsWidget/LeasingCrossVisitsWidget';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { OptionsWrapper } from './styles';
import Preset from '../../../Preset/Preset';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../../../General.reducer';
import { getReportingObjects } from '../../../../../tools/getReportingObjects';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../tools/useWidgetCurrentOptions';

/**
 * Компоннт дя отображения модуля Leasing:Cross-visits
 */
const CrossVisits = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Leasing:Cross-visits');
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const REPORTING_OBJECTS_VALID_OPTIONS = [t('Zones'), t('Tenants')];

    const validOptions = useMemo(() => {
        if (Object.keys(reportingObjectsByType)) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [reportingObjectsByType, t]);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ObjectsSelect maxSelected={5} validOptions={REPORTING_OBJECTS_VALID_OPTIONS} />
                        <UniversalSelect
                            name={LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE}
                            labelText={t('Objects types')}
                            options={optionsOfReportingObjectType}
                            mode="single"
                        />
                        {localCurrentOptions?.[LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE][0].id ===
                            IdsOfREportingObjectTypes.Tenants && <FiltersSelect validOptions={validOptions} />}
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    LEASING_CROSS_VISITS_REPORTING_OBJECT_TYPE,
                                    'selectedReportingObjectsIds',
                                    'mainPeriod',
                                    'filters',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <LeasingCrossVisitsWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default CrossVisits;
