var DEFAULT_SEPARATOR_MODIFICATION_PREFIX = '--';
var DEFAULT_SEPARATOR_NAME_SPACE_PREFIX = '-';
var DEFAULT_SEPARATOR_ELEMENT_PREFIX = '__';
var DEFAULT_CLASS_BTN_PREFIX = 'button';
var DEFAULT_CLASS_BTN_GROUP_PREFIX = 'button-group';
var DEFAULT_CLASS_BTN_GROUP_ITEM_PREFIX = 'button-group__item';
var DEFAULT_CLASS_ICON_PREFIX = 'icon';
var DEFAULT_CLASS_COLOR_PREFIX = 'color';
var DEFAULT_CLASS_BACKGROUND_PREFIX = 'background';
var DEFAULT_CLASS_SLIDER_PREFIX = 'atoms_slider';
var DEFAULT_CLASS_HOVER_PREFIX = 'atoms-hover';
var DEFAULT_CLASS_FOCUS_PREFIX = 'atoms-focus';
var DEFAULT_CLASS_ACTIVE_PREFIX = 'atoms-active';
var DEFAULT_CLASS_DIVIDER_PREFIX = 'atoms-divider';
var DEFAULT_CLASS_TAG_PREFIX = 'molecules-tag';
var DEFAULT_CLASS_SHADOW_PREFIX = 'shadow';
var DEFAULT_CLASS_INPUT_PREFIX = 'form-control';
var DEFAULT_CLASS_BORDER_RADIUS_PREFIX = 'border-radius';
var DEFAULT_CLASS_SLIDER_ITEM_PREFIX = 'ruler-item';
var DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX = 'molecules_list-item';
var DEFAULT_CLASS_ORGANISMS_LIST_PREFIX = 'organisms_list';
var DEFAULT_MOLECULES_LOADER_IS_ENABLED = true;
var DEFAULT_MOLECULES_LOADER_ICON_TYPE = 'loader';
var DEFAULT_INPUT_MAX_LENGTH = 999;
var PATH_ICON_SPRITE = 'assets/crumbs_atomic-design-form/images/sprites/svg-sprite.svg';
var DEFAULT_CLASS_PROGRESS_BAR_SIZE_PREFIX = 'atoms-progress--size';
var DEFAULT_CLASS_PROGRESS_BAR_ALIGN_TEXT_PREFIX = 'text';
var DEFAULT_CLASS_PROGRESS_BAR_MAX_VALUE = 100;
var MINUTE_IN_MILLIS = 60000;

export { DEFAULT_CLASS_ACTIVE_PREFIX, DEFAULT_CLASS_BACKGROUND_PREFIX, DEFAULT_CLASS_BORDER_RADIUS_PREFIX, DEFAULT_CLASS_BTN_GROUP_ITEM_PREFIX, DEFAULT_CLASS_BTN_GROUP_PREFIX, DEFAULT_CLASS_BTN_PREFIX, DEFAULT_CLASS_COLOR_PREFIX, DEFAULT_CLASS_DIVIDER_PREFIX, DEFAULT_CLASS_FOCUS_PREFIX, DEFAULT_CLASS_HOVER_PREFIX, DEFAULT_CLASS_ICON_PREFIX, DEFAULT_CLASS_INPUT_PREFIX, DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX, DEFAULT_CLASS_ORGANISMS_LIST_PREFIX, DEFAULT_CLASS_PROGRESS_BAR_ALIGN_TEXT_PREFIX, DEFAULT_CLASS_PROGRESS_BAR_MAX_VALUE, DEFAULT_CLASS_PROGRESS_BAR_SIZE_PREFIX, DEFAULT_CLASS_SHADOW_PREFIX, DEFAULT_CLASS_SLIDER_ITEM_PREFIX, DEFAULT_CLASS_SLIDER_PREFIX, DEFAULT_CLASS_TAG_PREFIX, DEFAULT_INPUT_MAX_LENGTH, DEFAULT_MOLECULES_LOADER_ICON_TYPE, DEFAULT_MOLECULES_LOADER_IS_ENABLED, DEFAULT_SEPARATOR_ELEMENT_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_SEPARATOR_NAME_SPACE_PREFIX, MINUTE_IN_MILLIS, PATH_ICON_SPRITE };
