import { IMsDataObject, IPeriod, IReportingObject } from '../../../../../../../../General.interfaces';
import { DateTime } from 'luxon';

interface IArgs {
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    msDataObjects: IMsDataObject[];
    mainDateRanges: IPeriod[];
    mainPeriod: IPeriod;
    zoneMarker: string;
}

/**
 * Функция для получения этажей для зоны
 * @param reportingObjectsByTypeAndMarker Объект отчетных объектов где ключ это [тип:маркер]
 * @param mainDateRanges Периоды дат для главного периода
 * @param msDataObjects Данные для отчетных объектов
 * @param mainPeriod Главный период
 * @param zoneMarker Маркер зоны
 */
const filterZoneValidFloors = (args: IArgs): null | IReportingObject[] => {
    const { mainDateRanges, mainPeriod, zoneMarker, msDataObjects, reportingObjectsByTypeAndMarker } = args;
    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod.id);

    if (mainPeriodDateRnage) {
        const mainPeriodDateFrom = DateTime.fromISO(mainPeriodDateRnage.period?.dateFrom || '');
        const mainPeriodDateTo = DateTime.fromISO(mainPeriodDateRnage.period?.dateTo || '');

        const validFloors = msDataObjects?.filter((msDataObject) => {
            const msDataObjectDateFrom = DateTime.fromISO(msDataObject.date_from);
            const msDataObjectDateTo = DateTime.fromISO(msDataObject.date_to);

            return (
                msDataObject.object_type === 'zone' &&
                msDataObjectDateFrom <= mainPeriodDateFrom &&
                msDataObjectDateTo >= mainPeriodDateTo &&
                msDataObject.marker === zoneMarker
            );
        });

        if (validFloors.length) {
            const result: IReportingObject[] = [];
            validFloors.forEach((floor) => {
                if (reportingObjectsByTypeAndMarker[`floor:${floor.floor}`]) {
                    result.push(reportingObjectsByTypeAndMarker[`floor:${floor.floor}`]);
                }
            });

            if (result.length) return result;
        }
    }

    return null;
};

export default filterZoneValidFloors;
