import React from 'react';
import cn from 'classnames';

import { ISwitcherProps } from '../../configurations';
import * as constants from '../../constants/constants';
import '../../styles/styles.scss';

const Switcher: React.FC<ISwitcherProps> = ({ compact, ...props }) => {
    const { DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_CLASS_SWITCHER_FORM, DEFAULT_CLASS_SWITCHER } = constants;

    const switcherFormClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SWITCHER_FORM]: true,
        });
    }, [props]);

    const switcherClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SWITCHER]: true,
            [`${DEFAULT_CLASS_SWITCHER}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: compact,
        });
    }, [props]);

    return (
        <div className={switcherFormClassName}>
            <div className={switcherClassName}>{props.children}</div>
        </div>
    );
};

export default Switcher;
