import React from 'react';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';
import { CellWrapper, Percent, Value } from '../styles';

const Cell: React.FC<{ value?: number; type?: string; name?: string }> = ({ value, type, name }) => {
    return (
        <CellWrapper>
            {type === 'percent' && (
                <Percent>{value && !isNaN(Number(value)) ? `${numberWithSpaces(value.toFixed(2))} %` : '–'}</Percent>
            )}
            {type !== 'percent' && !name && <Value isMain={true}>{numberWithSpaces(value?.toFixed(2))}</Value>}
            {name && <Value isMain={false}>{name}</Value>}
        </CellWrapper>
    );
};

export default Cell;
