import { Icon, Stack } from '@fluentui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleWrapper from '../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../Wrappers/WrapperContainer/WrapperContainer';
import { Cont, Dash, ErrorText, iconStyles, MainError, Title } from './styles';

interface IProps {
    /** Ошибка */
    error: Error;
}

/** Компонент для отображения ошибки при краше */
const ErrorBoundaryFallback: FC<IProps> = ({ error }) => {
    const { t } = useTranslation();

    console.log('Сбой при работе приложения >>>>>>>', error);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <Cont>
                    <Stack horizontal={true} tokens={{ childrenGap: 80 }}>
                        <Icon styles={iconStyles} iconName="CoffeeScript" />
                        <Title>{t('Whoops, something went wrong')}.</Title>
                    </Stack>
                    <MainError>
                        <span>{t('Error')}</span>
                    </MainError>
                    <Dash />
                    <ErrorText>{error.message}</ErrorText>
                </Cont>
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default ErrorBoundaryFallback;
