import styled from 'styled-components/macro';
export const ListItemBullets = styled.li`
    border-bottom: 1px solid var(--color-border-gray);
    padding: var(--space-sm) 0;
    margin-bottom: 0;
    line-height: 20px;
    position: relative;
    margin-left: var(--space-sm);
    :before {
        position: absolute;
        left: -18px;
        top: 28px;
        content: '';
        height: 6px;
        width: 6px;
        border-radius: 3px;
        background: #4572e6;
    }
`;
export const ListBullets = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 0;
`;
export const Description = styled.p`
    max-width: 66%;
    margin-top: 0;
`;
export const Image = styled.img`
    width: 100%;
`;
