import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Title, Overlay, Modal, Header, contentStyles, Body } from './Modals.styles';
import { IModalProps } from './Modals.interfaces';
import Button from '../Button';
import Icon from '../Icon';

/**
 * Обертка для всех модалок.
 * @param modalStatus Состояние модалки, управляемое извне.
 * @param title Название модалки.
 */
const Modals: React.FC<IModalProps> = ({ topColor, title, ...props }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        props.modalStatus.show ? open() : close();
    }, [props.modalStatus]);

    useEffect(() => {
        !isModalOpen && props.closeModal && props.closeModal();
    }, [isModalOpen]);

    useEffect(() => {
        function listener(e: globalThis.KeyboardEvent) {
            if (e.code === 'Escape') close();
        }
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, []);

    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);
    const onOKClick = () => {
        setModalOpen(false);
    };

    return (
        <Overlay tabIndex={0} isActive={isModalOpen} onClick={close}>
            <Modal topColor={topColor} isActive={isModalOpen} onClick={(e) => e.stopPropagation()}>
                <Header>
                    <Title>{title}</Title>
                    <Button onClick={close} theme="icon" type="base">
                        <Icon type="close" size="xxs" />
                    </Button>
                </Header>
                <Body>{props.children}</Body>
            </Modal>
        </Overlay>
    );
};

export default Modals;
