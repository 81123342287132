import styled from 'styled-components/macro';

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    /* height: 40px; */
    /* padding-right: 15px;
    padding-left: 15px; */
    /* flex: 0 0 100%; */
    max-width: 100%;
`;
export const OptionsWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-start;
    max-width: 100%;
    flex-wrap: wrap;
`;
