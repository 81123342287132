import { createContext } from 'react';
import {
    IDataObj2ProjectCategory,
    IDateRange,
    IPeriod,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
} from '../../../../../../../General.interfaces';
import { IExtendedReportingObject, TAvailableReportingObjectsType } from './interfaces';

interface IContext {
    storeSelectedReportingObjectsTypeTrans: (type: TAvailableReportingObjectsType) => void;
    selectedReportingObjectsType: TAvailableReportingObjectsType;
    dataObj2ProjectCategory: IDataObj2ProjectCategory[];
    mainDateRanges?: IDateRange[];
    filters?: number[];
    mainPeriod?: IPeriod;
    isLoading: boolean;
    daysInPeriod: number;
    isError: boolean;
    tenant2FloorByTenantId: {
        [tenantId: string]: ITenant2FloorRelation[];
    };
    tenant2ZoneByTenantId: {
        [tenantId: string]: ITenant2ZoneRelation[];
    };
    reportingObjectsByType: {
        [x: string]: IReportingObject[];
    };
    reportingObjectsById: {
        [id: string]: IReportingObject;
    };
    extendedReportingObjectsById: {
        [id: string]: IExtendedReportingObject;
    } | null;
}

export const IntersectionsWithTenantsContext = createContext<null | IContext>(null);
