import React, { ComponentType, ReactNode, useEffect, useRef } from 'react';
import LoadingBox from '../../cabinet/components/LoadingBox/LoadingBox';
import { TLoadingData } from '../../General.interfaces';

type Base = {};

export const withLoading = <TProps extends Base>(
    Component: ComponentType<TProps>,
    params: { data: any; height?: number },
) => {
    return (props: TProps & { children?: ReactNode }) => {
        if (params.data?.['status'] === 'Loading') {
            return <LoadingBox height={params.height} hasBorder text={params.data?.['message'] || ''}></LoadingBox>;
        } else if (params.data?.['status'] === 'Error') {
            return (
                <LoadingBox height={params.height} isError hasBorder text={params.data?.['message'] || ''}></LoadingBox>
            );
        } else if (params.data?.['status'] === 'No data') {
            return (
                <LoadingBox height={params.height} noData hasBorder text={params.data?.['message'] || ''}></LoadingBox>
            );
        } else {
            return <Component {...props} />;
        }
    };
};
