import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { Leasing_TenantOverview_Reach_Widget_Reducer_Values, reloadWidget, storeSelectedCategory } from './reducer';
import { Tr, TableWrapper, WidgetWrapper, PivotWrapper } from './styles';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import Cell from './components/Cell';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../reducer';
import WidgetPeriodWrapper from '../../../../../Wrappers/WidgetPeriodWrapper/WidgetPeriodWrapper';
import HeaderCell from './components/HeaderCell';
import { TOrdering } from '../../../../../OrderSwitcher/interfaces';
import { cloneDeep } from 'lodash';
import { PivotItem } from '@fluentui/react';
import Pivot from '../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const Reach: React.FC = ({}) => {
    const ref = useRef(null);

    const { preparedData, selectedCategory } = useSelector(Leasing_TenantOverview_Reach_Widget_Reducer_Values);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const {
        src: { projectCategories },
        lang,
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const dispatch = useDispatch();

    const [ordering, setOrdering] = useState<{ ordering: TOrdering; primaryType: string }>({
        ordering: 'none',
        primaryType: 'percent',
    });

    useEffect(() => {
        if (projectCategories?.length && !selectedCategory) {
            const parentCategories = projectCategories?.filter((item) => !item.parent_id);
            dispatch(storeSelectedCategory(String(parentCategories[0].id)));
        }
    }, [projectCategories]);

    const rerender = useRerender(preparedData);

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;
    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];

    const handleOrderToggle = (args: { ordering: TOrdering; id: string }) => {
        setOrdering({ ordering: args.ordering, primaryType: args.id });
    };

    const table = useMemo(() => {
        if (Array.isArray(preparedData)) {
            const header = (
                <thead>
                    <tr>
                        <HeaderCell
                            type="tenant"
                            colSpan={2}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                        >
                            {t('Tenant (Traffic)')}
                        </HeaderCell>
                        <HeaderCell
                            type="percent"
                            colSpan={1}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                        >
                            {t('Zone → Tenant')}
                        </HeaderCell>
                        <HeaderCell
                            type="zone"
                            colSpan={2}
                            handleOrderToggle={handleOrderToggle}
                            ordering={ordering.ordering}
                            primaryType={ordering.primaryType}
                        >
                            {t('Zone (Traffic)')}
                        </HeaderCell>
                    </tr>
                </thead>
            );

            const sortedData = (
                preparedData: any[],
                ordering: {
                    ordering: TOrdering;
                    primaryType: string;
                },
            ) => {
                if (ordering.ordering === 'none') {
                    return cloneDeep(preparedData)?.sort((rowA: any[], rowB: any[]) => {
                        const isSelectedTenant = rowA[0].isSelectedTenant;
                        return isSelectedTenant ? -1 : 1;
                    });
                } else {
                    const multiplier = ordering.ordering === 'up' ? 1 : -1;
                    return cloneDeep(preparedData)?.sort((rowA: any[], rowB: any[]) => {
                        const valueA = rowA?.find((cell: any) => cell.type === ordering.primaryType).value;
                        const valueB = rowB?.find((cell: any) => cell.type === ordering.primaryType).value;
                        return multiplier * (valueB - valueA);
                    });
                }
            };

            const rows = sortedData(preparedData, ordering).map((row, i) => {
                const cells = row.map((cell: any, i: number) => {
                    return <Cell key={`${cell?.value}mm${i}`} {...cell} />;
                });
                const isSelectedTenant = row[0].isSelectedTenant;
                return (
                    <Tr key={`rrrow ${i}`} isSelectedTenant={isSelectedTenant}>
                        {cells}
                    </Tr>
                );
            });

            return (
                <table>
                    {header}
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            return null;
        }
    }, [ordering, preparedData, t]);

    const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: preparedData, height: 400 });

    const handleLinkClick = (item?: PivotItem) => {
        item?.props?.itemKey && dispatch(storeSelectedCategory(item.props.itemKey));
    };

    const parentCategories = projectCategories?.filter((item) => !item.parent_id);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Reach to the tenant. Comparison with category')}
                    <WidgetAdditionalControls
                        widgetName={'Reach to the tenant. Comparison with category'}
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
                <PivotWrapper>
                    <Pivot widgetName="ReachToTheTenant" selectedKey={selectedCategory} onLinkClick={handleLinkClick}>
                        {parentCategories.map((element) => (
                            <PivotItem
                                key={element.id + Math.random()}
                                headerText={t(element.object_name)}
                                itemKey={String(element.id)}
                            />
                        ))}
                    </Pivot>
                </PivotWrapper>
            </WidgetTitleWrapper>
            <WidgetPeriodWrapper>{shortStringDate(mainDateRange?.period, lang)}</WidgetPeriodWrapper>
            <WithLoadingTable>{table}</WithLoadingTable>
        </WidgetWrapper>
    );
};

export default Reach;
