import { ColorsEnum } from '../../constants/enums';
import { TSeries } from '../../interfaces';
import { isUndefined } from 'lodash';

/**
 * Функция для генерации настроек оси Y
 * @param maxColors максимальное количесвто цветов
 * @param series все серии
 * @returns массив или объект с настройками оси Y
 */
const generateYAxis = (series: TSeries[], maxColors: number) => {
    const yAxisCount = series?.reduce((acc, value) => {
        if (!isUndefined(value.yAxis)) acc += 1;
        return acc;
    }, 0);

    if (yAxisCount > 1) {
        return series.map((element, index) => {
            const colorIndex = index > maxColors ? index % 10 : index;
            return {
                labels: { style: { color: element.color || ColorsEnum[`index-${colorIndex}`] } },
                opposite: index % 2 === 0 ? false : true,
                title: { text: null },
                softMin: 0,
            };
        });
    }
    return {
        labels: { style: { color: '#666666;' } },
        title: { text: null },
        softMin: 0,
    };
};

export default generateYAxis;
