import { StageWrapper, Floor, Wrapper, RatingsWrapper, MapsWrapper } from './styles';
import useStageHandlers from './hooks/useStageHandlers';
import FinalLayer from '../StageLayers/FinalLayer';
import FloorsContext from './context';
import { IProps } from './interfaces';
import { Stage } from 'react-konva';
import { FC } from 'react';
import Loader from '../Loader/Loader';
import useStageInitialData from './hooks/useStageInitialData';

/**
 * Компонент для отображения этажей выбранной локации на холсте
 * @param stageWidthCalculationFactor коэффициент для вычисления ширины холста (Используется,если
 * Вы собираетесь отобразить несколько комопонент или Вы не хотите использовать все свободное простарнство)
 * @param reportingObjectsByTypeAndMarker Все отчетные объекты, где ключ это тип и маркер отчетного объекта
 * @param selectedReportingObjectType Выбраннвый тип отчетного объекта
 * @param selectedGroupOfZones Выбранная группа зон
 * @param selectedLayerType Выбранный тип слоя
 * @param availableMarkers Все доступные маркеры для запроса данных
 * @param RatingsComponent Компонент рейтингов
 * @param colorsByMarker Объект цветов, где ключ это маркер отчетного объекта
 * @param selectedMarker Выбранный маркер
 * @param showPerimeters Показать \ скрыть слой периметров
 * @param showAllLabels Показать \ скрыть все лейблы
 * @param stageAreaSize Размеры холста
 * @param selectObject Функция для выбора маркера объекта
 * @param joinFloors Флаг отвечающий за объеденение за объеденение планов этажей
 * @param showPlans Показать \ скрыть картинку плана
 * @param layers Все слои
 * @param plans Все планы
 */
const Floors: FC<IProps> = ({
    stageWidthCalculationFactor = 1,
    reportingObjectsByTypeAndMarker,
    selectedReportingObjectType,
    selectedGroupOfZones,
    labelAdditionalText,
    joinFloors = false,
    selectedLayerType,
    RatingsComponent,
    showPlans = true,
    availableMarkers,
    showPerimeters,
    colorsByMarker,
    selectedMarker,
    stageAreaSize,
    showAllLabels,
    selectObject,
    showLoader,
    layers,
    plans,
}) => {
    /** Данные, необходимые для отображения планов */
    const {
        changeHoveredMarker,
        changeStageScale,
        plansYPosition,
        hoveredMarker,
        extendedPlans,
        refsOfStages,
        stageHeight,
        stageWidth,
        stageScale,
        widestPlan,
    } = useStageInitialData({
        stageWidthCalculationFactor,
        stageAreaSize,
        plans,
    });
    /** Все обработчики событий */
    const {
        stageDoubleClickHandler,
        stageClickHandler,
        stageWheelHandler,
        changeStageCursor,
        onMouseLeave,
        onMouseOver,
    } = useStageHandlers({
        changeHoveredMarker,
        changeStageScale,
        refsOfStages,
        widestPlan,
        stageWidth,
    });

    const context = {
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectType,
        selectedGroupOfZones,
        labelAdditionalText,
        selectedLayerType,
        changeStageCursor,
        availableMarkers,
        colorsByMarker,
        showPerimeters,
        selectedMarker,
        hoveredMarker,
        showAllLabels,
        selectObject,
        onMouseOver,
        onMouseLeave,
        stageScale,
        showPlans,
        layers,
    };

    return (
        <Wrapper>
            <MapsWrapper>
                {joinFloors ? (
                    <StageWrapper marginBottom={0}>
                        {showLoader && <Loader />}
                        <Stage
                            ref={(el) => (refsOfStages.current![0] = el)}
                            onDblClick={stageDoubleClickHandler}
                            scaleX={stageScale.stageScale}
                            scaleY={stageScale.stageScale}
                            onWheel={stageWheelHandler}
                            onClick={stageClickHandler}
                            x={stageScale.stageX}
                            y={stageScale.stageY}
                            height={stageHeight}
                            width={stageWidth}
                            draggable
                        >
                            <FloorsContext.Provider value={context}>
                                {extendedPlans.map((plan, i) => {
                                    return (
                                        <FinalLayer
                                            stage={refsOfStages.current![0]}
                                            yCoordinate={plansYPosition[i]}
                                            showPlans={showPlans}
                                            key={plan.floor - i}
                                            plan={plan}
                                        />
                                    );
                                })}
                            </FloorsContext.Provider>
                        </Stage>
                    </StageWrapper>
                ) : (
                    <div>
                        {extendedPlans.map((plan, planIndex) => {
                            const planRatio = plan.width / plan.height;
                            const stageHeight = Math.round(stageWidth / planRatio);

                            return (
                                <StageWrapper marginBottom={10} key={`canvas${planIndex}${plan.floor}`}>
                                    {showLoader && <Loader />}

                                    <Floor>{plan.floor}</Floor>
                                    <Stage
                                        ref={(el) => (refsOfStages.current![planIndex] = el)}
                                        onDblClick={stageDoubleClickHandler}
                                        scaleX={stageScale.stageScale}
                                        scaleY={stageScale.stageScale}
                                        onWheel={stageWheelHandler}
                                        onClick={stageClickHandler}
                                        id={`canvas${planIndex}`}
                                        x={stageScale.stageX}
                                        y={stageScale.stageY}
                                        height={stageHeight}
                                        width={stageWidth}
                                        draggable
                                    >
                                        <FloorsContext.Provider value={context}>
                                            <FinalLayer
                                                stage={refsOfStages.current![planIndex]}
                                                stageIndex={planIndex}
                                                showPlans={showPlans}
                                                plan={plan}
                                            />
                                        </FloorsContext.Provider>
                                    </Stage>
                                </StageWrapper>
                            );
                        })}
                    </div>
                )}
            </MapsWrapper>
            {stageWidthCalculationFactor < 1 ? (
                <RatingsWrapper height={stageHeight + extendedPlans.length * 10 - 4}>
                    <RatingsComponent
                        selectedName={
                            selectedMarker
                                ? reportingObjectsByTypeAndMarker?.[
                                      `${selectedReportingObjectType}:${selectedMarker}`
                                  ]?.['name'] || selectedMarker
                                : null
                        }
                        colorsByMarker={colorsByMarker}
                        onMouseLeave={onMouseLeave}
                        onMouseOver={onMouseOver}
                        showLoader={showLoader}
                    />
                </RatingsWrapper>
            ) : null}
        </Wrapper>
    );
};

export default Floors;
