import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-xs);
`;

export const Wrapper = styled.div`
    max-width: 500px;
`;

export const SettingsWrapper = styled.div`
    background-color: #f9f9f9;
    max-height: 300px;
    padding: 15px;
    overflow-y: auto;
    margin-top: var(--space-xs);
`;

export const SettingsTitle = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 500;
`;

export const SettingsSubtitle = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
`;

export const SettingsText = styled.div`
    color: #999;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SettingsBodyWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const SettingsBodyItem = styled.div`
    flex: 0 0 50%;
    padding-right: 20px;
    overflow: hidden;
`;
