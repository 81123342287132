import { IconButton, IIconProps, TooltipHost } from '@fluentui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {}

/**
 * Компонент для отображения кнопки с загрузкой xlsx файла
 */
const XLSXDownload: FC<IProps> = ({}) => {
    const { t } = useTranslation();
    const iconPrtops: IIconProps = {
        iconName: 'ExcelLogo16',
        styles: { root: { color: 'rgb(180, 180, 195)', fontSize: '16px' } },
    };

    return (
        <TooltipHost content={t('Download XLSX')}>
            <IconButton iconProps={iconPrtops} styles={{ root: { width: '22px' } }} />
        </TooltipHost>
    );
};

export default XLSXDownload;
