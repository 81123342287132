import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, storeUser, storeAllMetrics, storeLocations } from '../../General.reducer';
import { allMetrics, permissionsMap } from '../../constants/metricsNames';
import { requestLocations } from '../../lib/esm/components';
import { requestCurr } from './requestCurrent';

export const useUserAPI = () => {
    const d = useDispatch();
    const { token, user } = useSelector(generalReducerValues);

    useEffect(() => {
        if (token) {
            console.log('process.env: ', process.env);
            requestCurr(token).then((res) => {
                if (res.status === 200) {
                    d(storeUser(res.data));
                    requestLocations(token).then((res) => {
                        d(storeLocations(res.data));
                    });
                } else {
                    localStorage.removeItem('xtoken');
                    window.location.reload()
                }
            });
        }
    }, [token]);

    useEffect(() => {
        if (user?.permissions) {
            const metrics = allMetrics.filter((m) => {
                const p = permissionsMap[m.section];
                return !p || user.permissions.includes(p);
            });
            d(storeAllMetrics(metrics));
        }
    }, [user]);
};
