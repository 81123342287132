import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Select } from '../../../../../../../../../lib/esm/components';
import { IObject, TSelectedOptions } from '../../../../../../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues } from '../../../../../../../UserSettings/reducer';
import {
    storeSelectedGroup,
    storeSelectedZoneId,
    Maps_MapsOverview_Module_Reducer_Values,
    storeSelectedRelationId,
    storeSelectedPlaceId,
} from '../../../../reducer';
import { SelectsWrapper } from './styles';

const Selects: React.FC = () => {
    const { t } = useTranslation();
    const {
        currentOptions: { comparePeriods, compareDateRanges },
    } = useSelector(userSettingsReducerValues);
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    const { selectedObjectType, zonesByGroup, selectedGroupId, selectedZoneId, selectedRelationId, selectedPlaceId } =
        useSelector(Maps_MapsOverview_Module_Reducer_Values);

    const handGroupSelect = (args: TSelectedOptions) => {
        const groupId = String(args[0].id);
        dispatch(storeSelectedGroup(groupId));
        const selectedZoneId = zonesByGroup[groupId][0].id;
        dispatch(storeSelectedZoneId(String(selectedZoneId)));
    };

    const handZoneSelect = (args: TSelectedOptions) => {
        const selectedZoneId = args[0].id;
        dispatch(storeSelectedZoneId(String(selectedZoneId)));
    };

    const handleRelationSelect = (args: TSelectedOptions) => {
        dispatch(storeSelectedRelationId(String(args[0].id)));
    };

    const handPlaceSelect = (args: TSelectedOptions) => {
        dispatch(storeSelectedPlaceId(Number(args[0].id)));
    };

    const relationSelect = useMemo(() => {
        if (selectedObjectType !== 'passway') return null;

        const options = [
            { id: 'all', text: t('All') },
            { id: 'zone', text: t('Zones') },
            { id: 'place', text: t('Places') },
            // { id: 'floor', text: t('Floors') },
            { id: 'location', text: t('Location') },
        ];

        const outsideSelected = options?.filter((item) => item.id === selectedRelationId);

        return (
            <Select
                iconType="chevron-down"
                options={options}
                labelText={t('Select relations')}
                showFilter={false}
                mode="single"
                margin="r_sm"
                handleSelect={handleRelationSelect}
                outsideSelected={outsideSelected}
                text={'null'}
                dropdownWidth="sm"
            />
        );
    }, [selectedObjectType, t, selectedRelationId]);

    const groupsSelect = useMemo(() => {
        if (selectedObjectType === 'zone' || (selectedObjectType === 'passway' && selectedRelationId === 'zone')) {
            const options = Object.keys(zonesByGroup).map((groupMarker) => {
                return { id: groupMarker, text: groupMarker };
            });

            const outsideSelected = options?.filter((item) => item.id === selectedGroupId);

            return (
                <Select
                    iconType="chevron-down"
                    options={options}
                    labelText={t('Select zones group')}
                    showFilter={false}
                    mode="single"
                    margin="r_sm"
                    handleSelect={handGroupSelect}
                    outsideSelected={outsideSelected}
                    text={'null'}
                />
            );
        } else {
            return null;
        }
    }, [selectedObjectType, selectedRelationId, zonesByGroup, t, selectedGroupId]);

    const zoneSelect = useMemo(() => {
        if (selectedObjectType === 'passway' && selectedRelationId === 'zone') {
            const options = selectedGroupId
                ? zonesByGroup[selectedGroupId].map((zone) => ({ id: zone.id, text: zone.name }))
                : [];

            const outsideSelected = options?.filter((item) => String(item.id) === String(selectedZoneId));

            return (
                <Select
                    iconType="chevron-down"
                    options={options}
                    labelText={t('Select zone')}
                    showFilter={false}
                    mode="single"
                    margin="r_sm"
                    handleSelect={handZoneSelect}
                    outsideSelected={outsideSelected}
                    text={'null'}
                />
            );
        } else return null;
    }, [selectedObjectType, selectedRelationId, selectedGroupId, zonesByGroup, t, selectedZoneId]);

    const placeSelect = useMemo(() => {
        if (selectedObjectType === 'passway' && selectedRelationId === 'place') {
            const allPlaces = reportingObjectsByType['place'];
            const options = allPlaces.map((place) => ({ id: place.id, text: place.name }));

            const outsideSelected = options?.filter((item) => String(item.id) === String(selectedPlaceId));

            return (
                <Select
                    iconType="chevron-down"
                    options={options}
                    labelText={t('Select place')}
                    showFilter={false}
                    mode="single"
                    margin="r_sm"
                    handleSelect={handPlaceSelect}
                    outsideSelected={outsideSelected}
                    text={'null'}
                />
            );
        } else return null;
    }, [selectedObjectType, selectedRelationId, reportingObjectsByType, t, handZoneSelect, selectedPlaceId]);

    return (
        <SelectsWrapper>
            {relationSelect}
            {groupsSelect}
            {zoneSelect}
            {placeSelect}
        </SelectsWrapper>
    );
};

export default Selects;
