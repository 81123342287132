import { DateTime } from 'luxon';
import { IResponseItem } from './interfaces';
import { TSeries } from '../../../../../../Charts/Dynamics/interfaces';
import { stringDate } from '../../../../../../../../tools/Strings/stringDate';
import { TLang } from '../../../../../../SideBar/configurations';

/**
 * Функция для генерации серий для графика
 * @param rawMetricsByDate Объект сырых данных метрик, где ключ это дата, за которую была получена эта метрика
 * @param lang язык приложения
 * @returns Массив серий
 */
const generateSeriesData = (rawMetricsByDate: { [date: string]: IResponseItem[] }[], lang: TLang): TSeries[] => {
    const series: TSeries[] = [];

    rawMetricsByDate.forEach((element, index) => {
        const [date] = Object.keys(element);
        const seriesDataPoints = element[date].map((element) => {
            return {
                name: stringDate(element.time, lang, '', 'MMMM yyyy'),
                x: DateTime.fromISO(element.time).toMillis(),
                y: element.value,
            };
        });
        const newSeries: TSeries = {
            name: stringDate(date, lang, '', 'yyyy'),
            data: seriesDataPoints,
            isMain: index === 0,
            type: 'column',
            id: date,
        };
        series.push(newSeries);
    });

    return series;
    // const xValues: number[] = [];
    // return rowMetricsByDate.map((element, metricByDateIndex) => {
    //     let data: { [key: string]: any }[] = [];
    //     let date = '';
    //     Object.keys(element).map((dateKey: string) => {
    //         date = dateKey;
    //         data = element[date].map((responseItem, itemIndex) => {
    //             if (metricByDateIndex === 0) xValues.push(DateTime.fromISO(responseItem.time).toMillis());
    //             return {
    //                 name: stringDate(responseItem.time, lang, '', 'MMMM yyyy'),
    //                 x: xValues[itemIndex],
    //                 y: responseItem.value,
    //             };
    //         });
    //     });
    //     return {
    //         name: stringDate(date, lang, '', 'yyyy'),
    //         type: 'column',
    //         data,
    //     };
    // });
};
export default generateSeriesData;
