import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import getRanges from '../../tools/getRanges';
import GridHeader from '../GridHeader/GridHeader';
import { Cell, MonthTitle, Wrapper, Year } from './styles';
import { generalReducerValues } from '../../../../../../../../../../../General.reducer';
import MonthsGrid from '../MonthsGrid/MonthsGrid';
import { TMode } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface IProps {
    /** Текущая дата */
    date: DateTime;
    /** Функция для смены режима работы календаря */
    setModeTrans: (mode: TMode) => void;
    /** Функция для записи сдвига */
    setShiftTrans: (shift: number) => void;
}

/**
 * Компонент для отображения календаря за год
 */
const YearsGrid: FC<IProps> = ({ date, setModeTrans, setShiftTrans }) => {
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    const onMonthTitleClick = (month: DateTime) => () => {
        const dateNow = DateTime.now().startOf('month');
        const shift = month.startOf('month').diff(dateNow, ['months']).toObject().months || 0;
        setModeTrans('months');
        setShiftTrans(shift);
    };

    const allMonthsOfCalendar = useMemo(() => {
        return getRanges({ date, mode: 'year', durationType: 'months' });
    }, [date]);

    const months = useMemo(() => {
        return allMonthsOfCalendar.map((month) => {
            return (
                <Cell key={month.toMillis()}>
                    <MonthTitle onClick={onMonthTitleClick(month)}>{t(month.toFormat('MMMM'))}</MonthTitle>
                    <MonthsGrid date={month} yearView={true} />
                </Cell>
            );
        });
    }, [allMonthsOfCalendar, lang, t]);

    return (
        <Wrapper>
            <GridHeader date={date} mode={'years'} />
            <Year>{months}</Year>
        </Wrapper>
    );
};

export default YearsGrid;
