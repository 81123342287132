import {
    DefaultText,
    Dot,
    DotsWrapper,
    HeaderCellWrapper,
    MetricValuesWrapper,
    NameWrapper,
    StyledTable,
    THead,
    Td,
    Th,
    Tr,
} from './styles';
import { FC, createRef, memo, useEffect, useState } from 'react';

import ContextualMenu from '../../../../../../../ContextualMenu/ContexttualMenu';
import DropdownBody from './components/DropdownBody/DropdownBody';
import { IProps } from './interfaces';
import { IReportingObject } from '../../../../../../../../../General.interfaces';
import { ITableSortData } from '../../interfaces';
import OrderSwitcher from '../../../../../../../OrderSwitcher/OrderSwitcher';
import { cloneDeep } from 'lodash';
import filterReportingObjects from '../../../../../../../Filters/tools/filterReportingObjects';
import filterTenants from './tools/filterTenants';
import getDifferenceBetweenNumbers from '../../../../../../../../../tools/getDifferenceBetweenNumbers';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import sortTenants from './tools/sortTenants';
import { useTranslation } from 'react-i18next';

/**
 * Комопонент для отображения таблицы
 * @param openAdditionalTenantInformation функция для перехода на новую страницу с другим виджетом
 * @param storeTableSortDataTrans функция для записи новых данных сортировки таблицы
 * @param tenant2FloorByTenantId Связь арендатора и этажа
 * @param tenant2ZoneByTenantId Связь арендатора и зоны
 * @param dataObj2ProjectCategory Связь всех объектов с категорями
 * @param reportingObjectsById Все отчетные объекты, где ключ это id отчетного объекта
 * @param extendedTenantsById расширенные данные арендаторов, где ключ - это id арнедатора
 * @param selectedMetricsIds id выбранных метрик
 * @param extendedTenants расширенные арендаторы
 * @param mainDateRanges Периоды дат для главного периода
 * @param tableSortData Данные для сортировки таблицы
 * @param allMetrics все метрики
 * @param mainPeriod Основной период

 * @param filters id всех фильтров
 */
const Table: FC<IProps> = memo(
    ({
        openAdditionalTenantInformation,
        storeTableSortDataTrans,
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        extendedTenantsById,
        selectedMetricsIds,
        extendedTenants,
        mainDateRanges,
        tableSortData,
        allMetrics,
        mainPeriod,
        filters,
    }) => {
        const [contextMenuStatus, setContextMenuStatus] = useState<{ show: boolean; tenantId: null | number }>({
            show: false,
            tenantId: null,
        });
        const [filteredTenants, setFilteredTenants] = useState<IReportingObject[]>([]);
        const [sortedTenants, setSortedTenants] = useState<IReportingObject[]>([]);
        const [refs, setRefs] = useState([]);
        const { t } = useTranslation();

        useEffect(() => {
            setRefs((refs) =>
                Array(sortedTenants.length)
                    .fill(null)
                    .map((_, i) => refs[i] || createRef()),
            );
        }, [sortedTenants]);

        useEffect(() => {
            setFilteredTenants(extendedTenants.map((element) => element.tenantData));
        }, [extendedTenants]);

        useEffect(() => {
            setSortedTenants(filteredTenants);
        }, [filteredTenants]);

        /** Получение отфильтрованных арендаторов */
        useEffect(() => {
            if (
                Object.keys(tenant2FloorByTenantId).length &&
                Object.keys(tenant2ZoneByTenantId).length &&
                mainDateRanges &&
                mainPeriod
            ) {
                const tenants = filterReportingObjects({
                    initialArray: extendedTenants.map((element) => element.tenantData),
                    tenant2FloorByTenantId,
                    tenant2ZoneByTenantId,
                    dataObj2ProjectCategory,
                    mainDateRanges,
                    filters,
                    mainPeriod,
                    reportingObjectsById,
                });
                setFilteredTenants(tenants);
            } else {
                setFilteredTenants(extendedTenants.map((element) => element.tenantData));
            }
        }, [filters, tenant2FloorByTenantId, tenant2ZoneByTenantId, extendedTenants, dataObj2ProjectCategory]);

        /** Сортировка арендаторов */
        useEffect(() => {
            if (tableSortData) {
                setSortedTenants((tenants) => {
                    const extendedTenants = tenants.map((element) => ({ ...extendedTenantsById[element.id] }));
                    return sortTenants({
                        dataForSort: tableSortData,
                        tenants: extendedTenants,
                    }).map((element) => element.tenantData);
                });
            }
        }, [tableSortData, filteredTenants]);

        const handleSelect = (data: {
            tenantData: IReportingObject;
            module: 'Leasing:Tenant overview' | 'Performance:Year over year';
            path: string;
        }) => {
            let selectedMetrics = [selectedMetricsIds[0]];
            switch (data.module) {
                case 'Leasing:Tenant overview':
                    selectedMetrics = selectedMetricsIds;
                    break;

                default:
                    break;
            }
            openAdditionalTenantInformation({ ...data, selectedMetrics });
            closeContextualMenu();
        };

        const handleOrderToggle = (data: ITableSortData) => {
            storeTableSortDataTrans(data);
        };

        const closeContextualMenu = () => {
            setContextMenuStatus((prevState) => ({ ...prevState, show: false, tenantId: null }));
        };

        const toggleContextualMenu = (tenantId: number) => (e: React.MouseEvent<HTMLElement>) => {
            setContextMenuStatus((prevState) => {
                const show = !prevState.show;
                return {
                    show,
                    tenantId: show ? tenantId : null,
                };
            });
        };

        return (
            <StyledTable>
                <THead>
                    <Tr>
                        <Th>
                            <DefaultText>{t('Name')}</DefaultText>
                        </Th>
                        {selectedMetricsIds.map((selectedMetric) => {
                            const text = allMetrics?.find((element) => element.id === selectedMetric)?.text;
                            const orderingMain =
                                tableSortData?.metricId === selectedMetric && tableSortData?.periodType === 'mainPeriod'
                                    ? tableSortData.ordering
                                    : 'none';

                            const orderingCompare =
                                tableSortData?.metricId === selectedMetric &&
                                tableSortData?.periodType === 'comaprePeriod'
                                    ? tableSortData.ordering
                                    : 'none';
                            return (
                                <Th key={selectedMetric}>
                                    <HeaderCellWrapper>
                                        <OrderSwitcher
                                            ordering={orderingMain}
                                            id="mainPeriod"
                                            handleOrderToggle={(data) =>
                                                handleOrderToggle({
                                                    ...data,
                                                    metricId: selectedMetric,
                                                    periodType: data.id,
                                                })
                                            }
                                        />

                                        <DefaultText>{t(text || selectedMetric)}</DefaultText>
                                        <OrderSwitcher
                                            id="comaprePeriod"
                                            ordering={orderingCompare}
                                            handleOrderToggle={(data) =>
                                                handleOrderToggle({
                                                    ...data,
                                                    metricId: selectedMetric,
                                                    periodType: data.id,
                                                })
                                            }
                                        />
                                    </HeaderCellWrapper>
                                </Th>
                            );
                        })}
                    </Tr>
                </THead>
                <tbody>
                    {sortedTenants.map((element, index) => {
                        const extendedTenant = extendedTenantsById[element.id];
                        return (
                            <Tr key={extendedTenant.tenantData.id}>
                                <Td>
                                    <NameWrapper>
                                        <DefaultText>{extendedTenant.tenantData.name}</DefaultText>
                                        <DotsWrapper
                                            ref={refs[index]}
                                            onClick={toggleContextualMenu(extendedTenant.tenantData.id)}
                                        >
                                            <Dot />
                                            <Dot />
                                            <Dot />
                                            {contextMenuStatus.tenantId === extendedTenant.tenantData.id && (
                                                <ContextualMenu
                                                    isContextualMenuOpen={
                                                        contextMenuStatus.tenantId === extendedTenant.tenantData.id
                                                            ? contextMenuStatus.show
                                                            : false
                                                    }
                                                    hideContextualMenu={closeContextualMenu}
                                                    targetRef={refs[index]}
                                                    gapSpace={10}
                                                    CustomMenuComponent={
                                                        <DropdownBody
                                                            handleSelect={handleSelect}
                                                            tenantData={extendedTenant.tenantData}
                                                        />
                                                    }
                                                />
                                            )}
                                        </DotsWrapper>
                                    </NameWrapper>
                                </Td>
                                {selectedMetricsIds.map((selectedMetric) => {
                                    const mainValue: number | undefined | null =
                                        extendedTenant.metrics?.[selectedMetric]?.mainPeriod?.items[0].value;

                                    let compareValue = undefined;
                                    if (mainValue !== undefined && mainValue !== null) {
                                        if (
                                            extendedTenant.metrics![selectedMetric].comparePeriod?.items[0].value !==
                                            null
                                        ) {
                                            const difference = getDifferenceBetweenNumbers(
                                                mainValue,
                                                extendedTenant.metrics![selectedMetric].comparePeriod?.items[0].value,
                                            );
                                            compareValue = difference.percentDifference;
                                        }
                                    }
                                    return (
                                        <Td key={extendedTenant.tenantData.id + selectedMetric}>
                                            {extendedTenant.metricsLoading ? (
                                                t('Loading...')
                                            ) : extendedTenant.metricsError ? (
                                                t('Unable to retrieve data')
                                            ) : (
                                                <MetricValuesWrapper>
                                                    <DefaultText>
                                                        {mainValue !== undefined && mainValue !== null
                                                            ? numberWithSpaces(mainValue.toFixed(2))
                                                            : '—'}
                                                    </DefaultText>
                                                    <DefaultText value={compareValue}>
                                                        {compareValue !== undefined
                                                            ? numberWithSpaces(compareValue) + '%'
                                                            : '—'}
                                                    </DefaultText>
                                                </MetricValuesWrapper>
                                            )}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </tbody>
            </StyledTable>
        );
    },
);

export default Table;
