import {
    PATH_TO_PURCHASE_FUNNEL_SETTINGS,
    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
    PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS,
    optionsOfFunnelSettings,
    optionsOfFunnelSteps,
} from './constants/constants';

import { ISettings } from '../../../UserSettings/interfaces';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { OptionsWrapper } from './styles';
import PathToPurchaseWidget from './widgets/PathToPurchaseWidget/PathToPurchaseWidget';
import Preset from '../../../Preset/Preset';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import { generalReducerValues } from '../../../../../General.reducer';
import { memo } from 'react';
import usePrepareData from './hooks/usePrepareData';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Компонент модуля "Путь к покупке"
 */
const PathToPurchase = memo(() => {
    const { groupsOfZonesSelectOptions, defaultSelectedGroup } = usePrepareData();
    const { t } = useTranslation();
    const validObjectTypes = [t('Tenants'), t('Zones'), t('Floors')];
    const { currentModule } = useSelector(generalReducerValues);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ObjectsSelect maxSelected={8} validOptions={validObjectTypes} />
                        <UniversalSelect
                            name={PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS}
                            options={groupsOfZonesSelectOptions}
                            labelText={t('Group of zones')}
                            defaultSelected={defaultSelectedGroup ? [defaultSelectedGroup] : undefined}
                            mode="single"
                        />
                        <UniversalSelect
                            name={PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS}
                            options={optionsOfFunnelSteps}
                            defaultSelected={optionsOfFunnelSteps.slice(0, 2)}
                            labelText={t('Funnel steps')}
                            minSelected={2}
                        />
                        <UniversalSelect
                            defaultSelected={[optionsOfFunnelSettings[0]]}
                            name={PATH_TO_PURCHASE_FUNNEL_SETTINGS}
                            options={optionsOfFunnelSettings}
                            labelText={t('Funnel settings')}
                            mode="single"
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS,
                                    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
                                    PATH_TO_PURCHASE_FUNNEL_SETTINGS,
                                    'selectedReportingObjectsIds',
                                    'mainPeriod',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <PathToPurchaseWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
});

export default PathToPurchase;
