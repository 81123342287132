/**
 * Размеры кнопоки
 */
var ButtonSize;
(function (ButtonSize) {
    /**
     * Маленький размер
     */
    ButtonSize["Sm"] = "sm";
    /**
     * Средний размер
     */
    ButtonSize["Md"] = "md";
    /**
     * Большоя размер
     */
    ButtonSize["Lg"] = "lg";
})(ButtonSize || (ButtonSize = {}));

export { ButtonSize };
