import { TServerResponse, handleServerResponse } from '../../../../../../tools/API/handleServerResponse';
import { resetCrossVisitsModuleReducer, storeRawVersionsData } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { ResponseStatus } from '../../../../../../tools/API/constants';
import { generalReducerValues } from '../../../../../../General.reducer';
import { mlFromLocation } from '../../../../../../tools/mlFromLocation';
import { requestVersions } from '../../../../../../lib/esm/components';
import responseAnalyzer from '../../../../../../tools/API/responseAnalyzer';
import { useEffect } from 'react';
import { useGetRawData as useGetRawWidgetData } from '../widgets/CrossVisitsWidget/hooks/useFetchData';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../tools/useWidgetCurrentOptions';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const { locations, token } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Cross-visits');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useGetRawWidgetData();

    /** Получение и сохранение сырых данных для версий*/
    useEffect(() => {
        dispatch(resetCrossVisitsModuleReducer());

        if (!localCurrentOptions || !localCurrentOptions.selectedLocationId || !token || !locations.length) return;
        const location = locations?.find((item) => item.id === localCurrentOptions.selectedLocationId);
        const mlId = mlFromLocation(location);

        dispatch(storeRawVersionsData({ status: ResponseStatus.Loading, message: `${t(ResponseStatus.Loading)}...` }));

        mlId &&
            requestVersions({ mlId, token }).then((res: TServerResponse) => {
                handleServerResponse({
                    success: storeRawVersionsData,
                    error: storeRawVersionsData,
                    responseAnalyzer,
                    dispatch,
                    res,
                });
            });
    }, [localCurrentOptions?.selectedLocationId, token, locations]);
};
