import { IPeriod } from '../../../../../../../../General.interfaces';
import { IRequestMetricsArgs } from '../../../../../../../../lib/esm/components';
import { TMetricLevel } from '../../../../../../CabinetPreferences/interfaces';
import { subsetsOfSalesMetricsOptions } from '../../../constants/contsants';

interface IArgs {
    periodDateRanges: IPeriod[];
    reportingObjectId: number;
    metricLevel: TMetricLevel;
    metricId: string;
    period: IPeriod;
    token: string;
}

const generatePaymentAnalysisRequests = (args: IArgs): IRequestMetricsArgs[] => {
    const { token, reportingObjectId, period, periodDateRanges, metricId, metricLevel } = args;
    const periodValues = periodDateRanges?.find((element) => element.id === period.id)?.period;
    if (periodValues) {
        const finalResult: IRequestMetricsArgs[] = [];
        subsetsOfSalesMetricsOptions.forEach((subset) => {
            subset.subtypes.forEach((subType) => {
                finalResult.push({
                    time_range: [periodValues.dateFrom, periodValues.dateTo],
                    alias: `sales:${subset.id}:${subType.id}`,
                    metric_params: subType.metricParams,
                    obj_ids: [reportingObjectId],
                    metric_level: metricLevel,
                    object_aggregation: false,
                    metric: metricId,
                    time_freq: null,
                    token,
                });
            });
        });
        return finalResult;
    }
    return [];
};

export default generatePaymentAnalysisRequests;
