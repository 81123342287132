/**
 * цвета процента
 */
export enum PercentColor {

    /**
     * null
     */
    Empty = 'rgba(235, 235, 235, 0.57)', //gray

    /**
     *  % ≤ -30
     */
    Negative1 = '#EA4842', // red

    /**
     * -30 < % ≤ -20
     */
    Negative2 = 'rgba(234, 72, 66, 0.7)', // red-

    /**
     * -20 < % ≤ -10
     */
    Negative3 = 'rgba(234, 72, 66, 0.55)', // red--

    /**
     * -10 < % ≤ -5
     */
    Negative4 = 'rgba(234, 72, 66, 0.4)', // red---

    /**
     * -5 < % ≤ -3
     */
    Negative5 = 'rgba(234, 72, 66, 0.3)', // red----

    /**
     * -3 < % < 3
     */
    Neutral = '#FFF4CC', // yellow

    /**
     * 3 ≤ % < 5
     */
    Positive5 = 'rgba(71, 174, 39, 0.3)', //green----

    /**
     * 5 ≤ % < 10
     */
    Positive4 = 'rgba(71, 174, 39, 0.4)', //green---

    /**
     * 10 ≤ % < 20
     */
    Positive3 = 'rgba(71, 174, 39, 0.55)', //green--

    /**
     * 20 ≤ % < 30
     */
    Positive2 = 'rgba(71, 174, 39, 0.7)', //green-

    /**
     * % ≥ 30
     */
    Positive1 = '#47AE27' //green
}

/**
 * Сторона цвета
 */
export enum PercentColorSide {
    /**
     * Положительная
     */
    Positive = 'Positive',

    /**
     * Отрицательная
     */
    Negative = 'Negative'
}