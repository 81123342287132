import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

export type TServerResponse = AxiosResponse[] | AxiosResponse | AxiosError;
export interface IResponseAnalyzerResult {
    isCanceled: boolean;
    isError: boolean;
    result: any;
    initialData: any;
}

interface Args {
    responseAnalyzer: (res: TServerResponse) => IResponseAnalyzerResult;
    success?: (z: any) => AnyAction;
    error?: (z: any) => AnyAction;
    res: TServerResponse;
    dispatch: Dispatch;
    responseHandlerFoo?: (reponse: any) => void;
}

/**
 * Функция для обработки любых ответов от сервера с возможностью
 * диспатча резльтата (ошибочного или успешного)
 * @param responseHandlerFoo Функция анализа ответа от сервера (если передана, то диспатч не происходит)
 * @param responseAnalyzer Функция анализа ответа от сервера
 * @param dispatch Функция диспатча
 * @param success Экшн для диспатча успешного результата
 * @param error Экшн для диспатча результата с ошибкой
 * @param res ответ от сервера
 */
export const handleServerResponse = (args: Args): void => {
    const { res, success, dispatch, error, responseAnalyzer, responseHandlerFoo } = args;

    /** Анализ ответа сервера для проверки на ошибки и получения нужного формата результата */
    const { isCanceled, isError, result } = responseAnalyzer(res);

    if (!isCanceled) {
        if (isError) {
            responseHandlerFoo && responseHandlerFoo(result);
            error && dispatch(error(result));
        } else if (result) {
            responseHandlerFoo && responseHandlerFoo(result);
            success && dispatch(success(result));
        }
    }
};
