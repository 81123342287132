import { HeaderWrapper, SelectsWrapper, SelectsWrapperItem } from './styles';
import { FC } from 'react';
import { IChartOptionsProps } from './interfaces';
import { useTranslation } from 'react-i18next';
import UniversalSelect from '../../../../../../../Selects/UniversalSelect/UniversalSelect';
import UniversalTextInput from '../../../../../../../Selects/UniversalTextInput/UniversalTextInput';
import UniversalDatePicker from '../../../../../../../Selects/UniversalDatePicker/UniversalDatePicker';

/**
 * Компонент для отображения селектов, для выбора настроек графика
 * @param showEntitiesSelect Флаг, отвечающий за отображения селекта с объектами
 * @param showMetricsSelect Флаг, отвечающий за отображение селекта с метриками
 * @param showDatePeriod Флаг, отвечающий за отображение селекта с периодом
 * @param title Заголовок
 */
const CalculatorSelects: FC<IChartOptionsProps> = () => {

    const { t } = useTranslation();

    const unitsOptions = [
        { id: 'percent', text: '%' },
        { id: 'rub', text: '₽' },
    ];

    return (
        <HeaderWrapper>
            <SelectsWrapper>
                <SelectsWrapperItem>
                    <UniversalTextInput name={'discount'} labelText={t('Discount')} type="number" min={0} />
                </SelectsWrapperItem>
                <SelectsWrapperItem>
                    <UniversalSelect
                        mode="single"
                        options={unitsOptions}
                        name={'discountUnits'}
                        labelText={t('Units')}
                        text={t('Select units')}
                        dropdownWidth="sm"
                    />
                </SelectsWrapperItem>
                <SelectsWrapperItem>
                    <UniversalDatePicker name={'discountPeriod'} labelText={t('Discount period')} yearsOnly />
                </SelectsWrapperItem>
            </SelectsWrapper>
        </HeaderWrapper>
    );
};

export default CalculatorSelects;
