import { createContext, RefObject } from 'react';
import { AnyAction } from 'redux';
import {
    IDataObj2ProjectCategory,
    IPeriod,
    IPeriodObj,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
} from '../../../../../../../General.interfaces';
import { IComparePeriod, IExtendedReportingObject, ITableSortData } from './interfaces';

interface ITableOfObjectsContext {
    extendedReportingObjects: null | IExtendedReportingObject[];
    selectedComparePeriod: null | IComparePeriod;
    availableComparePeriods: IComparePeriod[];
    mainDateRanges: undefined | IPeriod[];
    filters: undefined | number[];
    tenant2ZoneByTenantId: {
        [tenantId: string]: ITenant2ZoneRelation[];
    };
    tenant2FloorByTenantId: {
        [tenantId: string]: ITenant2FloorRelation[];
    };
    mainPeriod: undefined | IPeriod;
    selectedMetrics: undefined | string[];
    tableSortData: ITableSortData | null;
    selectedObjectsType: string | null;
    rootNodeRef: RefObject<null>;

    reportingObjectsById: {
        [x: string]: IReportingObject;
    };
    allMetrics: {
        id: string;
        text: string;
        units: string;
    }[];
    rerender: number;
    reportingObjectsByType: {
        [x: string]: IReportingObject[];
    };
    dataObj2ProjectCategory: IDataObj2ProjectCategory[];
    storeSelectedComparePeriodTrans: (comparePeriod: IComparePeriod) => void;
    storeSelectedObjectsTypeTrans: (selectedObjectsType: string) => void;
    storeTableSortDataTrans: (data: null | ITableSortData) => void;
    reloadWidget: () => AnyAction;
}

export const TableOfObjectsContext = createContext<null | ITableOfObjectsContext>(null);
