import { isNull, isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { IChangedEvent, ITableCellData } from '../interfaces';

/**
 * Функция для получения объектасобытия из строки таблицы
 * @param row строка таблицы
 */
const getEventDataFromRow = (row: ITableCellData[], timezone: string): IChangedEvent => {
    let event = {};
    let dateFrom = '';
    let dateTo = '';
    let timeFrom = '';
    let timeTo = '';
    row.forEach((cell) => {
        if (!isUndefined(cell.value) && !isNull(cell.value)) {
            if (cell.event?.id) {
                event['id'] = cell.event.id;
            }

            if (cell.eventKey) {
                event[cell.eventKey] = cell.value;
            }

            if (cell.key === 'date_from') {
                dateFrom = cell.value;
            }

            if (cell.key === 'date_to') {
                dateTo = cell.value;
            }

            if (cell.key === 'time_from') {
                timeFrom = cell.value;
            }

            if (cell.key === 'time_to') {
                timeTo = cell.value;
            }
        }
    });

    event['date_from'] = getISODate(`${dateFrom}T${timeFrom}`, timezone);

    event['date_to'] = getISODate(`${dateTo}T${timeTo}`, timezone);

    return event as IChangedEvent;
};

export default getEventDataFromRow;

const getISODate = (str: string, tz: string) => {
    return DateTime.fromFormat(str, "yyyy-MM-dd'T'HH:mm", { zone: tz as string }).toISO();
};
