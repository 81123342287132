import { TServerResponse, handleServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import {
    increaseAllReportingObjectsCounter,
    increaseChartByReportingObjectsCounter,
    storeExtendedReportingObjectsById,
    storeRawMetricsData,
    toggleUpdateAllReportingObjects,
    Performance_YoY_YoY_Widget_Reducer_Values,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { YOY_YEARS_SETTINGS } from '../../../constants/constants';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateQueryData from '../tools/generateQueryData';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useEffect } from 'react';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';

/**
 * Кастомный хук для получения метрик и работы с настройками
 * текущего виджета
 */
export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();

    const localCurrentOptions = useWidgetCurrentOptions('Performance:Year over year');
    const {
        src: { reportingObjects },
        token,
    } = useSelector(generalReducerValues);
    const {
        updateChartByReportingObjectsCounter,
        updateAllReportingObjectsCounter,
        shoudUpdateAllReportingObjects,
        extendedReportingObjectsById,
        rawMetricsData,
        dataRefetchObject,
    } = useSelector(Performance_YoY_YoY_Widget_Reducer_Values);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    /** Обновление отчетных объектов и увеличение триггера запроса метрика */
    useEffect(() => {
        const objects = reportingObjects?.filter((element) =>
            localCurrentOptions?.selectedReportingObjectsIds?.includes(element.id),
        );
        dispatch(storeExtendedReportingObjectsById(objects));

        if (
            !shoudUpdateAllReportingObjects ||
            !Object.keys(extendedReportingObjectsById).length ||
            rawMetricsData['status']
        ) {
            dispatch(increaseChartByReportingObjectsCounter(null));
        }
    }, [localCurrentOptions?.selectedReportingObjectsIds, reportingObjects]);

    // Обновление отчетных объектов при изменение флага обновления всех отчтеных объектов
    useEffect(() => {
        const objects = reportingObjects?.filter((element) =>
            localCurrentOptions?.selectedReportingObjectsIds?.includes(element.id),
        );
        dispatch(storeExtendedReportingObjectsById(objects));
    }, [localCurrentOptions?.selectedReportingObjectsIds, shoudUpdateAllReportingObjects, reportingObjects]);

    /** Увеличение тригера запроса метрик */
    useEffect(() => {
        if (localCurrentOptions?.selectedMetrics && localCurrentOptions?.[YOY_YEARS_SETTINGS]) {
            dispatch(increaseAllReportingObjectsCounter(null));
            dispatch(toggleUpdateAllReportingObjects(true));
        }
    }, [
        localCurrentOptions?.[YOY_YEARS_SETTINGS],
        localCurrentOptions?.selectedLocationId,
        localCurrentOptions?.selectedMetrics,
        dataRefetchObject,
    ]);

    /** Получение метрик и обновление отчетных объектов новыми данными*/
    useEffect(() => {
        if (!localCurrentOptions || !Object.keys(extendedReportingObjectsById).length || !token) return;

        const controller = new AbortController();
        const signal = controller.signal;
        if (
            localCurrentOptions?.selectedReportingObjectsIds &&
            localCurrentOptions?.selectedMetrics &&
            localCurrentOptions?.[YOY_YEARS_SETTINGS]
        ) {
            const queryData = generateQueryData({
                selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
                selectedYears: localCurrentOptions[YOY_YEARS_SETTINGS],
                selectedMetrics: localCurrentOptions.selectedMetrics,
                shoudUpdateAllReportingObjects,
                extendedReportingObjectsById,
                metricLevel,
                token,
            }).map((element) => ({ ...element, signal }));

            dispatch(storeRawMetricsData({ status: 'Loading', message: t('Loading...') }));

            fetchData(queryData)
                .then((res: TServerResponse) => {
                    handleServerResponse({
                        responseAnalyzer: responseAnalyzer,
                        success: storeRawMetricsData,
                        error: storeRawMetricsData,
                        dispatch,
                        res,
                    });
                })
                .catch((error: any) => {
                    console.log(`Year over year usegetMetrics error: ${error}`);
                });
        }
        return () => controller.abort();
    }, [updateChartByReportingObjectsCounter, updateAllReportingObjectsCounter, token]);
};
