import { ColorPicker, IColor, Stack } from '@fluentui/react';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Button, Modals, TextInput } from '../../../../../../../../../lib/esm/components';
import {
    getRequest,
    postRequest,
    patchRequest,
    deleteRequest,
} from '../../../../../../../../../tools/API/appBackendAPI';
import { IEventType } from '../../../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { storeEventTypes } from '../../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import ColorBox from './components/ColorBox/ColorBox';
import DeleteConfirmModalBody from './components/DeleteConfirmModalBody/DeleteConfirmModalBody';
import { Footer, InputWrapper, Wrapper } from './styles';

interface IProps {
    closeModal: () => void;
    eventTypeToEdit?: IEventType | null;
}

const CreateModalBody: React.FC<IProps> = ({ closeModal, eventTypeToEdit }) => {
    const [deleteConformModalStatus, setDeleteConformModalStatus] = useState({ show: false });
    const [modalStatus, setModalStatus] = useState({ show: false });
    const { user, token, appBackendHost, selectedLocationId } = useSelector(generalReducerValues);
    const [color, setColor] = useState('#FF0000');
    const [name, setName] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (eventTypeToEdit) {
            setColor(eventTypeToEdit.color);
            setName(eventTypeToEdit.name);
        }
    }, [eventTypeToEdit]);

    const url = `${appBackendHost}/api/location-events/location-event-types/`;

    const openDeleteConfirmModal = () => {
        setDeleteConformModalStatus({ show: true });
    };

    const closeDeleteConfirmModal = () => {
        setDeleteConformModalStatus({ show: false });
    };

    const onColorBoxClick = () => {
        setModalStatus({ show: true });
    };

    const handleModalClose = () => {
        setModalStatus({ show: false });
    };

    const updateColor = React.useCallback((ev: any, colorObj: IColor) => {
        setColor(() => colorObj.str);
    }, []);

    const onNameChange = (args: { value: string }) => {
        setName(() => args.value);
    };

    const getRequestFoo = useCallback(() => {
        return getRequest(url, token, dispatch).then((response: AxiosError | AxiosResponse) => {
            if (response['data'].length) {
                dispatch(storeEventTypes(response['data']));
            }
        });
    }, [url, token]);

    const onCreateClick = () => {
        const data = { pl_id: selectedLocationId, name, color };
        postRequest(url, token, data, dispatch).then(() => {
            getRequestFoo();
        });

        setName(() => '');
        closeModal();
    };

    const onUpdateClick = () => {
        const data = { ...eventTypeToEdit, pl_id: selectedLocationId, name, color };

        patchRequest(url, token, data, dispatch).then(() => {
            getRequestFoo();
        });

        setName(() => '');
        closeModal();
    };

    const onDelete = () => {
        if (eventTypeToEdit) {
            deleteRequest(url, token, eventTypeToEdit.id, dispatch).then(() => {
                getRequestFoo();
            });

            setName(() => '');
            closeDeleteConfirmModal();
            closeModal();
        }
    };

    return (
        <Wrapper>
            <Modals
                title={t('Event type color')}
                closeModal={handleModalClose}
                modalStatus={modalStatus}
                topColor="fff"
            >
                <ColorPicker
                    color={color}
                    onChange={updateColor}
                    showPreview={true}
                    strings={{
                        hueAriaLabel: 'Hue',
                    }}
                />
            </Modals>
            <Modals
                title={t('Event type delete')}
                closeModal={closeDeleteConfirmModal}
                modalStatus={deleteConformModalStatus}
                topColor="fff"
            >
                <DeleteConfirmModalBody onDelete={onDelete} closeModal={closeDeleteConfirmModal} />
            </Modals>
            <InputWrapper>
                <TextInput
                    fullWidth={true}
                    placeholder={t('Event type name')}
                    outsideValue={name}
                    handleChange={onNameChange}
                />
                <ColorBox color={color} onColorBoxClick={onColorBoxClick} />
            </InputWrapper>
            <Footer>
                {eventTypeToEdit ? (
                    <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <Button text={t('Delete')} type="accent" onClick={openDeleteConfirmModal} />
                        <Button text={t('Save')} onClick={onUpdateClick} />
                    </Stack>
                ) : (
                    <Button text={t('Create')} onClick={onCreateClick} />
                )}
            </Footer>
        </Wrapper>
    );
};

export default CreateModalBody;
