import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import { OptionsWrapper } from './styles';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../Preset/Preset';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import { useTranslation } from 'react-i18next';
import { ISettings } from '../../../UserSettings/interfaces';
import CalculatorWidget from './widgets/CalculatorWidget/CalculatorWidget';
import { Cases_Calculator_Calculator_Widget_Reducer_Values } from './widgets/CalculatorWidget/reducer';
import { useWidgetCurrentOptions } from '../../../../../tools/useWidgetCurrentOptions';
import { storeUniversalTextInput } from '../../../UserSettings/reducer';
import { tenantsOptions } from './widgets/CalculatorWidget/tenantsOptions';

const Dashboard: React.FC = ({}) => {
    const { selectedLocationId } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { moduleName } = useSelector(Cases_Calculator_Calculator_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const tenantId = localCurrentOptions?.['tenant']?.[0]?.id;

    useEffect(() => {
        tenantsOptions[tenantId] &&
            Object.keys(tenantsOptions[tenantId]).forEach((key) => {
                let data = '';
                if (key !== 'indexingDate') {
                    data = tenantsOptions[tenantId][key];
                }
                selectedLocationId &&
                    dispatch(
                        storeUniversalTextInput({
                            locationId: selectedLocationId,
                            module: moduleName,
                            data,
                            name: key,
                        }),
                    );
            });
    }, [dispatch, moduleName, selectedLocationId, tenantId]);

    const tenantOptions = [
        { id: 'Henderson', text: 'Henderson' },
        { id: 'Burger King', text: 'Burger King' },
    ];

    const refOptions1 = [
        { id: 'Сударь', text: 'Сударь' },
        { id: 'Эстет', text: 'Эстет' },
        { id: 'Albione', text: 'Albione' },
        { id: 'Antony Morato', text: 'Antony Morato' },
        { id: 'Cacharel', text: 'Cacharel' },
        { id: 'Gatoi Design', text: 'Gatoi Design' },
        { id: 'Giovanni Botticelli', text: 'Giovanni Botticelli' },
        { id: 'GLASMAN', text: 'GLASMAN' },
        { id: 'HUGO BOSS', text: 'HUGO BOSS' },
        { id: 'KANZLER', text: 'KANZLER' },
        { id: 'Roy Robson', text: 'Roy Robson' },
        { id: 'Strellson', text: 'Strellson' },
        { id: 'The Windsor Knot', text: 'The Windsor Knot' },
        { id: 'Van Cliff', text: 'Van Cliff' },
    ];

    const refOptions2 = [
        { id: 'Крошка Картошка', text: 'Крошка Картошка' },
        { id: 'Ташир пицца', text: 'Ташир пицца' },
        { id: 'Теремок', text: 'Теремок' },
        { id: 'Том Ям', text: 'Том Ям' },
        { id: 'Три Утки', text: 'Три Утки' },
        { id: 'Шаурмэн', text: 'Шаурмэн' },
        { id: 'Subway', text: 'Subway' },
        { id: 'KFC', text: 'KFC' },
        { id: 'Kebablab', text: 'Kebablab' },
        { id: 'Вкусно и точка', text: 'Вкусно и точка' },
        { id: 'Livan house', text: 'Livan house' },
        { id: 'Black Star Burger', text: 'Black Star Burger' },
    ];

    const refOptions = { Henderson: refOptions1, 'Burger King': refOptions2 };

    const metricsOptions = [
        { id: 'traffic', text: t('Traffic in') },
        { id: 'sales_no_vat', text: t('Sales without VAT, ₽') },
        { id: 'sales_per_area_no_vat', text: t('Sales of tenant/m² without VAT, ₽') },
        { id: 'sales_per_area_uniq_no_vat', text: t('Sales of group/m² without VAT, ₽') },
    ];

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect validOptions={['currentYear', 'selectCustom']} yearsOnly />
                        <ComparePeriodSelect
                            validOptions={['sameMonthdays', 'yoy', 'selectCustom']}
                            mode="single"
                            maxSelected={2}
                            yearsOnly
                        />
                        <UniversalSelect
                            mode="single"
                            options={tenantOptions}
                            name={'tenant'}
                            labelText={t('Tenant')}
                            text={t('Select a tenant')}
                            dropdownWidth="sm"
                        />
                        {/* <UniversalSelect
                            mode="single"
                            options={categoryOptions}
                            name={'category'}
                            labelText={t('Category')}
                            text={t('Select a category')}
                        /> */}
                        <UniversalSelect
                            mode="multi"
                            options={refOptions[tenantId]}
                            name={`referenceGroup:${tenantId}`}
                            labelText={t('Reference group')}
                            dropdownWidth="sm"
                        />

                        <UniversalSelect
                            mode="multi"
                            options={metricsOptions}
                            name={'myMetrics'}
                            labelText={t('Metrics')}
                            dropdownWidth="lg"
                            maxSelected={10}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    'mainPeriod',
                                    'comparePeriods',
                                    'tenant',
                                    `referenceGroup:${tenantId}`,
                                    'myMetrics',
                                    'rentInMonth',
                                    'indexingDate',
                                    'indexPercent',
                                    'receivables',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <CalculatorWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default Dashboard;
