/**
 * Размеры иконок
 */
var Size;
(function (Size) {
    /**
     * Значение пусто
     */
    Size["Empty"] = "";
    /**
     * Базовый размер
     */
    Size["Base"] = "xs";
    /**
     * Самый маленький размер
     */
    Size["Xxxs"] = "xxxs";
    /**
     * Очень очень маленький
     */
    Size["Xxs"] = "xxs";
    /**
     * Очень маленький
     */
    Size["Xs"] = "xs";
    /**
     * Маленький размер
     */
    Size["Sm"] = "sm";
    /**
     * Средний размер
     */
    Size["Md"] = "md";
    /**
     * Большоя размер
     */
    Size["Lg"] = "lg";
    /**
     * Очень большой размер
     */
    Size["Xl"] = "xl";
    /**
     * Огромный размер
     */
    Size["Xxl"] = "xxl";
})(Size || (Size = {}));

export { Size };
