import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 30vw;
`;

export const Label = styled.span<{ isRequired: boolean }>`
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.4);
    &:after {
        content: '*';
        display: ${(p) => (p.isRequired ? 'inline-block' : 'none')};
        position: absolute;
        right: -8px;
        color: red;
        top: -3px;
        opacity: 1;
    }
`;

export const InputWrapper = styled.div`
    margin-top: 15px;
`;

export const HalfWidthWrapper = styled.div`
    width: 50%;
`;

export const ButtonsWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const CheckboxWrapper = styled.div`
    margin-top: 5px;
`;
