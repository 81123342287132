import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSettingsReducerValues } from '../cabinet/components/UserSettings/reducer';
import { TCurrentOptions } from '../cabinet/components/UserSettings/interfaces';
import { isEqual } from 'lodash';

export const useWidgetCurrentOptions = (module: string) => {
    const [localCurrentOptions, setLocalCurrentOptions] = useState<TCurrentOptions>();

    const { currentOptions } = useSelector(userSettingsReducerValues);

    useEffect(() => {
        if (currentOptions.currentModule !== module || isEqual(localCurrentOptions, currentOptions)) return;
        const newLocal = {};
        Object.keys(currentOptions).forEach((key) => {
            if (!isEqual(localCurrentOptions?.[key], currentOptions[key])) {
                newLocal[key] = currentOptions[key];
            }
        });
        if (!localCurrentOptions) {
            setLocalCurrentOptions({ ...currentOptions, ...newLocal });
        } else {
            setLocalCurrentOptions({ ...localCurrentOptions, ...newLocal });
        }
    }, [JSON.stringify(currentOptions)]);

    return localCurrentOptions;
};
