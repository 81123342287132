import React from 'react';
import { Icon } from '../lib/esm/components';

export const getArrowAndColor = (value: number) => {
    if (value >= -3 && value <= 3) {
        return {
            color: 'var(--color-orange-light)',
            icon: null,
        };
    } else if (value < 3) {
        return {
            color: 'var(--color-error-light)',
            icon: <Icon type="short-arrow-down" size="xxs" margin="l_xxxxs" />,
        };
    } else {
        return { color: 'var(--color-success)', icon: <Icon type="short-arrow-up" size="xxs" margin="l_xxxxs" /> };
    }
};
