import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import React, { useRef } from 'react';
import { Size } from './configurations/size.js';
import cn from 'classnames';
import * as constants from '../../constants/constants.js';
import { DEFAULT_CLASS_ICON_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';

var Icon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? Size.Base : _b, _c = _a.type, type = _c === void 0 ? 'arrow-left' : _c, props = __rest(_a, ["size", "type"]);
    var ref = useRef(null);
    var mergedStyles = useStyles({ ref: ref, props: props, constants: constants });
    var className = React.useMemo(function () {
        var cssClassAlign = '';
        var DEFAULT_CLASS_ICON_PREFIX$1 = DEFAULT_CLASS_ICON_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1 = DEFAULT_SEPARATOR_MODIFICATION_PREFIX;
        var cssClassSize = "".concat(DEFAULT_CLASS_ICON_PREFIX$1).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX$1).concat(size);
        return cn(__spreadArray([DEFAULT_CLASS_ICON_PREFIX$1, cssClassSize, cssClassAlign], mergedStyles, true));
    }, [props, size]);
    return (jsx("svg", __assign({ ref: ref, className: className }, { children: jsx("use", { href: "#".concat(type) }, void 0) }), void 0));
};

export { Icon as default };
