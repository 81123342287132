import { Stack } from '@fluentui/react';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { CompareText, Period, Wrapper } from './styles';
import { stringDate } from '../../../../../../../../../tools/Strings/stringDate';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Select } from '../../../../../../../../../lib/esm/components';
import { TableOfObjectsContext } from '../../context';
import { IComparePeriod } from '../../interfaces';
import FiltersSelect from '../../../../../../../Filters/FiltersSelect/FiltersSelect';
import FiltersMonitor from '../../../../../../../Filters/FiltersMonitor/FiltersMonitor';
import { getReportingObjects } from '../../../../../../../../../tools/getReportingObjects';

/**
 * Нижняя часть шапки с выбором периодов сравнения
 */
const SubGeader = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Events:Events');
    const { lang } = useSelector(generalReducerValues);
    const context = useContext(TableOfObjectsContext);
    const { t } = useTranslation();

    const validOptions = useMemo(() => {
        if (context) {
            const lockedFields = ['location', 'passway', 'place'];
            return getReportingObjects(context.reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey))
                .map((element) => element.headerText);
        }
        return [];
    }, [context?.reportingObjectsByType, t]);

    const currentPeriod: string = useMemo(() => {
        if ((localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges)) {
            const currentPeriod = localCurrentOptions.mainDateRanges?.find(
                (element) => element.id === localCurrentOptions.mainPeriod?.id,
            )?.period;
            let result = '';

            if (currentPeriod) {
                result = stringDate(
                    { dateFrom: currentPeriod.dateFrom, dateTo: currentPeriod.dateTo },
                    lang,
                    '',
                    'dd MMM yyyy',
                );
            }
            return result;
        }
        return '';
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, lang]);

    const handleComparePeriodSelect = (args: IComparePeriod[]) => {
        if (context) {
            context.storeSelectedComparePeriodTrans(args[0]);
        }
    };

    return (
        <Wrapper>
            <Stack verticalAlign="center" horizontal={true} tokens={{ childrenGap: 10 }}>
                <Period>{currentPeriod}</Period>
                <CompareText>{t('Compare with')}</CompareText>
                {context && (
                    <Select
                        handleSelect={handleComparePeriodSelect}
                        iconType="chevron-down"
                        outsideSelected={
                            context.selectedComparePeriod
                                ? [
                                      {
                                          ...context.selectedComparePeriod,
                                          text: stringDate(
                                              context.selectedComparePeriod.period,
                                              lang,
                                              '',
                                              'dd MMM yyyy',
                                          ),
                                      },
                                  ]
                                : null
                        }
                        options={context.availableComparePeriods}
                        showFilter={false}
                        mode="single"
                        labelText=""
                    />
                )}
                {context?.selectedObjectsType === 'tenant' && <FiltersSelect validOptions={validOptions} label="" />}
            </Stack>
            {context?.selectedObjectsType === 'tenant' && <FiltersMonitor />}
        </Wrapper>
    );
};

export default SubGeader;
