import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { useEffect } from 'react';
import { Reports_Comparison_Widget_Reducer_Values, resetReducer, storeGrid } from '../reducer';
import { COMPARISON_REPORT_ADDITIONAL_FIELDS } from '../../../constants/constants';
import { generateGrid } from '../tools/generateGrid';
import { ISelectedOption } from '../../../../../../../../components/Select/configurations';
import { generalReducerValues } from '../../../../../../../../General.reducer';

/** Кастомный хук для подготовки данных */
const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Reports:With comparison');
    const { rawMetricsData } = useSelector(Reports_Comparison_Widget_Reducer_Values);
    const {
        src: { reportingObjects },
        allMetrics,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Обнуление редьюсера при смене локации */
    useEffect(() => {
        dispatch(resetReducer());
    }, [localCurrentOptions?.selectedLocationId]);

    /** Создание таблицы после получения метрик */
    useEffect(() => {
        if (Array.isArray(rawMetricsData)) {
            const additionalFieldsIds = localCurrentOptions?.[COMPARISON_REPORT_ADDITIONAL_FIELDS]?.map(
                (element: ISelectedOption) => element.id,
            );
            const grid = generateGrid(rawMetricsData, reportingObjects, allMetrics, t, additionalFieldsIds);
            grid && dispatch(storeGrid(grid));
        }
    }, [localCurrentOptions?.[COMPARISON_REPORT_ADDITIONAL_FIELDS], rawMetricsData, t]);
};

export default usePrepareData;
