import { IChartOptionsGeneratorSettings, IExtendedReportingObject } from '../interfaces';
import {
    IMsDataObject,
    IPeriod,
    IReportingObject,
    ITenant2ZoneRelation,
    TMetricResponse,
} from '../../../../../../../../General.interfaces';
import { IOptionsOfFunnelSteps, IdsOfFunnelStepsOptions, LegendNamesOfFunnelSteps } from '../../../constants/constants';

import { TFunction } from 'react-i18next';
import { cloneDeep } from 'lodash';
import filterTenantValidZone from './filterTenantValidZone';
import filterZoneValidFloors from './filterZoneValidFloors';

interface IArgs {
    reportingObjectsById: { [reportingObjectId: string]: IReportingObject };
    reportingObjectsByTypeAndMarker: { [x: string]: IReportingObject };
    tenant2ZoneRelations: ITenant2ZoneRelation[];
    selectedReportingObjectsIds: number[];
    funnelSteps: IOptionsOfFunnelSteps[];
    rawMetricsData: TMetricResponse;
    msDataObjects: IMsDataObject[];
    selectedGroupMarker: string;
    mainDateRanges: IPeriod[];
    mainPeriod: IPeriod;
    t: TFunction;
}

interface IResponse {
    chartOptionsGeneratorSettings: IChartOptionsGeneratorSettings;
    extendedReportingObjects: IExtendedReportingObject[];
}

/**
 * Функция для генерации данных для графика и расширенных отчетных объектов
 * @param reportingObjectsByTypeAndMarker Объект отчетных объектов где ключ это [тип:маркер]
 * @param selectedReportingObjectsIds id выбранных отчетных объектов
 * @param reportingObjectsById Отчетный объекты, где ключ это id отчетного объекта
 * @param tenant2ZoneRelations Взаимосвязь арендатора и зоны
 * @param selectedGroupMarker Выбранный маркер группы
 * @param rawMetricsData Массивы сырых данных метрики
 * @param mainDateRanges Периоды дат для главного периода
 * @param msDataObjects Данные для отчетных объектов
 * @param funnelSteps Шаги воронки
 * @param mainPeriod Главный период
 * @param t Функция перевода
 */
const generateData = (args: IArgs): IResponse => {
    const {
        reportingObjectsByTypeAndMarker,
        selectedReportingObjectsIds,
        reportingObjectsById,
        tenant2ZoneRelations,
        selectedGroupMarker,
        rawMetricsData,
        mainDateRanges,
        msDataObjects,
        funnelSteps,
        mainPeriod,
        t,
    } = args;

    const location = rawMetricsData?.find((element) => element.context.data_objects[0].type === 'location');
    const mainPeriodDateRanges = mainDateRanges?.find((element) => element.id === mainPeriod!.id);

    const extendedReportingObjects: IExtendedReportingObject[] = [];

    const seriesById = cloneDeep(funnelSteps)
        .sort((a, b) => (a.order > b.order ? -1 : 1))
        .reduce((acc, value) => {
            if (value.id !== IdsOfFunnelStepsOptions.Location) {
                acc[value.id] = {
                    id: value.legendName,
                    name: t(value.legendName),
                    color: value.seriesColor,
                    type: 'column',
                    data: [],
                    isMain: true,
                    category: '',
                };
            }
            return acc;
        }, {});
    const categories: string[] = [];

    if (mainPeriodDateRanges && Object.keys(reportingObjectsById).length && location) {
        const locationData = {
            name: location.context.data_objects[0].name,
            metricValue: location.items[0].value,
            percentage: 100,
        };

        selectedReportingObjectsIds.forEach((reportingObjectId, reportingObjectIndex) => {
            const currentReportingObject = reportingObjectsById[reportingObjectId];
            if (currentReportingObject) {
                categories.push(currentReportingObject?.name);

                switch (currentReportingObject.object_type) {
                    case 'tenant':
                        const tenant: IExtendedReportingObject = {
                            reportingObjectData: currentReportingObject,
                            location: locationData,
                            floor: null,
                            zone: null,
                            tenant: null,
                            transactions: null,
                            isLoading: false,
                        };
                        const validZone = filterTenantValidZone({
                            reportingObjectsByTypeAndMarker,
                            tenant: currentReportingObject,
                            tenant2ZoneRelations,
                            selectedGroupMarker,
                            mainDateRanges,
                            mainPeriod,
                        });

                        if (validZone) {
                            const currentZoneMetricsData = rawMetricsData?.find((metricsData) => {
                                return metricsData.context.data_objects[0].id === validZone.id;
                            });

                            if (currentZoneMetricsData) {
                                tenant.zone = {
                                    name: currentZoneMetricsData.context.data_objects[0].name,
                                    metricValue: currentZoneMetricsData.items[0].value,
                                    percentage:
                                        (currentZoneMetricsData.items[0].value * 100) /
                                        (tenant.location!.metricValue || 1),
                                };
                                seriesById[IdsOfFunnelStepsOptions.Zone]?.data.push({
                                    y: currentZoneMetricsData.items[0].value,
                                    x: reportingObjectIndex,
                                    name: t(LegendNamesOfFunnelSteps.zone),
                                });
                            }
                            const validFloors = filterZoneValidFloors({
                                reportingObjectsByTypeAndMarker,
                                zoneMarker: validZone.marker,
                                mainDateRanges,
                                msDataObjects,
                                mainPeriod,
                            });

                            if (validFloors) {
                                const { yValue, name } = validFloors.reduce(
                                    (acc, floor) => {
                                        const currentFloorMetricsData = rawMetricsData?.find((metricsData) => {
                                            return metricsData.context.data_objects[0].id === floor.id;
                                        });

                                        if (currentFloorMetricsData) {
                                            acc.yValue += currentFloorMetricsData.items[0].value;
                                            if (!acc.name) acc.name += floor.name;
                                            else acc.name += ` + ${floor.name}`;
                                        }

                                        return acc;
                                    },
                                    { yValue: 0, name: '' },
                                );

                                tenant.floor = {
                                    percentage: (yValue * 100) / (tenant.location!.metricValue || 1),
                                    metricValue: yValue,
                                    name,
                                };

                                seriesById[IdsOfFunnelStepsOptions.Floor]?.data.push({
                                    name: t(LegendNamesOfFunnelSteps.floor),
                                    x: reportingObjectIndex,
                                    y: yValue,
                                });
                            }
                        }

                        const currentTenantMetricsData = rawMetricsData?.find((metricsData) => {
                            return metricsData.context.data_objects[0].id === currentReportingObject.id;
                        });

                        if (currentTenantMetricsData) {
                            tenant.tenant = {
                                percentage:
                                    (currentTenantMetricsData.items[0].value * 100) /
                                    (tenant.location!.metricValue || 1),
                                name: currentTenantMetricsData.context.data_objects[0].name,
                                metricValue: currentTenantMetricsData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.Tenant]?.data.push({
                                y: currentTenantMetricsData.items[0].value,
                                name: t(LegendNamesOfFunnelSteps.tenant),
                                x: reportingObjectIndex,
                            });
                        }
                        extendedReportingObjects.push(tenant);
                        break;
                    case 'zone':
                        const zone: IExtendedReportingObject = {
                            reportingObjectData: currentReportingObject,
                            location: locationData,
                            floor: null,
                            zone: null,
                            tenant: null,
                            transactions: null,
                            isLoading: false,
                        };

                        const validFloors = filterZoneValidFloors({
                            zoneMarker: currentReportingObject.marker,
                            reportingObjectsByTypeAndMarker,
                            mainDateRanges,
                            msDataObjects,
                            mainPeriod,
                        });

                        const currentZoneMetricsData = rawMetricsData?.find((metricsData) => {
                            return metricsData.context.data_objects[0].id === currentReportingObject.id;
                        });

                        if (currentZoneMetricsData) {
                            zone.zone = {
                                percentage:
                                    (currentZoneMetricsData.items[0].value * 100) / (zone.location!.metricValue || 1),
                                name: currentZoneMetricsData.context.data_objects[0].name,
                                metricValue: currentZoneMetricsData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.Zone]?.data.push({
                                y: currentZoneMetricsData.items[0].value,
                                name: t(LegendNamesOfFunnelSteps.zone),
                                x: reportingObjectIndex,
                            });
                        }

                        if (validFloors) {
                            const { yValue, name } = validFloors.reduce(
                                (acc, floor) => {
                                    const currentFloorMetricsData = rawMetricsData?.find((metricsData) => {
                                        return metricsData.context.data_objects[0].id === floor.id;
                                    });

                                    if (currentFloorMetricsData) {
                                        acc.yValue += currentFloorMetricsData.items[0].value;
                                        if (!acc.name) acc.name += floor.name;
                                        else acc.name += ` + ${floor.name}`;
                                    }

                                    return acc;
                                },
                                { yValue: 0, name: '' },
                            );

                            zone.floor = {
                                percentage: (yValue * 100) / (zone.location!.metricValue || 1),
                                metricValue: yValue,
                                name,
                            };

                            seriesById[IdsOfFunnelStepsOptions.Floor]?.data.push({
                                name: t(LegendNamesOfFunnelSteps.floor),
                                x: reportingObjectIndex,
                                y: yValue,
                            });
                        }
                        extendedReportingObjects.push(zone);
                        break;

                    case 'floor':
                        const floor: IExtendedReportingObject = {
                            reportingObjectData: currentReportingObject,
                            location: locationData,
                            floor: null,
                            zone: null,
                            tenant: null,
                            transactions: null,
                            isLoading: false,
                        };
                        const currentFloorMetricsData = rawMetricsData?.find((metricsData) => {
                            return metricsData.context.data_objects[0].id === currentReportingObject.id;
                        });
                        if (currentFloorMetricsData) {
                            floor.floor = {
                                percentage:
                                    (currentFloorMetricsData.items[0].value * 100) / (floor.location!.metricValue || 1),
                                name: currentFloorMetricsData.context.data_objects[0].name,
                                metricValue: currentFloorMetricsData.items[0].value,
                            };
                            seriesById[IdsOfFunnelStepsOptions.Floor]?.data.push({
                                y: currentFloorMetricsData?.items[0].value,
                                name: t(LegendNamesOfFunnelSteps.floor),
                                x: reportingObjectIndex,
                            });
                        }
                        extendedReportingObjects.push(floor);
                        break;

                    default:
                        break;
                }
            }
        });
    }

    return {
        chartOptionsGeneratorSettings: {
            series: Object.values(seriesById),
            columnsStacking: 'normal',
            xAxisType: 'category',
            sharedTooltips: false,
            inactiveSeriesOpacity: 0.2,
            seriesReverse: true,
            columnsGrouping: false,
            inverted: true,
            categories,
        },
        extendedReportingObjects,
    };
};

export default generateData;
