import React, { useMemo } from 'react';
import { IRating } from '../../interfaces';
import { Performance_Ratings_RatingList_Widget_Reducer_Values } from '../../reducer';
import RatingItem from './components/RatingItem/RatingItem';
import { RatingListContainer } from './style';
import { useSelector } from 'react-redux';

const RatingList: React.FC = () => {
    const { ratings } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);

    const List = useMemo(() => {
        const ratingItems = ratings;
        if (ratingItems && ratingItems?.length !== 0) {
            return ratingItems.map((item: IRating) => {
                return <RatingItem rating={item} key={item.id}></RatingItem>;
            });
        }
        return;
    }, [ratings]);
    return <RatingListContainer>{List}</RatingListContainer>;
};
export default RatingList;
