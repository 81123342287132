import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import generateId from '../../../../../../../../tools/generateId';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import { columns } from '../components/Table/constants/constants';
import {
    resetChangedData,
    storeTableData,
    Configuration_EventsConfig_EventManagement_Widget_Reducer_Values,
    resetFilters,
} from '../redcuer';

/**
 * Кастомный хук для подготовки данных
 */
const usePrepareData = () => {
    const { rawEventsData } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const { cancelChangesObject } = useSelector(Configuration_EventsConfig_EventManagement_Widget_Reducer_Values);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    /** Получение начальной таблицы */
    useEffect(() => {
        dispatch(resetChangedData());
        dispatch(resetFilters());
        dispatch(storeTableData([]));
        const frontId = generateId();
        const header = columns.map((element) => ({
            ...element,
            value: t(element.value),
            type: 'header',
            readOnly: true,
            frontId,
        }));

        const body = rawEventsData?.map((event) => {
            const frontId = generateId();
            const dateFrom = DateTime.fromISO(event.date_from).setZone(event.timezone);
            const dateTo = DateTime.fromISO(event.date_to).setZone(event.timezone);
            return columns.map((element) => {
                const data = {
                    ...element,
                    value: event[element.eventKey],
                    readOnly: false,
                    type: 'body',
                    frontId,
                    event,
                };

                switch (element.key) {
                    case 'controls':
                        data.readOnly = true;
                        break;
                    case 'event_type':
                        data.readOnly = true;
                        break;
                    case 'data_objects_ids':
                        data.readOnly = true;
                        break;
                    case 'date_from':
                        data.value = dateFrom.toFormat('yyyy-MM-dd');
                        break;
                    case 'time_from':
                        data.value = dateFrom.toFormat('HH:mm');
                        break;
                    case 'date_to':
                        data.value = dateTo.toFormat('yyyy-MM-dd');
                        break;
                    case 'time_to':
                        data.value = dateTo.toFormat('HH:mm');
                        break;
                    default:
                        break;
                }
                return data;
            });
        });

        const result = Array.isArray(body) ? [header, ...body] : [header];
        dispatch(storeTableData(result));
    }, [rawEventsData, cancelChangesObject]);
};

export default usePrepareData;
