import React, { useMemo, useRef, useState } from 'react';
import { TreeView } from '@mui/lab';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BothIcomsWrapper, DeleteIconWrapper, IconWrapper, ItemWrapper, TreeWrapper } from './styles';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Button, Modals } from '../../../../../../../../../lib/esm/components';
import StyledTreeItem from './components/StyledTreeItem';
import { CloseSquare, MinusSquare, PlusSquare } from './components/Icons';
import { getTheme, Icon, TooltipHost } from '@fluentui/react';
import ModalAddSubCategory from './components/ModalAddSubCategory';
import ModalDeleteSubCategory from './components/ModalDeleteSubCategory';

const theme = getTheme();

const Tree: React.FC = ({}) => {
    const {
        src: { categories, projectCategories },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const [addSubCategoryModalStatus, setAddSubCategoryModalStatus] = useState<{
        show: boolean;
        parentId?: number | null;
        mode?: string;
    }>({
        show: false,
    });
    const [deleteSubCategoryModalStatus, setDeleteSubCategoryModalStatus] = useState<{
        show: boolean;
        id?: number;
        mode?: string;
    }>({
        show: false,
    });

    const handleModalClose = () => {
        setAddSubCategoryModalStatus({ show: false });
        setDeleteSubCategoryModalStatus({ show: false });
    };

    const tree = useMemo(() => {
        const categoriesColumns = categories
            ?.filter((item) => !item.parent_id)
            .map((item) => ({ value: item.object_name }));
        const projectCategoriesColumns = projectCategories
            ?.filter((item) => !item.parent_id)
            .map((item) => ({ value: item.object_name }));

        const columns = [{ value: t('Object type') }, { value: t('Object name') }];

        columns.push(...categoriesColumns, ...projectCategoriesColumns);

        const allCategories = [
            // ...categories,
            ...projectCategories,
        ];

        const onAddSubCategoryClick = (parentId: number, mode: string) => () => {
            setAddSubCategoryModalStatus({ show: true, parentId, mode });
        };

        const onDeleteSubCategoryClick = (id: number, mode: string) => () => {
            setDeleteSubCategoryModalStatus({ show: true, id, mode });
        };

        const data = allCategories
            ?.filter((item) => !item.parent_id)
            .map((item, j) => {
                const parentId = item.id;

                const subTree = allCategories
                    ?.filter((item) => item.parent_id === parentId)
                    .map((item, i) => {
                        return (
                            <ItemWrapper key={`${item.id}--${i}--${j}`}>
                                <StyledTreeItem
                                    nodeId={String(item.id)}
                                    label={item.object_name}
                                    key={`${item.id}--${i}--${j}`}
                                />
                                <TooltipHost content={t('Delete subcategory')}>
                                    <DeleteIconWrapper onClick={onDeleteSubCategoryClick(item.id, 'subcategory')}>
                                        <Icon iconName="Cancel" />
                                    </DeleteIconWrapper>
                                </TooltipHost>
                            </ItemWrapper>
                        );
                    });

                return (
                    <ItemWrapper key={`${item.id}----${j}`}>
                        <StyledTreeItem nodeId={String(item.id)} label={item.object_name}>
                            {subTree}
                        </StyledTreeItem>
                        <BothIcomsWrapper>
                            <TooltipHost content={t('Add new subcategory')}>
                                <IconWrapper onClick={onAddSubCategoryClick(parentId, 'subcategory')}>
                                    <Icon iconName="Add" />
                                </IconWrapper>
                            </TooltipHost>
                            <TooltipHost content={t('Delete category')}>
                                <DeleteIconWrapper onClick={onDeleteSubCategoryClick(item.id, 'category')}>
                                    <Icon iconName="Cancel" />
                                </DeleteIconWrapper>
                            </TooltipHost>
                        </BothIcomsWrapper>
                    </ItemWrapper>
                );
            });

        console.log(data);

        return (
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                sx={{
                    height: 'auto',
                    maxHeight: '100%',
                    maxWidth: '400px',
                    flexGrow: 1,
                    overflowY: 'auto',
                    paddingTop: '20px',
                    paddingBottom: '30px',
                }}
            >
                {data}
            </TreeView>
        );
    }, [categories, projectCategories, t]);

    return (
        <TreeWrapper>
            <Button
                text={t('Add new category')}
                onClick={() => setAddSubCategoryModalStatus({ show: true, parentId: null, mode: 'category' })}
            />

            {tree}

            <Modals
                title={
                    addSubCategoryModalStatus.mode === 'subcategory'
                        ? t('Enter new subcategory name')
                        : t('Enter new category name')
                }
                closeModal={handleModalClose}
                modalStatus={addSubCategoryModalStatus}
                topColor={theme.palette.themePrimary}
            >
                <ModalAddSubCategory modalStatus={addSubCategoryModalStatus} handleOKClick={handleModalClose} />
            </Modals>

            <Modals
                title={
                    deleteSubCategoryModalStatus.mode === 'subcategory'
                        ? t('Delete subcategory?')
                        : t('Delete category?')
                }
                closeModal={handleModalClose}
                modalStatus={deleteSubCategoryModalStatus}
                topColor={theme.palette.red}
            >
                <ModalDeleteSubCategory modalStatus={deleteSubCategoryModalStatus} handleOKClick={handleModalClose} />
            </Modals>
        </TreeWrapper>
    );
};

export default Tree;
