import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../lib/esm/components';
import { TNestedOptions, TSelectedOptions } from '../../../../lib/esm/components/Select/configurations';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { getMetrics } from './tools/getMetrics';
import { generalReducerValues } from '../../../../General.reducer';
import { storeSelectedMetrics } from '../../UserSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsReducerValues } from '../../UserSettings/reducer';
import { useWidgetCurrentOptions } from '../../../../tools/useWidgetCurrentOptions';

/**
 * Компонент для отображения селекта с выбором метрик
 * @param dropdownWidth Ширина селекта
 * @param showFilter Флаг, отвечающий за показ фильтра
 * @param selectedMetrics Выбранные метрики (для контроля извне)
 * @param mode Режим работы селетка
 */
const MetricsSelect: React.FC<IProps> = ({
    dropdownWidth = 'md',
    showFilter = true,
    maxSelected,
    selectedMetrics,
    mode = 'multi',
    ...props
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { allMetrics, currentModule, selectedLocationId } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModule);

    /**
     * Если у юзера пропали пермишшены на какието метрики, которые были ранее выбраны,
     * то этот хук исключает их и перезаписывает settings
     */

    useEffect(() => {
        if (localCurrentOptions?.selectedMetrics && allMetrics.length && selectedLocationId) {
            const allPermittedMetrics = allMetrics.map((item) => item.id);
            const permittedMetrics = localCurrentOptions?.selectedMetrics.filter((item) =>
                allPermittedMetrics.includes(item),
            );
            if (localCurrentOptions?.selectedMetrics.some((item) => !permittedMetrics.includes(item))) {
                dispatch(
                    storeSelectedMetrics({
                        module: currentModule,
                        locationId: selectedLocationId,
                        selectedMetrics: permittedMetrics,
                    }),
                );
            }
        }
    }, [allMetrics, currentModule, JSON.stringify(localCurrentOptions?.selectedMetrics), selectedLocationId]);

    const options = useMemo(() => {
        const obj = {};
        allMetrics
            .map((item) => {
                return { ...item, text: t(item.text), section: t(item.section) };
            })
            .forEach((item) => {
                if (obj[item.section]) {
                    obj[item.section] = [...obj[item.section], item];
                } else {
                    obj[item.section] = [item];
                }
            });
        const result: TNestedOptions = Object.keys(obj).map((key) => {
            const objects = obj[key];
            const section = key;
            return { section, objects };
        });
        return result;
    }, [allMetrics, t]);

    let outsideSelected: TSelectedOptions = [];

    if (selectedMetrics) {
        outsideSelected = allMetrics
            ?.filter((item) => selectedMetrics.includes(item.id))
            .map((item) => ({
                ...item,
                section: t(item.section),
                text: t(item.text),
            }));
    } else {
        const metricIds = localCurrentOptions?.selectedMetrics || [];
        outsideSelected = allMetrics
            ?.filter((item) => metricIds.includes(item.id))
            .map((item) => ({
                ...item,
                section: t(item.section),
                text: t(item.text),
            }));
    }

    const handleSelect = (args: TSelectedOptions) => {
        const selectedMetrics = args.map((item) => String(item.id));
        props.handleSelect && props.handleSelect(selectedMetrics);
        selectedLocationId &&
            dispatch(
                storeSelectedMetrics({
                    module: currentModule,
                    locationId: selectedLocationId,
                    selectedMetrics,
                }),
            );
    };

    return (
        <Wrapper>
            <Select
                // disabled={locations.length === 0}
                options={options}
                dropdownWidth={dropdownWidth}
                text={t('Selected')}
                minSelected={1}
                maxSelected={maxSelected}
                dropdownAlign="left"
                showFilter={showFilter}
                mode={mode}
                labelText={`${t('Metrics')}${maxSelected ? ` (${t('max')}: ${maxSelected})` : ''}`}
                iconType="chevron-down"
                handleSelect={handleSelect}
                outsideSelected={outsideSelected}
                // outsideText={mainPeriod ? getOutsideText(mainPeriod) : getOutsideText(options[0] as IPeriod)}
            />
        </Wrapper>
    );
};

export default MetricsSelect;
