import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { subsetsOfSalesMetricsOptions } from '../../../constants/contsants';
import { IExtendedTenant } from '../interfaces';
import {
    Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    storeExtendedTenantsById,
    storeSelectedSubsetId,
    storeTotalValue,
    toggleMetricsFetching,
    toggleMetricsFetchingError,
    updateExtendedTenantsWithMetricData,
} from '../reducer';
import aggregateRawMetrcisData from '../tools/aggregateRawMetrcisData';
import getTotalValue from '../tools/getTotalValue';

/**
 * Кастомный хук для подготовки данных
 */
const usePrepareData = () => {
    const { selectedSubsetId, rawMetricsData } = useSelector(
        Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    );
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    /** Инициализационный выбор подмножества */
    useEffect(() => {
        if (!selectedSubsetId) {
            const idsOfSubsets = subsetsOfSalesMetricsOptions.map((element) => element.id);
            dispatch(storeSelectedSubsetId(idsOfSubsets[0]));
        }
    }, [subsetsOfSalesMetricsOptions]);

    /** Изменение флагов ошибки и загрузки */
    useEffect(() => {
        if (rawMetricsData?.['status'] === ResponseStatus.Loading) {
            dispatch(toggleMetricsFetchingError(false));
            dispatch(toggleMetricsFetching(true));
        } else if (
            rawMetricsData?.['status'] === ResponseStatus.Error ||
            rawMetricsData?.['status'] === ResponseStatus.NoData
        ) {
            dispatch(toggleMetricsFetchingError(true));
            dispatch(toggleMetricsFetching(false));
        }
    }, [rawMetricsData]);

    /** Агрегация сырых данных */
    useEffect(() => {
        if (Array.isArray(rawMetricsData) && reportingObjectsByType['tenant']?.length) {
            dispatch(toggleMetricsFetching(false));

            const aggregatedData = aggregateRawMetrcisData({ rawMetricsData });
            const totalValue = getTotalValue({
                tenantsCount: reportingObjectsByType['tenant'].length,
                rawMetricsData,
            });

            dispatch(updateExtendedTenantsWithMetricData(aggregatedData));
            dispatch(storeTotalValue(totalValue));
        }
    }, [rawMetricsData, reportingObjectsByType['tenant']]);

    /** Получение расширенных объектов арендаторов */
    useEffect(() => {
        if (
            reportingObjectsByType?.['tenant'] &&
            Object.values(reportingObjectsByType?.['tenant']).length &&
            selectedSubsetId
        ) {
            const currentSubset = subsetsOfSalesMetricsOptions?.find((element) => element.id === selectedSubsetId);

            if (currentSubset) {
                const extendedTenantsById: { [id: string]: IExtendedTenant } = reportingObjectsByType['tenant'].reduce(
                    (acc, value) => {
                        const metricsData = currentSubset.subtypes.reduce((acc, value) => {
                            acc[value.id] = {
                                meinPeriod: null,
                                comparePeriod: null,
                            };
                            return acc;
                        }, {});

                        acc[value.id] = {
                            tenantData: value,
                            metricsData,
                        };

                        return acc;
                    },
                    {},
                );

                dispatch(storeExtendedTenantsById(extendedTenantsById));
            }
        }
    }, [reportingObjectsByType, selectedSubsetId]);
};

export default usePrepareData;
