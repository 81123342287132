import { stringDate } from '../../../../../../../../tools/Strings/stringDate';
import { TTimeFreq } from '../../../../../../../../lib/esm/components';

/**
 * Функция для получения данных о колонке с временем
 * @param detail Выбранная детализация
 * @param date Да
 * @returns Объект с именем колонки и значением
 */
const getDateColumnDataByDetail = (
    detail: TTimeFreq,
    date: string = '',
): { columnHeader: string; columnValue: string } => {
    let columnHeader: string = 'Date';
    let columnValue = date;

    switch (detail) {
        case 'H':
            columnHeader = 'DateTime';
            columnValue = stringDate(date, 'en', '', 'yyyy-MM-dd HH:mm');
            break;
        case null:
            columnHeader = 'Period';
            break;
        default:
            columnHeader = 'Date';
            columnValue = stringDate(date, 'en', '', 'yyyy-MM-dd');
            break;
    }

    return {
        columnHeader,
        columnValue,
    };
};

export default getDateColumnDataByDetail;
