export const COMPARISON_REPORT_ADDITIONAL_FIELDS = 'compariosnReportAdditonalFields';

export enum AdditionalFiledsIds {
    AddId = 'addId',
    AddWeekDay = 'addWeekDay',
    AddTimeZone = 'addTimeZone',
    AddPercent = 'addPercent',
    AddDelta = 'addDelta',
}

export const additionalFieldsOptions = [
    { id: AdditionalFiledsIds.AddId, text: 'Add id' },
    { id: AdditionalFiledsIds.AddWeekDay, text: 'Add week day' },
    { id: AdditionalFiledsIds.AddTimeZone, text: 'Add time zone' },
    { id: AdditionalFiledsIds.AddPercent, text: 'Add percent' },
    { id: AdditionalFiledsIds.AddDelta, text: 'Add delta' },
];
