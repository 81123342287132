import { IAdditioanlSettings } from '../hooks/interfaces';
import { ISelectedChartType } from '../interfaces';
import { TFunction } from 'i18next';

/**
 * Функция для генерации дополнительных настроек графика
 * @param args аргументв
 * @returns дополнительные настройки графика
 */
const generateAdditionalSettings = (args: IArgs): IAdditioanlSettings => {
    const { selectedChartType, selectedMetric, t } = args;
    let showSeriesNameInTooltipBody = false;
    let tooltipSubTitle = '';
    let tooltipTitle = '';

    switch (selectedChartType.id) {
        case 'metrics':
            showSeriesNameInTooltipBody = true;
            break;
        case 'objects':
            tooltipSubTitle = t(selectedMetric);
            showSeriesNameInTooltipBody = true;
            break;

        default:
            tooltipSubTitle = t(selectedMetric);
            tooltipTitle = t('Periods');
            break;
    }

    return {
        showSeriesNameInTooltipBody,
        tooltipSubTitle,
        tooltipTitle,
    };
};

export default generateAdditionalSettings;

interface IArgs {
    /**
     * Выбранный тип графика
     */
    selectedChartType: ISelectedChartType;
    /**
     * Название выбранной метрики
     */
    selectedMetric: string;
    /**
     * функция перевода
     */
    t: TFunction;
}
