import { FC, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ContextualMenu from '../../../../../../../../../ContextualMenu/ContexttualMenu';
import { Events_EventsMap_Widget_Reducer_Values } from '../../../../../../../../Events/EventsMap/widgets/EventsMapWidget/reducer';
import { EventsManagementContext } from '../../../../context';
import { ITableCellData } from '../../../../interfaces';
import ContextualMenuBody from './components/ContextualMenuBody/ContextualMenuBody';
import { Wrapper } from './styles';

interface IProps {
    /** Данные ячейки таблицы */
    data: ITableCellData;
}

/**
 * Компонент для отображения ячейки с типо события
 */
const EventType: FC<IProps> = ({ data }) => {
    const { eventTypesByName } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const context = useContext(EventsManagementContext);
    const ref = useRef(null);

    const toggleContextualMenu = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const onTypeChange = (value: number) => {
        context?.updateCellValueTrans({ frontId: data.frontId, key: data.key, value });
    };

    return (
        <Wrapper ref={ref} onDoubleClick={toggleContextualMenu}>
            {eventTypesByName[data.value || '']?.name || ''}
            <ContextualMenu
                CustomMenuComponent={<ContextualMenuBody onTypeChange={onTypeChange} />}
                isContextualMenuOpen={isContextualMenuOpen}
                hideContextualMenu={closeContextualMenu}
                targetRef={ref}
                gapSpace={5}
            />
        </Wrapper>
    );
};

export default EventType;
