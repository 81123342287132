export const pivotItems = [
    {
        itemKey: 'zone',
        layerType: 'zones_layer',
        headerText: 'Zones',
    },
    {
        itemKey: 'tenant',
        layerType: 'tenants_layer',
        headerText: 'Tenants',
    },
    {
        itemKey: 'place',
        layerType: 'places_layer',
        headerText: 'Places',
    },
    {
        itemKey: 'passway',
        layerType: 'pass_ways_layer',
        headerText: 'Passways',
    },
    {
        itemKey: 'floor',
        layerType: 'perimeter_layer',
        headerText: 'Floors',
    },
];
