import { ChartWrapper, Wrapper } from './styles';
import { FC, useMemo } from 'react';

import Dynamics from '../../../../../../../../../Charts/Dynamics/Dynamics';
import { IProps } from './interfaces';
import useChartOptions from './hooks/useChartOptions';
import useGetMetrics from './hooks/useFetchData';
import usePrepareData from './hooks/usePrepareData';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userSettingsReducerValues } from '../../../../../../../../../UserSettings/reducer';
import { withLoading } from '../../../../../../../../../../../tools/API/withLoading';
import { Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values } from './reducer';

/**
 * Комопонент тела модального окна. Служит для отображения детализации для выбранного периода сравнения
 * @param extendedReportingObjectsById Объект расширенных отчетных объектов, где ключ ID объетка
 * @param reportingObjectId ID отчетного объекта, для которого нужно посмотреть детализацию
 * @param chartDetailing Детализация графиков (МЕСЯЦ: MS или ДЕНЬ: D)
 * @param selectedMetric Выбранная метркиа
 * @param timeRanges Период, за который нужна детализация
 */
const PeriodModalBody: FC<IProps> = ({
    extendedReportingObjectsById,
    reportingObjectId,
    chartDetailing,
    selectedMetric,
    timeRanges,
}) => {
    const { series, xAxisLabelsFormating, tooltipSubTitle } = useChartOptions({ chartDetailing, selectedMetric });
    const { rawMetricsData, rawMetricsDataByTimeRange } = useSelector(
        Performance_YoY_YoY_PeriodModalBody_Component_Reducer_Values,
    );
    const {
        currentOptions: { currentModule },
    } = useSelector(userSettingsReducerValues);
    const { t } = useTranslation();
    useGetMetrics({
        extendedReportingObjectsById,
        reportingObjectId,
        chartDetailing,
        selectedMetric,
        timeRanges,
    });
    usePrepareData();

    const chartId = `dynamics:${currentModule}:0`;

    const chart = useMemo(() => {
        const WithLoadingTable = withLoading(ChartWrapper, {
            data: rawMetricsDataByTimeRange ? [] : rawMetricsData,
            height: 400,
        });
        return (
            <WithLoadingTable>
                <Dynamics
                    xAxisLabelsFormating={xAxisLabelsFormating}
                    tooltipSubTitle={t(tooltipSubTitle)}
                    tooltipTitle={t('Periods')}
                    chartId={chartId}
                    series={series}
                />
            </WithLoadingTable>
        );
    }, [rawMetricsData, series, t, selectedMetric]);

    return <Wrapper>{chart}</Wrapper>;
};

export default PeriodModalBody;
