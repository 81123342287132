import { version } from 'os';
import styled from 'styled-components/macro';

export const ObjectWrapper = styled.div`
    margin-bottom: var(--space-sm);
`;
export const Row = styled.div<{ numCompPeriods: number }>`
    -moz-column-gap: 3px;
    column-gap: 3px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${(p) => {
        return 'repeat(' + p.numCompPeriods + ', 210px)';
    }};
`;

export const PivotWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CellWrapper = styled.td<{
    styles: {
        textAlign: string;
        fontWeight: string;
        color: string;
        hover?: boolean;
    };
}>`
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    cursor: ${(p) => (p?.styles?.['hover'] ? 'pointer' : 'auto')};
    text-align: ${(p) => p.styles.textAlign};
    color: ${(p) => p.styles.color};
    font-weight: ${(p) => p.styles.fontWeight};
    font-size: 14px;
    position: relative;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const Title = styled.div`
    padding: var(--space-xxs) 0;
`;

export const Tr = styled.tr<{ isSelectedTenant: boolean }>`
    background-color: ${(p) => (p.isSelectedTenant ? 'rgb(255, 253, 235)' : 'none')};
`;

export const Th = styled.th`
    text-align: center;
    border: 1px solid hsla(0, 0%, 92.2%, 0.9);
    justify-content: center;
    font-size: 14px;
    position: relative;
`;
export const ArrowWrapper = styled.div`
    color: var(--color-blue-lighter);
    /* border: 1px solid hsla(0, 0%, 92.2%, 0.9); */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 10;
    background-color: white;
    top: 12px;
    left: -15px;
`;

export const Percent = styled.div`
    white-space: nowrap;
    /* flex: 0; */
    justify-content: flex-end;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const Value = styled.div<{ isMain?: boolean }>`
    width: 100%;
    align-items: center;
    display: flex;
    font-weight: ${(p) => (p.isMain ? '600' : '400')};
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const Period = styled.div`
    /* font-size: var(--text-sm); */
    width: 100%;
    padding: var(--space-xxxs) var(--space-xxs);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`;

export const Metric = styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const WidgetWrapper = styled.div`
    margin-top: var(--space-sm);
`;

export const Marker = styled.div`
    padding: var(--space-xxs);
    display: block;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
export const TableWrapper = styled.div`
    padding: var(--space-xxs) 0;
    max-height: 700px;
    overflow: auto;
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }
    tr {
        border: 1px solid var(--default-border-color);
    }
    td,
    th {
        padding: var(--space-xxxxs) var(--space-xxs);
        height: var(--space-lg);
    }
    thead {
        font-weight: 600;
    }
`;

export const Name = styled.td`
    /* max-width: 400px; */
`;

export const MainPercent = styled.td`
    font-weight: 700;
    text-align: right;
`;

export const MainTraffic = styled.td`
    text-align: right;
    color: var(--color-gray-darker);
    border-right: 1px solid var(--default-border-color);
`;
export const CompareDates = styled.td`
    text-align: right;
    div {
        display: flex;
        flex-direction: column;
    }
`;

export const ComparePercent = styled.td<{ color: string }>`
    text-align: right;
    font-weight: 700;
    color: ${(p) => p.color};
`;

export const Rating = styled.td`
    min-width: 100px;
    position: relative;
    display: flex;
`;

export const RatingValue = styled.div<{ width: string }>`
    width: ${(p) => {
        return p.width;
    }};
    background-color: var(--color-info-lighter);
    opacity: 0.6;
`;

export const PeriodWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const Cont = styled.div`
    display: flex;
    align-items: center;
`;
