import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { generalReducerValues } from '../General.reducer';
import { getRequest, patchRequest, postRequest } from './API/appBackendAPI';
import {
    userSettingsReducerValues,
    storeAccParams,
    toggleAccParamsReceived,
} from '../cabinet/components/UserSettings/reducer';

export const useRequestAccParams = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token, selectedLocationId, appBackendHost, user, currentModule } = useSelector(generalReducerValues);
    const { settings, accParamsID, accParamsReceived } = useSelector(userSettingsReducerValues);
    const baseUrl = `${appBackendHost}/api/user-settings/account-parameters/`;

    /** При загрузке приложения, запрос Параметров сохранения аакаунта */
    useEffect(() => {
        const url = `${baseUrl}?user_id=${user?.id}`;
        if (token && user?.id) {
            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (response?.['data']?.length) {
                    dispatch(storeAccParams(response['data'][0]));
                } else if (Array.isArray(response?.['data']) && !response?.['data']?.length) {
                    postRequest(url, token, { user_id: user?.id, params: settings }).then(
                        (response: AxiosError | AxiosResponse) => {
                            const url = `${appBackendHost}/api/user-settings/account-parameters/?user_id=${user?.id}`;
                            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                                dispatch(storeAccParams(response['data'][0]));
                            });
                        },
                    );
                }
            });
        }
    }, [appBackendHost, dispatch, token, user?.id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (accParamsID) {
                dispatch(toggleAccParamsReceived());
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [accParamsID]);

    useEffect(() => {
        if (!user?.id || !token || !accParamsReceived) return;
        const timer = setTimeout(() => {
            patchRequest(baseUrl, token, { id: accParamsID, params: settings }).then();
        }, 5000);
        return () => clearTimeout(timer);
    }, [settings, accParamsReceived, appBackendHost, token, user?.id]);

    // useEffect(() => {
    //     if (!user?.id || !token) return;
    //     if (accParamsID) {
    //         patchRequest(baseUrl, token, { id: accParamsID, params: settings }).then();
    //     }
    // }, [accParamsID, baseUrl, currentModule, selectedLocationId, token, user?.id]);
};
