import { ICabinetPreferencesState, IGeneralPreferences } from './interfaces';
import { TMetricLevel } from './components/General/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { cloneDeep } from 'lodash';
import { CABINET_PREFERENCES_LOCAL_STORAGE_KEY, DEFAULT_GENERAL_CABINET_PREFERENCES } from './constants/constants';

const initialState: ICabinetPreferencesState = {
    preferences: DEFAULT_GENERAL_CABINET_PREFERENCES,
    dataReceived: false,
};

export const CabinetPreferencesReducer = createSlice({
    name: 'cabinetPreferences',
    initialState,
    reducers: {
        /**
         * Запись настроек кабинета
         */
        storeCabinetPreferences: (state, action: PayloadAction<{ preferences: IGeneralPreferences }>) => {
            state.preferences = action.payload.preferences;
            state.dataReceived = true;
        },

        /**
         * Запись metric level
         */
        storeMetricLevel: (state, action: PayloadAction<TMetricLevel>) => {
            const { preferences } = cloneDeep(state);
            state.preferences = { ...preferences, metricLevel: action.payload };
            localStorage.setItem(CABINET_PREFERENCES_LOCAL_STORAGE_KEY, JSON.stringify(cloneDeep(state)));
        },
    },
});

export const { storeCabinetPreferences, storeMetricLevel } = CabinetPreferencesReducer.actions;

export const cabinetPreferencesValues = (state: RootState) => state.CabinetPreferencesReducer;

export default CabinetPreferencesReducer.reducer;
