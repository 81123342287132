import { IAdditioanlSettings } from '../hooks/interfaces';
import { ISelectedChartType } from '../interfaces';

/**
 * Функция для генерации дополнительных настроек графика
 * @param args аргументв
 * @returns дополнительные настройки графика
 */
const generateAdditionalSettings = (args: IArgs): IAdditioanlSettings => {
    const { selectedChartType, selectedMetric } = args;
    let showSeriesNameInTooltipBody = false;
    let tooltipSubTitle = '';
    let tooltipTitle = '';

    switch (selectedChartType.id) {
        case 'metrics':
            showSeriesNameInTooltipBody = true;
            break;
        case 'objects':
            tooltipSubTitle = selectedMetric;
            showSeriesNameInTooltipBody = true;
            break;

        default:
            tooltipSubTitle = selectedMetric;
            tooltipTitle = 'Periods';
            break;
    }

    return {
        showSeriesNameInTooltipBody,
        tooltipSubTitle,
        tooltipTitle,
    };
};

export default generateAdditionalSettings;

interface IArgs {
    /**
     * Выбранный тип графика
     */
    selectedChartType: ISelectedChartType;
    /**
     * Название выбранной метрики
     */
    selectedMetric: string;
}
