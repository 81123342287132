import React from 'react';
import { useTranslation } from 'react-i18next';
import UniversalSelect from '../../../../Selects/UniversalSelect/UniversalSelect';

const LayoutSelect: React.FC = ({}) => {
    const { t } = useTranslation();

    const options = [
        { id: 'portrait', text: 'Portrait' },
        { id: 'landscape', text: 'Landscape' },
    ];

    return (
        <UniversalSelect
            name={'pageLayout'}
            labelText={t('Page orientation')}
            options={options}
            mode="single"
            defaultSelected={[{ id: 'portrait', text: 'Portrait' }]}
        />
    );
};

export default LayoutSelect;
