import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from '../../../lib/esm/components';
import { IProps, TOrdering } from './interfaces';
import { IconWrapper, Wrapper } from './styles';

const OrderSwitcher: React.FC<IProps> = ({
    mode = 'double',
    id = 'percent',
    handleOrderToggle,
    ordering = 'none',
    primaryId = null,
}) => {
    const onClick = (dir: TOrdering) => (e: React.MouseEvent) => {
        e.stopPropagation();

        if (mode === 'double') {
            handleOrderToggle({ ordering: dir, id });
        } else {
            if (ordering === dir && id === primaryId) {
                handleOrderToggle({ ordering: 'none', id });
            } else {
                handleOrderToggle({ ordering: dir, id });
            }
        }
    };

    const getColor = useCallback(
        (mode: TOrdering) => {
            if (mode === 'none' || mode !== ordering || (primaryId && primaryId !== id)) {
                return 'secondary-dark';
            } else {
                return 'primary';
            }
        },
        [id, ordering, primaryId],
    );

    return (
        <Wrapper>
            <IconWrapper onClick={onClick('up')}>
                <Icon type="caret-up" size="xxs" color={getColor('up')} />
            </IconWrapper>
            <IconWrapper onClick={onClick('down')}>
                <Icon type="caret-down" size="xxs" color={getColor('down')} />
            </IconWrapper>
        </Wrapper>
    );
};

export default OrderSwitcher;
