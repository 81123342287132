import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../../../../lib/esm/components';

export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    span {
        margin-right: var(--space-sm);
    }
`;
