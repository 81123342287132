import { cloneDeep } from 'lodash';
import { TSeries } from '../../../../../../Charts/Dynamics/interfaces';

/**
 * Функция для получения числа посетителей внутри в данный момент
 * @param series все серии для отчетного объекта
 * @returns число посетителей внутри
 */
const getVisitorsAtTheMoment = (series: TSeries[]): number => {
    let visitorsAtTheMoment = 0;

    series.forEach((element) => {
        if (element.isMain) {
            element.data.forEach((element, index, initialValue) => {
                const currentDateValue = cloneDeep(initialValue)
                    .reverse()
                    ?.find((element) => element.y !== null)?.y;

                visitorsAtTheMoment = currentDateValue || element.y || 0;
            });
        }
    });

    return visitorsAtTheMoment < 0 ? 0 : visitorsAtTheMoment;
};

export default getVisitorsAtTheMoment;
