import { TFunction } from 'i18next';
import { IReportingObject } from '../General.interfaces';

export const getReportingObjects = (reportingObjectsByType: { [x: string]: IReportingObject[] }, t: TFunction) => {
    return Object.keys(reportingObjectsByType).map((key) => {
        const objects = reportingObjectsByType[key].map((obj) => {
            return { ...obj, text: obj.name, type: 'object' };
        });
        const section = objectTypesMap[key] ? t(objectTypesMap[key]) : key;
        return { section, objects, key };
    });
};

export const objectTypesMap = {
    passway: 'Passways',
    zone: 'Zones',
    place: 'Places',
    floor: 'Floors',
    location: 'Location',
    tenant: 'Tenants',
};
