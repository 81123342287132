import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';
import { getUrls, hosts } from '../../constants/urls.js';

var requestLocations = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios({
                        method: 'POST',
                        url: getUrls(hosts).LOCATIONS_URL,
                        data: JSON.stringify({
                            auth: {
                                xtoken: token,
                            },
                        }),
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, error_1];
            case 3: return [2 /*return*/];
        }
    });
}); };

export { requestLocations as default };
