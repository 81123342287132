import { IPeriod, IDateRange } from './../General.interfaces';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, storeSelectedLocationId } from '../General.reducer';
import { storeCurrentOptions } from '../cabinet/components/UserSettings/reducer';
import { useTranslation } from 'react-i18next';
import { userSettingsReducerValues } from '../cabinet/components/UserSettings/reducer';
import { generateMainDateRanges } from './generateMainDateRanges';
import { generateCompareDateRanges } from './generateCompareDateRanges';
import { modulesConfig } from '../constants/modulesConfig';

const useCurrentOptions = (): void => {
    const { t } = useTranslation();
    const { settings } = useSelector(userSettingsReducerValues);
    const { selectedLocationId, currentModule, lang, timeZone } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    useEffect(() => {
        const config = modulesConfig?.find((item) => item.module_name === currentModule);
        if (config && currentModule && selectedLocationId && timeZone) {
            const newCurrentOptions: { [x: string]: any } = {};
            Object.keys(config.store_method).forEach((key) => {
                const options: string[] = config.store_method[key];
                options.forEach((option) => {
                    switch (key) {
                        case 'byML':
                            newCurrentOptions[option] =
                                settings?.[`${key}:${currentModule}:${selectedLocationId}`]?.[option];
                            break;
                        case 'byM':
                            newCurrentOptions[option] = settings?.[`${key}:${currentModule}`]?.[option];
                            break;
                        case 'byL':
                            newCurrentOptions[option] = settings?.[`${key}:${selectedLocationId}`]?.[option];
                            break;
                        case 'global':
                            newCurrentOptions[option] = settings?.[key]?.[option];
                            break;

                        default:
                            break;
                    }
                });
            });

            if (newCurrentOptions?.['mainPeriod']) {
                const mainDateRanges: IDateRange[] = generateMainDateRanges(
                    newCurrentOptions.mainPeriod as IPeriod,
                    t,
                    timeZone,
                );
                newCurrentOptions['mainDateRanges'] = mainDateRanges;
            }

            if (
                newCurrentOptions?.['mainDateRanges'] &&
                newCurrentOptions?.['comparePeriods'] &&
                newCurrentOptions?.['mainPeriod']
            ) {
                const compareDateRanges: IDateRange[] = generateCompareDateRanges(
                    newCurrentOptions?.['mainDateRanges'],
                    newCurrentOptions.comparePeriods as IPeriod[],
                    newCurrentOptions.mainPeriod as IPeriod,
                    t,
                    lang,
                );
                newCurrentOptions['compareDateRanges'] = compareDateRanges;
            }

            dispatch(
                storeCurrentOptions({
                    ...newCurrentOptions,
                    selectedLocationId,
                    currentModule,
                }),
            );
        }
    }, [currentModule, dispatch, lang, selectedLocationId, settings, t, timeZone]);

    useEffect(() => {
        if (Number(settings.global?.['pl']?.[0]?.id) !== selectedLocationId) {
            dispatch(storeSelectedLocationId(Number(settings.global?.['pl']?.[0]?.id)));
        }
    }, [dispatch, selectedLocationId, settings.global]);

};

export default useCurrentOptions;
