import { Stack } from '@fluentui/react';
import { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextInput } from '../../../../../../../../../lib/esm/components';
import ContextualMenu from '../../../../../../../ContextualMenu/ContexttualMenu';
import CsvFormatContextualMenuBody from './components/CsvFormatContextualMenuBody/CsvFormatContextualMenuBody';
import { CsvFormatButtonWrapper, InputWrapper, Label, Wrapper } from './styles';

interface IProps {}

/**
 * Компонент тела модального окна для пакетной загрузки событий
 */
const MultipleEventsModalBody: FC<IProps> = ({}) => {
    const [eventsCsvString, setEventsCsvString] = useState(
        'name;date_from;date_to;event_type;data_objects_ids;comment',
    );
    const [selectedFile, setSelectedFile] = useState<null | File>(null);
    const [showCsvFormat, setShowCsvFormat] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const csvFormat = useRef(null);
    const { t } = useTranslation();

    const onFileClick = (e: MouseEvent<HTMLInputElement>) => {
        e.target['value'] = null;
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.files && setSelectedFile(e.target.files[0]);
    };

    const onSelectClick = () => {
        fileInputRef?.current?.click();
    };

    const onCsvStringChange = (args: { value: string }) => {
        setEventsCsvString(args.value);
    };

    const toggleCsvFormatContextualMenu = () => {
        setShowCsvFormat((prevState) => !prevState);
    };

    const closeCsvFormatContextualMenu = () => {
        setShowCsvFormat(false);
    };

    const onAddClick = () => {
        console.log('start parsing');
    };

    return (
        <Wrapper>
            <input
                style={{ display: 'none' }}
                onChange={onFileChange}
                onClick={onFileClick}
                ref={fileInputRef}
                accept=".csv"
                type="file"
            />

            <ContextualMenu
                CustomMenuComponent={<CsvFormatContextualMenuBody />}
                hideContextualMenu={closeCsvFormatContextualMenu}
                isContextualMenuOpen={showCsvFormat}
                targetRef={csvFormat}
                gapSpace={10}
            />

            <CsvFormatButtonWrapper ref={csvFormat}>
                <Button onClick={toggleCsvFormatContextualMenu}>{t('Show csv format')}</Button>
            </CsvFormatButtonWrapper>

            <InputWrapper>
                <Label>{t('Event csv string')}</Label>
                <TextInput
                    handleChange={onCsvStringChange}
                    outsideValue={eventsCsvString}
                    isMultiLine={true}
                    fullWidth={true}
                />
            </InputWrapper>

            <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 10, padding: '15px 0 0 0' }}>
                <Button onClick={onSelectClick}>{t('Select csv file')}</Button>
                <div>{selectedFile?.name}</div>
            </Stack>
            <Stack horizontal={true} verticalAlign="center" horizontalAlign="end" tokens={{ padding: '15px 0 0 0' }}>
                <Button onClick={onAddClick}>{t('Add')}</Button>
            </Stack>
        </Wrapper>
    );
};

export default MultipleEventsModalBody;
