import { cloneDeep, isNumber } from 'lodash';
import { IReportingObject } from '../../../../../../../../General.interfaces';
import { IExtendedTenant, ITableOrderData } from '../interfaces';

interface IArgs {
    tenants: IReportingObject[];
    tableOrderData: ITableOrderData;
    extendedTenantsById: { [id: string]: IExtendedTenant };
}

/**
 * Функция для сортировки арнедаторов
 */
const sortTenants = (args: IArgs): IReportingObject[] => {
    const { tenants, tableOrderData, extendedTenantsById } = args;

    if (tableOrderData.columnId === 'tenant') {
        return cloneDeep(tenants).sort((a, b) => {
            if (tableOrderData.ordering === 'down') {
                return a.name > b.name ? -1 : 1;
            }

            return a.name < b.name ? -1 : 1;
        });
    }

    return cloneDeep(tenants).sort((a, b) => {
        const aData: IExtendedTenant | undefined = extendedTenantsById[a.id];
        const bData: IExtendedTenant | undefined = extendedTenantsById[b.id];
        if (aData && bData) {
            let aSubtypeValue: number | undefined =
                aData.metricsData[tableOrderData.columnId]?.mainPeriod?.items[0].value;
            let bSubtypeValue: number | undefined =
                bData.metricsData[tableOrderData.columnId]?.mainPeriod?.items[0].value;

            if (tableOrderData.period === 'compare') {
                const aCompareValue = aData.metricsData[tableOrderData.columnId]?.comparePeriod?.items[0].value;
                const aMainValue = aData.metricsData[tableOrderData.columnId]?.mainPeriod?.items[0].value;
                const bCompareValue = bData.metricsData[tableOrderData.columnId]?.comparePeriod?.items[0].value;
                const bMainValue = bData.metricsData[tableOrderData.columnId]?.mainPeriod?.items[0].value;

                if (
                    isNumber(aCompareValue) &&
                    isNumber(bCompareValue) &&
                    isNumber(bMainValue) &&
                    isNumber(aMainValue)
                ) {
                    aSubtypeValue = (aCompareValue / aMainValue) * 100 - 100;
                    bSubtypeValue = (bCompareValue / bMainValue) * 100 - 100;
                } else {
                    aSubtypeValue = undefined;
                    bSubtypeValue = undefined;
                }
            }

            if (isNumber(aSubtypeValue) && isNumber(bSubtypeValue)) {
                if (tableOrderData.ordering === 'down') {
                    return aSubtypeValue < bSubtypeValue ? -1 : 1;
                }

                return aSubtypeValue > bSubtypeValue ? -1 : 1;
            }
        }

        return 1;
    });
};

export default sortTenants;
