import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Group, Shape } from 'react-konva';
import { Props } from './interfaces';

const Place: React.FC<Props> = ({ object, selectObject, selectedMarker, onMouseOverLeave, heatColor = 'gray' }) => {
    const onObjectClick = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        selectObject(object.marker);
    };

    const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave(object.marker);
    };

    const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        onMouseOverLeave(null);
    };

    const fill = selectedMarker !== object.marker ? heatColor : 'rgba(0,0,255,0.5)';

    return (
        <Shape
            id={'Shape!@##11'}
            key={'Shape!@##11'}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onObjectClick}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                object.coords.coordinates.forEach((coord: number[], i: number) => {
                    if (i > 0) {
                        context.lineTo(coord[0], coord[1]);
                    }
                });
                context.fillStrokeShape(shape);
            }}
            opacity={1}
            // stroke={'blue'}
            fill={fill}
            strokeWidth={2}
        />
    );
};

export default Place;
