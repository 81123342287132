import { ContextualMenu } from '@fluentui/react';
import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div<{ marginBottom?: string }>`
    width: 100%;
    min-height: 62px;
    border-bottom: 1px solid var(--color-border-gray);
    display: flex;
    justify-content: center;
    margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : 'var(--space-sm)')};
    position: sticky;
    top: 0;
    background-color: var(--color-secondary);
    z-index: 100;
`;

export const HeaderCentralArea = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    max-width: 1230px;
    padding: 0 var(--space-md);
`;

export const HeaderControlsArea = styled.div`
    display: flex;
    align-items: center;
`;

export const LangWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--space-xxxxs);
    width: 36px;
    height: 36px;
    border: 1px solid var(--color-border-gray);
`;

export const StyledContextualMenu = styled(ContextualMenu)`
    min-width: auto !important;
`;

export const LogoutWrapper = styled.div`
    margin-left: var(--space-xs);
    cursor: pointer;
`;
