import { __makeTemplateObject } from '../../_virtual/_tslib.js';
import styled from 'styled-components/macro';
import { getTheme } from '@fluentui/react/lib/Styling';

var theme = getTheme();
var _a = theme.spacing; _a.l1; _a.l2; _a.s1; var s2 = _a.s2; _a.m;
var Overlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: ", ";\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: all 0.2s;\n    opacity: ", ";\n    pointer-events: ", ";\n"], ["\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: ", ";\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: all 0.2s;\n    opacity: ", ";\n    pointer-events: ", ";\n"])), function (_a) {
    var isActive = _a.isActive;
    return (isActive ? 10000 : -10000);
}, function (_a) {
    var isActive = _a.isActive;
    return (isActive ? 1 : 0);
}, function (_a) {
    var isActive = _a.isActive;
    return (isActive ? 'all' : 'none');
});
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: #000;\n    font-size: 16px;\n    font-weight: 500;\n    overflow: hidden;\n"], ["\n    color: #000;\n    font-size: 16px;\n    font-weight: 500;\n    overflow: hidden;\n"])));
styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    align-self: flex-end;\n    margin-top: auto;\n"], ["\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    align-self: flex-end;\n    margin-top: auto;\n"])));
styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    right: 0px;\n    top: 5px;\n"], ["\n    position: absolute;\n    right: 0px;\n    top: 5px;\n"])));
var Modal = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;\n    border-radius: 2px;\n    background-color: rgb(255, 255, 255);\n    min-width: 300px;\n    min-height: 200px;\n    max-width: 90vw;\n    max-height: 90vh;\n    padding: 20px;\n    overflow: hidden;\n    transition: all 0.2s;\n    opacity: ", ";\n    border-top: ", ";\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    /* justify-content: space-between; */\n"], ["\n    box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;\n    border-radius: 2px;\n    background-color: rgb(255, 255, 255);\n    min-width: 300px;\n    min-height: 200px;\n    max-width: 90vw;\n    max-height: 90vh;\n    padding: 20px;\n    overflow: hidden;\n    transition: all 0.2s;\n    opacity: ", ";\n    border-top: ", ";\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    /* justify-content: space-between; */\n"])), function (_a) {
    var isActive = _a.isActive;
    return (isActive ? 1 : 0);
}, function (_a) {
    var topColor = _a.topColor;
    return "".concat(s2, " solid ").concat(topColor || theme.palette.themePrimary);
});
var Body = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    overflow-y: auto;\n    display: flex;\n    flex-grow: 1;\n"], ["\n    overflow-y: auto;\n    display: flex;\n    flex-grow: 1;\n"])));
var Header = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n"])));
/**
 * Стили для кнопки закрытия модалки.
 */
({
    root: {
        color: theme.palette.themePrimary,
        marginLeft: 'auto',
        marginTop: s2,
        marginRight: '2px',
        selectors: {
            i: { fontSize: '20px !important' },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

export { Body, Header, Modal, Overlay, Title };
