import { TMetricResponse } from '../../../../../../../../General.interfaces';
import { ISaleBySubset } from '../interfaces';

interface IArgs {
    /** Сырые данные продаж */
    rawMetricsData: TMetricResponse[];
}

/**
 * Функция для генерации объекта с ключом - производная от платежа, а его значения это подтипы производной
 */
const generateSalesBySubset = (args: IArgs): ISaleBySubset => {
    const { rawMetricsData } = args;
    const salesBySubset = rawMetricsData.reduce((acc, value) => {
        const [metricData] = value;
        const subsetName = metricData?.context.alias!.split(':')[1];
        if (acc[subsetName]) {
            acc[subsetName].push(value);
        } else {
            acc[subsetName] = [value];
        }
        return acc;
    }, {});

    const result = Object.keys(salesBySubset).reduce((acc, value) => {
        const subtypes: TMetricResponse[] = salesBySubset[value];
        const totalSum = subtypes.reduce((sum: number, value) => {
            sum += value[0].items[0].value;
            return sum;
        }, 0);

        const extendedSubtypes = subtypes.map((element) => {
            return {
                metricData: element,
                percentageOfSubset: (element[0].items[0].value / totalSum) * 100 || 0,
            };
        });

        acc[value] = extendedSubtypes;

        return acc;
    }, {});

    return result;
};

export default generateSalesBySubset;
