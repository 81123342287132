import { Checkbox, Select } from '../../../../../../../../lib/esm/components';
import { FC, useEffect } from 'react';
import { ItemsWrapper, Wrapper } from './styles';

import { AdditionalSeriesIds } from '../../../../constants/enums';
import { IExtendedOption } from './hooks/interfaces';
import { IProps } from './interfaces';
import { TChartSettingsKeys } from '../../../../../../ChartSettings/interfaces';
import usePrepareData from './hooks/usePrepareData';
import { useTranslation } from 'react-i18next';

/**
 * Меню настроек диаграммы
 * @param onSeriesSelect Функция выбора серии, на основе которой построить дополнительную серию
 * @param series все серии (включая новые)
 */
const ContextualMenuBody: FC<IProps> = ({
    updateChartSettingsByIdTrans,
    weekendsHighlightPossibility,
    chartSettings,
    chartId,
    series,
}) => {
    const { seriesSelectOptions } = usePrepareData({ series });
    const { t } = useTranslation();

    const handleToggleHighlightWeekend = () => {
        chartSettings &&
            updateChartSettingsByIdTrans({
                value: !chartSettings.highlightWeekends,
                key: 'highlightWeekends',
                chartId,
            });
    };

    const onSelect = (key: TChartSettingsKeys) => (args: IExtendedOption[]) => {
        const [series] = args;
        updateChartSettingsByIdTrans({
            value: series.id === AdditionalSeriesIds.Remove ? [] : [series.seriesData.id],
            chartId,
            key,
        });
    };

    return (
        <Wrapper>
            <ItemsWrapper>
                <Checkbox
                    handleToggle={handleToggleHighlightWeekend}
                    selected={chartSettings?.highlightWeekends}
                    disabled={!weekendsHighlightPossibility}
                    label={t('Highlight weekend')}
                    view={'checkbox'}
                />
            </ItemsWrapper>
            <ItemsWrapper>
                <Select
                    handleSelect={onSelect('selectedSeriesIdsForTrend')}
                    options={
                        [
                            {
                                id: AdditionalSeriesIds.Remove,
                                text: t('Remove trend') || '',
                                seriesData: {},
                            },
                            ...seriesSelectOptions,
                        ] as IExtendedOption[]
                    }
                    outsideText={
                        series?.find((element) => element.id === chartSettings?.selectedSeriesIdsForTrend[0])?.name ||
                        t('Select series')
                    }
                    labelText={t('Trend for')}
                    showFilter={false}
                    dropdownWidth="sm"
                    mode="single"
                />
            </ItemsWrapper>
            <ItemsWrapper>
                <Select
                    handleSelect={onSelect('selectedSeriesIdsForAverage')}
                    options={
                        [
                            {
                                id: AdditionalSeriesIds.Remove,
                                text: t('Remove average') || '',
                                seriesData: {},
                            },
                            ...seriesSelectOptions,
                        ] as IExtendedOption[]
                    }
                    outsideText={
                        series?.find((element) => element.id === chartSettings?.selectedSeriesIdsForAverage[0])?.name ||
                        t('Select series')
                    }
                    labelText={t('Average for')}
                    showFilter={false}
                    dropdownWidth="sm"
                    mode="single"
                />
            </ItemsWrapper>
        </Wrapper>
    );
};

export default ContextualMenuBody;
