import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IWindowSize } from '../../../../../../../../../General.interfaces';
import { Button, TextInput } from '../../../../../../../../../lib/esm/components';
import { TSelectedOptions } from '../../../../../../../../../lib/esm/components/Select/configurations';
import CheckboxGroupObjectType from './components/CheckboxGroupObjectType/CheckboxGroupObjectType';
import SelectMainIndicator from './components/SelectMainIndicator/SelectMainIndicator';
import SelectMetric from './components/SelectMetric/SelectMetric';
import SelectObjectType from './components/SelectObjectType/SelectObjectType';
import { ButtonWrapper, Column, FormItem, GridTemplate, Label, ModalNewRatingWrapper } from './styles';
import { DEFAULT_RATING_SETTING } from '../../constants/constants';
import _ from 'lodash';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import Panel from '../../../../../../../Panel/Panel';
import { ICheckboxItem } from '../../interfaces';
import { Performance_Ratings_RatingList_Widget_Reducer_Values, storeEditRatingId, storeNewRating } from '../../reducer';
import { useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { getReportingObjects } from '../../../../../../../../../tools/getReportingObjects';

const ModalNewRating: React.FC<{ mainWindowSize: IWindowSize; closeModal: () => void }> = ({
    mainWindowSize,
    closeModal,
}) => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { editRatingId, ratings } = useSelector(Performance_Ratings_RatingList_Widget_Reducer_Values);
    const { t } = useTranslation();
    const [rating, setValueRating] = useState(_.cloneDeep(DEFAULT_RATING_SETTING));
    const dispatch = useDispatch();
    const [selected, setSelected] = useState('asc');
    const objectsTypes = getReportingObjects(reportingObjectsByType, t).map((item) => ({
        id: item.key,
    }));
    const optionsSort = [
        {
            key: 'desc',
            text: `${t('Desc')}`,
        },
        {
            key: 'asc',
            text: `${t('Asc')}`,
        },
    ];

    /**
     * Обновление конфигурации рейтинга при редактировании
     */
    useEffect(() => {
        if (editRatingId) {
            const editRatingItem = ratings?.find((x) => x.id === editRatingId);
            if (editRatingItem) {
                setValueRating(editRatingItem);
                dispatch(storeEditRatingId(null));
            }
        }
    }, [editRatingId]);

    /**
     * Обработка выбора сортировки
     */
    const onChangeSorting = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
        setValueRating((prevState) => ({
            ...prevState,
            sort_method: String(option.key),
        }));
        setSelected(option.key);
    }, []);

    /**
     * Обработка выбора метрики
     */
    const handleMetricSelectValue = (selects: TSelectedOptions) => {
        setValueRating((prevState) => ({
            ...prevState,
            metric: String(selects[0].id),
        }));
    };

    /**
     * Обработка выбора основного индикатора
     */
    const handleMainIndicatorSelectValue = (selects: TSelectedOptions) => {
        setValueRating((prevState) => ({
            ...prevState,
            main_column: String(selects[0].id),
        }));
    };

    /**
     * Обработка ввода названия рейтинга
     */
    const handleSetName = (data: { value: string }) => {
        setValueRating({
            ...rating,
            name: data.value,
        });
    };

    /**
     * Обработка выбора типа объектов
     */
    const handleSetObjectType = (selects: TSelectedOptions) => {
        setValueRating((prevState) => ({
            ...prevState,
            dataobj_type: String(selects[0].id),
        }));
    };

    /**
     * Обработка выбора дополнительных индикаторов
     */
    const handleSecondIndicatorsValue = (checkboxs: ICheckboxItem[]) => {
        setValueRating((prevState) => ({
            ...prevState,
            additional_columns: checkboxs,
        }));
    };

    const buttonClick = () => {
        if (rating.dataobj_type === '') {
            dispatch(storeNewRating({ ...rating, dataobj_type: String(objectsTypes[0].id) }));
        } else {
            dispatch(storeNewRating(rating));
        }

        closeModal();

        setValueRating(_.cloneDeep(DEFAULT_RATING_SETTING));
    };

    useEffect(() => {
        if (rating.sort_method) {
            setSelected(rating.sort_method);
        }
    }, [rating.sort_method]);

    const modalForm = useMemo(() => {
        return (
            <GridTemplate>
                <Column>
                    <FormItem>
                        <Label>{t("Rating's title")}</Label>
                        <TextInput
                            handleChange={handleSetName}
                            id="ratingName"
                            name="ratingName"
                            hasError={rating.name === '' ? true : false}
                            outsideValue={rating.name}
                        />
                    </FormItem>
                    <SelectMetric handleSelectValue={handleMetricSelectValue} selectedId={rating.metric} />

                    <SelectMainIndicator
                        handleSelectValue={handleMainIndicatorSelectValue}
                        selectedId={rating.main_column}
                    />
                    <Panel title={t('Sorting')}>
                        <ChoiceGroup selectedKey={selected} options={optionsSort} onChange={onChangeSorting} />
                    </Panel>
                </Column>
                <Column>
                    <SelectObjectType handleSelectValue={handleSetObjectType} selectedId={rating.dataobj_type} />
                    <Panel title={t('Show additional')}>
                        <CheckboxGroupObjectType
                            defaultRatingIndicators={rating.additional_columns}
                            mainIndicator={rating.main_column}
                            handleCheckboxsValues={handleSecondIndicatorsValue}
                        />
                    </Panel>
                </Column>
            </GridTemplate>
        );
    }, [rating, t, buttonClick]);

    return (
        <ModalNewRatingWrapper mainWindowSize={mainWindowSize}>
            {modalForm}
            <ButtonWrapper>
                {rating.id !== 0 ? (
                    <Button onClick={buttonClick}>{t('Save')}</Button>
                ) : (
                    <Button disabled={rating.name === '' ? true : false} onClick={buttonClick}>
                        {t('Add rating')}
                    </Button>
                )}
            </ButtonWrapper>
        </ModalNewRatingWrapper>
    );
};
export default ModalNewRating;
