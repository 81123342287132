import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { increaseDownloadSignalCounter, Reports_Intersections_Widget_Reducer_Values } from './reducer';
import SaveXLSXFileButton from '../../../../../SaveXLSXFileButton/SaveXLSXFileButton';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import { Button } from '../../../../../../../lib/esm/components';
import { Footer, TableWrapper, WidgetWrapper } from './styles';
import useGridGenerator from './hooks/useGridGenerator';
import { useDispatch, useSelector } from 'react-redux';
import usePrepareData from './hooks/usePrepareData';
import { useTranslation } from 'react-i18next';
import ReactDataSheet from 'react-datasheet';
import React, { useMemo } from 'react';
import { generalReducerValues } from '../../../../../../../General.reducer';

/**
 * Компонент для отображения виджета среднедневынх пересечений
 */
const Widget: React.FC = ({}) => {
    const { grid, rawAverageIntersectionsData } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Daily intersections');
    const { selectedLocationId, locations, currentModule } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useGridGenerator();
    usePrepareData();

    const locationName = locations?.find((item) => item.id === selectedLocationId)?.name;

    const onDownloadClick = () => {
        dispatch(increaseDownloadSignalCounter());
    };

    const table = useMemo(() => {
        if (rawAverageIntersectionsData) {
            const WithLoadingTable = withLoading(TableWrapper, { height: 400, data: rawAverageIntersectionsData });

            return (
                <WithLoadingTable>
                    {grid && <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />}
                </WithLoadingTable>
            );
        }
        return null;
    }, [rawAverageIntersectionsData, grid]);

    return (
        <WidgetWrapper>
            {table}
            <Footer>
                <Button
                    text={t('Download')}
                    disabled={
                        !localCurrentOptions?.['compareObjects']?.length ||
                        !localCurrentOptions?.selectedReportingObjectsIds?.length
                    }
                    onClick={onDownloadClick}
                />
                <SaveXLSXFileButton
                    bookName={`${locationName} daily intersections report`}
                    grid={grid || []}
                    disabled={!!!grid}
                    currentModule={currentModule}
                />
            </Footer>
        </WidgetWrapper>
    );
};

export default Widget;
