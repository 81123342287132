import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Performance_Ratings_RatingList_Widget_Reducer_Values,
    resetRatingsReducer,
    storeRatings,
    storeRatingsData,
    storeRatingsDataRecived,
    storeResponseResult,
} from '../reducer';
import { useTranslation } from 'react-i18next';

import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import _ from 'lodash';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { usePrepareData } from './usePrepareData';
import { t } from 'i18next';
import { getReportingObjects } from '../../../../../../../../tools/getReportingObjects';
import { IRating } from '../interfaces';

export const useFetchData = () => {
    usePrepareData();
    const fetchData = useRequestMetrics();
    const {
        token,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { ratings, ratingsDataRecived, ratingsData, responseResult } = useSelector(
        Performance_Ratings_RatingList_Widget_Reducer_Values,
    );
    const [periods, setPeriods] = useState('');
    const [ratingSwicher, setRatingSwicher] = useState<{ mainMetric: string; mainObjects: string }[] | null>(null);
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Ratings');
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const dispatch = useDispatch();

    /**
     * Создает массив значений по которым стоит делать запрос при изменении рейтинга
     */
    useEffect(() => {
        const newRatingSwicher = ratings?.map((item) => {
            return {
                mainMetric: item.metric,
                mainObjects: item.dataobj_type,
                locationId: localCurrentOptions?.selectedLocationId,
            };
        });

        if (localCurrentOptions?.selectedLocationId) {
            if (newRatingSwicher && newRatingSwicher.length !== 0) {
                setRatingSwicher(newRatingSwicher);
            }
        }
    }, [localCurrentOptions?.selectedLocationId, ratings]);

    /**
     * Создает зависимость от редактирования периодов
     */
    useEffect(() => {
        /**
         * Создает зависимость от редактирования периодов
         */
        if (localCurrentOptions?.comparePeriods && localCurrentOptions?.mainPeriod) {
            dispatch(storeResponseResult(null));
            dispatch(storeRatingsDataRecived([]));
            dispatch(storeRatingsData([]));

            setPeriods(
                JSON.stringify([
                    localCurrentOptions?.comparePeriods,
                    localCurrentOptions?.mainPeriod,
                    localCurrentOptions?.selectedLocationId,
                ]),
            );
        }
    }, [localCurrentOptions?.comparePeriods, localCurrentOptions?.mainPeriod]);

    /**
     * Запрос рейтингов
     */
    useEffect(() => {
        
        if (!localCurrentOptions) return;
        const controller = new AbortController();
        const signal = controller.signal;

        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const [mainDateRange] = localCurrentOptions.mainDateRanges?.filter((item) => item.id === mainPeriod);
        const mainTimeRange = [mainDateRange?.period?.dateFrom, mainDateRange?.period?.dateTo];
        const comparePeriod = localCurrentOptions.comparePeriods;
        let allObjectsIdRating: number[] = [];
        if (ratings && ratings.length && comparePeriod) {
            const filtered = ratings?.filter((item) => !ratingsDataRecived?.includes(item.id));
            const [compareDateRange] = localCurrentOptions.compareDateRanges?.filter(
                (item) => item.id === comparePeriod[0].id,
            );
            const compareTimeRange = [compareDateRange.period.dateFrom, compareDateRange.period.dateTo];

            const requestParams = {
                signal,
                token,
                metric_level: metricLevel,
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };
            const filteredRatingsData = _.cloneDeep(ratingsData);
            _.forEach(filtered, function (item) {
                _.remove(filteredRatingsData, function (n) {
                    return n.id === item.id;
                });
            });
            dispatch(storeRatingsData(filteredRatingsData));
            _.forEach(filtered, function (item) {
                const objectsIds = _.map(reportingObjectsByType[item.dataobj_type], 'id');
                allObjectsIdRating.push(...objectsIds);
                const mainMetric = item.metric;
                const metricMainRequest = {
                    ...requestParams,
                    time_range: mainTimeRange,
                    obj_ids: objectsIds,
                    metric: mainMetric,
                    alias: String(item.id) + ':MainPeriod',
                };
                const metricCompareRequest = {
                    ...requestParams,
                    time_range: compareTimeRange,
                    obj_ids: objectsIds,
                    metric: mainMetric,
                    alias: String(item.id) + ':ComparePeriod',
                };
                const areaRequest = {
                    ...requestParams,
                    time_range: mainTimeRange,
                    obj_ids: objectsIds,
                    metric: 'sum_area',
                    alias: String(item.id) + ':Area',
                };

                fetchData([metricMainRequest, metricCompareRequest, areaRequest])
                    .then((res: TServerResponse) => {
                        handleServerResponse({
                            responseAnalyzer: responseAnalyzer,
                            success: storeResponseResult,
                            error: storeResponseResult,
                            dispatch,
                            res,
                        });
                    })
                    .catch((error: any) => {
                        console.log(`Ratings usegetMetrics error: ${error}`);
                    });
            });
        }
        return () => {
            controller.abort();
        };
    }, [JSON.stringify(ratingSwicher), periods]);

    /**
     * Запись рейтинга из localStorage в редусер
     */
    // useEffect(() => {
    //     dispatch(resetRatingsReducer());
    //     if (getReportingObjects(reportingObjectsByType, t).length !== 0) {
    //         const storage = localStorage.getItem('ratings');
    //         if (storage) {
    //             const storageParse: IRating[] = JSON.parse(storage);
    //             dispatch(storeRatings(prepareRating(storageParse)));
    //         }
    //     }
    // }, [JSON.stringify(reportingObjectsByType)]);

    /**
     *
     * @param ratingList
     * @returns - массив рейтингов, без рейтингов типы объектов которых отсутствуют в текущей локации
     */
    const prepareRating = (ratingList: IRating[]) => {
        const objectsByType = getReportingObjects(reportingObjectsByType, t).map((item) => item.key);
        const storageResult = ratingList?.filter((x) => {
            return _.indexOf(objectsByType, x.dataobj_type) !== -1;
        });
        if (storageResult.length === 0) {
            return null;
        } else {
            return storageResult;
        }
    };
};
