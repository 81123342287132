import { isNumber } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useParallelFetching from '../../../../../../../../tools/API/useParallelFetching/useParallelFetching';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { IPreparedData } from '../interfaces';
import { Leasing_TenantOverview_Summary_Widget_Reducer_Values, storePreparedData } from '../reducer';

/**
 * Кастомный хук для подготовки данных
 */
export const usePrepareData = () => {
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { dataRefetchObject } = useSelector(Leasing_TenantOverview_Summary_Widget_Reducer_Values);
    const dispatch = useDispatch();

    const tenantsIds = useMemo(() => {
        if (localCurrentOptions?.['tenants'].length) {
            return localCurrentOptions['tenants'].map((element: { id: number }) => element.id);
        }
        return [];
    }, [localCurrentOptions?.['tenants']]);

    const { rawData } = useParallelFetching({
        reportingObjectsIds: tenantsIds,
        dataRefetchObject,
        moduleName,
    });

    /** Подготовка сырых данных */
    useEffect(() => {
        if (rawData) {
            const result: IPreparedData = Object.keys(rawData).reduce((acc, key) => {
                const splitedKey = key.split(':');
                const value = rawData[key];

                const reportingObjectId = splitedKey[0];
                const metricId = splitedKey[1];
                const periodType = splitedKey[2];
                const periodId = splitedKey[3];

                if (!acc.hasOwnProperty(reportingObjectId)) {
                    acc[reportingObjectId] = {
                        reportingObject: value.reportingObject,
                    };
                }
                if (!acc[reportingObjectId].hasOwnProperty('metrics')) {
                    acc[reportingObjectId].metrics = {};
                }

                if (!acc[reportingObjectId].metrics.hasOwnProperty(metricId)) {
                    acc[reportingObjectId].metrics[metricId] = {};
                }
                if (!acc[reportingObjectId].metrics[metricId].hasOwnProperty(periodType)) {
                    acc[reportingObjectId].metrics[metricId][periodType] = {};
                }

                acc[reportingObjectId].metrics[metricId][periodType][periodId] = {
                    isFetching: value.isFetching,
                    isError: value.isError,
                    period: value.period,
                    data: value.data,
                    percentageOfMain: null,
                };

                return acc;
            }, {});

            Object.keys(result).forEach((id) => {
                const data = result[id];

                Object.keys(data.metrics).forEach((metricId) => {
                    const metricValue = data.metrics[metricId];
                    const mainPeriodId = Object.keys(metricValue.mainPeriod)[0];
                    const mainPeriodValue: number | undefined | null =
                        metricValue.mainPeriod[mainPeriodId].data.items?.[0]?.value;

                    Object.keys(metricValue.comparePeriod).forEach((comparePeriodId) => {
                        let comparePeriodValue: number | undefined | null =
                            metricValue.comparePeriod[comparePeriodId].data.items?.[0]?.value;

                        if (isNumber(mainPeriodValue) && isNumber(comparePeriodValue)) {
                            const percentageOfMain = (mainPeriodValue / comparePeriodValue - 1) * 100;

                            if (
                                isNumber(percentageOfMain) &&
                                !isNaN(percentageOfMain) &&
                                percentageOfMain !== Infinity
                            ) {
                                result[id].metrics[metricId].comparePeriod[comparePeriodId].percentageOfMain =
                                    percentageOfMain;
                            } else {
                                result[id].metrics[metricId].comparePeriod[comparePeriodId].percentageOfMain = 0;
                            }
                        }
                    });
                });
            });

            dispatch(storePreparedData(result));
        }
    }, [rawData]);
};
