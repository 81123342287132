import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../../../lib/esm/components';
import { EventsMapWidgetContext } from '../../../../context';
import { ButtonsWrapper, ContentWrapper, Wrapper } from './styles/styles';
import './styles/styles.scss';

interface IProps {
    /** Закрытие модалки с подтверджением удаления */
    closeModal: () => void;
    /** Id события */
    eventId?: number;
}

/**
 * Компонент для подтверждения удаления события
 */
const DeleteConfirmModalBody: FC<IProps> = ({ eventId, closeModal }) => {
    const context = useContext(EventsMapWidgetContext);
    const { t } = useTranslation();

    const onDelete = () => {
        if (eventId && context) {
            context.storeEventIdToDeleteTrans(eventId);
            closeModal();
            context.closeEventModal();
        }
    };

    return (
        <Wrapper>
            <ContentWrapper>
                <div>{t('You are going to delete the event. Continue?')}</div>
            </ContentWrapper>
            <ButtonsWrapper>
                <Button onClick={closeModal} addClasses={'margin-left'} theme="outline">
                    {t('Cancel')}
                </Button>
                <Button disabled={!!!eventId} onClick={onDelete} addClasses={'margin-left'}>
                    {t('Ok')}
                </Button>
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default DeleteConfirmModalBody;
