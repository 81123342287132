import { IGenerateSeriesDataArgs, IGenerateSeriesDataResponse, TSeriesType, ISeriesItem } from './interfaces';
import { chartTickOptionsByDetail } from '../../../../../../../../constants/detailOptions';
import { MAIN_PERIOD_ALIAS } from '../constants/constants';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

/**
 * Функция для генерации данных для серии и категорий графика
 * @param selectedChartDetailing Детализация данных
 * @param selectedChartType Выбранный режим графика
 * @param responseIndex Индекс запроса (нужно для изщменения типа серии)
 * @param metricsData иноформации о метрике
 * @param allMetrics Все метрики
 * @param xValues Все значения по оси x (ВАЖНО,чтобы первые дпнные metricsData были основным переиодом, иначе массив занчений xValues будет пуст!!)
 * @returns Объект с массивом серий и категорий
 */
const generateSeriesData = (args: IGenerateSeriesDataArgs): IGenerateSeriesDataResponse => {
    const { selectedChartDetailing, responseIndex, metricsData, allMetrics, xValues, mallName, t } = args;
    const series: TSeriesType[] = [];
    const categories: string[] = [];
    let showSeriesName = true;
    let multipleAxes = false;
    let visible = true;
    let subTitle = '';
    let title = '';

    const myXValues: number[] = cloneDeep(xValues);

    metricsData.forEach((metric, metricIndex) => {
        let data: ISeriesItem[] = [];
        let name = '';
        let color = '#4C7EFF';
        let type: 'spline' | 'areaspline' | 'column' = 'spline';

        if (metric.context.alias?.split(':')?.[0] === 'mall' && !xValues.length) {
            name = `${t('mall')}: ${mallName}`;
            data = metric.items.map((item) => {
                myXValues.push(DateTime.fromISO(item.time).toMillis());

                return {
                    units: '%',
                    y: item.value,
                    name: item.time,
                    x: DateTime.fromISO(item.time).toMillis(),
                    weekday: DateTime.fromISO(item.time).weekday,
                };
            });
        } else {
            name = 'Mall Index';
            color = 'red';
            data = metric.items.map((item, index) => {
                return {
                    units: '%',
                    y: item.value,
                    name: item.time,
                    x: DateTime.fromISO(item.time).toMillis(),
                    weekday: DateTime.fromISO(item.time).weekday,
                };
            });
        }

        // subTitle = allMetrics?.find((element) => element.id === metric.context.metric)?.text || metric.context.metric;
        type = 'spline';
        title = t('Dynamics');

        if (data.length === 1) type = 'column';

        const seriesData: TSeriesType = {
            yAxis: 0,
            id: `${responseIndex + metricIndex + Math.random()}`,
            isMain: false,
            visible,
            type,
            color,
            name,
            data,
        };

        series.push(seriesData);
    });

    return { series, categories, title, subTitle, showSeriesName, multipleAxes, xValues: myXValues };
};

export default generateSeriesData;
