import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';

interface IProps {
    /** Текст лейбла */
    text: string;
}

/**
 * Компонент для отображения лейбла колонки
 */
const ColumnLabel: FC<IProps> = ({ text }) => {
    const { t } = useTranslation();

    return <Wrapper>{t(text)}</Wrapper>;
};

export default ColumnLabel;
