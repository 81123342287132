import { IModuleConfig } from '../General.interfaces';
import { CABINET_SERVICE_NAME, CURRENT_HOST } from './urls';

export const modulesConfig: IModuleConfig[] = [
    {
        module_name: 'Events:Events map',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/events/events-map',
        },
        icons: {
            icon: 'overview',
            fluent_icon: 'CalendarSettings',
        },
        permissions: ['app/client | modules/events/events-map | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [],
        },
    },
    {
        module_name: 'Events:Events',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/events/events',
        },
        icons: {
            icon: 'overview',
            fluent_icon: 'SpecialEvent',
        },
        permissions: ['app/client | modules/events/events | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods', 'selectedMetrics', 'selectedReportingObjectsIds', 'filters'],
        },
    },
    // {
    //     module_name: 'Events:Events efficiency',
    //     service: {
    //         name: CABINET_SERVICE_NAME,
    //         host: CURRENT_HOST,
    //         path: '/events/events-efficiency',
    //     },
    //     icons: {
    //         icon: 'overview',
    //         fluent_icon: 'CalculatorPercentage',
    //     },
    //     permissions: ['app/client | modules/events/events-efficiency | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byM: [],
    //         byL: [],
    //         byML: [],
    //     },
    // },
    {
        module_name: 'Performance:Dashboard',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/performance/dashboard',
        },
        icons: {
            icon: 'overview',
        },
        permissions: ['app/client | modules/performance/dashboard | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods', 'selectedMetrics', 'selectedReportingObjectsIds', 'detail'],
        },
    },
    {
        module_name: 'Performance:Year over year',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/performance/year-over-year',
        },
        icons: {
            icon: 'retrospective-analysis',
        },
        permissions: ['app/client | modules/performance/year-over-year | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['selectedMetrics', 'selectedReportingObjectsIds', 'yoyYearsSettings', 'yoyTableSettings'],
        },
    },
    {
        module_name: 'Performance:Visitors inside',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/performance/visitors-inside',
        },
        icons: {
            icon: 'real-time',
        },
        permissions: ['app/client | modules/performance/visitors-inside | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods', 'selectedReportingObjectsIds'],
        },
    },
    {
        module_name: 'Performance:Ratings',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/performance/ratings',
        },
        icons: {
            icon: 'ratings',
        },
        permissions: ['app/client | modules/performance/ratings | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods'],
        },
    },
    {
        module_name: 'Performance:Path to purchase',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/performance/path-to-purchase',
        },
        icons: {
            icon: 'funnel',
        },
        permissions: ['app/client | modules/performance/path-to-purchase | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'selectedReportingObjectsIds',
                'pathToPurchaseFunnelStepsSettings',
                'pathToPurchaseFunnelSettings',
                'pathToPurchaseGroupsOfZonesSettings',
            ],
        },
    },
    {
        module_name: 'Maps:Maps overview',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/maps/maps-overview',
        },
        icons: {
            icon: 'metrics',
            fluent_icon: 'Nav2DMapView',
        },
        permissions: ['app/client | modules/maps/maps-overview | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods', 'selectedMetrics', 'mapsSettings'],
        },
    },
    {
        module_name: 'Maps:Cross-visits',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/maps/cross-visits',
        },
        icons: {
            icon: 'metrics',
            fluent_icon: 'MicrosoftFlowLogo',
        },
        permissions: ['app/client | modules/maps/cross-visits | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods', 'crossVisitsMapsSettings'],
        },
    },
    {
        module_name: 'Sales:Payment analysis',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/sales/payment-analysis',
        },
        icons: {
            icon: 'metrics',
            fluent_icon: 'PaymentCard',
        },
        permissions: ['app/client | modules/sales/payment-analysis | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'detail',
                'mainPeriod',
                'comparePeriods',
                'paymentAnalysisTenantSelect',
                'paymentAnalysisMetricsSelect',
                'subsetsOfSalesMetricsSelect',
                'filters',
            ],
        },
    },
    {
        module_name: 'Work cases:Benchmarks',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/cases/benchmarks',
        },
        icons: {
            icon: 'metrics',
            fluent_icon: 'AreaChart',
        },
        permissions: ['app/client | modules/cases/benchmarks | feature/view'],
        version: '𝝰',
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'detail',
                'selectedReportingObjectsIds',
                'geoOptions',
                'mallTypeOptions',
                'myDetail',
                'category',
                'tenant',
            ],
        },
    },
    {
        module_name: 'Work cases:Tenant discount calculator',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/cases/calculator',
        },
        icons: {
            icon: 'metrics',
            fluent_icon: 'Calculator',
        },
        version: '𝝰',
        permissions: ['app/client | modules/cases/calculator | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'comparePeriods',
                'indexingDate',
                'rentInMonth',
                'indexPercent',
                'receivables',
                'myMetrics',
                'myDetail',
                'discount',
                'discountPeriod',
                'tenant',
                'discountUnits',
                'referenceGroup:Henderson',
                'referenceGroup:Burger King',
            ],
        },
    },
    {
        module_name: 'Leasing:Tenant overview',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/leasing/tenant-overview',
        },
        icons: {
            icon: 'tenant-analysis',
        },
        permissions: ['app/client | modules/leasing/tenant-overview | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'tenants',
                'selectedComparePeriod',
                'otherTenants',
                'filters',
            ],
        },
    },
    {
        module_name: 'Leasing:Tenants',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/leasing/tenants',
        },
        icons: {
            icon: 'tenants',
        },
        permissions: ['app/client | modules/leasing/tenants | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'comparePeriods', 'selectedMetrics', 'filters'],
        },
    },
    {
        module_name: 'Leasing:Cross-visits',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/leasing/cross-visits',
        },
        icons: {
            icon: 'cross-visitation',
        },
        permissions: ['app/client | modules/leasing/cross-visits | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: ['mainPeriod', 'selectedReportingObjectsIds', 'leasingCrossVisitsREportingObjectType', 'filters'],
        },
    },
    {
        module_name: 'Reports:Basic',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/reports/basic',
        },
        icons: {
            icon: 'faq',
            fluent_icon: 'ReportLibrary',
        },
        permissions: ['app/client | modules/reports/basic | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'selectedReportingObjectsIds',
                'selectedMetrics',
                'detail',
                'basicReportAdditonalFields',
                'pageLayout',
            ],
        },
    },
    {
        module_name: 'Reports:With comparison',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/reports/with-comparison',
        },
        icons: {
            icon: 'faq',
            fluent_icon: 'ReportDocument',
        },
        permissions: ['app/client | modules/reports/with-comparison | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'comparePeriods',
                'selectedReportingObjectsIds',
                'selectedMetrics',
                'detail',
                'compariosnReportAdditonalFields',
                'pageLayout',
            ],
        },
    },
    {
        module_name: 'Reports:Daily intersections',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/reports/daily-intersections',
        },
        icons: {
            icon: 'faq',
            fluent_icon: 'Remote',
        },
        permissions: ['app/client | modules/reports/daily-intersections | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'selectedReportingObjectsIds',
                'compareObjects',
                'intersectionsReportAdditionalFields',
                'pageLayout',
            ],
        },
    },
    {
        module_name: 'Reports:By tenants',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/reports/by-tenants',
        },
        icons: {
            icon: 'faq',
            fluent_icon: 'Group',
        },
        permissions: ['app/client | modules/reports/by-tenants | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [
                'mainPeriod',
                'selectedReportingObjectsIds',
                'selectedMetrics',
                'detail',
                'tenantsReportAdditionalFileds',
                'pageLayout',
            ],
        },
    },
    {
        module_name: 'Configuration:Categories',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/configuration/categories',
        },
        icons: {
            icon: 'import-metrics',
        },
        permissions: ['app/client | modules/configuration/categories | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [],
        },
    },
    {
        module_name: 'Configuration:Events',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/configuration/events',
        },
        icons: {
            icon: 'import-metrics',
        },
        permissions: ['app/client | modules/configuration/events | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [],
        },
    },
    {
        module_name: 'Help:FAQ',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/helps/faq',
        },
        icons: {
            icon: 'faq',
        },
        permissions: ['app/client | modules/helps/faq | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [],
        },
    },
    {
        module_name: 'Help:Metrics',
        service: {
            name: CABINET_SERVICE_NAME,
            host: CURRENT_HOST,
            path: '/helps/metrics',
        },
        icons: {
            icon: 'metrics',
        },
        permissions: ['app/client | modules/helps/metrics | feature/view'],
        store_method: {
            global: ['pl'],
            byM: [],
            byL: [],
            byML: [],
        },
    },
    // {
    //     module_name: 'none:Import metrics',
    //     service: {
    //         name: CABINET_SERVICE_NAME,
    //         host: CURRENT_HOST,
    //         path: '/import-metrics',
    //     },
    //     icons: {
    //         icon: 'import-metrics',
    //     },
    //     permissions: ['app/client | modules/import-metric | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byM: [],
    //         byL: [],
    //         byML: [],
    //     },
    // },
];

export const getStoreMethod = (moduleName: string, selectName: string) => {
    const module = modulesConfig?.find((item) => item.module_name === moduleName);
    let result = '';
    if (module) {
        Object.keys(module.store_method).forEach((key) => {
            if (module.store_method[key].includes(selectName)) {
                result = key;
            }
        });
    }
    return result;
};
