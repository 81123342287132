import { FC, useCallback, useEffect } from 'react';

import { IProps } from './interfcaes';
import { StyledContextualMenu } from './styles';

/**
 * Комопнент для отображения кастомного контекстного меню
 * @param isContextualMenuOpen флга, отвечающий за показ контекстного меню
 * @param eventBubblingEnabled Автоматическое закрытие контекстного меню, если таковых несколько
 * @param CustomMenuComponent Компонент, который отобразить
 * @param hideContextualMenu функция для закрытия контекстного меню
 * @param directionalHint Позиционирование контекстного меню
 * @param targetRef ссылка на элемент, около которого отобразить элеамент
 * @param overflow Css свойство для отображения контента вне блока
 * @param gapSpace Растояние от элемента от которого нужно отобразить
 */
const ContextualMenu: FC<IProps> = ({
    eventBubblingEnabled = false,
    isContextualMenuOpen,
    CustomMenuComponent,
    overflow = 'hidden',
    hideContextualMenu,
    directionalHint,
    gapSpace = 0,
    targetRef,
}) => {
    const onHideContextualMenu = useCallback(() => hideContextualMenu(), [hideContextualMenu]);

    useEffect(() => {
        const listener = () => onHideContextualMenu();

        setTimeout(() => document.addEventListener('contextmenu', listener), 300);

        return () => document.removeEventListener('contextmenu', listener);
    }, [isContextualMenuOpen]);

    const onRenderMenuList = () => {
        const Component = () => CustomMenuComponent;
        return <Component />;
    };
    return (
        <StyledContextualMenu
            calloutProps={{ styles: { calloutMain: { overflow } }, layerProps: { eventBubblingEnabled } }}
            onRenderMenuList={onRenderMenuList}
            directionalHint={directionalHint}
            onDismiss={onHideContextualMenu}
            hidden={!isContextualMenuOpen}
            items={[{ key: 'blank' }]}
            gapSpace={gapSpace}
            target={targetRef}
            styles={{
                root: {
                    minWidth: '50px',
                },
            }}
        />
    );
};

export default ContextualMenu;
