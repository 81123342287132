import { createContext } from 'react';
import { IReportingObject } from '../../../../../../../General.interfaces';
import { IEventType } from '../../../../Events/EventsMap/widgets/EventsMapWidget/interfaces';
import { TColumnsKeys } from './components/Table/constants/constants';
import { IChangedData, IContextMenuTarget, IFiltersByCellKey, ITableCellData, TFiltersMode } from './interfaces';

export interface IContext {
    updateCellValueTrans: (data: { frontId: string; key: TColumnsKeys; value: any }) => void;
    addDeletedRowToChangedDataTrans: (data: { frontId: string }) => void;
    tableData: ITableCellData[][];
    eventTypes: IEventType[];
    reportingObjectsById: {
        [x: string]: IReportingObject;
    };
    changedData: IChangedData;
    onCloneClickTrans: (frontId: string) => void;
    storeContextMenuTragetTrans: (data: null | IContextMenuTarget) => void;
    contextMenuTarget: null | IContextMenuTarget;
    resetFiltersTrans: () => void;
    addNewFilterTrans: (data: { filter: string; key: string; mode?: TFiltersMode }) => void;
    filtersByCellKey: IFiltersByCellKey;
    eventTypesById: {
        [id: string]: IEventType;
    };
}

export const EventsManagementContext = createContext<null | IContext>(null);
