import {
    IdsOfFunnelSettings,
    PATH_TO_PURCHASE_FUNNEL_SETTINGS,
    PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS,
    PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS,
} from '../../../constants/constants';
import {
    Performance_PathToPurchase_Widget_Reducer_Values,
    storeChartOptionsGeneratorSettings,
    storeExtendedReportingObjects,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { IFunnelStepData } from '../interfaces';
import { cloneDeep } from 'lodash';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateData from '../tools/generateData';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';

/** Касстомный хук для подготовки данных */
const usePrepareData = () => {
    const { rawMetricsData, extendedReportingObjects } = useSelector(Performance_PathToPurchase_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Path to purchase');
    const {
        cfg: { reportingObjectsById, reportingObjectsByTypeAndMarker },
        structures,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_SETTINGS] && extendedReportingObjects) {
            let result = null;
            if (
                localCurrentOptions[PATH_TO_PURCHASE_FUNNEL_SETTINGS]?.[0]?.id ===
                IdsOfFunnelSettings.PercentageOfThePreviousStep
            ) {
                const REVERSED_MALL_INDEX = 4;
                result = extendedReportingObjects.map((element) => {
                    const newData = cloneDeep(element);
                    const reversedKeys = Object.keys(element).reverse();
                    reversedKeys.forEach((key, index) => {
                        const value: IFunnelStepData = cloneDeep(element[key]);
                        if (value && index < REVERSED_MALL_INDEX) {
                            let previousStepIndex = index + 1;
                            while (!element[reversedKeys[previousStepIndex]]) {
                                previousStepIndex++;
                            }
                            const previousStep: IFunnelStepData = element[reversedKeys[previousStepIndex]];
                            value.percentage = (value.metricValue * 100) / (previousStep.metricValue || 1);
                            newData[key] = value;
                        }
                    });
                    return newData;
                });
            } else {
                result = extendedReportingObjects.map((element) => {
                    const newData = cloneDeep(element);
                    const keys = Object.keys(element);
                    keys.forEach((key) => {
                        const location = element.location;
                        const value: IFunnelStepData = cloneDeep(element[key]);

                        if (value && location) {
                            value.percentage = (value.metricValue * 100) / (location.metricValue || 1);
                            newData[key] = value;
                        }
                    });
                    return newData;
                });
            }

            dispatch(storeExtendedReportingObjects(result));
        }
    }, [localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_SETTINGS], extendedReportingObjects?.length]);

    /** Сохранение настроек для построеня графика и расширенныйх отчетных объектов */
    useEffect(() => {
        if (
            localCurrentOptions?.[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS]?.[0]?.id &&
            localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS] &&
            Object.keys(reportingObjectsByTypeAndMarker).length &&
            localCurrentOptions?.selectedReportingObjectsIds &&
            Object.keys(reportingObjectsById).length &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            Array.isArray(rawMetricsData) &&
            rawMetricsData.length &&
            structures
        ) {
            const result = generateData({
                selectedGroupMarker: localCurrentOptions[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS][0].id,
                selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
                funnelSteps: localCurrentOptions[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS],
                tenant2ZoneRelations: structures['relations_tenant2zone'] || [],
                msDataObjects: structures['elements_ms_data_objects'] || [],
                mainDateRanges: localCurrentOptions.mainDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                rawMetricsData: rawMetricsData[0],
                reportingObjectsByTypeAndMarker,
                reportingObjectsById,
                t,
            });
            dispatch(storeChartOptionsGeneratorSettings(result.chartOptionsGeneratorSettings));
            dispatch(storeExtendedReportingObjects(result.extendedReportingObjects));
        }
    }, [
        localCurrentOptions?.[PATH_TO_PURCHASE_GROUPS_OF_ZONES_SETTINGS],
        localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS],
        reportingObjectsByTypeAndMarker,
        reportingObjectsById,
        rawMetricsData,
        structures,
        t,
    ]);
};

export default usePrepareData;
