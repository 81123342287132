import { ISheetCell } from '../../../../../../../../General.interfaces';
import {
    ITenantsReportAdditionalFieldOption,
    AdditionalFieldsIds,
} from '../../../constants/tenantsReportAdditionalFields';

interface IGenerateGridRowArgs {
    additionalFields: ITenantsReportAdditionalFieldOption[];
    metricsData: {
        [metricName: string]: string;
    };
    isFieldReadOnly: boolean;
    showWeekDay: boolean;
    mainPeriod: string;
    tenantName: string;
    placeName: string;
    tenantId: string;
    zoneName: string;
    timeZone: string;
    weekday: string;
    floor: string;
    area: string;
}

/**
 * Функция для генерации строки таблицы
 * @param additionalFields Дополнительные поля таблицы
 * @param isFieldReadOnly Колонка readOnly или нет
 * @param metricsData Данные метрик
 * @param showWeekDay Флаг, отвечающий за отображение дней денеди
 * @param tenantName Название арендатора
 * @param mainPeriod Основной период
 * @param placeName Название помещения
 * @param tenantId ID арендатора
 * @param zoneName Название зоны
 * @param timeZone Тайм зона
 * @param weekday ID арендатора
 * @param floor Номер этажа
 * @param area Площадь помещения
 * @returns Сгенерированную строку для таблицы
 */
const generateGridRow = (args: IGenerateGridRowArgs): ISheetCell[] => {
    const {
        tenantId,
        tenantName,
        area,
        mainPeriod,
        metricsData,
        additionalFields,
        isFieldReadOnly,
        placeName,
        floor,
        zoneName,
        timeZone,
        weekday,
        showWeekDay,
    } = args;

    const additionalFieldsIds = additionalFields.map((element) => element.id);

    const result = [
        { value: tenantId, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.Id },
        { value: tenantName, readOnly: isFieldReadOnly },
        { value: placeName, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.Place },
        { value: area, readOnly: isFieldReadOnly },
        { value: floor, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.Floor },
        { value: zoneName, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.Zone },
        { value: mainPeriod, readOnly: isFieldReadOnly },
        { value: weekday, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.WeekDay },
        { value: timeZone, readOnly: isFieldReadOnly, fieldId: AdditionalFieldsIds.TimeZone },
        ...Object.keys(metricsData).map((metric) => ({ value: metricsData[metric], readOnly: isFieldReadOnly })),
    ];

    Object.keys(AdditionalFieldsIds).forEach((key) => {
        if (!additionalFieldsIds.includes(key)) {
            const index = result.findIndex((element) => element.fieldId === key);
            if (index > -1) result.splice(index, 1);
        }
    });

    if (!showWeekDay) {
        const index = result.findIndex((element) => element.fieldId === AdditionalFieldsIds.WeekDay);

        if (index > -1) result.splice(index, 1);
    }

    return result;
};

export default generateGridRow;
