import { TLoadingData, TMetricResponse } from '../../../../../../../../General.interfaces';

import { IAggregatedObject } from './interfaces';
import { IExtendedReportingObject } from '../interfaces';
import { cloneDeep } from 'lodash';

/**
 * Функция для агрегации метрик по датам и отчетным объектм
 * @param responses Ответы от функции получения метрик
 * @returns Объект где кде ключ это ID отчетного объекта, а значение это
 * объект, где ключом является дата, за которую была получена метркиа
 */
const aggregateMetricsResponse = (
    rawMetricsData: TLoadingData,
    extendedReportingObjectsById: {
        [reportingObjectId: string]: IExtendedReportingObject;
    },
    shoudUpdateAllReportingObjects: boolean,
): IAggregatedObject => {
    if (Array.isArray(rawMetricsData)) {
        return rawMetricsData.reduce((acc: IAggregatedObject, value: TMetricResponse) => {
            value.forEach((element) => {
                const objectId: number = element.context.data_objects[0].id;
                const timeRange = element.context.time_range;
                if (!acc[objectId]) acc[objectId] = [{ [`${timeRange[0]}`]: element.items }];
                else acc[objectId].push({ [`${timeRange[0]}`]: element.items });
            });

            return acc;
        }, {});
    } else {
        return Object.keys(extendedReportingObjectsById).reduce((acc, value) => {
            const reportingObject = cloneDeep(extendedReportingObjectsById[value]);
            if (shoudUpdateAllReportingObjects || reportingObject.rawMetricsByDate['status']) {
                acc[value] = rawMetricsData;
            }
            return acc;
        }, {});
    }
};

export default aggregateMetricsResponse;
