import { IObject, TSelectedOptions } from '../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues, storeUniversalOptions } from '../../UserSettings/reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../lib/esm/components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, memo } from 'react';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { getSettings } from '../../../../tools/getSettings';
import { getStoreMethod } from '../../../../constants/modulesConfig';
import { useWidgetCurrentOptions } from '../../../../tools/useWidgetCurrentOptions';

/**
 * Компонент универсального селетка
 */
const UniversalSelect: React.FC<IProps> = ({
    iconType = 'chevron-down',
    defaultSelected,
    mode = 'multi',
    dropdownAlign = 'left',
    labelText = ' ',
    dropdownWidth,
    outsideText,
    showFilter = false,
    options,
    text,
    minSelected,
    maxSelected,
    name,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { locations, selectedLocationId, currentModule } = useSelector(generalReducerValues);
    const { settings } = useSelector(userSettingsReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModule);

    useEffect(() => {
        if (defaultSelected && selectedLocationId && !localCurrentOptions?.[name]) {
            dispatch(
                storeUniversalOptions({
                    selectedOptions: localCurrentOptions?.[name] || defaultSelected,
                    locationId: selectedLocationId,
                    module: currentModule,
                    name,
                }),
            );
        }
    }, [currentModule, localCurrentOptions?.[name], name, selectedLocationId, JSON.stringify(defaultSelected)]);

    useEffect(() => {
        const storeMethod = getStoreMethod(currentModule, name);
        const selectSettings = getSettings(settings, selectedLocationId, currentModule, name, storeMethod);

        if (!selectSettings && options?.[0] && selectedLocationId && mode === 'single') {
            dispatch(
                storeUniversalOptions({
                    selectedOptions: [options?.[0]],
                    locationId: selectedLocationId,
                    module: currentModule,
                    name,
                }),
            );
        }
    }, [currentModule, dispatch, mode, name, options, selectedLocationId, settings]);

    const handleSelect = (args: TSelectedOptions) => {
        const argsNoTranslate = args.map((item) => {
            const initial = options?.find((obj) => obj.id === item.id);
            const text = initial?.text;
            const section = initial?.section;
            return { ...item, text, section };
        });

        selectedLocationId &&
            dispatch(
                storeUniversalOptions({
                    locationId: selectedLocationId,
                    module: currentModule,
                    selectedOptions: argsNoTranslate,
                    name,
                }),
            );
    };

    return (
        <Wrapper>
            <Select
                outsideSelected={
                    (localCurrentOptions?.[name] as TSelectedOptions)?.map((element) => ({
                        ...element,
                        text: t(element.text || ''),
                    })) || []
                }
                disabled={locations.length === 0}
                options={
                    options?.map((element) => ({
                        ...element,
                        text: t(element.text || ''),
                    })) as IObject[]
                }
                dropdownWidth={dropdownWidth}
                handleSelect={handleSelect}
                maxSelected={maxSelected}
                minSelected={minSelected}
                labelText={t(labelText)}
                dropdownAlign={dropdownAlign}
                outsideText={outsideText}
                iconType={iconType}
                showFilter={showFilter}
                mode={mode}
                text={text ? t(text) : t('Selected')}
            />
        </Wrapper>
    );
};

export default UniversalSelect;
