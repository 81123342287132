import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { paymentAnalysisMetricsSelect, paymentAnalysisTenantSelect } from '../../../constants/contsants';
import generatePaymentAnalysisRequests from '../tools/generatePaymentAnalysisRequests';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../reducer';
import { Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values, storeRawMetricsData } from '../reducer';
import usePrepareData from './usePrepareData';
import { TTimeFreq } from '../../../../../../../../lib/esm/components';

/**
 * Кастомный хук для получения данных
 */
export const useGetRawData = () => {
    const { selectedChartType, dataRefetchObject } = useSelector(
        Sales_PaymentAnalysis_StructureDynamics_Widget_Reducer_Values,
    );
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { token } = useSelector(generalReducerValues);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    usePrepareData();

    /** Получение сырых данных */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions?.[paymentAnalysisMetricsSelect] &&
            localCurrentOptions?.[paymentAnalysisTenantSelect] &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            localCurrentOptions?.comparePeriods &&
            localCurrentOptions?.detail?.id &&
            token &&
            selectedChartType
        ) {
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: 'Loading...' }));

            const requestArgs = generatePaymentAnalysisRequests({
                reportingObjectId: localCurrentOptions[paymentAnalysisTenantSelect][0].id,
                metricId: localCurrentOptions[paymentAnalysisMetricsSelect][0].id,
                periodDateRanges: localCurrentOptions.mainDateRanges,
                compareDateRanges: localCurrentOptions.compareDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                comparePeriod: localCurrentOptions?.comparePeriods[0],
                time_freq: localCurrentOptions.detail.id as TTimeFreq,
                metricLevel: metricLevel,
                token,
                subsetOfSalesMetricsOptions: selectedChartType,
            }).map((element) => ({ ...element, signal }));

            fetchData(requestArgs).then((res: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    dispatch,
                    res,
                });
            });
        }

        return () => controller.abort();
    }, [
        localCurrentOptions?.[paymentAnalysisMetricsSelect],
        localCurrentOptions?.[paymentAnalysisTenantSelect],
        localCurrentOptions?.mainPeriod,
        localCurrentOptions?.detail?.id,
        selectedChartType,
        dataRefetchObject,
        token,
    ]);
};
