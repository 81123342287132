import { PivotItem } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Pivot from '../../../../../Pivot/Pivot';
import { TSelectedWidgetKey } from '../../interfaces';
import { Configuration_EventsConfig_Module_Redcuer_Values, storeSelectedWidgetKey } from '../../reducer';
import { pivotOptions } from './constants/constants';

/**
 * Компонент для отображения навигации по виджетам
 */
const WidgetsNavigation = () => {
    const { selectedWidgetKey } = useSelector(Configuration_EventsConfig_Module_Redcuer_Values);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onLinkClick = (item?: PivotItem | undefined) => {
        item?.props.itemKey && dispatch(storeSelectedWidgetKey(item?.props.itemKey as TSelectedWidgetKey));
    };

    return (
        <Pivot selectedKey={selectedWidgetKey} onLinkClick={onLinkClick}>
            {pivotOptions.map((element) => (
                <PivotItem headerText={t(element.headerText)} itemKey={element.itemKey} key={element.itemKey} />
            ))}
        </Pivot>
    );
};

export default WidgetsNavigation;
