import styled from 'styled-components/macro';
import { getColorFromPercent } from '../../../../../../../../../../../tools/getColorFromPercent';

export const Td = styled.td`
    border: 1px solid #ececec;
    padding: 10px;
`;

export const MetricValue = styled.div`
    font-weight: 500;
`;

export const PercentageValue = styled(MetricValue)<{ value: number | string | null | undefined }>`
    color: ${(p) => getColorFromPercent(p.value)};
`;
