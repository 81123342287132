import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../../tools/API/constants';
import { IObjectsRatingData, TRawObjectsRatingData } from '../../interfaces';

import { Performance_Dashboard_ByArea_Widget_Reducer_Values } from '../../reducer';
import RatingItem from '../Rating/Rating';
import { RatingsWrapper } from './styles';

const ObjectsRatings: React.FC = () => {
    const { objectsRatingData, selectedObjectId, rawObjectsRatingData } = useSelector(
        Performance_Dashboard_ByArea_Widget_Reducer_Values,
    );
    const { cfg } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const RATING_TYPES = ['Growing', 'Falling'];

    const list = (
        trend: string,
        objectsRatingData: IObjectsRatingData[] | null,
        rawObjectsRatingData: TRawObjectsRatingData,
    ) => {
        if (Array.isArray(objectsRatingData)) {
            const list = objectsRatingData
                ?.filter((item) => Number(item.colorValue) !== Infinity)
                ?.filter((item) => {
                    if (trend === 'Growing') {
                        return Number(item.colorValue) >= 0;
                    } else {
                        return Number(item.colorValue) < 0;
                    }
                })
                .sort((a, b) => Math.abs(Number(b.colorValue)) - Math.abs(Number(a.colorValue)));
            if (list.length) {
                return list;
            } else {
                return null;
            }
        }
        return rawObjectsRatingData?.['status'] || ResponseStatus.Loading;
    };

    const ratings = useMemo(() => {
        if (selectedObjectId && Object.keys(cfg.reportingObjectsById).length) {
            return RATING_TYPES.map((type) => {
                const selectedObjectName = cfg.reportingObjectsById?.[selectedObjectId]?.name;
                const data = list(type, objectsRatingData, rawObjectsRatingData);
                return (
                    <RatingItem
                        key={selectedObjectName + data + type}
                        data={data}
                        title={`${t(type)} ${t('metrics')} "${selectedObjectName}"`}
                    />
                );
            });
        }

        return null;
    }, [selectedObjectId, rawObjectsRatingData, objectsRatingData, cfg.reportingObjectsById]);

    return <RatingsWrapper>{ratings}</RatingsWrapper>;
};

export default ObjectsRatings;
