import { IDateRange, IPeriod } from '../General.interfaces';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';
import { stringDate } from './Strings/stringDate';
import { TLang } from '../cabinet/components/SideBar/configurations';

export const generateCompareDateRanges = (
    mainDateRanges: IDateRange[],
    comparePeriods: IPeriod[],
    mainPeriod: IPeriod,
    t: TFunction,
    lang: TLang,
) => {
    const mPeriod = mainDateRanges?.find((item) => item.id === mainPeriod?.id) as IDateRange;

    if (!mPeriod?.id) return [];

    const getPrevious = (mainPeriod: IDateRange) => {
        let daysNumber = 1;
        if (mainPeriod.period.dateTo !== mainPeriod.period.dateFrom) {
            daysNumber =
                DateTime.fromISO(mainPeriod.period.dateTo).diff(DateTime.fromISO(mainPeriod.period.dateFrom), ['days'])
                    .days + 1;
        }
        return {
            dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ days: daysNumber }).toISODate(),
            dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ days: daysNumber }).toISODate(),
        };
    };

    const getSameWeekDays = (mainPeriod: IDateRange) => {
        switch (mainPeriod.id) {
            case 'today':
            case 'yesterday':
            case 'currentWeek':
            case 'previousWeek':
                return {
                    dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ weeks: 1 }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ weeks: 1 }).toISODate(),
                };
            case 'currentMonth':
            case 'currentYear':
            case 'selectCustom':
                const start = DateTime.fromISO(mainPeriod.period.dateFrom);
                const end = DateTime.fromISO(mainPeriod.period.dateTo);
                /**
                 * Вычисляем сколько недель между двумя датами
                 * Округляем в большую сторону
                 */
                let weeksToShift = Math.ceil(end.diff(start, 'weeks').toObject().weeks!);
                if (weeksToShift === 0) {
                    weeksToShift = 1;
                }

                return {
                    dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ weeks: weeksToShift }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ weeks: weeksToShift }).toISODate(),
                };
            case 'previousMonth':
                return {
                    dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ months: 1 }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ months: 1 }).endOf('month').toISODate(),
                };

            default:
                return {
                    dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ weeks: 1 }).toISODate(),
                    dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ weeks: 1 }).toISODate(),
                };
        }
    };

    const getSameMonthDays = (mainPeriod: IDateRange) => {
        const from = DateTime.fromISO(mainPeriod.period.dateFrom);
        const to = DateTime.fromISO(mainPeriod.period.dateTo);
        let monthsNumber = 1;
        if (mainPeriod.period.dateTo !== mainPeriod.period.dateFrom) {
            monthsNumber =
                DateTime.fromISO(mainPeriod.period.dateTo)
                    .startOf('month')
                    .diff(DateTime.fromISO(mainPeriod.period.dateFrom).startOf('month'), ['months']).months + 1;
        }
        const firstDay = to.startOf('month').minus({ months: monthsNumber }).startOf('month').day;
        const lastDay = to.startOf('month').minus({ months: monthsNumber }).endOf('month').day;
        const days = lastDay - firstDay;
        let dateTo = to.startOf('month').minus({ months: monthsNumber }).set({ day: to.day });

        if (to.day > days || (to.day === to.endOf('month').day && from.day === from.startOf('month').day)) {
            dateTo = to.startOf('month').minus({ months: monthsNumber }).endOf('month');
        }

        return {
            dateFrom: from.startOf('month').minus({ months: monthsNumber }).set({ day: from.day }).toISODate(),
            dateTo: dateTo.toISODate(),
        };
    };

    const getAligned = (mainPeriod: IDateRange) => {
        return {
            dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ weeks: 52 }).toISODate(),
            dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ weeks: 52 }).toISODate(),
        };
    };

    const getYearOverYear = (mainPeriod: IDateRange) => {
        return {
            dateFrom: DateTime.fromISO(mainPeriod.period.dateFrom).minus({ years: 1 }).toISODate(),
            dateTo: DateTime.fromISO(mainPeriod.period.dateTo).minus({ years: 1 }).toISODate(),
        };
    };

    const getCustom = (comparePeriods?: IPeriod[]) => {
        const customPeriod = comparePeriods?.find((item) => item.id === 'selectCustom');
        if (customPeriod?.period) {
            return customPeriod?.period;
        } else {
            return null;
        }
    };

    const getStringPeriod = (period: { dateFrom: string; dateTo: string } | null) => {
        if (!period) return '';
        if (period.dateFrom === period.dateTo) {
            return `${stringDate(period.dateFrom, lang)}`;
        } else {
            return `${stringDate(period.dateFrom, lang)} – ${stringDate(period.dateTo, lang)}`;
        }
    };

    let options = [
        {
            id: 'previous',
            text: `${t('The same days number before')}
            (${getStringPeriod(getPrevious(mPeriod))})`,
            period: getPrevious(mPeriod),
        },
        {
            id: 'sameWeekdays',
            text: `${t('The same weekdays before')}
            (${getStringPeriod(getSameWeekDays(mPeriod))})`,
            period: getSameWeekDays(mPeriod),
        },
        {
            id: 'sameMonthdays',
            text: `${t('The same monthdays before')}
            (${getStringPeriod(getSameMonthDays(mPeriod))})`,
            period: getSameMonthDays(mPeriod),
        },
        {
            id: 'alignedYoY',
            text: `${t('Aligned Year-Over-Year')}
            (${getStringPeriod(getAligned(mPeriod))})`,
            period: getAligned(mPeriod),
        },
        {
            id: 'yoy',
            text: `${t('Year-Over-Year')}
            (${getStringPeriod(getYearOverYear(mPeriod))})`,
            period: getYearOverYear(mPeriod),
        },
        {
            id: 'selectCustom',
            text: `${t('Select custom')}
            ${getCustom(comparePeriods) ? ` (${getStringPeriod(getCustom(comparePeriods))})` : ''}`,
            period: getCustom(comparePeriods),
        },
    ] as IDateRange[];

    return options;
};
