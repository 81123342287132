import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import cn from 'classnames';
import * as constants from '../../constants/constants.js';
import { DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_SEPARATOR_ELEMENT_PREFIX } from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';

/**
 * Компонент для отображения элемента списка
 */
var ListItem = function (_a) {
    var _b = _a.listItemPosition, listItemPosition = _b === void 0 ? 'center' : _b, _c = _a.size, size = _c === void 0 ? 'md' : _c, _d = _a.listItemGrid, listItemGrid = _d === void 0 ? 'default-grid' : _d, _e = _a.theme, theme = _e === void 0 ? 'default-theme' : _e, _f = _a.listItemTextTruncate, listItemTextTruncate = _f === void 0 ? false : _f, children = _a.children, props = __rest(_a, ["listItemPosition", "size", "listItemGrid", "theme", "listItemTextTruncate", "children"]);
    var mergedStyles = useStyles({ props: props, constants: constants });
    var itemClassName = useMemo(function () {
        var _a;
        return cn.apply(void 0, __spreadArray([DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX, "".concat(DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(size), "".concat(DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(theme), "".concat(DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(listItemGrid), (_a = {},
                _a["".concat(DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX, "text-truncate")] = listItemTextTruncate,
                _a)], mergedStyles, false));
    }, [size, listItemGrid, theme, listItemTextTruncate, mergedStyles]);
    var slotClassName = useMemo(function () {
        return cn("".concat(DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX).concat(DEFAULT_SEPARATOR_ELEMENT_PREFIX, "slot"), "".concat(DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX).concat(DEFAULT_SEPARATOR_ELEMENT_PREFIX, "slot--position-").concat(listItemPosition));
    }, [listItemPosition]);
    var style = { display: children ? 'block' : 'none', marginLeft: 0 };
    return (jsx("div", __assign({ className: itemClassName }, { children: jsx("div", __assign({ className: slotClassName, style: style }, { children: jsx("span", { children: children }, void 0) }), void 0) }), void 0));
};

export { ListItem as default };
