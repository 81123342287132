import { IPeriod } from '../../../../../../../../General.interfaces';
import { IRequestMetricsArgs } from '../../../../../../../../lib/esm/components';
import { TMetricLevel } from '../../../../../../CabinetPreferences/interfaces';
import { subsetsOfSalesMetricsOptions } from '../../../constants/contsants';

export interface IArgs {
    compareDateRanges: IPeriod[];
    mainDateRanges: IPeriod[];
    metricLevel: TMetricLevel;
    selectedSubsetId: string;
    selectedMetricId: string;
    comparePeriod: IPeriod;
    mainPeriod: IPeriod;
    tenantsIds: number[];
    token: string;
}

/**
 * Функция для генерации аргументов для функции запроса метрик
 */
const generateQueryData = (args: IArgs): IRequestMetricsArgs[] => {
    const {
        compareDateRanges,
        selectedSubsetId,
        selectedMetricId,
        mainDateRanges,
        comparePeriod,
        metricLevel,
        mainPeriod,
        tenantsIds,
        token,
    } = args;

    const currentSubset = subsetsOfSalesMetricsOptions?.find((element) => element.id === selectedSubsetId);

    const comparePeriodData = compareDateRanges?.find((element) => element.id === comparePeriod.id)?.period;
    const mainPeriodData = mainDateRanges?.find((element) => element.id === mainPeriod.id)?.period;

    const result: IRequestMetricsArgs[] = [];

    if (currentSubset && mainPeriodData && comparePeriodData) {
        currentSubset.subtypes.forEach((element) => {
            result.push({
                time_range: [mainPeriodData.dateFrom, mainPeriodData.dateTo],
                alias: `sales:${currentSubset.id}:${element.id}:main`,
                metric_params: element.metricParams,
                object_aggregation: false,
                metric_level: metricLevel,
                metric: selectedMetricId,
                obj_ids: tenantsIds,
                time_freq: null,
                token,
            });

            result.push({
                time_range: [comparePeriodData.dateFrom, comparePeriodData.dateTo],
                alias: `sales:${currentSubset.id}:${element.id}:compare`,
                metric_params: element.metricParams,
                object_aggregation: false,
                metric_level: metricLevel,
                metric: selectedMetricId,
                obj_ids: tenantsIds,
                time_freq: null,
                token,
            });
        });
    }
    return result;
};

export default generateQueryData;
