import useImage from 'use-image';
import hatchingLines from '../../../../../assets/images/maps/hatchingLines.png';

/** Хук для получения картинки с линиями штриховки */
const useHatchingLines = () => {
    const [image] = useImage(hatchingLines);
    return image;
};

export default useHatchingLines;
