import { ISelectedOption } from '../../../lib/esm/components/Select/configurations';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPresetReducerState } from './interfaces';
import { RootState } from '../../../store';

const initialState: IPresetReducerState = {
    selectedPreset: null,
    availablePresets: {},
};

export const PresetReducer = createSlice({
    name: 'PresetReducer',
    initialState,
    reducers: {
        /**
         * Запись выбранного пресета
         */
        storeSelectedPreset: (state, action: PayloadAction<ISelectedOption | null>) => {
            state.selectedPreset = action.payload;
        },

        /**
         * Запись доступных пресетов
         */
        storeAvailablePresets: (state, action) => {
            state.availablePresets = action.payload;
        },

        /**
         * Обнуление редьюсера
         */
        resetPresetReducer: () => initialState,
    },
});

export const { storeSelectedPreset, storeAvailablePresets, resetPresetReducer } = PresetReducer.actions;

export const PresetReducerValues = (state: RootState) => state.PresetReducer;

export default PresetReducer.reducer;
