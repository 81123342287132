/**
 * Список допустимых цветов
 */
var Size;
(function (Size) {
    /**
     * Пустое значение
     */
    Size["Empty"] = "";
    /**
     * Самое маленькое закругление
     */
    Size["Xs"] = "xs";
    /**
     * Маленькое закругление(по умолчанию)
     */
    Size["Sm"] = "sm";
    /**
     * Среднее закругление
     */
    Size["Md"] = "md";
    /**
     * Большое закругление
     */
    Size["Lg"] = "lg";
    /**
     * Очень большое закругление
     */
    Size["Xl"] = "xl";
    /**
     * Закругление равно 0
     */
    Size["Unset"] = "unset";
    /**
     * Закругление равно 50%
     */
    Size["Circle"] = "circle";
})(Size || (Size = {}));

export { Size };
