import React from 'react';
import cn from 'classnames';
import * as constants from '../../../../constants/constants';

import { IItemProps } from '../../../../configurations';
import '../../../../styles/styles.scss';
import { Icon as FluentIcon } from '@fluentui/react/lib/Icon';
import Switcher from '../../../Switcher';
import SwitcherStub from '../../../SwitcherStub';
import SwitcherContent from '../../../SwitcherContent';
import { VersionWrapper } from '../../../../styles/styles';
import { Icon, ListItem } from '../../../../../../../lib/esm/components';
import { IIcons } from '../../../../../../../lib/esm/components/Icon/configurations/iconsList';

const Item: React.FC<IItemProps> = ({ subsection, onClick, active, compact, lang, ...props }) => {
    const { DEFAULT_CLASS_SIDEBAR_LINK, DEFAULT_CLASS_LINK, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;
    const sidebarLinkCN = cn({
        [DEFAULT_CLASS_SIDEBAR_LINK]: true,
        [DEFAULT_CLASS_LINK]: true,
        [`${DEFAULT_CLASS_LINK}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}active`]: active,
    });

    return subsection ? (
        <ListItem
            size="xs"
            theme="default-theme"
            listItemGrid="default-grid"
            listItemTextTruncate={true}
            addClasses={'sidebar-list-item'}
            hover="background-none"
        >
            <div
                onClick={onClick}
                className={sidebarLinkCN}
                // href={subsection.url}
            >
                <Switcher compact={compact}>
                    <SwitcherStub>
                        {subsection.fluentIcon ? (
                            <FluentIcon iconName={subsection.fluentIcon} />
                        ) : (
                            <Icon type={subsection.icon as IIcons} size="xs" />
                        )}
                    </SwitcherStub>
                    {!compact && (
                        <SwitcherContent>
                            <span className="navigation-item__text">{subsection.names[lang]}</span>
                        </SwitcherContent>
                    )}
                    {!compact && subsection.version && (
                        <VersionWrapper>
                            <span className="navigation-item__text">{subsection.version}</span>
                        </VersionWrapper>
                    )}
                </Switcher>
            </div>
        </ListItem>
    ) : null;
};

export default Item;
