import { numberWithSpaces } from '../../../../../../../../../../../tools/Strings/numberWithSpaces';
import { IReportingObject } from '../../../../../../../../../../../General.interfaces';
import { colors } from '../../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { cloneDeep } from 'lodash';
import {
    BodyIntersectionWrapper,
    RowReportingObjectName,
    BodyDefaultText,
    PaiwiseValue,
    PercentValue,
    StyledIcon,
    BodyTd,
    BodyTr,
} from './styles';
import { FC } from 'react';

/**
 * Компонент для отображения тела таблицы
 * @param storeSelectedReportingObjectsTrans Функция для записи выбранных отчетных объектов
 * @param extendedReportingObjectsById Объект с расширенными выбранными отчетными объектми
 * @param selectedReportingObjects выбранные отчетные объекты
 * @param sortedReportingObjects Отсортированные отчетные объекты
 * @param intersectionsDataById Данные по пересечениям
 * @param isLoading Флаг загрузки
 * @param isError Флаг ошибки
 * @param days количество дней в выбранном периоде
 */
const Body: FC<IProps> = ({
    storeSelectedReportingObjectsTrans,
    extendedReportingObjectsById,
    selectedReportingObjects,
    sortedReportingObjects,
    intersectionsDataById,
    isLoading,
    isError,
    days,
}) => {
    const { t } = useTranslation();

    const onRowClick = (reportingObject: IReportingObject) => () => {
        const copy = cloneDeep(selectedReportingObjects);
        const index = copy.findIndex((element) => element.reportingObject.id === reportingObject.id);

        if (index > -1) {
            copy.splice(index, 1);
            storeSelectedReportingObjectsTrans(copy);
        } else {
            const allColors = colors.map((element) => element.selectedColor);
            const selectedColors = copy.map((element) => element.color);
            const availableColors = allColors?.filter((color) => !selectedColors.includes(color));
            if (availableColors.length) {
                copy.push({ reportingObject, color: availableColors[0] });
                storeSelectedReportingObjectsTrans(copy);
            }
        }
    };

    return (
        <tbody>
            {sortedReportingObjects.map((reportingObject) => {
                const selectedColor =
                    selectedReportingObjects?.find(
                        (selectedObject) => selectedObject.reportingObject.id === reportingObject.id,
                    )?.color || null;

                return (
                    <BodyTr
                        onClick={onRowClick(reportingObject)}
                        selectedColor={selectedColor}
                        key={reportingObject.id}
                    >
                        <BodyTd>
                            <RowReportingObjectName>
                                <div>{reportingObject.name}</div>
                                {extendedReportingObjectsById[reportingObject.id] && (
                                    <StyledIcon
                                        color={extendedReportingObjectsById[reportingObject.id].color}
                                        iconName={'FavoriteStarFill'}
                                    />
                                )}
                            </RowReportingObjectName>
                        </BodyTd>
                        <BodyTd>
                            {isLoading ? (
                                <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                            ) : isError ? (
                                <BodyDefaultText>{t('Some error')}</BodyDefaultText>
                            ) : (
                                <BodyIntersectionWrapper>
                                    <PercentValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].percentIntersection !== null
                                            ? numberWithSpaces(
                                                  (
                                                      intersectionsDataById[reportingObject.id].percentIntersection! /
                                                      days
                                                  ).toFixed(2),
                                              ) + '%'
                                            : '—'}
                                    </PercentValue>
                                    <PaiwiseValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].intersection !== null
                                            ? numberWithSpaces(
                                                  String(
                                                      (
                                                          intersectionsDataById[reportingObject.id].intersection! / days
                                                      ).toFixed(0),
                                                  ),
                                              )
                                            : '—'}
                                    </PaiwiseValue>
                                </BodyIntersectionWrapper>
                            )}
                        </BodyTd>
                        <BodyTd>
                            {isLoading ? (
                                <BodyDefaultText>{t('Loading...')}</BodyDefaultText>
                            ) : isError ? (
                                <BodyDefaultText>{t('Some error')}</BodyDefaultText>
                            ) : (
                                <BodyIntersectionWrapper>
                                    <PercentValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].percentIntersection !== null
                                            ? numberWithSpaces(
                                                  intersectionsDataById[
                                                      reportingObject.id
                                                  ].percentIntersection!.toFixed(2),
                                              ) + '%'
                                            : '—'}
                                    </PercentValue>
                                    <PaiwiseValue>
                                        {intersectionsDataById &&
                                        intersectionsDataById[reportingObject.id] &&
                                        intersectionsDataById[reportingObject.id].intersection !== null
                                            ? numberWithSpaces(
                                                  String(
                                                      intersectionsDataById[reportingObject.id].intersection!.toFixed(
                                                          0,
                                                      ),
                                                  ),
                                              )
                                            : '—'}
                                    </PaiwiseValue>
                                </BodyIntersectionWrapper>
                            )}
                        </BodyTd>
                    </BodyTr>
                );
            })}
        </tbody>
    );
};

export default Body;
