import { DateTime } from 'luxon';
import { TLang } from '../../cabinet/components/SideBar/configurations';

const ss = (isoDate: string | undefined | null, lang: TLang, preset: string, token?: string) => {
    if (isoDate === null) {
        return 'Now';
    }
    if (token) {
        return DateTime.fromISO(isoDate || '')
            .setLocale(lang)
            .toFormat(token);
    }
    return DateTime.fromISO(isoDate || '')
        .setLocale(lang)
        .toLocaleString(DateTime[preset]);
};

const isObjectRange = (args: any) => {
    if (!args) return false;
    return (
        args.hasOwnProperty('dateFrom') &&
        args.hasOwnProperty('dateTo') &&
        typeof args['dateFrom'] === 'string' &&
        typeof args['dateTo'] === 'string'
    );
};

export function stringDate(
    range: string | { dateFrom: string | null; dateTo: string | null } | (string | null)[] | undefined,
    lang: TLang,
    preset: string = 'DATE_MED',
    token?: string,
): string {
    if (isObjectRange(range)) {
        if (range && typeof range === 'object' && !Array.isArray(range) && range?.dateFrom !== range.dateTo) {
            return `${ss(range.dateFrom, lang, preset, token)} – ${ss(range.dateTo, lang, preset, token)}`;
        } else if (range && typeof range === 'object' && !Array.isArray(range) && range?.dateFrom === range.dateTo) {
            return `${ss(range.dateFrom, lang, preset, token)}`;
        } else return '';
    } else if (typeof range === 'string') {
        return ss(range, lang, preset, token);
    } else if (Array.isArray(range)) {
        if (range[0] !== range[1]) {
            return `${ss(range[0], lang, preset, token)} – ${ss(range[1], lang, preset, token)}`;
        } else if (range[0] === range[1]) {
            return `${ss(range[0], lang, preset, token)}`;
        } else return '';
    } else return '';
}
