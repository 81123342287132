import { DateTime } from 'luxon';
import { TSeries } from '../../interfaces';
import { cloneDeep } from 'lodash';

interface IArgs {
    series: TSeries[];
}

const genegatePlotBandsForWeekends = (args: IArgs) => {
    const SATURDAY_DAY_NUMBER = 7;
    const FRIDAY_DAY_NUMBER = 5;
    const MONDAY_DAY_NUMBER = 1;
    const plotBands: Highcharts.AxisPlotBandsOptions[] = [];
    const plotBand: Highcharts.AxisPlotBandsOptions = {
        color: 'rgba(255, 167, 167, 0.3)',
    };
    let temporaryPlotBand = cloneDeep(plotBand);
    const { series } = args;

    const allXValues: number[] = series.reduce((acc: number[], value) => {
        const values = value.data.map((element) => element.x);
        return acc.concat(values);
    }, []);

    const filteredXValues = allXValues?.filter((element, index) => allXValues.indexOf(element) === index).sort();

    filteredXValues.forEach((x) => {
        const pointWeekday = DateTime.fromMillis(x).weekday;
        if (pointWeekday) {
            if (pointWeekday === FRIDAY_DAY_NUMBER) {
                temporaryPlotBand.from = x;
            }
            if (pointWeekday === SATURDAY_DAY_NUMBER) {
                temporaryPlotBand.to = x;
            }
            if (pointWeekday === MONDAY_DAY_NUMBER) {
                temporaryPlotBand = cloneDeep(plotBand);
            }
        }
        if (temporaryPlotBand.to && !temporaryPlotBand.from) {
            temporaryPlotBand.from = filteredXValues[0];
        }
        if (temporaryPlotBand.from && !temporaryPlotBand.to) {
            temporaryPlotBand.to = filteredXValues[filteredXValues.length - 1];
        }
        plotBands.push(temporaryPlotBand);
    });

    return plotBands?.filter((v, i, a) => a.findIndex((t) => t.to === v.to && t.from === v.from) === i);
};

export default genegatePlotBandsForWeekends;
