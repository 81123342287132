import { Container, ModuleWrapper } from './styles';
import EventsMapWidget from './widgets/EventsMapWidget/EventsMapWidget';

/**
 * Компонент для отображения карты событий (календаря)
 */
const EventsMap = () => {
    return (
        <ModuleWrapper>
            <Container>
                <EventsMapWidget />
            </Container>
        </ModuleWrapper>
    );
};

export default EventsMap;
