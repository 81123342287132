import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { storePreraredData, Leasing_TenantOverview_Reference_Widget_Reducer_Values } from '../reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { Leasing_TenantOverview_Module_Reducer_Values } from '../../../reducer';
import { filterValidDateRanges } from '../../../../../../../../tools/filterValidDateRanges';
import { ITenant2ZoneRelation, TMetricResponse } from '../../../../../../../../General.interfaces';

export const usePrepareData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        cfg: { reportingObjectsByType, tenant2ZoneByTenantId, tenant2FloorByTenantId, reportingObjectsById },
        src: { projectCategories, dataObj2ProjectCategory },
    } = useSelector(generalReducerValues);
    const { allTenantsData, selectedTab } = useSelector(Leasing_TenantOverview_Reference_Widget_Reducer_Values);
    const { moduleName } = useSelector(Leasing_TenantOverview_Module_Reducer_Values);

    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    useEffect(() => {
        if (!Array.isArray(allTenantsData)) return;

        const selectedTenant = localCurrentOptions?.['tenants']?.[0];

        if (selectedTab === 'otherTenants') {
            const otherTenants = localCurrentOptions?.['otherTenants'];
            const allTenants = [selectedTenant];
            if (Array.isArray(otherTenants)) {
                allTenants.push(...otherTenants);
            }
            const preparedData = generateTableData(allTenants, allTenantsData, '');
            dispatch(storePreraredData(preparedData));
        } else {
            const mainPeriod = localCurrentOptions?.mainDateRanges?.filter(
                (item) => item.id === localCurrentOptions?.mainPeriod?.id,
            )[0].period;

            const zoneOfSelectedTenant = filterValidDateRanges(
                tenant2ZoneByTenantId?.[selectedTenant?.id]?.filter((item) => item.content_percentage >= 99),
                mainPeriod,
            );

            const sameZoneTenants = filterValidDateRanges(
                Object.values(tenant2ZoneByTenantId)
                    .reduce((acc, value) => {
                        const result: ITenant2ZoneRelation[] = [];
                        Array.isArray(value) &&
                            value?.forEach((item) => {
                                if (item.zone_marker === zoneOfSelectedTenant?.[0]?.zone_marker) {
                                    result.push(item);
                                }
                            });
                        return [...acc, ...result];
                    }, [])
                    ?.filter((item) => item.content_percentage >= 99),
                mainPeriod,
            )
                .map((item) => ({ id: item.tenant_id, text: item.tenant_name }))
                ?.filter((item) => item.id !== selectedTenant.id);

            const floorOfSelectedTenant = filterValidDateRanges(
                tenant2FloorByTenantId?.[selectedTenant?.id],
                mainPeriod,
            );

            const sameFloorTenants = filterValidDateRanges(
                Object.values(tenant2ZoneByTenantId).reduce((acc, value) => {
                    const result: ITenant2ZoneRelation[] = [];
                    Array.isArray(value) &&
                        value?.forEach((item) => {
                            if (item.floor === floorOfSelectedTenant?.[0]?.floor) {
                                result.push(item);
                            }
                        });
                    return [...acc, ...result];
                }, []),
                mainPeriod,
            )
                .map((item) => ({ id: item.tenant_id, text: item.tenant_name }))
                ?.filter((item) => item.id !== selectedTenant.id);

            //--------------------------------------

            const selectedTenantCategories = dataObj2ProjectCategory?.filter(
                (item) => item.data_object_id === selectedTenant?.id,
            );

            const preparedCategoryData = selectedTenantCategories.reduce((acc, tenantCategory) => {
                const parentCategoryId = projectCategories?.find(
                    (item) => item.id === tenantCategory.category_id,
                )?.parent_id;
                const parentCategoryName =
                    projectCategories?.find((item) => item.id === parentCategoryId)?.object_name || '';

                const sameCategoryTenants = dataObj2ProjectCategory
                    ?.filter((item) => item.category_id === tenantCategory.category_id)
                    .map((item) => {
                        const text = reportingObjectsById[item.data_object_id].name || '';
                        return { id: item.data_object_id, text };
                    })
                    ?.filter((item) => item.id !== selectedTenant.id);

                const result = generateTableData(sameCategoryTenants, allTenantsData, parentCategoryName);
                result.unshift(generateSummaryRow(sameCategoryTenants, allTenantsData, parentCategoryName));

                return [...acc, ...result];
            }, []);

            //--------------------------------------

            const setectedTenantData = generateTableData([selectedTenant], allTenantsData, 'selectedTenant');

            const preparedZoneData = generateTableData(sameZoneTenants, allTenantsData, 'zone');
            preparedZoneData.unshift(generateSummaryRow(sameZoneTenants, allTenantsData, 'zone'));

            const preparedFloorData = generateTableData(sameFloorTenants, allTenantsData, 'floor');
            preparedFloorData.unshift(generateSummaryRow(sameFloorTenants, allTenantsData, 'floor'));

            dispatch(
                storePreraredData([
                    ...setectedTenantData,
                    ...preparedZoneData,
                    ...preparedFloorData,
                    ...preparedCategoryData,
                ]),
            );
        }
    }, [
        allTenantsData,
        dataObj2ProjectCategory,
        dispatch,
        localCurrentOptions,
        projectCategories,
        selectedTab,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
    ]);

    const generateTableData = (
        tenantsArr: Array<{ id: number; text: string }>,
        allTenantsData: TMetricResponse[],
        alias: string,
    ) => {
        const selectedTenant = localCurrentOptions?.['tenants']?.[0];
        return tenantsArr
            .map((tenant) => {
                if (tenant) {
                    const tenantName = tenant?.text;

                    const metricsData = localCurrentOptions?.selectedMetrics?.map((metric) => {
                        const main = allTenantsData
                            ?.find((item) => item[0]?.context?.alias === `${metric}:main`)
                            ?.find((item) => item.context.data_objects[0]?.id === tenant.id)?.items[0].value;
                        const compare = allTenantsData
                            ?.find((item) => item[0]?.context?.alias === `${metric}:comparison`)
                            ?.find((item) => item.context.data_objects[0]?.id === tenant.id)?.items[0].value;
                        const deviation: string = main && compare ? ((100 * main) / compare).toFixed(2) : '–';
                        return { metric, main, deviation };
                    });

                    const isSelectedTenant = tenant?.id === selectedTenant?.id;
                    return { tenantId: tenant.id, tenantName, metricsData, isSelectedTenant, alias };
                }
                return {};
            })
            .sort((a, b) => Number(b.metricsData?.[0].main) - Number(a.metricsData?.[0].main));
    };

    const generateSummaryRow = (
        tenantsArr: Array<{ id: number; text: string }>,
        allTenantsData: TMetricResponse[],
        alias: string,
    ) => {
        const metricsData = localCurrentOptions?.selectedMetrics?.map((metric) => {
            const main = tenantsArr.reduce((acc, tenant) => {
                const mTraffic =
                    allTenantsData
                        ?.find((item) => item[0].context.alias === `${metric}:main`)
                        ?.find((item) => item.context.data_objects[0].id === tenant.id)?.items[0].value || 0;
                return acc + mTraffic;
            }, 0);

            const compare = tenantsArr.reduce((acc, tenant) => {
                const mTraffic =
                    allTenantsData
                        ?.find((item) => item[0].context.alias === `${metric}:comparison`)
                        ?.find((item) => item.context.data_objects[0].id === tenant.id)?.items[0].value || 0;
                return acc + mTraffic;
            }, 0);

            const deviation: string = main && compare ? ((100 * main) / compare).toFixed(2) : '–';

            return { metric, main, deviation };
        });

        const result = {
            tenantId: 333,
            tenantName: `${t('Tenants of')} ${t(alias)}`,
            isSelectedTenant: false,
            metricsData,
            alias,
            collapseable: true,
        };

        return result;
    };
};
