import { IGenerateQueryDataArgs } from './interfaces';
import { IRequestMetricsArgs } from '../../../../../../../../lib/esm/components';

/**
 * Функция для генерации массива тел запроса (payload) для получения метрик
 * @param shoudUpdateAllReportingObjects Флаг отвечающий за обновление всех отчетных объекто (перезапрос всех данных)
 * @param extendedReportingObjectsById Объект расширенных отчетных объектов
 * @param selectedReportingObjectsIds Массив выбранных ID отчтеных объектов
 * @param selectedMetrics Массив выбранных метрик
 * @param selectedYears Массив выбранных лет
 * @param metricLevel Уровень запроса метрик
 * @param token Токен авторизации
 * @returns Массив тел запроса для получения метрик
 */
const generateQueryData = (args: IGenerateQueryDataArgs): IRequestMetricsArgs[] => {
    const {
        shoudUpdateAllReportingObjects,
        extendedReportingObjectsById,
        selectedReportingObjectsIds,
        selectedMetrics,
        selectedYears,
        metricLevel,
        token,
    } = args;
    const queryData: IRequestMetricsArgs[] = [];

    const reportingObjectsIds = shoudUpdateAllReportingObjects
        ? selectedReportingObjectsIds
        : Object.keys(extendedReportingObjectsById)
              .map((id) => +id)
              ?.filter((element) => !!extendedReportingObjectsById[element].rawMetricsByDate['status']);

    if (reportingObjectsIds.length) {
        selectedYears.forEach((element) => {
            queryData.push({
                time_range: [element.period.dateFrom, element.period.dateTo],
                obj_ids: reportingObjectsIds,
                metric: selectedMetrics[0],
                object_aggregation: false,
                metric_level: metricLevel,
                time_freq: 'MS',
                alias: null,
                token,
            });
        });
    }

    return queryData;
};

export default generateQueryData;
