import { PivotItem } from '@fluentui/react';
import { FC, RefObject, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useRerender } from '../../../../../../../../../tools/useRerender';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import Pivot from '../../../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { subsetsOfSalesMetricsOptions } from '../../../../constants/contsants';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../../reducer';
import { SalesOfTenantsContext } from '../../context';

interface IProps {
    /** Ссылка на контенер виджета */
    wrapperRef: RefObject<null>;
}

/**
 * Компонент для отображения шапки виджета
 */
const Header: FC<IProps> = ({ wrapperRef }) => {
    const context = useContext(SalesOfTenantsContext);
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);

    const rerender = useRerender([
        localCurrentOptions?.mainDateRanges,
        localCurrentOptions?.['filters'],
        localCurrentOptions?.mainPeriod,
        context?.extendedTenantsById,
    ]);
    const { t } = useTranslation();

    const onPivotClick = (item: PivotItem) => {
        if (context && item.props.itemKey) {
            context.storeSelectedSubsetIdTrans(item.props.itemKey);
        }
    };

    const pivotItems = useMemo(() => {
        return subsetsOfSalesMetricsOptions.map((subset) => {
            return {
                headerText: subset.text,
                itemKey: subset.id,
            };
        });
    }, [subsetsOfSalesMetricsOptions]);

    return (
        <WidgetTitleWrapper>
            <WidgetTitle>
                {t('Sales of tenants')}
                <WidgetAdditionalControls
                    pdfDownloadData={{ targetRef: wrapperRef, rerenderKey: rerender }}
                    reloadHandler={context?.reloadWidget}
                    widgetName="Sales of tenants"
                />
            </WidgetTitle>
            <Pivot overflowBehavior="menu" selectedKey={context?.selectedSubsetId} onLinkClick={onPivotClick}>
                {pivotItems.map((element) => (
                    <PivotItem headerText={t(element.headerText)} key={element.itemKey} itemKey={element.itemKey} />
                ))}
            </Pivot>
        </WidgetTitleWrapper>
    );
};

export default Header;
