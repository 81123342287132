import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Select } from '../../../../../../../../../lib/esm/components';
import { TSelectedOptions } from '../../../../../../../../../lib/esm/components/Select/configurations';
import { shortStringDate } from '../../../../../../../../../tools/Strings/shortStringDate';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import {
    storeSelectedGroup,
    storeSelectedZoneId,
    Performance_Dashboard_Traffic_Widget_Reducer_Values,
    storeSelectedFloor,
} from '../../reducer';
import { MainPeriodText, SelectsWrapper } from './styles';

const Selects: React.FC = () => {
    const { t } = useTranslation();

    const {
        currentModule,
        lang,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    const { ratingType, zonesByGroup, selectedGroupId, selectedZoneId, allFloors, selectedFloor } = useSelector(
        Performance_Dashboard_Traffic_Widget_Reducer_Values,
    );

    const localCurrentOptions = useWidgetCurrentOptions(currentModule);

    const handGroupSelect = (args: TSelectedOptions) => {
        const groupId = String(args[0].id);
        dispatch(storeSelectedGroup(groupId));
        const selectedZoneId = zonesByGroup[groupId][0].id;
        dispatch(storeSelectedZoneId(String(selectedZoneId)));
    };

    const handZoneSelect = (args: TSelectedOptions) => {
        const selectedZoneId = args[0].id;
        dispatch(storeSelectedZoneId(String(selectedZoneId)));
    };

    const handFloorSelect = (args: TSelectedOptions) => {
        const selectedFloor = Number(args[0].text);
        dispatch(storeSelectedFloor(selectedFloor as number));
    };
    const mainDates = localCurrentOptions?.mainDateRanges?.filter(
        (item) => item.id === localCurrentOptions?.mainPeriod?.id,
    )[0]?.period;

    const mainPeriodText = mainDates?.dateFrom ? shortStringDate([mainDates?.dateFrom, mainDates?.dateTo], lang) : '';

    const groupsSelect = useMemo(() => {
        if (ratingType !== 'zone') return null;

        const options = Object.keys(zonesByGroup).map((groupMarker) => {
            return { id: groupMarker, text: groupMarker };
        });

        const outsideSelected = options?.filter((item) => item.id === selectedGroupId);

        return (
            <Select
                iconType="chevron-down"
                disabled={ratingType !== 'zone'}
                options={options}
                labelText={t('Select zones group')}
                showFilter={false}
                mode="single"
                margin="r_sm"
                handleSelect={handGroupSelect}
                outsideSelected={outsideSelected}
                text={'null'}
            />
        );
    }, [zonesByGroup, selectedGroupId, ratingType, reportingObjectsByType]);

    const zoneSelect = useMemo(() => {
        if (ratingType !== 'zone') return null;
        const options = selectedGroupId
            ? zonesByGroup[selectedGroupId].map((zone) => ({ id: zone.id, text: zone.name }))
            : [];

        const outsideSelected = options?.filter((item) => String(item.id) === String(selectedZoneId));

        return (
            <Select
                iconType="chevron-down"
                disabled={ratingType !== 'zone'}
                options={options}
                labelText={t('Select zone')}
                showFilter={false}
                mode="single"
                margin="r_sm"
                handleSelect={handZoneSelect}
                outsideSelected={outsideSelected}
                text={'null'}
            />
        );
    }, [zonesByGroup, selectedGroupId, ratingType, selectedZoneId, reportingObjectsByType]);

    const floorSelect = useMemo(() => {
        if (ratingType !== 'floor') return null;
        const options = allFloors ? allFloors.map((floor) => ({ id: floor.id, text: floor.marker })) : [];
        const outsideSelected = options?.filter((item) => String(item.text) === String(selectedFloor));

        return (
            <Select
                iconType="chevron-down"
                disabled={ratingType !== 'floor'}
                options={options}
                labelText={t('Select floor')}
                showFilter={false}
                mode="single"
                margin="r_sm"
                handleSelect={handFloorSelect}
                outsideSelected={outsideSelected}
                text={'null'}
                dropdownWidth={'sm'}
            />
        );
    }, [allFloors, selectedFloor, ratingType, reportingObjectsByType]);

    return (
        <SelectsWrapper>
            <MainPeriodText>{mainPeriodText}</MainPeriodText>
            {groupsSelect}
            {zoneSelect}
            {floorSelect}
        </SelectsWrapper>
    );
};

export default Selects;
