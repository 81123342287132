import { FC, useMemo, useRef, useState } from 'react';
import { TextInput } from '../../../../../../../../../../../lib/esm/components';
import ContextualMenu from '../../../../../../../../../ContextualMenu/ContexttualMenu';
import { generalReducerValues } from '../../../../../../../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { Layer, Wrapper } from './styles';
import ReportingObjectsMenuBody from '../../../../../../../../../ContextualMenu/components/ReportingObjectsMenuBody/ReportingObjectsMenuBody';

interface IProps {
    /** Обработчик выбора отчетных объектов объекта */
    storeSelectedReportingObjectsIds: (ids: number[]) => void;
    /** Выбранный отчетный объект */
    selectedReportingObjectsIds: number[];
    /** Ошибка, если не выбран отчетный объект */
    selectedReportingObjectsIdsError: boolean;
}

/**
 * Компонент для отображения инпута с выбором отчетного объекта для события
 */
const ReportingObjectsSelect: FC<IProps> = ({
    selectedReportingObjectsIdsError,
    selectedReportingObjectsIds,
    storeSelectedReportingObjectsIds,
}) => {
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const ref = useRef(null);

    const toggleContextualMenuOpen = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    const onSelect = (ids: number[]) => {
        setContextualMenuOpen(false);
        storeSelectedReportingObjectsIds(ids);
    };

    const outsideValue = useMemo(() => {
        return selectedReportingObjectsIds.reduce((acc, id, index) => {
            if (index + 1 === selectedReportingObjectsIds.length) {
                acc += `${reportingObjectsById[id].name || id}`;
            } else {
                acc += `${reportingObjectsById[id].name || id}, `;
            }
            return acc;
        }, '');
    }, [reportingObjectsById, selectedReportingObjectsIds]);

    return (
        <Wrapper ref={ref} onClick={toggleContextualMenuOpen}>
            <Layer />
            <TextInput
                handleChange={() => {}}
                isMultiLine={true}
                hasError={selectedReportingObjectsIdsError}
                outsideValue={outsideValue}
                fullWidth={true}
            />
            <ContextualMenu
                CustomMenuComponent={
                    <ReportingObjectsMenuBody
                        selectedReportingObjectsIdsTrans={selectedReportingObjectsIds}
                        onReportingObjectsSelect={onSelect}
                    />
                }
                isContextualMenuOpen={isContextualMenuOpen}
                hideContextualMenu={closeContextualMenu}
                targetRef={ref}
                gapSpace={10}
            />
        </Wrapper>
    );
};

export default ReportingObjectsSelect;
