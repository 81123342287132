import { ChartOptions } from 'highcharts';

export const chartOptions: ChartOptions = {
    animation: { duration: 100 },
    zoomType: 'xy',
    style: {
        fontFamily: 'Roboto',
    },
    spacingLeft: 0,
    spacingRight: 0,

    // events: {
    //     selection: function (event: any): boolean {
    //         const that: any = this;

    //         let zoomedIn: boolean = false;

    //         if (event.xAxis && event.xAxis[0]) {
    //             that.xAxis[0].setExtremes(event.xAxis[0].min, event.xAxis[0].max, false);

    //             zoomedIn = true;
    //         } else {
    //             that.xAxis[0].setExtremes(0, 100, false);
    //         }

    //         if (event.yAxis && event.yAxis[0]) {
    //             that.yAxis[0].setExtremes(100 - event.yAxis[0].max, 100 - event.yAxis[0].min);

    //             zoomedIn = true;
    //         } else {
    //             that.yAxis[0].setExtremes(0, 100);
    //         }

    //         if (zoomedIn) {
    //             that.showResetZoom();
    //         } else {
    //             that.resetZoomButton = that.resetZoomButton.destroy();
    //         }

    //         return false;
    //     }
    // }
};
