import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRerender } from '../../../../../../../tools/useRerender';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import WidgetsNavigation from '../../components/WidgetsNavigation/WidgetsNavigation';

const EventsFileUpload = () => {
    const rerender = useRerender('');
    const { t } = useTranslation();
    const ref = useRef(null);

    return (
        <div ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Events file upload')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={'Events file upload'}
                    />
                </WidgetTitle>
                <WidgetsNavigation />
            </WidgetTitleWrapper>
            <div>File upload</div>
        </div>
    );
};

export default EventsFileUpload;
