import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import { useWidgetCurrentOptions } from '../../useWidgetCurrentOptions';
import {
    IEmptyStructure,
    IGenerateEmptyStructureArgs,
    IResultItemArgs,
    IPrepareEmptyStructureArgs,
} from './interfaces';

/**
 * Кастомный хук для подготовки пустой структуры
 */
const usePrepareEmptyStructure = (args: IPrepareEmptyStructureArgs) => {
    const { setResolvedRequests, setRawData, moduleName, dataRefetchObject, reportingObjectsIds } = args;
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const [mainPeriodCounter, setMainPeriodCounter] = useState(0);
    const [emptyStructure, setEmptyStructure] = useState<IEmptyStructure | null>(null);

    /** Обнуление всех обработанных запросов, при изменение основного периода */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod) {
            setMainPeriodCounter((prevState) => (prevState += 1));
            setResolvedRequests([]);
            setRawData(null);
        }
    }, [localCurrentOptions?.mainPeriod, dataRefetchObject]);

    const calculatedReportingObjectsIds = useMemo(() => {
        if (reportingObjectsIds) return reportingObjectsIds;
        return localCurrentOptions?.selectedReportingObjectsIds;
    }, [localCurrentOptions?.selectedReportingObjectsIds, reportingObjectsIds]);

    /** Получение пустой структуры */
    useEffect(() => {
        if (
            calculatedReportingObjectsIds?.length &&
            Object.values(reportingObjectsById).length &&
            localCurrentOptions?.selectedMetrics &&
            localCurrentOptions?.comparePeriods &&
            localCurrentOptions?.mainPeriod &&
            mainPeriodCounter > 0
        ) {
            const emptyStructure = generateEmptyStructure({
                reportingObjectsById,
                reportingObjectsIds: calculatedReportingObjectsIds,
                selectedMetricsIds: localCurrentOptions.selectedMetrics!,
                comparePeriods: {
                    periodsDateRanges: localCurrentOptions.compareDateRanges,
                    periods: localCurrentOptions.comparePeriods!,
                },
                mainPeriod: {
                    periodDateRanges: localCurrentOptions.mainDateRanges,
                    period: localCurrentOptions.mainPeriod,
                },
            });
            setEmptyStructure(emptyStructure);
        }
    }, [
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.comparePeriods,
        calculatedReportingObjectsIds,
        reportingObjectsById,
        mainPeriodCounter,
    ]);

    /**
     * Функция для генерации структуры сырых данных
     */
    const generateEmptyStructure = (agrs: IGenerateEmptyStructureArgs): IEmptyStructure => {
        const { mainPeriod, comparePeriods, selectedMetricsIds, reportingObjectsIds, reportingObjectsById } = agrs;

        const result = reportingObjectsIds.reduce((acc, reportingObjectId) => {
            const reportingObject = reportingObjectsById[reportingObjectId];
            selectedMetricsIds.forEach((metricId) => {
                const period = mainPeriod?.periodDateRanges?.find(
                    (period) => period.id === mainPeriod.period.id,
                )?.period;

                if (period) {
                    const { key, value } = getResultItem({
                        periodId: mainPeriod.period.id,
                        periodType: 'mainPeriod',
                        reportingObject,
                        reportingObjectId,
                        metricId,
                        period,
                    });
                    acc[key] = value;
                }

                comparePeriods?.periods.forEach((comparePeriod) => {
                    const period = comparePeriods.periodsDateRanges?.find(
                        (period) => period.id === comparePeriod.id,
                    )?.period;
                    if (period) {
                        const { key, value } = getResultItem({
                            periodId: comparePeriod.id,
                            periodType: 'comparePeriod',
                            reportingObject,
                            reportingObjectId,
                            metricId,
                            period,
                        });
                        acc[key] = value;
                    }
                });
            });
            return acc;
        }, {});

        return result;
    };

    /**
     * Генерация элемента структуры
     */
    const getResultItem = (args: IResultItemArgs) => {
        const { reportingObjectId, metricId, periodType, periodId, period, reportingObject } = args;
        return {
            key: `${reportingObjectId}:${metricId}:${periodType}:${periodId}`,
            value: {
                isFetching: false,
                isError: true,
                data: [],
                period,
                reportingObject,
            },
        };
    };

    return { emptyStructure };
};

export default usePrepareEmptyStructure;
