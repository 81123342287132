import { MessageBar, MessageBarType } from '@fluentui/react';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAlert, IProps } from './interfaces';
import { removeAlert } from './reducer';
import { Wrapper } from './styles';



/**
 * Компонент для отображения нотификации
 */
const Alert: FC<IProps> = ({ alert, children }) => {
    const dispatch = useDispatch();

    /** Автозакрытие */
    useEffect(() => {
        let timer: undefined | NodeJS.Timeout = undefined;
        if (alert.duration) {
            timer = setTimeout(() => dispatch(removeAlert(alert.id)), alert.duration * 1000);
        }

        return () => clearTimeout(timer);
    }, [alert.duration]);

    const onDismiss = () => {
        dispatch(removeAlert(alert.id));
    };

    return (
        <Wrapper>
            <MessageBar onDismiss={onDismiss} messageBarType={alert.type}>
                <div>{alert.text}</div>
                {children}
            </MessageBar>
        </Wrapper>
    );
};

export default Alert;
