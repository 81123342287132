import { ColorPicker as FluentColorPicker, getColorFromString, IColor } from '@fluentui/react';
import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../../../../../../../../../../../tools/useDebounce';

interface IProps {
    currentColor: string;
    onColorChange: (color: string) => void;
}

const ColorPicker: FC<IProps> = ({ currentColor, onColorChange }) => {
    const [color, setColor] = useState(getColorFromString(currentColor)!);
    const debouncedColor: IColor = useDebounce(color);

    useEffect(() => {
        if (debouncedColor?.str !== currentColor) {
            onColorChange(debouncedColor?.str || 'rgba(112, 24, 24, 0.5)');
        }
    }, [debouncedColor]);

    const updateColor = useCallback((_: SyntheticEvent<HTMLElement, Event>, colorObj: IColor) => {
        setColor(colorObj);
    }, []);

    return <FluentColorPicker color={color} onChange={updateColor} showPreview={true} />;
};

export default ColorPicker;
