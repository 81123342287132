import styled from 'styled-components/macro';
import { Stack } from '@fluentui/react';

export const SelectsWrapper = styled(Stack)`
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--space-xxs);
`;

export const MainPeriodText = styled.div`
    margin-right: var(--space-sm);
    font-size: var(--text-lg);
    font-weight: 500;
    padding-bottom: var(--space-xxxs);
`;
