import React, { ComponentType, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import { Events_Events_Summary_Widget_Reducer_Values, reloadWidget } from './reducer';
import {
    CellWrapper,
    Cont,
    Marker,
    Metric,
    ObjectWrapper,
    Period,
    PeriodWrapper,
    Row,
    TableWrapper,
    WidgetWrapper,
} from './styles';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { shortStringDate } from '../../../../../../../tools/Strings/shortStringDate';
import { IDateRange } from '../../../../../../../General.interfaces';
import { Icon } from '../../../../../../../lib/esm/components';
import Cell from './components/Cell';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { numberWithSpaces } from '../../../../../../../tools/Strings/numberWithSpaces';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

const SummaryTable: React.FC = ({}) => {
    const ref = useRef(null);

    const { preparedData } = useSelector(Events_Events_Summary_Widget_Reducer_Values);
    const {
        lang,
        allMetrics,
        src: { reportingObjects },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const localCurrentOptions = useWidgetCurrentOptions('Events:Events');

    const rerender = useRerender(preparedData);

    const mainPeriod = localCurrentOptions?.mainPeriod?.id;
    const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
    const mainTimeRange = [mainDateRange?.period.dateFrom, mainDateRange?.period.dateTo];

    const table = useMemo(() => {
        if (!preparedData?.['status'] && Array.isArray(preparedData)) {
            return preparedData.map((obj) => {
                const reportingObject = reportingObjects?.find((item) => String(item.id) === obj.objId);
                if (
                    reportingObject &&
                    localCurrentOptions?.comparePeriods &&
                    mainTimeRange &&
                    localCurrentOptions?.selectedMetrics
                ) {
                    return (
                        <ObjectWrapper key={reportingObject.id}>
                            <Row numCompPeriods={localCurrentOptions?.comparePeriods?.length + 2}>
                                <CellWrapper isHeader={true}>
                                    <Marker>
                                        <WidgetTitle>{reportingObject?.name}</WidgetTitle>
                                    </Marker>
                                </CellWrapper>
                                <CellWrapper isHeader={true}>
                                    <PeriodWrapper>
                                        <Cont>
                                            {/* <Icon /> */}
                                            <Period>{shortStringDate(mainTimeRange as string[], lang)}</Period>
                                        </Cont>
                                    </PeriodWrapper>
                                </CellWrapper>
                                {localCurrentOptions.comparePeriods.map((compPeriod, compPeriodIndex) => {
                                    const cPeriod = localCurrentOptions.compareDateRanges?.find(
                                        (item) => item.id === compPeriod.id,
                                    ) as IDateRange;
                                    return cPeriod?.period ? (
                                        <CellWrapper isHeader={true} key={compPeriod.id}>
                                            <PeriodWrapper>
                                                <Cont>
                                                    <Icon type="compare" />
                                                    <Period>{shortStringDate(cPeriod.period, lang)}</Period>
                                                </Cont>
                                            </PeriodWrapper>
                                        </CellWrapper>
                                    ) : null;
                                })}
                            </Row>
                            {localCurrentOptions?.selectedMetrics.map((metric, metricIndex) => {
                                const metricName = t(allMetrics?.find((m) => m.id === metric)?.text || '');
                                const numCompPeriods = localCurrentOptions?.comparePeriods?.length;
                                const units = t(allMetrics?.find((m) => m.id === metric)?.units || '');

                                return numCompPeriods && obj?.['data']?.[metric]?.['main'] ? (
                                    <Row key={metric} numCompPeriods={numCompPeriods + 2}>
                                        <CellWrapper>
                                            <Metric>{metricName}</Metric>
                                        </CellWrapper>
                                        <Cell
                                            isMain={true}
                                            value={`${numberWithSpaces(
                                                obj?.['data']?.[metric]?.['main']?.['value']?.toFixed(2),
                                            )} ${units}`}
                                        />
                                        {localCurrentOptions?.comparePeriods?.map((compPeriod, compPeriodIndex) => {
                                            const cPeriod = localCurrentOptions.compareDateRanges?.find(
                                                (item) => item.id === compPeriod.id,
                                            ) as IDateRange;
                                            return obj?.['data']?.[metric]?.[cPeriod?.id] ? (
                                                <Cell
                                                    key={`${metric}+${reportingObject.id}--${compPeriod.id}`}
                                                    isMain={false}
                                                    value={`${numberWithSpaces(
                                                        obj?.['data']?.[metric]?.[cPeriod.id]?.['value']?.toFixed(2),
                                                    )}`}
                                                    percent={obj['data'][metric][cPeriod.id]['percent']}
                                                />
                                            ) : null;
                                        })}
                                    </Row>
                                ) : null;
                            })}
                        </ObjectWrapper>
                    );
                } else {
                    return null;
                }
            });
        } else {
            return null;
        }
    }, [
        preparedData,
        reportingObjects,
        localCurrentOptions?.comparePeriods,
        localCurrentOptions?.selectedMetrics,
        localCurrentOptions?.compareDateRanges,
        mainTimeRange,
        lang,
        t,
        allMetrics,
    ]);

    const WithLoadingTable = withLoading(TableWrapper as ComponentType, { data: preparedData, height: 400 });

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Summary')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        reloadHandler={reloadWidget}
                        widgetName={'Summary'}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            <WithLoadingTable>{table}</WithLoadingTable>
        </WidgetWrapper>
    );
};

export default SummaryTable;
