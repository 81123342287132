import { CrossVisitsMapsSettingsIds, ICrossVisitsSelectOption } from '../../../constants/crossVisitsMapsSettings';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { requestLayers, requestPlans } from '../../../../../../../../lib/esm/components';
import requestAverageIntersectionsHelper from '../tools/requestAverageIntersectionsHelper';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { mlFromLocation } from '../../../../../../../../tools/mlFromLocation';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { CROSS_VISITS_MAPS_SETTINGS } from '../../../constants/constants';
import { Maps_CrossVisits_Module_Reducer_Values } from '../../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    storeRawComparisonAverageIntersectionsData,
    storeRawAverageIntersectionsData,
    Maps_CrossVisits_Widget_Reducer_Values,
    storeRawLayersData,
    storeRawPlansData,
} from '../reducer';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const { mainReportingObjectId, contraReportingObjectsIds } = useSelector(Maps_CrossVisits_Widget_Reducer_Values);
    const { selectedVersionId } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Cross-visits');
    const { token, locations } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Запрос и сохранение сырых данных по слоям */
    useEffect(() => {
        if (selectedVersionId && localCurrentOptions?.selectedLocationId && token) {
            const location = locations?.find((item) => item.id === localCurrentOptions.selectedLocationId);
            const mlId = mlFromLocation(location);
            dispatch(
                storeRawLayersData({ status: ResponseStatus.Loading, message: `${t(ResponseStatus.Loading)}...` }),
            );
            mlId &&
                requestLayers({ mlId, token, versionId: String(selectedVersionId) }).then((res: TServerResponse) => {
                    handleServerResponse({
                        success: storeRawLayersData,
                        error: storeRawLayersData,
                        responseAnalyzer,
                        dispatch,
                        res,
                    });
                });
        }
    }, [selectedVersionId, token]);

    /** Запрос и сохранение сырых данных для планов*/
    useEffect(() => {
        if (!localCurrentOptions || !localCurrentOptions.selectedLocationId || !token || !locations.length) return;
        const location = locations?.find((item) => item.id === localCurrentOptions.selectedLocationId);
        const mlId = mlFromLocation(location);
        dispatch(storeRawPlansData({ status: ResponseStatus.Loading, message: `${t(ResponseStatus.Loading)}...` }));
        mlId &&
            requestPlans({ mlId, token }).then((res: TServerResponse) => {
                handleServerResponse({
                    success: storeRawPlansData,
                    error: storeRawPlansData,
                    responseAnalyzer,
                    dispatch,
                    res,
                });
            });
    }, [localCurrentOptions?.selectedLocationId, token, locations]);

    /** Заапрос и сохранение сырых данных по среднедневным пересечениям */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (localCurrentOptions?.mainPeriod && mainReportingObjectId && contraReportingObjectsIds) {
            requestAverageIntersectionsHelper({
                dateRanges: localCurrentOptions.mainDateRanges,
                action: storeRawAverageIntersectionsData,
                period: localCurrentOptions.mainPeriod,
                contraReportingObjectsIds,
                mainReportingObjectId,
                dispatch,
                signal,
                token,
            });
        }

        return () => controller.abort();
    }, [localCurrentOptions?.mainPeriod, mainReportingObjectId, contraReportingObjectsIds]);

    /** Получение сырых данных для периода сранвнения */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (
            !!localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS]?.find(
                (element: ICrossVisitsSelectOption) => element.id === CrossVisitsMapsSettingsIds.Comparison,
            ) &&
            localCurrentOptions?.comparePeriods &&
            mainReportingObjectId &&
            contraReportingObjectsIds
        ) {
            requestAverageIntersectionsHelper({
                action: storeRawComparisonAverageIntersectionsData,
                dateRanges: localCurrentOptions.compareDateRanges,
                period: localCurrentOptions.comparePeriods[0],
                contraReportingObjectsIds,
                mainReportingObjectId,
                dispatch,
                signal,
                token,
            });
        } else {
            dispatch(storeRawComparisonAverageIntersectionsData(null));
        }

        return () => controller.abort();
    }, [
        localCurrentOptions?.[CROSS_VISITS_MAPS_SETTINGS],
        localCurrentOptions?.compareDateRanges,
        localCurrentOptions?.comparePeriods,
        contraReportingObjectsIds,
        mainReportingObjectId,
    ]);
};
