import { TFunction } from 'react-i18next';

export enum IntersectionsReportAdditionalFieldsIds {
    Id = 'Id',
}

export interface IIntersectionsReportAddFieldOption {
    id: IntersectionsReportAdditionalFieldsIds;
    text: string;
    columnName?: string;
}

export const intersectionsReportAdditionalFieldsOptions: IIntersectionsReportAddFieldOption[] = [
    {
        id: IntersectionsReportAdditionalFieldsIds.Id,
        text: 'Add id',
    },
];

export const generateIntersectionsReportAddFields = (t: TFunction): IIntersectionsReportAddFieldOption[] => {
    return intersectionsReportAdditionalFieldsOptions.map((element) => ({
        ...element,
        text: (element.text),
        columnName: t(element.columnName || ''),
    }));
};
