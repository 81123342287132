/**
 * Словарь соответствий полей локации между бэком и фронтом.
 */
export const cabinetPreferencesMapping: Map<string, string> = new Map<string, string>([
    ['id', 'id'],
    ['userId', 'user_id'],
    ['metricLevel', 'metric_level'],
    ['saveSettingsMode', 'settings_save_mode'],
    ['commonPeriods', 'use_common_periods'],
]);
