import styled from 'styled-components/macro';

export const MetricContainer = styled.section`
    margin-top: var(--space-xxs);
    display: flex;
`;

export const CanvasWrapper = styled.div<{ colsNumber: number }>`
    display: grid;
    grid-template-columns: ${(p) => (p.colsNumber === 2 ? 'auto auto' : 'auto')};
    grid-gap: 10px;
    /* display: flex; */
`;
export const Cont = styled.div``;

export const Floor = styled.div`
    position: absolute;
    width: var(--space-md);
    height: var(--space-md);
    background-color: var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 2;
    font-weight: 500;
`;

export const StageWrapper = styled.div`
    cursor: pointer;
    position: relative;
    border: 1px solid var(--color-secondary-dark);
    /* padding: 10px; */
    margin-bottom: 10px;
    background-color: var(--color-secondary);
`;

export const Overlay = styled.div`
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
`;

export const WidgetWrapper = styled.div`
    /* margin-top: var(--space-sm); */
`;
