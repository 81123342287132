import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import { OptionsWrapper } from './styles/styles';
import MapsWidget from './widgets/MapsWidget/MapsWidget';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import Preset from '../../../Preset/Preset';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import { Container } from './styles';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import { useTranslation } from 'react-i18next';
import { Maps_MapsOverview_Module_Reducer_Values, storeSelectedVersionId } from './reducer';
import { stringDate } from '../../../../../tools/Strings/stringDate';
import { generalReducerValues } from '../../../../../General.reducer';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { Select } from '../../../../../lib/esm/components';
import { TSelectedOptions } from '../../../../../lib/esm/components/Select/configurations';
import { ISettings } from '../../../UserSettings/interfaces';

const Maps: React.FC = ({}) => {
    const { showComparison, versions, selectedVersionId } = useSelector(Maps_MapsOverview_Module_Reducer_Values);
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (versions.length) {
            const id = cloneDeep(versions).sort((a, b) => {
                return (
                    DateTime.fromISO(b.date_from || '2300-01-01').toMillis() -
                    DateTime.fromISO(a.date_from || '2300-01-01').toMillis()
                );
            })[0].id;
            dispatch(storeSelectedVersionId(id));
        }
    }, [dispatch, versions]);

    const settingsSelect = useMemo(() => {
        return (
            <UniversalSelect
                options={[
                    { id: 'comparison', text: 'Comparison' },
                    { id: 'showPlans', text: 'Show plans' },
                    { id: 'showPerimeters', text: 'Show Perimeters' },
                    { id: 'showLabels', text: 'Show labels' },
                    { id: 'showRatings', text: 'Show ratings' },
                    { id: 'joinFloors', text: 'Join floors' },
                ]}
                name={'mapsSettings'}
                labelText={'Settings'}
                iconType={'setting'}
                defaultSelected={[{ id: 'showRatings', text: 'Show ratings' }]}
            />
        );
    }, []);

    const versionsOptions = cloneDeep(versions)
        .sort((a, b) => {
            return (
                DateTime.fromISO(b.date_from || '2300-01-01').toMillis() -
                DateTime.fromISO(a.date_from || '2300-01-01').toMillis()
            );
        })
        .map((item) => {
            const text = `id: ${item.id} (${stringDate([item.date_from, item.date_to], lang)})`;
            return { ...item, text };
        });

    const handleVersionSelect = (args: TSelectedOptions) => {
        const id = args[0].id;
        dispatch(storeSelectedVersionId(Number(id)));
    };

    const outsideSelected = versionsOptions?.filter((item) => item.id === selectedVersionId) || [];

    return (
        <ModuleWrapper>
            <Container>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        {showComparison && <ComparePeriodSelect mode="single" />}
                        <MetricsSelect maxSelected={2} />
                        {/* {versions.length > 1 && (
                            <Select
                                options={versionsOptions}
                                labelText={'Versions'}
                                mode="single"
                                showFilter={false}
                                handleSelect={handleVersionSelect}
                                outsideSelected={outsideSelected}
                                // margin={'r_sm'}
                                iconType="chevron-down"
                            />
                        )} */}
                        {settingsSelect}
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={[
                                'selectedMetrics',
                                'comparePeriods',
                                'mainPeriod',
                                'mapsSettings' as keyof ISettings,
                            ]}
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <MapsWidget />
            </Container>
        </ModuleWrapper>
    );
};

export default Maps;
