import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { paymentAnalysisMetricsSelect, paymentAnalysisTenantSelect } from '../../../constants/contsants';
import generatePaymentAnalysisRequests from '../tools/generatePaymentAnalysisRequests';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../reducer';
import { TRawMetricsData } from '../interfaces';
import {
    Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values,
    storeChartOptions,
    storeRawMetricsData,
    storeSalesBySubset,
} from '../reducer';
import usePrepareData from './usePrepareData';

/**
 * Кастомный хук для получения данных
 */
export const useGetRawData = () => {
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const { dataRefetchObject } = useSelector(Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const { token } = useSelector(generalReducerValues);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    usePrepareData();

    /** Получение сырых данных */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions?.[paymentAnalysisMetricsSelect] &&
            localCurrentOptions?.[paymentAnalysisTenantSelect] &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.mainPeriod &&
            token
        ) {
            dispatch(storeSalesBySubset(null));
            dispatch(storeChartOptions(null));
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: 'Loading...' }));

            const requestArgs = generatePaymentAnalysisRequests({
                reportingObjectId: localCurrentOptions[paymentAnalysisTenantSelect][0].id,
                metricId: localCurrentOptions[paymentAnalysisMetricsSelect][0].id,
                periodDateRanges: localCurrentOptions.mainDateRanges,
                period: localCurrentOptions.mainPeriod,
                metricLevel: metricLevel,
                token,
            }).map((element) => ({ ...element, signal }));

            fetchData(requestArgs).then((res: TServerResponse) => {
                // dispatch(storeRawMetricsData(dataFish as unknown as TRawMetricsData));
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    dispatch,
                    res,
                });
            });
        }

        return () => controller.abort();
    }, [
        localCurrentOptions?.[paymentAnalysisMetricsSelect],
        localCurrentOptions?.[paymentAnalysisTenantSelect],
        localCurrentOptions?.mainPeriod,
        dataRefetchObject,
        token,
    ]);
};

const dataFish = [
    [
        {
            context: {
                alias: 'sales:is_fiscal:true',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 500,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:is_fiscal:false',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 1500,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:ecash',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 1000,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:cash',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 500,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:mixed',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 250,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:prepaid',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 75,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:postpaid',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 75,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:provision',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 50,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:payment_type:other',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 50,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:operation_type:sale',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 750,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:operation_type:return',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 250,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:operation_type:arrival',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 500,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:operation_type:return_arrival',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 250,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:operation_type:other',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 250,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:discount_level:0_24',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 1000,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:discount_level:25_49',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 500,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:discount_level:50_99',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 250,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:discount_level:100',
                metric: 'fsf_sales_novat',
                metric_level: 'clean',
                time_range: ['2022-06-01', '2022-06-30'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-06-01',
                    value: 250,
                },
            ],
        },
    ],
];
