import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import generateAverageIntersectionsQueryData from '../tools/generateAverageIntersectionsQueryData';
import { requestAverageIntersections } from '../../../../../../../../lib/esm/components';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    storeExtendedAverageIntersectionsData,
    Reports_Intersections_Widget_Reducer_Values,
    storeRawAverageIntersectionsData,
    resetIntersewctionsWidgetReducer,
    storeGrid,
} from '../reducer';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Daily intersections');
    const { downloadSignalCounter } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const { token, selectedLocationId } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Обнуление редьюсера при выборе другой проектной локации */
    useEffect(() => {
        dispatch(resetIntersewctionsWidgetReducer());
    }, [selectedLocationId]);

    useEffect(() => {
        if (!localCurrentOptions || !token || !downloadSignalCounter) return;
        dispatch(storeExtendedAverageIntersectionsData(null));
        dispatch(storeGrid(null));
        const { selectedReportingObjectsIds, mainPeriod, mainDateRanges } = localCurrentOptions;

        const selectedCompareReportingObjectsIds = localCurrentOptions?.['compareObjects'].map((item: any) => item.id);

        const controller = new AbortController();
        const signal = controller.signal;

        if (selectedReportingObjectsIds && selectedCompareReportingObjectsIds && mainDateRanges && mainPeriod) {
            const queryData = generateAverageIntersectionsQueryData({
                selectedCompareReportingObjectsIds: selectedCompareReportingObjectsIds,
                selectedReportingObjectsIds: selectedReportingObjectsIds,
                mainDateRanges,
                mainPeriod,
                token,
            }).map((element) => ({ ...element, signal }));

            dispatch(storeRawAverageIntersectionsData({ status: ResponseStatus.Loading, message: t('Loading...') }));
            requestAverageIntersections(queryData).then((responses: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawAverageIntersectionsData,
                    error: storeRawAverageIntersectionsData,
                    res: responses,
                    dispatch,
                });
            });
        }

        return () => controller.abort();
    }, [downloadSignalCounter, token]);
};
