import { IGenerateExtendedReportingObjectDataArgs } from './interfaces';

/**
 * Функция для генеарции расширенных данных для отчетного объекта
 * @param aggaregatedMetrics Объект метрик, где ключом является ID отчетного объекта,а значение это так же объект,
 *  где ключ это дата, за которую была получена метрика, а значение это сами данные
 * @param selectedMetrics Выбранная метрика
 * @param allMetrics Массив всех метрик
 * @returns Объект, где ключ это ID отчетного объекта, а значение это данные,
 * которыми нужно расширеть существующий отчетных объект
 */
const generateExtendedReportingObjectData = (args: IGenerateExtendedReportingObjectDataArgs) => {
    const { aggaregatedMetrics, allMetrics, selectedMetrics } = args;
    const selectedMetricText =
        allMetrics?.find((element) => element.id === selectedMetrics[0])?.text || selectedMetrics[0];
    return Object.keys(aggaregatedMetrics).reduce((acc, key) => {
        acc[key] = {
            rawMetricsByDate: aggaregatedMetrics[key],
            chartOptionsGeneratorSettings: aggaregatedMetrics[key]['status']
                ? undefined
                : {
                      tooltipSubTitle: selectedMetricText,
                      tooltipTitle: 'Periods',
                  },
        };
        return acc;
    }, {});
};

export default generateExtendedReportingObjectData;
