import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_ru from './translations/translations_ru.json';
import sidebar_translations_ru from './translations/sidebar_translations_ru.json';
import help_translations_ru from './translations/help_translations_ru.json';
import events_translations_ru from './translations/events_translations_ru.json';

const resources = {
    ru: {
        translation: translations_ru,
        translationHelps: help_translations_ru,
        sidebarTranslation: sidebar_translations_ru,
        eventsTranslation: events_translations_ru,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
