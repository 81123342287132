import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import { IReducerState } from './interfaces';

const initialState: IReducerState = {
    moduleName: 'Sales:Payment analysis',
};

const Sales_PaymentAnalysis_Module_Reducer = createSlice({
    name: 'Sales_PaymentAnalysis_Module_Reducer',
    initialState,
    reducers: {},
});

export const {} = Sales_PaymentAnalysis_Module_Reducer.actions;

export const Sales_PaymentAnalysis_Module_Reducer_Values = (state: RootState) =>
    state.Sales_PaymentAnalysis_Module_Reducer;

export default Sales_PaymentAnalysis_Module_Reducer.reducer;
