import { __assign } from '../../../../_virtual/_tslib.js';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import { Week, WeekDay, DayCell, WeekNumber, MonthWrapper, MonthHeader, MonthName, Month } from './styles.js';
import { weekDayNames, monthNames, bgColor } from '../../const/index.js';
import { arrangeDaysToMonthGrid, getRanges } from '../../core/index.js';

var MonthsGrid = function (_a) {
    var allDaysOfCalendar = _a.allDaysOfCalendar, lang = _a.lang, firstDateDidChanged = _a.firstDateDidChanged, secondDateDidChanged = _a.secondDateDidChanged, oneDayOnly = _a.oneDayOnly, showFutureDates = _a.showFutureDates;
    var _b = useState(null), firstSelectedDate = _b[0], setFirstSelectedDate = _b[1];
    var _c = useState(null), secondSelectedDate = _c[0], setSecondSelectedDate = _c[1];
    var _d = useState(null), dateUnderCursor = _d[0], setDateUnderCursor = _d[1];
    var _e = useState(null), weekUnderCursor = _e[0], setWeekUnderCursor = _e[1];
    var now = DateTime.now();
    useEffect(function () {
        firstDateDidChanged(firstSelectedDate);
        secondDateDidChanged(secondSelectedDate);
    }, [firstSelectedDate, secondSelectedDate]);
    var weekDays = (jsx(Week, { children: weekDayNames.map(function (item) {
            return jsx(WeekDay, { children: item[lang].toUpperCase() }, "".concat(item[lang]));
        }) }, void 0));
    var onDayCellClick = function (data) { return function () {
        if (data === null || data === void 0 ? void 0 : data.day) {
            if (!oneDayOnly) {
                if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                    setFirstSelectedDate(data.day);
                    setSecondSelectedDate(null);
                    setDateUnderCursor(null);
                }
                else if (firstSelectedDate && !secondSelectedDate) {
                    setSecondSelectedDate(data.day);
                }
            }
            else {
                if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                    setFirstSelectedDate(data.day);
                    setSecondSelectedDate(data.day);
                    setDateUnderCursor(null);
                }
                else if (firstSelectedDate && !secondSelectedDate) {
                    setSecondSelectedDate(data.day);
                }
            }
        }
    }; };
    var onWeekNumberClick = function (week) { return function () {
        var _a, _b, _c;
        var someDay = (_b = (_a = week.week) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.data; })[0].data) === null || _b === void 0 ? void 0 : _b.day;
        if (someDay) {
            var firstWeekDay = someDay === null || someDay === void 0 ? void 0 : someDay.startOf('week');
            var lastWeekDay = someDay === null || someDay === void 0 ? void 0 : someDay.endOf('week');
            var range = Interval.fromDateTimes(firstWeekDay, lastWeekDay);
            var filteredWeek = (_c = Array.from(getRanges(range, 'day'))) === null || _c === void 0 ? void 0 : _c.filter(function (day) {
                return showFutureDates ? true : day.valueOf() < now.valueOf();
            });
            lastWeekDay = filteredWeek.pop();
            setDateUnderCursor(lastWeekDay);
            setFirstSelectedDate(firstWeekDay);
            setSecondSelectedDate(lastWeekDay);
        }
    }; };
    var onDayCellMouseEnter = function (data) { return function () {
        if (firstSelectedDate && secondSelectedDate)
            return;
        if (data === null || data === void 0 ? void 0 : data.day) {
            setDateUnderCursor(data.day);
        }
    }; };
    var onWeekNumberMouseEnterLeave = function (weekNumber) { return function () {
        setWeekUnderCursor(function () { return weekNumber; });
    }; };
    var grid = allDaysOfCalendar.map(function (month, i) {
        var weeks = arrangeDaysToMonthGrid(month).map(function (week, j) {
            var days = week.week.map(function (day, k) {
                var _a, _b, _c;
                var disabled = day.data ? (showFutureDates ? false : day.data.day > now) : true;
                var isLastUnderCusor = (day.data !== null &&
                    (((_a = day === null || day === void 0 ? void 0 : day.data) === null || _a === void 0 ? void 0 : _a.day.valueOf()) === (dateUnderCursor === null || dateUnderCursor === void 0 ? void 0 : dateUnderCursor.valueOf()) ||
                        (secondSelectedDate &&
                            ((_b = day === null || day === void 0 ? void 0 : day.data) === null || _b === void 0 ? void 0 : _b.day.valueOf()) === (secondSelectedDate === null || secondSelectedDate === void 0 ? void 0 : secondSelectedDate.startOf('day').valueOf())))) ||
                    false;
                var selected = day.data !== null &&
                    ((firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) === day.data.day.valueOf() ||
                        (secondSelectedDate === null || secondSelectedDate === void 0 ? void 0 : secondSelectedDate.valueOf()) === day.data.day.valueOf());
                var bg = 'transparent';
                if (day.data && firstSelectedDate && dateUnderCursor) {
                    if (day.data.day.valueOf() === (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) &&
                        firstSelectedDate < dateUnderCursor) {
                        bg = "linear-gradient(90deg,transparent 50%,".concat(bgColor, " 0)");
                    }
                    else if (day.data.day.valueOf() === (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) &&
                        firstSelectedDate > dateUnderCursor) {
                        bg = "linear-gradient(-90deg,transparent 50%,".concat(bgColor, " 0)");
                    }
                    else if ((day.data.day.valueOf() > (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) &&
                        day.data.day.valueOf() < (dateUnderCursor === null || dateUnderCursor === void 0 ? void 0 : dateUnderCursor.valueOf())) ||
                        (day.data.day.valueOf() < (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) &&
                            day.data.day.valueOf() > (dateUnderCursor === null || dateUnderCursor === void 0 ? void 0 : dateUnderCursor.valueOf()))) {
                        bg = "".concat(bgColor);
                    }
                    else if (day.data.day.valueOf() > (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && isLastUnderCusor) {
                        bg = "linear-gradient(-90deg,transparent 50%,".concat(bgColor, " 0)");
                    }
                    else if (day.data.day.valueOf() < (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && isLastUnderCusor) {
                        bg = "linear-gradient(90deg,transparent 50%,".concat(bgColor, " 0)");
                    }
                }
                return (jsx(DayCell, __assign({ disabled: disabled, bg: bg, selected: selected, isLastUnderCusor: isLastUnderCusor, onClick: onDayCellClick(day.data), onMouseEnter: onDayCellMouseEnter(day.data) }, { children: jsx("span", { children: (_c = day.data) === null || _c === void 0 ? void 0 : _c.day.day }, void 0) }), "day".concat(i, "++").concat(j, "++").concat(k)));
            });
            var weekDisabled = showFutureDates ? false : week.week.every(function (d) { var _a; return ((_a = d.data) === null || _a === void 0 ? void 0 : _a.day) > now || !d.data; });
            var weekInFocus = week.weekNumber === weekUnderCursor && weekUnderCursor !== null && !weekDisabled;
            if (!oneDayOnly) {
                days.unshift(jsx(WeekNumber, __assign({ onMouseEnter: onWeekNumberMouseEnterLeave(week.weekNumber), onMouseLeave: onWeekNumberMouseEnterLeave(null), weekDisabled: weekDisabled, onClick: onWeekNumberClick(week) }, { children: week.weekNumber && jsx("span", { children: week.weekNumber }, void 0) }), "weeknumber".concat(i, "++").concat(j, "++").concat(week.weekNumber)));
            }
            else {
                days.unshift(jsx(WeekNumber, { weekDisabled: weekDisabled }, "weeknumber".concat(i, "++").concat(j, "++").concat(week.weekNumber)));
            }
            return (jsx(Week, __assign({ weekInFocus: weekInFocus, weekDisabled: weekDisabled }, { children: days }), "week".concat(i, "++").concat(j)));
        });
        var monthName = "".concat(monthNames[month[0].day.month][lang], " ").concat(month[0].day.year);
        return (jsxs(MonthWrapper, __assign({ index: i }, { children: [jsx(MonthHeader, { children: jsx(MonthName, { children: monthName }, void 0) }, void 0), jsxs(Month, { children: [weekDays, weeks] }, "month".concat(i))] }), "".concat(monthName, "00").concat(i)));
    });
    return jsx(Fragment, { children: grid }, void 0);
};

export { MonthsGrid as default };
