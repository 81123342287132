import React, { ReactNode } from 'react';
import cn from 'classnames';
import * as constants from '../../constants/constants';

import '../../styles/styles.scss';

const SwitcherStub: React.FC<{ children?: ReactNode; onStubClick?: () => void }> = ({
    onStubClick,
    ...props
}) => {
    const { DEFAULT_CLASS_SWITCHER, DEFAULT_SEPARATOR_ELEMENT_PREFIX } = constants;

    const className = cn([`${DEFAULT_CLASS_SWITCHER}${DEFAULT_SEPARATOR_ELEMENT_PREFIX}stub`]);

    return (
        <div className={className} onClick={onStubClick}>
            {props.children}
        </div>
    );
};

export default SwitcherStub;
