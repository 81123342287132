import styled from 'styled-components/macro';

export const Title = styled.div`
    position: relative;
    font-size: 1.5rem ;
    display: inline-block;
    font-weight: 500;
    /* height: 40px; */
    /* padding-right: 15px;
    padding-left: 15px; */
    /* flex: 0 0 100%; */
    max-width: 100%;
`;
