var weekDayNames = [
    { en: '', ru: '' },
    { en: 'Mo', ru: 'Пн' },
    { en: 'Tu', ru: 'Вт' },
    { en: 'We', ru: 'Ср' },
    { en: 'Th', ru: 'Чт' },
    { en: 'Fr', ru: 'Пт' },
    { en: 'Sa', ru: 'Сб' },
    { en: 'Su', ru: 'Вс' },
];
var monthNames = {
    1: { en: 'January', ru: 'Январь' },
    2: { en: 'February', ru: 'Февраль' },
    3: { en: 'March', ru: 'Март' },
    4: { en: 'April', ru: 'Апрель' },
    5: { en: 'May', ru: 'Май' },
    6: { en: 'June', ru: 'Июнь' },
    7: { en: 'July', ru: 'Июль' },
    8: { en: 'August', ru: 'Август' },
    9: { en: 'September', ru: 'Сентябрь' },
    10: { en: 'October', ru: 'Октябрь' },
    11: { en: 'November', ru: 'Ноябрь' },
    12: { en: 'December', ru: 'Декабрь' },
};
var shortMonthNames = {
    1: { en: 'Jan', ru: 'Янв' },
    2: { en: 'Feb', ru: 'Фев' },
    3: { en: 'Mar', ru: 'Мар' },
    4: { en: 'Apr', ru: 'Апр' },
    5: { en: 'May', ru: 'Май' },
    6: { en: 'Jun', ru: 'Июн' },
    7: { en: 'Jul', ru: 'Июл' },
    8: { en: 'Aug', ru: 'Авг' },
    9: { en: 'Sep', ru: 'Сен' },
    10: { en: 'Oct', ru: 'Окт' },
    11: { en: 'Nov', ru: 'Ноя' },
    12: { en: 'Dec', ru: 'Дек' },
};
var applyBtnText = { en: 'Apply', ru: 'Применить' };
var showText = { en: 'Show by:', ru: 'Показывать по:' };
var yearsText = { en: 'Years', ru: 'Годы' };
var monthsText = { en: 'Months', ru: 'Месяцы' };
var bgColor = 'rgba(69,114,230,.2)';

export { applyBtnText, bgColor, monthNames, monthsText, shortMonthNames, showText, weekDayNames, yearsText };
