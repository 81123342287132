/**
 * Стилевое оформление кнопки
 */
var ButtonTheme;
(function (ButtonTheme) {
    /**
     * Значение темы кнопки не определено
     */
    ButtonTheme["Empty"] = "";
    /**
     * Кнопка только с рамочкой
     */
    ButtonTheme["Outline"] = "outline";
    /**
     * Кнопка без рамки и фона
     */
    ButtonTheme["Text"] = "text";
    /**
     * Кнопка с иконкой без текста
     */
    ButtonTheme["Icon"] = "icon";
})(ButtonTheme || (ButtonTheme = {}));

export { ButtonTheme };
