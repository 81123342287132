import { IChartDetailing } from '../../../../../../../../constants/chartDetailing';
import { IDateFormatFromChartDetailing } from './interfaces';

/**
 * Функция для получения формата даты в зависимости от выбранной детализации графика
 * @param chartDetailing детализация графика
 * @param isCategory флаг, категории
 * @returns формат даты
 */
const getDateFormatByChartDetailing = (
    chartDetailing: IChartDetailing,
    isCategory: boolean = false,
): IDateFormatFromChartDetailing => {
    const format: IDateFormatFromChartDetailing = {
        preset: 'DATE_MED',
        token: 'dd MMM yyyy',
    };
    switch (chartDetailing.id) {
        case '15MIN':
        case 'H':
            format.token = 'dd MMM yyyy, HH:mm';
            break;
        case 'D':
            format.token = isCategory ? 'dd MMM yyyy' : 'dd MMM yyyy (EEEE)';
            break;
        case 'W-MON':
            format.token = 'WW yyyy';
            break;
        case 'MS':
            format.token = 'MMMM yyyy';
        default:
            break;
    }
    return format;
};

export default getDateFormatByChartDetailing;
