import { useMemo, useRef } from 'react';

import { ChartWrapper } from './styles';
import Dynamics from '../../../../../Charts/Dynamics/Dynamics';
import Funnel from './components/Funnel/Funnel';
import { PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS } from '../../constants/constants';
import { ResponseStatus } from '../../../../../../../tools/API/constants';
import { Stack } from '@fluentui/react';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { generalReducerValues } from '../../../../../../../General.reducer';
import getDataForLoadingWrapper from '../../../../Maps/CrossVisits/widgets/CrossVisitsWidget/tools/getDataForLoadingWrapper';
import { Performance_PathToPurchase_Widget_Reducer_Values, reloadWidget } from './reducer';
import { useRerender } from '../../../../../../../tools/useRerender';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWidgetCurrentOptions } from '../../../../../../../tools/useWidgetCurrentOptions';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета Путь к покупке
 */
const PathToPurchaseWidget = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Path to purchase');
    const { rawMetricsData, chartOptionsGeneratorSettings, extendedReportingObjects } = useSelector(
        Performance_PathToPurchase_Widget_Reducer_Values,
    );
    const { structures } = useSelector(generalReducerValues);
    const rerender = useRerender(rawMetricsData);
    const { t } = useTranslation();
    const ref = useRef(null);

    const funnels = useMemo(() => {
        if (extendedReportingObjects && localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS]) {
            return extendedReportingObjects.map((element) => (
                <Funnel
                    funnelSteps={localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS]}
                    key={element.reportingObjectData.id}
                    data={element}
                />
            ));
        }
        return null;
    }, [extendedReportingObjects, localCurrentOptions?.[PATH_TO_PURCHASE_FUNNEL_STEPS_SETTINGS]]);

    const chart = useMemo(() => {
        const loadingData = getDataForLoadingWrapper([
            structures || { status: ResponseStatus.Error, message: t('Get structure error') },
            rawMetricsData,
        ]);
        const WithLoadingTable = withLoading(ChartWrapper, { height: 400, data: loadingData });

        return (
            <WithLoadingTable>
                {chartOptionsGeneratorSettings && <Dynamics {...chartOptionsGeneratorSettings} />}
            </WithLoadingTable>
        );
    }, [rawMetricsData, structures, chartOptionsGeneratorSettings, t]);

    return (
        <div ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Path to purchase')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={'Path to purchase'}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>

            {chart}
            <Stack wrap={true} horizontal tokens={{ childrenGap: 10, padding: 10 }}>
                {funnels}
            </Stack>
        </div>
    );
};

export default PathToPurchaseWidget;
