import styled from 'styled-components';

export const ColorBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: var(--space-xs);
`;

export const Txt = styled.div`
    margin-left: var(--space-xs);
`;

export const Box = styled.div<{ color: string }>`
    cursor: pointer;
    background-color: ${(p) => p.color};
    width: 30px;
    height: 30px;
    border-radius: 5px;
`;
