import styled from 'styled-components/macro';

export const Rating = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: var(--space-xs);
    height: 320px;
    border: 1px solid var(--color-secondary);
    border-radius: 5px;
    .byArea-ratins-list {
        margin-top: var(--space-xs);
        overflow: auto;
        height: 83%;
    }
`;

export const Ul = styled.ul`
    padding: 0;
    margin: 0;
`;
export const Li = styled.li`
    list-style: none;
    padding-bottom: var(--space-xxs);
    display: flex;
    justify-content: space-between;
`;
export const Percent = styled.div<{ color: string }>`
    font-weight: 500;
    color: ${(p) => p.color};
`;
