import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderText = styled.div`
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: var(--text-lg);
    div {
        span {
            margin: 0 var(--space-xs);
            font-size: var(--text-xs);
            color: var(--color-secondary-darker);
        }
    }
`;

export const SelectsWrapper = styled.div`
    display: flex;
`;

export const SelectsWrapperItem = styled.div<{ hideMargin?: boolean }>`
    margin-left: ${({ hideMargin = false }) => (hideMargin ? '0px' : 'var(--space-unit)')};
`;

export const PeriodWrapper = styled.div`
    margin-left: var(--space-xs);
`;
