import React, { useMemo } from 'react';
import cn from 'classnames';

import { IMenuProps } from '../../configurations';
import * as constants from '../../constants/constants';
import '../../styles/styles.scss';
import Menu from './components/Menu';

const SideBarMenu: React.FC<IMenuProps> = ({ ...props }) => {
    const { DEFAULT_CLASS_MENU, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;

    const className = React.useMemo(() => {
        return cn({
            'ss-container': true,
            [DEFAULT_CLASS_MENU]: true,
            [`${DEFAULT_CLASS_MENU}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: props.compact,
        });
    }, [DEFAULT_CLASS_MENU, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, props.compact]);

    return (
        <div className={className}>
            <div className="ss-wrapper">
                <div className="ss-content">
                    <Menu {...props} />
                </div>
            </div>
        </div>
    );
};

export default SideBarMenu;
