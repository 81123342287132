import styled from 'styled-components/macro';

export const ObjectWrapper = styled.div`
    margin-bottom: var(--space-sm);
`;
export const Row = styled.div<{ numCompPeriods: number }>`
    -moz-column-gap: 3px;
    column-gap: 3px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${(p) => {
        return 'repeat(' + p.numCompPeriods + ', 210px)';
    }};
`;

export const CellWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 14px;
    padding: var(--space-md);
    border-left: 1px solid var(--color-secondary) ;
`;

export const Name = styled.div`
min-width: 150px;
    width: 100%;
    align-items: center;
    display: flex;
    color: var(--color-secondary-dark);
    margin-bottom: var(--space-md);
`;

export const Value = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    font-weight: 600;
`;

export const Period = styled.div`
    /* font-size: var(--text-sm); */
    width: 100%;
    padding: var(--space-xxxs) var(--space-xxs);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`;

export const Metric = styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: var(--space-xxxs) var(--space-xxs);
`;

export const WidgetWrapper = styled.div`
    margin-top: var(--space-sm);
`;

export const Marker = styled.div`
    padding: var(--space-xxs);
    display: block;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
`;
export const TableWrapper = styled.div`
    padding: var(--space-xxs) 0;
    display: flex;
    flex-wrap: wrap;
`;

export const MainPercent = styled.td`
    font-weight: 700;
    text-align: right;
`;

export const MainTraffic = styled.td`
    text-align: right;
    color: var(--color-gray-darker);
    border-right: 1px solid var(--default-border-color);
`;
export const CompareDates = styled.td`
    text-align: right;
    div {
        display: flex;
        flex-direction: column;
    }
`;

export const ComparePercent = styled.td<{ color: string }>`
    text-align: right;
    font-weight: 700;
    color: ${(p) => p.color};
`;

export const Rating = styled.td`
    min-width: 100px;
    position: relative;
    display: flex;
`;

export const RatingValue = styled.div<{ width: string }>`
    width: ${(p) => {
        return p.width;
    }};
    background-color: var(--color-info-lighter);
    opacity: 0.6;
`;

export const PeriodWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const Cont = styled.div`
    display: flex;
    align-items: center;
`;
