import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Exclude } from './configurations/exclude.enum.js';
import { Size } from './configurations/size.enum.js';

var useBorderRadius = function (_a) {
    _a.ref; var _b = _a.styles, borderRadius = _b.borderRadius, borderRadiusExclude = _b.borderRadiusExclude, _c = _a.constants, DEFAULT_CLASS_BORDER_RADIUS_PREFIX = _c.DEFAULT_CLASS_BORDER_RADIUS_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX = _c.DEFAULT_SEPARATOR_MODIFICATION_PREFIX;
    var _d = useState([]), classes = _d[0], setClasses = _d[1];
    /**
     * Проверка: есть ли есть ли такие углы, возвращает массив валидных значений
     * @param exclude - значение параметра Exclude
     */
    var isValidExclude = function (exclude) {
        var excludeArray = _.replace(exclude, /[\s]/g, '').split(',');
        var validExcludeArray = [];
        _.forEach(excludeArray, function (item) {
            if (_.values(Exclude).includes(item)) {
                validExcludeArray.push(item);
            }
        });
        return validExcludeArray;
    };
    /**
     * Проверка: есть ли такой размер?
     * @param size - размер скругления угла
     */
    var isValidSize = function (size) {
        var key = _.capitalize(size);
        return Boolean(Size[key]);
    };
    useEffect(function () {
        var classes = [];
        if (borderRadius && isValidSize(borderRadius)) {
            classes.push("".concat(DEFAULT_CLASS_BORDER_RADIUS_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(borderRadius));
        }
        if (borderRadiusExclude && borderRadius) {
            var excludeValue = isValidExclude(borderRadiusExclude);
            _.forEach(excludeValue, function (item) {
                classes.push("".concat(DEFAULT_CLASS_BORDER_RADIUS_PREFIX).concat(DEFAULT_SEPARATOR_MODIFICATION_PREFIX).concat(item, "--unset"));
            });
        }
        setClasses(classes);
    }, [DEFAULT_CLASS_BORDER_RADIUS_PREFIX, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, borderRadius, borderRadiusExclude]);
    return classes;
};

export { useBorderRadius as default };
