import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import {
    storeZonesByGroup,
    storeSelectedGroup,
    storeSelectedZoneId,
    storeResponseResult,
    storePreraredData,
    storeAllFloors,
    storeSelectedFloor,
    storeRatingType,
    Performance_Dashboard_Traffic_Widget_Reducer_Values,
} from '../reducer';
import { userSettingsReducerValues } from '../../../../../../UserSettings/reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { cloneDeep } from 'lodash';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';

export const useGetMetrics = () => {
    const fetchData = useRequestMetrics();
    const { dataRefetchObject } = useSelector(Performance_Dashboard_Traffic_Widget_Reducer_Values);
    const {
        structures,
        token,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const localCurrentOptions = useWidgetCurrentOptions('Performance:Dashboard');
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);

    useEffect(() => {
        if (!Object.keys(reportingObjectsByType).length) return;
        const allZones = reportingObjectsByType['zone'];
        const allFloors = reportingObjectsByType['floor'];
        dispatch(storeRatingType('location'));
        if (!allZones) {
            dispatch(storeZonesByGroup({}));
            dispatch(storeSelectedGroup(null));
            dispatch(storeSelectedZoneId(null));
        } else {
            const zonesByGroup = {};
            allZones.forEach((zone) => {
                const groupMarker = zone.object_params.group_marker;
                if (groupMarker && zonesByGroup[groupMarker]) {
                    zonesByGroup[groupMarker] = [...zonesByGroup[groupMarker], zone];
                } else if (groupMarker && !zonesByGroup[groupMarker]) {
                    zonesByGroup[groupMarker] = [zone];
                }
            });
            dispatch(storeZonesByGroup(zonesByGroup));
            const selectedGroupId = Object.keys(zonesByGroup).map((groupMarker) => groupMarker)[0];
            dispatch(storeSelectedGroup(selectedGroupId));
            const selectedZoneId = zonesByGroup[selectedGroupId][0].id;
            dispatch(storeSelectedZoneId(selectedZoneId));
        }
        if (!allFloors) {
            dispatch(storeAllFloors([]));
            dispatch(storeSelectedFloor(null));
        } else {
            const sorted = cloneDeep(allFloors).sort(
                (a, b) => Number(b.object_params.floor) - Number(a.object_params.floor),
            );
            dispatch(storeAllFloors(sorted));
            const selectedFloor = Number(sorted[0].object_params.floor);
            dispatch(storeSelectedFloor(selectedFloor));
        }
    }, [reportingObjectsByType]);

    useEffect(() => {
        dispatch(storePreraredData({ status: 'Loading', message: t('Loading...') }));
        dispatch(storeResponseResult(null));
        if (!localCurrentOptions?.mainPeriod || !structures?.relations_passway2dataobj) {
            handleServerResponse({
                responseAnalyzer: responseAnalyzer,
                success: storeResponseResult,
                error: storePreraredData,
                dispatch,
                res: [],
            });
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;
        const objIds = reportingObjectsByType['passway']?.map((item) => item.id);
        const mainPeriod = localCurrentOptions.mainPeriod?.id;
        const mainDateRange = localCurrentOptions.mainDateRanges?.find((item) => item.id === mainPeriod);
        if (mainDateRange) {
            const mainTimeRange = [mainDateRange.period.dateFrom, mainDateRange.period.dateTo] as string[];
            const compareTimeRanges = localCurrentOptions.compareDateRanges
                ?.filter((item) => localCurrentOptions.comparePeriods?.find((p) => p.id === item.id) !== undefined)
                .map((item) => [item.period.dateFrom, item.period.dateTo]);

            const mainRequest = {
                signal,
                token,
                alias: 'Traffic by entrances',
                metric: 'fpc_sum_pass_count_in_wh',
                metric_level: metricLevel,
                obj_ids: objIds,
                object_aggregation: false,
                time_range: mainTimeRange,
                time_freq: null,
            };

            const compareRequests = compareTimeRanges?.map((period) => {
                return { ...mainRequest, time_range: period };
            });

            const finalRequests = [mainRequest, ...compareRequests];

            fetchData(finalRequests).then((res: TServerResponse) => {
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeResponseResult,
                    error: storeResponseResult,
                    dispatch,
                    res,
                });
            });
        }

        return () => {
            controller.abort();
        };
    }, [
        localCurrentOptions?.mainPeriod,
        localCurrentOptions?.comparePeriods,
        reportingObjectsByType['passway'],
        structures?.relations_passway2dataobj,
        dataRefetchObject,
    ]);
};
