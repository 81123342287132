import { FC, useContext, useEffect, useState } from 'react';
import { HeaderText, HeaderWrapper, PeriodWrapper, SelectsWrapper, SelectsWrapperItem } from './styles';

import { DynamicsPMOContext } from '../../context';
import { IChartOptionsProps } from './interfaces';
import { Select } from '../../../../../../../../../lib/esm/components';
import { stringDate } from '../../../../../../../../../tools/Strings/stringDate';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отображения селектов, для выбора настроек графика
 * @param showEntitiesSelect Флаг, отвечающий за отображения селекта с объектами
 * @param showMetricsSelect Флаг, отвечающий за отображение селекта с метриками
 * @param showDatePeriod Флаг, отвечающий за отображение селекта с периодом
 * @param title Заголовок
 */
const ChartOptions: FC<IChartOptionsProps> = ({
    showEntitiesSelect = true,
    showMetricsSelect = true,
    showDatePeriod = false,
    title,
}) => {
    const {
        storeSelectedChartDetailingTrans,
        extendedReportingObjects,
        storeSelectedEntityTrans,
        storeSelectedMetricTrans,
        availableChartDetailing,
        selectedChartDetailing,
        extendedMetrics,
        selectedObject,
        selectedMetric,
        mainDateRanges,
        mainPeriod,
        lang,
    } = useContext(DynamicsPMOContext);
    const [datePeriodText, setDatePriodText] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (mainPeriod && mainDateRanges && showDatePeriod) {
            const dateRanges = mainDateRanges?.find((element) => element.id === mainPeriod.id);
            if (dateRanges) {
                setDatePriodText(
                    stringDate({ dateFrom: dateRanges.period.dateFrom, dateTo: dateRanges.period.dateTo }, lang),
                );
            }
        } else {
            setDatePriodText('');
        }
    }, [showDatePeriod, mainDateRanges, mainPeriod]);

    return (
        <HeaderWrapper>
            <HeaderText>
                {`${title}`}
                {showDatePeriod ? <PeriodWrapper>{datePeriodText}</PeriodWrapper> : ''}
            </HeaderText>
            <SelectsWrapper>
                {/* {showEntitiesSelect ? (
                    <SelectsWrapperItem>
                        <Select
                            outsideSelected={selectedObject && [selectedObject]}
                            handleSelect={storeSelectedEntityTrans}
                            options={extendedReportingObjects}
                            labelText={t('Select object')}
                            iconType={'chevron-down'}
                            dropdownWidth={'sm'}
                            showFilter={false}
                            mode={'single'}
                        />
                    </SelectsWrapperItem>
                ) : null} */}

                {showMetricsSelect ? (
                    <SelectsWrapperItem>
                        <Select
                            outsideSelected={selectedMetric && [{ ...selectedMetric, text: t(selectedMetric.text) }]}
                            options={extendedMetrics.map((element) => ({ ...element, text: t(element.text) }))}
                            handleSelect={storeSelectedMetricTrans || function () {}}
                            labelText={t('Metrics')}
                            iconType={'chevron-down'}
                            dropdownWidth={'sm'}
                            showFilter={false}
                            mode={'single'}
                        />
                    </SelectsWrapperItem>
                ) : null}

                <SelectsWrapperItem>
                    <Select
                        outsideSelected={selectedChartDetailing && [selectedChartDetailing]}
                        handleSelect={storeSelectedChartDetailingTrans || function () {}}
                        options={availableChartDetailing}
                        labelText={t('Detailing')}
                        iconType={'chevron-down'}
                        dropdownWidth={'sm'}
                        showFilter={false}
                        mode={'single'}
                    />
                </SelectsWrapperItem>
            </SelectsWrapper>
        </HeaderWrapper>
    );
};

export default ChartOptions;
