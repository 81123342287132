import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsColorAxis from 'highcharts/modules/coloraxis';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
HighchartsColorAxis(Highcharts);
NoDataToDisplay(Highcharts);

const Column: React.FC<HighchartsReact.Props> = (props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<any>();

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        </div>
    );
};

export default Column;
