import { IExtendedReportingObject, ITableSortData, IState, TRawMetricsData, IComparePeriod } from './interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store';
import { IPeriodObj } from '../../../../../../../General.interfaces';

const initialState: IState = {
    idsOfReportingObjects: null,
    selectedObjectsType: null,
    extendedReportingObjects: null,
    rawMetricsData: null,
    tableSortData: null,
    selectedComparePeriod: null,
    availableComparePeriods: [],
    dataRefetchObject: {},
};

const Events_Events_TableOfObjects_Widget_Reducer = createSlice({
    name: 'Leasing_Tenants_Tenants_Widget_Reducer',
    initialState,
    reducers: {
        /**
         * Сохранение id всех отчетныъ объектов выбранного типа
         */
        storeIdsOfReportingObjects: (state, action: PayloadAction<null | number[]>) => {
            state.idsOfReportingObjects = action.payload;
        },

        /**
         * Сохранение сырых данных метрик
         */
        storeRawMetricsData: (state, action: PayloadAction<TRawMetricsData>) => {
            state.rawMetricsData = action.payload;
        },

        /**
         * Сохранение расширенныйх данных отчетных объектов выбранного типа
         */
        storeExtendedReportingObjects: (state, action: PayloadAction<null | IExtendedReportingObject[]>) => {
            state.extendedReportingObjects = action.payload;
        },

        /**
         * Сохрание данных для сортировки таблицы
         */
        storeTableSortData: (state, action: PayloadAction<null | ITableSortData>) => {
            state.tableSortData = action.payload;
        },

        /**
         * Сохранение выбранного типа отчетных объектов
         */
        storeSelectedObjectsType: (state, action: PayloadAction<null | string>) => {
            state.selectedObjectsType = action.payload;
        },

        /**
         * Сохранение выбранного периода сравнения
         */
        storeSelectedComparePeriod: (state, action: PayloadAction<null | IComparePeriod>) => {
            state.selectedComparePeriod = action.payload;
        },

        /**
         * Сохранение достпных периодов сравнения
         */
        storeAvailableComparePeriods: (state, action: PayloadAction<IComparePeriod[]>) => {
            state.availableComparePeriods = action.payload;
        },

        /**
         * Перезагрузка виджета
         */
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeIdsOfReportingObjects,
    storeRawMetricsData,
    storeExtendedReportingObjects,
    storeSelectedComparePeriod,
    storeTableSortData,
    storeSelectedObjectsType,
    storeAvailableComparePeriods,
    reloadWidget,
} = Events_Events_TableOfObjects_Widget_Reducer.actions;

export const Events_Events_TableOfObjects_Widget_Reducer_Values = (state: RootState) =>
    state.Events_Events_TableOfObjects_Widget_Reducer;

export default Events_Events_TableOfObjects_Widget_Reducer.reducer;
