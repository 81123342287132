import { TFunction } from 'react-i18next';

export const colors = [
    { color: 'rgb(76, 158, 166)', selectedColor: 'rgba(76, 158, 166, 0.15)' },
    { color: 'rgb(220, 99, 12)', selectedColor: 'rgba(220, 99, 12, 0.15)' },
    { color: 'rgb(179, 14, 172)', selectedColor: 'rgba(179, 14, 172, 0.15)' },
    { color: 'rgb(12, 33, 220)', selectedColor: 'rgba(12, 33, 220, 0.15)' },
    { color: 'rgb(54, 206, 251)', selectedColor: 'rgba(54, 206, 251, 0.15)' },
];

export const ReportingObjectsTypesMap = {
    tenant: 'Tenants',
    zone: 'Zones',
};

export const generateOptionsOfReportingObjectsTypes = (t: TFunction) => [
    { id: 'tenant', text: t('Tenants'), value: 'tenant' },
    { id: 'zone', text: t('Zones'), value: 'zone' },
];
