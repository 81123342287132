import { TMetricResponse } from '../../../../../../../../General.interfaces';
import { ITotalValue } from '../interfaces';

interface IArgs {
    /**
     * Сырые данные
     */
    rawMetricsData: TMetricResponse[];
    /**
     * Общее количесвто арендаторов
     */
    tenantsCount: number;
}

/**
 * Функция для получения итогового значения по всем метрикам и периодам
 */
const getTotalValue = (args: IArgs): ITotalValue => {
    const { rawMetricsData, tenantsCount } = args;

    const result: ITotalValue = {};

    rawMetricsData.forEach((metricResponse) => {
        metricResponse.forEach((data) => {
            const periodType = data.context.alias!.split(':')[3];
            const subtypeId = data.context.alias!.split(':')[2];

            if (!result[subtypeId]) {
                result[subtypeId] = {
                    comparePercentageOfTheAverageMain: 0,
                    comparePercentageOfTheMain: 0,
                    comparePeriodTotalValue: 0,
                    mainPeriodTotalValue: 0,
                    compareAverageValue: 0,
                    mainAverageValue: 0,
                };

                if (periodType === 'main') {
                    result[subtypeId].mainPeriodTotalValue = data.items[0].value;
                }

                if (periodType === 'compare') {
                    result[subtypeId].comparePeriodTotalValue = data.items[0].value;
                }
            } else {
                if (periodType === 'main') {
                    result[subtypeId].mainPeriodTotalValue += data.items[0].value;
                }

                if (periodType === 'compare') {
                    result[subtypeId].comparePeriodTotalValue += data.items[0].value;
                }
            }
        });
    });

    const finalResult = Object.keys(result).reduce((acc, value) => {
        const subtypeValue = result[value];

        const compareAverageValue = subtypeValue.comparePeriodTotalValue / tenantsCount;
        const mainAverageValue = subtypeValue.mainPeriodTotalValue / tenantsCount;

        acc[value] = {
            ...subtypeValue,
            comparePercentageOfTheMain:
                subtypeValue.mainPeriodTotalValue === 0
                    ? 0
                    : (subtypeValue.comparePeriodTotalValue / subtypeValue.mainPeriodTotalValue) * 100 - 100,
            comparePercentageOfTheAverageMain:
                mainAverageValue === 0 ? 0 : (compareAverageValue / mainAverageValue) * 100 - 100,
            compareAverageValue,
            mainAverageValue,
        };
        return acc;
    }, {});

    return finalResult;
};

export default getTotalValue;
