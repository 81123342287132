import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

const WrapperContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    return <Container>{children}</Container>;
};

export default WrapperContainer;
