import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../lib/esm/components';
import { storeRemoveRating } from '../../reducer';
import { useDispatch } from 'react-redux';
import { Controls } from './styles';

const ModalDeleteRating: React.FC<{ closeModal: () => void; ratingId: number }> = ({ closeModal, ratingId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const buttonRemoveClick = () => {
        closeModal();
        if (ratingId) {
            dispatch(storeRemoveRating(ratingId));
        }
    };

    return (
        <div>
            <p> {t('You are going to delete the event. Continue?')}</p>
            <Controls>
                <Button onClick={closeModal} type="primary" theme="outline">
                    {t('Cancel')}
                </Button>
                <Button onClick={buttonRemoveClick} type="accent">
                    {t('Remove')}
                </Button>
            </Controls>
        </div>
    );
};
export default ModalDeleteRating;
