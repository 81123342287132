import { ContextualMenu, FocusZoneDirection } from '@fluentui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../../../../../../../../../tools/useDebounce';
import FilterInput from '../../../../../../../../../FilterInput/FilterInput';
import { EventsManagementContext } from '../../../../context';
import { TFiltersMode } from '../../../../interfaces';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import getCellStringValue from '../../../../tools/getCellStringValue';

/**
 * Компонент для отображения контекстного окна с фильтрами
 */
const FilterContextualMenu = () => {
    const context = useContext(EventsManagementContext);
    const [filter, setFilter] = useState<null | { filter: string; key: string; mode?: TFiltersMode }>(null);
    const debouncedFilter = useDebounce(filter);
    const { t } = useTranslation();

    useEffect(() => {
        if (debouncedFilter) {
            context?.addNewFilterTrans(debouncedFilter);
        }
    }, [debouncedFilter]);

    const changeValue = (value: string, mode?: TFiltersMode) => {
        if (context && context.contextMenuTarget) {
            setFilter({ filter: value, key: context.contextMenuTarget.cell.key, mode });
        }
    };

    const renderFilterItem = () => {
        if (!context?.contextMenuTarget) return null;
        return <FilterInput value={filter?.filter || ''} changeValue={changeValue} isOutsideControlled />;
    };

    const closeContextualMenu = () => {
        context?.storeContextMenuTragetTrans(null);
    };

    const resetFilters = () => {
        context?.resetFiltersTrans();
        setFilter(null);
    };

    const menuItems = context?.contextMenuTarget
        ? [
              {
                  key: 'filters',
                  itemType: ContextualMenuItemType.Section,
                  sectionProps: {
                      title: t('Filters'),
                      items:
                          context.contextMenuTarget.cell.type === 'body'
                              ? [
                                    {
                                        key: 'filter',
                                        text: `${t('Filter')}`,
                                        subMenuProps: {
                                            focusZoneProps: { direction: FocusZoneDirection.bidirectional },
                                            items: [
                                                {
                                                    key: 'filterInput',
                                                    text: `${t('Filter')}`,
                                                    onRender: renderFilterItem,
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        key: 'filterByValue',
                                        text: `${t('Filter by value')}`,
                                        onClick: () => {
                                            let value = '';
                                            if (context && context?.contextMenuTarget) {
                                                value = getCellStringValue(
                                                    context.contextMenuTarget.cell,
                                                    context.reportingObjectsById,
                                                    context.eventTypesById,
                                                );
                                            }
                                            return changeValue(value);
                                        },
                                    },
                                    {
                                        key: 'filterByAnyValue',
                                        text: `${t('Filter by any value')}`,
                                        onClick: () => changeValue('__', 'Any value'),
                                    },
                                    {
                                        key: 'filterByNoValue',
                                        text: `${t('Filter by no value')}`,
                                        onClick: () => changeValue('__', 'No value'),
                                    },
                                    {
                                        key: 'reset',
                                        text: t('Reset filters'),
                                        onClick: resetFilters,
                                    },
                                ]
                              : [
                                    {
                                        key: 'reset',
                                        text: t('Reset filters'),
                                        onClick: resetFilters,
                                    },
                                ],
                  },
              },
          ]
        : [];

    return (
        <ContextualMenu
            items={menuItems}
            hidden={!!!context?.contextMenuTarget}
            target={context?.contextMenuTarget?.cursorCoords || null}
            onDismiss={closeContextualMenu}
        />
    );
};

export default FilterContextualMenu;
