import { useEffect, useMemo, useRef, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { createTheme, loadTheme } from '@fluentui/react';
import { svgSprite } from './lib/esm/components';
import './style.scss';
import Header from './cabinet/components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import {
    storeLocations,
    generalReducerValues,
    storeSideBarMenu,
    changeCurrentModule,
    storeToken,
    storeMainWindowSize,
} from './General.reducer';
import { useTranslation } from 'react-i18next';
import useSettings from './tools/useSettings';
import Dashboard from './cabinet/components/Modules/Performance/Dashboard/Dashboard';
import YearOverYear from './cabinet/components/Modules/Performance/YearOverYear/YearOverYear';
import VisitorsInside from './cabinet/components/Modules/Performance/VisitorsInside/VisitorsInside';
import Basic from './cabinet/components/Modules/Reports/Basic/Basic';
import Comparison from './cabinet/components/Modules/Reports/Comparison/Comparison';
import Intersections from './cabinet/components/Modules/Reports/Intersections/Intersections';
import TenantsReport from './cabinet/components/Modules/Reports/Tenants/Tenants';
import { AlertsLayer, Main, MainArea, WorkArea } from './App.styles';
import useCurrentOptions from './tools/useCurrentOptions';
import { RegisterIcons } from './tools/RegisterIcons';
import Faq from './cabinet/components/Modules/Help/Faq/Faq';
import DataComponent from './tools/DataComponent';
import 'react-datasheet/lib/react-datasheet.css';
import LoadingBox from './cabinet/components/LoadingBox/LoadingBox';
import ModuleWrapper from './cabinet/components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from './cabinet/components/Wrappers/WrapperContainer/WrapperContainer';
import Maps from './cabinet/components/Modules/Maps/MapsOverview/MapsOverview';
import PaymentAnalysis from './cabinet/components/Modules/Sales/PaymentAnalysis/PaymentAnalysis';
import useCabinetPreferencesData from './cabinet/components/CabinetPreferences/hooks/useCabinetPreferencesData';
import Ratings from './cabinet/components/Modules/Performance/Ratings/Ratings';
import { useWindowResize } from './tools/useWindowResize';
import CrossVisits from './cabinet/components/Modules/Maps/CrossVisits/CrossVisits';
import Categories from './cabinet/components/Modules/Configuration/Categories/Categories';
import EventsConfig from './cabinet/components/Modules/Configuration/EventsConfig/EventsConfig';
import PathToPurchase from './cabinet/components/Modules/Performance/PathToPurchase/PathToPurchase';
import Benchmarks from './cabinet/components/Modules/Cases/Benchmarks/Benchmarks';
import Calculator from './cabinet/components/Modules/Cases/Calculator/Calculator';
import TenantOverview from './cabinet/components/Modules/Leasing/TenantOverview/TenantOverview';
import Tenants from './cabinet/components/Modules/Leasing/Tenants/Tenants';
import LeasingCrossVisits from './cabinet/components/Modules/Leasing/CrossVisits/CrossVisits';
import Metrics from './cabinet/components/Modules/Help/Metrics/Metrics';
import EventsMap from './cabinet/components/Modules/Events/EventsMap/EventsMap';
import Events from './cabinet/components/Modules/Events/Events/Events';
import { ILocation, TMenu } from './cabinet/components/SideBar/configurations';
import SideBar from './cabinet/components/SideBar';
import { modulesConfig } from './constants/modulesConfig';
import { CABINET_SERVICE_NAME } from './constants/urls';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';
import { useAnalytics } from './tools/useAnalytics';
import { useAppBackendHost } from './tools/useAppBackendHost';
import { useRequestEvents } from './cabinet/components/Modules/Events/EventsMap/widgets/EventsMapWidget/hooks/useFetchData';
import { DateTime } from 'luxon';
import { AlertsReducerValues } from './cabinet/components/Alert/reducer';
import Alert from './cabinet/components/Alert/Alert';
import { useRequestRatings } from './cabinet/components/Modules/Performance/Ratings/widgets/RatingList/hooks/useRequestRatings';
import { useRequestAccParams } from './tools/useRequestAccParams';
import { useRequestMainStructures } from './tools/API/useRequestMainStructures';
import { userSettingsReducerValues } from './cabinet/components/UserSettings/reducer';
import { useUserAPI } from './tools/API/useUserAPI';
import TokenExpiration from './cabinet/components/TokenExpiration/TokenExpiration';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './cabinet/components/ErrorBounadryFallback/ErrorBounadaryFallback';
import { useErrorReset } from './tools/useErrorReset';
import { useGenerateBackXLS } from './tools/useGenerateBackXLS';
import { useModulePermissions } from './tools/useModulePermissions';

const theme = createTheme({
    // You can also modify certain other properties such as fontWeight if desired
    defaultFontStyle: { fontFamily: 'var(--font-primary)', fontSize: 'var(--text-md)' },
    palette: {
        themePrimary: 'var(--color-primary)',
    },
});
initializeIcons();
loadTheme(theme);

// Если понадобится динамически переопределить CSS переменные - то делаем так
// document.documentElement.style.setProperty('--color-primary', 'red')

function App() {
    const mainRef = useRef(null);
    const mainWindowSize = useWindowResize(mainRef);
    let navigate = useNavigate();
    useUserAPI();
    useGenerateBackXLS();
    useRequestMainStructures();
    useSettings();
    useAppBackendHost();
    useAnalytics();
    useCurrentOptions();
    useCabinetPreferencesData();
    useRequestRatings();
    useRequestEvents();
    useRequestAccParams();
    const location = useLocation();
    const showModule = useModulePermissions();

    // useMetrics();
    const { t } = useTranslation();
    const [sideBarOptions, setSideBarOptions] = useState({
        selectedSubsection: 'Performance:Dashboard',
    });
    // const [token, setToken] = useState<string | null>(null);
    const dispatch = useDispatch();
    const {
        selectedLocationId,
        lang,
        token,
        timeZone,
        src: { reportingObjects },
        cfg: { reportingObjectsByType, reportingObjectsById },
        currentModule,
    } = useSelector(generalReducerValues);

    const { alerts } = useSelector(AlertsReducerValues);
    const { accParamsReceived } = useSelector(userSettingsReducerValues);

    useEffect(() => {
        dispatch(storeMainWindowSize(mainWindowSize));
    }, [mainWindowSize.height, mainWindowSize.width]);

    useEffect(() => {
        if (currentModule && sideBarOptions.selectedSubsection !== currentModule) {
            setSideBarOptions({ ...sideBarOptions, selectedSubsection: currentModule });
        }
    }, [currentModule]);

    const subsectionDidSelected = (args: { servicePath: string; subsectionId: string }): void => {
        // Метод вызывается при выборе сабсекции в ТЕКУЩЕМ сервисе
        console.log(args.subsectionId);
        document.title = args.subsectionId.split(':')[1];

        dispatch(changeCurrentModule(args.subsectionId));
        navigate(args.servicePath);
        setSideBarOptions({ ...sideBarOptions, selectedSubsection: args.subsectionId });
    };

    // const initialOptionsDidObtain = (args: IGetInitialOptionsArgs): void => {
    //     console.log('initialOptionsDidObtain >>>', args);
    //     dispatch(changeLang(args.lang));
    //     setSideBarOptions({ selectedSubsection: args.selectedSubsection });
    // };

    const tokenDidObtain = (token: string): void => {
        // Записываем полученный токен в хранилище
        dispatch(storeToken(token));
        // console.log('tokenDidObtain >>>', token);
        // setToken(() => token);
    };

    const locationsDidObtain = (locations: ILocation[]): void => {
        // Записываем полученные локации в хранилище
        locations && dispatch(storeLocations(locations));
    };

    const menuDidObtain = (menu: TMenu): void => {
        // Записываем меню в хранилище
        menu && dispatch(storeSideBarMenu(menu));
    };

    const dataHooks = useMemo(() => {
        if (token && Object.keys(reportingObjectsById).length && accParamsReceived) {
            return <DataComponent />;
        } else {
            return null;
        }
    }, [token, reportingObjectsById, accParamsReceived]);

    const reset = useErrorReset();

    return (
        <WorkArea>
            {dataHooks}
            <RegisterIcons />
            <TokenExpiration />
            <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: svgSprite }} />
            <SideBar
                modulesConfig={modulesConfig}
                sideBarOptions={{ ...sideBarOptions, lang }}
                currentServiceName={CABINET_SERVICE_NAME}
                subsectionDidSelected={subsectionDidSelected}
                // initialOptionsDidObtain={initialOptionsDidObtain}
                tokenDidObtain={tokenDidObtain}
                menuDidObtain={menuDidObtain}
            />
            <MainArea ref={mainRef}>
                {/* Основная рабочая область сервиса */}
                <Header marginBottom={location.pathname === '/events/events' ? '0px' : undefined} />
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} resetKeys={[reset]}>
                    <Main>
                        {showModule && selectedLocationId && Object.keys(reportingObjectsById).length ? (
                            <Routes>
                                <Route path="/" element={<Navigate to="/performance/dashboard" />} />
                                <Route path="events">
                                    <Route path="events-map" element={<EventsMap />} />
                                    <Route path="events" element={<Events />} />
                                </Route>
                                <Route path="performance">
                                    <Route path="dashboard" element={<Dashboard />} />
                                    <Route path="year-over-year" element={<YearOverYear />} />
                                    <Route path="visitors-inside" element={<VisitorsInside />} />
                                    <Route path="ratings" element={<Ratings />} />
                                    <Route path="path-to-purchase" element={<PathToPurchase />} />
                                </Route>
                                <Route path="maps">
                                    <Route path="maps-overview" element={<Maps />} />
                                    <Route path="cross-visits" element={<CrossVisits />} />
                                </Route>
                                <Route path="sales">
                                    <Route path="payment-analysis" element={<PaymentAnalysis />} />
                                </Route>
                                <Route path="cases">
                                    <Route path="benchmarks" element={<Benchmarks />} />
                                    <Route path="calculator" element={<Calculator />} />
                                </Route>
                                <Route path="leasing">
                                    <Route path="tenant-overview" element={<TenantOverview />} />
                                    <Route path="tenants" element={<Tenants />} />
                                    <Route path="cross-visits" element={<LeasingCrossVisits />} />
                                </Route>
                                <Route path="reports">
                                    <Route path="basic" element={<Basic />} />
                                    <Route path="with-comparison" element={<Comparison />} />
                                    <Route path="daily-intersections" element={<Intersections />} />
                                    <Route path="by-tenants" element={<TenantsReport />} />
                                </Route>
                                <Route path="configuration">
                                    <Route path="categories" element={<Categories />} />
                                    <Route path="events" element={<EventsConfig />} />
                                </Route>
                                <Route path="helps">
                                    <Route path="faq" element={<Faq />} />
                                    <Route path="metrics" element={<Metrics />} />
                                </Route>
                            </Routes>
                        ) : (
                            <ModuleWrapper>
                                <WrapperContainer>
                                    <LoadingBox height={500} text={t('Loading...')} />
                                </WrapperContainer>
                            </ModuleWrapper>
                        )}
                    </Main>
                </ErrorBoundary>
            </MainArea>
            <AlertsLayer>
                {alerts.map((alert) => (
                    <Alert alert={alert} key={alert.id} />
                ))}
            </AlertsLayer>
        </WorkArea>
    );
}

export default App;
