import { __rest, __spreadArray, __assign } from '../../_virtual/_tslib.js';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import cn from 'classnames';
import * as constants from '../../constants/constants.js';
import { DEFAULT_SEPARATOR_ELEMENT_PREFIX } from '../../constants/constants.js';
import useStyles from '../../hooks/useStyles.js';

var Title = function (_a) {
    var _b = _a.titleLevel, titleLevel = _b === void 0 ? 'h2' : _b, props = __rest(_a, ["titleLevel"]);
    var mergedStyles = useStyles({ props: props, constants: constants });
    var className = React.useMemo(function () {
        var DEFAULT_SEPARATOR_ELEMENT_PREFIX$1 = DEFAULT_SEPARATOR_ELEMENT_PREFIX;
        return cn(__spreadArray(['title_react', "title_react".concat(DEFAULT_SEPARATOR_ELEMENT_PREFIX$1).concat(titleLevel)], mergedStyles, true));
    }, [props, titleLevel]);
    return jsx("div", __assign({ className: className }, { children: props.children }), void 0);
};

export { Title as default };
