import { IAggregatedObject, IGenerateSeriesDataArgs, IResponseItem, ISeriesData, TSeriesType } from './interfaces';

import { DateTime } from 'luxon';
import { MINUTE_IN_MILLIS } from '../../../../../../../../constants/constants';
import { ISeriesPoint, TSeries } from '../../../../../../Charts/Dynamics/interfaces';
import { cloneDeep, isNull } from 'lodash';
import generateId from '../../../../../../../../tools/generateId';
import { stringDate } from '../../../../../../../../tools/Strings/stringDate';

/**
 * функция для генерации серий и дополнительных параметров необходимых для получения настроек
 * диаграммы
 * @param reportingObjectsRawMetricsDataById Объект сырыых метрик для каждого из отчетных объектов
 * @param extendedReportingObjectsById Объект расширенных отчетных объектов
 * @param shoudUpdateChartsByPeriod Флаг отвечающий за обновление всех данных
 * @param mainPeriod основной период
 * @returns объект, где для ключом является id отчетного объекта, а значение - это объект с данными,
 * необходимыми для генерации настроект графика
 */
const generateSeriesData = (args: IGenerateSeriesDataArgs) => {
    const { reportingObjectsRawMetricsDataById, mainDateRanges, mainPeriod, t, lang } = args;
    const FORTY_FIVE_MINUTES_IN_MILLIS = 2700000;
    const subTitle = t('Visitors inside');
    const dateNow = DateTime.now();
    const title = t('Periods');
    const mainPeriodDateRnage = mainDateRanges?.find((element) => element.id === mainPeriod?.id);
    const mainPeriodDateFrom = DateTime.fromISO(mainPeriodDateRnage?.period?.dateFrom || '').toMillis();
    const mainPeriodDateTo = DateTime.fromISO(mainPeriodDateRnage?.period?.dateTo || '')
        .set({ hour: 23, minute: 59 })
        .toMillis();
    const result = Object.keys(reportingObjectsRawMetricsDataById).reduce((acc, reportingObjectId) => {
        const series: TSeries[] = [];
        const rawMetricsData = cloneDeep(reportingObjectsRawMetricsDataById[reportingObjectId]);
        if (Array.isArray(rawMetricsData)) {
            const [rawData]: IAggregatedObject[] = rawMetricsData;
            if (rawData.visitorsInside) {
                Object.keys(rawData.visitorsInside).forEach((period, periodIndex) => {
                    const periodDate = DateTime.fromISO(period).toMillis();

                    let visitorsInsideAtTheMomentPoint = false;
                    const data: { y: number | null; x: number; name: string }[] = rawData.visitorsInside![
                        period
                    ].reduce(
                        (
                            acc: { y: number | null; x: number; name: string }[],
                            metricData: IResponseItem,
                            metricIndex: number,
                            initialArray: IResponseItem[],
                        ) => {
                            const dateNowWithTimeZone = dateNow.setZone(rawData.objectInfo['timezone']);
                            const currentDatetime = DateTime.fromISO(metricData.time, { zone: 'UTC' });

                            let yValue = metricData.value;

                            /**
                             * Если сейчас итерация идет по основному периоду и выбраy сегодняшний день, то для точек со значением null до
                             * текущего часа устанавливается значение 0, а для остаьных null или то значение, которое пришло в значении метрики
                             */
                            if (
                                DateTime.fromISO(metricData.time).toMillis() <= mainPeriodDateTo &&
                                DateTime.fromISO(metricData.time).toMillis() >= mainPeriodDateFrom &&
                                mainPeriod.id === 'today'
                            ) {
                                if (isNull(metricData.value) && dateNowWithTimeZone.hour > currentDatetime.hour) {
                                    yValue = 0;
                                } else if (!isNull(metricData.value) && metricData.value <= 0) {
                                    yValue = 0;
                                } else if (metricData.value) {
                                    yValue = metricData.value;
                                }
                            } else {
                                if (isNull(metricData.value) || metricData.value < 0) {
                                    yValue = 0;
                                }
                            }

                            const currentValue = {
                                name: stringDate(
                                    DateTime.fromISO(metricData.time).toISO(),
                                    lang,
                                    '',
                                    'dd MMM yyyy, HH:mm',
                                ),
                                x: currentDatetime.toMillis(),
                                y: yValue,
                            };

                            if (
                                dateNowWithTimeZone.hour === currentDatetime.hour &&
                                dateNowWithTimeZone.minute - currentDatetime.minute <= 15 &&
                                !visitorsInsideAtTheMomentPoint &&
                                mainPeriod.id === 'today'
                            ) {
                                /**
                                 * Значение в настоящем времени получается путем получения первого элемента с конца списка со значением не null
                                 */
                                const currentDateValue = cloneDeep(initialArray)
                                    .reverse()
                                    ?.find((element) => element.value !== null)?.value;

                                visitorsInsideAtTheMomentPoint = true;
                                let y = 0;

                                /**
                                 * Если итерация идет по периоду сравнения, то его значение берется на конец часа
                                 */
                                if (
                                    DateTime.fromISO(metricData.time).toMillis() <= mainPeriodDateTo &&
                                    DateTime.fromISO(metricData.time).toMillis() >= mainPeriodDateFrom
                                ) {
                                    y = metricData.value || currentDateValue || 0;
                                } else {
                                    y = metricData.value || 0;
                                }

                                return acc.concat([
                                    // currentValue,
                                    {
                                        name: stringDate(
                                            DateTime.fromISO(metricData.time)
                                                .set({
                                                    hour: dateNowWithTimeZone.hour,
                                                    minute: dateNowWithTimeZone.minute,
                                                })
                                                .toISO(),
                                            lang,
                                            '',
                                            'dd MMM yyyy, HH:mm',
                                        ),
                                        x:
                                            dateNowWithTimeZone.toMillis() +
                                            DateTime.now().setZone(rawData.objectInfo['timezone']).offset *
                                                MINUTE_IN_MILLIS,
                                        y: y < 0 ? 0 : y,
                                    },
                                ]);
                            }

                            // const index = acc.findIndex(
                            //     (element) =>
                            //         DateTime.fromFormat(element.name, 'dd MMM yyyy, HH:mm').hour ===
                            //         currentDatetime.hour,
                            // );

                            /**
                             * Данная логика отвечает за отображение значения за начало часа или за конец
                             */
                            // if (index > -1) {
                            //     // if (!isNull(currentValue.y) && !isNull(acc[index].y) && currentValue.y > 0) {
                            //     //     acc[index].y! = currentValue.y;
                            //     // }
                            // } else {
                            //     acc.push(currentValue);
                            // }

                            currentDatetime.minute === 0 && acc.push(currentValue);

                            return acc;
                        },
                        [],
                    );
                    const seriesData: TSeries = {
                        type: periodDate === mainPeriodDateFrom ? 'areaspline' : 'spline',
                        isMain: periodDate === mainPeriodDateFrom,
                        name: stringDate(period.split(':')[0], lang),
                        id: period.split(':')[0] + generateId(),
                        data,
                    };
                    series.push(seriesData);
                });
            }

            acc[reportingObjectId] = {
                timezone: rawData.objectInfo['timezone'],
                showCurrentDateIndicator: true,
                tooltipSubTitle: subTitle,
                tooltipTitle: title,
                series,
            };
        } else {
            acc[reportingObjectId] = null;
        }

        return acc;
    }, {});

    return result;
};

export default generateSeriesData;
