import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import getChartDataDetailing from '../../../../../../../../constants/chartDetailing';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import {
    storeChartOptionsGeneratorSettings,
    storeExtendedReportingObjects,
    storeAvailableChartDetailing,
    Cases_Benchmarks_Traffic_Widgwet_Reducer_Values,
    storeExtendedMetrics,
    toggleHighlightWeekend,
} from '../reducer';
import { TTimeFreq } from '../../../../../../../../lib/esm/components';
import { IMetricsData, TSeriesType } from '../tools/interfaces';
import generateSeriesData from '../tools/generateSeriesData';

const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Work cases:Benchmarks');
    const { rowMetricsData, highlightWeekend, selectedChartDetailing } = useSelector(
        Cases_Benchmarks_Traffic_Widgwet_Reducer_Values,
    );
    const {
        src: { reportingObjects },
        cfg: { reportingObjectsByType },
        allMetrics,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Изменении флага подсветки выходных при изменении детализации */
    useEffect(() => {
        if (selectedChartDetailing?.id !== 'D') dispatch(toggleHighlightWeekend(false));
        else dispatch(toggleHighlightWeekend(true));
    }, [selectedChartDetailing]);

    // В зависимости от главного периода изменяет доступную детализацию графкиа
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges) {
            dispatch(
                storeAvailableChartDetailing(
                    getChartDataDetailing(t, localCurrentOptions.mainDateRanges, localCurrentOptions.mainPeriod),
                ),
            );
        }
    }, [localCurrentOptions?.mainPeriod, localCurrentOptions?.mainDateRanges, t]);

    /**
     * В зависимости от выбранных отбъектов в главном селекте изменяет и селект виджета.
     * Инициурет обновление метрик, если изменение объектов произошло на вкладке Объектов
     */
    useEffect(() => {
        localCurrentOptions?.selectedReportingObjectsIds &&
            dispatch(
                storeExtendedReportingObjects({
                    selectedReportingObjectsIds: localCurrentOptions.selectedReportingObjectsIds,
                    reportingObjects,
                }),
            );
    }, [reportingObjects, localCurrentOptions?.selectedReportingObjectsIds]);

    /**
     * В зависимости от выбранных метрик в главном селекте изменяет селект виджета.
     * Иницииурет обновление метрик, если изменение метрик произошло на вкладке Метрики
     */
    useEffect(() => {
        localCurrentOptions?.selectedMetrics &&
            dispatch(
                storeExtendedMetrics({
                    selectedMetrics: localCurrentOptions.selectedMetrics,
                    allMetrics,
                }),
            );
    }, [localCurrentOptions?.selectedMetrics]);

    /**
     * Генерирует настройки генерации опций для диаграммы при изменении
     *  сырых данных в сторе
     */
    useEffect(() => {
        if (Array.isArray(rowMetricsData)) {
            const allSereis: TSeriesType[] = [];
            let tooltipShowSeriesName = true;
            let chartMultipleAxes = false;
            let tooltipSubTitle = '';
            let tooltipTitle = '';
            let finalXValues: number[] = [];
            const mallName = reportingObjectsByType['location'][0].name;
            cloneDeep(rowMetricsData)
                .sort((a, _) => (a[0].context.alias.split(':')[0] === 'mall' ? -1 : 1))
                .forEach((element: IMetricsData[], responseIndex) => {
                    const selectedChartDetailing = {
                        id: element?.[0]?.context?.alias?.split(':')?.[1] as TTimeFreq,
                        text: '',
                    };
                    const { series, title, subTitle, showSeriesName, multipleAxes, xValues } = generateSeriesData({
                        t,
                        mallName,
                        selectedChartDetailing,
                        xValues: finalXValues,
                        metricsData: element,
                        responseIndex,
                        allMetrics,
                    });
                    tooltipShowSeriesName = showSeriesName;
                    chartMultipleAxes = multipleAxes;
                    tooltipSubTitle = subTitle;
                    allSereis.push(...series);
                    finalXValues = xValues;
                    tooltipTitle = title;
                });

            const optionsGeneratorSettings = {
                series: allSereis.sort((a, _) => (a.isMain ? -1 : 1)),
                showSeriesName: tooltipShowSeriesName,
                xAxisType: 'datetime' as 'datetime',
                multipleAxes: chartMultipleAxes,
                highlightWeekend: false,
                tooltipSubTitle,
                tooltipTitle,
            };

            dispatch(storeChartOptionsGeneratorSettings(optionsGeneratorSettings));
        }
    }, [rowMetricsData]);
};

export default usePrepareData;
