import { ISeriesPoint, TSeries } from '../../interfaces';

import { AdditionalSeriesIds } from '../../constants/enums';
import { ColorType } from 'highcharts';
import { TFunction } from 'i18next';

interface IArgs {
    seriesData: ISeriesPoint[];
    seriesColor?: ColorType;
    seriesName: string;
    t: TFunction;
}

const generateAverageSeries = (args: IArgs) => {
    const { seriesData, seriesName, seriesColor, t } = args;
    const filteredData = seriesData?.filter((element) => element.y !== null);

    if (!filteredData.length) return null;

    const data = filteredData.reduce(
        (acc: { sum: number; count: number; min: number; max: number }, value) => {
            if (value.x > acc.max) acc.max = value.x;
            if (value.x < acc.min) acc.min = value.x;
            acc.sum += value.y as number;
            acc.count += 1;
            return acc;
        },
        {
            min: filteredData[0].x,
            max: filteredData[0].x,
            count: 0,
            sum: 0,
        },
    );

    const { sum, count, min, max } = data as { sum: number; count: number; min: number; max: number };

    const result = {
        additionalSeriesId: AdditionalSeriesIds.Avergage,
        name: `${t('Average for')} ${seriesName || ''}`,
        id: `Average for ${seriesName || ''}`,
        color: seriesColor || 'grey',
        dashStyle: 'Dash',
        isMain: false,
        type: 'line',
        data: [
            {
                y: Math.round(sum / count),
                showTooltipBody: false,
                name: '',
                x: min,
            },
            {
                y: Math.round(sum / count),
                showTooltipBody: false,
                name: '',
                x: max,
                dataLabels: {
                    enabled: true,
                    format: '{y}',
                },
            },
        ],
    };

    return result as TSeries;
};

export default generateAverageSeries;
