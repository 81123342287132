import { IPeriod } from '../../../../../../../../General.interfaces';
import { IRequestMetricsArgs, TTimeFreq } from '../../../../../../../../lib/esm/components';
import { TMetricLevel } from '../../../../../../CabinetPreferences/interfaces';
import { subsetsOfSalesMetricsOptions } from '../../../constants/contsants';
import { ISelectedChartType } from '../interfaces';

interface IArgs {
    periodDateRanges: IPeriod[];
    compareDateRanges: IPeriod[];
    reportingObjectId: number;
    metricLevel: TMetricLevel;
    metricId: string;
    mainPeriod: IPeriod;
    comparePeriod: IPeriod;
    token: string;
    time_freq: TTimeFreq;
    subsetOfSalesMetricsOptions: ISelectedChartType;
}

const generatePaymentAnalysisRequests = (args: IArgs): IRequestMetricsArgs[] => {
    const {
        token,
        reportingObjectId,
        mainPeriod,
        comparePeriod,
        periodDateRanges,
        compareDateRanges,
        metricId,
        metricLevel,
        time_freq,
        subsetOfSalesMetricsOptions,
    } = args;
    const periodValues = periodDateRanges?.find((element) => element.id === mainPeriod.id)?.period;
    const comparePeriodData = compareDateRanges?.find((element) => element.id === comparePeriod.id)?.period;
    const selectedSubsets = subsetsOfSalesMetricsOptions?.find(
        (element) => element.id === subsetOfSalesMetricsOptions.id,
    );
    if (periodValues && selectedSubsets && comparePeriodData) {
        const finalResult: IRequestMetricsArgs[] = [];

        selectedSubsets.subtypes.forEach((subType) => {
            finalResult.push({
                time_range: [periodValues.dateFrom, periodValues.dateTo],
                alias: `sales:${subsetOfSalesMetricsOptions.id}:${subType.id}:main`,
                metric_params: subType.metricParams,
                obj_ids: [reportingObjectId],
                metric_level: metricLevel,
                object_aggregation: false,
                metric: metricId,
                time_freq: time_freq,
                token,
            });
        });

        selectedSubsets.subtypes.forEach((subType) => {
            finalResult.push({
                time_range: [comparePeriodData.dateFrom, comparePeriodData.dateTo],
                alias: `sales:${subsetOfSalesMetricsOptions.id}:${subType.id}:compare`,
                metric_params: subType.metricParams,
                obj_ids: [reportingObjectId],
                metric_level: metricLevel,
                object_aggregation: false,
                metric: metricId,
                time_freq: time_freq,
                token,
            });
        });

        return finalResult;
    }
    return [];
};

export default generatePaymentAnalysisRequests;
