import { ICheckboxItem, IRating } from '../interfaces';

export const MAIN_RATING_INDICATORS: ICheckboxItem[] = [
    {
        id: 'absolute',
        text: 'Absolute value',
        checked: false,
    },
    {
        id: 'delta',
        text: 'Delta',
        checked: false,
    },
    {
        id: 'deviation',
        text: 'Deviation',
        checked: false,
    },
    {
        id: 'area',
        text: 'Area',
        checked: false,
    },
];

export const DEFAULT_RATING_SETTING: IRating = {
    id: 0,
    name: '',
    metric: 'shp_sum_visit_count',
    main_column: MAIN_RATING_INDICATORS[0].id,
    sort_method: 'asc',
    dataobj_type: '',
    additional_columns: MAIN_RATING_INDICATORS,
};
