import React from 'react';

import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import RatingListWidget from './widgets/RatingList/RatingListWidget';

const Ratings: React.FC = () => {
    return (
        <ModuleWrapper>
            <RatingListWidget></RatingListWidget>
        </ModuleWrapper>
    );
};

export default Ratings;
