import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '../../../../../../lib/esm/components';
import { IAccordionItem } from '../../../../../../lib/esm/components/Accordion/configurations';
import { ListBullets, Image, ListItemBullets } from '../../styles';
import { IDataPreparation } from './interfaces';

/**
 * Компонент подготовки данных для аккордеона
 * @param data - данные типа IDataPreparation
 * @returns  - Компонент аккордеон с валидными данными
 */
const AccordionItemContent: React.FC<IDataPreparation> = ({ data }) => {
    const { t } = useTranslation('translationHelps');

    /**
     * Метод подготовки данных
     * @returns возвращает данные в формате предусмотренном компонентом Accordion
     */
    const prepareData = () => {
        const dataPreparation: IAccordionItem[] = [];
        data.forEach((obj) => {
            const imgPath = obj.imageUrl;
            const label: string = t(obj.label);
            const content: string | ReactNode = (
                <>
                    {obj.content && <div>{t(obj.content)}</div>}
                    {obj.list && (
                        <ListBullets>
                            {obj.list.map((itemElement: { text: string }, index) => (
                                <ListItemBullets key={index + Math.random()}>{t(itemElement.text)}</ListItemBullets>
                            ))}
                        </ListBullets>
                    )}
                    {imgPath && <Image src={require(`/src/cabinet/assets/images/helps/${imgPath}`)} />}
                </>
            );
            dataPreparation.push({
                label: label,
                content: content,
            });
        });

        return dataPreparation;
    };
    return <Accordion allowMultipleExpanded={true} data={prepareData()} />;
};
export default AccordionItemContent;
