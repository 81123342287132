import { GradientColorObject, PatternObject } from 'highcharts';
import styled from 'styled-components/macro';

export const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CellWrapper = styled.div`
    width: 100%;
    padding: 0 5px;

    :last-child {
        text-align: right;
        padding-left: 40px !important;
    }
`;
