import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    margin-top: 15px;
    overflow: auto;
    max-height: 700px;
    border: 1px solid #ececec;
`;

export const Tr = styled.tr`
    border: 1px solid #ececec;
`;

export const StyledTable = styled.table`
    border-collapse: separate;
    width: 100%;
    position: relative;
`;
