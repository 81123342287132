import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { IExtendedMetric, IExtendedTenant } from '../interfaces';
import { IMetricResponseItem, IReportingObject, TMetricResponse } from '../../../../../../../../General.interfaces';

interface IArgs {
    rawMetricsData: TMetricResponse[] | { status: ResponseStatus; message: string };
    tenants: IReportingObject[];
    selectedMetrics: string[];
}

/**
 * Функция для получения расширенной информации о арендаторах в зависимости от сырых данных метрик
 * @param selectedMetrics выбранные метрики
 * @param rawMetricsData сырые данные
 * @param tenants все арендаторы
 */
const generateExtendedTenants = (args: IArgs): IExtendedTenant[] => {
    const { rawMetricsData, tenants, selectedMetrics } = args;
    const result: IExtendedTenant[] = [];

    if (Array.isArray(rawMetricsData)) {
        const mainPeriodMetrics = rawMetricsData?.filter((element) => {
            return element[0].context.alias === 'main';
        });

        const comparePeriodMetrics = rawMetricsData?.filter((element) => {
            return element[0].context.alias === 'compare';
        });

        tenants.forEach((tenant) => {
            const currentTenantMainPeriodMetrics: { [metricId: string]: IMetricResponseItem } = {};
            const currentTenantComparePeriodMetrics: { [metricId: string]: IMetricResponseItem } = {};

            mainPeriodMetrics.forEach((element) => {
                const currentTenantMetric = element?.find(
                    (element) => element.context.data_objects[0].id === tenant.id,
                );
                if (currentTenantMetric) {
                    currentTenantMainPeriodMetrics[currentTenantMetric.context.metric] = currentTenantMetric;
                }
            });
            comparePeriodMetrics.forEach((element) => {
                const currentTenantMetric = element?.find(
                    (element) => element.context.data_objects[0].id === tenant.id,
                );

                if (currentTenantMetric) {
                    currentTenantComparePeriodMetrics[currentTenantMetric.context.metric] = currentTenantMetric;
                }
            });

            const metrics: {
                [metricId: string]: IExtendedMetric;
            } = selectedMetrics.reduce((acc, value) => {
                acc[value] = {
                    comparePeriod: currentTenantComparePeriodMetrics[value],
                    mainPeriod: currentTenantMainPeriodMetrics[value],
                };
                return acc;
            }, {});

            result.push({
                metricsLoading: false,
                metricsError: false,
                tenantData: tenant,
                metrics,
            });
        });
    } else if (rawMetricsData.status === ResponseStatus.Loading) {
        tenants.forEach((tenant) => {
            result.push({
                metricsLoading: true,
                metricsError: false,
                tenantData: tenant,
                metrics: null,
            });
        });
    } else if (rawMetricsData.status === ResponseStatus.Error) {
        tenants.forEach((tenant) => {
            result.push({
                metricsLoading: false,
                metricsError: true,
                tenantData: tenant,
                metrics: null,
            });
        });
    }

    return result;
};

export default generateExtendedTenants;
