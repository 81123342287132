import { Reports_Intersections_Widget_Reducer_Values, storeExtendedAverageIntersectionsData } from '../reducer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { IAverageIntersection } from '../../../../../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

/**
 * Кастомный хук для подготовки данных таблицы
 */
const usePrepareData = () => {
    const { rawAverageIntersectionsData } = useSelector(Reports_Intersections_Widget_Reducer_Values);
    const {
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            rawAverageIntersectionsData &&
            !rawAverageIntersectionsData['status'] &&
            Array.isArray(rawAverageIntersectionsData)
        ) {
            const extendedAverageIntersectionsData = rawAverageIntersectionsData.map(
                (intersections: IAverageIntersection[]) => {
                    return intersections.map((element: IAverageIntersection) => {
                        return {
                            ...element,
                            contraReportingObjectData: reportingObjectsById[element.contra_obj_id],
                            mainReportingObjectData: reportingObjectsById[element.main_obj_id],
                        };
                    });
                },
            );

            dispatch(storeExtendedAverageIntersectionsData(extendedAverageIntersectionsData));
        }
    }, [rawAverageIntersectionsData]);
};

export default usePrepareData;
