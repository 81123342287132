import {
    Maps_CrossVisits_Module_Reducer_Values,
    storeSelectedVersionId,
    storeSelectedVersionOption,
    storeVersionSelectOptions,
} from '../reducer';
import { useDispatch, useSelector } from 'react-redux';

import { DateTime } from 'luxon';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useEffect } from 'react';
import { useWidgetCurrentOptions } from '../../../../../../tools/useWidgetCurrentOptions';

/**
 * Кастомный зук для обработки данных
 */
const usePrepareData = () => {
    const { rawVersionsData, versionSelectOptions, selectedVersionId } = useSelector(Maps_CrossVisits_Module_Reducer_Values);
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Cross-visits');
    const { lang } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    /** Сохранение новой опции селекта при изменении всех опций или id выбранной версии */
    useEffect(() => {
        if (versionSelectOptions && selectedVersionId) {
            const selectedOption = versionSelectOptions?.find((element) => element.id === selectedVersionId);
            selectedOption && dispatch(storeSelectedVersionOption([selectedOption]));
        }
    }, [versionSelectOptions, selectedVersionId]);

    /** Получение и сохранение id актуальной версии и опций для селекта версий */
    useEffect(() => {
        if (Array.isArray(rawVersionsData) && rawVersionsData.length) {
            // Тут мы анализируем изменения основного периода и , если надо меняем текущую версию
            const mainPeriod = localCurrentOptions?.mainPeriod?.id;
            const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
            const period = mainDateRange?.period;
            const filtered = rawVersionsData?.filter((version) => {
                return (
                    period?.dateTo &&
                    version.date_from &&
                    DateTime.fromISO(period.dateTo).toMillis() >= DateTime.fromISO(version.date_from).toMillis() &&
                    (!version.date_to ||
                        (version.date_to &&
                            DateTime.fromISO(period.dateTo).toMillis() <= DateTime.fromISO(version.date_to).toMillis()))
                );
            })[0];

            if (filtered?.id && filtered?.id !== selectedVersionId) {
                dispatch(storeSelectedVersionId(filtered.id));
            }
        }
    }, [rawVersionsData, lang, localCurrentOptions?.mainPeriod]);
};

export default usePrepareData;
