import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ height: number }>`
    width: 30%;
    height: ${(p) => p.height + 'px'};
    border: 1px solid var(--color-secondary-dark);
    margin-left: 10px;
    flex-grow: 1;
    padding: var(--space-xxxs);
    overflow: auto;
`;

export const Ul = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;
export const Li = styled.li`
    cursor: pointer;
    width: 100%;
    position: relative;
    overflow: hidden;
`;

export const Color = styled.div.attrs<{ width: string; bgColor: string }>((props) => ({
    style: {
        background: props.bgColor,
        width: props.width,
    },
}))<{ width: string; bgColor: string }>`
    border: 1px solid var(--color-white);
    padding: var(--space-xxxxs);
    overflow: visible;
    display: flex;
    height: 26px;
`;
export const Text = styled.div`
    color: var(--color-white);
    mix-blend-mode: difference;
    position: absolute;
    top: 2px;
    left: 10px;
    font-size: x-small;
`;
