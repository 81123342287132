import { FC, useEffect, useState } from 'react';
import { Header, HeaderTitle, StyledIcon, Table, Wrapper } from './styles';

import Body from './components/Body/Body';
import { IProps } from './interfaces';
import { IReportingObject } from '../../../../../../../../../General.interfaces';
import { default as TableHeader } from './components/Header/Header';
import filterReportingObjects from '../../../../../../../Filters/tools/filterReportingObjects';
import sortReportingObjects from './tools/sortReportingObjects';

/**
 * Компонент отображения карточки отчетного объекта
 * @param storeSelectedReportingObjectsTrans Функция для записи выбранных отчетных объектов
 * @param extendedReportingObjectsById Объект с расширенными выбранными отчетными объектми
 * @param selectedReportingObjects выбранные отчетные объекты
 * @param extendedReportingObject Текущий выбранный отчетный объект
 * @param dataObj2ProjectCategory Связи отчетных объектов с проектными категорями
 * @param tenant2FloorByTenantId  Связи арендаторов и этажей
 * @param tenant2ZoneByTenantId  Связи арендаторов и зон
 * @param intersectionsDataById Данные по пересечениям
 * @param reportingObjectsById Отчетные объекты, где ключ это id
 * @param reportingObjectsType Выбранный тип отчтеного объекта
 * @param mainDateRanges Периоды дат
 * @param daysInPeriod Количесвто дней в выбранном периоде
 * @param mainPeriod Основной период
 * @param isLoading Флаг загрузки
 * @param isError Флаг ошибки
 * @param filters все фильтры
 */
const Card: FC<IProps> = ({
    storeSelectedReportingObjectsTrans,
    extendedReportingObjectsById,
    selectedReportingObjects,
    extendedReportingObject,
    dataObj2ProjectCategory,
    tenant2FloorByTenantId,
    tenant2ZoneByTenantId,
    intersectionsDataById,
    reportingObjectsById,
    reportingObjectsType,
    mainDateRanges,
    daysInPeriod,
    mainPeriod,
    isLoading,
    isError,
    filters,
}) => {
    const [filteredReportingObjects, setFilteredReportingObjects] = useState<IReportingObject[]>([]);
    const [sortedReportingObjects, setSortedReportingObjects] = useState<IReportingObject[]>([]);

    useEffect(() => {
        setFilteredReportingObjects(
            extendedReportingObject.contraReportingObjects.map((element) => element.reportingObject),
        );
    }, [extendedReportingObject.contraReportingObjects]);

    useEffect(() => {
        setSortedReportingObjects(
            sortReportingObjects({
                reportingObjects: filteredReportingObjects,
                intersectionsDataById,
            }),
        );
    }, [intersectionsDataById, filteredReportingObjects]);

    useEffect(() => {
        setFilteredReportingObjects(
            filterReportingObjects({
                initialArray: extendedReportingObject.contraReportingObjects.map((element) => element.reportingObject),
                dataObj2ProjectCategory,
                tenant2FloorByTenantId,
                tenant2ZoneByTenantId,
                reportingObjectsById,
                mainDateRanges,
                mainPeriod,
                filters,
            }),
        );
    }, [
        dataObj2ProjectCategory,
        extendedReportingObject,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        filters,
    ]);

    const days = daysInPeriod || 1;
    const sumValue = intersectionsDataById
        ? Object.keys(intersectionsDataById).reduce((acc, id) => {
              const value = intersectionsDataById[id];
              if (value.intersection !== null) {
                  acc += value.intersection;
              }
              return acc;
          }, 0)
        : null;

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>{extendedReportingObject.reportingObject.name}</HeaderTitle>
                <StyledIcon color={extendedReportingObject.color} iconName={'FavoriteStarFill'} />
            </Header>
            <Table>
                <TableHeader
                    reportingObjectsType={reportingObjectsType}
                    isLoading={isLoading}
                    sumValue={sumValue}
                    isError={isError}
                    days={days}
                />
                <Body
                    storeSelectedReportingObjectsTrans={storeSelectedReportingObjectsTrans}
                    extendedReportingObjectsById={extendedReportingObjectsById}
                    selectedReportingObjects={selectedReportingObjects}
                    sortedReportingObjects={sortedReportingObjects}
                    intersectionsDataById={intersectionsDataById}
                    isLoading={isLoading}
                    isError={isError}
                    days={days}
                />
            </Table>
        </Wrapper>
    );
};

export default Card;
