export enum ColorsEnum {
    'index-0' = '#4C7EFF',
    'index-1' = '#FFA928',
    'index-2' = '#36CEFB',
    'index-3' = '#9859FF',
    'index-4' = '#4C9EA6',
    'index-5' = '#EDCD23',
    'index-6' = '#B30EAC',
    'index-7' = '#AE9CF1',
    'index-8' = '#5587C1',
    'index-9' = '#DC630C',
}

export enum AdditionalSeriesIds {
    Avergage = 'SeriesAverageLine',
    Trend = 'SeriesTrendLine',
    Remove = 'RemoveAdditionalSeries',
}

export enum AdditionalSeriesTypes {
    Trend = 'Trend',
    Avergage = 'Average',
}
