import React, { useMemo } from 'react';
import cn from 'classnames';
import { IMenuProps, ISection, ISelectSubsectionArgs, ISubsection } from '../../../../configurations';
import '../../../../styles/styles.scss';
import * as constants from '../../../../constants/constants';
import CollapseHeader from '../CollapseHeader';
import Item from '../Item';
import { DirectionalHint, ICalloutProps, TooltipDelay } from '@fluentui/react';
import { Collapse, List, ListItem, Tooltip } from '../../../../../../../lib/esm/components';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { useSelector } from 'react-redux';

const Menu: React.FC<IMenuProps> = ({
    menu,
    subsectionDidSelected,
    selectedSubsection,
    changeCollapsedSections,
    collapsedSections,
    lang,
    compact,
    ...props
}) => {
    const { user } = useSelector(generalReducerValues);
    const { DEFAULT_CLASS_SIDEBAR_LIST, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, DEFAULT_CLASS_SIDEBAR_DIVIDER } =
        constants;

    const listClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SIDEBAR_LIST]: true,
            [`${DEFAULT_CLASS_SIDEBAR_LIST}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: compact,
        });
    }, [DEFAULT_CLASS_SIDEBAR_LIST, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, compact]);

    const dividerClassName = React.useMemo(() => {
        return cn({
            [DEFAULT_CLASS_SIDEBAR_DIVIDER]: true,
            [`${DEFAULT_CLASS_SIDEBAR_DIVIDER}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: compact,
        });
    }, [DEFAULT_CLASS_SIDEBAR_DIVIDER, DEFAULT_SEPARATOR_MODIFICATION_PREFIX, compact]);

    const onToggleCollapse = (sectionName: string) => (collapsed: boolean) => {
        if (collapsedSections.includes(sectionName)) {
            changeCollapsedSections(collapsedSections?.filter((item) => item !== sectionName));
        } else {
            changeCollapsedSections([...collapsedSections, sectionName]);
        }
    };

    const onSelectSubsection =
        ({ subsectionId, servicePath, serviceName, serviceHost }: ISelectSubsectionArgs) =>
        () => {
            subsectionDidSelected && subsectionDidSelected({ subsectionId, servicePath, serviceName, serviceHost });
        };

    const tooltipProps = {
        calloutProps: { isBeakVisible: false, beakWidth: 0, gapSpace: -15 } as ICalloutProps,
        showTooltip: compact,
        directionalHint: DirectionalHint.rightCenter,
        delay: TooltipDelay.zero,
    };

    const sectionList = React.useCallback(
        (section: ISection) => {
            return section?.subsections.map((subsection: ISubsection, j: number) => {
                const active = selectedSubsection === `${section.names.en}:${subsection.names.en}`;
                return (
                    <Tooltip content={subsection.names[lang]} key={`${j}---${subsection.names.en}`} {...tooltipProps}>
                        <Item
                            compact={compact}
                            active={active}
                            key={`${j}---${subsection.names.en}`}
                            lang={lang}
                            subsection={subsection}
                            onClick={onSelectSubsection({
                                subsectionId: `${section.names.en}:${subsection.names.en}`,
                                servicePath: subsection.servicePath,
                                serviceName: section.serviceName,
                                serviceHost: section.serviceHost,
                            })}
                        />
                    </Tooltip>
                );
            });
        },
        [subsectionDidSelected, selectedSubsection, compact, lang],
    );

    const result = menu.map((sec, i) => {
        if (sec.type === 'section') {
            const section = sec as ISection;
            const isCollapsed = collapsedSections.includes(section.names.en);

            const collapseHeader = <CollapseHeader title={section.names[lang]} icon={'caret-down'} compact={compact} />;
            return (
                <React.Fragment key={`${i}--${section.names.en}`}>
                    <List addClasses={listClassName}>
                        <ListItem addClasses="sidebar-list-item" size="xs" listItemTextTruncate={true} />
                    </List>
                    <Collapse
                        hover="background-none"
                        collapseHeader={collapseHeader}
                        isCollapsed={isCollapsed}
                        toggleCollapse={onToggleCollapse(section.names.en)}
                    >
                        <List addClasses={listClassName}>{sectionList(section)}</List>
                    </Collapse>
                    <div className={dividerClassName}></div>
                </React.Fragment>
            );
        } else if (sec.type === 'subsection') {
            const subsection = sec as ISubsection;
            const active = selectedSubsection === `none:${subsection.names.en}`;
            return subsection?.serviceName && subsection?.serviceHost ? (
                <React.Fragment key={`${i}--${subsection.names.en}`}>
                    <List addClasses={listClassName}>
                        <Tooltip
                            content={subsection.names[lang]}
                            key={`${i}---${subsection.names.en}`}
                            {...tooltipProps}
                        >
                            <Item
                                compact={compact}
                                active={active}
                                lang={lang}
                                subsection={subsection as ISubsection}
                                onClick={onSelectSubsection({
                                    subsectionId: `none:${subsection.names.en}`,
                                    servicePath: subsection?.servicePath,
                                    serviceName: subsection?.serviceName,
                                    serviceHost: subsection?.serviceHost,
                                })}
                            />
                        </Tooltip>
                    </List>
                    <div className={dividerClassName}></div>
                </React.Fragment>
            ) : null;
        } else {
            return null;
        }
    });

    return <>{result}</>;
};

export default Menu;
