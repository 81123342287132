import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ColorBoxWrapper, Txt } from './styles';

const ColorBox: React.FC<{ color: string; onColorBoxClick: () => void }> = ({ color, onColorBoxClick }) => {
    const { t } = useTranslation();

    return (
        <ColorBoxWrapper>
            <Box color={color} onClick={onColorBoxClick}></Box>
            <Txt>{t('Event type color')}</Txt>
        </ColorBoxWrapper>
    );
};

export default ColorBox;
