import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { paymentAnalysisMetricsSelect } from '../../../constants/contsants';
import { Sales_PaymentAnalysis_Module_Reducer_Values } from '../../../reducer';
import {
    Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    storeRawMetricsData,
    storeTableOrderData,
} from '../reducer';
import generateQueryData from '../tools/generateQueryData';
import usePrepareData from './usePrepareData';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import { TRawMetricsData } from '../interfaces';

/**
 * Кастомный хук для получения данных
 */
export const useGetRawData = () => {
    const { moduleName } = useSelector(Sales_PaymentAnalysis_Module_Reducer_Values);
    const { selectedSubsetId, dataRefetchObject } = useSelector(
        Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    );
    const {
        token,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const fetchData = useRequestMetrics();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePrepareData();

    const comparePeriods = useMemo(() => {
        return localCurrentOptions?.compareDateRanges.map((element) => element.period);
    }, [localCurrentOptions?.compareDateRanges]);

    /** Получение сырых данных */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (
            localCurrentOptions?.[paymentAnalysisMetricsSelect] &&
            reportingObjectsByType['tenant']?.length &&
            localCurrentOptions?.compareDateRanges &&
            localCurrentOptions?.mainDateRanges &&
            localCurrentOptions?.comparePeriods &&
            localCurrentOptions?.mainPeriod &&
            selectedSubsetId &&
            comparePeriods &&
            token
        ) {
            const tenantsIds = reportingObjectsByType['tenant'].map((element) => element.id);

            const queryData = generateQueryData({
                selectedMetricId: localCurrentOptions[paymentAnalysisMetricsSelect][0].id,
                compareDateRanges: localCurrentOptions.compareDateRanges,
                comparePeriod: localCurrentOptions.comparePeriods[0],
                mainDateRanges: localCurrentOptions.mainDateRanges,
                mainPeriod: localCurrentOptions.mainPeriod,
                selectedSubsetId,
                metricLevel,
                tenantsIds,
                token,
            }).map((element) => ({ ...element, signal }));

            if (queryData.length) {
                dispatch(storeTableOrderData(null));
                dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

                fetchData(queryData).then((res: TServerResponse) => {
                    // dispatch(storeRawMetricsData(FiscalDataDataFish as unknown as TRawMetricsData));
                    handleServerResponse({
                        responseAnalyzer: responseAnalyzer,
                        success: storeRawMetricsData,
                        error: storeRawMetricsData,
                        dispatch,
                        res,
                    });
                });
            }
        }
        return () => controller.abort();
    }, [
        localCurrentOptions?.[paymentAnalysisMetricsSelect],
        localCurrentOptions?.comparePeriods,
        localCurrentOptions?.mainPeriod,
        JSON.stringify(comparePeriods),
        selectedSubsetId,
        dataRefetchObject,
        token,
    ]);
};

const FiscalDataDataFish = [
    [
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13062,
                        type: 'tenant',
                        name: 'Intimissimi (1-91)',
                        marker: '13062',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 3500,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13243,
                        type: 'tenant',
                        name: 'Koton (1-57-1-58)',
                        marker: '13243',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 33120,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 32150,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13126,
                        type: 'tenant',
                        name: 'Beeline (0-07)',
                        marker: '13126',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13446,
                        type: 'tenant',
                        name: 'KEDDO (2-58)',
                        marker: '13446',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 35,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13309,
                        type: 'tenant',
                        name: 'Happy Shoes (2-28)',
                        marker: '13309',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13438,
                        type: 'tenant',
                        name: 'M.Manzoni (№ 2-137)',
                        marker: '13438',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13447,
                        type: 'tenant',
                        name: 'HUGO (№ 1-123)',
                        marker: '13447',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13110,
                        type: 'tenant',
                        name: 'Tally weijl (2-79+2-80)',
                        marker: '13110',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10776,
                        type: 'tenant',
                        name: 'GLO (1-20)',
                        marker: '10776',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10777,
                        type: 'tenant',
                        name: 'VIVA express (1-12+1-13)',
                        marker: '10777',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13310,
                        type: 'tenant',
                        name: 'Mini City (2-31+2-32)',
                        marker: '13310',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13112,
                        type: 'tenant',
                        name: 'UM&H (2-79+2-80)',
                        marker: '13112',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13113,
                        type: 'tenant',
                        name: 'Dosso Dossi (2-90+2-91)',
                        marker: '13113',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13315,
                        type: 'tenant',
                        name: 'Penti (2-57)',
                        marker: '13315',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13035,
                        type: 'tenant',
                        name: 'Starbucks 2 (1-01)',
                        marker: '13035',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13439,
                        type: 'tenant',
                        name: 'Podium kass (№ 2-138)',
                        marker: '13439',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13038,
                        type: 'tenant',
                        name: 'CARPISA (1-114)',
                        marker: '13038',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13042,
                        type: 'tenant',
                        name: 'Hair Point (1-12+1-13)',
                        marker: '13042',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13044,
                        type: 'tenant',
                        name: 'Rebel Flowers (1-18)',
                        marker: '13044',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13045,
                        type: 'tenant',
                        name: 'Yves Rocher (1-18)',
                        marker: '13045',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13051,
                        type: 'tenant',
                        name: 'Avanti (1-19)',
                        marker: '13051',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13053,
                        type: 'tenant',
                        name: 'Сбербанк (1-22)',
                        marker: '13053',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13058,
                        type: 'tenant',
                        name: 'Delish (1-43)',
                        marker: '13058',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13063,
                        type: 'tenant',
                        name: 'THEFACESHOP (2-102)',
                        marker: '13063',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13064,
                        type: 'tenant',
                        name: 'W Key (2-102)',
                        marker: '13064',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13070,
                        type: 'tenant',
                        name: 'Kitikate (2-109)',
                        marker: '13070',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13071,
                        type: 'tenant',
                        name: 'Zadari.kz (2-115)',
                        marker: '13071',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13072,
                        type: 'tenant',
                        name: 'Gulliver (2-115)',
                        marker: '13072',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13311,
                        type: 'tenant',
                        name: 'Ocean Basket (2-50+2-51)',
                        marker: '13311',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13114,
                        type: 'tenant',
                        name: 'Askona (2-90+2-91)',
                        marker: '13114',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13127,
                        type: 'tenant',
                        name: 'Casa & More (0-08)',
                        marker: '13127',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13079,
                        type: 'tenant',
                        name: 'Wow,RITZY! (2-118)',
                        marker: '13079',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13440,
                        type: 'tenant',
                        name: 'Soda (№ 0-04)',
                        marker: '13440',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13047,
                        type: 'tenant',
                        name: 'Eterna (1-19)',
                        marker: '13047',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13055,
                        type: 'tenant',
                        name: 'Sadu (1-43)',
                        marker: '13055',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13065,
                        type: 'tenant',
                        name: 'Lego education (2-103)',
                        marker: '13065',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13066,
                        type: 'tenant',
                        name: 'adidas Kids (2-103)',
                        marker: '13066',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13067,
                        type: 'tenant',
                        name: 'Dosso Dossi Kids (2-105)',
                        marker: '13067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13068,
                        type: 'tenant',
                        name: 'Kelzin kids (2-105)',
                        marker: '13068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13069,
                        type: 'tenant',
                        name: "Carter's (2-109)",
                        marker: '13069',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13073,
                        type: 'tenant',
                        name: 'Moleskine (2-118)',
                        marker: '13073',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13082,
                        type: 'tenant',
                        name: 'Nature Republic (2-119)',
                        marker: '13082',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13084,
                        type: 'tenant',
                        name: 'Milavitsa (2-119)',
                        marker: '13084',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13087,
                        type: 'tenant',
                        name: 'Qazaq Republic (2-120)',
                        marker: '13087',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13090,
                        type: 'tenant',
                        name: 'Bronx and Banco (2-120)',
                        marker: '13090',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13093,
                        type: 'tenant',
                        name: 'Jasmine lingerie (2-123)',
                        marker: '13093',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13316,
                        type: 'tenant',
                        name: 'Josiny (2-58)',
                        marker: '13316',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13036,
                        type: 'tenant',
                        name: 'Mixit (1-114)',
                        marker: '13036',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13061,
                        type: 'tenant',
                        name: 'Isabel Garcia (1-91)',
                        marker: '13061',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13116,
                        type: 'tenant',
                        name: 'The Entertainer (2-95)',
                        marker: '13116',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13097,
                        type: 'tenant',
                        name: 'Le Carrousel (2-18)',
                        marker: '13097',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13123,
                        type: 'tenant',
                        name: 'Altel 4G, Tele 2 (0-04)',
                        marker: '13123',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13099,
                        type: 'tenant',
                        name: 'Reima (2-18)',
                        marker: '13099',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13120,
                        type: 'tenant',
                        name: 'Ателье Шебер (0-02)',
                        marker: '13120',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13102,
                        type: 'tenant',
                        name: 'Tutubi (2-22)',
                        marker: '13102',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13103,
                        type: 'tenant',
                        name: 'Coccodrillo (2-22)',
                        marker: '13103',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13105,
                        type: 'tenant',
                        name: 'TERRANOVA (2-29+2-30)',
                        marker: '13105',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13124,
                        type: 'tenant',
                        name: 'Astana Mega Studio (0-05)',
                        marker: '13124',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13128,
                        type: 'tenant',
                        name: 'Lux Optics (0-09)',
                        marker: '13128',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13129,
                        type: 'tenant',
                        name: 'Ловец снов (0-10)',
                        marker: '13129',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13130,
                        type: 'tenant',
                        name: 'Albion time (0-11)',
                        marker: '13130',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13133,
                        type: 'tenant',
                        name: 'Eco Clean (0-14)',
                        marker: '13133',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13144,
                        type: 'tenant',
                        name: 'Rinascimento (01-07)',
                        marker: '13144',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13134,
                        type: 'tenant',
                        name: 'RamNan (0-15/1)',
                        marker: '13134',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13136,
                        type: 'tenant',
                        name: 'Unimaster (0-17)',
                        marker: '13136',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13137,
                        type: 'tenant',
                        name: 'AutoCovers.kz (0-18)',
                        marker: '13137',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13141,
                        type: 'tenant',
                        name: 'New Yorker (1-02+1-03)',
                        marker: '13141',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13142,
                        type: 'tenant',
                        name: 'BEAUTYMANIA (1-04+1-05)',
                        marker: '13142',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13145,
                        type: 'tenant',
                        name: 'AVRORA (1-08+1-09)',
                        marker: '13145',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13147,
                        type: 'tenant',
                        name: 'Actual optic (1-102)',
                        marker: '13147',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13148,
                        type: 'tenant',
                        name: 'Geox (1-103)',
                        marker: '13148',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13149,
                        type: 'tenant',
                        name: 'Lee Wrangler (1-104)',
                        marker: '13149',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13150,
                        type: 'tenant',
                        name: 'Jack & Jones (1-105)',
                        marker: '13150',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13151,
                        type: 'tenant',
                        name: 'Avrora Lingery (1-106)',
                        marker: '13151',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13152,
                        type: 'tenant',
                        name: 'Lady Collection (1-107)',
                        marker: '13152',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13153,
                        type: 'tenant',
                        name: 'Timberland (1-108)',
                        marker: '13153',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13157,
                        type: 'tenant',
                        name: 'ЭПЛ.Якутские бриллианты (1-112)',
                        marker: '13157',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13165,
                        type: 'tenant',
                        name: 'NYX (1-121)',
                        marker: '13165',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13166,
                        type: 'tenant',
                        name: "MarcO'Polo (1-122)",
                        marker: '13166',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13167,
                        type: 'tenant',
                        name: 'Uterque (1-123+1-124)',
                        marker: '13167',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13170,
                        type: 'tenant',
                        name: 'Oysho (1-128+1-129+1-130+2-71/1)',
                        marker: '13170',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13171,
                        type: 'tenant',
                        name: 'MAC (1-131)',
                        marker: '13171',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13172,
                        type: 'tenant',
                        name: 'Swiss time (1-132)',
                        marker: '13172',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13173,
                        type: 'tenant',
                        name: 'Pull & Bear (1-134+1-135+1-136)',
                        marker: '13173',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13179,
                        type: 'tenant',
                        name: 'Calvin Klein Jeans (1-144)',
                        marker: '13179',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13196,
                        type: 'tenant',
                        name: 'ForteBank (1-21)',
                        marker: '13196',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13197,
                        type: 'tenant',
                        name: 'Beeline (1-23)',
                        marker: '13197',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13201,
                        type: 'tenant',
                        name: 'Viva (1-24+1-25)',
                        marker: '13201',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13231,
                        type: 'tenant',
                        name: 'Coffee BOOM (1-36+1-37)',
                        marker: '13231',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13233,
                        type: 'tenant',
                        name: 'LAZANIA (1-39+1-40)',
                        marker: '13233',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13234,
                        type: 'tenant',
                        name: 'Marrone Rosso (1-41+1-42)',
                        marker: '13234',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13235,
                        type: 'tenant',
                        name: 'Korean house (1-44+1-45)',
                        marker: '13235',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13238,
                        type: 'tenant',
                        name: 'Пивоварофф (1-50)',
                        marker: '13238',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13239,
                        type: 'tenant',
                        name: 'Лепим и Варим (1-51)',
                        marker: '13239',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13240,
                        type: 'tenant',
                        name: 'PAUL (1-52+1-53)',
                        marker: '13240',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13104,
                        type: 'tenant',
                        name: 'EVRIKUM (2-29+2-30)',
                        marker: '13104',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13118,
                        type: 'tenant',
                        name: 'Gaissina (2-95)',
                        marker: '13118',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13106,
                        type: 'tenant',
                        name: 'TOP STUDIO (2-72)',
                        marker: '13106',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13107,
                        type: 'tenant',
                        name: 'Chic (2-72)',
                        marker: '13107',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13108,
                        type: 'tenant',
                        name: 'Aizhan Assenbayeva (2-76)',
                        marker: '13108',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13138,
                        type: 'tenant',
                        name: 'Li ning (02-05)',
                        marker: '13138',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13109,
                        type: 'tenant',
                        name: 'Fagum (2-76)',
                        marker: '13109',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13122,
                        type: 'tenant',
                        name: 'Khan Mura (0-03)',
                        marker: '13122',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13125,
                        type: 'tenant',
                        name: 'Comtel 2 (0-06)',
                        marker: '13125',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13131,
                        type: 'tenant',
                        name: 'Tobacco Shop (0-12)',
                        marker: '13131',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13132,
                        type: 'tenant',
                        name: 'Премьер (0-13)',
                        marker: '13132',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13135,
                        type: 'tenant',
                        name: 'Phone Service (0-16)',
                        marker: '13135',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13139,
                        type: 'tenant',
                        name: 'Black Star Wear (02-06)',
                        marker: '13139',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13242,
                        type: 'tenant',
                        name: 'adidas (1-55-1-56)',
                        marker: '13242',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13140,
                        type: 'tenant',
                        name: 'Briolette (101/1)',
                        marker: '13140',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13146,
                        type: 'tenant',
                        name: 'The Body Shop (1-10)',
                        marker: '13146',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13154,
                        type: 'tenant',
                        name: 'Under Armour (1-109)',
                        marker: '13154',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13155,
                        type: 'tenant',
                        name: 'Kaztour (1-11)',
                        marker: '13155',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13156,
                        type: 'tenant',
                        name: 'Mango (1-110+1-111)',
                        marker: '13156',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13158,
                        type: 'tenant',
                        name: 'Viled (1-113)',
                        marker: '13158',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13159,
                        type: 'tenant',
                        name: 'Московский ювелирный завод (1-115)',
                        marker: '13159',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13160,
                        type: 'tenant',
                        name: 'Pandora (1-116)',
                        marker: '13160',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13161,
                        type: 'tenant',
                        name: 'Lacoste (1-117)',
                        marker: '13161',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13162,
                        type: 'tenant',
                        name: "Levi's (1-118)",
                        marker: '13162',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13163,
                        type: 'tenant',
                        name: 'Hyundai City Store Premium Astana (1-119)',
                        marker: '13163',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13164,
                        type: 'tenant',
                        name: 'US Polo (1-120)',
                        marker: '13164',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13168,
                        type: 'tenant',
                        name: 'Calvin klein (1-124)',
                        marker: '13168',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13169,
                        type: 'tenant',
                        name: 'Massimo Dutti (1-127+1-126+1-125)',
                        marker: '13169',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13174,
                        type: 'tenant',
                        name: 'Bershka (1-137+1-138+1-139+1-140)',
                        marker: '13174',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13175,
                        type: 'tenant',
                        name: 'the Saem (1-14)',
                        marker: '13175',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13176,
                        type: 'tenant',
                        name: 'GUESS (1-141)',
                        marker: '13176',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13177,
                        type: 'tenant',
                        name: 'Tommy Hilfiger (1-142)',
                        marker: '13177',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13178,
                        type: 'tenant',
                        name: 'Diesel (1-143)',
                        marker: '13178',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13180,
                        type: 'tenant',
                        name: '7 Life (1-145)',
                        marker: '13180',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13181,
                        type: 'tenant',
                        name: 'Swarovski (1-15)',
                        marker: '13181',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13189,
                        type: 'tenant',
                        name: 'Pharmacom (1-16)',
                        marker: '13189',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13202,
                        type: 'tenant',
                        name: 'Nickol (1-26)',
                        marker: '13202',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13203,
                        type: 'tenant',
                        name: 'Sony (1-27)',
                        marker: '13203',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13206,
                        type: 'tenant',
                        name: 'Интертоп (1-31)',
                        marker: '13206',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13207,
                        type: 'tenant',
                        name: 'Starbucks 1 (1-32)',
                        marker: '13207',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13208,
                        type: 'tenant',
                        name: 'Пинта бар (1-33+1-34+1-35)',
                        marker: '13208',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13232,
                        type: 'tenant',
                        name: 'Angel-In-Us 1 (1-38)',
                        marker: '13232',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13236,
                        type: 'tenant',
                        name: 'Rumi (1-46+1-47)',
                        marker: '13236',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13237,
                        type: 'tenant',
                        name: 'Якитория (1-48+1-49)',
                        marker: '13237',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13241,
                        type: 'tenant',
                        name: 'Nike (1-54)',
                        marker: '13241',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13244,
                        type: 'tenant',
                        name: 'Французский дом (1-59)',
                        marker: '13244',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13245,
                        type: 'tenant',
                        name: 'Angel-In-Us 2 (1-60)',
                        marker: '13245',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13246,
                        type: 'tenant',
                        name: 'Zara Home (1-61+1-62+1-63)',
                        marker: '13246',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13247,
                        type: 'tenant',
                        name: 'ZARA (1-64-1-71+2-72/1+2-73/1+2-70/1)',
                        marker: '13247',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13248,
                        type: 'tenant',
                        name: 'Stradivarius (1-72+1-73+1-74+2-74/1)',
                        marker: '13248',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13249,
                        type: 'tenant',
                        name: 'H&M (1-76-1-81+2-81/1)',
                        marker: '13249',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13250,
                        type: 'tenant',
                        name: 'Armani Exchange (1-82+1-83+1-84)',
                        marker: '13250',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13251,
                        type: 'tenant',
                        name: 'Walker (1-85)',
                        marker: '13251',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13252,
                        type: 'tenant',
                        name: 'Calzedonia (1-86)',
                        marker: '13252',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13253,
                        type: 'tenant',
                        name: 'IQOS (1-87)',
                        marker: '13253',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13313,
                        type: 'tenant',
                        name: 'Adika (2-55)',
                        marker: '13313',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13254,
                        type: 'tenant',
                        name: 'GLASMAN (1-88)',
                        marker: '13254',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13255,
                        type: 'tenant',
                        name: 'Fabiani (1-89)',
                        marker: '13255',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13314,
                        type: 'tenant',
                        name: 'Grazie (2-56)',
                        marker: '13314',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13256,
                        type: 'tenant',
                        name: 'MI (1-90)',
                        marker: '13256',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13257,
                        type: 'tenant',
                        name: 'Kanzler (1-92)',
                        marker: '13257',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13317,
                        type: 'tenant',
                        name: 'L-Shoes (2-59)',
                        marker: '13317',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13258,
                        type: 'tenant',
                        name: 'Jysan Bank (1-93)',
                        marker: '13258',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13259,
                        type: 'tenant',
                        name: 'GANT (1-94)',
                        marker: '13259',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13318,
                        type: 'tenant',
                        name: 'OSTIN (2-60+2-61)',
                        marker: '13318',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13260,
                        type: 'tenant',
                        name: 'Miniso (1-95)',
                        marker: '13260',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13286,
                        type: 'tenant',
                        name: 'PLUME (2-132)',
                        marker: '13286',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13261,
                        type: 'tenant',
                        name: 'iPoint (1-96+1-97)',
                        marker: '13261',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13319,
                        type: 'tenant',
                        name: 'Indigo (2-62)',
                        marker: '13319',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13262,
                        type: 'tenant',
                        name: 'Loccitane (1-98)',
                        marker: '13262',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13263,
                        type: 'tenant',
                        name: 'Halyk Bank (1-99+1-100+1-101)',
                        marker: '13263',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13320,
                        type: 'tenant',
                        name: 'Colins (2-63+2-64)',
                        marker: '13320',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13264,
                        type: 'tenant',
                        name: 'Black Star Burger (2-01+2-02)',
                        marker: '13264',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13265,
                        type: 'tenant',
                        name: 'Спортмастер (2-03+2-04)',
                        marker: '13265',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13321,
                        type: 'tenant',
                        name: 'DeFacto (2-65+2-66+2-67+2-68)',
                        marker: '13321',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13266,
                        type: 'tenant',
                        name: 'Imperial Home (2-07+2-08)',
                        marker: '13266',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13267,
                        type: 'tenant',
                        name: 'ТВОЕ (2-09+2-10)',
                        marker: '13267',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13322,
                        type: 'tenant',
                        name: 'Climber (2-69+2-70)',
                        marker: '13322',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13268,
                        type: 'tenant',
                        name: 'Kerasys Koreanshop (2-101)',
                        marker: '13268',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13269,
                        type: 'tenant',
                        name: 'Kawaii box (2-101/1)',
                        marker: '13269',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13323,
                        type: 'tenant',
                        name: 'Lichi (2-71)',
                        marker: '13323',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13270,
                        type: 'tenant',
                        name: 'Tiflani (2-104)',
                        marker: '13270',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13271,
                        type: 'tenant',
                        name: 'FLO (2-106+2-107+2-125+2-126)',
                        marker: '13271',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13324,
                        type: 'tenant',
                        name: 'Tucino (2-73)',
                        marker: '13324',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13272,
                        type: 'tenant',
                        name: 'Footie (2-108)',
                        marker: '13272',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13273,
                        type: 'tenant',
                        name: 'KARI (2-11+2-12)',
                        marker: '13273',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13325,
                        type: 'tenant',
                        name: 'Emilio Guido (2-74)',
                        marker: '13325',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13274,
                        type: 'tenant',
                        name: 'Orchestra (2-110+2-111)',
                        marker: '13274',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13275,
                        type: 'tenant',
                        name: 'OSTIN Kids (2-112)',
                        marker: '13275',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13326,
                        type: 'tenant',
                        name: 'SMAN (2-75)',
                        marker: '13326',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13276,
                        type: 'tenant',
                        name: 'De Salitto (2-113)',
                        marker: '13276',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13277,
                        type: 'tenant',
                        name: 'Babyboom (2-114)',
                        marker: '13277',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13327,
                        type: 'tenant',
                        name: 'Империя меха (2-77)',
                        marker: '13327',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13278,
                        type: 'tenant',
                        name: 'Adili (2-117)',
                        marker: '13278',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13279,
                        type: 'tenant',
                        name: 'NEXT (2-121+2-122)',
                        marker: '13279',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13328,
                        type: 'tenant',
                        name: 'Consolle (2-78)',
                        marker: '13328',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13280,
                        type: 'tenant',
                        name: 'Hey Baby (2-124)',
                        marker: '13280',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13281,
                        type: 'tenant',
                        name: 'La verna (2-127)',
                        marker: '13281',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13329,
                        type: 'tenant',
                        name: 'Gloria Jeans (2-81+2-82)',
                        marker: '13329',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13282,
                        type: 'tenant',
                        name: 'Mark Formelle (2-128)',
                        marker: '13282',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13283,
                        type: 'tenant',
                        name: 'CITY MEN (2-129)',
                        marker: '13283',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13330,
                        type: 'tenant',
                        name: 'Pasha (2-83+2-84)',
                        marker: '13330',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13284,
                        type: 'tenant',
                        name: 'Cossmo (2-13)',
                        marker: '13284',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13285,
                        type: 'tenant',
                        name: 'Respect (2-130+2-131)',
                        marker: '13285',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13287,
                        type: 'tenant',
                        name: 'The North Face (2-133+2-134+2-135)',
                        marker: '13287',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13332,
                        type: 'tenant',
                        name: 'SUMMIT SPORT (2-87)',
                        marker: '13332',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13288,
                        type: 'tenant',
                        name: 'Frato2 (2-136)',
                        marker: '13288',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13289,
                        type: 'tenant',
                        name: 'Кербез (2-137)',
                        marker: '13289',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13333,
                        type: 'tenant',
                        name: 'SKECHERS (2-88)',
                        marker: '13333',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13334,
                        type: 'tenant',
                        name: 'Шапо (2-89)',
                        marker: '13334',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13291,
                        type: 'tenant',
                        name: 'Dara by Gaissina (2-138)',
                        marker: '13291',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13290,
                        type: 'tenant',
                        name: 'Love Republic (2-139)',
                        marker: '13290',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13292,
                        type: 'tenant',
                        name: 'Mothercare (2-14+2-15)',
                        marker: '13292',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13335,
                        type: 'tenant',
                        name: 'KIMEX (2-92+2-93)',
                        marker: '13335',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13293,
                        type: 'tenant',
                        name: 'Madame Coco (2-140+2-141)',
                        marker: '13293',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13294,
                        type: 'tenant',
                        name: 'Zingal Riche (2-142)',
                        marker: '13294',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13295,
                        type: 'tenant',
                        name: 'KELZIN (2-143+2-144)',
                        marker: '13295',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13296,
                        type: 'tenant',
                        name: 'Vitacci (2-145)',
                        marker: '13296',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13297,
                        type: 'tenant',
                        name: 'Daniel Rizotto (2-146)',
                        marker: '13297',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13298,
                        type: 'tenant',
                        name: 'Алтын алан (2-147)',
                        marker: '13298',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13299,
                        type: 'tenant',
                        name: 'Kazyna Gold (2-148)',
                        marker: '13299',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13300,
                        type: 'tenant',
                        name: 'Deloras (2-16)',
                        marker: '13300',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13301,
                        type: 'tenant',
                        name: 'Zeyland (2-19)',
                        marker: '13301',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13303,
                        type: 'tenant',
                        name: 'DJI (2-20)',
                        marker: '13303',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13304,
                        type: 'tenant',
                        name: 'Mimioriki (2-21)',
                        marker: '13304',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13305,
                        type: 'tenant',
                        name: 'Wanex (2-23)',
                        marker: '13305',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13306,
                        type: 'tenant',
                        name: 'BEARRICHI (2-24)',
                        marker: '13306',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13307,
                        type: 'tenant',
                        name: 'Du pareil Au Meme (2-25)',
                        marker: '13307',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13336,
                        type: 'tenant',
                        name: 'BRO - Best Room Optic (2-94)',
                        marker: '13336',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13337,
                        type: 'tenant',
                        name: 'Shopogolik (2-96)',
                        marker: '13337',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13338,
                        type: 'tenant',
                        name: 'Basconi (2-97)',
                        marker: '13338',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13339,
                        type: 'tenant',
                        name: 'Injoy (2-98)',
                        marker: '13339',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13340,
                        type: 'tenant',
                        name: 'Tchibo (2-99+2-100)',
                        marker: '13340',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13341,
                        type: 'tenant',
                        name: 'Happylon (A1-02+A2-03)',
                        marker: '13341',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13345,
                        type: 'tenant',
                        name: 'SuperDry (№ 1-133)',
                        marker: '13345',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13348,
                        type: 'tenant',
                        name: 'Galmart (А1-01+1-17)',
                        marker: '13348',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13351,
                        type: 'tenant',
                        name: 'Технодом (А1-03)',
                        marker: '13351',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13353,
                        type: 'tenant',
                        name: 'MARWIN (А2-01+2-17)',
                        marker: '13353',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13354,
                        type: 'tenant',
                        name: 'Леонардо (А2-02+2-26)',
                        marker: '13354',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13355,
                        type: 'tenant',
                        name: 'Kango (А2-04)',
                        marker: '13355',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10778,
                        type: 'tenant',
                        name: 'LC Waikiki (1-28+1-29+1-30+2-27)',
                        marker: '10778',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13143,
                        type: 'tenant',
                        name: 'Samsonite (01-06)',
                        marker: '13143',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13302,
                        type: 'tenant',
                        name: 'Бургерная FARШ, Novikova и МИРАТОРГ (2-19-2-20)',
                        marker: '13302',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13331,
                        type: 'tenant',
                        name: 'Ceramiche Edelweiss (2-85,2-86)',
                        marker: '13331',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13537,
                        type: 'tenant',
                        name: 'Roberto bravo (№ 2-148)',
                        marker: '13537',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13342,
                        type: 'tenant',
                        name: 'Chaplin (A1-04+А2-05+2-41)',
                        marker: '13342',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14067,
                        type: 'tenant',
                        name: 'Ecohome (№ 2-85, 2-86)',
                        marker: '14067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14068,
                        type: 'tenant',
                        name: 'BAO Noodles Sushi Bar (№ 2-83, 2-84)',
                        marker: '14068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13062,
                        type: 'tenant',
                        name: 'Intimissimi (1-91)',
                        marker: '13062',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 3230,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13243,
                        type: 'tenant',
                        name: 'Koton (1-57-1-58)',
                        marker: '13243',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 3510,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350000,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13126,
                        type: 'tenant',
                        name: 'Beeline (0-07)',
                        marker: '13126',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 123350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13446,
                        type: 'tenant',
                        name: 'KEDDO (2-58)',
                        marker: '13446',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13309,
                        type: 'tenant',
                        name: 'Happy Shoes (2-28)',
                        marker: '13309',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13438,
                        type: 'tenant',
                        name: 'M.Manzoni (№ 2-137)',
                        marker: '13438',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13447,
                        type: 'tenant',
                        name: 'HUGO (№ 1-123)',
                        marker: '13447',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13110,
                        type: 'tenant',
                        name: 'Tally weijl (2-79+2-80)',
                        marker: '13110',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10776,
                        type: 'tenant',
                        name: 'GLO (1-20)',
                        marker: '10776',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10777,
                        type: 'tenant',
                        name: 'VIVA express (1-12+1-13)',
                        marker: '10777',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13310,
                        type: 'tenant',
                        name: 'Mini City (2-31+2-32)',
                        marker: '13310',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13112,
                        type: 'tenant',
                        name: 'UM&H (2-79+2-80)',
                        marker: '13112',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13113,
                        type: 'tenant',
                        name: 'Dosso Dossi (2-90+2-91)',
                        marker: '13113',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13315,
                        type: 'tenant',
                        name: 'Penti (2-57)',
                        marker: '13315',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13035,
                        type: 'tenant',
                        name: 'Starbucks 2 (1-01)',
                        marker: '13035',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13439,
                        type: 'tenant',
                        name: 'Podium kass (№ 2-138)',
                        marker: '13439',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13038,
                        type: 'tenant',
                        name: 'CARPISA (1-114)',
                        marker: '13038',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13042,
                        type: 'tenant',
                        name: 'Hair Point (1-12+1-13)',
                        marker: '13042',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13044,
                        type: 'tenant',
                        name: 'Rebel Flowers (1-18)',
                        marker: '13044',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13045,
                        type: 'tenant',
                        name: 'Yves Rocher (1-18)',
                        marker: '13045',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13051,
                        type: 'tenant',
                        name: 'Avanti (1-19)',
                        marker: '13051',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13053,
                        type: 'tenant',
                        name: 'Сбербанк (1-22)',
                        marker: '13053',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13058,
                        type: 'tenant',
                        name: 'Delish (1-43)',
                        marker: '13058',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13063,
                        type: 'tenant',
                        name: 'THEFACESHOP (2-102)',
                        marker: '13063',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13064,
                        type: 'tenant',
                        name: 'W Key (2-102)',
                        marker: '13064',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13070,
                        type: 'tenant',
                        name: 'Kitikate (2-109)',
                        marker: '13070',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13071,
                        type: 'tenant',
                        name: 'Zadari.kz (2-115)',
                        marker: '13071',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13072,
                        type: 'tenant',
                        name: 'Gulliver (2-115)',
                        marker: '13072',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13311,
                        type: 'tenant',
                        name: 'Ocean Basket (2-50+2-51)',
                        marker: '13311',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13114,
                        type: 'tenant',
                        name: 'Askona (2-90+2-91)',
                        marker: '13114',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13127,
                        type: 'tenant',
                        name: 'Casa & More (0-08)',
                        marker: '13127',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13079,
                        type: 'tenant',
                        name: 'Wow,RITZY! (2-118)',
                        marker: '13079',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13440,
                        type: 'tenant',
                        name: 'Soda (№ 0-04)',
                        marker: '13440',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13047,
                        type: 'tenant',
                        name: 'Eterna (1-19)',
                        marker: '13047',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13055,
                        type: 'tenant',
                        name: 'Sadu (1-43)',
                        marker: '13055',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13065,
                        type: 'tenant',
                        name: 'Lego education (2-103)',
                        marker: '13065',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13066,
                        type: 'tenant',
                        name: 'adidas Kids (2-103)',
                        marker: '13066',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13067,
                        type: 'tenant',
                        name: 'Dosso Dossi Kids (2-105)',
                        marker: '13067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13068,
                        type: 'tenant',
                        name: 'Kelzin kids (2-105)',
                        marker: '13068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13069,
                        type: 'tenant',
                        name: "Carter's (2-109)",
                        marker: '13069',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13073,
                        type: 'tenant',
                        name: 'Moleskine (2-118)',
                        marker: '13073',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13082,
                        type: 'tenant',
                        name: 'Nature Republic (2-119)',
                        marker: '13082',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13084,
                        type: 'tenant',
                        name: 'Milavitsa (2-119)',
                        marker: '13084',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13087,
                        type: 'tenant',
                        name: 'Qazaq Republic (2-120)',
                        marker: '13087',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13090,
                        type: 'tenant',
                        name: 'Bronx and Banco (2-120)',
                        marker: '13090',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13093,
                        type: 'tenant',
                        name: 'Jasmine lingerie (2-123)',
                        marker: '13093',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13316,
                        type: 'tenant',
                        name: 'Josiny (2-58)',
                        marker: '13316',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13036,
                        type: 'tenant',
                        name: 'Mixit (1-114)',
                        marker: '13036',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13061,
                        type: 'tenant',
                        name: 'Isabel Garcia (1-91)',
                        marker: '13061',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13116,
                        type: 'tenant',
                        name: 'The Entertainer (2-95)',
                        marker: '13116',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13097,
                        type: 'tenant',
                        name: 'Le Carrousel (2-18)',
                        marker: '13097',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13123,
                        type: 'tenant',
                        name: 'Altel 4G, Tele 2 (0-04)',
                        marker: '13123',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13099,
                        type: 'tenant',
                        name: 'Reima (2-18)',
                        marker: '13099',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13120,
                        type: 'tenant',
                        name: 'Ателье Шебер (0-02)',
                        marker: '13120',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13102,
                        type: 'tenant',
                        name: 'Tutubi (2-22)',
                        marker: '13102',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13103,
                        type: 'tenant',
                        name: 'Coccodrillo (2-22)',
                        marker: '13103',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13105,
                        type: 'tenant',
                        name: 'TERRANOVA (2-29+2-30)',
                        marker: '13105',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13124,
                        type: 'tenant',
                        name: 'Astana Mega Studio (0-05)',
                        marker: '13124',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13128,
                        type: 'tenant',
                        name: 'Lux Optics (0-09)',
                        marker: '13128',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13129,
                        type: 'tenant',
                        name: 'Ловец снов (0-10)',
                        marker: '13129',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13130,
                        type: 'tenant',
                        name: 'Albion time (0-11)',
                        marker: '13130',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13133,
                        type: 'tenant',
                        name: 'Eco Clean (0-14)',
                        marker: '13133',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13144,
                        type: 'tenant',
                        name: 'Rinascimento (01-07)',
                        marker: '13144',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13134,
                        type: 'tenant',
                        name: 'RamNan (0-15/1)',
                        marker: '13134',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13136,
                        type: 'tenant',
                        name: 'Unimaster (0-17)',
                        marker: '13136',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13137,
                        type: 'tenant',
                        name: 'AutoCovers.kz (0-18)',
                        marker: '13137',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13141,
                        type: 'tenant',
                        name: 'New Yorker (1-02+1-03)',
                        marker: '13141',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13142,
                        type: 'tenant',
                        name: 'BEAUTYMANIA (1-04+1-05)',
                        marker: '13142',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13145,
                        type: 'tenant',
                        name: 'AVRORA (1-08+1-09)',
                        marker: '13145',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13147,
                        type: 'tenant',
                        name: 'Actual optic (1-102)',
                        marker: '13147',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13148,
                        type: 'tenant',
                        name: 'Geox (1-103)',
                        marker: '13148',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13149,
                        type: 'tenant',
                        name: 'Lee Wrangler (1-104)',
                        marker: '13149',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13150,
                        type: 'tenant',
                        name: 'Jack & Jones (1-105)',
                        marker: '13150',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13151,
                        type: 'tenant',
                        name: 'Avrora Lingery (1-106)',
                        marker: '13151',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13152,
                        type: 'tenant',
                        name: 'Lady Collection (1-107)',
                        marker: '13152',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13153,
                        type: 'tenant',
                        name: 'Timberland (1-108)',
                        marker: '13153',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13157,
                        type: 'tenant',
                        name: 'ЭПЛ.Якутские бриллианты (1-112)',
                        marker: '13157',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13165,
                        type: 'tenant',
                        name: 'NYX (1-121)',
                        marker: '13165',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13166,
                        type: 'tenant',
                        name: "MarcO'Polo (1-122)",
                        marker: '13166',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13167,
                        type: 'tenant',
                        name: 'Uterque (1-123+1-124)',
                        marker: '13167',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13170,
                        type: 'tenant',
                        name: 'Oysho (1-128+1-129+1-130+2-71/1)',
                        marker: '13170',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13171,
                        type: 'tenant',
                        name: 'MAC (1-131)',
                        marker: '13171',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13172,
                        type: 'tenant',
                        name: 'Swiss time (1-132)',
                        marker: '13172',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13173,
                        type: 'tenant',
                        name: 'Pull & Bear (1-134+1-135+1-136)',
                        marker: '13173',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13179,
                        type: 'tenant',
                        name: 'Calvin Klein Jeans (1-144)',
                        marker: '13179',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13196,
                        type: 'tenant',
                        name: 'ForteBank (1-21)',
                        marker: '13196',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13197,
                        type: 'tenant',
                        name: 'Beeline (1-23)',
                        marker: '13197',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13201,
                        type: 'tenant',
                        name: 'Viva (1-24+1-25)',
                        marker: '13201',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13231,
                        type: 'tenant',
                        name: 'Coffee BOOM (1-36+1-37)',
                        marker: '13231',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13233,
                        type: 'tenant',
                        name: 'LAZANIA (1-39+1-40)',
                        marker: '13233',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13234,
                        type: 'tenant',
                        name: 'Marrone Rosso (1-41+1-42)',
                        marker: '13234',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13235,
                        type: 'tenant',
                        name: 'Korean house (1-44+1-45)',
                        marker: '13235',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13238,
                        type: 'tenant',
                        name: 'Пивоварофф (1-50)',
                        marker: '13238',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13239,
                        type: 'tenant',
                        name: 'Лепим и Варим (1-51)',
                        marker: '13239',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13240,
                        type: 'tenant',
                        name: 'PAUL (1-52+1-53)',
                        marker: '13240',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13104,
                        type: 'tenant',
                        name: 'EVRIKUM (2-29+2-30)',
                        marker: '13104',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13118,
                        type: 'tenant',
                        name: 'Gaissina (2-95)',
                        marker: '13118',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13106,
                        type: 'tenant',
                        name: 'TOP STUDIO (2-72)',
                        marker: '13106',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13107,
                        type: 'tenant',
                        name: 'Chic (2-72)',
                        marker: '13107',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13108,
                        type: 'tenant',
                        name: 'Aizhan Assenbayeva (2-76)',
                        marker: '13108',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13138,
                        type: 'tenant',
                        name: 'Li ning (02-05)',
                        marker: '13138',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13109,
                        type: 'tenant',
                        name: 'Fagum (2-76)',
                        marker: '13109',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13122,
                        type: 'tenant',
                        name: 'Khan Mura (0-03)',
                        marker: '13122',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13125,
                        type: 'tenant',
                        name: 'Comtel 2 (0-06)',
                        marker: '13125',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13131,
                        type: 'tenant',
                        name: 'Tobacco Shop (0-12)',
                        marker: '13131',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13132,
                        type: 'tenant',
                        name: 'Премьер (0-13)',
                        marker: '13132',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13135,
                        type: 'tenant',
                        name: 'Phone Service (0-16)',
                        marker: '13135',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13139,
                        type: 'tenant',
                        name: 'Black Star Wear (02-06)',
                        marker: '13139',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13242,
                        type: 'tenant',
                        name: 'adidas (1-55-1-56)',
                        marker: '13242',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13140,
                        type: 'tenant',
                        name: 'Briolette (101/1)',
                        marker: '13140',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13146,
                        type: 'tenant',
                        name: 'The Body Shop (1-10)',
                        marker: '13146',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13154,
                        type: 'tenant',
                        name: 'Under Armour (1-109)',
                        marker: '13154',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13155,
                        type: 'tenant',
                        name: 'Kaztour (1-11)',
                        marker: '13155',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13156,
                        type: 'tenant',
                        name: 'Mango (1-110+1-111)',
                        marker: '13156',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13158,
                        type: 'tenant',
                        name: 'Viled (1-113)',
                        marker: '13158',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13159,
                        type: 'tenant',
                        name: 'Московский ювелирный завод (1-115)',
                        marker: '13159',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13160,
                        type: 'tenant',
                        name: 'Pandora (1-116)',
                        marker: '13160',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13161,
                        type: 'tenant',
                        name: 'Lacoste (1-117)',
                        marker: '13161',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13162,
                        type: 'tenant',
                        name: "Levi's (1-118)",
                        marker: '13162',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13163,
                        type: 'tenant',
                        name: 'Hyundai City Store Premium Astana (1-119)',
                        marker: '13163',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13164,
                        type: 'tenant',
                        name: 'US Polo (1-120)',
                        marker: '13164',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13168,
                        type: 'tenant',
                        name: 'Calvin klein (1-124)',
                        marker: '13168',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13169,
                        type: 'tenant',
                        name: 'Massimo Dutti (1-127+1-126+1-125)',
                        marker: '13169',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13174,
                        type: 'tenant',
                        name: 'Bershka (1-137+1-138+1-139+1-140)',
                        marker: '13174',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13175,
                        type: 'tenant',
                        name: 'the Saem (1-14)',
                        marker: '13175',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13176,
                        type: 'tenant',
                        name: 'GUESS (1-141)',
                        marker: '13176',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13177,
                        type: 'tenant',
                        name: 'Tommy Hilfiger (1-142)',
                        marker: '13177',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13178,
                        type: 'tenant',
                        name: 'Diesel (1-143)',
                        marker: '13178',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13180,
                        type: 'tenant',
                        name: '7 Life (1-145)',
                        marker: '13180',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13181,
                        type: 'tenant',
                        name: 'Swarovski (1-15)',
                        marker: '13181',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13189,
                        type: 'tenant',
                        name: 'Pharmacom (1-16)',
                        marker: '13189',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13202,
                        type: 'tenant',
                        name: 'Nickol (1-26)',
                        marker: '13202',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13203,
                        type: 'tenant',
                        name: 'Sony (1-27)',
                        marker: '13203',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13206,
                        type: 'tenant',
                        name: 'Интертоп (1-31)',
                        marker: '13206',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13207,
                        type: 'tenant',
                        name: 'Starbucks 1 (1-32)',
                        marker: '13207',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13208,
                        type: 'tenant',
                        name: 'Пинта бар (1-33+1-34+1-35)',
                        marker: '13208',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13232,
                        type: 'tenant',
                        name: 'Angel-In-Us 1 (1-38)',
                        marker: '13232',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13236,
                        type: 'tenant',
                        name: 'Rumi (1-46+1-47)',
                        marker: '13236',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13237,
                        type: 'tenant',
                        name: 'Якитория (1-48+1-49)',
                        marker: '13237',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13241,
                        type: 'tenant',
                        name: 'Nike (1-54)',
                        marker: '13241',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13244,
                        type: 'tenant',
                        name: 'Французский дом (1-59)',
                        marker: '13244',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13245,
                        type: 'tenant',
                        name: 'Angel-In-Us 2 (1-60)',
                        marker: '13245',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13246,
                        type: 'tenant',
                        name: 'Zara Home (1-61+1-62+1-63)',
                        marker: '13246',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13247,
                        type: 'tenant',
                        name: 'ZARA (1-64-1-71+2-72/1+2-73/1+2-70/1)',
                        marker: '13247',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13248,
                        type: 'tenant',
                        name: 'Stradivarius (1-72+1-73+1-74+2-74/1)',
                        marker: '13248',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13249,
                        type: 'tenant',
                        name: 'H&M (1-76-1-81+2-81/1)',
                        marker: '13249',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13250,
                        type: 'tenant',
                        name: 'Armani Exchange (1-82+1-83+1-84)',
                        marker: '13250',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13251,
                        type: 'tenant',
                        name: 'Walker (1-85)',
                        marker: '13251',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13252,
                        type: 'tenant',
                        name: 'Calzedonia (1-86)',
                        marker: '13252',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13253,
                        type: 'tenant',
                        name: 'IQOS (1-87)',
                        marker: '13253',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13313,
                        type: 'tenant',
                        name: 'Adika (2-55)',
                        marker: '13313',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13254,
                        type: 'tenant',
                        name: 'GLASMAN (1-88)',
                        marker: '13254',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13255,
                        type: 'tenant',
                        name: 'Fabiani (1-89)',
                        marker: '13255',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13314,
                        type: 'tenant',
                        name: 'Grazie (2-56)',
                        marker: '13314',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13256,
                        type: 'tenant',
                        name: 'MI (1-90)',
                        marker: '13256',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13257,
                        type: 'tenant',
                        name: 'Kanzler (1-92)',
                        marker: '13257',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13317,
                        type: 'tenant',
                        name: 'L-Shoes (2-59)',
                        marker: '13317',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13258,
                        type: 'tenant',
                        name: 'Jysan Bank (1-93)',
                        marker: '13258',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13259,
                        type: 'tenant',
                        name: 'GANT (1-94)',
                        marker: '13259',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13318,
                        type: 'tenant',
                        name: 'OSTIN (2-60+2-61)',
                        marker: '13318',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13260,
                        type: 'tenant',
                        name: 'Miniso (1-95)',
                        marker: '13260',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13286,
                        type: 'tenant',
                        name: 'PLUME (2-132)',
                        marker: '13286',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13261,
                        type: 'tenant',
                        name: 'iPoint (1-96+1-97)',
                        marker: '13261',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13319,
                        type: 'tenant',
                        name: 'Indigo (2-62)',
                        marker: '13319',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13262,
                        type: 'tenant',
                        name: 'Loccitane (1-98)',
                        marker: '13262',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13263,
                        type: 'tenant',
                        name: 'Halyk Bank (1-99+1-100+1-101)',
                        marker: '13263',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13320,
                        type: 'tenant',
                        name: 'Colins (2-63+2-64)',
                        marker: '13320',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13264,
                        type: 'tenant',
                        name: 'Black Star Burger (2-01+2-02)',
                        marker: '13264',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13265,
                        type: 'tenant',
                        name: 'Спортмастер (2-03+2-04)',
                        marker: '13265',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13321,
                        type: 'tenant',
                        name: 'DeFacto (2-65+2-66+2-67+2-68)',
                        marker: '13321',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13266,
                        type: 'tenant',
                        name: 'Imperial Home (2-07+2-08)',
                        marker: '13266',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13267,
                        type: 'tenant',
                        name: 'ТВОЕ (2-09+2-10)',
                        marker: '13267',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13322,
                        type: 'tenant',
                        name: 'Climber (2-69+2-70)',
                        marker: '13322',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13268,
                        type: 'tenant',
                        name: 'Kerasys Koreanshop (2-101)',
                        marker: '13268',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13269,
                        type: 'tenant',
                        name: 'Kawaii box (2-101/1)',
                        marker: '13269',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13323,
                        type: 'tenant',
                        name: 'Lichi (2-71)',
                        marker: '13323',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13270,
                        type: 'tenant',
                        name: 'Tiflani (2-104)',
                        marker: '13270',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13271,
                        type: 'tenant',
                        name: 'FLO (2-106+2-107+2-125+2-126)',
                        marker: '13271',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13324,
                        type: 'tenant',
                        name: 'Tucino (2-73)',
                        marker: '13324',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13272,
                        type: 'tenant',
                        name: 'Footie (2-108)',
                        marker: '13272',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13273,
                        type: 'tenant',
                        name: 'KARI (2-11+2-12)',
                        marker: '13273',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13325,
                        type: 'tenant',
                        name: 'Emilio Guido (2-74)',
                        marker: '13325',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13274,
                        type: 'tenant',
                        name: 'Orchestra (2-110+2-111)',
                        marker: '13274',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13275,
                        type: 'tenant',
                        name: 'OSTIN Kids (2-112)',
                        marker: '13275',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13326,
                        type: 'tenant',
                        name: 'SMAN (2-75)',
                        marker: '13326',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13276,
                        type: 'tenant',
                        name: 'De Salitto (2-113)',
                        marker: '13276',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13277,
                        type: 'tenant',
                        name: 'Babyboom (2-114)',
                        marker: '13277',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13327,
                        type: 'tenant',
                        name: 'Империя меха (2-77)',
                        marker: '13327',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13278,
                        type: 'tenant',
                        name: 'Adili (2-117)',
                        marker: '13278',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13279,
                        type: 'tenant',
                        name: 'NEXT (2-121+2-122)',
                        marker: '13279',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13328,
                        type: 'tenant',
                        name: 'Consolle (2-78)',
                        marker: '13328',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13280,
                        type: 'tenant',
                        name: 'Hey Baby (2-124)',
                        marker: '13280',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13281,
                        type: 'tenant',
                        name: 'La verna (2-127)',
                        marker: '13281',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13329,
                        type: 'tenant',
                        name: 'Gloria Jeans (2-81+2-82)',
                        marker: '13329',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13282,
                        type: 'tenant',
                        name: 'Mark Formelle (2-128)',
                        marker: '13282',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13283,
                        type: 'tenant',
                        name: 'CITY MEN (2-129)',
                        marker: '13283',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13330,
                        type: 'tenant',
                        name: 'Pasha (2-83+2-84)',
                        marker: '13330',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13284,
                        type: 'tenant',
                        name: 'Cossmo (2-13)',
                        marker: '13284',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13285,
                        type: 'tenant',
                        name: 'Respect (2-130+2-131)',
                        marker: '13285',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13287,
                        type: 'tenant',
                        name: 'The North Face (2-133+2-134+2-135)',
                        marker: '13287',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13332,
                        type: 'tenant',
                        name: 'SUMMIT SPORT (2-87)',
                        marker: '13332',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13288,
                        type: 'tenant',
                        name: 'Frato2 (2-136)',
                        marker: '13288',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13289,
                        type: 'tenant',
                        name: 'Кербез (2-137)',
                        marker: '13289',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13333,
                        type: 'tenant',
                        name: 'SKECHERS (2-88)',
                        marker: '13333',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13334,
                        type: 'tenant',
                        name: 'Шапо (2-89)',
                        marker: '13334',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13291,
                        type: 'tenant',
                        name: 'Dara by Gaissina (2-138)',
                        marker: '13291',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13290,
                        type: 'tenant',
                        name: 'Love Republic (2-139)',
                        marker: '13290',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13292,
                        type: 'tenant',
                        name: 'Mothercare (2-14+2-15)',
                        marker: '13292',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13335,
                        type: 'tenant',
                        name: 'KIMEX (2-92+2-93)',
                        marker: '13335',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13293,
                        type: 'tenant',
                        name: 'Madame Coco (2-140+2-141)',
                        marker: '13293',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13294,
                        type: 'tenant',
                        name: 'Zingal Riche (2-142)',
                        marker: '13294',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13295,
                        type: 'tenant',
                        name: 'KELZIN (2-143+2-144)',
                        marker: '13295',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13296,
                        type: 'tenant',
                        name: 'Vitacci (2-145)',
                        marker: '13296',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13297,
                        type: 'tenant',
                        name: 'Daniel Rizotto (2-146)',
                        marker: '13297',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13298,
                        type: 'tenant',
                        name: 'Алтын алан (2-147)',
                        marker: '13298',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13299,
                        type: 'tenant',
                        name: 'Kazyna Gold (2-148)',
                        marker: '13299',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13300,
                        type: 'tenant',
                        name: 'Deloras (2-16)',
                        marker: '13300',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13301,
                        type: 'tenant',
                        name: 'Zeyland (2-19)',
                        marker: '13301',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13303,
                        type: 'tenant',
                        name: 'DJI (2-20)',
                        marker: '13303',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13304,
                        type: 'tenant',
                        name: 'Mimioriki (2-21)',
                        marker: '13304',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13305,
                        type: 'tenant',
                        name: 'Wanex (2-23)',
                        marker: '13305',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13306,
                        type: 'tenant',
                        name: 'BEARRICHI (2-24)',
                        marker: '13306',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13307,
                        type: 'tenant',
                        name: 'Du pareil Au Meme (2-25)',
                        marker: '13307',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13336,
                        type: 'tenant',
                        name: 'BRO - Best Room Optic (2-94)',
                        marker: '13336',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13337,
                        type: 'tenant',
                        name: 'Shopogolik (2-96)',
                        marker: '13337',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13338,
                        type: 'tenant',
                        name: 'Basconi (2-97)',
                        marker: '13338',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13339,
                        type: 'tenant',
                        name: 'Injoy (2-98)',
                        marker: '13339',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13340,
                        type: 'tenant',
                        name: 'Tchibo (2-99+2-100)',
                        marker: '13340',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13341,
                        type: 'tenant',
                        name: 'Happylon (A1-02+A2-03)',
                        marker: '13341',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13345,
                        type: 'tenant',
                        name: 'SuperDry (№ 1-133)',
                        marker: '13345',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13348,
                        type: 'tenant',
                        name: 'Galmart (А1-01+1-17)',
                        marker: '13348',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13351,
                        type: 'tenant',
                        name: 'Технодом (А1-03)',
                        marker: '13351',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13353,
                        type: 'tenant',
                        name: 'MARWIN (А2-01+2-17)',
                        marker: '13353',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13354,
                        type: 'tenant',
                        name: 'Леонардо (А2-02+2-26)',
                        marker: '13354',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13355,
                        type: 'tenant',
                        name: 'Kango (А2-04)',
                        marker: '13355',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10778,
                        type: 'tenant',
                        name: 'LC Waikiki (1-28+1-29+1-30+2-27)',
                        marker: '10778',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13143,
                        type: 'tenant',
                        name: 'Samsonite (01-06)',
                        marker: '13143',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13302,
                        type: 'tenant',
                        name: 'Бургерная FARШ, Novikova и МИРАТОРГ (2-19-2-20)',
                        marker: '13302',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13331,
                        type: 'tenant',
                        name: 'Ceramiche Edelweiss (2-85,2-86)',
                        marker: '13331',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13537,
                        type: 'tenant',
                        name: 'Roberto bravo (№ 2-148)',
                        marker: '13537',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13342,
                        type: 'tenant',
                        name: 'Chaplin (A1-04+А2-05+2-41)',
                        marker: '13342',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14067,
                        type: 'tenant',
                        name: 'Ecohome (№ 2-85, 2-86)',
                        marker: '14067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:true:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14068,
                        type: 'tenant',
                        name: 'BAO Noodles Sushi Bar (№ 2-83, 2-84)',
                        marker: '14068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13062,
                        type: 'tenant',
                        name: 'Intimissimi (1-91)',
                        marker: '13062',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 3123500,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13243,
                        type: 'tenant',
                        name: 'Koton (1-57-1-58)',
                        marker: '13243',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 3510,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 351230,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13126,
                        type: 'tenant',
                        name: 'Beeline (0-07)',
                        marker: '13126',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 35011,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13446,
                        type: 'tenant',
                        name: 'KEDDO (2-58)',
                        marker: '13446',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 1350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13309,
                        type: 'tenant',
                        name: 'Happy Shoes (2-28)',
                        marker: '13309',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13438,
                        type: 'tenant',
                        name: 'M.Manzoni (№ 2-137)',
                        marker: '13438',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13447,
                        type: 'tenant',
                        name: 'HUGO (№ 1-123)',
                        marker: '13447',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13110,
                        type: 'tenant',
                        name: 'Tally weijl (2-79+2-80)',
                        marker: '13110',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10776,
                        type: 'tenant',
                        name: 'GLO (1-20)',
                        marker: '10776',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10777,
                        type: 'tenant',
                        name: 'VIVA express (1-12+1-13)',
                        marker: '10777',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13310,
                        type: 'tenant',
                        name: 'Mini City (2-31+2-32)',
                        marker: '13310',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13112,
                        type: 'tenant',
                        name: 'UM&H (2-79+2-80)',
                        marker: '13112',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13113,
                        type: 'tenant',
                        name: 'Dosso Dossi (2-90+2-91)',
                        marker: '13113',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13315,
                        type: 'tenant',
                        name: 'Penti (2-57)',
                        marker: '13315',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13035,
                        type: 'tenant',
                        name: 'Starbucks 2 (1-01)',
                        marker: '13035',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13439,
                        type: 'tenant',
                        name: 'Podium kass (№ 2-138)',
                        marker: '13439',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13038,
                        type: 'tenant',
                        name: 'CARPISA (1-114)',
                        marker: '13038',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13042,
                        type: 'tenant',
                        name: 'Hair Point (1-12+1-13)',
                        marker: '13042',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13044,
                        type: 'tenant',
                        name: 'Rebel Flowers (1-18)',
                        marker: '13044',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13045,
                        type: 'tenant',
                        name: 'Yves Rocher (1-18)',
                        marker: '13045',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13051,
                        type: 'tenant',
                        name: 'Avanti (1-19)',
                        marker: '13051',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13053,
                        type: 'tenant',
                        name: 'Сбербанк (1-22)',
                        marker: '13053',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13058,
                        type: 'tenant',
                        name: 'Delish (1-43)',
                        marker: '13058',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13063,
                        type: 'tenant',
                        name: 'THEFACESHOP (2-102)',
                        marker: '13063',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13064,
                        type: 'tenant',
                        name: 'W Key (2-102)',
                        marker: '13064',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13070,
                        type: 'tenant',
                        name: 'Kitikate (2-109)',
                        marker: '13070',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13071,
                        type: 'tenant',
                        name: 'Zadari.kz (2-115)',
                        marker: '13071',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13072,
                        type: 'tenant',
                        name: 'Gulliver (2-115)',
                        marker: '13072',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13311,
                        type: 'tenant',
                        name: 'Ocean Basket (2-50+2-51)',
                        marker: '13311',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13114,
                        type: 'tenant',
                        name: 'Askona (2-90+2-91)',
                        marker: '13114',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13127,
                        type: 'tenant',
                        name: 'Casa & More (0-08)',
                        marker: '13127',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13079,
                        type: 'tenant',
                        name: 'Wow,RITZY! (2-118)',
                        marker: '13079',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13440,
                        type: 'tenant',
                        name: 'Soda (№ 0-04)',
                        marker: '13440',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13047,
                        type: 'tenant',
                        name: 'Eterna (1-19)',
                        marker: '13047',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13055,
                        type: 'tenant',
                        name: 'Sadu (1-43)',
                        marker: '13055',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13065,
                        type: 'tenant',
                        name: 'Lego education (2-103)',
                        marker: '13065',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13066,
                        type: 'tenant',
                        name: 'adidas Kids (2-103)',
                        marker: '13066',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13067,
                        type: 'tenant',
                        name: 'Dosso Dossi Kids (2-105)',
                        marker: '13067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13068,
                        type: 'tenant',
                        name: 'Kelzin kids (2-105)',
                        marker: '13068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13069,
                        type: 'tenant',
                        name: "Carter's (2-109)",
                        marker: '13069',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13073,
                        type: 'tenant',
                        name: 'Moleskine (2-118)',
                        marker: '13073',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13082,
                        type: 'tenant',
                        name: 'Nature Republic (2-119)',
                        marker: '13082',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13084,
                        type: 'tenant',
                        name: 'Milavitsa (2-119)',
                        marker: '13084',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13087,
                        type: 'tenant',
                        name: 'Qazaq Republic (2-120)',
                        marker: '13087',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13090,
                        type: 'tenant',
                        name: 'Bronx and Banco (2-120)',
                        marker: '13090',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13093,
                        type: 'tenant',
                        name: 'Jasmine lingerie (2-123)',
                        marker: '13093',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13316,
                        type: 'tenant',
                        name: 'Josiny (2-58)',
                        marker: '13316',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13036,
                        type: 'tenant',
                        name: 'Mixit (1-114)',
                        marker: '13036',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13061,
                        type: 'tenant',
                        name: 'Isabel Garcia (1-91)',
                        marker: '13061',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13116,
                        type: 'tenant',
                        name: 'The Entertainer (2-95)',
                        marker: '13116',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13097,
                        type: 'tenant',
                        name: 'Le Carrousel (2-18)',
                        marker: '13097',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13123,
                        type: 'tenant',
                        name: 'Altel 4G, Tele 2 (0-04)',
                        marker: '13123',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13099,
                        type: 'tenant',
                        name: 'Reima (2-18)',
                        marker: '13099',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13120,
                        type: 'tenant',
                        name: 'Ателье Шебер (0-02)',
                        marker: '13120',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13102,
                        type: 'tenant',
                        name: 'Tutubi (2-22)',
                        marker: '13102',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13103,
                        type: 'tenant',
                        name: 'Coccodrillo (2-22)',
                        marker: '13103',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13105,
                        type: 'tenant',
                        name: 'TERRANOVA (2-29+2-30)',
                        marker: '13105',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13124,
                        type: 'tenant',
                        name: 'Astana Mega Studio (0-05)',
                        marker: '13124',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13128,
                        type: 'tenant',
                        name: 'Lux Optics (0-09)',
                        marker: '13128',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13129,
                        type: 'tenant',
                        name: 'Ловец снов (0-10)',
                        marker: '13129',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13130,
                        type: 'tenant',
                        name: 'Albion time (0-11)',
                        marker: '13130',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13133,
                        type: 'tenant',
                        name: 'Eco Clean (0-14)',
                        marker: '13133',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13144,
                        type: 'tenant',
                        name: 'Rinascimento (01-07)',
                        marker: '13144',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13134,
                        type: 'tenant',
                        name: 'RamNan (0-15/1)',
                        marker: '13134',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13136,
                        type: 'tenant',
                        name: 'Unimaster (0-17)',
                        marker: '13136',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13137,
                        type: 'tenant',
                        name: 'AutoCovers.kz (0-18)',
                        marker: '13137',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13141,
                        type: 'tenant',
                        name: 'New Yorker (1-02+1-03)',
                        marker: '13141',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13142,
                        type: 'tenant',
                        name: 'BEAUTYMANIA (1-04+1-05)',
                        marker: '13142',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13145,
                        type: 'tenant',
                        name: 'AVRORA (1-08+1-09)',
                        marker: '13145',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13147,
                        type: 'tenant',
                        name: 'Actual optic (1-102)',
                        marker: '13147',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13148,
                        type: 'tenant',
                        name: 'Geox (1-103)',
                        marker: '13148',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13149,
                        type: 'tenant',
                        name: 'Lee Wrangler (1-104)',
                        marker: '13149',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13150,
                        type: 'tenant',
                        name: 'Jack & Jones (1-105)',
                        marker: '13150',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13151,
                        type: 'tenant',
                        name: 'Avrora Lingery (1-106)',
                        marker: '13151',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13152,
                        type: 'tenant',
                        name: 'Lady Collection (1-107)',
                        marker: '13152',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13153,
                        type: 'tenant',
                        name: 'Timberland (1-108)',
                        marker: '13153',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13157,
                        type: 'tenant',
                        name: 'ЭПЛ.Якутские бриллианты (1-112)',
                        marker: '13157',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13165,
                        type: 'tenant',
                        name: 'NYX (1-121)',
                        marker: '13165',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13166,
                        type: 'tenant',
                        name: "MarcO'Polo (1-122)",
                        marker: '13166',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13167,
                        type: 'tenant',
                        name: 'Uterque (1-123+1-124)',
                        marker: '13167',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13170,
                        type: 'tenant',
                        name: 'Oysho (1-128+1-129+1-130+2-71/1)',
                        marker: '13170',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13171,
                        type: 'tenant',
                        name: 'MAC (1-131)',
                        marker: '13171',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13172,
                        type: 'tenant',
                        name: 'Swiss time (1-132)',
                        marker: '13172',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13173,
                        type: 'tenant',
                        name: 'Pull & Bear (1-134+1-135+1-136)',
                        marker: '13173',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13179,
                        type: 'tenant',
                        name: 'Calvin Klein Jeans (1-144)',
                        marker: '13179',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13196,
                        type: 'tenant',
                        name: 'ForteBank (1-21)',
                        marker: '13196',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13197,
                        type: 'tenant',
                        name: 'Beeline (1-23)',
                        marker: '13197',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13201,
                        type: 'tenant',
                        name: 'Viva (1-24+1-25)',
                        marker: '13201',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13231,
                        type: 'tenant',
                        name: 'Coffee BOOM (1-36+1-37)',
                        marker: '13231',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13233,
                        type: 'tenant',
                        name: 'LAZANIA (1-39+1-40)',
                        marker: '13233',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13234,
                        type: 'tenant',
                        name: 'Marrone Rosso (1-41+1-42)',
                        marker: '13234',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13235,
                        type: 'tenant',
                        name: 'Korean house (1-44+1-45)',
                        marker: '13235',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13238,
                        type: 'tenant',
                        name: 'Пивоварофф (1-50)',
                        marker: '13238',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13239,
                        type: 'tenant',
                        name: 'Лепим и Варим (1-51)',
                        marker: '13239',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13240,
                        type: 'tenant',
                        name: 'PAUL (1-52+1-53)',
                        marker: '13240',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13104,
                        type: 'tenant',
                        name: 'EVRIKUM (2-29+2-30)',
                        marker: '13104',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13118,
                        type: 'tenant',
                        name: 'Gaissina (2-95)',
                        marker: '13118',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13106,
                        type: 'tenant',
                        name: 'TOP STUDIO (2-72)',
                        marker: '13106',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13107,
                        type: 'tenant',
                        name: 'Chic (2-72)',
                        marker: '13107',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13108,
                        type: 'tenant',
                        name: 'Aizhan Assenbayeva (2-76)',
                        marker: '13108',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13138,
                        type: 'tenant',
                        name: 'Li ning (02-05)',
                        marker: '13138',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13109,
                        type: 'tenant',
                        name: 'Fagum (2-76)',
                        marker: '13109',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13122,
                        type: 'tenant',
                        name: 'Khan Mura (0-03)',
                        marker: '13122',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13125,
                        type: 'tenant',
                        name: 'Comtel 2 (0-06)',
                        marker: '13125',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13131,
                        type: 'tenant',
                        name: 'Tobacco Shop (0-12)',
                        marker: '13131',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13132,
                        type: 'tenant',
                        name: 'Премьер (0-13)',
                        marker: '13132',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13135,
                        type: 'tenant',
                        name: 'Phone Service (0-16)',
                        marker: '13135',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13139,
                        type: 'tenant',
                        name: 'Black Star Wear (02-06)',
                        marker: '13139',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13242,
                        type: 'tenant',
                        name: 'adidas (1-55-1-56)',
                        marker: '13242',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13140,
                        type: 'tenant',
                        name: 'Briolette (101/1)',
                        marker: '13140',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13146,
                        type: 'tenant',
                        name: 'The Body Shop (1-10)',
                        marker: '13146',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13154,
                        type: 'tenant',
                        name: 'Under Armour (1-109)',
                        marker: '13154',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13155,
                        type: 'tenant',
                        name: 'Kaztour (1-11)',
                        marker: '13155',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13156,
                        type: 'tenant',
                        name: 'Mango (1-110+1-111)',
                        marker: '13156',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13158,
                        type: 'tenant',
                        name: 'Viled (1-113)',
                        marker: '13158',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13159,
                        type: 'tenant',
                        name: 'Московский ювелирный завод (1-115)',
                        marker: '13159',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13160,
                        type: 'tenant',
                        name: 'Pandora (1-116)',
                        marker: '13160',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13161,
                        type: 'tenant',
                        name: 'Lacoste (1-117)',
                        marker: '13161',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13162,
                        type: 'tenant',
                        name: "Levi's (1-118)",
                        marker: '13162',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13163,
                        type: 'tenant',
                        name: 'Hyundai City Store Premium Astana (1-119)',
                        marker: '13163',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13164,
                        type: 'tenant',
                        name: 'US Polo (1-120)',
                        marker: '13164',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13168,
                        type: 'tenant',
                        name: 'Calvin klein (1-124)',
                        marker: '13168',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13169,
                        type: 'tenant',
                        name: 'Massimo Dutti (1-127+1-126+1-125)',
                        marker: '13169',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13174,
                        type: 'tenant',
                        name: 'Bershka (1-137+1-138+1-139+1-140)',
                        marker: '13174',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13175,
                        type: 'tenant',
                        name: 'the Saem (1-14)',
                        marker: '13175',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13176,
                        type: 'tenant',
                        name: 'GUESS (1-141)',
                        marker: '13176',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13177,
                        type: 'tenant',
                        name: 'Tommy Hilfiger (1-142)',
                        marker: '13177',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13178,
                        type: 'tenant',
                        name: 'Diesel (1-143)',
                        marker: '13178',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13180,
                        type: 'tenant',
                        name: '7 Life (1-145)',
                        marker: '13180',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13181,
                        type: 'tenant',
                        name: 'Swarovski (1-15)',
                        marker: '13181',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13189,
                        type: 'tenant',
                        name: 'Pharmacom (1-16)',
                        marker: '13189',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13202,
                        type: 'tenant',
                        name: 'Nickol (1-26)',
                        marker: '13202',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13203,
                        type: 'tenant',
                        name: 'Sony (1-27)',
                        marker: '13203',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13206,
                        type: 'tenant',
                        name: 'Интертоп (1-31)',
                        marker: '13206',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13207,
                        type: 'tenant',
                        name: 'Starbucks 1 (1-32)',
                        marker: '13207',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13208,
                        type: 'tenant',
                        name: 'Пинта бар (1-33+1-34+1-35)',
                        marker: '13208',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13232,
                        type: 'tenant',
                        name: 'Angel-In-Us 1 (1-38)',
                        marker: '13232',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13236,
                        type: 'tenant',
                        name: 'Rumi (1-46+1-47)',
                        marker: '13236',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13237,
                        type: 'tenant',
                        name: 'Якитория (1-48+1-49)',
                        marker: '13237',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13241,
                        type: 'tenant',
                        name: 'Nike (1-54)',
                        marker: '13241',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13244,
                        type: 'tenant',
                        name: 'Французский дом (1-59)',
                        marker: '13244',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13245,
                        type: 'tenant',
                        name: 'Angel-In-Us 2 (1-60)',
                        marker: '13245',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13246,
                        type: 'tenant',
                        name: 'Zara Home (1-61+1-62+1-63)',
                        marker: '13246',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13247,
                        type: 'tenant',
                        name: 'ZARA (1-64-1-71+2-72/1+2-73/1+2-70/1)',
                        marker: '13247',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13248,
                        type: 'tenant',
                        name: 'Stradivarius (1-72+1-73+1-74+2-74/1)',
                        marker: '13248',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13249,
                        type: 'tenant',
                        name: 'H&M (1-76-1-81+2-81/1)',
                        marker: '13249',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13250,
                        type: 'tenant',
                        name: 'Armani Exchange (1-82+1-83+1-84)',
                        marker: '13250',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13251,
                        type: 'tenant',
                        name: 'Walker (1-85)',
                        marker: '13251',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13252,
                        type: 'tenant',
                        name: 'Calzedonia (1-86)',
                        marker: '13252',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13253,
                        type: 'tenant',
                        name: 'IQOS (1-87)',
                        marker: '13253',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13313,
                        type: 'tenant',
                        name: 'Adika (2-55)',
                        marker: '13313',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13254,
                        type: 'tenant',
                        name: 'GLASMAN (1-88)',
                        marker: '13254',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13255,
                        type: 'tenant',
                        name: 'Fabiani (1-89)',
                        marker: '13255',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13314,
                        type: 'tenant',
                        name: 'Grazie (2-56)',
                        marker: '13314',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13256,
                        type: 'tenant',
                        name: 'MI (1-90)',
                        marker: '13256',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13257,
                        type: 'tenant',
                        name: 'Kanzler (1-92)',
                        marker: '13257',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13317,
                        type: 'tenant',
                        name: 'L-Shoes (2-59)',
                        marker: '13317',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13258,
                        type: 'tenant',
                        name: 'Jysan Bank (1-93)',
                        marker: '13258',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13259,
                        type: 'tenant',
                        name: 'GANT (1-94)',
                        marker: '13259',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13318,
                        type: 'tenant',
                        name: 'OSTIN (2-60+2-61)',
                        marker: '13318',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13260,
                        type: 'tenant',
                        name: 'Miniso (1-95)',
                        marker: '13260',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13286,
                        type: 'tenant',
                        name: 'PLUME (2-132)',
                        marker: '13286',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13261,
                        type: 'tenant',
                        name: 'iPoint (1-96+1-97)',
                        marker: '13261',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13319,
                        type: 'tenant',
                        name: 'Indigo (2-62)',
                        marker: '13319',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13262,
                        type: 'tenant',
                        name: 'Loccitane (1-98)',
                        marker: '13262',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13263,
                        type: 'tenant',
                        name: 'Halyk Bank (1-99+1-100+1-101)',
                        marker: '13263',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13320,
                        type: 'tenant',
                        name: 'Colins (2-63+2-64)',
                        marker: '13320',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13264,
                        type: 'tenant',
                        name: 'Black Star Burger (2-01+2-02)',
                        marker: '13264',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13265,
                        type: 'tenant',
                        name: 'Спортмастер (2-03+2-04)',
                        marker: '13265',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13321,
                        type: 'tenant',
                        name: 'DeFacto (2-65+2-66+2-67+2-68)',
                        marker: '13321',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13266,
                        type: 'tenant',
                        name: 'Imperial Home (2-07+2-08)',
                        marker: '13266',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13267,
                        type: 'tenant',
                        name: 'ТВОЕ (2-09+2-10)',
                        marker: '13267',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13322,
                        type: 'tenant',
                        name: 'Climber (2-69+2-70)',
                        marker: '13322',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13268,
                        type: 'tenant',
                        name: 'Kerasys Koreanshop (2-101)',
                        marker: '13268',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13269,
                        type: 'tenant',
                        name: 'Kawaii box (2-101/1)',
                        marker: '13269',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13323,
                        type: 'tenant',
                        name: 'Lichi (2-71)',
                        marker: '13323',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13270,
                        type: 'tenant',
                        name: 'Tiflani (2-104)',
                        marker: '13270',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13271,
                        type: 'tenant',
                        name: 'FLO (2-106+2-107+2-125+2-126)',
                        marker: '13271',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13324,
                        type: 'tenant',
                        name: 'Tucino (2-73)',
                        marker: '13324',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13272,
                        type: 'tenant',
                        name: 'Footie (2-108)',
                        marker: '13272',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13273,
                        type: 'tenant',
                        name: 'KARI (2-11+2-12)',
                        marker: '13273',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13325,
                        type: 'tenant',
                        name: 'Emilio Guido (2-74)',
                        marker: '13325',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13274,
                        type: 'tenant',
                        name: 'Orchestra (2-110+2-111)',
                        marker: '13274',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13275,
                        type: 'tenant',
                        name: 'OSTIN Kids (2-112)',
                        marker: '13275',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13326,
                        type: 'tenant',
                        name: 'SMAN (2-75)',
                        marker: '13326',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13276,
                        type: 'tenant',
                        name: 'De Salitto (2-113)',
                        marker: '13276',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13277,
                        type: 'tenant',
                        name: 'Babyboom (2-114)',
                        marker: '13277',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13327,
                        type: 'tenant',
                        name: 'Империя меха (2-77)',
                        marker: '13327',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13278,
                        type: 'tenant',
                        name: 'Adili (2-117)',
                        marker: '13278',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13279,
                        type: 'tenant',
                        name: 'NEXT (2-121+2-122)',
                        marker: '13279',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13328,
                        type: 'tenant',
                        name: 'Consolle (2-78)',
                        marker: '13328',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13280,
                        type: 'tenant',
                        name: 'Hey Baby (2-124)',
                        marker: '13280',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13281,
                        type: 'tenant',
                        name: 'La verna (2-127)',
                        marker: '13281',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13329,
                        type: 'tenant',
                        name: 'Gloria Jeans (2-81+2-82)',
                        marker: '13329',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13282,
                        type: 'tenant',
                        name: 'Mark Formelle (2-128)',
                        marker: '13282',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13283,
                        type: 'tenant',
                        name: 'CITY MEN (2-129)',
                        marker: '13283',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13330,
                        type: 'tenant',
                        name: 'Pasha (2-83+2-84)',
                        marker: '13330',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13284,
                        type: 'tenant',
                        name: 'Cossmo (2-13)',
                        marker: '13284',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13285,
                        type: 'tenant',
                        name: 'Respect (2-130+2-131)',
                        marker: '13285',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13287,
                        type: 'tenant',
                        name: 'The North Face (2-133+2-134+2-135)',
                        marker: '13287',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13332,
                        type: 'tenant',
                        name: 'SUMMIT SPORT (2-87)',
                        marker: '13332',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13288,
                        type: 'tenant',
                        name: 'Frato2 (2-136)',
                        marker: '13288',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13289,
                        type: 'tenant',
                        name: 'Кербез (2-137)',
                        marker: '13289',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13333,
                        type: 'tenant',
                        name: 'SKECHERS (2-88)',
                        marker: '13333',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13334,
                        type: 'tenant',
                        name: 'Шапо (2-89)',
                        marker: '13334',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13291,
                        type: 'tenant',
                        name: 'Dara by Gaissina (2-138)',
                        marker: '13291',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13290,
                        type: 'tenant',
                        name: 'Love Republic (2-139)',
                        marker: '13290',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13292,
                        type: 'tenant',
                        name: 'Mothercare (2-14+2-15)',
                        marker: '13292',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13335,
                        type: 'tenant',
                        name: 'KIMEX (2-92+2-93)',
                        marker: '13335',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13293,
                        type: 'tenant',
                        name: 'Madame Coco (2-140+2-141)',
                        marker: '13293',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13294,
                        type: 'tenant',
                        name: 'Zingal Riche (2-142)',
                        marker: '13294',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13295,
                        type: 'tenant',
                        name: 'KELZIN (2-143+2-144)',
                        marker: '13295',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13296,
                        type: 'tenant',
                        name: 'Vitacci (2-145)',
                        marker: '13296',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13297,
                        type: 'tenant',
                        name: 'Daniel Rizotto (2-146)',
                        marker: '13297',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13298,
                        type: 'tenant',
                        name: 'Алтын алан (2-147)',
                        marker: '13298',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13299,
                        type: 'tenant',
                        name: 'Kazyna Gold (2-148)',
                        marker: '13299',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13300,
                        type: 'tenant',
                        name: 'Deloras (2-16)',
                        marker: '13300',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13301,
                        type: 'tenant',
                        name: 'Zeyland (2-19)',
                        marker: '13301',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13303,
                        type: 'tenant',
                        name: 'DJI (2-20)',
                        marker: '13303',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13304,
                        type: 'tenant',
                        name: 'Mimioriki (2-21)',
                        marker: '13304',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13305,
                        type: 'tenant',
                        name: 'Wanex (2-23)',
                        marker: '13305',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13306,
                        type: 'tenant',
                        name: 'BEARRICHI (2-24)',
                        marker: '13306',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13307,
                        type: 'tenant',
                        name: 'Du pareil Au Meme (2-25)',
                        marker: '13307',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13336,
                        type: 'tenant',
                        name: 'BRO - Best Room Optic (2-94)',
                        marker: '13336',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13337,
                        type: 'tenant',
                        name: 'Shopogolik (2-96)',
                        marker: '13337',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13338,
                        type: 'tenant',
                        name: 'Basconi (2-97)',
                        marker: '13338',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13339,
                        type: 'tenant',
                        name: 'Injoy (2-98)',
                        marker: '13339',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13340,
                        type: 'tenant',
                        name: 'Tchibo (2-99+2-100)',
                        marker: '13340',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13341,
                        type: 'tenant',
                        name: 'Happylon (A1-02+A2-03)',
                        marker: '13341',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13345,
                        type: 'tenant',
                        name: 'SuperDry (№ 1-133)',
                        marker: '13345',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13348,
                        type: 'tenant',
                        name: 'Galmart (А1-01+1-17)',
                        marker: '13348',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13351,
                        type: 'tenant',
                        name: 'Технодом (А1-03)',
                        marker: '13351',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13353,
                        type: 'tenant',
                        name: 'MARWIN (А2-01+2-17)',
                        marker: '13353',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13354,
                        type: 'tenant',
                        name: 'Леонардо (А2-02+2-26)',
                        marker: '13354',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13355,
                        type: 'tenant',
                        name: 'Kango (А2-04)',
                        marker: '13355',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10778,
                        type: 'tenant',
                        name: 'LC Waikiki (1-28+1-29+1-30+2-27)',
                        marker: '10778',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13143,
                        type: 'tenant',
                        name: 'Samsonite (01-06)',
                        marker: '13143',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13302,
                        type: 'tenant',
                        name: 'Бургерная FARШ, Novikova и МИРАТОРГ (2-19-2-20)',
                        marker: '13302',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13331,
                        type: 'tenant',
                        name: 'Ceramiche Edelweiss (2-85,2-86)',
                        marker: '13331',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13537,
                        type: 'tenant',
                        name: 'Roberto bravo (№ 2-148)',
                        marker: '13537',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13342,
                        type: 'tenant',
                        name: 'Chaplin (A1-04+А2-05+2-41)',
                        marker: '13342',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14067,
                        type: 'tenant',
                        name: 'Ecohome (№ 2-85, 2-86)',
                        marker: '14067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:main',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-14', '2022-11-20'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14068,
                        type: 'tenant',
                        name: 'BAO Noodles Sushi Bar (№ 2-83, 2-84)',
                        marker: '14068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-14',
                    value: 350,
                },
            ],
        },
    ],
    [
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13062,
                        type: 'tenant',
                        name: 'Intimissimi (1-91)',
                        marker: '13062',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 1350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13243,
                        type: 'tenant',
                        name: 'Koton (1-57-1-58)',
                        marker: '13243',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 2350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13095,
                        type: 'tenant',
                        name: 'Billion.co (2-123)',
                        marker: '13095',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 33250,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13126,
                        type: 'tenant',
                        name: 'Beeline (0-07)',
                        marker: '13126',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 50,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13446,
                        type: 'tenant',
                        name: 'KEDDO (2-58)',
                        marker: '13446',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13309,
                        type: 'tenant',
                        name: 'Happy Shoes (2-28)',
                        marker: '13309',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13438,
                        type: 'tenant',
                        name: 'M.Manzoni (№ 2-137)',
                        marker: '13438',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13447,
                        type: 'tenant',
                        name: 'HUGO (№ 1-123)',
                        marker: '13447',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13110,
                        type: 'tenant',
                        name: 'Tally weijl (2-79+2-80)',
                        marker: '13110',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10776,
                        type: 'tenant',
                        name: 'GLO (1-20)',
                        marker: '10776',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10777,
                        type: 'tenant',
                        name: 'VIVA express (1-12+1-13)',
                        marker: '10777',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13310,
                        type: 'tenant',
                        name: 'Mini City (2-31+2-32)',
                        marker: '13310',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13112,
                        type: 'tenant',
                        name: 'UM&H (2-79+2-80)',
                        marker: '13112',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13113,
                        type: 'tenant',
                        name: 'Dosso Dossi (2-90+2-91)',
                        marker: '13113',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13315,
                        type: 'tenant',
                        name: 'Penti (2-57)',
                        marker: '13315',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13035,
                        type: 'tenant',
                        name: 'Starbucks 2 (1-01)',
                        marker: '13035',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13439,
                        type: 'tenant',
                        name: 'Podium kass (№ 2-138)',
                        marker: '13439',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13038,
                        type: 'tenant',
                        name: 'CARPISA (1-114)',
                        marker: '13038',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13042,
                        type: 'tenant',
                        name: 'Hair Point (1-12+1-13)',
                        marker: '13042',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13044,
                        type: 'tenant',
                        name: 'Rebel Flowers (1-18)',
                        marker: '13044',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13045,
                        type: 'tenant',
                        name: 'Yves Rocher (1-18)',
                        marker: '13045',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13051,
                        type: 'tenant',
                        name: 'Avanti (1-19)',
                        marker: '13051',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13053,
                        type: 'tenant',
                        name: 'Сбербанк (1-22)',
                        marker: '13053',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13058,
                        type: 'tenant',
                        name: 'Delish (1-43)',
                        marker: '13058',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13063,
                        type: 'tenant',
                        name: 'THEFACESHOP (2-102)',
                        marker: '13063',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13064,
                        type: 'tenant',
                        name: 'W Key (2-102)',
                        marker: '13064',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13070,
                        type: 'tenant',
                        name: 'Kitikate (2-109)',
                        marker: '13070',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13071,
                        type: 'tenant',
                        name: 'Zadari.kz (2-115)',
                        marker: '13071',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13072,
                        type: 'tenant',
                        name: 'Gulliver (2-115)',
                        marker: '13072',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13311,
                        type: 'tenant',
                        name: 'Ocean Basket (2-50+2-51)',
                        marker: '13311',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13114,
                        type: 'tenant',
                        name: 'Askona (2-90+2-91)',
                        marker: '13114',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13127,
                        type: 'tenant',
                        name: 'Casa & More (0-08)',
                        marker: '13127',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13079,
                        type: 'tenant',
                        name: 'Wow,RITZY! (2-118)',
                        marker: '13079',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13440,
                        type: 'tenant',
                        name: 'Soda (№ 0-04)',
                        marker: '13440',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13047,
                        type: 'tenant',
                        name: 'Eterna (1-19)',
                        marker: '13047',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13055,
                        type: 'tenant',
                        name: 'Sadu (1-43)',
                        marker: '13055',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13065,
                        type: 'tenant',
                        name: 'Lego education (2-103)',
                        marker: '13065',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13066,
                        type: 'tenant',
                        name: 'adidas Kids (2-103)',
                        marker: '13066',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13067,
                        type: 'tenant',
                        name: 'Dosso Dossi Kids (2-105)',
                        marker: '13067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13068,
                        type: 'tenant',
                        name: 'Kelzin kids (2-105)',
                        marker: '13068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13069,
                        type: 'tenant',
                        name: "Carter's (2-109)",
                        marker: '13069',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13073,
                        type: 'tenant',
                        name: 'Moleskine (2-118)',
                        marker: '13073',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13082,
                        type: 'tenant',
                        name: 'Nature Republic (2-119)',
                        marker: '13082',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13084,
                        type: 'tenant',
                        name: 'Milavitsa (2-119)',
                        marker: '13084',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13087,
                        type: 'tenant',
                        name: 'Qazaq Republic (2-120)',
                        marker: '13087',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13090,
                        type: 'tenant',
                        name: 'Bronx and Banco (2-120)',
                        marker: '13090',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13093,
                        type: 'tenant',
                        name: 'Jasmine lingerie (2-123)',
                        marker: '13093',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13316,
                        type: 'tenant',
                        name: 'Josiny (2-58)',
                        marker: '13316',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13036,
                        type: 'tenant',
                        name: 'Mixit (1-114)',
                        marker: '13036',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13061,
                        type: 'tenant',
                        name: 'Isabel Garcia (1-91)',
                        marker: '13061',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13116,
                        type: 'tenant',
                        name: 'The Entertainer (2-95)',
                        marker: '13116',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13097,
                        type: 'tenant',
                        name: 'Le Carrousel (2-18)',
                        marker: '13097',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13123,
                        type: 'tenant',
                        name: 'Altel 4G, Tele 2 (0-04)',
                        marker: '13123',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13099,
                        type: 'tenant',
                        name: 'Reima (2-18)',
                        marker: '13099',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13120,
                        type: 'tenant',
                        name: 'Ателье Шебер (0-02)',
                        marker: '13120',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13102,
                        type: 'tenant',
                        name: 'Tutubi (2-22)',
                        marker: '13102',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13103,
                        type: 'tenant',
                        name: 'Coccodrillo (2-22)',
                        marker: '13103',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13105,
                        type: 'tenant',
                        name: 'TERRANOVA (2-29+2-30)',
                        marker: '13105',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13124,
                        type: 'tenant',
                        name: 'Astana Mega Studio (0-05)',
                        marker: '13124',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13128,
                        type: 'tenant',
                        name: 'Lux Optics (0-09)',
                        marker: '13128',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13129,
                        type: 'tenant',
                        name: 'Ловец снов (0-10)',
                        marker: '13129',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13130,
                        type: 'tenant',
                        name: 'Albion time (0-11)',
                        marker: '13130',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13133,
                        type: 'tenant',
                        name: 'Eco Clean (0-14)',
                        marker: '13133',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13144,
                        type: 'tenant',
                        name: 'Rinascimento (01-07)',
                        marker: '13144',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13134,
                        type: 'tenant',
                        name: 'RamNan (0-15/1)',
                        marker: '13134',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13136,
                        type: 'tenant',
                        name: 'Unimaster (0-17)',
                        marker: '13136',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13137,
                        type: 'tenant',
                        name: 'AutoCovers.kz (0-18)',
                        marker: '13137',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13141,
                        type: 'tenant',
                        name: 'New Yorker (1-02+1-03)',
                        marker: '13141',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13142,
                        type: 'tenant',
                        name: 'BEAUTYMANIA (1-04+1-05)',
                        marker: '13142',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13145,
                        type: 'tenant',
                        name: 'AVRORA (1-08+1-09)',
                        marker: '13145',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13147,
                        type: 'tenant',
                        name: 'Actual optic (1-102)',
                        marker: '13147',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13148,
                        type: 'tenant',
                        name: 'Geox (1-103)',
                        marker: '13148',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13149,
                        type: 'tenant',
                        name: 'Lee Wrangler (1-104)',
                        marker: '13149',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13150,
                        type: 'tenant',
                        name: 'Jack & Jones (1-105)',
                        marker: '13150',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13151,
                        type: 'tenant',
                        name: 'Avrora Lingery (1-106)',
                        marker: '13151',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13152,
                        type: 'tenant',
                        name: 'Lady Collection (1-107)',
                        marker: '13152',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13153,
                        type: 'tenant',
                        name: 'Timberland (1-108)',
                        marker: '13153',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13157,
                        type: 'tenant',
                        name: 'ЭПЛ.Якутские бриллианты (1-112)',
                        marker: '13157',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13165,
                        type: 'tenant',
                        name: 'NYX (1-121)',
                        marker: '13165',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13166,
                        type: 'tenant',
                        name: "MarcO'Polo (1-122)",
                        marker: '13166',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13167,
                        type: 'tenant',
                        name: 'Uterque (1-123+1-124)',
                        marker: '13167',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13170,
                        type: 'tenant',
                        name: 'Oysho (1-128+1-129+1-130+2-71/1)',
                        marker: '13170',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13171,
                        type: 'tenant',
                        name: 'MAC (1-131)',
                        marker: '13171',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13172,
                        type: 'tenant',
                        name: 'Swiss time (1-132)',
                        marker: '13172',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13173,
                        type: 'tenant',
                        name: 'Pull & Bear (1-134+1-135+1-136)',
                        marker: '13173',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13179,
                        type: 'tenant',
                        name: 'Calvin Klein Jeans (1-144)',
                        marker: '13179',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13196,
                        type: 'tenant',
                        name: 'ForteBank (1-21)',
                        marker: '13196',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13197,
                        type: 'tenant',
                        name: 'Beeline (1-23)',
                        marker: '13197',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13201,
                        type: 'tenant',
                        name: 'Viva (1-24+1-25)',
                        marker: '13201',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13231,
                        type: 'tenant',
                        name: 'Coffee BOOM (1-36+1-37)',
                        marker: '13231',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13233,
                        type: 'tenant',
                        name: 'LAZANIA (1-39+1-40)',
                        marker: '13233',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13234,
                        type: 'tenant',
                        name: 'Marrone Rosso (1-41+1-42)',
                        marker: '13234',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13235,
                        type: 'tenant',
                        name: 'Korean house (1-44+1-45)',
                        marker: '13235',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13238,
                        type: 'tenant',
                        name: 'Пивоварофф (1-50)',
                        marker: '13238',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13239,
                        type: 'tenant',
                        name: 'Лепим и Варим (1-51)',
                        marker: '13239',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13240,
                        type: 'tenant',
                        name: 'PAUL (1-52+1-53)',
                        marker: '13240',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13104,
                        type: 'tenant',
                        name: 'EVRIKUM (2-29+2-30)',
                        marker: '13104',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13118,
                        type: 'tenant',
                        name: 'Gaissina (2-95)',
                        marker: '13118',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13106,
                        type: 'tenant',
                        name: 'TOP STUDIO (2-72)',
                        marker: '13106',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13107,
                        type: 'tenant',
                        name: 'Chic (2-72)',
                        marker: '13107',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13108,
                        type: 'tenant',
                        name: 'Aizhan Assenbayeva (2-76)',
                        marker: '13108',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13138,
                        type: 'tenant',
                        name: 'Li ning (02-05)',
                        marker: '13138',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13109,
                        type: 'tenant',
                        name: 'Fagum (2-76)',
                        marker: '13109',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13122,
                        type: 'tenant',
                        name: 'Khan Mura (0-03)',
                        marker: '13122',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13125,
                        type: 'tenant',
                        name: 'Comtel 2 (0-06)',
                        marker: '13125',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13131,
                        type: 'tenant',
                        name: 'Tobacco Shop (0-12)',
                        marker: '13131',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13132,
                        type: 'tenant',
                        name: 'Премьер (0-13)',
                        marker: '13132',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13135,
                        type: 'tenant',
                        name: 'Phone Service (0-16)',
                        marker: '13135',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13139,
                        type: 'tenant',
                        name: 'Black Star Wear (02-06)',
                        marker: '13139',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13242,
                        type: 'tenant',
                        name: 'adidas (1-55-1-56)',
                        marker: '13242',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13140,
                        type: 'tenant',
                        name: 'Briolette (101/1)',
                        marker: '13140',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13146,
                        type: 'tenant',
                        name: 'The Body Shop (1-10)',
                        marker: '13146',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13154,
                        type: 'tenant',
                        name: 'Under Armour (1-109)',
                        marker: '13154',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13155,
                        type: 'tenant',
                        name: 'Kaztour (1-11)',
                        marker: '13155',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13156,
                        type: 'tenant',
                        name: 'Mango (1-110+1-111)',
                        marker: '13156',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13158,
                        type: 'tenant',
                        name: 'Viled (1-113)',
                        marker: '13158',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13159,
                        type: 'tenant',
                        name: 'Московский ювелирный завод (1-115)',
                        marker: '13159',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13160,
                        type: 'tenant',
                        name: 'Pandora (1-116)',
                        marker: '13160',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13161,
                        type: 'tenant',
                        name: 'Lacoste (1-117)',
                        marker: '13161',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13162,
                        type: 'tenant',
                        name: "Levi's (1-118)",
                        marker: '13162',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13163,
                        type: 'tenant',
                        name: 'Hyundai City Store Premium Astana (1-119)',
                        marker: '13163',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13164,
                        type: 'tenant',
                        name: 'US Polo (1-120)',
                        marker: '13164',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13168,
                        type: 'tenant',
                        name: 'Calvin klein (1-124)',
                        marker: '13168',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13169,
                        type: 'tenant',
                        name: 'Massimo Dutti (1-127+1-126+1-125)',
                        marker: '13169',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13174,
                        type: 'tenant',
                        name: 'Bershka (1-137+1-138+1-139+1-140)',
                        marker: '13174',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13175,
                        type: 'tenant',
                        name: 'the Saem (1-14)',
                        marker: '13175',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13176,
                        type: 'tenant',
                        name: 'GUESS (1-141)',
                        marker: '13176',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13177,
                        type: 'tenant',
                        name: 'Tommy Hilfiger (1-142)',
                        marker: '13177',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13178,
                        type: 'tenant',
                        name: 'Diesel (1-143)',
                        marker: '13178',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13180,
                        type: 'tenant',
                        name: '7 Life (1-145)',
                        marker: '13180',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13181,
                        type: 'tenant',
                        name: 'Swarovski (1-15)',
                        marker: '13181',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13189,
                        type: 'tenant',
                        name: 'Pharmacom (1-16)',
                        marker: '13189',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13202,
                        type: 'tenant',
                        name: 'Nickol (1-26)',
                        marker: '13202',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13203,
                        type: 'tenant',
                        name: 'Sony (1-27)',
                        marker: '13203',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13206,
                        type: 'tenant',
                        name: 'Интертоп (1-31)',
                        marker: '13206',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13207,
                        type: 'tenant',
                        name: 'Starbucks 1 (1-32)',
                        marker: '13207',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13208,
                        type: 'tenant',
                        name: 'Пинта бар (1-33+1-34+1-35)',
                        marker: '13208',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13232,
                        type: 'tenant',
                        name: 'Angel-In-Us 1 (1-38)',
                        marker: '13232',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13236,
                        type: 'tenant',
                        name: 'Rumi (1-46+1-47)',
                        marker: '13236',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13237,
                        type: 'tenant',
                        name: 'Якитория (1-48+1-49)',
                        marker: '13237',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13241,
                        type: 'tenant',
                        name: 'Nike (1-54)',
                        marker: '13241',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13244,
                        type: 'tenant',
                        name: 'Французский дом (1-59)',
                        marker: '13244',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13245,
                        type: 'tenant',
                        name: 'Angel-In-Us 2 (1-60)',
                        marker: '13245',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13246,
                        type: 'tenant',
                        name: 'Zara Home (1-61+1-62+1-63)',
                        marker: '13246',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13247,
                        type: 'tenant',
                        name: 'ZARA (1-64-1-71+2-72/1+2-73/1+2-70/1)',
                        marker: '13247',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13248,
                        type: 'tenant',
                        name: 'Stradivarius (1-72+1-73+1-74+2-74/1)',
                        marker: '13248',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13249,
                        type: 'tenant',
                        name: 'H&M (1-76-1-81+2-81/1)',
                        marker: '13249',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13250,
                        type: 'tenant',
                        name: 'Armani Exchange (1-82+1-83+1-84)',
                        marker: '13250',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13251,
                        type: 'tenant',
                        name: 'Walker (1-85)',
                        marker: '13251',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13252,
                        type: 'tenant',
                        name: 'Calzedonia (1-86)',
                        marker: '13252',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13253,
                        type: 'tenant',
                        name: 'IQOS (1-87)',
                        marker: '13253',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13313,
                        type: 'tenant',
                        name: 'Adika (2-55)',
                        marker: '13313',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13254,
                        type: 'tenant',
                        name: 'GLASMAN (1-88)',
                        marker: '13254',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13255,
                        type: 'tenant',
                        name: 'Fabiani (1-89)',
                        marker: '13255',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13314,
                        type: 'tenant',
                        name: 'Grazie (2-56)',
                        marker: '13314',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13256,
                        type: 'tenant',
                        name: 'MI (1-90)',
                        marker: '13256',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13257,
                        type: 'tenant',
                        name: 'Kanzler (1-92)',
                        marker: '13257',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13317,
                        type: 'tenant',
                        name: 'L-Shoes (2-59)',
                        marker: '13317',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13258,
                        type: 'tenant',
                        name: 'Jysan Bank (1-93)',
                        marker: '13258',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13259,
                        type: 'tenant',
                        name: 'GANT (1-94)',
                        marker: '13259',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13318,
                        type: 'tenant',
                        name: 'OSTIN (2-60+2-61)',
                        marker: '13318',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13260,
                        type: 'tenant',
                        name: 'Miniso (1-95)',
                        marker: '13260',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13286,
                        type: 'tenant',
                        name: 'PLUME (2-132)',
                        marker: '13286',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13261,
                        type: 'tenant',
                        name: 'iPoint (1-96+1-97)',
                        marker: '13261',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13319,
                        type: 'tenant',
                        name: 'Indigo (2-62)',
                        marker: '13319',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13262,
                        type: 'tenant',
                        name: 'Loccitane (1-98)',
                        marker: '13262',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13263,
                        type: 'tenant',
                        name: 'Halyk Bank (1-99+1-100+1-101)',
                        marker: '13263',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13320,
                        type: 'tenant',
                        name: 'Colins (2-63+2-64)',
                        marker: '13320',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13264,
                        type: 'tenant',
                        name: 'Black Star Burger (2-01+2-02)',
                        marker: '13264',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13265,
                        type: 'tenant',
                        name: 'Спортмастер (2-03+2-04)',
                        marker: '13265',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13321,
                        type: 'tenant',
                        name: 'DeFacto (2-65+2-66+2-67+2-68)',
                        marker: '13321',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13266,
                        type: 'tenant',
                        name: 'Imperial Home (2-07+2-08)',
                        marker: '13266',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13267,
                        type: 'tenant',
                        name: 'ТВОЕ (2-09+2-10)',
                        marker: '13267',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13322,
                        type: 'tenant',
                        name: 'Climber (2-69+2-70)',
                        marker: '13322',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13268,
                        type: 'tenant',
                        name: 'Kerasys Koreanshop (2-101)',
                        marker: '13268',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13269,
                        type: 'tenant',
                        name: 'Kawaii box (2-101/1)',
                        marker: '13269',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13323,
                        type: 'tenant',
                        name: 'Lichi (2-71)',
                        marker: '13323',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13270,
                        type: 'tenant',
                        name: 'Tiflani (2-104)',
                        marker: '13270',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13271,
                        type: 'tenant',
                        name: 'FLO (2-106+2-107+2-125+2-126)',
                        marker: '13271',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13324,
                        type: 'tenant',
                        name: 'Tucino (2-73)',
                        marker: '13324',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13272,
                        type: 'tenant',
                        name: 'Footie (2-108)',
                        marker: '13272',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13273,
                        type: 'tenant',
                        name: 'KARI (2-11+2-12)',
                        marker: '13273',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13325,
                        type: 'tenant',
                        name: 'Emilio Guido (2-74)',
                        marker: '13325',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13274,
                        type: 'tenant',
                        name: 'Orchestra (2-110+2-111)',
                        marker: '13274',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13275,
                        type: 'tenant',
                        name: 'OSTIN Kids (2-112)',
                        marker: '13275',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13326,
                        type: 'tenant',
                        name: 'SMAN (2-75)',
                        marker: '13326',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13276,
                        type: 'tenant',
                        name: 'De Salitto (2-113)',
                        marker: '13276',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13277,
                        type: 'tenant',
                        name: 'Babyboom (2-114)',
                        marker: '13277',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13327,
                        type: 'tenant',
                        name: 'Империя меха (2-77)',
                        marker: '13327',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13278,
                        type: 'tenant',
                        name: 'Adili (2-117)',
                        marker: '13278',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13279,
                        type: 'tenant',
                        name: 'NEXT (2-121+2-122)',
                        marker: '13279',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13328,
                        type: 'tenant',
                        name: 'Consolle (2-78)',
                        marker: '13328',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13280,
                        type: 'tenant',
                        name: 'Hey Baby (2-124)',
                        marker: '13280',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13281,
                        type: 'tenant',
                        name: 'La verna (2-127)',
                        marker: '13281',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13329,
                        type: 'tenant',
                        name: 'Gloria Jeans (2-81+2-82)',
                        marker: '13329',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13282,
                        type: 'tenant',
                        name: 'Mark Formelle (2-128)',
                        marker: '13282',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13283,
                        type: 'tenant',
                        name: 'CITY MEN (2-129)',
                        marker: '13283',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13330,
                        type: 'tenant',
                        name: 'Pasha (2-83+2-84)',
                        marker: '13330',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13284,
                        type: 'tenant',
                        name: 'Cossmo (2-13)',
                        marker: '13284',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13285,
                        type: 'tenant',
                        name: 'Respect (2-130+2-131)',
                        marker: '13285',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13287,
                        type: 'tenant',
                        name: 'The North Face (2-133+2-134+2-135)',
                        marker: '13287',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13332,
                        type: 'tenant',
                        name: 'SUMMIT SPORT (2-87)',
                        marker: '13332',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13288,
                        type: 'tenant',
                        name: 'Frato2 (2-136)',
                        marker: '13288',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13289,
                        type: 'tenant',
                        name: 'Кербез (2-137)',
                        marker: '13289',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13333,
                        type: 'tenant',
                        name: 'SKECHERS (2-88)',
                        marker: '13333',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13334,
                        type: 'tenant',
                        name: 'Шапо (2-89)',
                        marker: '13334',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13291,
                        type: 'tenant',
                        name: 'Dara by Gaissina (2-138)',
                        marker: '13291',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13290,
                        type: 'tenant',
                        name: 'Love Republic (2-139)',
                        marker: '13290',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13292,
                        type: 'tenant',
                        name: 'Mothercare (2-14+2-15)',
                        marker: '13292',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13335,
                        type: 'tenant',
                        name: 'KIMEX (2-92+2-93)',
                        marker: '13335',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13293,
                        type: 'tenant',
                        name: 'Madame Coco (2-140+2-141)',
                        marker: '13293',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13294,
                        type: 'tenant',
                        name: 'Zingal Riche (2-142)',
                        marker: '13294',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13295,
                        type: 'tenant',
                        name: 'KELZIN (2-143+2-144)',
                        marker: '13295',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13296,
                        type: 'tenant',
                        name: 'Vitacci (2-145)',
                        marker: '13296',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13297,
                        type: 'tenant',
                        name: 'Daniel Rizotto (2-146)',
                        marker: '13297',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13298,
                        type: 'tenant',
                        name: 'Алтын алан (2-147)',
                        marker: '13298',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13299,
                        type: 'tenant',
                        name: 'Kazyna Gold (2-148)',
                        marker: '13299',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13300,
                        type: 'tenant',
                        name: 'Deloras (2-16)',
                        marker: '13300',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13301,
                        type: 'tenant',
                        name: 'Zeyland (2-19)',
                        marker: '13301',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13303,
                        type: 'tenant',
                        name: 'DJI (2-20)',
                        marker: '13303',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13304,
                        type: 'tenant',
                        name: 'Mimioriki (2-21)',
                        marker: '13304',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13305,
                        type: 'tenant',
                        name: 'Wanex (2-23)',
                        marker: '13305',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13306,
                        type: 'tenant',
                        name: 'BEARRICHI (2-24)',
                        marker: '13306',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13307,
                        type: 'tenant',
                        name: 'Du pareil Au Meme (2-25)',
                        marker: '13307',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13336,
                        type: 'tenant',
                        name: 'BRO - Best Room Optic (2-94)',
                        marker: '13336',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13337,
                        type: 'tenant',
                        name: 'Shopogolik (2-96)',
                        marker: '13337',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13338,
                        type: 'tenant',
                        name: 'Basconi (2-97)',
                        marker: '13338',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13339,
                        type: 'tenant',
                        name: 'Injoy (2-98)',
                        marker: '13339',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13340,
                        type: 'tenant',
                        name: 'Tchibo (2-99+2-100)',
                        marker: '13340',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13341,
                        type: 'tenant',
                        name: 'Happylon (A1-02+A2-03)',
                        marker: '13341',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13345,
                        type: 'tenant',
                        name: 'SuperDry (№ 1-133)',
                        marker: '13345',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13348,
                        type: 'tenant',
                        name: 'Galmart (А1-01+1-17)',
                        marker: '13348',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13351,
                        type: 'tenant',
                        name: 'Технодом (А1-03)',
                        marker: '13351',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13353,
                        type: 'tenant',
                        name: 'MARWIN (А2-01+2-17)',
                        marker: '13353',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13354,
                        type: 'tenant',
                        name: 'Леонардо (А2-02+2-26)',
                        marker: '13354',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13355,
                        type: 'tenant',
                        name: 'Kango (А2-04)',
                        marker: '13355',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 10778,
                        type: 'tenant',
                        name: 'LC Waikiki (1-28+1-29+1-30+2-27)',
                        marker: '10778',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13143,
                        type: 'tenant',
                        name: 'Samsonite (01-06)',
                        marker: '13143',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13302,
                        type: 'tenant',
                        name: 'Бургерная FARШ, Novikova и МИРАТОРГ (2-19-2-20)',
                        marker: '13302',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13331,
                        type: 'tenant',
                        name: 'Ceramiche Edelweiss (2-85,2-86)',
                        marker: '13331',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13537,
                        type: 'tenant',
                        name: 'Roberto bravo (№ 2-148)',
                        marker: '13537',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 13342,
                        type: 'tenant',
                        name: 'Chaplin (A1-04+А2-05+2-41)',
                        marker: '13342',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14067,
                        type: 'tenant',
                        name: 'Ecohome (№ 2-85, 2-86)',
                        marker: '14067',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
        {
            context: {
                alias: 'sales:is_fiscal:false:compare',
                metric: 'fsf_sales_vat',
                metric_level: 'clean',
                time_range: ['2022-11-07', '2022-11-13'],
                time_freq: null,
                data_objects: [
                    {
                        id: 14068,
                        type: 'tenant',
                        name: 'BAO Noodles Sushi Bar (№ 2-83, 2-84)',
                        marker: '14068',
                        timezone: 'Asia/Almaty',
                        external_ids: [],
                    },
                ],
            },
            items: [
                {
                    time: '2022-11-07',
                    value: 350,
                },
            ],
        },
    ],
];
