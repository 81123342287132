import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { read, utils, writeFile } from 'xlsx-with-styles';
import { Button } from '../../../lib/esm/components';
import { ISheetCell } from '../../../General.interfaces';
import { sendSimpleMetrics } from '../../../tools/useAnalytics';
import { generalReducerValues } from '../../../General.reducer';

const SaveXLSXFileButton: React.FC<{
    grid: ISheetCell[][];
    disabled?: boolean;
    bookName?: string;
    currentModule: string;
}> = ({ grid, disabled = false, bookName = 'Basic report', currentModule }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(generalReducerValues);
    const canSendAnalytics = !user?.permissions.includes('app/client | not-collect-analytics | feature/view');

    const saveFile = (ext: string) => () => {
        const wb = utils.book_new();
        var worksheet = utils.aoa_to_sheet(
            grid.map((row) => {
                return row.map((cell) => {
                    return cell.value;
                });
            }),
        );

        const colsWidths = grid[0].map((cell, i) => {
            const width =
                Math.max(
                    ...grid.map((row) => {
                        return row[i].value.length;
                    }),
                ) *
                    2 +
                1;
            return { width, height: 3 };
        });

        Object.keys(worksheet).forEach((key) => {
            /* all keys that do not begin with "!" correspond to cell addresses */
            if (key[0] !== '!') {
                const cell = worksheet[key];
                let bold = false;
                let patternType = 'none';
                let bgColor = { rgb: 'FFFFFFFF' };
                let bottom = { style: 'hair', color: { auto: 1 } };

                if (key[1] === '1' && !key[2]) {
                    bold = true;
                    bottom = { style: 'thin', color: { auto: 1 } };
                }
                cell.s = {
                    fill: {
                        patternType,
                        fgColor: { rgb: 'FF000000' },
                        bgColor,
                    },
                    font: {
                        // name: 'Times New Roman',
                        sz: 14,
                        color: { rgb: 'FF000000' },
                        bold,
                        italic: false,
                        underline: false,
                    },
                    border: {
                        top: { style: 'hair', color: { auto: 1 } },
                        right: { style: 'hair', color: { auto: 1 } },
                        bottom,
                        left: { style: 'hair', color: { auto: 1 } },
                    },
                };
            }
        });

        worksheet['!cols'] = colsWidths;

        utils.book_append_sheet(wb, worksheet, 'worksheet');

        writeFile(wb, `${bookName}.` + ext);

        sendSimpleMetrics('reachGoal', `${'report'}_downloaded`, currentModule, canSendAnalytics);
    };

    return <Button text={t('Save to local drive')} onClick={saveFile('xlsx')} margin="l_xxxs" disabled={disabled} />;
};

export default SaveXLSXFileButton;
