import { __awaiter, __generator } from '../../_virtual/_tslib.js';
import axios from 'axios';

var requestFrontExchange = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? 'save' : _b, cloud_temp_hash = _a.cloud_temp_hash, data = _a.data;
    var url = 'https://functions.yandexcloud.net/d4e56pu8nusdd8tgqa6n';
    // const url = 'https://functions.yandexcloud.net/d4ev0b4e99upobl1bksj';
    var postFrontExchangeData = function (url, data) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios({
                            method: 'POST',
                            url: url,
                            data: JSON.stringify(data),
                        })
                            .then(function (response) {
                            result = response.data;
                        })
                            .catch(function (err) { })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, result];
            }
        });
    }); };
    var getFrontExchangeData = function (url, cloud_temp_hash) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    result = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios({
                            method: 'GET',
                            url: "".concat(url, "?cloud_temp_hash=").concat(cloud_temp_hash),
                        })
                            .then(function (response) {
                            result = response.data;
                        })
                            .catch(function (err) {
                            result = err;
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, result];
            }
        });
    }); };
    if (mode === 'save' && data) {
        return postFrontExchangeData(url, data);
    }
    else if (mode === 'load') {
        return getFrontExchangeData(url, cloud_temp_hash);
    }
    else
        return null;
};

export { requestFrontExchange as default };
