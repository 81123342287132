import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../store';
import { IReducerState } from './interfaces';

import { IReportingObject, TLoadingData, TMetricResponse } from '../../../../../../../General.interfaces';

const initialState: IReducerState = {
    preparedData: { message: 'Loading', status: 'Loading' },
    ratingType: 'location',
    allFloors: [],
    zonesByGroup: {},
    selectedGroupId: null,
    selectedFloor: null,
    selectedZoneId: null,
    responseResult: null,
    dataRefetchObject: {},
};

export const Performance_Dashboard_Traffic_Widget_Reducer = createSlice({
    name: 'Performance_Dashboard_Traffic_Widget_Reducer',
    initialState,

    reducers: {
        storePreraredData: (state, action: PayloadAction<TLoadingData>) => {
            state.preparedData = action.payload;
        },

        storeRatingType: (state, action: PayloadAction<string>) => {
            state.ratingType = action.payload;
        },

        storeZonesByGroup: (state, action: PayloadAction<{ [x: string]: IReportingObject[] }>) => {
            state.zonesByGroup = action.payload;
        },

        storeSelectedGroup: (state, action: PayloadAction<string | null>) => {
            state.selectedGroupId = action.payload;
        },

        storeSelectedZoneId: (state, action: PayloadAction<string | null>) => {
            state.selectedZoneId = action.payload;
        },

        storeResponseResult: (state, action: PayloadAction<TMetricResponse[] | null>) => {
            state.responseResult = action.payload;
        },
        storeAllFloors: (state, action: PayloadAction<IReportingObject[]>) => {
            state.allFloors = action.payload;
        },
        storeSelectedFloor: (state, action: PayloadAction<number | null>) => {
            state.selectedFloor = action.payload;
        },

        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
    },
});

export const {
    storeRatingType,
    storeAllFloors,
    storeSelectedFloor,
    storePreraredData,
    storeResponseResult,
    storeSelectedGroup,
    storeZonesByGroup,
    storeSelectedZoneId,
    reloadWidget,
} = Performance_Dashboard_Traffic_Widget_Reducer.actions;

export const Performance_Dashboard_Traffic_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_Traffic_Widget_Reducer;

export default Performance_Dashboard_Traffic_Widget_Reducer.reducer;
