import { IResponseItem } from '../../../tools/interfaces';
import { DateTime } from 'luxon';

/**
 * Функция для получения суммарного значения по выбранной метрике
 * @param rowMetricsByDate Объект сырых метрик, где ключ это дата, за которую получена данная метркиа
 * @param year Год, за который нужно получить суммарное значение по метрике
 * @returns Суммарное значение за год, для выбранной метрики
 */
const getYearTotalValue = (
    year: number,
    rowMetricsByDate: {
        [date: string]: IResponseItem[];
    }[],
): number | null => {
    const currentYearRowDataElement = rowMetricsByDate?.find((element) => {
        const [date] = Object.keys(element);
        return DateTime.fromISO(date).year === year;
    });
    let totalValue = null;

    if (currentYearRowDataElement) {
        const [currentYearRowDataKey] = Object.keys(currentYearRowDataElement);
        const currentYearRowData = currentYearRowDataElement[currentYearRowDataKey];
        totalValue = currentYearRowData.reduce((acc: null | number, value) => {
            if (value.value !== null) {
                if (acc === null) acc = value.value;
                else acc += value.value;
            }
            return acc;
        }, null);
    }

    return totalValue;
};

export default getYearTotalValue;
