import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import WidgetTitle from '../../../../../Wrappers/WidgetTitle/WidgetTitle';

import WidgetTitleWrapper from '../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { useRerender } from '../../../../../../../tools/useRerender';
import { ChartWrapper, WidgetWrapper } from './styles';
import { reloadWidget, Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values } from './reducer';
import { withLoading } from '../../../../../../../tools/API/withLoading';
import Dynamics from '../../../../../Charts/Dynamics/Dynamics';
import WidgetAdditionalControls from '../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Компонент для отображения виджета структура платежей
 */
const PaymentStructure: React.FC = ({}) => {
    const { rawMetricsData, chartOptions } = useSelector(Sales_PaymentAnalysis_PaymentStructure_Widget_Reducer_Values);
    const rerender = useRerender(chartOptions);
    const { t } = useTranslation();
    const ref = useRef(null);

    const chart = useMemo(() => {
        const WithLoadingTable = withLoading(ChartWrapper, { height: 400, data: rawMetricsData });
        return <WithLoadingTable>{chartOptions && <Dynamics {...chartOptions} />}</WithLoadingTable>;
    }, [rawMetricsData, chartOptions]);

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {t('Payment structure')}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref, rerenderKey: rerender }}
                        widgetName={'Payment structure'}
                        reloadHandler={reloadWidget}
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            {chart}
        </WidgetWrapper>
    );
};

export default PaymentStructure;
