import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getReportingObjects } from '../../../../../../../../../tools/getReportingObjects';
import WidgetTitle from '../../../../../../../Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../../../Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import { TableOfObjectsContext } from '../../context';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { PivotItem } from '@fluentui/react';
import Pivot from '../../../../../../../Pivot/Pivot';
import WidgetAdditionalControls from '../../../../../../../WidgetAdditionalControls/WidgetAdditionalControls';

/**
 * Шапка виджета
 */
const Header = () => {
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const [pivotItems, setPivotItems] = useState<{ itemKey: string; headerText: string }[]>([]);
    const context = useContext(TableOfObjectsContext);
    const { t } = useTranslation();

    useEffect(() => {
        const lockedFields = ['location', 'passway'];

        if (context) {
            const pivotItems = getReportingObjects(reportingObjectsByType, t)
                .map((item) => ({
                    headerText: item.section,
                    itemKey: item.key,
                }))
                ?.filter((item) => !lockedFields.includes(item.itemKey));

            if (!context.selectedObjectsType && pivotItems.length) {
                context.storeSelectedObjectsTypeTrans(pivotItems[0].itemKey);
            }

            setPivotItems(pivotItems);
        }
    }, [reportingObjectsByType, t]);

    const onPivotItemClick = (item: PivotItem) => {
        if (context && item.props.itemKey) {
            context.storeSelectedObjectsTypeTrans(item.props.itemKey);
        }
    };

    return (
        <WidgetTitleWrapper>
            <WidgetTitle>
                {t('Table of objects')}
                {context && (
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: context.rootNodeRef, rerenderKey: context.rerender }}
                        reloadHandler={context.reloadWidget}
                        widgetName={'Table of objects'}
                    />
                )}
            </WidgetTitle>
            <Pivot onLinkClick={onPivotItemClick} selectedKey={context?.selectedObjectsType} overflowBehavior="menu">
                {pivotItems.map((element) => (
                    <PivotItem
                        key={element.itemKey + Math.random()}
                        headerText={t(element.headerText)}
                        itemKey={element.itemKey}
                    />
                ))}
            </Pivot>
        </WidgetTitleWrapper>
    );
};

export default Header;
