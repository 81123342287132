import styled from 'styled-components';

export const PanelWrapper = styled.div`
    border-radius: var(--space-xxxxs);
    border: 1px solid var(--color-secondary);
    padding: var(--space-xs);
    margin-bottom: var(--space-md);
    margin-right: var(--space-sm);
    position: relative;
    display: inline-flex;
    flex-direction: column;
    min-width: 200px;
`;

export const Title = styled.div`
    position: absolute;
    left: var(--space-xxs);
    top: -20px;
    padding: var(--space-xxxs);
    background-color: white;
    z-index: 10;
    font-size: var(--text-md);
    color: var(--color-secondary-darker);
`;
