import {
    CompareCellValue,
    CustomTable,
    HeaderCell,
    LoadingWrapper,
    MonthCell,
    MonthCellValue,
    TableWrapper,
    TotalCompareCellValue,
    TotalRowCell,
    TotalRowCellValue,
    Wrapper,
    YearCell,
    compareCellTooltipStyles,
} from './styles';
import { FC, useMemo, useState } from 'react';
import { IProps, ISelectedPeriod, ITableCell } from './interfaces';
import { Modals, Tooltip } from '../../../../../../../../../lib/esm/components';
import { SHOW_ALL_VALUES_OPTION_ID, SHOW_DIFFERENCE_OPTION_ID } from '../../../../constants/constants';

import { DateTime } from 'luxon';
import { IResponseItem } from '../../tools/interfaces';
import PeriodModalBody from './components/PeriodModalBody/PeriodModalBody';
import { cloneDeep } from 'lodash';
import getPercentDifferenceBetweenNumbers from './tools/getDifferenceBetweenNumbers';
import getYearTotalValue from './tools/getYearTotalValue';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { resetYOYPeriodModalReducer } from './components/PeriodModalBody/reducer';
import { stringDate } from '../../../../../../../../../tools/Strings/stringDate';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отображения таблиц со значениями метрик для выбранных
 * отчетных объектов
 * @param extendedReportingObjectsById Объект отчетных объектов, где ключ это их ID
 * @param yoyTableSettings Настройки таблица из универсального селекта
 * @param selectedMetrics Выбранные метрики
 * @param lang Язык приложения
 */
const Table: FC<IProps> = ({ extendedReportingObjectsById, lang, selectedMetrics, yoyTableSettings }) => {
    const [selectedPeriod, setSelectedPeriod] = useState<ISelectedPeriod | null>(null);
    const [modalStatus, setModalStatus] = useState({ show: false });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCompareCellClick = (args: ISelectedPeriod) => () => {
        setModalStatus({ show: true });
        setSelectedPeriod(args);
    };

    const handleModalClose = () => {
        dispatch(resetYOYPeriodModalReducer());
        setModalStatus({ show: false });
        setSelectedPeriod(null);
    };

    /** Геренация самих таблиц */
    const tables = useMemo(() => {
        return Object.keys(extendedReportingObjectsById).map((id) => {
            const { reportingObjectInfo, rawMetricsByDate } = extendedReportingObjectsById[id];
            const data: ITableCell[][] = [];
            const yoyTableSettingsOptionsIds = yoyTableSettings ? yoyTableSettings.map((option) => option.id) : [];
            const showDifference = yoyTableSettingsOptionsIds.includes(SHOW_DIFFERENCE_OPTION_ID);
            const showAllValues = yoyTableSettingsOptionsIds.includes(SHOW_ALL_VALUES_OPTION_ID);
            let headerColSpan = 1;
            if (Array.isArray(rawMetricsByDate)) {
                const years = rawMetricsByDate
                    .map((element) => {
                        const [date] = Object.keys(element);
                        return DateTime.fromISO(date).year;
                    })
                    .sort();
                const compareYears: { title: string; compareYear: number; currentYear: number }[] = years
                    ?.filter((_, index) => index !== years.length - 1)
                    .map((element, index) => {
                        return {
                            title: `${years[index + 1]} / ${element}`,
                            compareYear: years[index + 1],
                            currentYear: element,
                        };
                    });

                headerColSpan += years.length + compareYears.length;

                if (years.length) {
                    //** Генерация строки с выбранными годами */
                    const yearsRow: ITableCell[] = [
                        {
                            component: <YearCell />,
                            forceComponent: true,
                            value: '',
                        },
                    ];

                    showAllValues &&
                        years.forEach((element) =>
                            yearsRow.push({
                                component: <YearCell>{element}</YearCell>,
                                forceComponent: true,
                                value: element,
                            }),
                        );

                    compareYears.forEach((element) =>
                        yearsRow.push({
                            component: <YearCell>{element.title}</YearCell>,
                            forceComponent: true,
                            value: element.title,
                        }),
                    );
                    data.push(yearsRow);

                    /** Генерация строки с сумарными значениями за год */
                    const totalRow: ITableCell[] = [
                        {
                            component: <TotalRowCell>{t('Total')}</TotalRowCell>,
                            forceComponent: true,
                            value: t('Total'),
                        },
                    ];

                    showAllValues &&
                        years.forEach((element) => {
                            const totalValue = getYearTotalValue(element, rawMetricsByDate);
                            totalRow.push({
                                value: totalValue !== null ? totalValue : ' —',
                                forceComponent: true,
                                component: (
                                    <TotalRowCellValue>
                                        {totalValue !== null ? numberWithSpaces(totalValue.toFixed(2)) : ' —'}
                                    </TotalRowCellValue>
                                ),
                            });
                        });

                    compareYears.forEach((element) => {
                        const compareYear = DateTime.fromObject({ year: element.compareYear });
                        const compareYearTimeRange = [
                            compareYear.startOf('year').toFormat('yyyy-MM-dd'),
                            compareYear.endOf('year').toFormat('yyyy-MM-dd'),
                        ];

                        const year = DateTime.fromObject({ year: element.currentYear });
                        const yearTimeRange = [
                            year.startOf('year').toFormat('yyyy-MM-dd'),
                            year.endOf('year').toFormat('yyyy-MM-dd'),
                        ];

                        const yearTotalValue = getYearTotalValue(year.year, rawMetricsByDate);
                        const compareYearTotalValue = getYearTotalValue(compareYear.year, rawMetricsByDate);

                        const difference =
                            compareYearTotalValue && yearTotalValue
                                ? getPercentDifferenceBetweenNumbers(yearTotalValue, compareYearTotalValue)
                                : null;

                        totalRow.push({
                            value: difference !== null ? difference.percentDifference : ' —',
                            forceComponent: true,
                            component: (
                                <Tooltip styles={compareCellTooltipStyles} content={t('Show details')}>
                                    <TotalCompareCellValue
                                        onClick={handleCompareCellClick({
                                            chartDetailing: 'MS',
                                            timeRanges: [yearTimeRange, compareYearTimeRange],
                                            reportingObjectId: reportingObjectInfo.id,
                                        })}
                                        value={difference?.percentDifference || null}
                                    >
                                        {difference !== null
                                            ? `${numberWithSpaces(difference.percentDifference)}% ${
                                                  showDifference ? `(${numberWithSpaces(difference.difference)})` : ''
                                              }`
                                            : ' —'}
                                    </TotalCompareCellValue>
                                </Tooltip>
                            ),
                        });
                    });

                    data.push(totalRow);
                }

                /** Генерация строк с месяцами */
                for (let i = 1; i <= 12; i++) {
                    const month = stringDate(
                        DateTime.fromObject({ year: 2000, day: 1, month: i }).toISO(),
                        lang,
                        '',
                        'MMMM',
                    );

                    const monthData: ITableCell[] = [
                        {
                            component: <MonthCell>{month}</MonthCell>,
                            forceComponent: true,
                            value: month,
                        },
                    ];

                    showAllValues &&
                        cloneDeep(rawMetricsByDate)
                            .sort((a, b) => {
                                const [aDate] = Object.keys(a);
                                const [bDate] = Object.keys(b);

                                return DateTime.fromISO(aDate) > DateTime.fromISO(bDate) ? 1 : -1;
                            })
                            .map((element) => {
                                const [rowDatakey] = Object.keys(element);

                                const rowData: IResponseItem[] = element[rowDatakey];

                                const currentMonth = rowData?.find(
                                    (element) => DateTime.fromISO(element.time).month === i,
                                );
                                monthData.push({
                                    component: (
                                        <MonthCellValue>
                                            {currentMonth?.value
                                                ? numberWithSpaces(currentMonth.value.toFixed(2))
                                                : ' —'}
                                        </MonthCellValue>
                                    ),
                                    value: currentMonth ? currentMonth.value : ' —',
                                    forceComponent: true,
                                });
                            });

                    compareYears.forEach((compareYear) => {
                        try {
                            const compareYearElement = rawMetricsByDate?.find((element) => {
                                const [date] = Object.keys(element);
                                return DateTime.fromISO(date).year === compareYear.compareYear;
                            });
                            const yearElement = rawMetricsByDate?.find((element) => {
                                const [date] = Object.keys(element);
                                return DateTime.fromISO(date).year === compareYear.currentYear;
                            });
                            const compareYearKey = Object.keys(compareYearElement)[0];
                            const yearKey = Object.keys(yearElement)[0];
                            const compareYearData = compareYearElement[compareYearKey];
                            const yearData = yearElement[yearKey];
                            const currentCompareMonth = compareYearData?.find(
                                (element: IResponseItem) => DateTime.fromISO(element.time).month === i,
                            );
                            const currentCompareMonthTimeRange = [
                                DateTime.fromISO(currentCompareMonth?.time || '')
                                    .startOf('month')
                                    .toFormat('yyyy-MM-dd'),
                                DateTime.fromISO(currentCompareMonth?.time || '')
                                    .endOf('month')
                                    .toFormat('yyyy-MM-dd'),
                            ];
                            const currentMonth = yearData?.find(
                                (element: IResponseItem) => DateTime.fromISO(element.time).month === i,
                            );
                            const currentMonthTimeRange = [
                                DateTime.fromISO(currentMonth?.time || '')
                                    .startOf('month')
                                    .toFormat('yyyy-MM-dd'),
                                DateTime.fromISO(currentMonth?.time || '')
                                    .endOf('month')
                                    .toFormat('yyyy-MM-dd'),
                            ];

                            const difference =
                                currentCompareMonth?.value && currentMonth?.value
                                    ? getPercentDifferenceBetweenNumbers(currentMonth.value, currentCompareMonth.value)
                                    : null;

                            monthData.push({
                                component: (
                                    <Tooltip styles={compareCellTooltipStyles} content={t('Show details')}>
                                        <CompareCellValue
                                            onClick={
                                                currentMonth &&
                                                currentCompareMonth &&
                                                handleCompareCellClick({
                                                    chartDetailing: 'D',
                                                    timeRanges: [currentMonthTimeRange, currentCompareMonthTimeRange],
                                                    reportingObjectId: reportingObjectInfo.id,
                                                })
                                            }
                                            value={difference?.percentDifference || null}
                                        >
                                            {difference !== null
                                                ? `${numberWithSpaces(difference.percentDifference)}% ${
                                                      showDifference
                                                          ? `(${numberWithSpaces(difference.difference)})`
                                                          : ''
                                                  }`
                                                : ' —'}
                                        </CompareCellValue>
                                    </Tooltip>
                                ),
                                value: difference !== null ? difference.percentDifference : ' —',
                                forceComponent: true,
                            });
                        } catch (error) {
                            console.log('Compare moths error', error);
                        }
                    });

                    data.push(monthData);
                }
            }
            const header = [
                {
                    component: <HeaderCell>{reportingObjectInfo.name}</HeaderCell>,
                    value: reportingObjectInfo.name,
                    colSpan: headerColSpan + 100,
                    forceComponent: true,
                },
            ];

            data.unshift(header);

            return (
                <TableWrapper key={reportingObjectInfo.id}>
                    <CustomTable data={data} valueRenderer={(cell: any) => cell.value} />
                    {rawMetricsByDate['status'] === 'Loading' && <LoadingWrapper>{t('Loading...')}</LoadingWrapper>}
                    {rawMetricsByDate['status'] === 'Error' && (
                        <LoadingWrapper>{rawMetricsByDate['message'] || 'Some error accured'}</LoadingWrapper>
                    )}
                </TableWrapper>
            );
        });
    }, [extendedReportingObjectsById, yoyTableSettings, lang, t]);

    if (!Object.keys(extendedReportingObjectsById).length) return null;

    return (
        <Wrapper>
            <Modals title={t('Period')} closeModal={handleModalClose} modalStatus={modalStatus} topColor="fff">
                {selectedPeriod && selectedMetrics && (
                    <PeriodModalBody
                        extendedReportingObjectsById={extendedReportingObjectsById}
                        reportingObjectId={selectedPeriod.reportingObjectId}
                        chartDetailing={selectedPeriod.chartDetailing}
                        timeRanges={selectedPeriod.timeRanges}
                        selectedMetric={selectedMetrics[0]}
                    />
                )}
            </Modals>

            {tables}
        </Wrapper>
    );
};

export default Table;
