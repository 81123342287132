import Highcharts, { Chart, Options } from 'highcharts';
import { IDynamicsProps, TSeries } from './interfaces';
import React, { useEffect, useRef, useState } from 'react';

import DynamicsOptions from './components/DynamicsOptions/DynamicsOptions';
import HighchartsReact from 'highcharts-react-official';
import { Wrapper } from './styles';
import { chartSettingsReducerValues } from '../../ChartSettings/reducer';
import generateOptions from './tools/generateOptions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Компонент для отображения графиков типа "Динамика"
 */
const Dynamics: React.FC<IDynamicsProps> = ({ chartId, ...props }) => {
    const { chartSettingsById } = useSelector(chartSettingsReducerValues);
    const [options, setOptions] = useState<Options>();
    const { t } = useTranslation();

    useEffect(() => {
        const extendedOptions = generateOptions({
            chartSettings: chartId ? chartSettingsById[chartId] : undefined,
            t,
            ...props,
        });

        setOptions(extendedOptions as Options);
    }, [chartSettingsById, chartId, t]);

    const afterChartCreated = (chart: Chart) => {
        props.storeChartInstance && props.storeChartInstance(chart);
    };

    return (
        <Wrapper>
            {options && <HighchartsReact callback={afterChartCreated} highcharts={Highcharts} options={options} />}
            {chartId && options && <DynamicsOptions chartId={chartId} series={(options?.series as TSeries[]) || []} />}
        </Wrapper>
    );
};

export default Dynamics;
