import { IPeriodObj } from '../General.interfaces';
import { DateTime } from 'luxon';

export interface IAvailableYear {
    period: IPeriodObj;
    value: number;
    text: string;
    id: string;
}

/**
 * Функция для генерации доступных для выбора лет, начиная с 2016 года по текущий год
 * @returns массив доступных лет
 */
const generateAvailableYears = (): IAvailableYear[] => {
    const result: IAvailableYear[] = [];
    let availableDate = DateTime.now();
    const LAST_YEAR = 2016;

    while (availableDate.year >= LAST_YEAR) {
        const availableYear = availableDate.year;
        result.push({
            id: String(availableYear),
            value: availableYear,
            text: String(availableYear),
            period: {
                dateFrom: DateTime.fromObject({ year: availableYear, month: 1, day: 1 }).toISODate(),
                dateTo: DateTime.fromObject({ year: availableYear, month: 12, day: 31 }).toISODate(),
            },
        });
        availableDate = availableDate.minus({ year: 1 });
    }

    return result;
};

export default generateAvailableYears;
