import { Button, Icon, Tooltip } from '../../../../../../lib/esm/components';
import { FC, useEffect, useRef, useState } from 'react';
import { TChartSettingsKeys, TChartSettingsValues } from '../../../../ChartSettings/interfaces';
import {
    chartSettingsReducerValues,
    storeChartSettingsById,
    updateChartSettingsById,
} from '../../../../ChartSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';

import ContextualMenu from '../../../../ContextualMenu/ContexttualMenu';
import ContextualMenuBody from './components/ContextualMenuBody/ContextualMenuBody';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Отображение иконки с настройкми диаграммы
 * @param onSeriesSelect Функция выбора сер ии, на основе которой построить дополнительную серию
 * @param series все серии (включая новые)
 */
const DynamicsOptions: FC<IProps> = ({ series, chartId }) => {
    const [weekendsHighlightPossibility, setWeekendsHighlightPossibility] = useState(false);
    const { chartSettingsById } = useSelector(chartSettingsReducerValues);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);
    const DAY_IN_MILLIS = 86400000;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            updateChartSettingsById({
                value: weekendsHighlightPossibility,
                key: 'highlightWeekends',
                chartId,
            }),
        );
    }, [weekendsHighlightPossibility]);

    useEffect(() => {
        if (series.length) {
            let flag = false;
            if (series[0].data.length > 1) {
                if (series[0].data[1].x - series[0].data[0].x === DAY_IN_MILLIS) {
                    flag = true;
                }
            }
            setWeekendsHighlightPossibility(flag);
        }
    }, [series]);

    useEffect(() => {
        if (!chartSettingsById[chartId]) {
            dispatch(
                storeChartSettingsById({
                    [chartId]: {
                        selectedSeriesIdsForAverage: [],
                        selectedSeriesIdsForTrend: [],
                        highlightWeekends: false,
                    },
                }),
            );
        }
    }, [chartId, chartSettingsById]);

    const updateChartSettingsByIdTrans = (args: {
        value: TChartSettingsValues;
        key: TChartSettingsKeys;
        chartId: string;
    }) => {
        dispatch(updateChartSettingsById(args));
    };

    const toggleContextualMenuOpen = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    return (
        <Wrapper ref={targetRef}>
            <Tooltip
                calloutProps={{
                    isBeakVisible: false,
                    beakWidth: 0,
                    gapSpace: 10,
                }}
                content={t('Diagram settings')}
            >
                <Button onClick={toggleContextualMenuOpen} padding="l_xxxxs r_xxxxs" theme="icon" type="primary">
                    <Icon size="sm" color="gray-darker" type="setting-bolder" />
                </Button>
            </Tooltip>
            <ContextualMenu
                CustomMenuComponent={
                    <ContextualMenuBody
                        weekendsHighlightPossibility={weekendsHighlightPossibility}
                        updateChartSettingsByIdTrans={updateChartSettingsByIdTrans}
                        chartSettings={chartSettingsById[chartId]}
                        chartId={chartId}
                        series={series}
                    />
                }
                isContextualMenuOpen={isContextualMenuOpen}
                hideContextualMenu={closeContextualMenu}
                eventBubblingEnabled={true}
                targetRef={targetRef}
                overflow={'visible'}
                gapSpace={10}
            />
        </Wrapper>
    );
};

export default DynamicsOptions;
