import styled from 'styled-components/macro';

export const WorkArea = styled.section`
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
`;

export const MainArea = styled.section`
    flex-grow: 1;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    overflow: auto;
`;
export const Main = styled.section`
    width: 100%;
    flex-grow: 1;
`;

export const AlertsLayer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
`;
