import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TCurrentOptions } from '../cabinet/components/UserSettings/interfaces';
import { userSettingsReducerValues } from '../cabinet/components/UserSettings/reducer';
import { generalReducerValues } from '../General.reducer';
import { usePrevious } from './usePrevious';
import { useWidgetCurrentOptions } from './useWidgetCurrentOptions';

const yandexID = 91340986;

export const sendSimpleMetrics = (
    type: string,
    value: string,
    currentModule: string | null = '',
    canSendAnalytics: boolean,
) => {
    const targetID = `${currentModule} >> ${value}`;
    console.log(targetID);
    canSendAnalytics && window['ym'](yandexID, type, targetID);
};

const objectDiff = (a: any, b: any) => fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));

const tabooList = ['compareDateRanges'];

export const useAnalytics = () => {
    const {
        currentModule,
        selectedLocationId,
        user,
        cfg: { locationsById },
        allMetrics,
    } = useSelector(generalReducerValues);
    const { accParamsReceived } = useSelector(userSettingsReducerValues);
    const localCurrentOptions = useWidgetCurrentOptions(currentModule || '');
    const previousValue: TCurrentOptions = usePrevious(localCurrentOptions) as unknown as TCurrentOptions;
    const canSendAnalytics = !user?.permissions.includes('app/client | not-collect-analytics | feature/view');

    useEffect(() => {
        if (!accParamsReceived) return;
        sendSimpleMetrics('reachGoal', 'jump_to_module', currentModule, canSendAnalytics);
        const timeout = setTimeout(() => {
            sendSimpleMetrics('reachGoal', '1_min_presence_inside_module', currentModule, canSendAnalytics);
        }, 60000);
        return () => {
            clearTimeout(timeout);
        };
    }, [currentModule, accParamsReceived]);

    useEffect(() => {
        if (!accParamsReceived) return;

        if (previousValue?.currentModule === localCurrentOptions?.currentModule) {
            const diff = objectDiff(localCurrentOptions, previousValue);
            Object.keys(diff).forEach((key) => {
                if (!tabooList.includes(key)) {
                    if (key === 'selectedMetrics') {
                        const arr = diff[key] as string[];
                        const metricGroups = Array.from(
                            new Set(
                                arr?.map((item) => {
                                    return allMetrics?.find((metric) => metric.id === item)?.section;
                                }),
                            ),
                        );
                        metricGroups.forEach((group) => {
                            sendSimpleMetrics(
                                'reachGoal',
                                `${'groupOfMetris'}_${group}_selected`,
                                currentModule,
                                canSendAnalytics,
                            );
                        });
                    } else {
                        sendSimpleMetrics('reachGoal', `${key}_change`, currentModule, canSendAnalytics);
                    }
                }
            });
        }
    }, [localCurrentOptions, accParamsReceived, previousValue, currentModule]);

    useEffect(() => {
        if (selectedLocationId && accParamsReceived) {
            const pl_name = locationsById?.[selectedLocationId]?.name;
            console.log('========================================');
            console.log('user_id:', user?.id);
            console.log('pl_id:', selectedLocationId);
            console.log('pl_name:', pl_name);
            console.log('========================================');

            canSendAnalytics &&
                window['ym'](yandexID, 'userParams', { pl_name, pl_id: selectedLocationId, user_id: user?.id });
        }
    }, [selectedLocationId, accParamsReceived, canSendAnalytics]);
};
