import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generalReducerValues, reloadCategories } from '../../../../../../../../../../General.reducer';
import { Button, CategoriesAPI } from '../../../../../../../../../../lib/esm/components';
import { Footer, Wrapper } from './styles';

const ModalDeleteSubCategory: React.FC<{
    handleOKClick: () => void;
    modalStatus: { show: boolean; id?: number; mode?: string };
}> = ({ modalStatus, handleOKClick }) => {
    const { token } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onOKClick = () => {
        modalStatus?.id &&
            CategoriesAPI({
                token,
                chapter: 'project-categories',
                id: modalStatus?.id,
                method: 'DELETE',
            }).then(() => {
                dispatch(reloadCategories());
            });
        handleOKClick();
    };

    const warningText = `${t('This will delete')} ${modalStatus.mode} ${t('and all the relations with tenants')}!`;

    return (
        <Wrapper>
            <h4>{warningText}</h4>
            <Footer>
                <Button text={t('Delete')} onClick={onOKClick} />
            </Footer>
        </Wrapper>
    );
};

export default ModalDeleteSubCategory;
