import { AxisLabelsFormatterContextObject, DataLabelsOptions, PointLabelObject } from 'highcharts';
import { TFunction } from 'i18next';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import { IChartDetailing } from '../../../../../../../../constants/chartDetailing';
import { TLang } from '../../../../../../../../lib/esm/cabinet/components/SideBar/configurations';
import datetimeFormatHelper from '../../../../../../../../tools/Strings/datetimeFormatHelper';
import { ISeriesPoint, TSeries } from '../../../../../../Charts/Dynamics/interfaces';
import { subsetsOfSalesMetricsOptions, subsetsSubtypeTextMap, subsetsTextMap } from '../../../constants/contsants';
import { IChartOptions, ISaleBySubset, ISelectedChartType } from '../interfaces';
import { numberWithSpaces } from '../../../../../../../../tools/Strings/numberWithSpaces';

interface IArgs {
    /** Продажи по ключу производной */
    salesBySubset: ISaleBySubset;
    /** Выбранные производные */
    selectedSubsets: ISelectedChartType;
    /** Выбрання метрика */
    selectedMetric: { id: string; text: string; units: string };
    /** Функция перевода */
    t: TFunction;
    /** Флаг является ли график динамикой  */
    isDynamic: boolean;

    lang: TLang;

    selectedChartDetailing: IChartDetailing;
}

/**
 * Функция для генерации настроек диаграммы
 */
const generateChartOptions = (args: IArgs): IChartOptions => {
    const { salesBySubset, selectedSubsets, selectedMetric, isDynamic, t, lang, selectedChartDetailing } = args;
    const series: TSeries[] = [];

    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: this.value as number,
            isCategory: false,
            lang,
            t,
        });
    };

    const tooltipTitleFormatter = (value: string | number) => {
        return datetimeFormatHelper({
            selectedChartDetailing: selectedChartDetailing,
            dateInMillis: value as number,
            isCategory: false,
            lang,
            t,
        });
    };

    const filteredSubsets = Object.keys(salesBySubset)?.filter((element) => selectedSubsets.id.includes(element));
    filteredSubsets.forEach((subset) => {
        const salesFilteredTypes = subsetsOfSalesMetricsOptions?.filter((element) => element.id === subset);
        const sales = salesBySubset[subset];

        if (!isDynamic) {
            salesFilteredTypes[0].subtypes.forEach((saleType) => {
                const salesFiltered = sales?.filter(
                    (element) =>
                        element.metricData[0].context.alias!.split(':')[2] === saleType.id &&
                        element.metricData[0].context.alias!.split(':')[3] === 'main',
                );

                const seriesData: ISeriesPoint[] = [];
                salesFiltered.forEach((sale) => {
                    const data = sale.metricData[0].items;
                    data.forEach((item) => {
                        seriesData.push({
                            name: t(subsetsSubtypeTextMap[sale.metricData[0].context.alias!.split(':')[2]]),
                            title: t(subsetsTextMap[subset]),
                            units: t(selectedMetric.units),
                            y: item.value,
                            // y: randomIntFromInterval(1, 100),
                            x: DateTime.fromISO(item.time).toMillis(),
                        } as unknown as ISeriesPoint);
                    });
                });

                series.push({
                    name: t(subsetsSubtypeTextMap[saleType.id]),
                    id: saleType.id,
                    data: seriesData,
                    type: 'column',
                    isMain: false,
                });
            });
        } else {
            salesFilteredTypes[0].subtypes.forEach((saleType) => {
                const salesFiltered = sales?.filter(
                    (element) => element.metricData[0].context.alias!.split(':')[2] === saleType.id,
                );
                const salesElementsMainPeriod = salesFiltered?.filter(
                    (element) => element.metricData[0].context.alias!.split(':')[3] === 'main',
                );
                const salesElementsComparePeriod = salesFiltered?.filter(
                    (element) => element.metricData[0].context.alias!.split(':')[3] === 'compare',
                )[0].metricData[0].items;
                const seriesData: ISeriesPoint[] = [];
                salesElementsMainPeriod[0].metricData[0].items.forEach((saleItem, index) => {
                    let value = 0;
                    if (!isNull(saleItem.value) && !isNull(salesElementsComparePeriod[index].value)) {
                        value =
                            ((Number(saleItem.value) - Number(salesElementsComparePeriod[index].value)) /
                                Number(salesElementsComparePeriod[index].value)) *
                            100;
                    }

                    seriesData.push({
                        units: `%`,
                        name: t(
                            subsetsSubtypeTextMap[
                                salesElementsMainPeriod[0].metricData[0].context.alias!.split(':')[2]
                            ],
                        ),
                        title: t(subsetsTextMap[subset]),
                        y: Number(numberWithSpaces(value.toFixed(2))),
                        // y: randomIntFromInterval(-100, 100),
                        x: DateTime.fromISO(saleItem.time).toMillis(),
                    } as unknown as ISeriesPoint);
                });

                series.push({
                    name: t(subsetsSubtypeTextMap[saleType.id]),
                    id: saleType.id,
                    data: seriesData,
                    type: seriesData.length === 1 ? 'column' : 'spline',
                    isMain: true,
                });
            });
        }
    });

    return {
        tooltipSubTitle: `${t(selectedMetric.text)}`,
        columnDataLabelsEnabled: false,
        inactiveSeriesOpacity: 0.2,
        columnsStacking: 'normal',
        columnsGrouping: !isDynamic,
        tooltipTitleFormatter,
        xAxisLabelsFormating,
        sharedTooltips: isDynamic,
        numberOfSeriesToDispaly: null,
        series,
    };
};

export default generateChartOptions;

/**
 * Генератор тестовых данных
 */
function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
