export const peopleCountingMetricsData = [
    {
        label: 'Traffic',
        content:
            "The number of visitors entering the selected object. For a shopping center, this is the attendance of all entrance groups, and for a tenant, this is the number of people who entered the tenant's premises. Zone attendance is counted as the sum of visitors entering through its passages. Important: not unique visitors are counted, but the number of passes. Thus, the attendance of the zone may be higher than the attendance of the perimeter of the shopping center due to the fact that people inside can enter several times in the same zone. Passage attendance is the number of people who went through the passage to both the entrance and the exit.",
    },
    {
        label: 'Traffic (out)',
        content:
            'The number of visitors who left the selected property. Attendance Exit is a service metric, which, in contrast to attendance at the entrance, can be a little overpriced, this happens if visitors take out large-sized goods with a height of more than 120 cm. Attendance aisles is the number of people who passed through the aisle and at the entrance and exit.',
    },
    {
        label: 'Traffic/m2',
        content:
            'The number of visitors entered per square meter of the corresponding object. This metric is especially useful when comparing tenants, categories, and zones with each other. Attendance / m2 shows how well the facility attracts visitors.',
    },
];
export const shopsterMetricsData = [
    {
        label: 'Average visit duration',
        content:
            'The average duration of visitors staying within the selected location during the visit. For a shopping center, floor, zone, tenant, this is the time spent from the moment the visitor’s entry signal appeared until the visitor exited or disconnected the visitor’s wifi device. The average duration depends on the behavior of visitors in the store - for example, in a cosmetics store, a high average duration can indicate that buyers choose a product with consultants, and that visitors can look for the desired product longer without the attention of the consultants.',
    },
    {
        label: 'Visitors (total)',
        content:
            'The number of visits by visitors that were assigned to visitors, that is, are not staff or stationary signals. The number of visits is the number of visits.',
    },
    {
        label: 'Visitors (unique)',
        content:
            'The number of unique visitors that were assigned to visitors, that is, are not staff or stationary signals. The number of unique visitors is the number of MAC addresses.',
    },
    {
        label: 'Capture rate',
        content:
            'Percentage of unique visitors who were in the coverage area of the router and entered the shopping center, floor, zone, tenant. The approximate range of the WiFi sensor in the premise is 50 meters.',
    },
    {
        label: 'Engagement rate',
        content:
            "Percentage of unique visitors who spent more than the engagement threshold in the selected location. The engagement threshold is set in the Shopster metrics settings - the gear in the 'Metrics'.",
    },
    {
        label: 'Bounced rate',
        content:
            "Percentage of unique visitors who spent less than the engagement threshold in the selected location. The engagement threshold is set in the Shopster metrics settings - the gear in the 'Metrics'.",
    },
    {
        label: 'Returned rate',
        content:
            'Percentage of unique visitors who visited the selected location repeatedly during the selected return period. The return period is set in the Shopster metrics settings.',
    },
    {
        label: 'New visitors rate',
        content:
            "Percentage of unique visitors who were not in the selected location during the selected return period. The return period is set in the Shopster metrics settings - the gear in the 'Metrics'.",
    },
    {
        label: 'Intersections for the period',
        content:
            'Percentage of unique visitors who have visited the main location and moved to another target location for the entire selected period. Locations can be tenants and zones. ',
    },
    {
        label: 'Average daily intersections',
        content:
            'Percentage of unique visitors who visited the main location and moved to another target location for the period averaged by day. Locations can be tenants and zones. ',
    },
    {
        label: 'Average visit duration (engaged)',
        content:
            "The average duration of engaged visitors is the time spent at the location from the moment when visitor's entry signal appears until the moment the visitor exits or turns off the visitor's wifi device, excluding visitors who are below the engagement threshold. ",
    },
];

export const salesFlowMetricsData = [
    {
        label: 'Sales',
        content:
            'Tenant sales including VAT. For a shopping center, this is the sale of tenants connected to the ASCC. Depending on the type of connection, information may be available by the hour, or by the day. ',
    },
    {
        label: 'Sales/m²',
        content:
            'Sales volume including VAT divided by the area of object. For a shopping center, this is the sales volume of tenants divided by the area they occupy.',
    },
    {
        label: 'VAT',
        content:
            'The amount of value added tax paid on the sales of the tenant. For a shopping center, this is the amount of VAT paid by tenants connected to the Automated Sales Flow System.',
    },
    {
        label: 'Sales excl. VAT',
        content:
            "Tenants's sales excluding VAT. For a shopping center, this is summary of tenant's sales connected to the Automated Sales Flow System. Depending on the type of connection, information may be available by hour or by day.",
    },
    {
        label: 'Sales excl. VAT/m²',
        content:
            "Tenants' sales excluding VAT divided by the area of the object. For a shopping center, this is a summary of tenants' sales without VAT divided by the area they occupy. ",
    },
    {
        label: 'Receipts',
        content:
            "The number of tenant's checks. It may not be displayed correctly if the tenant unloads sales with one check per day. ",
    },
    {
        label: 'Quantity of goods',
        content:
            'The number of items sold. It may not display correctly when detailed by the hour, if there is a tenant sending data in the format of a collapsed check.',
    },
    {
        label: 'Average check',
        content:
            'Sold items divided by the number of receipts. It may not be displayed correctly if the tenant unloads sales with one check per day.',
    },
    {
        label: 'Conversion',
        content:
            'The number of checks divided by the number of visitors to the property. It may not be displayed correctly if the tenant unloads sales with one check per day.',
    },
    {
        label: 'Sales returns',
        content:
            "The amount of tenant's sales returns in currency. For a shopping center, this is the volume of sales returns of tenants connected to the Automated Sales Flow System.",
    },
    {
        label: 'Share of refunds',
        content: 'Refunds of tenant sales divided by tenant sales.',
    },
];
