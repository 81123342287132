import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../General.reducer';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import { OptionsWrapper } from './styles/styles';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../Preset/Preset';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import PaymentStructure from './widgets/PaymentStructure/PaymentStructure';
import StructureDynamics from './widgets/StructureDynamics/StructureDynamics';
import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import {
    subsetsOfSalesMetricsOptions,
    subsetsOfSalesMetricsSelect,
    paymentAnalysisMetricsSelect,
    paymentAnalysisTenantSelect,
} from './constants/contsants';
import { useTranslation } from 'react-i18next';
import UniversalSelect from '../../../Selects/UniversalSelect/UniversalSelect';
import MetricsSelect from '../../../Selects/MetricsSelect/MetricsSelect';
import { ISettings } from '../../../UserSettings/interfaces';
import SalesOfTenants from './widgets/SalesOfTenants/SalesOfTenants';

/**
 * Компонент для отображения модуля Анализ платежей
 */
const PaymentAnalysis: React.FC = ({}) => {
    const {
        allMetrics,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const availableIdsOfMetrics = ['fsf_sales_vat', 'fsf_sales_novat'];

    const tenantSelectOptions = useMemo(() => {
        return reportingObjectsByType['tenant']?.map((item) => ({ id: item.id, text: item.name }));
    }, [reportingObjectsByType]);

    const metricSelectOptions = useMemo(() => {
        return allMetrics?.filter((metric) => availableIdsOfMetrics.includes(metric.id));
    }, [allMetrics]);

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect />
                        <ComparePeriodSelect mode="single" />
                        <UniversalSelect
                            name={paymentAnalysisTenantSelect}
                            options={tenantSelectOptions}
                            text={t('Select tenant')}
                            labelText={t('Tenant')}
                            dropdownWidth="sm"
                            mode="single"
                            showFilter
                        />
                        <UniversalSelect
                            name={paymentAnalysisMetricsSelect}
                            options={metricSelectOptions}
                            text={t('Select metric')}
                            labelText={t('Metric')}
                            dropdownWidth="sm"
                            mode="single"
                            showFilter
                        />
                        <UniversalSelect
                            options={subsetsOfSalesMetricsOptions}
                            defaultSelected={subsetsOfSalesMetricsOptions}
                            name={subsetsOfSalesMetricsSelect}
                            text={t('Select subsets')}
                            labelText={t('Subsets')}
                            dropdownWidth="sm"
                            mode="multi"
                            showFilter
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    subsetsOfSalesMetricsSelect,
                                    paymentAnalysisMetricsSelect,
                                    paymentAnalysisTenantSelect,
                                    'selectedMetrics',
                                    'comparePeriods',
                                    'mainPeriod',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
                <PaymentStructure />
                <StructureDynamics />
                <SalesOfTenants />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default PaymentAnalysis;
