import { __assign } from '../../_virtual/_tslib.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import Button from '../Button/index.js';
import Icon from '../Icon/index.js';
import { DateTime, Interval } from 'luxon';
import { MainWrapper, Main, CalendarWrapper, ShifterLine, IconWrapper, Footer } from './styles/styles.js';
import { applyBtnText } from './const/index.js';
import { getRanges, getResult } from './core/index.js';
import MonthsGrid from './components/MonthsGrid/index.js';
import Header from './components/Header/index.js';
import YearsGrid from './components/YearsGrid/index.js';

var DateRangePicker = function (_a) {
    var _b, _c;
    var _d = _a.lang, lang = _d === void 0 ? 'ru' : _d, handleDateRangeChange = _a.handleDateRangeChange, _e = _a.oneDayOnly, oneDayOnly = _e === void 0 ? false : _e, _f = _a.yearsOnly, yearsOnly = _f === void 0 ? false : _f, _g = _a.showFutureDates, showFutureDates = _g === void 0 ? false : _g;
    var _h = useState(0), shift = _h[0], setShift = _h[1];
    var _j = useState('months'), mode = _j[0], setMode = _j[1];
    var _k = useState(null), firstSelectedDate = _k[0], setFirstSelectedDate = _k[1];
    var _l = useState(null), secondSelectedDate = _l[0], setSecondSelectedDate = _l[1];
    useEffect(function () {
        if (yearsOnly) {
            setMode(function () { return 'years'; });
        }
    }, [yearsOnly]);
    var now = DateTime.now();
    var end = now.plus((_b = {}, _b[mode] = 1 + shift, _b));
    var start = end.minus((_c = {}, _c[mode] = 2, _c));
    var interval = Interval.fromDateTimes(start, end);
    var allMonthsOfCalendar = Array.from(getRanges(interval, 'year')).map(function (startDay) {
        var endDay = startDay.endOf('year');
        var range = Interval.fromDateTimes(startDay, endDay);
        return Array.from(getRanges(range, 'month'));
    });
    var allDaysOfCalendar = Array.from(getRanges(interval, 'month')).map(function (startDay) {
        var endDay = startDay.endOf('month');
        var range = Interval.fromDateTimes(startDay, endDay);
        return Array.from(getRanges(range, 'day')).map(function (day) {
            return { day: day, weekDay: day.weekday };
        });
    });
    var onShiftClick = function (shift) { return function () {
        setShift(function (prev) { return prev + shift; });
    }; };
    var onApplyClick = function () {
        if (firstSelectedDate && secondSelectedDate) {
            var res = getResult(firstSelectedDate, secondSelectedDate);
            if (handleDateRangeChange) {
                var result = { dateFrom: res.dateFrom.toISODate(), dateTo: res.dateTo.endOf('day').toISODate() };
                if (mode === 'years') {
                    if (!oneDayOnly) {
                        result = { dateFrom: res.dateFrom.toISODate(), dateTo: res.dateTo.endOf('month').toISODate() };
                    }
                    else {
                        result = { dateFrom: res.dateFrom.toISODate(), dateTo: res.dateTo.toISODate() };
                    }
                }
                handleDateRangeChange(result);
            }
        }
    };
    function firstDateDidChanged(d) {
        setFirstSelectedDate(d);
    }
    function secondDateDidChanged(d) {
        setSecondSelectedDate(d);
    }
    function modeDidChanged(mode) {
        setMode(function () { return mode; });
    }
    return (jsxs(MainWrapper, { children: [!yearsOnly && (jsx(Header, { lang: lang, mode: mode, modeDidChanged: modeDidChanged, firstSelectedDate: firstSelectedDate, secondSelectedDate: secondSelectedDate }, void 0)), jsx(Main, { children: jsxs(CalendarWrapper, { children: [jsxs(ShifterLine, __assign({ shift: shift }, { children: [jsx(IconWrapper, __assign({ onClick: onShiftClick(-1) }, { children: jsx(Icon, { type: "chevron-left", size: "xxs" }, void 0) }), void 0), (showFutureDates || shift < 0) && (jsx(IconWrapper, __assign({ onClick: onShiftClick(1) }, { children: jsx(Icon, { type: "chevron-right", size: "xxs" }, void 0) }), void 0))] }), void 0), mode === 'months' ? (jsx(MonthsGrid, { allDaysOfCalendar: allDaysOfCalendar, lang: lang, showFutureDates: showFutureDates, firstDateDidChanged: firstDateDidChanged, secondDateDidChanged: secondDateDidChanged, oneDayOnly: oneDayOnly }, void 0)) : (jsx(YearsGrid, { allMonthsOfCalendar: allMonthsOfCalendar, lang: lang, showFutureDates: showFutureDates, firstDateDidChanged: firstDateDidChanged, secondDateDidChanged: secondDateDidChanged, oneDayOnly: oneDayOnly }, void 0))] }, void 0) }, void 0), jsx(Footer, { children: jsx(Button, { onClick: onApplyClick, text: applyBtnText[lang], type: "primary", disabled: !firstSelectedDate || !secondSelectedDate }, void 0) }, void 0)] }, void 0));
};

export { DateRangePicker as default };
