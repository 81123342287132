import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IExtendedEvent } from '../interfaces';
import {
    Events_EventsMap_Widget_Reducer_Values,
    storeEventsToUpdate,
    storeEventTypesByName,
    storeEventTypeToUpdate,
    storeExtendedEventsDataById,
} from '../reducer';

const usePrepareData = () => {
    const { rawEventsData, extendedEventsDataById, eventTypeToUpdate, eventTypes } = useSelector(
        Events_EventsMap_Widget_Reducer_Values,
    );
    const dispatch = useDispatch();

    /** Получение расширенного объекта событий */
    useEffect(() => {
        dispatch(storeExtendedEventsDataById({}));
        if (rawEventsData?.length && eventTypes?.length) {
            const result: { [id: string]: IExtendedEvent } = rawEventsData.reduce((acc, item) => {
                const eventType = eventTypes?.find((et) => et.id === item.event_type);
                acc[item.id] = {
                    ...item,
                    index: 0,
                    date_from: DateTime.fromISO(item.date_from)
                        .setZone(item.timezone)
                        .toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                    date_to: DateTime.fromISO(item.date_to).setZone(item.timezone).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
                    event_type_color: eventType?.color,
                    event_type_name: eventType?.name,
                };
                return acc;
            }, {});

            cloneDeep(rawEventsData)
                .sort((a, b) =>
                    DateTime.fromISO(a.date_from).toMillis() < DateTime.fromISO(b.date_from).toMillis() ? -1 : 1,
                )
                .forEach((rawEventData) => {
                    const currentPeriodEvents = rawEventsData?.filter((element) => {
                        return (
                            rawEventData.id !== element.id &&
                            ((DateTime.fromISO(rawEventData.date_from) >= DateTime.fromISO(element.date_from) &&
                                DateTime.fromISO(rawEventData.date_from) <= DateTime.fromISO(element.date_to)) ||
                                (DateTime.fromISO(rawEventData.date_from) <= DateTime.fromISO(element.date_from) &&
                                    DateTime.fromISO(rawEventData.date_to) >= DateTime.fromISO(element.date_to)) ||
                                (DateTime.fromISO(rawEventData.date_from) <= DateTime.fromISO(element.date_from) &&
                                    DateTime.fromISO(rawEventData.date_to) >= DateTime.fromISO(element.date_from)))
                        );
                    });
                    if (currentPeriodEvents.length) {
                        const allIndexs = currentPeriodEvents.map((element) => result[element.id].index);
                        const maxIndex = Math.max(...allIndexs);
                        let index = maxIndex + 1;

                        for (let i = 1; i <= maxIndex; i++) {
                            if (!allIndexs.includes(i) && index === maxIndex + 1) {
                                index = i;
                            }
                        }

                        result[rawEventData.id].index = index;
                    } else {
                        result[rawEventData.id].index = 1;
                    }
                });

            dispatch(storeExtendedEventsDataById(result));
        }
    }, [rawEventsData, eventTypes]);

    /** Получение Всех типов событий */
    useEffect(() => {
        dispatch(storeEventTypesByName({}));
        if (Object.keys(extendedEventsDataById).length) {
            const result = Object.values(extendedEventsDataById).reduce((acc, value) => {
                if (!acc[value.event_type]) {
                    acc[value.event_type] = {
                        name: value.event_type_name,
                        color: value.event_type_color,
                    };
                }
                return acc;
            }, {});

            dispatch(storeEventTypesByName(result));
        }
    }, [extendedEventsDataById]);

    /** Запись событий для обновления при изменении цвета типа события */
    // useEffect(() => {
    //     if (eventTypeToUpdate) {
    //         if (Object.keys(extendedEventsDataById).length) {
    //             const eventsToUpdate: IExtendedEvent[] = Object.values(extendedEventsDataById)
    //                 ?.filter((element) => element.event_type === eventTypeToUpdate.name)
    //                 .map((element) => ({ ...element, event_type_color: eventTypeToUpdate.color }));
    //             dispatch(storeEventsToUpdate(eventsToUpdate));
    //         }
    //         dispatch(storeEventTypeToUpdate(null));
    //     }
    // }, [eventTypeToUpdate, extendedEventsDataById]);
};

export default usePrepareData;
