import { FC, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modals from '../../../../../../../../../components/Modals';
import {
    Button,
    DateRangePicker,
    Select,
    TextInput,
    TSelectedOptions,
} from '../../../../../../../../../lib/esm/components';
import { ButtonsWrapper, InputWrapper, Label, HalfWidthWrapper, Wrapper } from './styles/styles';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { stringDate } from '../../../../../../../../../tools/Strings/stringDate';
import { defaultEventTypes } from '../../constants/constants';
import ContextualMenu from '../../../../../../../ContextualMenu/ContexttualMenu';
import EventTypeContextualMenuBody from './components/EventTypeContextualMenuBody/EventTypeContextualMenuBody';
import { ActionKind } from './interfaces';
import ReportingObjectsSelect from './components/ReportingObjectsSelect/ReportingObjectsSelect';
import { IExtendedEvent } from '../../interfaces';
import './styles/styles.scss';
import DeleteConfirmModalBody from './components/DeleteConfirmModalBody/DeleteConfirmModalBody';
import { initialState, reducer } from './reducer';
import { EventsMapWidgetContext } from '../../context';
import { useWidgetCurrentOptions } from '../../../../../../../../../tools/useWidgetCurrentOptions';
import { Stack, StackItem } from '@fluentui/react';
import { Events_EventsMap_Widget_Reducer_Values } from '../../reducer';

interface IProps {
    /** Выбранное событие */
    event?: IExtendedEvent;
}

/**
 * Компонент для отображения тела модального окна для создания нового ивента или новых
 */
const EventModalBody: FC<IProps> = ({ event }) => {
    const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] = useState({ show: false });
    const [eventTypeContextualMenuOpen, setEventTypeContextualMenuOpen] = useState(false);
    const [periodModalStatus, setPeriodModalStatus] = useState({ show: false });
    const localCurrentOptions = useWidgetCurrentOptions('Events:Events map');
    const [state, dispatch] = useReducer(reducer, initialState);
    const context = useContext(EventsMapWidgetContext);
    const {
        lang,
        timeZone,
        selectedLocationId,
        cfg: { reportingObjectsById },
    } = useSelector(generalReducerValues);
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const eventTypeRef = useRef(null);
    const { t } = useTranslation();

    /** Запись событий, которые нужно обновить */
    useEffect(() => {
        if (state.eventToUpdate && context) {
            context.storeEventsToUpdateTrans([state.eventToUpdate]);
            dispatch({ type: ActionKind.StoreEventToUpdate, payload: null });
            context.closeEventModal();
        }
    }, [state.eventToUpdate]);

    /** Запись новых событий */
    useEffect(() => {
        if (state.eventsToCreate.length && context) {
            context.storeNewEventsTrans(state.eventsToCreate);
            dispatch({ type: ActionKind.ResetReducer, payload: null });
            context.closeEventModal();
        }
    }, [state.eventsToCreate]);

    /** Запись данных в поля, если было выбрано событие */
    useEffect(() => {
        dispatch({ type: ActionKind.ResetReducer, payload: null });
        if (event) {
            const eventType = eventTypes?.find((item) => item.id === event.event_type);
            dispatch({
                type: ActionKind.FillDataFromEvent,
                payload: { event: { ...event, eventType }, reportingObjectsById },
            });
        }
    }, [event]);

    // useEffect(() => {
    //     if (
    //         state.eventType &&
    //         defaultEventTypes?.filter(
    //             (element) =>
    //                 element.toLowerCase().includes(state.eventType.toLowerCase()) && element !== state.eventType,
    //         ).length
    //     ) {
    //         setEventTypeContextualMenuOpen(true);
    //     } else {
    //         setEventTypeContextualMenuOpen(false);
    //     }
    // }, [state.eventType]);

    const openPeriodModal = () => {
        setPeriodModalStatus({ show: true });
    };

    const closePeriodModal = () => {
        setPeriodModalStatus({ show: false });
    };

    // const closeEventTypeContextualMenu = () => {
    //     setEventTypeContextualMenuOpen(false);
    // };

    const closeDeleteConfirmModal = () => {
        setDeleteConfirmModalStatus({ show: false });
    };

    const onDelete = () => {
        setDeleteConfirmModalStatus({ show: true });
    };

    const onNameChange = (args: { value: string; name: undefined; id: undefined }) => {
        dispatch({ type: ActionKind.StoreName, payload: args.value });
    };

    const handleDateRangeChange = (args: { dateFrom: string; dateTo: string }) => {
        dispatch({ type: ActionKind.StorePeriod, payload: args });
        closePeriodModal();
    };

    // const onEventTypeChange = (args: { value: string; name: undefined; id: undefined }) => {
    //     dispatch({ type: ActionKind.StoreEventType, payload: args.value });
    // };

    // const onEventTypeSelect = (value: string) => {
    //     dispatch({ type: ActionKind.StoreEventType, payload: value });
    //     setEventTypeContextualMenuOpen(false);
    // };

    const storeSelectedReportingObjectsIds = (ids: number[]) => {
        dispatch({ type: ActionKind.StoreSelectedReportingObjectsIds, payload: ids });
    };

    const onCommentChange = (args: { value: string; name: undefined; id: undefined }) => {
        dispatch({ type: ActionKind.StoreComment, payload: args.value });
    };

    const timeFromHandler = (args: { value: string }) => {
        args.value && dispatch({ type: ActionKind.StoreTimeFrom, payload: args.value });
    };

    const timeToHandler = (args: { value: string }) => {
        args.value && dispatch({ type: ActionKind.StoreTimeTo, payload: args.value });
    };

    const onUpdate = () => {
        if (event && context) {
            dispatch({
                type: ActionKind.StoreEventToUpdate,
                payload: { eventTypesByName: context.eventTypesByName, event },
            });
        }
    };

    const onSubmit = useCallback(() => {
        if (context && selectedLocationId && timeZone) {
            dispatch({
                type: ActionKind.StoreEventsToCreate,
                payload: {
                    eventTypesByName: context.eventTypesByName,
                    projectLocationId: selectedLocationId,
                    timeZone,
                },
            });
        }
    }, [context, selectedLocationId, timeZone]);

    const eventTypeOptions = eventTypes.map((item) => ({ ...item, text: item.name }));

    const selectedEventType = useMemo(() => {
        return state.eventType ? [{ ...state.eventType, text: state.eventType?.name }] : null;
    }, [state.eventType]);

    const handleEventTypeSelect = (args: TSelectedOptions) => {
        dispatch({ type: ActionKind.StoreEventType, payload: args[0] });
    };

    return (
        <Wrapper>
            <Modals title="" topColor="fff" modalStatus={periodModalStatus} closeModal={closePeriodModal}>
                <DateRangePicker showFutureDates={true} handleDateRangeChange={handleDateRangeChange} lang={lang} />
            </Modals>
            <Modals
                modalStatus={deleteConfirmModalStatus}
                closeModal={closeDeleteConfirmModal}
                title={t('Delete Event')}
                topColor="fff"
            >
                <DeleteConfirmModalBody closeModal={closeDeleteConfirmModal} eventId={event?.id} />
            </Modals>
            <InputWrapper>
                <Label isRequired={true}>{t('Event name')}</Label>
                <TextInput
                    handleChange={onNameChange}
                    hasError={state.nameError}
                    outsideValue={state.name}
                    fullWidth={true}
                />
            </InputWrapper>
            <InputWrapper>
                <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                    <HalfWidthWrapper>
                        <Label isRequired={true}>{t('Event type')}</Label>
                        <div key={String(selectedEventType)}>
                            <Select
                                fullWidth={true}
                                showFilter={false}
                                options={eventTypeOptions}
                                mode="single"
                                handleSelect={handleEventTypeSelect}
                                outsideSelected={selectedEventType}
                                text={t('Select event type')}
                                labelText=""
                                iconType="chevron-down"
                            />
                        </div>
                    </HalfWidthWrapper>
                    <HalfWidthWrapper>
                        <Label isRequired={true}>{t('Event period')}</Label>
                        <div>
                            <Button
                                addClasses="width100"
                                type="link"
                                onClick={openPeriodModal}
                                text={
                                    state.period.dateFrom
                                        ? `${
                                              state.period.dateFrom === state.period.dateTo
                                                  ? stringDate(state.period.dateFrom, lang, '', 'd MMMM yyyy')
                                                  : stringDate(
                                                        {
                                                            dateFrom: state.period.dateFrom,
                                                            dateTo: state.period.dateTo,
                                                        },
                                                        lang,
                                                        '',
                                                        'd MMMM yyyy',
                                                    )
                                          }`
                                        : t('Select event period')
                                }
                            />
                        </div>
                    </HalfWidthWrapper>
                </Stack>
            </InputWrapper>
            {state.period.dateFrom && (
                <InputWrapper>
                    <Stack horizontal={true} verticalAlign={'center'} tokens={{ childrenGap: 10 }}>
                        <HalfWidthWrapper>
                            <Label isRequired={true}>{t('Time from')}</Label>
                            <TextInput
                                handleChange={timeFromHandler}
                                outsideValue={state.timeFrom}
                                hasError={state.timeFromError}
                                placeholder="00:00"
                                fullWidth={true}
                            />
                        </HalfWidthWrapper>
                        <HalfWidthWrapper>
                            <Label isRequired={true}>{t('Time to')}</Label>
                            <TextInput
                                handleChange={timeToHandler}
                                outsideValue={state.timeTo}
                                hasError={state.timeToError}
                                placeholder="00:00"
                                fullWidth={true}
                            />
                        </HalfWidthWrapper>
                    </Stack>
                </InputWrapper>
            )}
            <InputWrapper>
                <Label isRequired={true}>{t('Reporting objects')}</Label>
                <ReportingObjectsSelect
                    selectedReportingObjectsIdsError={state.selectedReportingObjectsIdsError}
                    storeSelectedReportingObjectsIds={storeSelectedReportingObjectsIds}
                    selectedReportingObjectsIds={state.selectedReportingObjectsIds}
                />
            </InputWrapper>
            <InputWrapper>
                <Label isRequired={false}>{t('Comment')}</Label>
                <TextInput
                    handleChange={onCommentChange}
                    outsideValue={state.comment}
                    isMultiLine={true}
                    fullWidth={true}
                />
            </InputWrapper>
            <ButtonsWrapper>
                {event ? (
                    <>
                        <Button
                            disabled={!!state.eventsToCreate.length || !!state.eventToUpdate}
                            addClasses="margin-left"
                            onClick={onDelete}
                            type="accent"
                        >
                            {t('Delete')}
                        </Button>
                        <Button
                            disabled={!!state.eventsToCreate.length || !!state.eventToUpdate}
                            addClasses="margin-left"
                            onClick={onUpdate}
                        >
                            {t('Update')}
                        </Button>
                    </>
                ) : (
                    <Button disabled={!!state.eventsToCreate.length || !!state.eventToUpdate} onClick={onSubmit}>
                        {t('Add')}
                    </Button>
                )}
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default EventModalBody;
