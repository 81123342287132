import { IObject, TSelectedOptions } from '../../../../lib/esm/components/Select/configurations';
import { userSettingsReducerValues, storeUniversalOptions, storeUniversalTextInput } from '../../UserSettings/reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Select, TextInput } from '../../../../lib/esm/components';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { IProps } from './interfaces';
import { Label, Wrapper } from './styles';

/**
 * Компонент универсального селетка
 */
const UniversalTextInput: React.FC<IProps> = ({
    iconType = 'chevron-down',
    type = 'text',
    min,
    labelText = ' ',
    name,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { selectedLocationId, currentModule } = useSelector(generalReducerValues);
    const { currentOptions } = useSelector(userSettingsReducerValues);

    // useEffect(() => {
    //     if (!currentOptions?.[name]?.length && options?.[0] && selectedLocationId) {
    //         dispatch(
    //             storeUniversalOptions({
    //                 selectedOptions: [options?.[0]],
    //                 locationId: selectedLocationId,
    //                 module: currentModule,
    //                 name,
    //             }),
    //         );
    //     }
    // }, [currentModule, currentOptions, dispatch, name, options, selectedLocationId]);

    const handleChange = (args: { id: number | undefined; name: string | undefined; value: string }) => {
        selectedLocationId &&
            dispatch(
                storeUniversalTextInput({
                    locationId: selectedLocationId,
                    module: currentModule,
                    data: args.value,
                    name,
                }),
            );
    };

    const value = currentOptions[name] || '';

    return (
        <Wrapper>
            <Label>{labelText}</Label>
            <TextInput outsideValue={value} handleChange={handleChange} type={type} minNumber={min} />
        </Wrapper>
    );
};

export default UniversalTextInput;
