import ComparePeriodSelect from '../../../Selects/ComparePeriodSelect/ComparePeriodSelect';
import WrapperContainer from '../../../Wrappers/WrapperContainer/WrapperContainer';
import MainPeriodSelect from '../../../Selects/MainPeriodSelect/MainPeriodSelect';
import SelectsWrapper from '../../../Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../Wrappers/PresetsWrapper/PresetsWrapper';
import VisitorsInsideWidget from './widgets/VisitorsInside/VisitorsInside';
import ModuleWrapper from '../../../Wrappers/ModuleWrapper/ModuleWrapper';
import ObjectsSelect from '../../../Selects/ObjectsSelect/ObjectsSelect';
import { OptionsWrapper } from './styles/styles';
import { useTranslation } from 'react-i18next';
import Preset from '../../../Preset/Preset';
import { FC } from 'react';
import { generalReducerValues } from '../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { objectTypesMap } from '../../../../../tools/getReportingObjects';

/**
 * Компонент для отображения модуля VisitorsInside
 */
const VisitorsInside: FC = ({}) => {
    const { t } = useTranslation();

    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);

    const validObjectTypes = Object.keys(reportingObjectsByType)
        ?.filter((key) => key !== 'passway')
        .map((key) => {
            return t(objectTypesMap[key]) || key;
        });

    const validMainPeriods = ['today', 'yesterday', 'selectCustom'];

    return (
        <ModuleWrapper>
            <WrapperContainer>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect validOptions={validMainPeriods} oneDayOnly defaultPeriod="today" />
                        <ComparePeriodSelect oneDayOnly />
                        <ObjectsSelect maxSelected={5} validOptions={validObjectTypes} />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset currentOptionsKeys={['comparePeriods', 'mainPeriod', 'selectedReportingObjectsIds']} />
                    </PresetsWrapper>
                </OptionsWrapper>
                <VisitorsInsideWidget />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default VisitorsInside;
