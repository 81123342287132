import { IReportingObject } from '../../../../../../../../../../../General.interfaces';
import { useTranslation } from 'react-i18next';
import { OptionWrapper, Wrapper } from './styles';
import { IProps } from './interfaces';
import { FC } from 'react';

/**
 * Компонент для отображения тела дропдауна
 * @param handleSelect Функция для обработки выбора
 * @param tenantData даные арендатора
 */
const DropdownBody: FC<IProps> = ({ tenantData, handleSelect }) => {
    const { t } = useTranslation();
    const options = [
        {
            path: '/leasing/tenant-overview',
            module: 'Leasing:Tenant overview',
            text: t('Tenant Analysis'),
            id: 'TenantAnalysis',
            tenantData,
        },
        {
            path: '/performance/year-over-year',
            module: 'Performance:Year over year',
            text: t('Retrospective'),
            id: 'Retrospective',
            tenantData,
        },
    ];

    const onOptionClick = (tenantData: IReportingObject, module: string, path: string) => () => {
        handleSelect({ tenantData, module, path });
    };
    return (
        <Wrapper>
            {options.map((option) => {
                return (
                    <OptionWrapper
                        onClick={onOptionClick(option.tenantData, option.module, option.path)}
                        key={option.id}
                    >
                        {option.text}
                    </OptionWrapper>
                );
            })}
        </Wrapper>
    );
};

export default DropdownBody;
