
import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { Icon } from '../../../../../../../../../lib/esm/components';
import { HeaderContainer, IconContainer } from './styles';
import cn from 'classnames';

const Header: React.FC<{text: string, handleHeaderClick: (x: boolean) => void}> = ({text, handleHeaderClick}) => {
    let asc:boolean = true;
    const [ascStatus, setAscSort] = useState(asc);
    useEffect(() => {
        setAscSort(() => asc);
    }, [asc]);

    const iconClassName = useMemo(() => {
        return cn({ 'sort__icon--rotate': !ascStatus });
    }, [ascStatus]);

    const onHeaderClick = () => {
        handleHeaderClick(!ascStatus);
        setAscSort(!ascStatus);
    };
    return (
        <HeaderContainer onClick={onHeaderClick}>
            {text}
            <IconContainer className={iconClassName}>
                <Icon
                    size="xxxs"
                    type="caret-down"
                />
            </IconContainer>
            
        </HeaderContainer>
    );
};
export default Header;
