import { AxiosError, AxiosResponse } from 'axios';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../../../../../../tools/API/appBackendAPI';
import {
    storeEventTypes,
    storeRawEventsData,
    Events_EventsMap_Widget_Reducer_Values,
    storeNewEvents,
    storeEventsToUpdate,
    storeExtendedEventsDataById,
    storeEventTypesByName,
} from '../reducer';
import usePrepareData from './usePrepareData';
import { userSettingsReducerValues } from '../../../../../../UserSettings/reducer';

export const useRequestEvents = () => {
    usePrepareData();
    const dispatch = useDispatch();
    const { token, selectedLocationId, appBackendHost } = useSelector(generalReducerValues);
    const { accParamsReceived } = useSelector(userSettingsReducerValues);
    const { newEvents, eventsToUpdate, eventIdToDelete, dataRefetchObject } = useSelector(
        Events_EventsMap_Widget_Reducer_Values,
    );

    /** Добавление новых событий */
    useEffect(() => {
        if (newEvents.length) {
            const { data_objects_ids, date_from, date_to, timezone, name, event_type, comment } = newEvents[0];
            const data = { data_objects_ids, date_from, date_to, timezone, name, event_type, comment };
            const url = `${appBackendHost}/api/location-events/location-events/`;
            postRequest(url, token, data).then((response: AxiosError | AxiosResponse) => {
                getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                    dispatch(storeRawEventsData(response['data']));
                });
            });
            dispatch(storeNewEvents([]));
        }
    }, [newEvents]);

    /** Изменение событий */
    useEffect(() => {
        if (eventsToUpdate.length) {
            const {
                data_objects_ids,
                date_from,
                date_to,
                timezone,
                name,
                event_type,
                comment = '',
                id,
            } = eventsToUpdate[0];
            const data = { data_objects_ids, date_from, date_to, timezone, name, event_type, comment, id };
            const url = `${appBackendHost}/api/location-events/location-events/`;
            patchRequest(url, token, data).then(() => {
                getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                    dispatch(storeRawEventsData(response['data']));
                });
            });
            dispatch(storeEventsToUpdate([]));
        }
    }, [eventsToUpdate]);

    /** Удаление событий */
    useEffect(() => {
        if (eventIdToDelete) {
            const url = `${appBackendHost}/api/location-events/location-events/`;
            deleteRequest(url, token, eventIdToDelete).then(() => {
                getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                    dispatch(storeRawEventsData(response['data']));
                });
            });
            dispatch(storeEventsToUpdate([]));
        }
    }, [eventIdToDelete]);

    /** При загрузке приложения, запрос на типы событий для данной локации и потом, запрос на сами сохраненные события */
    useEffect(() => {
        dispatch(storeExtendedEventsDataById({}));
        dispatch(storeEventTypesByName({}));
        dispatch(storeRawEventsData([]));

        const url = `${appBackendHost}/api/location-events/location-event-types/?pl_id=${selectedLocationId}`;
        if (token && selectedLocationId && accParamsReceived) {
            dispatch(storeEventTypes([]));
            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (response?.['data']?.length) {
                    dispatch(storeEventTypes(response['data']));
                    const url = `${appBackendHost}/api/location-events/location-events/?event_type__pl_id=${selectedLocationId}`;
                    getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                        dispatch(storeRawEventsData(response['data']));
                    });
                } else {
                    dispatch(storeRawEventsData([]));
                }
            });
        }
    }, [token, selectedLocationId, dispatch, appBackendHost, accParamsReceived, dataRefetchObject]);
};
