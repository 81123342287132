import { storeExtendedTenants, storeIdsOfTenants, Leasing_Tenants_Tenants_Widget_Reducer_Values } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import generateExtendedTenants from '../tools/generateExtendedTenants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

/** Кастомный хук для подготовки данных*/
const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Leasing:Tenants');
    const {
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const { rawMetricsData } = useSelector(Leasing_Tenants_Tenants_Widget_Reducer_Values);
    const dispatch = useDispatch();

    /** Запись всех id арендаторов */
    useEffect(() => {
        dispatch(storeIdsOfTenants(null));
        dispatch(storeExtendedTenants(null));
        if (Object.keys(reportingObjectsByType).length) {
            const ids = reportingObjectsByType?.['tenant']?.map((element) => element.id);
            dispatch(storeIdsOfTenants(ids));
        }
    }, [reportingObjectsByType]);

    /** Получение расширенной информации о арендаторе */
    useEffect(() => {
        if (
            Object.keys(reportingObjectsByType).length &&
            localCurrentOptions?.selectedMetrics &&
            reportingObjectsByType?.['tenant'] &&
            rawMetricsData
        ) {
            const extendedTenants = generateExtendedTenants({
                selectedMetrics: localCurrentOptions.selectedMetrics,
                tenants: reportingObjectsByType['tenant'],
                rawMetricsData,
            });
            dispatch(storeExtendedTenants(extendedTenants));
        }
    }, [localCurrentOptions?.selectedMetrics, rawMetricsData]);
};

export default usePrepareData;
