import { ReportingObjectInfo, StepContentWrapper, PercentageFill, Percentage, Wrapper, Title, Step } from './styles';
import { numberWithSpaces } from '../../../../../../../../../tools/Strings/numberWithSpaces';
import { useTranslation } from 'react-i18next';
import { IProps } from './interfaces';
import { FC } from 'react';
import { IdsOfFunnelStepsOptions } from '../../../../constants/constants';

/**
 * Компонент для отображения воронки для отчтеного объекта
 * @param data Расширенный отчетный объект
 */
const Funnel: FC<IProps> = ({ data, funnelSteps }) => {
    const { t } = useTranslation();
    const idsOfFunnelSteps = funnelSteps.map((element) => element.id);

    return (
        <Wrapper>
            <Title>{data.reportingObjectData.name}</Title>
            {Object.keys(data)
                ?.filter(
                    (key) => key !== 'reportingObjectData' && idsOfFunnelSteps.includes(key as IdsOfFunnelStepsOptions),
                )
                .map((key) => (
                    <Step key={key}>
                        <StepContentWrapper>
                            <ReportingObjectInfo>
                                <div>
                                    <span>{t(key)}</span> {data[key] ? data[key].name : '—'}
                                </div>
                                <div>{data[key] ? numberWithSpaces(data[key].metricValue) + ` ${t('ppl')}` : '—'}</div>
                            </ReportingObjectInfo>
                            <Percentage>{data[key] ? data[key].percentage.toFixed(2) + '%' : '—'}</Percentage>
                        </StepContentWrapper>
                        <PercentageFill width={data[key]?.percentage || 0} />
                    </Step>
                ))}
        </Wrapper>
    );
};

export default Funnel;
