import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    padding-top: 40px;
`;

export const EventNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const EventName = styled.h1`
    font-size: 2.125rem;
    line-height: 2.5rem;
    margin: 0;
    font-weight: 500;
    margin-right: 15px;
    display: inline-block;
`;

export const EventTypeColor = styled.div<{ color: string }>`
    flex: 0 0 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
`;

export const EventPeriod = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
`;

export const ReportingObjects = styled.div`
    margin-top: 15px;
    font-size: 1rem;
    span {
        dispaly: inline-block;
        margin-right: 10px;
        font-weight: 600;
    }
`;

export const OptionsWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
`;

export const Comment = styled.div`
    margin-top: 15px;
    font-size: 1rem;
    /* color: var(--color-secondary-darker); */
    span {
        dispaly: inline-block;
        margin-right: 10px;
        font-weight: 600;
    }
`;
