import {
    IDataObj2ProjectCategory,
    IPeriod,
    IReportingObject,
    ITenant2FloorRelation,
    ITenant2ZoneRelation,
} from '../../../../General.interfaces';

import { DateTime } from 'luxon';

interface IArgs {
    tenant2FloorByTenantId: { [tenantId: string]: ITenant2FloorRelation[] };
    tenant2ZoneByTenantId: { [tenantId: string]: ITenant2ZoneRelation[] };
    filters: number[];
    initialArray: IReportingObject[];
    mainDateRanges: IPeriod[];
    mainPeriod: IPeriod;
    reportingObjectsById: {
        [id: string]: IReportingObject;
    };
    dataObj2ProjectCategory: IDataObj2ProjectCategory[];
}

/**
 * Функция для фильтрации арендаторов
 * @param tenant2FloorByTenantId Связь арендатора и этажа
 * @param tenant2ZoneByTenantId Связь арендатора и зоны
 * @param mainDateRanges Периоды для основоной даты
 * @param filters id всех фильтров
 * @param mainPeriod Основной период
 * @param initialArray все отчетные объекты
 */
const filterReportingObjects = (args: IArgs): IReportingObject[] => {
    const {
        initialArray,
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        mainDateRanges,
        mainPeriod,
        filters,
    } = args;
    const mainDatePeriod = mainDateRanges?.find((element) => element.id === mainPeriod.id)?.period;
    const MIN_CONTENT_PERCENTAGE = 99;
    if (mainDateRanges) {
        const mainPeriodDateFrom = DateTime.fromISO(mainDatePeriod!.dateFrom);
        const mainPeriodDateTo = DateTime.fromISO(mainDatePeriod!.dateTo);
        return initialArray?.filter((element) => {
            let flag = false;
            if (!filters.length) return true;
            filters.forEach((id) => {
                const reportingObject = reportingObjectsById[id];
                if (reportingObject) {
                    switch (reportingObject.block_type) {
                        case 'project_category':
                            if (!flag) {
                                const currentCategoryItemsDataObjectsIds = dataObj2ProjectCategory
                                    ?.filter((element) => element.category_id === reportingObject.id)
                                    .map((element) => element.data_object_id);
                                if (currentCategoryItemsDataObjectsIds.includes(element.id)) {
                                    flag = true;
                                }
                            }

                            break;

                        default:
                            break;
                    }

                    switch (reportingObject.object_type) {
                        case 'zone':
                            if (!flag) {
                                const currentTenantRelations = tenant2ZoneByTenantId[element.id];

                                if (currentTenantRelations) {
                                    const currentRelation = currentTenantRelations?.find((relation) => {
                                        const relationDateFrom = DateTime.fromISO(relation.date_from);
                                        const relationDateTo = DateTime.fromISO(relation.date_to);
                                        return (
                                            relation.content_percentage > MIN_CONTENT_PERCENTAGE &&
                                            relation.tenant_id === element.id &&
                                            relation.zone_marker === reportingObject.marker &&
                                            relationDateFrom <= mainPeriodDateFrom &&
                                            relationDateTo >= mainPeriodDateTo
                                        );
                                    });

                                    if (currentRelation) flag = true;
                                }
                            }
                            break;
                        case 'floor':
                            if (!flag) {
                                const currentTenantRelations = tenant2FloorByTenantId[element.id];

                                if (currentTenantRelations) {
                                    const currentRelation = currentTenantRelations?.find((relation) => {
                                        const relationDateFrom = DateTime.fromISO(relation.date_from);
                                        const relationDateTo = DateTime.fromISO(relation.date_to);
                                        return (
                                            relation.tenant_id === element.id &&
                                            String(relation.floor) === reportingObject.marker &&
                                            relationDateFrom <= mainPeriodDateFrom &&
                                            relationDateTo >= mainPeriodDateTo
                                        );
                                    });

                                    if (currentRelation) flag = true;
                                }
                            }
                            break;

                        case 'tenant': {
                            if (!flag) {
                                if (reportingObject.id === element.id) {
                                    flag = true;
                                }
                            }
                            break;
                        }

                        default: {
                            break;
                        }
                    }
                }
            });

            return flag;
        });
    }

    return initialArray;
};

export default filterReportingObjects;
