import { handleServerResponse, TServerResponse } from '../../../../../../../../tools/API/handleServerResponse';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { useRequestMetrics } from '../../../../../../../../tools/API/useRequestMetrics';
import { cabinetPreferencesValues } from '../../../../../../CabinetPreferences/reducer';
import responseAnalyzer from '../../../../../../../../tools/API/responseAnalyzer';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { ResponseStatus } from '../../../../../../../../tools/API/constants';
import generateMetricsQueryData from '../tools/generateMetricsQueryData';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
    Reports_ByTenants_Widget_Reducer_Values,
    resetTenantsWidgetReducer,
    storePreparedGridData,
    storeRawMetricsData,
    storeGrid,
} from '../reducer';

/**
 * Кастомный хук для получения сырых данных
 */
export const useGetRawData = () => {
    const fetchData = useRequestMetrics();

    const localCurrentOptions = useWidgetCurrentOptions('Reports:By tenants');
    const { downloadSignalCounter } = useSelector(Reports_ByTenants_Widget_Reducer_Values);
    const { selectedLocationId } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        preferences: { metricLevel },
    } = useSelector(cabinetPreferencesValues);
    const { token } = useSelector(generalReducerValues);

    /** Обнуление редьюсера при выборе другой проектной локации */
    useEffect(() => {
        dispatch(resetTenantsWidgetReducer());
    }, [selectedLocationId]);

    useEffect(() => {
        if (!localCurrentOptions || !downloadSignalCounter) return;
        dispatch(storePreparedGridData(null));
        dispatch(storeGrid(null));
        const controller = new AbortController();
        const signal = controller.signal;

        if (
            localCurrentOptions?.selectedReportingObjectsIds &&
            localCurrentOptions?.selectedMetrics &&
            localCurrentOptions?.mainPeriod &&
            localCurrentOptions?.detail &&
            token
        ) {
            const { selectedReportingObjectsIds, selectedMetrics, mainDateRanges, mainPeriod, detail } =
                localCurrentOptions;
            dispatch(storeRawMetricsData({ status: ResponseStatus.Loading, message: t('Loading...') }));

            const metricsQueryData = generateMetricsQueryData({
                selectedReportingObjectsIds: selectedReportingObjectsIds,
                selectedMetrics,
                mainDateRanges,
                metricLevel,
                mainPeriod,
                token,
                detail,
            }).map((element) => ({ ...element, signal }));

            fetchData(metricsQueryData).then((responses: TServerResponse) => {
                /** Обработка ответа для метрик */
                handleServerResponse({
                    responseAnalyzer: responseAnalyzer,
                    success: storeRawMetricsData,
                    error: storeRawMetricsData,
                    res: responses,
                    dispatch,
                });
            });
        }
        return () => controller.abort();
    }, [downloadSignalCounter, token]);
};
