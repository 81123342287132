import { CategoriesAPI, IRequestStructureArgs, requestStructure } from '../../lib/esm/components';
import { IReportingObject, IStoreObjectsArgs } from '../../General.interfaces';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { generalReducerValues, resetReportingObjects, storeObjects, storeStructures } from '../../General.reducer';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { userSettingsReducerValues } from '../../cabinet/components/UserSettings/reducer';

const categoriesSrcMapping = {
    categories: 'categories',
    'project-categories': 'projectCategories',
    'data-obj-2-project-category': 'dataObj2ProjectCategory',
};

export const useRequestMainStructures = () => {
    const dispatch = useDispatch();
    const { token, selectedLocationId, selectedProjectId, categoriesReload, user } = useSelector(generalReducerValues);
    const { accParamsReceived } = useSelector(userSettingsReducerValues);

    useEffect(() => {
        dispatch(resetReportingObjects());
    }, [selectedLocationId]);

    useEffect(() => {
        if (token && user && selectedProjectId && selectedLocationId && accParamsReceived) {
            const reportingObjectsPromise = requestStructure({
                token,
                queries: [
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'elements_admin_data_objects',
                    },
                ],
                jq: `map(.[]) | first`,
            });

            const categoriesPromise = CategoriesAPI({ token, chapter: 'categories', method: 'GET' });

            const projectCategoriesPromise = CategoriesAPI({
                token,
                chapter: 'project-categories',
                method: 'GET',
                urlParams: { project_id: selectedProjectId },
            });

            const dataObj2ProjectCategoryPromise = CategoriesAPI({
                token,
                chapter: 'data-obj-2-project-category',
                method: 'GET',
                urlParams: { project_id: selectedProjectId },
            });

            const promises = [
                reportingObjectsPromise,
                categoriesPromise,
                projectCategoriesPromise,
                dataObj2ProjectCategoryPromise,
            ];

            axios.all(promises).then((res: (AxiosResponse | AxiosError)[]) => {
                if (res.every((response) => response?.status === 200)) {
                    const data = {};
                    res.forEach((response: AxiosResponse, i) => {
                        const arr = response.request.responseURL.split('/');
                        let key = categoriesSrcMapping[arr[arr.length - 2]];
                        if (i === 0) {
                            key = 'reportingObjects';
                        }
                        data[key] = response.data;
                    });

                    dispatch(storeObjects(data as IStoreObjectsArgs));
                } else {
                    console.log('>>> useCategoriesAPI NET ERROR <<<');
                }
            });
        }
    }, [token, categoriesReload, selectedLocationId, user, selectedProjectId, accParamsReceived]);

    useEffect(() => {
        if (token && selectedLocationId && accParamsReceived) {
            const controller = new AbortController();
            const signal = controller.signal;

            const mainRequest: IRequestStructureArgs = {
                signal,
                token,
                queries: [
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_tenant2place',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_tenant2zone',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_tenant2floor',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_passway2dataobj',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'relations_place2zone',
                    },
                    {
                        pl_id: selectedLocationId,
                        structure_section: 'core',
                        structure_type: 'elements_ms_data_objects',
                    },
                ],
            };

            dispatch(storeStructures(null));
            requestStructure(mainRequest).then((res) => {
                dispatch(storeStructures(res.data));
            });
        }
    }, [token, selectedLocationId, accParamsReceived]);
};
