import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ yearView?: boolean }>`
    position: relative;
    width: 100%;
    height: ${(p) => (p.yearView ? 'calc(100% - 30px)' : '100%')};
`;

export const Row = styled.div<{ isHeader: boolean; yearView?: boolean }>`
    display: grid;
    position: ${(p) => (p.isHeader ? 'sticky' : 'relative')};
    grid-template-columns: ${(p) => (p.yearView ? 'repeat(7, 1fr)' : '50px repeat(7, 1fr)')};
    top: 0;
    background-color: white;
    z-index: ${(p) => (p.isHeader ? 5 : 1)};
    grid-gap: 1px;
`;

export const Month = styled.div<{ yearView?: boolean }>`
    box-sizing: border-box;
    overflow-x: auto;
    display: grid;
    grid-template-rows: ${(p) => (p.yearView ? '20px repeat(6, 1fr)' : '50px repeat(6, 1fr)')};
    max-height: 77vh;
    grid-gap: 1px;
    height: ${(p) => (p.yearView ? '100%' : 'auto')};
    border-top: 1px solid #dce0e0;
`;

export const Cell = styled.div<{ isHeader: boolean; maxIndex?: number; yearView?: boolean }>`
    height: ${(p) =>
        p.yearView
            ? p.isHeader
                ? '100%'
                : p.maxIndex
                ? p.maxIndex > 3
                    ? p.maxIndex * 9 + 9 + 'px'
                    : 'auto'
                : 'auto'
            : p.isHeader
            ? '100%'
            : p.maxIndex
            ? p.maxIndex > 3
                ? p.maxIndex * 30 + 30 + 'px'
                : '125px'
            : '125px'};
    outline: 1px solid #dce0e0;
    padding: ${(p) => (p.yearView ? '3px' : '5px')};
    display: ${(p) => (p.isHeader ? 'flex' : 'block')};
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: ${(p) => (p.yearView ? '45px' : '150px')};
    position: relative;
    :first-child {
        min-width: ${(p) => (p.yearView ? '45px' : '32px')};
        background-color: ${(p) => (p.yearView ? 'white' : 'hsla(0, 0%, 92.2%, 0.3)')};
    }
`;

export const CellText = styled.div<{ isHeader: boolean; isWeekend: boolean; yearView?: boolean }>`
    font-weight: ${(p) => (p.isHeader ? 600 : 'normal')};
    color: ${(p) => (p.isWeekend ? '#F96666' : 'black')};
    align-items: center;
    height: ${(p) => (p.isHeader ? '100%' : 'auto')};
    display: flex;
    font-size: ${(p) => (p.yearView ? '10px' : '14px')};
    line-height: ${(p) => (p.yearView ? '10px' : '14px')};
`;
