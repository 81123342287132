export const subsetsOfSalesMetricsSelect = 'subsetsOfSalesMetricsSelect';
export const paymentAnalysisTenantSelect = 'paymentAnalysisTenantSelect';
export const paymentAnalysisMetricsSelect = 'paymentAnalysisMetricsSelect';

export interface ISubsetsOfSalesMetricsOptions {
    id: string;
    text: string;
    subtypes: {
        id: string;
        text: string;
        metricParams: {
            [subset: string]: string;
        };
    }[];
}

export const subsetsOfSalesMetricsOptions: ISubsetsOfSalesMetricsOptions[] = [
    {
        id: 'is_fiscal',
        text: 'Payment attribute',
        subtypes: [
            {
                id: 'true',
                text: 'Fiscal operations',
                metricParams: {
                    is_fiscal: 'true',
                },
            },
            {
                id: 'false',
                text: 'Non-fiscal operations',
                metricParams: {
                    is_fiscal: 'false',
                },
            },
        ],
    },
    {
        id: 'payment_type',
        text: 'Payment type',
        subtypes: [
            {
                id: 'ecash',
                text: 'E-Cash',
                metricParams: {
                    payment_type: 'ecash',
                    is_fiscal: 'true',
                },
            },
            {
                id: 'cash',
                text: 'Cash',
                metricParams: {
                    payment_type: 'cash',
                },
            },
            {
                id: 'mixed',
                text: 'Mixed payment',
                metricParams: {
                    payment_type: 'mixed',
                },
            },
            {
                id: 'prepaid',
                text: 'Prepaid',
                metricParams: {
                    payment_type: 'prepaid',
                },
            },
            {
                id: 'postpaid',
                text: 'Postpaid',
                metricParams: {
                    payment_type: 'postpaid',
                },
            },
            {
                id: 'provision',
                text: 'provision',
                metricParams: {
                    payment_type: 'provision',
                },
            },
            {
                id: 'other',
                text: 'Other',
                metricParams: {
                    payment_type: 'other',
                },
            },
        ],
    },
    {
        id: 'operation_type',
        text: 'Operation type',
        subtypes: [
            {
                id: 'sale',
                text: 'Selling',
                metricParams: {
                    operation_type: 'sale',
                },
            },
            {
                id: 'return',
                text: 'Return',
                metricParams: {
                    operation_type: 'return',
                },
            },
            {
                id: 'arrival',
                text: 'Arrival',
                metricParams: {
                    operation_type: 'arrival',
                },
            },
            {
                id: 'return_arrival',
                text: 'Return arrival',
                metricParams: {
                    operation_type: 'return_arrival',
                },
            },
            {
                id: 'other',
                text: 'Other',
                metricParams: {
                    operation_type: 'other',
                },
            },
        ],
    },
    {
        id: 'discount_level',
        text: 'Discount level',
        subtypes: [
            {
                id: '0_24',
                text: '0 - 24%',
                metricParams: {
                    discount_level: '0_24',
                },
            },
            {
                id: '25_49',
                text: '25 - 49%',
                metricParams: {
                    discount_level: '25_49',
                },
            },
            {
                id: '50_99',
                text: '50 - 99%',
                metricParams: {
                    discount_level: '50_99',
                },
            },
            {
                id: '100',
                text: '100%',
                metricParams: {
                    discount_level: '100',
                },
            },
        ],
    },
];

export const subsetsTextMap = {
    is_fiscal: 'Payment attribute',
    payment_type: 'Payment type',
    operation_type: 'Operation type',
    discount_level: 'Discount level',
};

export const subsetsSubtypeTextMap = {
    true: 'Fiscal operations',
    false: 'Non-fiscal operations',
    ecash: 'E-Cash',
    cash: 'Cash',
    mixed: 'Mixed payment',
    other: 'Other',
    sale: 'Selling',
    return: 'Return',
    prepaid: 'Prepaid',
    postpaid: 'Postpaid',
    arrival: 'Arrival',
    return_arrival: 'Return arrival',
    provision: 'Provision',
    '0_24': '0 - 24%',
    '25_49': '25 - 49%',
    '50_99': '50 - 99%',
    '100': '100%',
};
