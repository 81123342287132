import { TFunction, useTranslation } from 'react-i18next';

export const tenantsOptions = {
    Henderson: {
        rentInMonth: '1573000',
        indexingDate: '2022-05-01',
        indexPercent: '10',
        percent: 17.1,
        koeff_3: 0.45,
        koeff_6: 0.25,
    },
    'Burger King': {
        rentInMonth: '1454750',
        indexingDate: '2022-03-01',
        indexPercent: '15',
        percent: 9.1,
        koeff_3: 0.75,
        koeff_6: 0.5,
    },
};

export const canShowOCR = (
    tenantId: string,
    rent: string,
    date: Array<{ id: string; text: string }>,
    indexPercent: string,
) => {
    const tenant = tenantsOptions?.[tenantId];
    return tenant?.rentInMonth === rent && tenant?.indexingDate === date[0]?.text && tenant?.indexPercent === indexPercent;
};

export const namesMap = (t: TFunction) => ({
    traffic: t('Attendance, ppl.'),
    sales_no_vat: t('Sales without VAT, ₽'),
    sales_per_area_no_vat: `${t('Sales of')} ${t('per m² without VAT, ₽')}`,
    sales_per_area_uniq_no_vat: t('Sales of group per m² without VAT, ₽'),
    profit: t('Total rental income, ₽'),
    ocr: ` OCR, %`,
});
