import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@fluentui/react/lib/TextField';

import { IFilterInputProps } from './FilterInput.interfaces';

/**
 * Компонента инпута с возможностью фильтрации
 */

const FilterInput = ({ disabled, changeValue, value = '', isOutsideControlled = false }: IFilterInputProps) => {
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState(value);

    const onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (isOutsideControlled) {
            changeValue && changeValue(newValue || '');
        } else {
            setFilterValue(newValue || '');
        }
    };

    return (
        <TextField
            disabled={disabled}
            placeholder={t('Filter')}
            value={isOutsideControlled ? value : filterValue}
            onChange={onChange}
        />
    );
};

export default FilterInput;
