import { Stack } from '@fluentui/react';
import { FC } from 'react';
import { AnyAction } from 'redux';

import PDFDownload from './components/PDFDownload/PDFDownload';
import Reload from './components/Reload/Reload';
import XLSXDownload from './components/XLSXDownload/XLSXDownload';

interface IProps {
    widgetName: string;
    /** Данные для загрузки PDF */
    pdfDownloadData?: {
        targetRef: React.RefObject<HTMLDivElement | null>;
        rerenderKey: number;
    };
    /** Данные для загрузки XLSX */
    xlsxDownloadData?: {};
    /** Функция для перезагрузки виджета */
    reloadHandler?: () => AnyAction;
}

/**
 * Компонент для отображения дополнительных контролов для виджета
 */
const WidgetAdditionalControls: FC<IProps> = ({ pdfDownloadData, reloadHandler, xlsxDownloadData, widgetName }) => {
    return (
        <Stack horizontal={true} tokens={{ padding: '0 0 0 5px', childrenGap: 3 }}>
            {pdfDownloadData && <PDFDownload {...pdfDownloadData} fileName={widgetName} />}
            {reloadHandler && <Reload reloadHandler={reloadHandler} />}
            {xlsxDownloadData && <XLSXDownload {...xlsxDownloadData} />}
        </Stack>
    );
};

export default WidgetAdditionalControls;
