import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Reports_Basic_Widget_Reducer_Values, resetReducer, storeGrid } from '../reducer';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { BASIC_REPORT_ADDITIONAL_FIELDS } from '../../../constants/constants';
import { ISelectedOption } from '../../../../../../../../components/Select/configurations';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { generateGrid } from '../tools/generateGrid';

/** Кастомный хук для подготовки данных */
const usePrepareData = () => {
    const localCurrentOptions = useWidgetCurrentOptions('Reports:Basic');
    const { rawMetricsData } = useSelector(Reports_Basic_Widget_Reducer_Values);
    const {
        src: { reportingObjects },
        allMetrics,
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    /** Обнуление редьюсера при смене локации */
    useEffect(() => {
        dispatch(resetReducer());
    }, [localCurrentOptions?.selectedLocationId]);

    /** Создание таблицы после получения метрик */
    useEffect(() => {
        if (Array.isArray(rawMetricsData) && localCurrentOptions?.[BASIC_REPORT_ADDITIONAL_FIELDS]) {
            const additionalFieldsIds = localCurrentOptions[BASIC_REPORT_ADDITIONAL_FIELDS].map(
                (element: ISelectedOption) => element.id,
            );

            const grid = generateGrid(rawMetricsData, reportingObjects, allMetrics, additionalFieldsIds, t);
            grid && dispatch(storeGrid(grid));
        }
    }, [localCurrentOptions?.[BASIC_REPORT_ADDITIONAL_FIELDS], rawMetricsData, t]);
};

export default usePrepareData;
