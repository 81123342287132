import axios, { AxiosError } from 'axios';
import { getUrls, hosts } from '../../constants/urls';
import { IGeneralPreferences } from '../../cabinet/components/CabinetPreferences/interfaces';
import { Dispatch } from '@reduxjs/toolkit';
import { storeNewAlert } from '../../cabinet/components/Alert/reducer';
import { MessageBarType } from '@fluentui/react';
import { isObject } from 'lodash';

export const getRequest = async (url: string, token: string, dispatch?: Dispatch) => {
    const headers = {
        'X-Token': token,
        'Content-type': 'application/json',
    };

    try {
        const response = await axios({
            method: 'GET',
            headers,
            url: `${url}`,
        });

        return response;
    } catch (error) {
        handleAxiosError(error, dispatch);
        return error;
    }
};

export const postRequest = async (url: string, token: string, data: object, dispatch?: Dispatch) => {
    const headers = {
        'X-Token': token,
        'Content-type': 'application/json',
    };

    try {
        const response = await axios({
            method: 'POST',
            headers,
            url: `${url}`,
            data,
        });
        return response;
    } catch (error) {
        handleAxiosError(error, dispatch);
        return error;
    }
};

export const patchRequest = async (url: string, token: string, data: object, dispatch?: Dispatch) => {
    const headers = {
        'X-Token': token,
        'Content-type': 'application/json',
    };

    try {
        const response = await axios({
            method: 'PATCH',
            headers,
            url: `${url}${data['id']}/`,
            data,
        });
        return response;
    } catch (error) {
        handleAxiosError(error, dispatch);
        return error;
    }
};

export const deleteRequest = async (url: string, token: string, id: number, dispatch?: Dispatch) => {
    const headers = {
        'X-Token': token,
        'Content-type': 'application/json',
    };

    try {
        const response = await axios({
            method: 'DELETE',
            headers,
            url: `${url}${id}/`,
        });
        return response;
    } catch (error) {
        handleAxiosError(error, dispatch);
        return error;
    }
};

const handleAxiosError = (error: AxiosError, dispatch?: Dispatch) => {
    const errors: string[] = [];
    if (isObject(error.response?.data)) {
        try {
            Object.values(error.response?.data as {})?.forEach((element) => {
                errors.push(JSON.stringify(element));
            });
        } catch (error) {
            errors.push('Some error accured');
        }
    } else {
        errors.push('Some error accured');
    }

    const result = errors.reduce((acc, value) => {
        acc += `${value}.  `;
        return acc;
    }, '');

    dispatch &&
        dispatch(
            storeNewAlert({
                type: MessageBarType.error,
                text: result,
                duration: 10,
            }),
        );
};
