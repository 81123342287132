/**
 * Данные раздела Обзор за период
 */
export const FaqDataPeriodReview = [
    {
        label: 'How to check a period’s metrics?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select an object.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'The page displays metrics for the selected period.',
            },
        ],
        imageUrl: `indicators_period.gif`,
    },
    {
        label: 'How to check out the events that took place during the period?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'The Mall’s events are displayed on the desktop if you have the Marketing module connected and if events were enabled within the baseline period.',
            },
        ],
        imageUrl: 'events_to_period.gif',
    },
    {
        label: 'How to check the performance of anchor tenants?',
        list: [
            {
                text: 'The fastest way to do this is by using a treemap: the cell size is in line with the area occupied by a tenant, so major anchor tenants are always visible.',
            },
            {
                text: 'Select a metric from the drop-down list, for which you want to build a treemap.',
            },
            {
                text: 'Switch the period for comparison in the drop-down list.',
            },
        ],
        imageUrl: 'indicators_anchor_tenants.gif',
    },
    {
        label: 'How to check out performance of tenants in a certain category?',
        list: [
            {
                text: 'Go to the treemap.',
            },
            {
                text: 'Click on the filter icon.',
            },
            {
                text: 'Select an attribute from the drop-down list to filter entries.',
            },
            {
                text: 'The treemap cells display filtered tenants. Below you will see their rating vs. the previous period.',
            },
        ],
        imageUrl: 'indicators_tenants_category.gif',
    },
    {
        label: 'How to cheek out the dynamics of tenant categories?',
        list: [
            {
                text: 'Go to the treemap.',
            },
            {
                text: 'Select the Categories tab.',
            },
            {
                text: 'Growing categories are displayed green; falling categories are displayed red.',
            },
            {
                text: 'Click on a cell with a category.',
            },
            {
                text: 'Below the treemap, you will see the rating of tenants and their changes in the selected category.',
            },
        ],
        imageUrl: 'dynamics_tenant_categories.gif',
    },
    {
        label: 'How to compare the dynamics of the baseline period with other periods?',
        list: [
            {
                text: 'Select periods to compare.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Periods tab.',
            },
            {
                text: 'Select the needed metric or object from the drop-down lists.',
            },
            {
                text: 'When hovering over the chart, the metric values and the day of the week of a specified point are displayed.',
            },
        ],
        imageUrl: 'dynamics_main_period_with_other_periods.gif',
    },
    {
        label: 'How to compare the dynamics of different objects?',
        list: [
            {
                text: 'Select up to 5 objects.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Objects tab.',
            },
            {
                text: 'The chart will display the dynamics of the selected objects during the baseline period.',
            },
            {
                text: 'When hovering over the chart, the values of the objects at the specified point are displayed.',
            },
        ],
        imageUrl: 'dynamics_different_objects_among_themselves.gif',
    },
    {
        label: 'How to compare the dynamics of metrics?',
        list: [
            {
                text: 'Select the metrics.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Metrics tab.',
            },
            {
                text: 'The chart displays the dynamics of metrics during the baseline period.',
            },
            {
                text: 'When hovering over the chart, the metric values at the specified point will be displayed.',
            },
            {
                text: 'Using a drop-down list, you can select objects to view on the chart.',
            },
        ],
        imageUrl: 'compare_dynamics_metrics.gif',
    },
    {
        label: 'How to find out which entrance of the Mall has the heaviest traffic?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Go to the “Mall Entrance Performance” chart.',
            },
            {
                text: 'The chart displays the traffic by entrances: the heavier the traffic, the higher the percentage.',
            },
        ],
        imageUrl: 'entrance_mall_most_loaded.gif',
    },
    {
        label: 'How to find out which entrance to the Mall has grown in traffic?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Go to the “Mall Entrance Performance” chart.',
            },
            {
                text: 'The column headings display the traffic of each entrance in absolute values, and below you can see the traffic dynamics compared to the baseline period.',
            },
        ],
        imageUrl: 'indicators_period2.gif',
    },
];
/**
 * Данные раздела Год к году
 */
export const FaqDataYearOverYear = [
    {
        label: 'How to compare the dynamics of indicators year to year?',
        list: [
            {
                text: 'Select up to 5 objects.',
            },
            {
                text: 'Select the metric.',
            },
            {
                text: 'Select the years.',
            },
            {
                text: 'The page displays a graph with the dynamics of objects for the selected period.',
            },
            {
                text: 'When you hover over the graph, the indicators of different years for the selected month will be displayed.',
            },
        ],
        imageUrl: 'indicators_dynamics_year_to_year.gif',
    },
    {
        label: 'How to see deviation and indicators year to year?',
        list: [
            {
                text: 'Select up to 5 objects.',
            },
            {
                text: 'Select the metric.',
            },
            {
                text: 'Select the years.',
            },
            {
                text: 'In order to display the difference in the value between years and months in the table, click on the table settings icon and check the box "Show difference (delta)".',
            },
            {
                text: 'In order to display the values of the indicators of the compared months and years in the table, click on the table parameters icon and check the box "Show absolute values".',
            },
        ],
        imageUrl: '',
    },
    {
        label: 'How to compare the dynamics of the months of the day year to year?',
        list: [
            {
                text: 'Select up to 5 objects.',
            },
            {
                text: 'Select the metric.',
            },
            {
                text: 'Select the years.',
            },
            {
                text: 'Click on the cell with the deviation opposite the desired month.',
            },
            {
                text: 'If necessary, use the function "Shift by days of the week".',
            },
        ],
        imageUrl: 'indicators_dynamics_days_year_to_year.gif',
    },
];
/**
 * Данные раздела Кросс-визиты
 */
export const FaqDataCrossVisits = [
    {
        label: 'How to see which tenants or zones are shared?',
        list: [
            {
                text: 'Select period.',
            },
            {
                text: 'Select the main object for which cross visits will be calculated.',
            },
            {
                text: 'In the drop-down list, select "Tenants" or "Zones".',
            },
            {
                text: 'In the table, a rating will be built by average daily intersections and by intersections for the period.',
            },
            {
                text: 'Gray indicates the number of unique MAC addresses.',
            },
        ],
        imageUrl: 'indicates_unique_mac_addresses.gif',
    },
    {
        label: 'How to see which tenants of a category a tenant intersects?',
        list: [
            {
                text: 'Select period.',
            },
            {
                text: 'Select the main object for which cross visits will be calculated.',
            },
            {
                text: 'In the drop-down list, select "Tenants".',
            },
            {
                text: 'In the table, a rating will be built by average daily intersections and by intersections for the period.',
            },
            {
                text: 'Select a category in the filter for which you want to show data.',
            },
            {
                text: 'Gray indicates the number of unique MAC addresses.',
            },
        ],
        imageUrl: 'cross_visitation_tenant_with_zone.gif',
    },
];
/**
 * Данные раздела Посетители внутри
 */
export const FaqDataVisitorInside = [
    {
        label: 'How to find out how many people were inside the object at a certain time?',
        list: [
            {
                text: 'Choose one day on the calendar.',
            },
            {
                text: 'Select days to compare.',
            },
            {
                text: 'Select objects.',
            },
            {
                text: 'The chart will display the number of people inside the selected objects.',
            },
            {
                text: 'When you hover over the graph, the number of people inside the selected objects by a certain hour will be displayed.',
            },
        ],
        imageUrl: 'people_now.gif',
    },
];
/**
 * Данные раздела Рейтинги
 */
export const FaqDataRatings = [
    {
        label: 'How to create a rating?',
        list: [
            {
                text: 'Click the button "Add Rating".',
            },
            {
                text: 'Select the metric by which the rating will be built, type of calculation and sorting.',
            },
            {
                text: 'Select object type.',
            },
            {
                text: 'In the advanced settings, you can add a name and auxiliary columns, for example, the area of objects.',
            },
            {
                text: 'Save rating.',
            },
            {
                text: 'On the page you can set the main period and periods for comparing all created ratings.',
            },
            {
                text: 'You can also edit or delete a rating.',
            },
        ],
        imageUrl: 'how_to_create_a_rating.gif',
    },
];
/**
 * Данные раздела Путь к покупке 
 */
export const FaqDataPathToPurchase = [
    {
        label: 'How to build a tenant visiting funnel?',
        list: [
            {
                text: 'Select period.',
            },
            {
                text: 'Select up to 5 tenants.',
            },
            {
                text: 'Select funnel steps - each step means the transition of visitors from one object to another. For example, from the zone in which the tenant is in his store.',
            },
            {
                text: 'A graph will appear on the page, according to which it is possible to determine which of the selected tenants is located in a zone with a more intensive flow of visitors, who has higher attendance, who has more buyers. ',
            },
        ],
        imageUrl: 'how_to_build_a_tenant_visit_funnel.gif',
    },
    {
        label: 'How to compare tenant funnels?',
        list: [
            {
                text: 'Select period.',
            },
            {
                text: 'Select tenants from the drop-down list.',
            },
            {
                text: 'Select funnel steps.',
            },
            {
                text: 'Go to the funnel.',
            },
            {
                text: 'Funnels can be represented in two ways: "Interest on the mall", or "Interest on the previous step". ',
            },
        ],
        imageUrl: 'tenant_funnel_compare.gif',
    },
];
/**
 * Данные раздела События
 */
export const FaqDataEvents = [
    {
        label: 'How to create an event?',
        list: [
            {
                text: 'Click the Add Events button.',
            },
            {
                text: 'Fill in the Event name, Event period, Event type and Location.',
            },
            {
                text: 'You can edit and delete events, and set events in advance.',
            },
        ],
        imageUrl: 'create_event.gif',
    },
];
/**
 * Данные раздела Анализ события 
 */
export const FaqDataEventsAnalysis = [
    {
        label: 'How to check out the event’s metrics?',
        list: [
            {
                text: 'Click on the event card in the list of events.',
            },
            {
                text: 'You will be forwarded to an event analysis page. The baseline period and location will be already filled out, so you will only have to select a period for comparison: by default, the previous period with the same number of days and days of the week as in the baseline period will be automatically proposed.',
            },
            {
                text: 'The page will build a report in line with the selected metrics.',
            },
        ],
        imageUrl: 'event_indicators.gif',
    },
    {
        label: 'How to compare the dynamics of an event period with other periods?',
        list: [
            {
                text: 'Go to the event page.',
            },
            {
                text: 'Add periods to compare, objects and metrics.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Periods tab.',
            },
            {
                text: 'Select the needed metric or object from the drop-down lists.',
            },
            {
                text: 'When hovering over the chart, the metric values will be displayed at the specified point.',
            },
        ],
        imageUrl: 'dynamics_period_event_with_other_periods.gif',
    },
    {
        label: 'How to check out the dynamics of event metrics?',
        list: [
            {
                text: 'Go to the event page.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Metrics tab.',
            },
            {
                text: 'The chart will display the dynamics of metrics for the event period.',
            },
            {
                text: 'When hovering over the chart, the metric values at the specified point will be displayed.',
            },
        ],
        imageUrl: 'dynamics_event_indicators.gif',
    },
    {
        label: 'How to compare the dynamics of a metric of several objects?',
        list: [
            {
                text: 'Go to the event page.',
            },
            {
                text: 'Select objects.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Objects tab.',
            },
            {
                text: 'The chart will display the dynamics of the selected objects.',
            },
            {
                text: 'When hovering over the chart, the metric values of each object at the specified point will be displayed.',
            },
        ],
        imageUrl: 'dynamics_indicat_several_objects_among_themselves.gif',
    },
    {
        label: 'How to check out which tenants grew during the event period?',
        list: [
            {
                text: 'Go to the event page.',
            },
            {
                text: 'Select periods to compare and metrics.',
            },
            {
                text: 'Go to the tab.',
            },
            {
                text: 'In the tab, select the period you want to evaluate the growth against.',
            },
            {
                text: 'Align the tenants in descending order, then you will see the tenants rating by growth.',
            },
        ],
        imageUrl: 'which_tenants_grown_during_event.gif',
    },
    {
        label: 'How to find out which of the tenants of a certain category grew?',
        list: [
            {
                text: 'Go to the event page.',
            },
            {
                text: 'Go to the tab.',
            },
            {
                text: 'Select the Tenants tab.',
            },
            {
                text: 'Click on the filter icon and select the entity by which you want to filter tenants.',
            },
            {
                text: 'Sort tenants by deviation vs. the baseline period.',
            },
        ],
        imageUrl: 'tenants_certain_category_grew.gif',
    },
    {
        label: 'How to find out which tenants performed highest during the event?',
        list: [
            {
                text: 'Go to the event page.',
            },
            {
                text: 'Select the Tenants tab.',
            },
            {
                text: 'Sort tenants by deviation vs. the baseline period.',
            },
            {
                text: 'When sorting in descending order, the ranking of tenants with the highest performance is displayed.',
            },
        ],
        imageUrl: 'tenants_during_event_were_highest_rates.gif',
    },
];
/**
 * Данные раздела Анализ арендатора
 */
export const FaqDataTenantAnalysis = [
    {
        label: 'How to get the tenant information?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select an object, i.e. a tenant you want to analyze.',
            },
            {
                text: 'Expand the tenant’s card on the page: it will display information about the location, occupied space and category of the tenant.',
            },
        ],
        imageUrl: 'tenant_information.gif',
    },
    {
        label: 'How to check out the dynamics of a tenant’s indicators?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Metrics tab.',
            },
            {
                text: 'The chart displays the dynamics of metrics during the baseline period.',
            },
        ],
        imageUrl: 'tenant_dynamic.gif',
    },
    {
        label: 'How to compare the dynamics of a tenant’s indicators across different periods?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select periods to compare.',
            },
            {
                text: 'Go to the chart.',
            },
            {
                text: 'Select the Periods tab.',
            },
            {
                text: 'The chart will display the dynamics of the selected metric over periods.',
            },
        ],
        imageUrl: 'compare_tenants.gif',
    },
    {
        label: 'How do check the percentage of the zone’s shoppers visiting a tenant?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select an object, i.e. a tenant you want to analyze.',
            },
            {
                text: 'Go to the Tenant Funnel.',
            },
            {
                text: 'The table will display the tenant’s traffic, the zone it is located in, and the percentage of the zone’s shoppers visiting the tenant.',
            },
        ],
        imageUrl: 'tenant_vs_zone_cross.gif',
    },
    {
        label: 'How to check out the traffic rating by tenant category?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select an object.',
            },
            {
                text: 'Go to the Tenant Funnel.',
            },
            {
                text: 'Click on the sort icon.',
            },
            {
                text: 'The table will display the traffic rating depending on the selected type of sorting.',
            },
        ],
        imageUrl: 'tenant_attendance_rating.gif',
    },
    {
        label: 'How to compare a tenant with reference groups (competitors)?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select an object.',
            },
            {
                text: 'Go to the Comparison with Reference Groups table.',
            },
            {
                text: 'Select the Reference Groups tab.',
            },
            {
                text: 'Select the reference groups from the drop-down list and enable the AVG option.',
            },
            {
                text: 'The table will display the indicators of the selected reference groups averaged by tenants.',
            },
        ],
        imageUrl: 'how_to_compare_tenant_with_reference_groups.gif',
    },
    {
        label: 'How to compare a tenant with other specific tenants?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select an object.',
            },
            {
                text: 'Go to the Comparison with Reference Groups table.',
            },
            {
                text: 'Select the Other Tenants tab.',
            },
            {
                text: 'Select tenants from the drop-down list.',
            },
            {
                text: 'The table will display the indicators of the selected tenants.',
            },
        ],
        imageUrl: 'compare_tenant_with_other.gif',
    },
    {
        label: 'How to see which tenants or areas the selected tenant intersects most effectively?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select an object.',
            },
            {
                text: 'Go to the table "Tenant Intersections".',
            },
            {
                text: 'In the left table, in the drop-down list, you can select the top 10 intersections with tenants or zones.',
            },
            {
                text: 'In the table on the right, in the drop-down list, the rating by tenants can be filtered by categories, zones, etc.',
            },
        ],
        imageUrl: 'tenant_intersects_most.gif',
    },
];
/**
 * Данные раздела Арендаторы
 */
export const FaqDataTenants = [
    {
        label: 'How to see the performance of a certain group of tenants?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'Click on the filter icon and select a sign to group tenants.',
            },
            {
                text: 'The table displays the indicators of the selected group.',
            },
            {
                text: 'Opposite the name of the tenant you are interested in, click on the icon to go to the page with its detailed analysis.',
            },
        ],
        imageUrl: 'tenant_group_analys.gif',
    },
    {
        label: 'How to see tenants with the highest/lowest metric values?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'In the table header to the left of the metric name, click on the sort icon.',
            },
            {
                text: 'Tenants are sorted from smaller to larger.',
            },
            {
                text: 'Opposite the name of the tenant you are interested in, click on the icon to go to the page with its detailed analysis.',
            },
        ],
        imageUrl: 'tenants_highest_or_lowest.gif',
    },
    {
        label: 'How to see which tenants have increased/decreased compared to the previous period?',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'In the table header, to the right of the metric name, click on the sort icon.',
            },
            {
                text: 'Tenants are sorted from smaller to larger.',
            },
            {
                text: 'Opposite the name of the tenant you are interested in, click on the icon to go to the page with its detailed analysis.',
            },
        ],
        imageUrl: 'tenants_increased_or_decreased.gif',
    },
];
/**
 * Данные раздела Особые функции
 */
export const FaqDataSpecialFeatures = [
    {
        label: 'Data detailing',
        list: [
            {
                text: 'Depending on the specified period, you can select the chart detailing. Importantly, the selected period should be complete when selecting the data detailing, e.g. detailing by months is not available if the month has 31 days, but only 30 days are actually selected.',
            },
            {
                text: 'Detailing is not available when using averaging or shifting by day of the week.',
            },
        ],
        imageUrl: 'data_drilldown.gif',
    },
    {
        label: 'Align by days of the week',
        list: [
            {
                text: 'If this feature is enabled, the periods on the chart are shifted so that the days of the week in these periods coincide.',
            },
        ],
        imageUrl: 'day_shift.gif',
    },
    {
        label: 'Averaging by day of the week',
        list: [
            {
                text: 'When choosing a comparison period lasting more than 7 days, averaging by days of the week is available.',
            },
            {
                text: 'When displaying the total values, first Mondays are averaged with Mondays, Tuesdays with Tuesdays, etc., and then the data is summed up and complemented to coincide with the baseline period by the number of days. In this case, the charts display the average values per day of the week and the period is complemented to coincide with the baseline period by the number of days.',
            },
            {
                text: 'To check out the average metrics by weekdays or weekends, click on the respective day in the calendar.',
            },
        ],
        imageUrl: '',
    },
    {
        label: 'Averaging by tenants',
        list: [
            {
                text: 'To display metrics averaged by tenants (e.g., the average by category’s tenants), select objects in the filter and then enable the AVG option.',
            },
        ],
        imageUrl: 'tenant_averaging.gif',
    },
    {
        label: 'Presets',
        list: [
            {
                text: 'If necessary, you can save the selected filter settings.',
            },
            {
                text: 'Select options on the page in the filter.',
            },
            {
                text: 'In the drop-down list of presets, select "Save as new" and confirm the save.',
            },
            {
                text: 'The created preset will be saved when you log in again.',
            },
            {
                text: 'If the preset has become irrelevant, you can delete it.',
            },
        ],
        imageUrl: 'preset.gif',
    },
    {
        label: 'Change Shopster Settings',
        list: [
            {
                text: 'Open the dropdown list with metrics.',
            },
            {
                text: 'Click on Gear Settings.',
            },
            {
                text: 'Select options for engagement threshold and return period.',
            },
            {
                text: 'Save settings.',
            },
        ],
        imageUrl: 'changing_shopster_settings.gif',
    },
];
/**
 * Данные раздела Импорт данных
 */
export const FaqDataImport = [
    {
        label: 'How to import attendance data into Focus?',
        list: [
            {
                text: 'Prepare the data with the file in accordance with the example below, observing the order of the columns.',
            },
            {
                text: 'Names of columns with metrics: people-count.in - traffic (input), people-count.out - traffic (output). If you have data only on attendance at the entrance, then you need to copy them to the output column.',
            },
            {
                text: 'Check file format, it should be .csv with delimiter: " ; ".',
            },
            {
                text: 'If the resulting file takes more than 2 MB, then divide it into several files.',
            },
            {
                text: 'You can upload up to 10 files at a time.',
            },
            {
                text: 'Drag the file in the marked area.',
            },
            {
                text: 'Click "Download Files".',
            },
            {
                text: 'For some time (from a minute to an hour, depending on the amount of data) after the download is complete, the data will appear in the system.',
            },
        ],
        imageUrl: '',
    },
    {
        label: 'How to import sales data into Focus?',
        list: [
            {
                text: 'Prepare the data with the file in accordance with the example below, observing the order of the columns.',
            },
            {
                text: 'Column names with metrics: sales.volume - sales, till_slip.count - cash receipts, till_slip.quantity - goods.',
            },
            {
                text: 'Check file format, it should be .csv with delimiter: " ; ".',
            },
            {
                text: 'If the resulting file takes more than 2 MB, then divide it into several files.',
            },
            {
                text: 'You can upload up to 10 files at a time.',
            },
            {
                text: 'Drag the file in the marked area.',
            },
            {
                text: 'Click "Download Files".',
            },
            {
                text: 'For some time (from a minute to an hour, depending on the amount of data) after the download is complete, the data will appear in the system.',
            },
        ],
        imageUrl: '',
    },
];
/**
 * Данные раздела Отчеты
 */
export const FaqDataReportList = [
    {
        label: 'Basic',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select report details. When choosing a calculation without detailing, the data is summarized for the entire reporting period.',
            },
            {
                text: 'Select objects.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'Click "Upload " - the finished report will appear in the browser downloads.',
            }
            
        ],
        imageUrl: '',
    },
    {
        label: 'Basic with comparison',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select a period to compare.',
            },
            {
                text: 'Select report details. When choosing a calculation without detailing, the data is summarized for the entire reporting period.',
            },
            {
                text: 'Select objects.',
            },
            {
                text: 'Select the metrics.',
            },
            {
                text: 'When you select the "%" icon in the report, a column appears with the deviation of the main period from the period for comparison.',
            },
            {
                text: 'When you select the "Δ" icon, a column appears in the report with the difference between the main period and the period for comparison.',
            },
            {
                text: 'Click "Upload " - the finished report will appear in the browser downloads.',
            }
            
        ],
        imageUrl: '',
    },
    {
        label: 'Average daily intersections',
        list: [
            {
                text: 'Select the baseline period.',
            },
            {
                text: 'Select main object.',
            },
            {
                text: 'Select an object to intersect.',
            },
            {
                text: 'Click "Upload " - the finished report will appear in the browser downloads.',
            }
            
        ],
        imageUrl: '',
    },
];

/**
 * 
 * 
    {
        label: '',
        list: [
            {
                text: '',
            },
            {
                text: '',
            },
            {
                text: '',
            },
            {
                text: '',
            },
            {
                text: '',
            }
            
        ],
        imageUrl: '',
    },
 */
