import React from 'react';
import cn from 'classnames';

import { IHeaderProps } from '../../configurations';
import * as constants from '../../constants/constants';
import '../../styles/styles.scss';
import SwitcherStub from '../SwitcherStub';
import Logo from './components/Logo';
import Switcher from '../Switcher';
import { Box, Icon } from '../../../../../lib/esm/components';

const SideBarHeader: React.FC<IHeaderProps> = ({ compact, ...props }) => {
    const { DEFAULT_CLASS_HEADER, DEFAULT_SEPARATOR_MODIFICATION_PREFIX } = constants;

    const className = React.useMemo(() => {
        return cn({ [DEFAULT_CLASS_HEADER]: true, [`${DEFAULT_CLASS_HEADER}${DEFAULT_SEPARATOR_MODIFICATION_PREFIX}compact`]: compact });
    }, [props]);

    return (
        <Box margin="b_md t_xxxs" padding="t_xxs">
            <div className={className}>
                <Switcher compact={compact}>
                    <SwitcherStub onStubClick={props.onStubClick}>
                        <Icon type={'menu'} />
                    </SwitcherStub>
                    {!compact && <Logo />}
                </Switcher>
            </div>
        </Box>
    );
};

export default SideBarHeader;
