import { CABINET_PREFERENCES_LOCAL_STORAGE_KEY, DEFAULT_GENERAL_CABINET_PREFERENCES } from '../constants/constants';
import { cabinetPreferencesValues, storeCabinetPreferences } from '../reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { generalReducerValues } from '../../../../General.reducer';
import { getRequest, patchRequest, postRequest } from '../../../../tools/API/appBackendAPI';
import { Axios, AxiosError, AxiosResponse } from 'axios';
import { mapBack2Front, mapFront2Back } from '../../../../tools/mappingFrontAndBack';
import { cabinetPreferencesMapping } from '../../../../constants/keysMapping';
import { IGeneralPreferences } from '../interfaces';

/**
 * Кастомный хук для работы с настройками кабинета для пользователя
 */
const useCabinetPreferencesData = () => {
    const dispatch = useDispatch();
    const { user, token, appBackendHost } = useSelector(generalReducerValues);
    const { preferences, dataReceived } = useSelector(cabinetPreferencesValues);

    const url = `${appBackendHost}/api/user-settings/cabinet-preferences/`;

    /**
     * Запись настроек пользователя из localStorage
     */
    // useEffect(() => {
    //     let cabinetPreferences = localStorage.getItem(CABINET_PREFERENCES_LOCAL_STORAGE_KEY);
    //     if (cabinetPreferences) {
    //         dispatch(storeCabinetPreferences(JSON.parse(cabinetPreferences)));
    //     }
    // }, []);

    useEffect(() => {
        if (user?.id && preferences && dataReceived) {
            patchRequest(url, token, mapFront2Back(cabinetPreferencesMapping, preferences));
        }
    }, [preferences, user?.id, dataReceived, token]);

    useEffect(() => {
        if (user?.id) {
            getRequest(url, token).then((response: AxiosError | AxiosResponse) => {
                if (!response?.['data'].length) {
                    const data = { ...DEFAULT_GENERAL_CABINET_PREFERENCES, user_id: user.id };
                    postRequest(url, token, data).then((response) => {
                        dispatch(
                            storeCabinetPreferences({
                                preferences: mapBack2Front(
                                    cabinetPreferencesMapping,
                                    response?.['data'],
                                ) as IGeneralPreferences,
                            }),
                        );
                    });
                } else {
                    dispatch(
                        storeCabinetPreferences({
                            preferences: mapBack2Front(
                                cabinetPreferencesMapping,
                                response?.['data'][0],
                            ) as IGeneralPreferences,
                        }),
                    );
                }
            });
        }
    }, [dispatch, token, user?.id]);
};

export default useCabinetPreferencesData;
