import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Maps_MapsOverview_Module_Reducer_Values, storeColorData } from '../../../reducer';
import { IMetricResponseItem } from '../../../../../../../../lib/esm/General.interfaces';
import { IColorsByMarker } from '../../../interfaces';
import { cloneDeep } from 'lodash';
import { getFiltered } from '../tools/getFiltered';
import { generalReducerValues } from '../../../../../../../../General.reducer';
import { HSL2RGB } from '../../../../../../../../tools/hls2rgb';
import { useWidgetCurrentOptions } from '../../../../../../../../tools/useWidgetCurrentOptions';
import { filterValidDateRanges } from '../../../../../../../../tools/filterValidDateRanges';

export const usePrepareData = () => {
    const {
        cfg: { reportingObjectsByType },
        structures,
        selectedLocationId,
    } = useSelector(generalReducerValues);
    const {
        selectedObjectType,
        selectedGroupId,
        selectedZoneId,
        showComparison,
        zonesByGroup,
        selectedPlaceId,
        metricsData,
        selectedRelationId,
    } = useSelector(Maps_MapsOverview_Module_Reducer_Values);

    const dispatch = useDispatch();
    const localCurrentOptions = useWidgetCurrentOptions('Maps:Maps overview');

    useEffect(() => {
        dispatch(storeColorData(null));

        if (!structures?.relations_passway2dataobj) return;
        const opacity = selectedObjectType !== 'passway' ? 0.6 : 1;

        if (!Array.isArray(metricsData)) {
            dispatch(storeColorData(null));
            return;
        }

        const mainPeriod = localCurrentOptions?.mainPeriod?.id;
        const mainDateRange = localCurrentOptions?.mainDateRanges?.filter((item) => item.id === mainPeriod)[0];
        const period = mainDateRange?.period;

        const filteredPassway2Dataobj = filterValidDateRanges(structures?.['relations_passway2dataobj'], period);

        let dataMetrics: any[] = cloneDeep(metricsData);

        if (selectedObjectType === 'passway') {
            if (selectedRelationId === 'all') {
            } else if (selectedRelationId === 'zone' && selectedGroupId) {
                const zoneMarker = zonesByGroup[selectedGroupId]?.filter(
                    (item) => String(item.id) === String(selectedZoneId),
                )[0].marker;
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'zone' && item.dataobj_marker === zoneMarker)
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else if (selectedRelationId === 'place' && selectedPlaceId) {
                const placeMarker = reportingObjectsByType['place']?.filter(
                    (item) => String(item.id) === String(selectedPlaceId),
                )[0].marker;
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'place' && item.dataobj_marker === placeMarker)
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else if (selectedRelationId === 'floor') {
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'floor')
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else if (selectedRelationId === 'location') {
                const allowedPasswayMarkers: string[] = filteredPassway2Dataobj
                    ?.filter((item) => item.dataobj_type === 'location')
                    .map((item) => item.passway_marker);
                dataMetrics = getFiltered(dataMetrics, allowedPasswayMarkers);
            } else {
            }
        }

        const colorsByMarker: IColorsByMarker = {};

        if (!showComparison) {
            dataMetrics.forEach((metric: IMetricResponseItem[]) => {
                const alias = metric[0]?.context?.alias?.split(':')[0] || '';
                const data = {};
                metric.forEach((obj) => {
                    const marker = obj.context.data_objects[0].marker;
                    data[marker] = { value: obj.items[0].value };
                });
                const values = Object.values(data).map((item: any) => Number(item.value));
                const maxValue = Math.max(...values);
                const minValue = Math.min(...values);
                metric.forEach((obj) => {
                    const id = obj.context.data_objects[0].id;
                    const name = obj.context.data_objects[0].name;
                    let marker = obj.context.data_objects[0].marker;

                    const value = obj.items[0].value;
                    const normValue = (value - minValue) / (maxValue - minValue) || 0;
                    const heatColor = HSL2RGB(normValue * 130, 200, 30, opacity);
                    // const heatColor = HSL2RGB(240 - normValue * 240, 100, 40, opacity);
                    data[marker] = { normValue, heatColor, value, id, name, marker };
                });
                colorsByMarker[alias] = data;
            });
        } else {
            dataMetrics.forEach((metric: IMetricResponseItem[]) => {
                const alias = metric[0]?.context.alias?.split(':')[0] || '';
                const period = metric[0]?.context.alias?.split(':')[1] || '';
                if (period === 'main') return;
                const data = {};
                const mainMetric: IMetricResponseItem[] = dataMetrics?.find((item) => {
                    const mainAlias = item[0]?.context.alias?.split(':')[0] || '';
                    const period = item[0]?.context.alias?.split(':')[1] || '';
                    return mainAlias === alias && period === 'main';
                });
                metric.forEach((obj) => {
                    const compareId = obj.context.data_objects[0].id;
                    const name = obj.context.data_objects[0].name;
                    let marker = obj.context.data_objects[0].marker;
                    const mainObj = mainMetric?.find((item) => item.context.data_objects[0].id === compareId);
                    const mainValue = mainObj?.items[0].value;
                    const value = mainValue
                        ? Number(((obj.items[0].value - mainValue) * 100) / mainValue).toFixed(2)
                        : undefined;

                    if (value !== undefined) {
                        data[marker] = {
                            marker,
                            id: compareId,
                            name,
                            value,
                        };
                    }
                });
                const values = Object.values(data)
                    ?.filter((item: any) => item.value !== undefined)
                    .map((item: any) => Number(item.value));
                const maxValue = Math.max(...values);
                const minValue = Math.min(...values);

                Object.keys(data).forEach((key) => {
                    const obj = data[key];
                    const value = obj.value;
                    const normValue = (value - minValue) / (maxValue - minValue) || 0;
                    const heatColor = HSL2RGB(normValue * 130, 200, 30, opacity);
                    // const heatColor = HSL2RGB(240 - normValue * 240, 100, 40, opacity);
                    data[key] = { ...obj, heatColor, normValue };
                });

                colorsByMarker[alias] = data;
            });
        }

        dispatch(storeColorData(colorsByMarker));
    }, [
        localCurrentOptions?.mainPeriod?.id,
        selectedLocationId,
        dispatch,
        metricsData,
        selectedGroupId,
        selectedObjectType,
        showComparison,
        structures,
        selectedRelationId,
        zonesByGroup,
        selectedZoneId,
        reportingObjectsByType,
        selectedPlaceId,
    ]);
};
