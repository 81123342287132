import { __assign } from '../../../../_virtual/_tslib.js';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { MonthCell, YearWrapper, YearHeader, YearName, Year } from './styles.js';
import { shortMonthNames, bgColor } from '../../const/index.js';

var YearsGrid = function (_a) {
    var allMonthsOfCalendar = _a.allMonthsOfCalendar, lang = _a.lang, firstDateDidChanged = _a.firstDateDidChanged, secondDateDidChanged = _a.secondDateDidChanged, oneDayOnly = _a.oneDayOnly, showFutureDates = _a.showFutureDates;
    var _b = useState(null), firstSelectedDate = _b[0], setFirstSelectedDate = _b[1];
    var _c = useState(null), secondSelectedDate = _c[0], setSecondSelectedDate = _c[1];
    var _d = useState(null), dateUnderCursor = _d[0], setDateUnderCursor = _d[1];
    var now = DateTime.now();
    useEffect(function () {
        firstDateDidChanged(firstSelectedDate);
        secondDateDidChanged(secondSelectedDate);
    }, [firstSelectedDate, secondSelectedDate]);
    var onCellClick = function (month) { return function () {
        if (!oneDayOnly) {
            if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                setFirstSelectedDate(month);
                setSecondSelectedDate(null);
                setDateUnderCursor(null);
            }
            else if (firstSelectedDate && !secondSelectedDate) {
                setSecondSelectedDate(month);
            }
        }
        else {
            if (!firstSelectedDate || (firstSelectedDate && secondSelectedDate)) {
                setFirstSelectedDate(month);
                setSecondSelectedDate(month);
                setDateUnderCursor(null);
            }
            else if (firstSelectedDate && !secondSelectedDate) {
                setSecondSelectedDate(month);
            }
        }
    }; };
    var onCellMouseEnter = function (month) { return function () {
        if (firstSelectedDate && secondSelectedDate)
            return;
        setDateUnderCursor(month);
    }; };
    var grid = allMonthsOfCalendar.map(function (year, i) {
        var months = year.map(function (month, j) {
            var disabled = showFutureDates ? false : month > now;
            var isLastUnderCusor = month.valueOf() === (dateUnderCursor === null || dateUnderCursor === void 0 ? void 0 : dateUnderCursor.valueOf()) ||
                (secondSelectedDate && month.valueOf() === (secondSelectedDate === null || secondSelectedDate === void 0 ? void 0 : secondSelectedDate.startOf('day').valueOf())) ||
                false;
            var selected = (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) === month.valueOf() || (secondSelectedDate === null || secondSelectedDate === void 0 ? void 0 : secondSelectedDate.valueOf()) === month.valueOf();
            var bg = 'transparent';
            if (firstSelectedDate && dateUnderCursor) {
                if (month.valueOf() === (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && firstSelectedDate < dateUnderCursor) {
                    bg = "linear-gradient(90deg,transparent 50%,".concat(bgColor, " 0)");
                }
                else if (month.valueOf() === (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && firstSelectedDate > dateUnderCursor) {
                    bg = "linear-gradient(-90deg,transparent 50%,".concat(bgColor, " 0)");
                }
                else if ((month.valueOf() > (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && month.valueOf() < (dateUnderCursor === null || dateUnderCursor === void 0 ? void 0 : dateUnderCursor.valueOf())) ||
                    (month.valueOf() < (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && month.valueOf() > (dateUnderCursor === null || dateUnderCursor === void 0 ? void 0 : dateUnderCursor.valueOf()))) {
                    bg = "".concat(bgColor);
                }
                else if (month.valueOf() > (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && isLastUnderCusor) {
                    bg = "linear-gradient(-90deg,transparent 50%,".concat(bgColor, " 0)");
                }
                else if (month.valueOf() < (firstSelectedDate === null || firstSelectedDate === void 0 ? void 0 : firstSelectedDate.valueOf()) && isLastUnderCusor) {
                    bg = "linear-gradient(90deg,transparent 50%,".concat(bgColor, " 0)");
                }
            }
            return (jsx(MonthCell, __assign({ disabled: disabled, bg: bg, selected: selected, isLastUnderCusor: isLastUnderCusor, onClick: onCellClick(month), onMouseEnter: onCellMouseEnter(month) }, { children: jsx("span", { children: shortMonthNames[month.month][lang] }, void 0) }), "month".concat(i, "++").concat(j, "--")));
        });
        return (jsxs(YearWrapper, __assign({ index: i }, { children: [jsx(YearHeader, { children: jsx(YearName, { children: year[0].year }, void 0) }, void 0), jsx(Year, { children: months }, "month".concat(i))] }), "".concat(year[0].year, "year").concat(i)));
    });
    return jsx(Fragment, { children: grid }, void 0);
};

export { YearsGrid as default };
