import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../../../store';
import { IObjectsRatingData, IReducerState, ITreeMapData, TData, TRawObjectsRatingData } from './interfaces';

import { IPeriod, TLoadingData, TMetricResponse } from '../../../../../../../General.interfaces';
import _ from 'lodash';

const initialState: IReducerState = {
    comparePeriod: { id: 'previous' },
    colorMetric: 'fpc_sum_pass_count_in_wh',
    areaMetric: 'sum_area',
    objectsType: '',
    treeMapData: [],
    data: 'Loading',
    selectedObjectId: null,
    responseResult: { message: 'Loading', status: 'Loading' },
    rawObjectsRatingData: null,
    objectsRatingData: null,
    dataRefetchObject: {},
};

export const Performance_Dashboard_ByArea_Widget_Reducer = createSlice({
    name: 'Performance_Dashboard_ByArea_Widget_Reducer',
    initialState,

    reducers: {
        storeComparePeriod: (state, action: PayloadAction<IPeriod>) => {
            state.comparePeriod = { id: action.payload.id };
        },

        storeColorMetric: (state, action: PayloadAction<string>) => {
            state.colorMetric = action.payload;
        },

        storeAreaMetric: (state, action: PayloadAction<string>) => {
            state.areaMetric = action.payload;
        },

        storeObjectsType: (state, action: PayloadAction<string>) => {
            state.objectsType = action.payload;
        },

        storeData: (state, action: PayloadAction<TData>) => {
            state.data = action.payload;
            // state.treeMapData = action.payload
        },

        storeTreeMapData: (state, action: PayloadAction<ITreeMapData[]>) => {
            state.treeMapData = action.payload;
        },

        storeResponseResult: (state, action: PayloadAction<TLoadingData>) => {
            state.responseResult = action.payload;
        },

        /**
         * Сохранение значении по метрикам
         */
        storeRawObjectsRatingData: (state, action: PayloadAction<TRawObjectsRatingData>) => {
            state.rawObjectsRatingData = action.payload;
        },

        /**
         * Сохранение id выбранного объекта
         */
        storeSelectedObjectId: (state, action: PayloadAction<number | null>) => {
            const { selectedObjectId } = _.cloneDeep(state);
            if (selectedObjectId === action.payload) {
                state.selectedObjectId = null;
            } else {
                state.selectedObjectId = action.payload;
            }
        },
        /**
         * Запись подготовленных данных для рейтингов объекта
         */
        storeObjectsRatingData: (state, action: PayloadAction<IObjectsRatingData[] | null>) => {
            state.objectsRatingData = action.payload;
        },
        reloadWidget: (state) => {
            state.dataRefetchObject = {};
        },
        /**
         * Обнуление редьюсера
         */
        resetReducer: () => initialState,
    },
});

export const {
    storeObjectsRatingData,
    resetReducer,
    storeComparePeriod,
    storeColorMetric,
    storeAreaMetric,
    storeResponseResult,
    storeObjectsType,
    storeData,
    storeTreeMapData,
    storeRawObjectsRatingData,
    storeSelectedObjectId,
    reloadWidget,
} = Performance_Dashboard_ByArea_Widget_Reducer.actions;

export const Performance_Dashboard_ByArea_Widget_Reducer_Values = (state: RootState) =>
    state.Performance_Dashboard_ByArea_Widget_Reducer;

export default Performance_Dashboard_ByArea_Widget_Reducer.reducer;
